import { API_ROUTE, errMessage } from "../utils/constants";

export const getACDownload = async (useMiddleware) => {
  return await useMiddleware
    .get(`${API_ROUTE}/anticheat/download`, { responseType: "blob" })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
