const Nice = () => {
  const styles = {
    one: {
      fill: "#d3a31b",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    three: {
      fill: "#c19016",
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="M627.79,117.12c-6.8-21.26-83.98-15.57-172.38,12.71-88.4,28.28-154.55,68.44-147.75,89.71,2.74,8.58,16.95,12.77,38.72,12.86,32.19,.13,80.93-8.7,133.67-25.57,88.4-28.28,154.55-68.44,147.75-89.71Zm-154.64,69.18c-67.76,22.78-125.28,33.54-128.48,24.03-3.2-9.51,49.14-35.69,116.9-58.47,67.76-22.78,125.28-33.54,128.48-24.03,3.2,9.51-49.14,35.69-116.9,58.47Z"
      />
      <path
        style={styles.three}
        d="M345.8,177.33c-1.33,1.33-6.52,6.84-7.06,15.62-.59,9.61,4.83,16.1,5.94,17.38,.29,.49,.82,1.27,1.68,1.97,2.24,1.84,4.96,1.78,10.55,1.76,7.06-.03,6.16,.06,7.04,0,13.45-.85,41.99-8.26,53.35-11.14,9.77-2.48,8.64-2.06,14.07-3.52,15.24-4.08,26.44-7.95,32.25-9.97,42.95-14.95,64.9-22.67,88.53-34.59,11.87-5.99,40.18-20.28,38.11-26.97-.48-1.56-2.52-2.35-4.27-2.78,.01-2.11-.27-5.7-2.18-9.53-3.58-7.2-10.23-9.96-12.03-10.63,10.46-1.26,19.2-1.03,25.51-.51,13.19,1.09,19.24,3.73,22.28,5.28,2.65,1.34,4.7,2.71,6.08,3.7,1.16,1.67,2.55,4.23,2.71,7.44,.34,6.52-4.57,11.55-6.45,13.49-28.42,29.17-77.98,48.08-77.98,48.08-48.61,18.55-73.51,27.98-102.61,35.77-18.68,5-31.99,7.24-58.63,11.73,0,0-22.57,3.8-42.8,2.35-2.8-.2-5.51-.57-9.97-1.17-9.62-1.3-12.24-2.16-14.66-3.52-2.88-1.62-4.87-3.6-6.09-5.03-.28-.46-.64-1.14-.95-2.01-2.39-6.86,3.1-14.45,5.86-18.18,1.81-2.43,4.61-5.73,14.07-12.9,4.23-3.21,10.16-7.47,17.62-12.1Z"
      />
      <path
        style={styles.two}
        d="M627.79,117.12c-6.8-21.26-83.98-15.57-172.38,12.71-88.4,28.28-154.55,68.44-147.75,89.71,2.74,8.58,16.95,12.77,38.72,12.86,32.19,.13,80.93-8.7,133.67-25.57,88.4-28.28,154.55-68.44,147.75-89.71Zm-154.64,69.18c-67.76,22.78-125.28,33.54-128.48,24.03-3.2-9.51,49.14-35.69,116.9-58.47,67.76-22.78,125.28-33.54,128.48-24.03,3.2,9.51-49.14,35.69-116.9,58.47Z"
      />
    </g>
  );
};

export default Nice;
