const DefaultHappy = () => {
  const styles = {
    one: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 1.5,
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="M446.15,377.32c-.18-.31,10.22-11.45,24-10.43,12.03,.89,20.08,10.57,19.83,10.96-.25,.38-8.63-8.54-20.97-9.03-13.12-.53-22.68,8.8-22.86,8.51Z"
      />
      <path
        style={styles.one}
        d="M582.11,377.32c-.18-.31,10.22-11.45,24-10.43,12.03,.89,20.08,10.57,19.83,10.96-.25,.38-8.63-8.54-20.97-9.03-13.12-.53-22.68,8.8-22.86,8.51Z"
      />
    </g>
  );
};

export default DefaultHappy;
