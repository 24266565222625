import { Grid, Typography } from "@mui/material";
import {
  cardVeryLight,
  normal,
  secondaryText,
  text,
  transition,
} from "../../utils/themeContstants";
import games from "../../assets/3d_icons/games.png";
import leaderboard from "../../assets/3d_icons/leaderboard.png";
import store from "../../assets/3d_icons/store.png";
import snipes from "../../assets/3d_icons/snipes.png";
import reset from "../../assets/3d_icons/reset.png";
import premium from "../../assets/3d_icons/premium.png";
import privacy from "../../assets/3d_icons/privacy.png";
import terms from "../../assets/3d_icons/terms.png";
import faq from "../../assets/3d_icons/faq.png";
import ac from "../../assets/3d_icons/ac.png";
import withdrawals from "../../assets/3d_icons/withdrawals.png";
import profit from "../../assets/3d_icons/profit.png";
import expense from "../../assets/3d_icons/expense.png";
import disputes from "../../assets/3d_icons/disputes.png";
import search from "../../assets/3d_icons/search.png";
import unban from "../../assets/3d_icons/unban.png";
import home from "../../assets/3d_icons/home.png";
import security from "../../assets/3d_icons/security.png";
import chargeback from "../../assets/3d_icons/charge-back.png";
import chatUnban from "../../assets/3d_icons/chat-unban.png";
import maps from "../../assets/3d_icons/maps.png";
import earnings from "../../assets/3d_icons/earnings-reset.png";
import referrals from "../../assets/3d_icons/referrals.png";

const MainMenuListItem = ({ title, description, type, onClick }) => {
  const getIcon = () => {
    switch (type) {
      case "games":
        return games;
      case "leaderboard":
        return leaderboard;
      case "store":
        return store;
      case "snipes":
        return snipes;
      case "reset":
        return reset;
      case "reset_earnings":
        return earnings;
      case "staff":
        return;
      case "premium":
        return premium;
      case "terms":
        return terms;
      case "faq":
        return faq;
      case "privacy":
        return privacy;
      case "AC":
        return ac;
      case "withdrawals":
        return withdrawals;
      case "profit":
        return profit;
      case "expense":
        return expense;
      case "disputes":
        return disputes;
      case "search":
        return search;
      case "unban":
        return unban;
      case "chat-unban":
        return chatUnban;
      case "home":
        return home;
      case "chargeback":
        return chargeback;
      case "security":
        return security;
      case "maps":
        return maps;
      case "referrals":
        return referrals;
      default:
        return null;
    }
  };

  const styles = {
    container: {
      width: "100%",
      borderRadius: 2,
      padding: 1,
      transition: transition,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: cardVeryLight,
      },
      maxWidth: 360,
    },
    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: normal,
      fontWeight: 600,
      color: text,
    },
    description: {
      fontSize: 12,
      color: secondaryText,
      fontWeight: 400,
      maxWidth: 280,
    },
  };

  return (
    <Grid item sx={styles.container} onClick={onClick}>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        gap={{ xs: 2 }}
        wrap="nowrap"
      >
        <Grid item sx={styles.icon}>
          <img src={getIcon()} draggable={false} height={36} width={36} />
        </Grid>

        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={styles.title}>{title}</Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.description}>{description}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainMenuListItem;
