const Shaggy = () => {
  const styles = {
    one: {
      strokeWidth: 1.5,
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "none",
    },
    two: {
      strokeWidth: 1.5,
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "none",
      strokeWidth: 2,
    },
    four: {
      fill: "#ffc9ff",
    },
    five: {
      fill: "red",
    },
  };

  return (
    <g>
      <g>
        <path
          style={styles.four}
          d="M492.33,371.19c-.77,1.33-1.61,2.58-2.51,3.75,0,0,0,.01-.01,.01-.42,.55-.85,1.09-1.3,1.61-1.52,1.77-3.17,3.33-4.93,4.68-6.3,4.84-13.85,6.89-20.8,5.4-.04,0-.08-.01-.11-.03-.06,0-.12-.02-.19-.04-7.22-1.66-11.77-6.71-13.77-9.34-.19-.25-.34-.45-.45-.6,4.56-1.26,9.66-2.45,15.25-3.38,.87-.15,1.75-.29,2.65-.42,.03,0,.05-.01,.07-.01l2.98-.41c4.01-.52,7.85-.86,11.49-1.05,.82-.04,1.63-.08,2.43-.11h0c2.12-.08,4.16-.11,6.12-.1h.28s2.8,.04,2.8,.04Z"
        />
        <path
          style={styles.five}
          d="M491.78,371.58c-5.31,10.62-15.98,16.79-26.39,15.55-10.09-1.2-15.96-8.88-17.13-10.48,1.06,1.13,9.25,9.54,21.89,8.6,14.03-1.03,21.05-12.66,21.63-13.67Z"
        />
        <path
          style={styles.five}
          d="M462.01,384.99c.08-1.31,.36-2.93,1.66-3.61,1.25-.41,1.95-1.49,2.19-2.74-.04,1.28-.74,2.63-1.94,3.17-.97,.7-.99,2.05-.91,3.16,0,0-1,.01-1,.01h0Z"
        />
        <path
          style={styles.five}
          d="M466.4,386.82c.02-1.68-.12-3.6,1.14-4.95,1.58-.8,1.99-2.43,2.11-4.09,.08,1.64-.28,3.55-1.76,4.44-.92,1.21-.63,3.05-.49,4.51,0,0-1,.08-1,.08h0Z"
        />
        <path
          style={styles.five}
          d="M472.56,385.83c.02-1.68-.12-3.6,1.14-4.95,1.58-.8,1.99-2.43,2.11-4.09,.08,1.64-.28,3.55-1.76,4.44-.92,1.21-.63,3.05-.49,4.51,0,0-1,.08-1,.08h0Z"
        />
        <path
          style={styles.five}
          d="M483.16,381.58c-.5-1.38-1.03-3.16-2.39-3.86-.29-.13-.8-.12-1.19-.35-1.14-.6-1.8-1.79-2.31-2.9,.61,1.02,1.34,2.1,2.47,2.56,.35,.16,.71,.09,1.2,.22,.91,.3,1.49,1.04,1.97,1.73,.48,.71,.85,1.47,1.19,2.24l-.93,.36h0Z"
        />
        <path
          style={styles.five}
          d="M476.44,383.8c.4-1.41,1.01-3.17,.31-4.53-.16-.27-.57-.56-.76-.98-.58-1.16-.42-2.5-.19-3.71-.1,1.19-.13,2.49,.51,3.52,.19,.34,.52,.49,.84,.88,.57,.78,.6,1.71,.58,2.56-.03,.86-.17,1.69-.34,2.52l-.97-.26h0Z"
        />
        <path
          style={styles.five}
          d="M468.61,385.75c.11-1.68,.08-3.6,1.42-4.88,1.62-.71,2.13-2.31,2.34-3.96-.01,1.64-.48,3.53-2.01,4.34-.99,1.16-.81,3.01-.75,4.48,0,0-1,.02-1,.02h0Z"
        />
        <path
          style={styles.five}
          d="M488.09,376.89c-.53-1.44-.99-3.27-2.48-3.96-1-.1-1.98-.84-2.48-1.67,0,0,0,0,0,0h0s-.06-.09-.06-.09c0,0,.08,.09,.06,.08,0,0,0,0,0,0,.45,.49,.97,.96,1.59,1.16,.62,.15,1.46,.12,1.98,.64,1.1,.91,1.73,2.22,2.32,3.46,0,0-.93,.38-.93,.38h0Z"
        />
        <path
          style={styles.five}
          d="M489.95,370.9c.18,.48,.46,1.78,.64,2.48,.01,.07,.02,.08-.02,0,0,0-.73-1-.73-.99-.2-.28-.54-.74-.74-.98-.04,.01,.86-.52,.85-.52h0Z"
        />
        <path
          style={styles.five}
          d="M486.35,378.05c-.47-1.31-.97-2.94-2.16-3.75-.28-.2-.62-.2-1.05-.32-1.17-.4-1.88-1.51-2.41-2.53,0,0-.03-.07-.03-.07,0,0,0-.02,0-.02,0,0,.01,.02,0,.02,0,0,.04,.07,.04,.07,.62,.92,1.39,1.91,2.5,2.17,.31,.06,.79,.03,1.19,.24,1.51,.83,2.15,2.4,2.87,3.86,0,0-.94,.34-.94,.34h0Z"
        />
        <path
          style={styles.five}
          d="M458.58,384.44c.13-1.31,.49-2.91,1.82-3.53,1.27-.35,2.01-1.4,2.31-2.65-.1,1.28-.85,2.59-2.07,3.09-1,.66-1.08,2.01-1.05,3.12,0,0-1-.03-1-.03h0Z"
        />
        <path
          style={styles.five}
          d="M455.87,383.06c.13-1.31,.49-2.91,1.82-3.53,1.27-.35,2.01-1.4,2.31-2.65-.1,1.28-.85,2.59-2.07,3.09-1,.66-1.08,2.01-1.05,3.12,0,0-1-.03-1-.03h0Z"
        />
        <path
          style={styles.five}
          d="M453.08,381.3c.13-1.31,.49-2.91,1.82-3.53,1.27-.35,2.01-1.4,2.31-2.65-.1,1.28-.85,2.59-2.07,3.09-1,.66-1.08,2.01-1.05,3.12,0,0-1-.03-1-.03h0Z"
        />
        <path
          style={styles.five}
          d="M450.53,379.73c.5-1.22,1.29-2.65,2.74-2.87,1.32,.02,2.33-.77,2.96-1.88-.45,1.2-1.55,2.24-2.86,2.37-1.15,.35-1.6,1.62-1.89,2.69,0,0-.95-.31-.95-.31h0Z"
        />
        <path
          style={styles.three}
          d="M442.12,372.45c.55,2.38,2.95,4.11,5.67,3.9,2.41-.69,5.93-1.58,10.24-2.32,1.63-.28,3.09-.49,4.37-.64,.38-.05,.74-.09,1.1-.13,1.01-.12,1.9-.2,2.67-.27,.75-.07,1.64-.15,2.94-.26,3.13-.26,8.76-.67,14.4-1.03,0,0,3.13-.26,5.76-.34,.12,0,.24-.01,.37-.02,1.1-.06,2.01-.11,2.69-.14,1.52-1.69,2-4.02,1.2-5.99-.87-2.16-2.89-2.97-3.26-3.1-2.59-14.26-16.41-22.96-28.69-19.98-10.4,2.53-18.16,13.07-17.45,25.09-1.66,1.28-2.45,3.35-2,5.24Z"
        />
        <path
          style={styles.one}
          d="M447.79,376.35c1.63-.4,3.99-.95,6.83-1.55,5.3-1.1,13.13-2.69,23.57-3.34,3.5-.22,8.56-.46,14.13-.27-6.34,11.5-18.2,17.67-29.01,15.6-8.83-1.69-14-8.32-15.53-10.44Z"
        />
        <path
          style={styles.two}
          d="M466.48,376.11c.35,1.55,1.43,2.81,2.98,3.67,.89,.5,1.95,.86,3.1,1.06,.7,.13,1.44,.19,2.2,.2,1.07,0,2.19-.11,3.33-.37,.66-.15,1.3-.34,1.9-.57,1.49-.55,2.81-1.31,3.87-2.21,.59-.5,1.11-1.04,1.53-1.61,.92-1.23,1.4-2.59,1.32-3.94,0-.06,0-.11-.01-.16,0-.01,0-.03,0-.04-1.96,0-4.01,.02-6.12,.1-.8,.03-1.61,.07-2.44,.11-3.63,.19-7.47,.53-11.49,1.05,0,.01-.01,.02-.01,.03-.03,.09-.06,.19-.08,.28-.21,.79-.24,1.61-.06,2.4Z"
        />
      </g>
      <g>
        <path
          style={styles.four}
          d="M627.33,371.19c-.77,1.33-1.61,2.58-2.51,3.75,0,0,0,.01-.01,.01-.42,.55-.85,1.09-1.3,1.61-1.52,1.77-3.17,3.33-4.93,4.68-6.3,4.84-13.85,6.89-20.8,5.4-.04,0-.08-.01-.11-.03-.06,0-.12-.02-.19-.04-7.22-1.66-11.77-6.71-13.77-9.34-.19-.25-.34-.45-.45-.6,4.56-1.26,9.66-2.45,15.25-3.38,.87-.15,1.75-.29,2.65-.42,.03,0,.05-.01,.07-.01l2.98-.41c4.01-.52,7.85-.86,11.49-1.05,.82-.04,1.63-.08,2.43-.11h0c2.12-.08,4.16-.11,6.12-.1h.28s2.8,.04,2.8,.04Z"
        />
        <path
          style={styles.five}
          d="M626.78,371.58c-5.31,10.62-15.98,16.79-26.39,15.55-10.09-1.2-15.96-8.88-17.13-10.48,1.06,1.13,9.25,9.54,21.89,8.6,14.03-1.03,21.05-12.66,21.63-13.67Z"
        />
        <path
          style={styles.five}
          d="M597.01,384.99c.08-1.31,.36-2.93,1.66-3.61,1.25-.41,1.95-1.49,2.19-2.74-.04,1.28-.74,2.63-1.94,3.17-.97,.7-.99,2.05-.91,3.16,0,0-1,.01-1,.01h0Z"
        />
        <path
          style={styles.five}
          d="M601.4,386.82c.02-1.68-.12-3.6,1.14-4.95,1.58-.8,1.99-2.43,2.11-4.09,.08,1.64-.28,3.55-1.76,4.44-.92,1.21-.63,3.05-.49,4.51,0,0-1,.08-1,.08h0Z"
        />
        <path
          style={styles.five}
          d="M607.56,385.83c.02-1.68-.12-3.6,1.14-4.95,1.58-.8,1.99-2.43,2.11-4.09,.08,1.64-.28,3.55-1.76,4.44-.92,1.21-.63,3.05-.49,4.51,0,0-1,.08-1,.08h0Z"
        />
        <path
          style={styles.five}
          d="M618.16,381.58c-.5-1.38-1.03-3.16-2.39-3.86-.29-.13-.8-.12-1.19-.35-1.14-.6-1.8-1.79-2.31-2.9,.61,1.02,1.34,2.1,2.47,2.56,.35,.16,.71,.09,1.2,.22,.91,.3,1.49,1.04,1.97,1.73,.48,.71,.85,1.47,1.19,2.24l-.93,.36h0Z"
        />
        <path
          style={styles.five}
          d="M611.44,383.8c.4-1.41,1.01-3.17,.31-4.53-.16-.27-.57-.56-.76-.98-.58-1.16-.42-2.5-.19-3.71-.1,1.19-.13,2.49,.51,3.52,.19,.34,.52,.49,.84,.88,.57,.78,.6,1.71,.58,2.56-.03,.86-.17,1.69-.34,2.52l-.97-.26h0Z"
        />
        <path
          style={styles.five}
          d="M603.61,385.75c.11-1.68,.08-3.6,1.42-4.88,1.62-.71,2.13-2.31,2.34-3.96-.01,1.64-.48,3.53-2.01,4.34-.99,1.16-.81,3.01-.75,4.48,0,0-1,.02-1,.02h0Z"
        />
        <path
          style={styles.five}
          d="M623.09,376.89c-.53-1.44-.99-3.27-2.48-3.96-1-.1-1.98-.84-2.48-1.67,0,0,0,0,0,0h0s-.06-.09-.06-.09c0,0,.08,.09,.06,.08,0,0,0,0,0,0,.45,.49,.97,.96,1.59,1.16,.62,.15,1.46,.12,1.98,.64,1.1,.91,1.73,2.22,2.32,3.46,0,0-.93,.38-.93,.38h0Z"
        />
        <path
          style={styles.five}
          d="M624.95,370.9c.18,.48,.46,1.78,.64,2.48,.01,.07,.02,.08-.02,0,0,0-.73-1-.73-.99-.2-.28-.54-.74-.74-.98-.04,.01,.86-.52,.85-.52h0Z"
        />
        <path
          style={styles.five}
          d="M621.35,378.05c-.47-1.31-.97-2.94-2.16-3.75-.28-.2-.62-.2-1.05-.32-1.17-.4-1.88-1.51-2.41-2.53,0,0-.03-.07-.03-.07,0,0,0-.02,0-.02,0,0,.01,.02,0,.02,0,0,.04,.07,.04,.07,.62,.92,1.39,1.91,2.5,2.17,.31,.06,.79,.03,1.19,.24,1.51,.83,2.15,2.4,2.87,3.86,0,0-.94,.34-.94,.34h0Z"
        />
        <path
          style={styles.five}
          d="M593.58,384.44c.13-1.31,.49-2.91,1.82-3.53,1.27-.35,2.01-1.4,2.31-2.65-.1,1.28-.85,2.59-2.07,3.09-1,.66-1.08,2.01-1.05,3.12,0,0-1-.03-1-.03h0Z"
        />
        <path
          style={styles.five}
          d="M590.87,383.06c.13-1.31,.49-2.91,1.82-3.53,1.27-.35,2.01-1.4,2.31-2.65-.1,1.28-.85,2.59-2.07,3.09-1,.66-1.08,2.01-1.05,3.12,0,0-1-.03-1-.03h0Z"
        />
        <path
          style={styles.five}
          d="M588.08,381.3c.13-1.31,.49-2.91,1.82-3.53,1.27-.35,2.01-1.4,2.31-2.65-.1,1.28-.85,2.59-2.07,3.09-1,.66-1.08,2.01-1.05,3.12,0,0-1-.03-1-.03h0Z"
        />
        <path
          style={styles.five}
          d="M585.53,379.73c.5-1.22,1.29-2.65,2.74-2.87,1.32,.02,2.33-.77,2.96-1.88-.45,1.2-1.55,2.24-2.86,2.37-1.15,.35-1.6,1.62-1.89,2.69,0,0-.95-.31-.95-.31h0Z"
        />
        <path
          style={styles.three}
          d="M577.12,372.45c.55,2.38,2.95,4.11,5.67,3.9,2.41-.69,5.93-1.58,10.24-2.32,1.63-.28,3.09-.49,4.37-.64,.38-.05,.74-.09,1.1-.13,1.01-.12,1.9-.2,2.67-.27,.75-.07,1.64-.15,2.94-.26,3.13-.26,8.76-.67,14.4-1.03,0,0,3.13-.26,5.76-.34,.12,0,.24-.01,.37-.02,1.1-.06,2.01-.11,2.69-.14,1.52-1.69,2-4.02,1.2-5.99-.87-2.16-2.89-2.97-3.26-3.1-2.59-14.26-16.41-22.96-28.69-19.98-10.4,2.53-18.16,13.07-17.45,25.09-1.66,1.28-2.45,3.35-2,5.24Z"
        />
        <path
          style={styles.one}
          d="M582.79,376.35c1.63-.4,3.99-.95,6.83-1.55,5.3-1.1,13.13-2.69,23.57-3.34,3.5-.22,8.56-.46,14.13-.27-6.34,11.5-18.2,17.67-29.01,15.6-8.83-1.69-14-8.32-15.53-10.44Z"
        />
        <path
          style={styles.two}
          d="M601.48,376.11c.35,1.55,1.43,2.81,2.98,3.67,.89,.5,1.95,.86,3.1,1.06,.7,.13,1.44,.19,2.2,.2,1.07,0,2.19-.11,3.33-.37,.66-.15,1.3-.34,1.9-.57,1.49-.55,2.81-1.31,3.87-2.21,.59-.5,1.11-1.04,1.53-1.61,.92-1.23,1.4-2.59,1.32-3.94,0-.06,0-.11-.01-.16,0-.01,0-.03,0-.04-1.96,0-4.01,.02-6.12,.1-.8,.03-1.61,.07-2.44,.11-3.63,.19-7.47,.53-11.49,1.05,0,.01-.01,.02-.01,.03-.03,.09-.06,.19-.08,.28-.21,.79-.24,1.61-.06,2.4Z"
        />
      </g>
    </g>
  );
};

export default Shaggy;
