import { Divider, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import useMiddleware from "../../api/useMiddleware";
import { buyChatUnban, buyUnban } from "../../api/user";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { numFormatter } from "../../utils/helpers";
import {
  cardLight,
  cardVeryLight,
  doubtedBlue,
  normal,
  secondaryText,
  small,
  text,
  yellow,
} from "../../utils/themeContstants";
import CustomCheckbox from "../custom/CustomCheckbox";
import CustomModal from "../custom/CustomModal";
import RivoxCoin from "../custom/RivoxCoin";
import PrimaryButton from "../custom/PrimaryButton";
import SecondaryButton from "../custom/SecondaryButton";

const PurchaseChatUnbanModal = ({ open, onClose, closeProfile }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const store = useContext(StoreContext);

  const [understands, setUnderstands] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setUnderstands(false);
    setLoading(false);
    onClose();
  };

  const handlePurchaseUnban = () => {
    setLoading(true);
    buyChatUnban(middleware).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
        closeProfile();
      }
    });
  };

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
      maxWidth: 500,
    },
    width: {
      width: "100%",
    },
    value: {
      fontSize: normal,
      color: text,
      fontWeight: 500,
    },
  };

  return (
    <CustomModal
      title="Purchase Chat Unban"
      description="Re-gain access to Rivox Chat Services."
      primaryButton={
        <SecondaryButton
          label="Buy now"
          bg={doubtedBlue}
          disabled={loading || !understands || store?.user?.balance < 1.49}
          onClick={handlePurchaseUnban}
        />
      }
      secondaryButton={<SecondaryButton label="Close" onClick={handleClose} />}
      open={open}
      onClose={handleClose}
      titleSize="large"
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Typography sx={styles.description}>
            You are about to regain access to all Chat services upon purchasing
            this item.
          </Typography>
        </Grid>

        <Grid item sx={styles.width}>
          <Divider
            sx={{ height: 2, width: "100%", backgroundColor: cardVeryLight }}
          />
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography sx={styles.value}>Purchasing</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={{ ...styles.value, fontWeight: 800 }}>
                    Chat Unban
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography sx={styles.value}>Total</Typography>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 0.5 }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <RivoxCoin size={20} />
                    </Grid>

                    <Grid item>
                      <Typography sx={{ ...styles.value, fontWeight: 800 }}>
                        {numFormatter().format(1.49)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <Divider
            sx={{ height: 2, width: "100%", backgroundColor: cardVeryLight }}
          />
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            gap={{ xs: 2 }}
          >
            <Grid>
              <CustomCheckbox
                checked={understands}
                onChange={(value) => setUnderstands(value)}
                color={doubtedBlue}
                disabled={loading}
                checkColor={text}
              />
            </Grid>

            <Grid>
              <Typography
                sx={{
                  fontSize: small,
                  color: secondaryText,
                  fontWeight: 600,
                  maxWidth: 400,
                }}
              >
                By checking this box you understand that you admit to and
                acknowledge the reason of your ban and that you may be subject
                to being re-banned upon breaking any rules or TOS.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default PurchaseChatUnbanModal;
