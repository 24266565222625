import { CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { giftPiece } from "../../api/avatar";
import { searchForUser } from "../../api/search";
import useMiddleware from "../../api/useMiddleware";
import Avatar from "../../avatar/Avatar";
import Piece from "../../avatar/Piece";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { numFormatter } from "../../utils/helpers";
import {
  card,
  cardDark,
  cardVeryLight,
  doubtedBlue,
  green,
  normal,
  secondaryText,
  small,
  text,
  transition,
} from "../../utils/themeContstants";
import CustomCheckbox from "../custom/CustomCheckbox";
import CustomInput from "../custom/CustomInput";
import CustomModal from "../custom/CustomModal";
import RivoxCoin from "../custom/RivoxCoin";
import NotSelected from "../custom/NotSelected";
import SecondaryButton from "../custom/SecondaryButton";

const GiftItemModal = ({ open, onClose, item }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [query, setQuery] = useState("");
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [understands, setUnderstands] = useState(false);
  const [user, setUser] = useState(null);
  const [purchaseLoading, setPurchaseLoading] = useState(false);

  const handleClose = () => {
    setQuery("");
    setResults(null);
    setLoading(false);
    setUnderstands(false);
    setPurchaseLoading(false);
    setUser(null);
    onClose();
  };

  const handleGift = () => {
    setPurchaseLoading(true);
    giftPiece(middleware, item?._id, user?.user_id).then((res) => {
      setPurchaseLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
        setUnderstands(false);
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
      }
    });
  };

  const handleUserClick = (user) => {
    setResults(null);
    setQuery("");
    setLoading(false);
    setUnderstands(false);
    setPurchaseLoading(false);
    setUser(user);
  };

  const handleSearch = (searchQuery) => {
    if (
      searchQuery === "" ||
      searchQuery == null ||
      searchQuery?.length >= 21
    ) {
      setResults(null);
      return;
    }

    setResults(null);
    setLoading(true);
    setUser(null);
    searchForUser(middleware, searchQuery).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setResults(res?.userArray);
      }
    });
  };

  const styles = {
    width: {
      width: "100%",
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    value: {
      fontSize: normal,
      color: text,
      fontWeight: 500,
    },
    username: {
      fontSize: normal,
      color: text,
      fontWeight: 800,
    },
    meta: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 600,
    },
    container: {
      width: "100%",
      paddingLeft: 2,
      paddingBottom: 1,
      paddingRight: 2,
      borderRadius: 1,
      backgroundColor: cardDark,
      transition: transition,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: card,
      },
    },
  };

  return (
    <CustomModal
      title={`Gift ${item?.name}`}
      onClose={handleClose}
      open={open}
      primaryButton={
        <SecondaryButton
          label="Buy Gift"
          bg={doubtedBlue}
          disabled={!understands}
          onClick={handleGift}
          loading={purchaseLoading}
        />
      }
      secondaryButton={<SecondaryButton label="Cancel" onClick={handleClose} />}
      titleSize="large"
      minWidth={600}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            <Grid item>
              <Typography sx={styles.label}>
                Search for a player to gift
              </Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <CustomInput
                value={query}
                placeholder="Search users..."
                onChange={(val) => {
                  setQuery(val);
                  handleSearch(val);
                }}
                autoFocus={true}
                onPaste={(val) => {
                  setQuery(val);
                  handleSearch(val);
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {loading && (
          <Grid item>
            <CircularProgress size={30} sx={{ color: secondaryText }} />
          </Grid>
        )}

        {!loading &&
          query !== "" &&
          query != null &&
          (results == null || results?.length < 1) && (
            <NotSelected label="No results" />
          )}

        {loading ||
        results?.length < 1 ||
        results == null ||
        query === "" ||
        query == null ? null : (
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              {results?.map((user, i) => {
                return (
                  <Grid
                    item
                    sx={styles.container}
                    key={i}
                    onClick={() => handleUserClick(user)}
                  >
                    <Grid container justifyContent="start" alignItems="center">
                      <Grid item sx={{ marginLeft: -2 }}>
                        <Avatar
                          size={100}
                          bgColor={card}
                          avatar={user?.avatar}
                        />
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            ...styles.username,
                            color: user?.isPremium
                              ? "rgb(255, 255, 193)"
                              : text,
                            textShadow: user?.isPremium
                              ? "rgb(255, 255, 193)"
                              : null,
                          }}
                        >
                          {user?.username}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}

        {!loading && user && (
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.width}>
                <Divider
                  sx={{ width: "100%", backgroundColor: cardVeryLight }}
                />
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: 18,
                            color: text,
                            fontWeight: 400,
                          }}
                        >
                          Gifting <b>{item?.name}</b> to <b>{user?.username}</b>
                        </Typography>
                      </Grid>

                      <Piece
                        type={item?.category}
                        color={
                          store?.user?.new_avatar?.options[
                            `${item?.category}Color`
                          ]
                        }
                        option={
                          item?.current_variant
                            ? item?.current_variant?.value
                            : item?.value
                        }
                        size={100}
                        skinColor={store?.user?.new_avatar?.options?.skinColor}
                      />
                    </Grid>
                  </Grid>

                  <Grid item sx={styles.width}>
                    <Divider
                      sx={{ width: "100%", backgroundColor: cardVeryLight }}
                    />
                  </Grid>

                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography sx={styles.value}>
                              Gifting{" "}
                              <span style={{ fontWeight: 800 }}>
                                {user?.username}
                              </span>
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography
                              sx={{ ...styles.value, fontWeight: 800 }}
                            >
                              {item?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography sx={styles.value}>Total</Typography>
                          </Grid>

                          <Grid item>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              gap={{ xs: 0.5 }}
                            >
                              <Grid
                                item
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <RivoxCoin size={20} />
                              </Grid>

                              <Grid item>
                                <Typography sx={styles.label}>
                                  {numFormatter().format(item?.price)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography sx={styles.value}>
                              Your balance after
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              gap={{ xs: 0.5 }}
                            >
                              <Grid
                                item
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <RivoxCoin size={20} />
                              </Grid>

                              <Grid item>
                                <Typography sx={styles.label}>
                                  {numFormatter().format(
                                    store?.user?.funds?.balance - item?.price
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sx={styles.width}>
                    <Divider
                      sx={{ width: "100%", backgroundColor: cardVeryLight }}
                    />
                  </Grid>

                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 2 }}
                    >
                      <Grid>
                        <CustomCheckbox
                          checked={understands}
                          onChange={(value) => setUnderstands(value)}
                          color={green}
                        />
                      </Grid>

                      <Grid>
                        <Typography
                          sx={{
                            fontSize: small,
                            color: secondaryText,
                            fontWeight: 600,
                            maxWidth: 400,
                          }}
                        >
                          By checking this box you understand that this gift is
                          irreversible and will come out of your balance.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </CustomModal>
  );
};

export default GiftItemModal;
