import { Divider, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { AiOutlinePaperClip } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { submitEvidence } from "../../api/matches";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import {
  cardLight,
  cardVeryLight,
  doubtedBlue,
  normal,
  offWhite,
  red,
  small,
  text,
} from "../../utils/themeContstants";
import CustomInput from "../custom/CustomInput";
import CustomModal from "../custom/CustomModal";
import PrimaryButton from "../custom/PrimaryButton";
import SecondaryButton from "../custom/SecondaryButton";
import TextButton from "../custom/TextButton";

const UploadEvidenceModal = ({ open, onClose, token }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const [currentLink, setCurrentLink] = useState("");

  const handleClose = () => {
    setLoading(false);
    setLinks([]);
    setCurrentLink("");
    onClose();
  };

  const handleUpload = () => {
    setLoading(true);
    submitEvidence(middleware, token?._id, links).then((res) => {
      setLoading(false);

      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
      }
    });
  };

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    label: {
      fontSize: normal,
      fontWeight: 700,
      color: offWhite,
    },
    width: {
      width: "100%",
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="Upload Evidence"
      primaryButton={
        <SecondaryButton
          label="Upload"
          bg={doubtedBlue}
          disabled={!links || links?.length < 1}
          loading={loading}
          onClick={handleUpload}
        />
      }
      secondaryButton={<SecondaryButton label="Cancel" onClick={handleClose} />}
      titleSize="large"
      description="Upload proof of your team winning the match."
      minWidth={600}
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Typography sx={styles.description}>
            Attach one or multiple <b>Streamable</b> (video) or <b>Imgur</b>{" "}
            (screenshot) links as evidence
          </Typography>
        </Grid>

        <Grid item sx={styles.width}>
          <Divider
            sx={{ width: "100%", backgroundColor: cardVeryLight, height: 2 }}
          />
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            <Grid item>
              <Typography sx={styles.label}>Attach Link</Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 1 }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AiOutlinePaperClip
                    style={{ fontSize: 30, color: offWhite }}
                  />
                </Grid>

                <Grid item sx={{ flexGrow: 1 }}>
                  <CustomInput
                    value={currentLink}
                    onChange={(val) => setCurrentLink(val)}
                    onPaste={(val) => setCurrentLink(val)}
                    placeholder="Paste link"
                    type="url"
                  />
                </Grid>

                <Grid item>
                  <SecondaryButton
                    label="Attach"
                    disabled={
                      !currentLink || currentLink == null || currentLink === ""
                    }
                    size="small"
                    onClick={() => {
                      if (
                        links &&
                        links?.length > 0 &&
                        links?.findIndex((link) => link === currentLink) !== -1
                      ) {
                        setCurrentLink("");
                        return;
                      } else {
                        let newLinks = links;
                        newLinks.push(currentLink);
                        setLinks([...newLinks]);
                        setCurrentLink("");
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {links && links?.length > 0 && (
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
            >
              {links && links?.length > 0 && (
                <>
                  {links?.map((link, i) => (
                    <Grid item key={i}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 1 }}
                      >
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <AiOutlinePaperClip
                            style={{ fontSize: 15, color: offWhite }}
                          />
                        </Grid>

                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: small,
                              fontWeight: 600,
                              color: text,
                            }}
                          >
                            {link}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <TextButton
                            label="Remove"
                            size="small"
                            icon={
                              <FaTrash
                                style={{ fontSize: 15, color: offWhite }}
                              />
                            }
                            onClick={() => {
                              let newLinks = links;
                              newLinks.splice(i, 1);
                              setLinks([...newLinks]);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </CustomModal>
  );
};

export default UploadEvidenceModal;
