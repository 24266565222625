import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { numFormatter } from "../../utils/helpers";
import {
  card,
  cardDark,
  green,
  normal,
  red,
  small,
  text,
  yellow,
} from "../../utils/themeContstants";
import DoubtedCoin from "./DoubtedCoin";
import Hover from "./Hover";
import RivoxCoin from "./RivoxCoin";

const CoinBadge = ({
  amount,
  type,
  label,
  placement,
  size,
  insideLabel,
  stretch,
  dark = false,
  backgroundColor = null,
}) => {
  const [hovered, setHovered] = useState(false);
  const [ref, setRef] = useState(null);

  const getBackgroundColor = () => {
    if (backgroundColor) return backgroundColor;
    if (dark) return card;
    if (type === "prize") return "rgba(114, 242, 56, .15)";
    if (type === "loss") return "rgba(245, 74, 74, .15)";
    return "rgba(252, 231, 28, 0.15)";
  };

  const getColor = () => {
    if (dark) return text;
    if (type === "prize") return green;
    if (type === "loss") return red;
    return yellow;
  };

  const styles = {
    value: {
      fontSize: normal,
      fontWeight: 600,
      color: text,
    },
  };

  return (
    <Grid
      item
      sx={{
        paddingTop: 0.5,
        paddingBottom: 0.5,
        paddingRight: 1,
        paddingLeft: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getBackgroundColor(),
        borderRadius: 2,
        position: "relative",
        flexGrow: stretch ? 1 : null,
      }}
      ref={setRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered && <Hover label={label} placement={placement} refEl={ref} />}

      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
      >
        {insideLabel && (
          <Grid item>
            <Typography
              sx={{
                fontSize: small,
                color: getColor(),
                fontWeight: 700,
              }}
            >
              {insideLabel}
            </Typography>
          </Grid>
        )}

        <Grid item>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            gap={{ xs: 0.5 }}
          >
            <RivoxCoin size={size === "small" ? 15 : 20} />

            <Grid item>
              <Typography
                sx={{
                  ...styles.value,
                  fontSize: size === "small" ? 14 : 16,
                  color: getColor(),
                }}
              >
                {type === "prize" ? "+" : null}
                {type === "loss" ? "-" : null}
                {numFormatter().format(amount)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CoinBadge;
