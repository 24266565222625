const MoneyCalling = ({ skinColor }) => {
  const styles = {
    one: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 3,
      fill: skinColor,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 3,
      fill: "none",
    },
    three: {
      fill: "#2c993e",
    },
    four: {
      fill: "#3fba57",
    },
  };

  return (
    <g>
      <path
        style={styles.four}
        d="m341.15,411.88l-20.55-19.87c2.85-8.28,7.07-18.24,13.43-28.83,8.21-13.66,17.28-23.87,24.72-31.04l14.15,7.13,13.09,20.92-44.84,51.69Z"
      />
      <path
        style={styles.four}
        d="m454.66,430.31c4.44,3.12,10.72,8.47,15.56,16.87,1.77,3.07,3.05,6.02,3.98,8.67-1.72,9.09-4.52,19.85-9.2,31.5-6.91,17.23-15.59,30.58-22.91,40.11-4.69-1.49-9.38-2.97-14.07-4.46l-9.59,4.46-4.5-10.96h-8.97v-7.04l-47.27-20.54,43.83-69.26c7.4.04,16.41.67,26.47,2.64,10.59,2.08,19.56,5.09,26.65,8.02Z"
      />
      <path
        style={styles.three}
        d="m420.39,422.1v8.2c7.58.72,23.67,3.43,36.26,16.09,2.65,2.66,4.79,5.41,6.52,8.05.13,3.38.15,7.96-.26,13.34-1.96,25.73-12.03,46.1-20.82,59.67,7.47-10.15,16.01-23.91,22.94-41.3,4.41-11.06,7.27-21.35,9.16-30.31-.95-2.33-1.9-4.66-2.86-6.99l-15.38-16.99c-4.89-2.75-11.76-5.9-20.38-7.88-5.73-1.31-10.9-1.79-15.19-1.89Z"
      />
      <path
        style={styles.three}
        d="m344.22,407.52c-9.98-10.82-9.86-26.93-1.05-36.26,7.04-7.45,18.78-9.69,29.21-5.36-9.39,13.87-18.78,27.74-28.16,41.61Z"
      />
      <polygon
        style={styles.three}
        points="361.43 335.65 376.3 365.13 386.4 360.04 372.91 339.26 361.43 335.65"
      />
      <path
        style={styles.three}
        d="m420.39,422.1v8.2c-3.95,4.59-7.95,9.63-11.9,15.14-10.93,15.28-18.57,30.21-23.97,43.27-.92-5.74-1.98-17.28,2.52-30.68,4.96-14.78,14.01-23.85,18.4-27.73,4.98-2.73,9.96-5.47,14.94-8.2Z"
      />
      <path
        style={styles.one}
        d="m468.27,430.22c-1.68,3.09-5.81,3.78-6.7,3.91-3.33-1.98-7.14-3.95-11.43-5.74-10.7-4.45-20.56-6.17-28.18-6.78-5.47,1.6-9.84,3.88-13.3,6.26-28.95,19.89-24.07,67.05-23.48,72.13-.81,2.09-2.17,5.27-4.31,8.87-5.9,9.96-11.9,13.32-19.17,21.91-2.91,3.44-6.85,8.7-10.57,16.05-2.48,6.13-6.2,16.14-9.37,28.86-3.58,14.4-4.46,24.21-5.49,32.18-1.21,9.3-2.76,16.9-4.5,23.66,0,.01,0,.02-.01.03-4.12,15.95-9.34,27.19-13.88,44.86-3,11.67-4.25,20.2-4.7,23.01-1.25,7.79-1.99,14.55-2.45,19.85-.78,5.74-1.87,11.93-3.37,18.49-1.77,7.73-3.84,14.77-6,21.07h0c-.4-.4-.8-.81-1.19-1.22-1.32-1.37-2.56-2.7-3.77-4-1.2-1.3-2.39-2.61-3.57-3.93-.14-.15-.28-.31-.41-.46-.69-.78-1.39-1.54-2.07-2.32-16.84-19.39-30.98-41.19-41.86-64.8,1.2-14.94,4.16-32.86,10.69-52.32,3.92-11.69,8.48-21.96,13.04-30.78,11.89-25.84,19.75-40.58,24.92-49.43,1.8-3.1,6.86-11.63,11.73-23.87,1.5-3.77,3.2-8.41,4.83-13.83,2.55-17.13,5.66-30.54,8.09-39.78,3.32-12.63,4.72-15.02,7.43-27.39,1.77-8.08,2.43-12.79,5.09-19.57,3.13-7.99,7.11-13.92,9.91-17.61.04-4.08.51-9.61,2.32-15.9,1.68-5.8,3.97-10.42,5.95-13.77,6.87-4.99,16.34-10.86,28.38-15.72,12.77-5.15,24.28-7.5,33-8.61,3.43-.75,6.03.01,6.49,1.35.09.25.24.92-.49,2.04-.63,1.26-1.44,2.59-2.47,3.92-4.53,5.82-9.5,7.8-12.82,8.77,6.33-.12,15.49.89,26.9,5.7,6.99,2.95,12.38,6.6,16.24,9.7.57.53.69,1.04.72,1.26.17,1.15-.8,2.48-2.48,3.09,3.66.34,6.43,3.12,6.65,6.3.17,2.38-1.11,4.73-3.26,6.13,3.82.7,6.16,3.61,5.87,6.13-.22,1.99-2.03,3.23-2.48,3.52,2.95,1.57,5.89,3.14,8.83,4.7,6.56,3.62,8.78,10.24,6.7,14.09Z"
      />
      <path d="m358.76,425.52c14.99-7.76,30.48-14.41,45.94-21.15,1.79-.75,4.12-1.89,5.98-2.42,8.42-2.76,17.66-1.64,25.69,1.85,0,0,5.9,2.19,5.9,2.19,3.95,1.45,7.92,2.87,11.86,4.48l-1.1,2.79c-3.91-1.45-7.88-2.82-11.86-4.22l-5.97-2.12c-5.55-2.37-11.68-3.76-17.71-3.06-4.01.34-7.98,1.71-11.65,3.35-15.58,6.42-31.09,12.93-47.08,18.3h0Z" />
      <path d="m351.31,418.09c8.54-7.42,17.86-13.75,27.07-20.28,4.26-2.89,9.68-7.34,14.54-9.12,5.47-2.22,11.53-3.1,17.41-2.39,9.13,1.68,18.11,4.97,25.9,10.05,3.04,1.62,6.23,3.31,9.65,3.83,1.68.3,3.43.25,5.52.63l-.58,2.94c-1.52-.26-3.47-.21-5.41-.5-5.64-.86-10.69-4.1-15.48-6.88-5.97-3.42-13.72-5.72-20.07-7-8.06-.93-16.38,1.11-23.05,5.8-11.65,7.77-23.18,16.21-35.49,22.91h0Z" />
      <path
        style={styles.two}
        d="m430.15,394.66c1.7-1.1,3.93-2.34,6.68-3.38,2.88-1.09,8.39-1.64,10.47-1.93"
      />
      <path
        style={styles.two}
        d="m420.39,421.61v8.7c-7.99,7.4-19.8,20.31-28.19,39.51-5,11.44-5.83,21.95-7.02,30.18,7.12,3.15,12.66,6.3,19.78,9.46v7.04h8.97l4.5,10.96,9.59-4.46,14.07,4.46c4.14-5.6,8.33-11.93,12.32-19.02,10.89-19.35,16.64-37.81,19.79-52.59-.14-.66-.38-1.62-.78-2.74-1.38-3.86-3.49-6.38-5.09-8.22-5.15-5.93-10.63-12.03-12.59-13.95"
      />
      <path
        style={styles.two}
        d="m340.33,410.76l-19.72-18.75c3.07-10.74,9.03-26.2,21.13-41.61,5.74-7.31,11.67-13.1,17.02-17.61,4.5,2.16,9,4.32,13.5,6.48l13.08,20.6"
      />
      <line
        style={styles.two}
        x1="358.76"
        y1="332.78"
        x2="375.52"
        y2="363.73"
      />
      <path
        style={styles.two}
        d="m372.26,365.22c-1.51-.55-14.55-5.08-24.65,2-10.67,7.48-14.1,25.15-4.96,40.3,3.12-3.27,6.5-6.63,10.16-10.03,4.73-4.4,9.38-8.35,13.84-11.89,2-1.56,4.09-3.12,6.26-4.7,2.91-2.1,5.76-4.05,8.54-5.87,1.88-1.49,5-4.09,9.59-4.89,11.9-2.09,23.62.18,23.58.4-.01.08-1.87-.08-9.59-1.09"
      />
      <path d="m396.86,497.51c3.13-22.43,12.52-44.37,27.7-61.29-6.1,9.51-11.77,19.2-16.44,29.47-4.76,10.18-8.2,21.19-11.26,31.82h0Z" />
      <path d="m404.92,499.99c3.13-22.43,12.52-44.37,27.7-61.29-6.1,9.51-11.77,19.2-16.44,29.47-4.76,10.18-8.2,21.19-11.26,31.82h0Z" />
      <path d="m416.95,499.99c2.51-19.19,10.59-38.06,23.79-52.33-6.41,10.21-12.34,20.63-16.83,31.81-2.8,6.62-4.81,13.82-6.96,20.52h0Z" />
      <path d="m422.64,505.08c2.48-18.65,10.5-36.93,23.51-50.63-5.04,7.92-9.81,15.89-13.79,24.37-4.07,8.38-6.98,17.54-9.72,26.26h0Z" />
      <path d="m428.85,508.96c2.28-17.6,9.88-34.88,22.24-47.72-4.72,7.49-9.22,15-12.99,22.99-3.86,7.89-6.61,16.54-9.25,24.73h0Z" />
      <path d="m434.08,513.63c2.03-16.1,9.08-31.94,20.58-43.5-4.29,6.86-8.44,13.69-11.93,20.97-3.58,7.18-6.13,15.09-8.65,22.53h0Z" />
    </g>
  );
};

export default MoneyCalling;
