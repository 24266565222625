const USA = () => {
  const styles = {
    one: {
      fill: "#2d416b",
    },
    two: {
      fill: "#342c33",
    },
    three: {
      fill: "#f2f2f2",
    },
    four: {
      fill: "none",
      stroke: "#2d416b",
      strokeMiterLimit: 10,
      strokeWidth: 3,
    },
    five: {
      fill: "#de4e44",
    },
  };

  return (
    <g>
      <g id="USA_Jersey" data-name="USA Jersey">
        <path
          style={styles.three}
          d="m673.05,774.99c-10.77,9.78-27.1,22.99-49.05,35.01-33.44,18.31-66.72,26.66-95.49,29.93-30.81,3.51-56.43,1.19-71.51-.93-6.61-.93-12.97-2.07-19.07-3.37-42.6-9.09-72.54-26.03-84.93-33.63-30.34-18.6-51.24-39.78-64.22-55.07-.02-5.23.1-10.75.4-16.52.39-7.56,1.1-15.55,2.22-23.92l.15-1.11c1.09-7.82,2.54-15.97,4.45-24.38.01-.02.01-.05.02-.07,2.36-10.4,5.15-20.03,8.13-28.84,22.39-19.49,54.11-42.4,95.85-60.09,9.39-3.98,18.52-7.36,27.3-10.26,2.25-.74,4.47-1.45,6.67-2.13.6-.19,1.19-.37,1.78-.53h.03c.75-.25,1.49-.47,2.23-.68,3.63-1.08,7.19-2.07,10.68-2.98-.23.26-.46.53-.7.79-.18.21-.36.41-.54.62-.98,1.11-1.96,2.21-2.94,3.32-.08.1-.17.19-.26.29-.49.56-.98,1.13-1.48,1.69-.26.29-.51.58-.77.87-.35,1.67-.65,4.18,0,7,.15.66.34,1.29.58,1.9.3.8.67,1.57,1.1,2.29,1.14,1.96,2.68,3.63,4.41,5.07h0c1.23,1.04,2.57,1.95,3.92,2.77.36.22.73.43,1.09.63.02.01.04.02.06.04,1.07.59,2.13,1.13,3.15,1.63,1.32.63,2.57,1.18,3.68,1.67.44.2.89.39,1.33.56.02,0,.03.01.05.02,12.36,5.05,23.37,3.72,25.62,3.42,1.19-.16,2.4-.37,3.63-.62.01,0,.03,0,.04-.01,1.05-.21,2.12-.46,3.19-.74.04-.02.07-.02.1-.03,3.17-.84,6.34-1.94,9.31-3.24.48-.2.95-.42,1.41-.63.15-.07.3-.14.44-.21,2.34-1.11,4.51-2.37,6.42-3.7,2.09-1.45,3.85-3.03,5.13-4.65.02-.02.03-.04.04-.06.27-.35.53-.7.74-1.05,1.09-1.65,1.64-3.35,1.55-5.06-.08-1.3-.57-3.29-2.84-5.66-.28-.29-.59-.59-.93-.89-.52-.48-1.11-.96-1.79-1.45-.32-.25-.67-.49-1.03-.73-.13-.08-.26-.18-.4-.26h-.01s-.07-.06-.11-.08c.17.02.34.05.51.07.26.04.52.07.79.11h.01c.27.04.53.07.8.11.09.02.19.03.29.05h.01c.07.01.14.02.22.03h.01c.43.06.87.12,1.31.19,1.87.28,3.83.59,5.85.95,1.05.18,2.12.37,3.21.57.7.13,1.42.27,2.14.41.56.11,1.12.22,1.69.34,2.48.5,5.03,1.06,7.66,1.68,2.38.57,4.82,1.18,7.31,1.85.9.24,1.81.49,2.72.75,19.7,5.56,42.55,14.71,65.58,29.96,17.07,11.3,28.71,24.07,39,35,2.88,9.41,6.2,19.45,9.23,30.26,2.06,7.3,3.98,14.95,5.54,22.99.11.48.2.96.28,1.45.34,1.75.66,3.51.95,5.3.97,5.88,1.81,11.64,2.52,17.25,2.06,16.15,3.11,31.15,3.53,44.74Z"
        />
        <path
          style={styles.one}
          d="m558,632.71c-16.18-5.19-39.95-10.79-69-10.71-34.4.1-61.83,8.14-79.03,14.68,11.23-14.16,22.47-28.33,33.71-42.49.07-.09.13-.17.2-.26,1.1,1.61,2.86,3.47,5.62,5.57,13.19,10.03,29.39,10.6,29.39,10.6,2.76.1,5.41-.01,7.94-.27,2.46-.26,4.81-.67,7.03-1.2.04-.02.07-.02.1-.03,3.17-.84,6.34-1.94,9.31-3.24.48-.2.95-.42,1.41-.63.15-.07.3-.14.44-.21,6.05-3.08,10.08-6.81,11.4-9.52.07-.15.17-.32.29-.53.21.19.43.39.64.59,13.51,12.55,27.04,25.1,40.55,37.65Z"
        />
        <path
          style={styles.two}
          d="m444.64,580.44c-.75.92-1.42,1.89-1.89,2.88v-.45l.02-.74.03-1.67,1.45-.02h.39Z"
        />
        <rect style={styles.two} x="512" y="581" width="2" height="1" />
        <path d="m450.27,570.05c-.15,5.06-.36,8.4-5.06,11.18-.82.55-1.87,2.31-2.04,3.84,0,0,.02-.18.02-.18,0,.26-.13.59-.31.77l.28-.54-.14,1v-.12s0,.08,0,.08v.04s0,.05,0,.05c-.79,5.19,2.83,9.23,6.77,12.18,4.07,3.1,8.67,5.51,13.51,7.24,14.91,5.52,31.19,4.14,44.51-3.92,3.16-2.02,6.29-4.31,7.99-7.68.77-1.33,1.19-2.43,1.08-3.84-.42-3.58-3.55-6.08-6.49-7.86,0,0,.98-1.74.98-1.74,3.77,2,7.94,6.04,7.68,10.32-.88,8.13-10.4,13.55-17.2,16.41-7.57,3.05-15.32,4.35-23.72,3.96-10.68-.96-21.15-4.6-29.7-11.16-4.72-3.6-8.36-8.2-7.48-14.2,0,0,.16-1.14.16-1.14l.28-.54c-.18.15-.3.46-.29.69.28-1.94,1.15-3.91,2.74-5.18.31-.22,1.89-1.28,2.46-1.85,1.49-1.33,1.83-3.08,2.39-4.98.3-.99.63-1.96,1.01-2.93l.54.11h0Z" />
        <path
          style={styles.four}
          d="m451.5,666.5h61v55c-2.07,2.29-4.4,4.65-7,7-6.96,6.29-13.94,10.78-20,14-5.54-3.2-11.65-7.15-18-12-6.21-4.74-11.52-9.48-16-13.89v-50.11Z"
        />
        <polygon
          style={styles.five}
          points="458 694 461 696 461 721.08 458 716.67 458 694"
        />
        <polygon
          style={styles.five}
          points="466 697 469 699.14 469 726 466 721.29 466 697"
        />
        <polygon
          style={styles.five}
          points="474 701 477 703.36 477 733 474 727.8 474 701"
        />
        <polygon
          style={styles.five}
          points="509 695 506 697 506 722.08 509 717.67 509 695"
        />
        <polygon
          style={styles.five}
          points="501 698 498 700.14 498 727 501 722.29 501 698"
        />
        <polygon
          style={styles.five}
          points="494 701 491 703.36 491 733 494 727.8 494 701"
        />
        <rect style={styles.five} x="482" y="704" width="4" height="30" />
        <path
          style={styles.five}
          d="m329,725c-5.41,1.12-11.08,2.14-17,3-7.94,1.16-15.56,1.94-22.82,2.41.39-7.56,1.1-15.55,2.22-23.92l.15-1.11c9.15,1.95,20.09,5.82,30.45,13.62,2.59,1.95,4.92,3.97,7,6Z"
        />
        <path
          style={styles.one}
          d="m345,718l-16,7c-4.89-3.33-10.55-6.76-17-10-7.27-3.66-14.23-6.41-20.6-8.51l.15-1.11c1.09-7.82,2.54-15.97,4.45-24.38.01-.02.01-.05.02-.07l42.98,21.07,6,16Z"
        />
        <path
          style={styles.five}
          d="m669.52,730.25c-6.56-.48-13.42-1.21-20.52-2.25-5.92-.86-11.59-1.88-17-3,2.08-2.03,4.41-4.05,7-6,9.01-6.78,18.45-10.59,26.77-12.75.11.48.2.96.28,1.45.34,1.75.66,3.51.95,5.3.97,5.88,1.81,11.64,2.52,17.25Z"
        />
        <path
          style={styles.one}
          d="m666.05,707.7c-5.37,1.92-11.1,4.31-17.05,7.3-6.45,3.24-12.11,6.67-17,10l-16-7,6-16,38.23-18.74c2.06,7.3,3.98,14.95,5.54,22.99.11.48.2.96.28,1.45Z"
        />
      </g>
      <g id="Shoulder">
        <g>
          <path d="m289.61,748.67c-.91-1.03-1.83-2.05-2.71-3.1-.16-.19-.28-.51-.27-.76.05-2.47.11-4.94.2-7.41.05-1.47.14-2.94.27-4.41.38-4.26.69-8.53,1.2-12.78.63-5.25,1.33-10.5,2.18-15.72.92-5.63,2.04-11.23,3.07-16.84.73-3.99,1.22-8.03,2.23-11.95,1.74-6.77,3.67-13.51,5.8-20.17.76-2.37,2.14-4.61,3.59-6.66,1.05-1.49,2.62-2.65,4.07-3.82,6.08-4.88,12.09-9.86,18.34-14.52,4.77-3.55,9.8-6.77,14.82-9.97,13.26-8.43,27.33-15.36,41.35-22.38,13.87-6.94,28.29-12.49,43.1-17.07,6.81-2.11,13.66-4.07,20.67-5.5-.25,1.22-.39,1.42-1.42,1.69-13.06,3.43-25.64,8.26-38.17,13.21-8.35,3.3-16.39,7.25-24.35,11.37-6.67,3.45-13.39,6.81-19.95,10.47-6.61,3.68-13.14,7.53-19.55,11.57-8.21,5.17-16.25,10.61-24.38,15.91-4.23,2.76-8.05,6.02-11.92,9.25-1.81,1.51-2.89,3.25-3.44,5.46-1.94,7.73-3.97,15.45-5.84,23.2-1.14,4.69-2.15,9.42-3.07,14.16-.85,4.41-1.53,8.86-2.21,13.3-.68,4.45-1.32,8.9-1.89,13.37-.27,2.12-.34,4.26-.52,6.39-.31,3.75-.63,7.5-.94,11.25-.05.56,0,1.12-.02,1.68-.07,2.35-.19,4.71-.22,7.06-.01,1.19.13,2.38.21,3.57-.08.05-.15.1-.23.16Z" />
          <path d="m671.16,776.11c0-4.67.12-9.14-.03-13.6-.17-4.96-.57-9.92-.92-14.88-.2-2.89-.43-5.77-.74-8.65-.31-2.86-.75-5.71-1.14-8.57-.48-3.48-.94-6.96-1.49-10.43-.6-3.77-1.25-7.53-1.97-11.28-1.13-5.83-2.24-11.66-3.55-17.45-1.76-7.74-3.63-15.46-5.61-23.15-1.27-4.93-3.11-9.67-5.69-14.09-.28-.47-.65-.91-1.05-1.29-2.43-2.31-4.84-4.63-7.32-6.88-3.68-3.35-7.31-6.75-11.14-9.92-5.38-4.47-10.81-8.9-16.42-13.08-11.22-8.36-23.42-15.08-36.09-20.98-9.9-4.61-20-8.7-30.39-12.06-7.93-2.57-16.05-4.38-24.18-6.14-4.02-.87-8.05-1.74-12.06-2.64-.64-.14-1.24-.44-1.85-.66l.06-.31c2.3.29,4.62.48,6.9.9,4.7.87,9.37,1.93,14.07,2.79,7,1.29,13.88,3.04,20.66,5.17,8.46,2.65,16.72,5.82,24.85,9.37,9.84,4.3,19.41,9.09,28.57,14.7,7.87,4.82,15.45,10.07,22.57,15.95,4.99,4.12,9.86,8.38,14.76,12.61,2.38,2.06,4.85,4.05,6.96,6.36,1.57,1.73,3.02,3.79,3.84,5.96,4.11,10.81,7.26,21.92,9.88,33.18,1.93,8.31,3.77,16.64,5.47,25,1.07,5.28,1.85,10.63,2.69,15.96.7,4.44,1.34,8.9,1.89,13.36.36,2.91.59,5.84.74,8.76.37,7.24.63,14.48.99,21.71.05,1.07-.28,1.82-1.08,2.46-.68.54-1.33,1.11-2.17,1.81Z" />
        </g>
      </g>
    </g>
  );
};

export default USA;
