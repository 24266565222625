import { Grid, Menu, Typography } from "@mui/material";
import { BiX } from "react-icons/bi";
import { card, lightGrey, text } from "../../utils/themeContstants";
import CircleIconButton from "./CircleIconButton";
import "./styles/ScrollBar.css";

const NewCustomMenu = ({
  anchor,
  handleClose,
  children,
  horizontalOrigin,
  verticalOrigin,
  minWidth = null,
  title = null,
  rightAddOns,
  backgroundColor = card,
}) => {
  const styles = {
    topContainer: {
      width: "100%",
      paddingLeft: 1,
    },
    bottomContainer: {
      width: "100%",
      maxHeight: "calc(85vh)",
      overflowY: "auto",
    },
    container: {
      borderRadius: 8,
      boxShadow: "0px 1px 5px 1px rgba(0,0,0,60%)",
      backgroundColor,
      minWidth,
      marginTop: 6,
      paddingBottom: 0,
      overflow: "hidden",
      maxHeight: 897,
    },
    header: {
      fontSize: 22,
      fontWeight: 700,
      color: text,
    },
  };

  return (
    <Menu
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={handleClose}
      PaperProps={{ style: styles.container }}
      anchorOrigin={{ vertical: verticalOrigin, horizontal: horizontalOrigin }}
      transformOrigin={{ vertical: "top", horizontal: horizontalOrigin }}
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 1 }}
        sx={{
          padding: 1,
          paddingBottom: 0,
        }}
      >
        <Grid item sx={styles.topContainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography sx={styles.header}>{title}</Typography>
            </Grid>

            <Grid item>
              <CircleIconButton onClick={handleClose} size="small">
                <BiX style={{ fontSize: 26, color: lightGrey }} />
              </CircleIconButton>
            </Grid>

            {/* {rightAddOns && rightAddOns?.length > 0 && (
              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  {rightAddOns?.map((addon) => ({ addon }))}
                </Grid>
              </Grid>
            )} */}
          </Grid>
        </Grid>

        <Grid item sx={styles.bottomContainer} className="scroll">
          {children}
        </Grid>
      </Grid>
    </Menu>
  );
};

export default NewCustomMenu;
