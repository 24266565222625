import Blinding from "./types/Blinding";
import Chain from "./types/Chain";
import Choker from "./types/Choker";
import DogTag from "./types/DogTag";
import EightyEightChain from "./types/EightyEightChain";
import Jordan from "./types/Jordan";
import OnPause from "./types/OnPause";
import SilverChain from "./types/SilverChain";
import Yeezy from "./types/Yeezy";
import Cleats from "../clothes/types/world_cup/Cleats";
import Bandana from "./types/Bandana";
import Scarf from "./types/Scarf";
import Cross from "./types/Cross";
import { getColor } from "../../utils/getColor";

const Necklace = ({ type, skinColor }) => {
  switch (type) {
    case "choker_necklace":
      return <Choker />;
    case "cuban_chain_necklace":
      return <Chain />;
    case "cuban_chain_silver_necklace":
      return <SilverChain />;
    case "eighty_eight_chain_necklace":
      return <EightyEightChain />;
    case "jordan_necklace":
      return <Jordan />;
    case "yeezy_necklace":
      return <Yeezy />;
    case "dog_tag_necklace":
      return <DogTag />;
    case "on_pause":
      return <OnPause />;
    case "blinding":
      return <Blinding />;
    case "wc_cleats":
      return <Cleats />;
    case "bandana":
      return <Bandana />;
    case "scarf":
      return <Scarf skinColor={getColor(skinColor)} />;
    case "cross":
      return <Cross />;
    default:
      return null;
  }
};

export default Necklace;
