import axios from "axios";
import { API_ROUTE, errMessage } from "../utils/constants";

export const renewTokens = async (token) => {
  return await axios
    .post(`${API_ROUTE}/user/renew`, { refresh_token: token })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getUserAuth = async (useMiddleware) => {
  return await useMiddleware
    .get(`${API_ROUTE}/user/me/auth`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
