const SwordOne = () => {
  const styles = {
    one: {
      fill: "#8c8b8b",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "none",
    },
    three: {
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      fill: "none",
    },
    four: {
      stroke: "#000",
      fill: "#666565",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },
    five: {
      fill: "#3d3c3c",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },
    six: {
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      fill: "#9e9d9d",
    },
    seven: {
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      fill: "#302e2e",
    },
    eight: {
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      fill: "#c64646",
    },
    nine: {
      fill: "#d65b5b",
    },
    ten: {
      fill: "#b5b4b4",
    },
  };

  return (
    <g>
      <polygon
        style={styles.six}
        points="291.5 509.5 347.11 617.6 394.29 592.97 342.9 483.74 291.5 509.5"
      />
      <path
        style={styles.four}
        d="M335.5,456.5l-49.29,23.85-12.71,6.15s-5.64,6.38-4,14c1.36,6.34,6.83,11.3,13.71,12.33,22.1-10.78,44.19-21.55,66.29-32.33,.19-.32,4.52-8.17,0-16-2.88-4.98-8.24-8.05-14-8Z"
      />
      <path
        style={styles.five}
        d="M286.5,466.5c-.48,1.77-.95,4.16-1,7-.05,2.75,.31,5.09,.71,6.85,11.82-5.72,23.63-11.43,35.45-17.15-.94-1.37-2.29-3.04-4.15-4.7-2.19-1.95-4.35-3.2-6-4-8.33,4-16.67,8-25,12Z"
      />
      <polygon
        style={styles.seven}
        points="273.5 385.5 256.5 393.5 288.59 465.5 310.38 455.04 273.5 385.5"
      />
      <path
        style={styles.four}
        d="M245.09,385.5l11.41,8,17-8-3-15c-1.69,2.17-3.98,4.65-7,7-6.98,5.42-14.19,7.28-18.41,8Z"
      />
      <path
        style={styles.eight}
        d="M227.5,380.3c1.6,1.25,4.28,3.05,8,4.2,4.03,1.24,7.5,1.19,9.59,1,5.45-1.23,14.59-4.2,22.41-12,2.02-2.01,3.66-4.06,5-6,1.32-1.8,2.93-4.48,4-8,.56-1.85,.85-3.55,1-5-1.92-3.08-5.37-7.77-11-12-5.38-4.04-10.58-6.04-14-7.07-1.97-.38-8.26-1.33-15,2.07-6.04,3.04-8.99,7.84-10,9.67-1.48,2.88-3.76,8.25-4,15.33-.29,8.45,2.47,14.83,4,17.8Z"
      />
      <path
        style={styles.one}
        d="M317.5,513.5c-.14-2.45,1-4.77,3-6,2.44-1.5,5.76-1.13,8,1l46.53,94.53-9.93,5.18-47.6-94.71Z"
      />
      <line
        style={styles.three}
        x1="270.01"
        y1="502.26"
        x2="348.74"
        y2="463.32"
      />
      <path
        style={styles.three}
        d="M227.5,380.3c16.67-8.6,33.33-17.2,50-25.8"
      />
      <path
        style={styles.two}
        d="M277.5,393.04c.11,2.32,.05,8.07-3.5,13.96-2.61,4.33-5.97,6.83-7.9,8.05"
      />
      <path
        style={styles.two}
        d="M283.97,405c.11,2.43,.06,8.43-3.68,14.59-2.75,4.52-6.27,7.13-8.3,8.41"
      />
      <path
        style={styles.two}
        d="M291.02,418.6c.12,2.47,.06,8.58-4,14.84-2.98,4.6-6.81,7.26-9.02,8.56"
      />
      <path
        style={styles.two}
        d="M299.74,435.1c.13,2.52,.06,8.76-4.22,15.16-3.15,4.7-7.19,7.41-9.52,8.74"
      />
      <path
        style={styles.nine}
        d="M274.17,358.39c-.68,2.14-4.36,12.93-16.45,19.22-11.71,6.1-23.01,3.65-25.42,3.08,13.96-7.43,27.92-14.87,41.87-22.3Z"
      />
      <path
        style={styles.nine}
        d="M258.06,346.35c-5.26-3.75-12.4-4.91-18.42-2.11-4.59,2.09-8.33,6.15-10.4,10.82-.7,1.59-1.24,3.27-1.62,4.99-.01,.07-.08,.11-.15,.1,.02-3.48,1.5-7.21,3.27-10.3,6.14-10.55,19.27-13.48,29.78-7.44,2.77,1.66,.25,5.72-2.47,3.94h0Z"
      />
      <polygon
        style={styles.ten}
        points="298.64 508.32 349.91 606.58 302.4 506.28 298.64 508.32"
      />
      <polygon
        style={styles.ten}
        points="334.25 489.63 385.52 587.89 338 487.59 334.25 489.63"
      />
    </g>
  );
};

export default SwordOne;
