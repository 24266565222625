import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { numFormatter } from "../../utils/helpers";
import {
  cardDark,
  cardVeryLight,
  doubtedBlue,
  small,
  text,
  yellow,
} from "../../utils/themeContstants";
import RivoxCoin from "./RivoxCoin";

const Input = styled.input`
  ::placeholder,
  ::-webkit-input-placeholder {
    color: none;
    font-weight: 700;
    font-size: 15px;
  }
  :-ms-input-placeholder {
    color: none;
  }
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  font-size: 15px;
  padding-left: 0px;
  padding-right: 8px;
  outline: none;
  font-family: "Inter";
  font-weight: 700;
  min-width: 100%;
  min-height: 100%;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border: none;
  &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "text")};
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const CurrencyInput = ({
  value,
  onChange,
  type = "number",
  min,
  max,
  step = 0,
  disabled = false,
  autoFocus = true,
}) => {
  const [focused, setFocused] = useState(false);

  const onBlur = () => {
    setFocused(false);
  };

  const onFocus = () => {
    setFocused(true);
  };

  const styles = {
    container: {
      width: "100%",
      backgroundColor: cardDark,
      borderRadius: 2,
      paddingLeft: 2,
      paddingRight: 2,
      height: 50,
      border: `2px solid ${focused ? doubtedBlue : cardVeryLight}`,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        sx={{ minHeight: "100%" }}
      >
        <Grid item>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            gap={{ xs: 1.5 }}
          >
            <RivoxCoin size={25} />

            <Grid item>
              <Typography
                sx={{ fontSize: small, fontWeight: 700, color: text }}
              >
                $
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 35,
          }}
        >
          <Input
            backgroundColor={cardDark}
            color={text}
            disabled={disabled}
            type={type}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            autoFocus={autoFocus}
            min={min}
            max={max}
            step={step}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CurrencyInput;
