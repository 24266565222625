import { Grid } from "@mui/material";
import ReactLoading from "react-loading";
import { doubtedBlue, yellow } from "../../utils/themeContstants";
import logo from "../../assets/rivox-logo.svg";
import background from "../../assets/background.png";

const LoadingScreen = ({ size }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="start"
      direction="column"
      sx={{
        width: "100%",
        minHeight: "100vh",
        // backgroundImage: `url(${background})`,
        // backgroundAttachment: "fixed",
        // backgroundSize: "cover",
        paddingTop: 10,
      }}
    >
      <Grid item sx={{ width: "100%" }}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item sx={{ marginBottom: -5 }}>
            <img
              src={logo}
              alt="rivox-logo"
              draggable={false}
              style={{ width: size * 2 }}
            />
          </Grid>
          <Grid item>
            <ReactLoading type="cylon" color={doubtedBlue} width={size} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoadingScreen;
