import { Grid } from "@mui/material";
import { useState } from "react";
import {
  cardLight,
  cardVeryLight,
  doubtedBlue,
} from "../../../utils/themeContstants";
import Hover from "../../custom/Hover";

const NavbarNavButtons = ({ onClick, label, icon, selected, anchorId }) => {
  const [hovered, setHovered] = useState(false);
  const [hoverRef, setHoverRef] = useState(null);

  const styles = {
    container: {
      height: 56,
      maxWidth: 126,
      padding: 1,
      flexGrow: 1,
      borderTop: "3px solid transparent",
      borderBottom: selected
        ? `4px solid ${doubtedBlue}`
        : `4px solid transparent`,
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "all .1s ease-in-out",
      position: "relative",
      "&:hover": {
        cursor: "pointer",
      },
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onClick={(e) => {
        setHovered(false);
        onClick(e);
      }}
      ref={setHoverRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      id={anchorId}
    >
      {hovered && !selected && (
        <Grid
          item
          sx={{
            backgroundColor: cardVeryLight,
            width: "100%",
            borderRadius: 2,
            position: "absolute",
            top: 1,
            bottom: 1,
            zIndex: -1,
          }}
        />
      )}
      {hovered && <Hover label={label} placement="bottom" refEl={hoverRef} />}

      <Grid
        item
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {icon}
      </Grid>
    </Grid>
  );
};

export default NavbarNavButtons;
