const TShirt = ({ color }) => {
  const styles = {
    one: {
      fill: color,
    },
  };
  return (
    <g>
      <path
        style={styles.one}
        d="M288.78,746.93c12.97,15.29,33.87,36.48,64.22,55.07,14.17,8.69,51.27,29.59,104,37,29.16,4.1,97.74,8.93,167-29,21.95-12.02,38.28-25.23,49.05-35.01-.56-18.31-2.28-39.19-6.05-61.99-3.77-22.78-8.85-43.09-14.21-60.6-10.29-10.92-23.72-23.1-40.79-34.4-38.48-25.48-76.44-33.93-100.11-37.07,6.01,3.85,7,7.17,7.11,9.07,.51,9.09-17.54,18.05-32,20-2.33,.31-14.09,1.73-27-4-5.91-2.62-15.91-6.96-18-16-.65-2.82-.35-5.33,0-7,2.23-2.53,4.46-5.05,6.69-7.58-15,3.92-31.42,9.26-48.69,16.58-41.74,17.69-73.46,40.6-95.85,60.09-2.99,8.83-5.79,18.48-8.15,28.91-5.61,24.73-7.29,47.17-7.22,65.93Z"
      />
      <path d="M450.27,570.05c-.01,1.33-.14,2.64-.28,3.95l-.12,.98c-.03,.33-.12,.72-.22,1.06-.2,.71-.51,1.4-.93,2.02-.81,1.27-2.06,2.17-3.12,2.89-.52,.36-.86,.67-1.21,1.11-.31,.44-.6,.92-.79,1.46-.41,1.07-.59,2.29-.62,3.5-.02,.61,0,1.22,.06,1.85,.05,.59,.15,1.15,.32,1.7,.69,2.22,2.35,4.17,4.18,5.87,1.85,1.71,3.95,3.2,6.12,4.54,2.15,1.38,4.47,2.48,6.82,3.51,4.73,1.97,9.73,3.33,14.8,4.05,1.25,.18,2.58,.33,3.76,.38,1.29,0,2.58,.03,3.86,0,2.56-.12,5.11-.35,7.63-.82,5.03-.91,9.97-2.48,14.48-4.86,2.25-1.18,4.45-2.51,6.39-4.11,.49-.39,.94-.83,1.41-1.24,.45-.43,.86-.89,1.29-1.33l.55-.71c.2-.23,.37-.47,.5-.72,.26-.42,.66-1.15,.94-1.65,.57-1.05,.89-2.1,.81-3.18-.05-1.09-.45-2.17-1.07-3.16-1.25-1.99-3.27-3.61-5.41-4.86l.98-1.74c1.2,.67,2.33,1.44,3.39,2.33,1.06,.89,2.04,1.91,2.83,3.13,.78,1.21,1.35,2.65,1.44,4.18,.14,1.54-.35,3.09-1.02,4.33-.35,.65-.58,1.07-.99,1.77-.18,.33-.38,.62-.6,.89l-.65,.84c-.47,.5-.93,1.03-1.43,1.5-.51,.45-1,.93-1.53,1.36-2.09,1.74-4.38,3.17-6.75,4.42-4.75,2.51-9.9,4.14-15.15,5.1-2.63,.49-5.29,.73-7.96,.86-1.33,.04-2.66,0-3.98,.01-1.44-.06-2.71-.21-4.05-.4-10.51-1.56-20.84-5.62-28.88-12.86-1.94-1.86-3.84-4.02-4.72-6.84-.21-.7-.34-1.44-.39-2.15-.05-.68-.07-1.38-.05-2.07,.05-1.39,.25-2.8,.79-4.18,.25-.69,.63-1.35,1.09-1.96,.45-.6,1.11-1.16,1.67-1.53,1.1-.73,2.01-1.41,2.67-2.33,.32-.46,.61-.95,.82-1.48,.11-.28,.18-.52,.27-.84l.26-.95c.37-1.26,.76-2.51,1.26-3.75l.54,.11Z" />
    </g>
  );
};

export default TShirt;
