import { CircularProgress, Grid } from "@mui/material";
import { useState } from "react";
import { cardVeryLight, text } from "../../utils/themeContstants";
import Hover from "./Hover";

const CustomIconButton = ({ icon, onClick, label, loading, opacity }) => {
  const [hovered, setHovered] = useState(false);
  const [ref, setRef] = useState(null);

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      backgroundColor: "transparent",
      borderRadius: 1,
      padding: 0.5,
      "&:hover": {
        cursor: loading ? "not-allowed" : "pointer",
        backgroundColor: loading
          ? "transparent"
          : opacity
          ? "rgba(52, 56, 74, .5)"
          : cardVeryLight,
      },
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      ref={setRef}
      onClick={loading ? null : onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered && label && (
        <Hover label={label} placement="bottom" refEl={ref} />
      )}
      {!loading && icon}
      {loading && <CircularProgress size={18} sx={{ color: text }} />}
    </Grid>
  );
};

export default CustomIconButton;
