const DogTag = () => {
  const styles = {
    one: {
      fill: "#f4f4f4",
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#d8d7d6",
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#cccbca",
    },
    five: {
      strokeMiterLimit: 10,
      stroke: "#cccbca",
    },
    six: {
      fill: "#d8d7d6",
    },
    seven: {
      fill: "#dbdad9",
    },
  };

  return (
    <g>
      <g>
        <path
          style={styles.four}
          d="M465.81,660.13l1.41,27.22c.03,.62,.24,1.21,.62,1.7,1.35,1.74,4.99,5.6,10.77,5.61,4.81,0,9.26-2.65,11.66-6.88,.27-.48,.38-1.05,.35-1.6l-1.41-27.24c-.03-.61-.24-1.2-.61-1.69-1.3-1.7-4.73-5.41-10.22-5.64-4.95-.21-9.67,2.48-12.21,6.91-.28,.49-.39,1.05-.36,1.61Z"
        />
        <path
          style={styles.seven}
          d="M468.02,661l1.31,25.27c.03,.57,.21,1.12,.52,1.58,1.12,1.62,4.15,5.23,8.92,5.26,3.97,.03,7.63-2.41,9.59-6.33,.22-.45,.31-.97,.28-1.48l-1.31-25.29c-.03-.57-.2-1.11-.51-1.57-1.08-1.59-3.93-5.04-8.46-5.29-4.09-.22-7.97,2.25-10.05,6.35-.23,.45-.32,.97-.29,1.49Z"
        />
        <path
          style={styles.four}
          d="M480.88,651.97c1.33,.03,3.36,.24,5.6,1.22,1.08,.47,1.98,1.02,2.7,1.54,.27,.19,.44,.48,.52,.8,2.1,9.28,4.2,18.56,6.3,27.84,.05,.24,.05,.48-.03,.71-.47,1.45-1.47,3.75-3.57,5.85-4.04,4.02-9.16,4.26-10.42,4.29,1.19-.33,3.38-1.12,5.48-3.03,1.6-1.45,2.54-3,3.06-4.08,.1-.21,.14-.43,.13-.66l-1.46-28.07c-.01-.28-.11-.55-.28-.77-.6-.76-1.36-1.6-2.32-2.42-2.1-1.8-4.24-2.73-5.71-3.22Z"
        />
        <circle style={styles.one} cx="477.6" cy="655.65" r="1" />
      </g>
      <g>
        <circle style={styles.five} cx="491" cy="642" r=".5" />
        <circle style={styles.six} cx="491" cy="642" r=".5" />
      </g>
      <g>
        <circle style={styles.two} cx="448.27" cy="577.72" r="1.73" />
        <circle style={styles.three} cx="448.27" cy="577.72" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="448.73" cy="581.83" r="1.73" />
        <circle style={styles.three} cx="448.73" cy="581.83" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="449.51" cy="585.26" r="1.73" />
        <circle style={styles.three} cx="449.51" cy="585.26" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="450.51" cy="589.26" r="1.73" />
        <circle style={styles.three} cx="450.51" cy="589.26" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="451.51" cy="593.26" r="1.73" />
        <circle style={styles.three} cx="451.51" cy="593.26" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="452.51" cy="597.35" r="1.73" />
        <circle style={styles.three} cx="452.51" cy="597.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="453.51" cy="601.35" r="1.73" />
        <circle style={styles.three} cx="453.51" cy="601.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="454.51" cy="605.35" r="1.73" />
        <circle style={styles.three} cx="454.51" cy="605.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="455.51" cy="609.35" r="1.73" />
        <circle style={styles.three} cx="455.51" cy="609.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="456.51" cy="613.35" r="1.73" />
        <circle style={styles.three} cx="456.51" cy="613.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="458.51" cy="617.35" r="1.73" />
        <circle style={styles.three} cx="458.51" cy="617.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="460.51" cy="621.35" r="1.73" />
        <circle style={styles.three} cx="460.51" cy="621.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="462.51" cy="625.35" r="1.73" />
        <circle style={styles.three} cx="462.51" cy="625.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="464.51" cy="629.35" r="1.73" />
        <circle style={styles.three} cx="464.51" cy="629.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="466.51" cy="633.35" r="1.73" />
        <circle style={styles.three} cx="466.51" cy="633.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="468.51" cy="637.35" r="1.73" />
        <circle style={styles.three} cx="468.51" cy="637.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="470.51" cy="641.35" r="1.73" />
        <circle style={styles.three} cx="470.51" cy="641.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="472.51" cy="645.35" r="1.73" />
        <circle style={styles.three} cx="472.51" cy="645.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="474.51" cy="649.35" r="1.73" />
        <circle style={styles.three} cx="474.51" cy="649.35" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="477.79" cy="654.43" r="1.73" />
        <circle style={styles.three} cx="477.79" cy="654.43" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="481.79" cy="650.43" r="1.73" />
        <circle style={styles.three} cx="481.79" cy="650.43" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="484.79" cy="647.43" r="1.73" />
        <circle style={styles.three} cx="484.79" cy="647.43" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="487.33" cy="644.74" r="1.73" />
        <circle style={styles.three} cx="487.33" cy="644.74" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="490.33" cy="641.74" r="1.73" />
        <circle style={styles.three} cx="490.33" cy="641.74" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="511.33" cy="582.74" r="1.73" />
        <circle style={styles.three} cx="511.33" cy="582.74" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="511.33" cy="587" r="1.73" />
        <circle style={styles.three} cx="511.33" cy="587" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="511.33" cy="591" r="1.73" />
        <circle style={styles.three} cx="511.33" cy="591" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="510.33" cy="595" r="1.73" />
        <circle style={styles.three} cx="510.33" cy="595" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="509.33" cy="599" r="1.73" />
        <circle style={styles.three} cx="509.33" cy="599" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="508.33" cy="603" r="1.73" />
        <circle style={styles.three} cx="508.33" cy="603" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="507.33" cy="607" r="1.73" />
        <circle style={styles.three} cx="507.33" cy="607" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="506.33" cy="611" r="1.73" />
        <circle style={styles.three} cx="506.33" cy="611" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="504.33" cy="615" r="1.73" />
        <circle style={styles.three} cx="504.33" cy="615" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="502.33" cy="619" r="1.73" />
        <circle style={styles.three} cx="502.33" cy="619" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="500.33" cy="623" r="1.73" />
        <circle style={styles.three} cx="500.33" cy="623" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="498.33" cy="627" r="1.73" />
        <circle style={styles.three} cx="498.33" cy="627" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="496.33" cy="631" r="1.73" />
        <circle style={styles.three} cx="496.33" cy="631" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="494.33" cy="635" r="1.73" />
        <circle style={styles.three} cx="494.33" cy="635" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="492.33" cy="638" r="1.73" />
        <circle style={styles.three} cx="492.33" cy="638" r="1.73" />
      </g>
      <g>
        <circle style={styles.two} cx="476.31" cy="652.05" r="1.05" />
        <circle style={styles.three} cx="476.31" cy="652.05" r="1.05" />
      </g>
      <g>
        <circle style={styles.two} cx="478.99" cy="651.37" r="1.05" />
        <circle style={styles.three} cx="478.99" cy="651.37" r="1.05" />
      </g>
    </g>
  );
};

export default DogTag;
