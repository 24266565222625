import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  cardDark,
  doubtedBlue,
  purple,
  secondaryText,
  small,
  text,
} from "../../utils/themeContstants";
import AuthButton from "../custom/AuthButton";
import CustomModal from "../custom/CustomModal";

const LoginRegisterModal = ({ open, onClose }) => {
  const [termsHovered, setTermsHovered] = useState(false);
  const navigate = useNavigate();

  const styles = {
    width: {
      width: "100%",
    },
    meta: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 500,
      textAlign: "center",
      maxWidth: 400,
    },
  };

  return (
    <CustomModal
      title="Get Started"
      description="Start competing in one click."
      open={open}
      onClose={onClose}
      bottomContent={
        <Grid item>
          <Typography sx={styles.meta}>
            By accessing this site, I confirm that I am at least 18 years old
            and have read the{" "}
            <span
              style={{
                fontWeight: 700,
                color: termsHovered ? doubtedBlue : text,
                cursor: termsHovered ? "pointer" : "default",
              }}
              onMouseEnter={() => setTermsHovered(true)}
              onMouseLeave={() => setTermsHovered(false)}
              onClick={() => {
                navigate("/support/terms");
                onClose();
              }}
            >
              Terms of Service
            </span>
          </Typography>
        </Grid>
      }
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            <AuthButton type="google" />
            <AuthButton type="discord" />
            <AuthButton type="twitch" />
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default LoginRegisterModal;
