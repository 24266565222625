import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { updateWithdrawal } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { AccountTypeEnum, TransactionStatusTypeEnum } from "../../utils/enums";
import { numFormatter } from "../../utils/helpers";
import { cardLight } from "../../utils/themeContstants";
import CustomModal from "../custom/CustomModal";
import PrimaryButton from "../custom/PrimaryButton";
import RadioListItem from "../custom/RadioListItem";
import SecondaryButton from "../custom/SecondaryButton";

const MarkWithdrawModal = ({
  withdraw,
  open,
  onClose,
  withdrawals,
  setWithdrawals,
}) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();

  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleClose = () => {
    setLoading(false);
    setSelectedStatus(withdraw?.status);
    onClose();
  };

  const handleUpdate = () => {
    setLoading(true);
    updateWithdrawal(middleware, withdraw?._id, selectedStatus).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
        setSelectedStatus(withdraw?.status);
      } else {
        const index = withdrawals?.findIndex((w) => w?._id === withdraw?._id);
        let newWithdrawals = withdrawals;
        newWithdrawals?.splice(index, 1);
        setWithdrawals(newWithdrawals);
        updateStore({
          type: SET_SUCCESSES,
          payload: "Updated withdrawal!",
        });
        handleClose();
      }
    });
  };

  useEffect(() => {
    setSelectedStatus(withdraw?.status);
  }, [withdraw]);

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Updating ${withdraw?.username}'s withdrawal to ${
        withdraw?.account_used
      } for $${numFormatter().format(withdraw?.amount)}`}
      primaryButton={
        <PrimaryButton
          label="Update"
          type="green"
          disabled={selectedStatus === withdraw?.status}
          onClick={handleUpdate}
          loading={loading}
        />
      }
      secondaryButton={<SecondaryButton label="Close" onClick={handleClose} />}
      backgroundColor={cardLight}
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 0.5, width: "100%" }}
      >
        {store?.user?.account_type >= AccountTypeEnum.GOD && (
          <Grid item sx={styles.width}>
            <RadioListItem
              title={"Completed"}
              value={TransactionStatusTypeEnum.COMPLETED}
              selected={selectedStatus === TransactionStatusTypeEnum.COMPLETED}
              onClick={() =>
                setSelectedStatus(TransactionStatusTypeEnum.COMPLETED)
              }
            />
          </Grid>
        )}

        <Grid item sx={styles.width}>
          <RadioListItem
            title={"Failed"}
            value={TransactionStatusTypeEnum.FAILED}
            selected={selectedStatus === TransactionStatusTypeEnum.FAILED}
            onClick={() => setSelectedStatus(TransactionStatusTypeEnum.FAILED)}
          />
        </Grid>

        <Grid item sx={styles.width}>
          <RadioListItem
            title={"Canceled"}
            value={TransactionStatusTypeEnum.CANCELED}
            selected={selectedStatus === TransactionStatusTypeEnum.CANCELED}
            onClick={() =>
              setSelectedStatus(TransactionStatusTypeEnum.CANCELED)
            }
          />
        </Grid>

        <Grid item sx={styles.width}>
          <RadioListItem
            title={"Pending"}
            value={TransactionStatusTypeEnum.PENDING}
            selected={selectedStatus === TransactionStatusTypeEnum.PENDING}
            onClick={() => setSelectedStatus(TransactionStatusTypeEnum.PENDING)}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default MarkWithdrawModal;
