import "../styles/AngelDevil.css";

const AngelDevil = () => {
  return (
    <g>
      <g>
        <g>
          <g>
            <path
              class="angel_devil-4"
              d="m345.68,441.52c.2-.14,5.79-4.08,9.97-.19,0,0-5.78-.62-4.79,2.23,0,0,2.49-.74,4.94-1.04,2.11-.26,4.19-.2,4.61.92,0,0-1.35-.82-1.94.92,0,0,4.36.35,4.77,2.44,0,0-2.42-1.27-2.6.07,0,0,8.49,1.83,10.96,13.1,0,0-5.06-5.11-5.65-3.69,0,0,5.59,4.14,5.58,6.32l-4.01-2.7c-1.75-1.55-3.5-3.09-5.25-4.64l-4.6-1.28-.26.92-2.53-.38-1.66,1.19-2.41-3.45-3.44.23-.09,1.76-2.96-1.79-3.5,2.17.27-3.1-1.7,4.68-1.42-.59-1.77,2.08-1.68.33-.85,2.34-2.6.07,1.53,10.15-1.34-.18-.26.92-3.97-9.24c-2.41-.45-4.46.58-5.49,1.94l-2.87-4.67.46-2.7-1.3-1.67,3.16-3.91-1.08-1.1,9.97-4.15-5.59-.18,6.78-1.86-2.07-.79,6.08-1.18-.65-3.34,1.63.54s7.42-8.84,17.52-1.66l-10.99,1.7,4.44.44-1.35,2Z"
            />
            <path
              class="angel_devil-13"
              d="m388.07,599.54c2.82,2,3.01,3.56,2.99,4.21-.09,3.13-5.75,6.26-14.46,7.36.07-.7.23-2.98-1.22-5.19-2.01-3.05-5.44-3.46-5.93-3.51,2.31-.84,4.56-1.45,6.7-1.88,2.06-.42,4.03-.69,5.85-.83,2.27-.19,4.32-.21,6.06-.16Z"
            />
            <path
              class="angel_devil-11"
              d="m361.84,460.97c.45.19.77.54.85.98.16.81-.56,1.63-1.6,1.83s-2.01-.29-2.17-1.1.56-1.63,1.6-1.83c.26-.05.52-.06.76-.02.03,0,.05,0,.07.01.17.04.33.08.49.14Z"
            />
            <path
              class="angel_devil-13"
              d="m386.32,590.49l1.75,9.05c-1.74-.05-3.79-.04-6.06.16l-.85-6.65c.02-1.34.83-2.53,2.03-3,1.62-.64,3.05.39,3.13.45Z"
            />
            <path
              class="angel_devil-2"
              d="m386.01,588.9l.31,1.59c-.08-.06-1.51-1.09-3.13-.45-1.2.47-2.01,1.66-2.03,3,0,0-11.72-29.75-9.76-42.84l3.12-.34.34,1.75,11.15,37.29Z"
            />
            <path
              class="angel_devil-3"
              d="m350.11,486.91c-3.67.19-7.54-.61-11.13-2.34-5.52-2.66-10.41-7.54-12.98-14.42-3.08-.09-5.22-2.34-5.17-4.22.01-.62.27-1.28.73-1.88,1.03-1.36,3.08-2.39,5.49-1.94l3.97,9.24.26-.92,1.34.18-1.53-10.15,2.6-.07.85-2.34,1.68-.33,1.77-2.08,1.42.59,1.7-4.68-.27,3.1,3.5-2.17,2.96,1.79.09-1.76,3.44-.23,2.41,3.45,1.66-1.19,2.53.38.26-.92,4.6,1.28c1.75,1.55,3.5,3.09,5.25,4.64,2.18,10.61-2.25,20.91-9.95,24.99-2.27,1.2-4.82,1.86-7.47,2Zm-5.93-25.08c-1.04.2-1.76,1.02-1.6,1.83s1.13,1.3,2.17,1.1,1.76-1.02,1.6-1.83c-.08-.44-.4-.78-.85-.98-.15-.07-.32-.12-.49-.14-.02,0-.05-.01-.07-.01-.24-.03-.5-.03-.76.02Zm16.34-.99c-1.04.2-1.76,1.02-1.6,1.83s1.13,1.3,2.17,1.1,1.76-1.02,1.6-1.83c-.08-.44-.4-.78-.85-.98-.15-.07-.32-.12-.49-.14-.02,0-.05-.01-.07-.01-.24-.03-.5-.03-.76.02Z"
            />
            <path
              class="angel_devil-2"
              d="m362.26,515.77c-1.14-3.07-2.27-6.82-3.03-11.16-.42-2.39-5.41-5.4-8.47-7.94-.11-.29-.59-.64-1.12-1.01-.74-.73-1.22-1.42-1.24-2,2.04.42,4.04-.26,8.21-.3,0,0,13.15.5,15.58,28.74,0,0-.01,2.18-3.16,2.79-2.22-4.34-4.92-7.34-6.78-9.13Z"
            />
            <path
              class="angel_devil-10"
              d="m336.64,418.47c10.54-2.04,19.43-1.92,19.86.28s-7.77,5.64-18.31,7.68-19.43,1.92-19.86-.28,7.77-5.64,18.31-7.68Zm12.22,1.33c-.16-.81-5.26-.5-11.4.69s-10.99,2.81-10.83,3.62,5.26.5,11.4-.69,10.99-2.81,10.83-3.62Z"
            />
            <path
              class="angel_devil-13"
              d="m372.84,539.14l.65,5.35-4.23.6c-.4-2.05-1.51-3.73-2.94-4.69l6.53-1.27Z"
            />
            <path
              class="angel_devil-13"
              d="m371.4,550.21l-3.13.33c.96-1.42,1.37-3.4.98-5.45l4.23-.6,1.04,5.38-3.12.34Z"
            />
            <path
              class="angel_devil-13"
              d="m381.16,593.04l.85,6.65c-1.82.14-3.79.41-5.85.83,0,0-.38-1.58-.99-4.17.04,0,.07,0,.11,0,0-.18.76-1.95,2.03-3,1.05-.87,2.58-1,3.86-.31Z"
            />
            <path
              class="angel_devil-2"
              d="m377.3,593.36c-1.27,1.05-2.04,2.83-2.03,3-.04,0-.07,0-.11,0-2.6-10.98-9.31-40.17-8.41-44.33.59-.37,1.11-.88,1.52-1.49l3.13-.33c-1.96,13.09,9.76,42.84,9.76,42.84-1.28-.68-2.81-.56-3.86.31Z"
            />
            <path
              class="angel_devil-13"
              d="m368.27,550.54c-.41.61-.92,1.11-1.52,1.49-.45.28-.95.48-1.49.58-.66.13-1.33.1-1.96-.07-1.71-.44-3.24-1.86-4.06-3.82-.2-.5-.37-1.03-.48-1.59-.35-1.8-.08-3.54.64-4.89s1.89-2.34,3.34-2.62c1.24-.24,2.49.07,3.56.8,1.43.96,2.54,2.64,2.94,4.69s-.01,4.02-.98,5.45Z"
            />
            <path
              class="angel_devil-13"
              d="m375.17,596.35c.61,2.59.99,4.17.99,4.17-2.14.43-4.39,1.04-6.7,1.88h0c-.53.19-1.06.4-1.59.61,0,0-.41-1.42-1.02-3.81.78-.71,2.16-1.77,4.16-2.45,2.12-.72,3.18-.52,4.17-.4Z"
            />
            <path
              class="angel_devil-2"
              d="m366.75,552.02c-.89,4.15,5.82,33.34,8.41,44.33-.99-.12-2.05-.31-4.17.4-2,.68-3.38,1.74-4.16,2.45-2.42-9.43-8.07-33.94-4.83-46.19h0c.42-.15.85-.3,1.29-.46h0c.64.15,1.3.18,1.96.05.54-.1,1.03-.3,1.49-.58Z"
            />
            <path
              class="angel_devil-13"
              d="m375.39,605.91c1.46,2.22,1.3,4.5,1.22,5.19-1.17.77-2.4,1.53-3.7,2.25-10.35,5.73-20.93,7.04-29.11,6.95,1.93-2.06,11.71-12.27,24.06-17.29.53-.22,1.06-.42,1.59-.61h0c.49.05,3.92.45,5.93,3.51Z"
            />
            <path
              class="angel_devil-11"
              d="m345.5,461.96c.45.19.77.54.85.98.16.81-.56,1.63-1.6,1.83s-2.01-.29-2.17-1.1.56-1.63,1.6-1.83c.26-.05.52-.06.76-.02.03,0,.05,0,.07.01.17.04.33.08.49.14Z"
            />
            <path
              class="angel_devil-3"
              d="m350.11,486.91l-1.72,6.74s0,0-.01,0c-.3-.06-.6-.15-.91-.26-3.7-1.38-6.84-3.46-9.37-5.16l.88-3.67c3.6,1.74,7.46,2.54,11.13,2.34Z"
            />
            <path
              class="angel_devil-13"
              d="m359.24,548.72c.82,1.96,2.35,3.38,4.06,3.82h0c-.43.17-.86.33-1.29.47h0c-2.62.9-5.18,1.58-8.62,2.34-5.59,1.22-10.65,1.72-14.93,1.86.98-1.62,1.68-3.56,2.16-5.62l18.42-2.81.19-.06Z"
            />
            <path
              class="angel_devil-2"
              d="m350.75,496.67c3.06,2.54,8.05,5.54,8.47,7.94.77,4.34,1.9,8.09,3.03,11.16,1.85,1.79,4.56,4.79,6.78,9.13.39.76.76,1.55,1.12,2.39,1.87,4.48,2.45,8.5,2.62,11.24l.07.6-6.53,1.27c-1.07-.72-2.32-1.04-3.56-.8-1.45.28-2.62,1.26-3.34,2.62l-.29-.18-12.76,1.98c1.08-.64,1.98-1.53,2.59-2.61.95-1.67,1.94-5.09-.15-7.11-1.75-1.69-5-1.78-7.45.2-.12-1.26-.23-2.01-.23-2.01l-2.92-1.61s-10.1-7.18-9.22-13.88c0,0,6.52-17.44-3.83-24.91,1.92-1.83,3.84-3.66,5.76-5.5.56,1.83,1.7,4.67,4.06,7.48,1.52,1.81,3.11,3.08,4.38,3.94,2.99.07,6.59-.08,10.6-.75.72-.12.9-.33.79-.59Z"
            />
            <path
              class="angel_devil-13"
              d="m350.75,496.67c.11.27-.07.48-.79.59-4.02.67-7.62.82-10.6.75-1.27-.86-2.85-2.13-4.38-3.94-2.36-2.81-3.5-5.65-4.06-7.48.09-.27.27-.71.68-1,1.27-.89,3.46.6,6.5,2.64,2.54,1.7,5.67,3.77,9.37,5.16.31.11.61.2.91.26-1.22.41.14,1.23,1.26,2.01.33.32.71.66,1.12,1.01Z"
            />
            <path
              class="angel_devil-13"
              d="m359.12,542.05l.29.18c-.72,1.36-.99,3.1-.64,4.89.11.56.27,1.1.48,1.59l-.19.06-18.42,2.81c.49-2.06.77-4.25.91-6.38,1.77.06,3.44-.37,4.82-1.18l12.76-1.98Z"
            />
            <path
              class="angel_devil-2"
              d="m353.39,555.35c3.44-.75,5.99-1.44,8.62-2.33-3.24,12.25,2.41,36.76,4.83,46.19-.04.04-.09.08-.13.12-3.79.25-10.07,1.14-16.95,4.59-5.98,3-10.09,6.76-12.54,9.39-1.14,2.89-4.02,8.92-10.47,13.34-2.75,1.89-5.43,2.98-7.56,3.64-3.26-7.26-.57-13.79,2.01-27.8.54-2.96,1.27-7.27,1.92-12.56-.06-2.7-.39-6.93-1.86-11.82-1.99-6.66-4.51-9.3-7.11-16.46-.52-1.43-1.11-3.22-1.66-5.37,2.62.49,5.99.89,9.9.72,3.31-.14,6.16-.64,8.47-1.21.73,4.97,1.33,6.01,1.75,6.07.57.09,1.06-.53,1.06-.53,2.07-.73,3.63-2.21,4.79-4.14,4.28-.14,9.34-.64,14.93-1.86Z"
            />
            <path
              class="angel_devil-13"
              d="m366.84,599.21c.62,2.4,1.02,3.81,1.02,3.81-12.35,5.02-22.13,15.23-24.06,17.29-6.67,7.12-10.72,14.12-18.1,14.39-1.8.07-3.28-.29-4.23-.58-.62-.8-1.51-2.09-2.28-3.81,2.14-.66,4.81-1.76,7.56-3.64,6.45-4.42,9.33-10.46,10.47-13.34,2.45-2.63,6.55-6.39,12.54-9.39,6.88-3.45,13.16-4.35,16.95-4.59.04-.04.09-.08.13-.12Z"
            />
            <path
              class="angel_devil-3"
              d="m346.36,544.03c-1.37.81-3.05,1.24-4.82,1.18.29-4.3.03-8.38-.19-10.71,2.45-1.98,5.7-1.89,7.45-.2,2.09,2.02,1.09,5.44.15,7.11-.61,1.08-1.51,1.97-2.59,2.61Z"
            />
            <path
              class="angel_devil-13"
              d="m338.2,530.88l2.92,1.61s.11.75.23,2.01c.22,2.33.48,6.4.19,10.71-.14,2.13-.42,4.32-.91,6.38-.49,2.07-1.19,4-2.16,5.62-1.16,1.92-2.72,3.41-4.79,4.14,0,0-.49.62-1.06.53-.42-.07-1.02-1.1-1.75-6.07,0,0,8.86-10.42,6.3-16.89,0,0-1.04-7.63,1.05-8.04Z"
            />
            <path
              class="angel_devil-2"
              d="m338.2,530.88c-2.1.41-1.05,8.04-1.05,8.04,2.56,6.47-6.3,16.89-6.3,16.89l-3.88-4.48-4.2-4.85-10.15-11.72c-.08-.13-.17-.28-.26-.44-1.43-2.48-4.49-8.64-3.74-16.84.1-1.05.25-2.05.44-3.01,1.72-7.71,6.36-12.94,7.53-14.21l8.56-8.16c10.35,7.46,3.83,24.91,3.83,24.91-.88,6.7,9.22,13.88,9.22,13.88Z"
            />
            <path
              class="angel_devil-2"
              d="m315.18,471.59c6.56,2.83,1.98,25.95,1.42,28.66-1.46,1.5-5.95,6.41-7.53,14.21-.55,2.44-.8,5.14-.56,8.03.15,1.81.48,3.48.9,4.99-1.2.3-13.44,3.15-18.83-2.72-1.2-10.18-5.61-13.84-5.61-13.84,3.96-15.75,11.57-33.44,26.35-39.02.6-.23,1.1-.39,1.43-.49,1-.2,1.8-.09,2.43.18Z"
            />
            <path
              class="angel_devil-13"
              d="m326.98,551.32l3.88,4.48c-2.31.56-5.16,1.07-8.47,1.21-3.91.17-7.29-.23-9.9-.72-.5-1.91-.97-4.11-1.34-6.56,1.69.92,4.84,2.33,9,2.49,2.87.11,5.24-.41,6.83-.9Z"
            />
            <path
              class="angel_devil-13"
              d="m322.77,546.47l4.2,4.85c-1.59.49-3.96,1.01-6.83.9-4.17-.16-7.32-1.58-9-2.49-.04-.24-.07-.49-.11-.74.17-.74.34-1.52.48-2.33l11.25-.18Z"
            />
            <path
              class="angel_devil-2"
              d="m312.25,541.78c.28-2.56.38-4.92.38-7.04l10.15,11.72-11.25.18c.29-1.51.54-3.14.73-4.87Z"
            />
            <path
              class="angel_devil-2"
              d="m312.63,534.75c-.44,3.97-.89,7.94-1.33,11.91-.08.77-.17,1.55-.26,2.33-4.41.42-8.83.84-13.25,1.26-.97,1.26-2.39,2.84-4.38,4.33-2.54,1.91-5,2.92-6.72,3.48,1.31,9.39,2.63,18.78,3.94,28.17.44,2.98-.59,5.11-1.79,5.35-.87.17-1.76-.66-2.17-1.1-1.37-4.38-2.46-8.62-3.33-12.68-.88-5.83-1.76-11.67-2.64-17.5-.83-9.65-.48-17.73.09-23.72.39-4.16,1.37-14.41,4.19-25.65,0,0,4.41,3.66,5.61,13.84,5.39,5.87,17.63,3.02,18.83,2.72.81,2.88,1.97,5.18,2.95,6.82.1.16.19.31.26.44Z"
            />
            <path
              class="angel_devil-2"
              d="m283.33,577.8c.04.27.08.53.12.8-.82,1.29-1.63,2.78-2.36,4.48-2.19,5.08-2.66,9.8-2.67,13.14-1.2-3.91-2.6-10.15-2.16-17.86.46-8.02,2.72-14.27,4.43-18.06.46,5.37,1.28,11.24,2.64,17.5Z"
            />
          </g>
          <g>
            <path
              class="angel_devil-5"
              d="m312.36,534.3c-.98-1.64-2.14-3.95-2.95-6.82-.42-1.51-.75-3.18-.9-4.99-.24-2.89,0-5.59.56-8.03,1.72-7.71,6.36-12.94,7.53-14.21.55-2.71,5.14-25.84-1.42-28.66-.63-.27-1.43-.38-2.43-.18-.32.1-.83.27-1.43.49-14.78,5.59-22.39,23.27-26.35,39.02-2.83,11.24-3.8,21.49-4.19,25.65-.57,6-.91,14.07-.09,23.72.46,5.37,1.28,11.24,2.64,17.5.87,4.07,1.97,8.3,3.33,12.68.41.44,1.3,1.27,2.17,1.1,1.2-.24,2.23-2.37,1.79-5.35-1.31-9.39-2.63-18.78-3.94-28.17,1.72-.56,4.19-1.57,6.72-3.48,1.99-1.5,3.41-3.07,4.38-4.33,4.41-.42,8.83-.84,13.25-1.26.17-.74.34-1.52.48-2.33.29-1.51.54-3.14.73-4.87.28-2.56.38-4.92.38-7.04"
            />
            <path
              class="angel_devil-5"
              d="m283.33,577.8c.04.27.08.53.12.8-.82,1.29-1.63,2.78-2.36,4.48-2.19,5.08-2.66,9.8-2.67,13.14-1.2-3.91-2.6-10.15-2.16-17.86.46-8.02,2.72-14.27,4.43-18.06.88,5.83,1.76,11.67,2.64,17.5Z"
            />
            <path
              class="angel_devil-5"
              d="m369.46,602.41c2.31-.84,4.56-1.45,6.7-1.88,2.06-.42,4.03-.69,5.85-.83,2.27-.19,4.32-.21,6.06-.16l-1.75-9.05-.31-1.59-11.15-37.29-.34-1.75-1.04-5.38-.65-5.35-.07-.6c-.17-2.74-.74-6.77-2.62-11.24-.35-.84-.73-1.64-1.12-2.39-2.22-4.34-4.92-7.34-6.78-9.13-1.14-3.07-2.27-6.82-3.03-11.16-.42-2.39-5.41-5.4-8.47-7.94-.41-.35-.79-.68-1.12-1.01-.74-.73-1.22-1.42-1.24-2h0s0,0-.01,0c-1.22.41.14,1.23,1.26,2.01.53.37,1,.71,1.12,1.01.11.27-.07.48-.79.59-4.02.67-7.62.82-10.6.75-1.27-.86-2.85-2.13-4.38-3.94-2.36-2.81-3.5-5.65-4.06-7.48-1.92,1.83-3.84,3.66-5.76,5.5l-8.56,8.16c-1.46,1.5-5.95,6.41-7.53,14.21-.2.96-.35,1.96-.44,3.01-.75,8.2,2.31,14.36,3.74,16.84.1.16.19.31.26.44-.44,3.97-.89,7.94-1.33,11.91-.08.77-.17,1.55-.26,2.33.04.25.07.5.11.74.37,2.45.84,4.65,1.34,6.56.55,2.14,1.14,3.94,1.66,5.37,2.6,7.16,5.12,9.8,7.11,16.46,1.46,4.9,1.8,9.13,1.86,11.82-.65,5.29-1.37,9.59-1.92,12.56-2.58,14.01-5.26,20.55-2.01,27.8.77,1.72,1.67,3.01,2.28,3.81.95.3,2.43.65,4.23.58,7.38-.27,11.42-7.27,18.1-14.39,1.93-2.06,11.71-12.27,24.06-17.29.53-.22,1.06-.42,1.59-.61"
            />
            <path
              class="angel_devil-5"
              d="m321.55,464.05c-.46.59-.71,1.25-.73,1.88-.05,1.88,2.09,4.13,5.17,4.22,2.57,6.88,7.46,11.76,12.98,14.42,3.6,1.74,7.46,2.54,11.13,2.34,2.65-.14,5.2-.8,7.47-2,7.7-4.08,12.13-14.38,9.95-24.99-1.75-1.55-3.5-3.09-5.25-4.64l-4.6-1.28-.26.92-2.53-.38-1.66,1.19-2.41-3.45-3.44.23-.09,1.76-2.96-1.79-3.5,2.17.27-3.1-1.7,4.68-1.42-.59-1.77,2.08-1.68.33-.85,2.34-2.6.07,1.53,10.15-1.34-.18-.26.92-3.97-9.24c-2.41-.45-4.46.58-5.49,1.94Z"
            />
            <path
              class="angel_devil-5"
              d="m330.92,486.6c.09-.27.27-.71.68-1,1.27-.89,3.46.6,6.5,2.64,2.54,1.7,5.67,3.77,9.37,5.16.31.11.61.2.91.26,0,0,0,0,0,0h0c2.04.42,4.04-.26,8.21-.29,0,0,13.15.5,15.58,28.74,0,0-.01,2.18-3.16,2.79"
            />
            <line
              class="angel_devil-5"
              x1="338.98"
              y1="484.57"
              x2="338.1"
              y2="488.24"
            />
            <polyline
              class="angel_devil-5"
              points="348.37 493.72 348.39 493.66 348.39 493.65 350.11 486.91 350.11 486.91"
            />
            <path
              class="angel_devil-5"
              d="m345.68,441.52h0c.2-.14,5.79-4.09,9.97-.19,0,0-5.78-.62-4.79,2.23,0,0,2.49-.74,4.94-1.04,2.11-.26,4.19-.2,4.61.92,0,0-1.35-.82-1.94.92,0,0,4.36.35,4.77,2.44,0,0-2.42-1.27-2.6.07,0,0,8.49,1.83,10.96,13.1,0,0-5.06-5.11-5.65-3.69,0,0,5.59,4.14,5.58,6.32l-4.01-2.7"
            />
            <path
              class="angel_devil-5"
              d="m321.55,464.05l-2.87-4.67.46-2.7-1.3-1.67,3.16-3.91-1.08-1.1,9.97-4.15-5.59-.18,6.78-1.86-2.07-.79,6.08-1.18-.65-3.34,1.63.54s7.42-8.84,17.52-1.66l-10.99,1.7,4.44.44-1.35,2"
            />
            <path
              class="angel_devil-5"
              d="m312.63,534.75l10.15,11.72,4.2,4.85,3.88,4.48s8.86-10.42,6.3-16.89c0,0-1.04-7.63,1.05-8.04,0,0-10.1-7.18-9.22-13.88,0,0,6.52-17.44-3.83-24.91-.05-.04-.1-.08-.16-.12"
            />
            <path
              class="angel_devil-5"
              d="m341.35,534.5c2.45-1.98,5.7-1.89,7.45-.2,2.09,2.02,1.09,5.44.15,7.11-.61,1.08-1.51,1.97-2.59,2.61-1.37.81-3.05,1.24-4.82,1.18"
            />
            <path class="angel_devil-5" d="m362.26,515.77s2.85-2.29,4.94-.96" />
            <path
              class="angel_devil-5"
              d="m319.19,630.3c2.14-.66,4.81-1.76,7.56-3.64,6.45-4.42,9.33-10.46,10.47-13.34,2.45-2.63,6.55-6.39,12.54-9.39,6.88-3.45,13.16-4.35,16.95-4.59.04-.04.09-.08.13-.12.78-.71,2.16-1.77,4.16-2.45,2.12-.72,3.18-.52,4.17-.4.04,0,.07,0,.11,0,0-.18.76-1.95,2.03-3,1.05-.87,2.58-1,3.86-.31.02-1.34.83-2.53,2.03-3,1.62-.64,3.05.39,3.13.45"
            />
            <polyline
              class="angel_devil-5"
              points="311.3 546.66 311.52 546.65 322.77 546.47"
            />
            <path
              class="angel_devil-5"
              d="m311.15,549.73c1.69.92,4.84,2.33,9,2.49,2.87.11,5.24-.41,6.83-.9"
            />
            <path
              class="angel_devil-5"
              d="m312.48,556.29h0c2.62.49,5.99.89,9.9.72,3.31-.14,6.16-.64,8.47-1.21.73,4.97,1.33,6.01,1.75,6.07.57.09,1.06-.53,1.06-.53,2.07-.73,3.63-2.21,4.79-4.14.98-1.62,1.68-3.56,2.16-5.62.49-2.06.77-4.25.91-6.38.29-4.3.03-8.38-.19-10.71-.12-1.26-.23-2.01-.23-2.01l-2.92-1.61"
            />
            <line
              class="angel_devil-5"
              x1="346.36"
              y1="544.03"
              x2="359.12"
              y2="542.05"
            />
            <line
              class="angel_devil-5"
              x1="340.63"
              y1="551.59"
              x2="359.05"
              y2="548.78"
            />
            <path
              class="angel_devil-5"
              d="m362.01,553.01c.42-.14.85-.3,1.29-.45"
            />
            <path
              class="angel_devil-5"
              d="m338.46,557.21c4.28-.14,9.34-.64,14.93-1.86,3.44-.75,5.99-1.44,8.62-2.33"
            />
            <path
              class="angel_devil-5"
              d="m369.25,545.09c.4,2.05-.01,4.02-.98,5.45-.41.61-.92,1.11-1.52,1.49-.45.28-.95.48-1.49.58-.66.13-1.33.1-1.96-.07-1.71-.44-3.24-1.86-4.06-3.82-.2-.5-.37-1.03-.48-1.59-.35-1.8-.08-3.54.64-4.89s1.89-2.34,3.34-2.62c1.24-.24,2.49.07,3.56.8,1.43.96,2.54,2.64,2.94,4.69Z"
            />
            <line
              class="angel_devil-5"
              x1="366.31"
              y1="540.41"
              x2="372.84"
              y2="539.14"
            />
            <line
              class="angel_devil-5"
              x1="369.25"
              y1="545.09"
              x2="373.48"
              y2="544.49"
            />
            <polyline
              class="angel_devil-5"
              points="368.27 550.54 371.4 550.21 374.53 549.87"
            />
            <path
              class="angel_devil-5"
              d="m343.8,620.31c8.19.09,18.76-1.22,29.11-6.95,1.3-.72,2.53-1.47,3.7-2.25.07-.7.23-2.98-1.22-5.19-2.01-3.05-5.44-3.46-5.93-3.51h0"
            />
            <path
              class="angel_devil-5"
              d="m388.07,599.54c2.82,2,3.01,3.56,2.99,4.21-.09,3.13-5.75,6.26-14.46,7.36"
            />
            <path
              class="angel_devil-5"
              d="m367.86,603.02s-.41-1.42-1.02-3.81c-2.42-9.43-8.07-33.94-4.83-46.19h0"
            />
            <path
              class="angel_devil-5"
              d="m376.16,600.52s-.38-1.58-.99-4.17c-2.6-10.98-9.31-40.17-8.41-44.33"
            />
            <path
              class="angel_devil-5"
              d="m382.01,599.69l-.85-6.65s-11.72-29.75-9.76-42.84h0"
            />
            <path
              class="angel_devil-6"
              d="m284.97,510.93s4.41,3.66,5.61,13.84c.3,2.49.4,5.36.19,8.67"
            />
            <path
              class="angel_devil-7"
              d="m293.7,519.06s12.68-33.36,16.21-35.35"
            />
            <path
              class="angel_devil-5"
              d="m304.54,493.02s2.54,16.48,1.85,19.66"
            />
            <path
              class="angel_devil-5"
              d="m309.51,527.46s-.03,0-.1.02c-1.2.3-13.44,3.15-18.83-2.72"
            />
            <path
              class="angel_devil-5"
              d="m286.69,558.06s0,0,0,0c.22-.3,7.02-10.03,7.51-15.21"
            />
            <path
              class="angel_devil-5"
              d="m288.24,540.57c-1.65,2.91-1.56,17.26-1.55,17.49"
            />
            <path
              class="angel_devil-5"
              d="m297.79,550.25s4.91-9.44,4.25-10.62"
            />
            <path
              class="angel_devil-5"
              d="m337.11,460.16c.72-.49,1.94-1.2,3.61-1.57,1.94-.43,3.56-.19,4.45,0"
            />
            <path
              class="angel_devil-5"
              d="m353.45,459.16c.61-.4,2.25-1.37,4.53-1.31,1.74.04,3.04.66,3.68,1.03"
            />
            <path
              class="angel_devil-5"
              d="m351.16,478.76c.59-.56,1.93-1.67,3.95-2.07,1.04-.21,1.95-.16,2.6-.07"
            />
            <path
              class="angel_devil-5"
              d="m348.19,467.15c3.24-1.3,5.81-1.3,6.31-.35.43.82-.66,2.44-2.29,3.92"
            />
            <path
              class="angel_devil-5"
              d="m345.01,461.82c.17.04.33.08.49.14.03.01.06.02.09.03"
            />
            <path
              class="angel_devil-5"
              d="m337.56,463.55s4.37-2.35,7.38-1.74"
            />
            <path
              class="angel_devil-5"
              d="m345.5,461.96c.45.19.77.54.85.98.16.81-.56,1.63-1.6,1.83s-2.01-.29-2.17-1.1.56-1.63,1.6-1.83c.26-.05.52-.06.76-.02.03,0,.05,0,.07.01.17.02.34.07.49.14Z"
            />
            <path
              class="angel_devil-5"
              d="m361.35,460.83c.17.04.33.08.49.14.03.01.06.02.09.03"
            />
            <path class="angel_devil-5" d="m353.9,462.56s4.37-2.35,7.38-1.74" />
            <path
              class="angel_devil-5"
              d="m361.84,460.97c.45.19.77.54.85.98.16.81-.56,1.63-1.6,1.83s-2.01-.29-2.17-1.1.56-1.63,1.6-1.83c.26-.05.52-.06.76-.02.03,0,.05,0,.07.01.17.02.34.07.49.14Z"
            />
            <ellipse
              class="angel_devil-5"
              cx="337.41"
              cy="422.46"
              rx="19.44"
              ry="4.06"
              transform="translate(-74.26 71.97) rotate(-10.98)"
            />
            <ellipse
              class="angel_devil-5"
              cx="337.75"
              cy="421.96"
              rx="11.32"
              ry="1.5"
              transform="translate(-74.16 72.02) rotate(-10.98)"
            />
          </g>
        </g>
        <g>
          <path
            class="angel_devil-5"
            d="m316.6,500.25c.55-2.71,5.14-25.83-1.42-28.66-.63-.27-1.42-.38-2.43-.18-.32.1-.83.27-1.43.49-25.33,9.57-29.59,54.66-30.54,64.67-1.09,11.5-1.36,30.63,5.89,53.91.41.44,1.31,1.27,2.17,1.1,1.2-.24,2.23-2.37,1.79-5.35-1.31-9.39-2.63-18.78-3.94-28.17,1.73-.56,4.19-1.57,6.72-3.48,1.99-1.49,3.41-3.07,4.38-4.33,4.42-.42,8.83-.84,13.25-1.26.49-2.13.92-4.54,1.22-7.2.28-2.55.38-4.92.38-7.04-1.52-2.45-3.65-6.68-4.12-12.26-1.01-12.04,6.55-20.57,8.09-22.24Z"
          />
          <path
            class="angel_devil-5"
            d="m278.42,596.21c-1.2-3.91-2.6-10.15-2.16-17.86.46-8.02,2.72-14.27,4.43-18.06.92,6.1,1.84,12.2,2.76,18.3-.82,1.29-1.63,2.78-2.36,4.48-2.19,5.08-2.66,9.8-2.67,13.14Z"
          />
          <path
            class="angel_devil-5"
            d="m311.04,548.98c.77,5.56,2.08,9.84,3.11,12.67,2.6,7.16,5.12,9.8,7.11,16.46,1.47,4.9,1.8,9.13,1.86,11.82-.65,5.29-1.37,9.59-1.92,12.56-2.58,14.01-5.26,20.55-2.01,27.8.77,1.72,1.67,3.01,2.29,3.81.94.29,2.43.65,4.22.58,7.37-.27,11.42-7.27,18.1-14.39,1.93-2.06,11.71-12.27,24.06-17.29,7.94-3.22,15.29-3.63,20.21-3.48l-2.06-10.63-11.15-37.29-1.38-7.13-.72-5.95c-.17-2.75-.74-6.77-2.62-11.24-2.36-5.64-5.71-9.42-7.89-11.52-1.14-3.07-2.27-6.82-3.03-11.16-.61-3.46-10.75-8.2-10.83-10.96-2.37.78,4.72,3.09,1.57,3.61-4.02.67-7.62.82-10.6.75-1.27-.86-2.86-2.13-4.38-3.94-2.36-2.81-3.5-5.65-4.06-7.48-4.77,4.55-9.55,9.1-14.32,13.66-1.64,1.68-7.1,7.68-7.98,17.21-.8,8.74,2.73,15.18,4,17.28-.53,4.74-1.06,9.49-1.59,14.23Z"
          />
          <path
            class="angel_devil-5"
            d="m325.99,470.15c5.45,14.61,21.37,20.18,31.58,14.76,7.7-4.08,12.13-14.38,9.95-24.99-1.75-1.55-3.5-3.09-5.25-4.64l-4.6-1.28-.26.92-2.53-.38-1.66,1.19-2.41-3.45-3.44.23-.09,1.76-2.96-1.79-3.5,2.17.27-3.1-1.7,4.68-1.42-.59-1.77,2.08-1.68.33-.85,2.34-2.6.07,1.53,10.15-1.34-.18-.26.92-3.97-9.24c-3.47-.64-6.17,1.75-6.22,3.82-.04,1.88,2.09,4.13,5.17,4.22Z"
          />
          <path
            class="angel_devil-5"
            d="m330.92,486.6c.09-.28.27-.72.68-1,2.33-1.63,7.72,4.75,15.87,7.8,2.42.9,4.33,0,9.13-.03,0,0,13.15.5,15.58,28.74,0,0-.01,2.18-3.16,2.79"
          />
          <line
            class="angel_devil-5"
            x1="338.98"
            y1="484.57"
            x2="338.1"
            y2="488.24"
          />
          <line
            class="angel_devil-5"
            x1="348.38"
            y1="493.72"
            x2="350.11"
            y2="486.91"
          />
          <path
            class="angel_devil-5"
            d="m321.55,464.05l-2.87-4.67.46-2.7-1.31-1.68,3.16-3.9-1.08-1.1,9.97-4.15-5.59-.18,6.78-1.86-2.07-.79,6.08-1.18-.65-3.34,1.63.54s7.42-8.84,17.52-1.66l-10.99,1.7,4.44.44-1.35,2s5.72-4.16,9.97-.19c0,0-5.78-.62-4.79,2.23,0,0,8.64-2.55,9.55-.11,0,0-1.35-.82-1.94.92,0,0,4.36.35,4.77,2.45,0,0-2.42-1.27-2.6.07,0,0,8.49,1.83,10.96,13.11,0,0-5.06-5.11-5.65-3.69,0,0,5.59,4.14,5.58,6.32l-4.01-2.7"
          />
          <path
            class="angel_devil-5"
            d="m312.63,534.75l18.23,21.05s8.86-10.42,6.3-16.89c0,0-1.04-7.63,1.05-8.04,0,0-10.1-7.18-9.22-13.88,0,0,6.59-17.63-3.98-25.03"
          />
          <path
            class="angel_devil-5"
            d="m341.35,534.5c2.45-1.98,5.7-1.89,7.45-.2,2.09,2.02,1.09,5.44.15,7.11-1.39,2.46-4.24,3.91-7.41,3.8"
          />
          <path class="angel_devil-5" d="m362.26,515.77s2.85-2.29,4.94-.96" />
          <path
            class="angel_devil-5"
            d="m319.19,630.3c2.14-.66,4.81-1.76,7.56-3.64,6.45-4.42,9.32-10.46,10.47-13.34,2.45-2.63,6.55-6.39,12.54-9.39,6.88-3.45,13.16-4.34,16.95-4.59.75-.7,2.17-1.85,4.29-2.57,2.19-.74,3.25-.51,4.28-.39-.01-.18.76-1.95,2.03-3,1.05-.87,2.58-1,3.86-.31.02-1.34.83-2.53,2.03-3,1.63-.64,3.05.39,3.13.45"
          />
          <line
            class="angel_devil-5"
            x1="311.3"
            y1="546.66"
            x2="322.78"
            y2="546.47"
          />
          <path
            class="angel_devil-5"
            d="m311.15,549.73c1.69.92,4.84,2.33,9,2.49,2.87.11,5.24-.41,6.83-.9"
          />
          <path
            class="angel_devil-5"
            d="m312.48,556.29c2.61.5,5.99.89,9.9.72,3.3-.14,6.17-.64,8.47-1.21.73,4.97,1.33,6.01,1.75,6.07.57.09,1.06-.53,1.06-.53,11-3.87,7.46-28.86,7.46-28.86l-2.92-1.61"
          />
          <line
            class="angel_devil-5"
            x1="346.36"
            y1="544.02"
            x2="359.12"
            y2="542.05"
          />
          <line
            class="angel_devil-5"
            x1="340.63"
            y1="551.59"
            x2="359.05"
            y2="548.78"
          />
          <path
            class="angel_devil-5"
            d="m338.46,557.21c4.28-.14,9.34-.64,14.93-1.86,4-.88,6.8-1.66,9.9-2.79"
          />
          <ellipse
            class="angel_devil-5"
            cx="364.01"
            cy="546.11"
            rx="5.34"
            ry="6.62"
            transform="translate(-97.31 79.29) rotate(-10.98)"
          />
          <line
            class="angel_devil-5"
            x1="366.31"
            y1="540.41"
            x2="372.84"
            y2="539.14"
          />
          <line
            class="angel_devil-5"
            x1="369.25"
            y1="545.09"
            x2="373.48"
            y2="544.49"
          />
          <line
            class="angel_devil-5"
            x1="368.27"
            y1="550.54"
            x2="374.53"
            y2="549.87"
          />
          <path
            class="angel_devil-5"
            d="m343.8,620.31c8.19.09,18.76-1.22,29.11-6.95,1.3-.72,2.53-1.47,3.7-2.25.08-.7.23-2.98-1.22-5.19-2.02-3.06-5.46-3.46-5.93-3.5"
          />
          <path
            class="angel_devil-5"
            d="m388.07,599.54c2.82,2,3.01,3.56,2.99,4.21-.09,3.13-5.75,6.27-14.46,7.36"
          />
          <path
            class="angel_devil-5"
            d="m367.86,603.02s-9.91-34.66-5.85-50.01"
          />
          <path
            class="angel_devil-5"
            d="m376.16,600.52s-10.51-43.36-9.41-48.5"
          />
          <path
            class="angel_devil-5"
            d="m382.01,599.69l-.85-6.65s-11.72-29.75-9.76-42.84"
          />
          <path d="m285.61,510.16c7.53,4.48,8.31,15.9,5.17,23.28-1.21-3.76-1.85-7.7-2.9-11.43-.93-3.54-2.08-7-3.55-10.31,0,0,1.28-1.54,1.28-1.54h0Z" />
          <path d="m293.7,519.06c1.77-8.68,8.76-30.76,16.21-35.35-1.7,2.74-2.39,5.99-3.68,8.93-3.68,9.02-7.64,17.94-12.53,26.41h0Z" />
          <path d="m305.52,492.87c.49,6.58,1.54,13.23.87,19.81.16-3.29-.55-6.55-1.03-9.8-.55-3.25-1.16-6.49-1.81-9.71l1.98-.31h0Z" />
          <path
            class="angel_devil-5"
            d="m309.51,527.46s-13.25,3.49-18.93-2.69"
          />
          <path d="m289.11,541.06c-.25-.09-.75-.33-.96-.23-1.38,4.07-1.03,8.58-.91,12.83.11,1.47.22,2.94.45,4.39,0,0-1.82-.57-1.82-.57,1.72-2.2,3.37-4.53,4.86-6.93,1.44-2.42,2.94-4.9,3.47-7.71-.06,1.43-.48,2.83-.94,4.19-1.81,5.14-4.71,9.94-7.57,14.59,0,0,0-3.55,0-3.55.22-1.49.37-2.96.54-4.43.47-3.85.66-8.3,1.62-12.04.06-.28.19-.52.27-.78-.03-.11-.26-.33-.41-.46,0,0-.34-.28-.34-.28l1.74.99h0Z" />
          <path d="m296.9,549.78c2.23-2.91,4.44-6.17,5.2-9.8,0-.06,0-.12,0-.18.06-.05-.14-.19-.92.32l1.74-.98c-.75.62-.88.59-.82.66-.55,3.78-2.55,7.15-3.41,10.91,0,0-1.77-.92-1.77-.92h0Z" />
          <path
            class="angel_devil-5"
            d="m337.11,460.16c.72-.49,1.94-1.2,3.61-1.57,1.94-.43,3.56-.19,4.45,0"
          />
          <path
            class="angel_devil-5"
            d="m353.45,459.16c.61-.4,2.25-1.37,4.53-1.31,1.74.04,3.04.66,3.68,1.03"
          />
          <path
            class="angel_devil-5"
            d="m351.16,478.76c.59-.56,1.93-1.67,3.95-2.07,1.04-.21,1.95-.16,2.6-.07"
          />
          <path
            class="angel_devil-5"
            d="m348.19,467.15c3.24-1.3,5.81-1.3,6.31-.35.43.82-.66,2.44-2.29,3.92"
          />
          <path class="angel_devil-5" d="m337.56,463.55s5.05-2.72,8.03-1.56" />
          <ellipse
            class="angel_devil-5"
            cx="344.46"
            cy="463.3"
            rx="1.92"
            ry="1.5"
            transform="translate(-81.91 74.06) rotate(-10.98)"
          />
          <path class="angel_devil-5" d="m353.9,462.56s5.05-2.72,8.03-1.56" />
          <ellipse
            class="angel_devil-5"
            cx="360.81"
            cy="462.31"
            rx="1.92"
            ry="1.5"
            transform="translate(-81.42 77.15) rotate(-10.98)"
          />
          <path
            class="angel_devil-5"
            d="m336.64,418.47c-10.54,2.04-18.74,5.48-18.31,7.68s9.32,2.33,19.86.28,18.74-5.48,18.31-7.68-9.32-2.33-19.86-.28Zm1.4,4.95c-6.14,1.19-11.24,1.5-11.4.69s4.69-2.43,10.83-3.62,11.24-1.5,11.4-.69-4.69,2.43-10.83,3.62Z"
          />
        </g>
      </g>
      <g>
        <path
          class="angel_devil-5"
          d="m708.2,506.07c-9.19,12.6-26.02,13.66-34.4,5.69-6.31-6.01-7.8-17.12-2.84-26.75,2.1-1.02,4.21-2.03,6.31-3.05h4.78s0,.96,0,.96l2.54.32,1.28,1.6,3.25-2.67,3.25,1.15-.38,1.72,3.33-.93,2.78,3.04.58-3.06.38,4.97,1.53-.19,1.14,2.48,1.53.77.19,2.48,2.48.77-4.21,9.36,1.34.19v.96s6.31-7.83,6.31-7.83c3.51.32,5.47,3.35,4.96,5.36-.46,1.82-3.12,3.42-6.12,2.67Z"
        />
        <path
          class="angel_devil-5"
          d="m714.12,501.4l4.02-3.72.29-2.72,1.71-1.26-1.99-4.61,1.34-.76-8.48-6.68,5.43,1.33-6.03-3.62,2.21-.2-5.54-2.78,1.52-3.04-1.71.08s-4.76-10.51-16.42-6.32l10.12,4.6-4.39-.77.76,2.29s-4.39-5.55-9.55-2.88c0,0,5.73.96,4.01,3.44,0,0-7.64-4.79-9.17-2.69,0,0,1.52-.43,1.62,1.41,0,0-4.29-.84-5.25,1.07,0,0,2.68-.57,2.48.77,0,0-8.67-.52-14.09,9.66,0,0,6.25-3.56,6.44-2.03,0,0-6.5,2.48-7.07,4.58l4.59-1.52"
        />
        <path
          class="angel_devil-5"
          d="m700.19,493.45c-.56-.67-1.54-1.68-3.05-2.49-1.75-.94-3.37-1.14-4.28-1.19"
        />
        <path
          class="angel_devil-5"
          d="m684.72,488.08c-.48-.55-1.8-1.93-4.01-2.49-1.69-.43-3.11-.18-3.82,0"
        />
        <path
          class="angel_devil-5"
          d="m681.64,507.57c-.42-.7-1.41-2.13-3.24-3.06-.95-.48-1.83-.68-2.48-.77"
        />
        <path
          class="angel_devil-5"
          d="m687.63,497.19c-2.77-2.13-5.25-2.82-5.98-2.04-.63.67-.02,2.53,1.14,4.4"
        />
        <path class="angel_devil-5" d="m698.84,496.6s-4.13-3.98-7.31-3.67" />
        <ellipse
          class="angel_devil-5"
          cx="692.26"
          cy="494.49"
          rx="1.5"
          ry="1.92"
          transform="translate(-60.07 891.66) rotate(-63.37)"
        />
        <path class="angel_devil-5" d="m683.37,491.23s-4.13-3.98-7.31-3.67" />
        <ellipse
          class="angel_devil-5"
          cx="676.79"
          cy="489.12"
          rx="1.5"
          ry="1.92"
          transform="translate(-63.81 874.87) rotate(-63.37)"
        />
        <g>
          <g>
            <path
              class="angel_devil-9"
              d="m721.54,585.88l-6.52,19.67-2.86-2.87c-33.47,27.66-36.09-17.62-36.09-17.62,4.37-4.97,9.44-11.68,13.97-20.24,3.35-6.33,5.66-12.27,7.28-17.38,13.35,22.94,1.29,37.45,1.29,37.45-14.91,8.77-11.29,14.89-11.29,14.89,7.82,7.27,20.06-2.84,20.06-2.84l-2.86-3.82,17.01-7.24Z"
            />
            <path
              class="angel_devil-12"
              d="m705.14,509.59l14.57,7.3s-28.1,11.45-26.96,18.71l-11.27-6.13,6.95-12.65c5.8-.22,11.88-2.55,16.71-7.23Z"
            />
            <path
              class="angel_devil-9"
              d="m664.02,595.75c-5.3,10.88-10.6,21.77-15.89,32.65l.76,3.25c0,1.58-.29,3.83-1.8,5.5-3.57,3.94-11.29,1.71-18.46-.36-5.81-1.68-11.69-3.46-12.79-7.85-.37-1.47-.1-2.83.21-3.82,4.04-6.68,10.88-6.87,10.88-6.87l15.89-30.74c3.83-5.73-.37-7.83-.37-7.83-3.24-3.06-5.33-16.05-5.33-16.05-2.48-5.54,7.48-24.45,7.48-24.45-14.52-.59-25.2-13.59-25.2-13.59l2.69-11.08-5.73-2.87-2.49,7.83c-5.73-2.87-1.14-5.35-1.14-5.35-3.05-3.44.96-5.73.96-5.73.58-9.74,5.16-2.67,5.16-2.67l4.3,2.82,17.28,11.34,21.68-1.45,4.26,4.38c2.79-.53,5.77-12.54,5.84-12.81.5.63,1.03,1.22,1.61,1.77,3.69,3.51,9.03,5.27,14.63,5.06l-6.95,12.65,11.27,6.13c4.58,3.25,4.57,11.85,4.57,11.85-1.62,5.11-3.93,11.05-7.28,17.38-4.53,8.56-9.6,15.26-13.97,20.24l-12.05,10.69Z"
            />
            <path
              class="angel_devil-1"
              d="m716.44,482.96s12.28,4.72,17.45-8.45c0,0-1.88,14.89-13.75,19.18l-1.99-4.61,1.34-.76-8.48-6.69,5.43,1.33Z"
            />
            <path
              class="angel_devil-4"
              d="m696.96,473.19l-.76-2.29,4.39.77-10.12-4.6c11.66-4.19,16.42,6.32,16.42,6.32l1.71-.08-1.52,3.04,5.54,2.78-2.21.2,6.03,3.62-5.43-1.33,8.48,6.69-1.34.76,1.99,4.61-1.71,1.26-.29,2.72-4.02,3.72c-.63-1.59-2.32-3.13-4.77-3.35l-6.31,7.83v-.96s-1.34-.19-1.34-.19l4.21-9.36-2.48-.77-.19-2.48-1.53-.77-1.14-2.48-1.53.19-.38-4.97-.58,3.06-2.78-3.03-3.33.92.38-1.72-3.25-1.15-3.25,2.67-1.28-1.6-2.54-.32v-.96s-4.78,0-4.78,0c-2.1,1.01-4.21,2.03-6.31,3.05l-4.59,1.52c.58-2.1,7.07-4.58,7.07-4.58-.19-1.53-6.44,2.03-6.44,2.03,5.42-10.19,14.09-9.66,14.09-9.66.19-1.34-2.48-.77-2.48-.77.22-.44.62-.74,1.11-.93,1.61-.63,4.14-.14,4.14-.14-.1-1.84-1.62-1.41-1.62-1.41.71-.97,2.73-.47,4.69.35,2.28.95,4.48,2.33,4.48,2.33,1.72-2.48-4.01-3.44-4.01-3.44.4-.21.8-.37,1.19-.48h0c4.54-1.33,8.21,3.18,8.35,3.35Z"
            />
            <path
              class="angel_devil-3"
              d="m672.19,509.99c-3.84-4.88-5.18-12.02-3.44-19.07.49-1.99,1.22-3.98,2.22-5.91,2.1-1.02,4.2-2.04,6.31-3.05h4.78s0,.96,0,.96l2.54.32,1.28,1.6,3.25-2.67,3.25,1.15-.38,1.72,3.33-.92,2.78,3.03.58-3.06.38,4.97,1.53-.19,1.14,2.48,1.53.77.19,2.48,2.48.77-4.21,9.36,1.34.19v.96s6.31-7.83,6.31-7.83c2.45.22,4.14,1.76,4.77,3.35.27.69.35,1.4.19,2-.46,1.82-3.12,3.42-6.12,2.67-.95,1.29-1.97,2.47-3.06,3.52-4.83,4.68-10.92,7.01-16.71,7.23-5.6.21-10.93-1.54-14.63-5.06-.58-.55-1.11-1.14-1.61-1.77Zm19.45-17.08c-.49.06-.9.31-1.11.71-.37.74.1,1.72,1.05,2.2s2.02.26,2.39-.47-.1-1.72-1.05-2.2c-.26-.13-.53-.21-.79-.24,0,0,0,0,0,0-.14-.02-.28-.02-.41,0-.02,0-.05,0-.07,0Zm-15.47-5.37c-.49.06-.9.31-1.11.71-.37.74.1,1.72,1.05,2.2s2.02.26,2.39-.47-.1-1.72-1.05-2.2c-.26-.13-.53-.21-.79-.24,0,0,0,0,0,0-.14-.02-.28-.02-.41,0-.02,0-.05,0-.07,0Z"
            />
            <path d="m692.93,493.15c.95.47,1.42,1.46,1.05,2.2s-1.44.95-2.39.47-1.42-1.46-1.05-2.2c.2-.4.62-.65,1.11-.71h.07c.14,0,.27,0,.41,0,0,0,0,0,0,0,.26.03.53.1.79.24Z" />
            <path
              class="angel_devil-9"
              d="m642.45,579.67s4.2,2.11.37,7.83l-15.89,30.74s-6.84.19-10.88,6.87c-1.5.24-6.91.89-11.86-2.5-2.2-1.5-3.58-3.3-4.39-4.59,1.53-4.97,10.33-8.2,10.33-8.2l23.17-42.39c1.34-3.63,3.83-3.82,3.83-3.82,0,0,2.09,12.99,5.33,16.05Z"
            />
            <path
              class="angel_devil-1"
              d="m687.41,470.32s5.73.96,4.01,3.44c0,0-2.2-1.38-4.48-2.33l.47-1.11Z"
            />
            <path
              class="angel_devil-12"
              d="m672.19,509.99c-.07.26-3.04,12.28-5.84,12.81l-4.26-4.38c7.94-9.3-2.17-24.13-2.17-24.13l8.82-3.37c-1.73,7.06-.39,14.2,3.44,19.07Z"
            />
            <path d="m677.46,487.79c.95.47,1.42,1.46,1.05,2.2s-1.44.95-2.39.47-1.42-1.46-1.05-2.2c.2-.4.62-.65,1.11-.71h.07c.14,0,.27,0,.41,0,0,0,0,0,0,0,.26.03.53.1.79.24Z" />
            <path
              class="angel_devil-1"
              d="m688.6,469.84c-.39.11-.79.27-1.19.48l-.47,1.11c-1.96-.82-3.98-1.33-4.69-.35,0,0,1.52-.43,1.62,1.41,0,0-2.53-.5-4.14.14-3.52-12.33,5.85-20.91,5.85-20.91-7.38,14.72,2.78,18.04,3.02,18.12Z"
            />
            <polygon
              class="angel_devil-8"
              points="595.01 604.84 591.11 612.61 590.64 603.89 613.86 519.46 616.35 511.63 622.08 514.5 619.39 525.58 595.01 604.84"
            />
            <path
              class="angel_devil-8"
              d="m650.39,484.73c-4.1,8.54-14.13,14.65-24.65,12.85-.7-.12-1.41-.27-2.11-.46-.77-.21-1.52-.45-2.24-.74-9.98-3.87-15.67-14.33-14.54-23.93,1.04-8.86,7.55-14.18,9.16-15.43,1.03,1,2.06,2,3.08,2.99-1.04,1.1-5.94,6.51-5.55,14.35.29,5.77,3.44,12.81,9.6,15.65.7.32,1.45.59,2.23.8.64.17,1.28.29,1.9.36,8.33.95,14.71-6.32,15.3-7.02,5.77-6.79,4.62-15.09,4.41-16.43,1.42-.15,2.83-.29,4.25-.44.67,2.06,2.89,9.68-.83,17.45Z"
            />
            <polygon
              class="angel_devil-1"
              points="652.51 467.15 651.22 467.28 645.64 467.81 647.08 458.22 652.51 467.15"
            />
            <path
              class="angel_devil-8"
              d="m625.74,497.58l-2.6,10.95-4.3-2.82,2.55-9.33c.72.28,1.47.53,2.24.74.7.19,1.4.35,2.11.46Z"
            />
            <path
              class="angel_devil-8"
              d="m631.82,471.94l-4.56,19.23c-.62-.07-1.26-.19-1.9-.36-.78-.21-1.53-.47-2.23-.8l5.18-18.96,3.51.88Z"
            />
            <polygon
              class="angel_devil-1"
              points="632.88 461.41 635.39 472.84 631.82 471.94 628.3 471.06 625.57 470.37 632.88 461.41"
            />
            <path
              class="angel_devil-1"
              d="m623.36,451.06l-3.28,9.93-1-.97c-1.02-1-2.05-2-3.08-2.99l-.87-.84,8.24-5.12Z"
            />
          </g>
          <g>
            <path
              class="angel_devil-5"
              d="m714.12,501.4c.27.69.35,1.4.19,2-.46,1.82-3.12,3.42-6.12,2.67-.95,1.29-1.97,2.47-3.06,3.52-4.83,4.68-10.92,7.01-16.71,7.23-5.6.21-10.93-1.54-14.63-5.06-.58-.55-1.11-1.14-1.61-1.77-3.84-4.88-5.18-12.02-3.44-19.07.49-1.99,1.22-3.98,2.22-5.91,2.1-1.02,4.2-2.04,6.31-3.05h4.78s0,.96,0,.96l2.54.32,1.28,1.6,3.25-2.67,3.25,1.15-.38,1.72,3.33-.92,2.78,3.03.58-3.06.38,4.97,1.53-.19,1.14,2.48,1.53.77.19,2.48,2.48.77-4.21,9.36,1.34.19v.96s6.31-7.83,6.31-7.83c2.45.22,4.14,1.76,4.77,3.35Z"
            />
            <path
              class="angel_devil-5"
              d="m688.6,469.84c-.39.11-.79.27-1.19.48,0,0,5.73.96,4.01,3.44,0,0-2.2-1.38-4.48-2.33-1.96-.82-3.98-1.33-4.69-.35,0,0,1.52-.43,1.62,1.41,0,0-2.53-.5-4.14.14-.49.19-.89.49-1.11.93,0,0,2.68-.57,2.48.77,0,0-8.67-.53-14.09,9.66,0,0,6.25-3.56,6.44-2.03,0,0-6.5,2.48-7.07,4.58l4.59-1.52"
            />
            <path
              class="angel_devil-5"
              d="m696.96,473.19h0c-.14-.18-3.81-4.69-8.35-3.36"
            />
            <path
              class="angel_devil-5"
              d="m714.12,501.4l4.02-3.72.29-2.72,1.71-1.26-1.99-4.61,1.34-.76-8.48-6.69,5.43,1.33-6.03-3.62,2.21-.2-5.54-2.78,1.52-3.04-1.71.08s-4.76-10.51-16.42-6.32l10.12,4.6-4.39-.77.76,2.29"
            />
            <path
              class="angel_devil-5"
              d="m700.19,493.45c-.56-.67-1.55-1.68-3.05-2.49-1.75-.94-3.37-1.14-4.29-1.19"
            />
            <path
              class="angel_devil-5"
              d="m684.72,488.08c-.48-.55-1.8-1.93-4.01-2.49-1.69-.43-3.11-.18-3.82,0"
            />
            <path
              class="angel_devil-5"
              d="m681.64,507.57c-.42-.7-1.41-2.13-3.24-3.06-.95-.48-1.83-.68-2.48-.77"
            />
            <path
              class="angel_devil-5"
              d="m687.63,497.19c-2.77-2.13-5.25-2.82-5.98-2.04-.63.67-.02,2.53,1.14,4.4"
            />
            <path
              class="angel_devil-5"
              d="m692.13,492.92c-.14,0-.27,0-.41,0h-.07s-.08.01-.12.02"
            />
            <path class="angel_devil-5" d="m698.84,496.6s-3.63-3.5-6.71-3.68" />
            <path
              class="angel_devil-5"
              d="m691.64,492.92c-.49.06-.9.31-1.11.71-.37.74.1,1.72,1.05,2.2s2.02.26,2.39-.47-.1-1.72-1.05-2.2c-.26-.13-.53-.21-.79-.24,0,0,0,0,0,0-.14-.02-.28-.02-.41,0-.02,0-.05,0-.07,0Z"
            />
            <path
              class="angel_devil-5"
              d="m676.66,487.55c-.14,0-.27,0-.41,0h-.07s-.08.01-.12.02"
            />
            <path
              class="angel_devil-5"
              d="m683.37,491.23s-3.63-3.5-6.71-3.68"
            />
            <path
              class="angel_devil-5"
              d="m676.18,487.55c-.49.06-.9.31-1.11.71-.37.74.1,1.72,1.05,2.2s2.02.26,2.39-.47-.1-1.72-1.05-2.2c-.26-.13-.53-.21-.79-.24,0,0,0,0,0,0-.14-.02-.28-.02-.41,0-.02,0-.05,0-.07,0Z"
            />
            <path
              class="angel_devil-5"
              d="m688.61,469.84h0c-.24-.08-10.4-3.4-3.02-18.12,0,0-9.37,8.58-5.85,20.91"
            />
            <path
              class="angel_devil-5"
              d="m716.44,482.96s12.28,4.72,17.45-8.45c0,0-1.88,14.89-13.75,19.18"
            />
            <path
              class="angel_devil-5"
              d="m672.19,509.99h0c-.07.27-3.04,12.28-5.84,12.81l-4.26-4.38-21.68,1.45-17.28-11.34-4.3-2.82s-4.58-7.07-5.16,2.67c0,0-4.01,2.29-.96,5.73,0,0-4.59,2.48,1.14,5.35l2.49-7.83,5.73,2.87-2.69,11.08s10.68,13,25.2,13.59c0,0-9.96,18.9-7.48,24.45,0,0-2.48.19-3.83,3.82l-23.17,42.39s-8.79,3.24-10.33,8.2c.81,1.29,2.19,3.09,4.39,4.59,4.96,3.38,10.36,2.73,11.86,2.5-.32.99-.58,2.36-.21,3.82,1.1,4.39,6.98,6.17,12.79,7.85,7.16,2.07,14.88,4.3,18.46.36,1.52-1.67,1.8-3.92,1.8-5.5l-.76-3.25c5.3-10.88,10.6-21.77,15.89-32.65l12.05-10.69s2.62,45.28,36.09,17.62l2.86,2.87,6.52-19.67-17.01,7.24,2.86,3.82s-12.24,10.11-20.06,2.84c0,0-3.62-6.12,11.29-14.89,0,0,12.05-14.51-1.29-37.45,0,0,0-8.6-4.57-11.85l-11.27-6.13,6.95-12.65"
            />
            <path
              class="angel_devil-5"
              d="m705.14,509.59l14.57,7.3s-28.1,11.45-26.96,18.71"
            />
            <path
              class="angel_devil-5"
              d="m668.75,490.92l-8.82,3.37s10.1,14.83,2.17,24.13"
            />
            <path
              class="angel_devil-5"
              d="m637.12,563.62s2.09,12.99,5.33,16.05c0,0,4.2,2.11.37,7.83l-15.89,30.74s-6.84.19-10.88,6.87"
            />
            <path
              class="angel_devil-5"
              d="m676.49,547.04l-14.35,28.63-5.94,5.17s-6.7,11.46,7.82,14.91"
            />
            <path class="angel_devil-5" d="m644.6,539.17s6.5-11.06,9.66-9.73" />
            <polygon
              class="angel_devil-5"
              points="631.82 471.94 635.39 472.84 632.88 461.41 625.57 470.37 628.3 471.06 631.82 471.94"
            />
            <line
              class="angel_devil-5"
              x1="618.84"
              y1="505.71"
              x2="621.39"
              y2="496.38"
            />
            <line
              class="angel_devil-5"
              x1="623.13"
              y1="490.02"
              x2="628.3"
              y2="471.06"
            />
            <line
              class="angel_devil-5"
              x1="623.14"
              y1="508.53"
              x2="625.74"
              y2="497.58"
            />
            <line
              class="angel_devil-5"
              x1="627.25"
              y1="491.17"
              x2="631.82"
              y2="471.94"
            />
            <polygon
              class="angel_devil-5"
              points="645.64 467.81 651.22 467.28 652.51 467.15 647.08 458.22 645.64 467.81"
            />
            <polyline
              class="angel_devil-5"
              points="619.08 460.02 620.08 460.99 623.36 451.06 615.13 456.18 616 457.02"
            />
            <path
              class="angel_devil-5"
              d="m616,457.02c-1.61,1.24-8.12,6.57-9.16,15.43-1.12,9.6,4.56,20.06,14.54,23.93.72.28,1.47.53,2.24.74.7.19,1.4.35,2.11.46,10.53,1.79,20.55-4.31,24.65-12.85,3.72-7.77,1.51-15.39.83-17.45-1.42.15-2.83.29-4.25.44.21,1.33,1.36,9.63-4.41,16.43-.59.7-6.97,7.97-15.3,7.02-.62-.07-1.26-.19-1.9-.36-.78-.21-1.53-.47-2.23-.8-6.16-2.83-9.31-9.88-9.6-15.65-.39-7.85,4.51-13.25,5.55-14.35-1.02-1-2.05-2-3.08-2.99Z"
            />
            <polyline
              class="angel_devil-5"
              points="619.39 525.58 595.01 604.84 591.11 612.61 590.64 603.89 613.86 519.46"
            />
            <path
              class="angel_devil-5"
              d="m697.32,547.45c-1.62,5.11-3.93,11.05-7.28,17.38-4.53,8.56-9.6,15.26-13.97,20.24"
            />
          </g>
        </g>
        <path
          class="angel_devil-5"
          d="m688.61,469.84s-10.5-3.23-3.03-18.12c0,0-9.37,8.58-5.85,20.91"
        />
        <path
          class="angel_devil-5"
          d="m716.44,482.96s12.28,4.72,17.45-8.45c0,0-1.88,14.89-13.75,19.18"
        />
        <path
          class="angel_devil-5"
          d="m672.19,509.99s-3.01,12.27-5.84,12.81l-4.26-4.38-21.68,1.45-21.57-14.16s-4.58-7.07-5.16,2.67c0,0-4.01,2.29-.96,5.73,0,0-4.59,2.48,1.14,5.35l2.49-7.83,5.73,2.87-2.69,11.08s10.68,13,25.2,13.59c0,0-9.96,18.9-7.48,24.45,0,0-2.48.19-3.83,3.82l-23.17,42.39s-8.79,3.24-10.33,8.2c.81,1.29,2.19,3.09,4.39,4.59,4.96,3.39,10.36,2.73,11.86,2.5-.32.98-.58,2.35-.22,3.82,1.1,4.38,6.98,6.17,12.79,7.85,7.16,2.07,14.88,4.31,18.46.36,1.52-1.67,1.8-3.92,1.8-5.5l-.76-3.25c5.3-10.88,10.6-21.77,15.89-32.65l12.05-10.69s2.62,45.28,36.09,17.62l2.86,2.87,6.52-19.67-17.01,7.24,2.86,3.82s-12.24,10.11-20.06,2.84c0,0-3.62-6.12,11.29-14.89,0,0,12.05-14.51-1.29-37.45,0,0,0-8.6-4.57-11.85l-11.27-6.13,6.95-12.65"
        />
        <path
          class="angel_devil-5"
          d="m705.14,509.59l14.57,7.3s-28.1,11.45-26.96,18.71"
        />
        <path
          class="angel_devil-5"
          d="m668.75,490.92l-8.82,3.38s10.1,14.83,2.17,24.13"
        />
        <path
          class="angel_devil-5"
          d="m637.12,563.62s2.09,12.99,5.33,16.05c0,0,4.2,2.11.37,7.83l-15.89,30.74s-6.84.19-10.88,6.87"
        />
        <path
          class="angel_devil-5"
          d="m676.49,547.04l-14.35,28.62-5.94,5.17s-6.7,11.46,7.82,14.91"
        />
        <path class="angel_devil-5" d="m644.6,539.17s6.5-11.06,9.66-9.72" />
        <polygon
          class="angel_devil-5"
          points="625.57 470.37 635.39 472.84 632.89 461.41 625.57 470.37"
        />
        <line
          class="angel_devil-5"
          x1="618.84"
          y1="505.71"
          x2="621.39"
          y2="496.38"
        />
        <line
          class="angel_devil-5"
          x1="623.13"
          y1="490.02"
          x2="628.3"
          y2="471.06"
        />
        <line
          class="angel_devil-5"
          x1="623.14"
          y1="508.53"
          x2="625.74"
          y2="497.58"
        />
        <line
          class="angel_devil-5"
          x1="627.25"
          y1="491.17"
          x2="631.82"
          y2="471.94"
        />
        <polygon
          class="angel_devil-5"
          points="645.64 467.81 652.51 467.15 647.08 458.22 645.64 467.81"
        />
        <polygon
          class="angel_devil-5"
          points="615.13 456.18 616 457.02 620.08 460.99 623.36 451.06 615.13 456.18"
        />
        <path
          class="angel_devil-5"
          d="m616,457.02c-1.61,1.24-8.12,6.57-9.16,15.43-1.2,10.29,5.42,21.58,16.78,24.66,11.28,3.06,22.39-3.27,26.76-12.39,3.72-7.77,1.5-15.39.83-17.45-1.42.15-2.83.3-4.25.44.21,1.33,1.36,9.63-4.41,16.43-.64.75-7.96,9.1-17.2,6.67-7.63-2-11.51-10.01-11.83-16.44-.39-7.85,4.51-13.25,5.56-14.35-1.03-1-2.06-2-3.08-2.99Z"
        />
        <polyline
          class="angel_devil-5"
          points="619.39 525.58 595 604.84 591.11 612.61 590.64 603.9 613.86 519.46"
        />
        <path
          class="angel_devil-5"
          d="m697.32,547.45c-1.62,5.11-3.93,11.05-7.28,17.38-4.53,8.56-9.6,15.26-13.97,20.24"
        />
      </g>
    </g>
  );
};

export default AngelDevil;
