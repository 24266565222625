const FrownTwo = () => {
  return (
    <g transform="translate(507, 463)">
      <path d="M8.84,2.77c.67,2.09-.35,4.04-.42,6.17,.29-.16,.56-.29,.81-.45,3.05-1.99,6.58-3.22,10.2-4.23,6.74-1.88,13.7-2.9,20.8-3.21,2.03-.09,4.07-.14,6.11-.14,4.85,0,9.65,.46,14.36,1.46,.24,.05,.49,.05,1.01,.1-.64-.88-1.17-1.6-1.81-2.47,1.53,.75,1.91,1.14,3.1,3.09-.58,.65-1.2,1.34-1.83,2.04-.1-.05-.19-.1-.29-.15,.42-.58,.84-1.16,1.37-1.88-1.41-.15-2.7-.31-4-.42-4.42-.37-8.84-.24-13.27-.02-6.01,.29-11.96,.93-17.83,2.04-4.33,.81-8.58,1.85-12.53,3.57-1.95,.85-3.82,1.79-5.13,3.32-.52,.62-1.14,1.23-.33,2,.06,.05,.03,.16,.05,.25,.13,.73-.14,1.39-.69,1.54-.8,.23-1.27-.27-1.64-.68-1.26-1.39-1.69-2.98-1.24-4.67,.34-1.27,.82-2.52,1.36-3.74,.53-1.2,1.22-2.35,1.84-3.52Z" />
      <path d="M53.04,9.93c.69-.66,1.2-1.14,1.81-1.71-1.58-.5-3.02-.11-4.4,.32-1.35,.42-2.62,1.01-3.99,1.44,1.87-1.59,4.12-2.53,6.8-2.72,.66-.05,1.33-.04,1.99,0,.86,.07,1.27,.57,1.09,1.23-.25,.93-1.64,1.65-3.31,1.44Z" />
      <path d="M1.75,14.37c-1.22-.3-1.59-1.01-1.69-1.74-.11-.87-.08-1.77,.1-2.63,.48-2.27,1.71-4.32,2.97-6.34,.59-.95,1.2-1.89,2.06-2.73-.54,.98-1.1,1.95-1.63,2.94-1.03,1.93-1.96,3.88-2.43,5.97-.27,1.19-.36,2.33,.3,3.46,.17,.28,.19,.63,.31,1.08Z" />
    </g>
  );
};

export default FrownTwo;
