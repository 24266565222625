const Naughty = () => {
  const styles = {
    one: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    two: {
      fill: "#ed1b25",
    },
    three: {
      fill: "#bc1526",
    },
  };

  return (
    <g>
      <g>
        <path
          style={styles.two}
          d="M553.56,252.61c-.46,5.53-1.76,28.77,14.62,49.83,13.22,17,30.37,22.81,36.54,24.58,1.2,.09,3.13,.07,5.32-.66,3.15-1.05,5.11-2.99,5.98-3.99,9.77-11.53,23.59-31.08,31.23-58.47,15.04-53.99-4.81-99.5-11.29-112.95-.5,9-3.3,39.18-27.24,65.11-17.81,19.29-38.38,26.55-47.84,29.23-.88,.23-2.86,.86-4.65,2.66s-2.43,3.77-2.66,4.65Z"
        />
        <path
          style={styles.three}
          d="M635.95,150.96c4.95,22.33,9.19,54.46,3.32,91.69-7.93,50.29-29.95,82.01-27.9,83.05,2.35,1.19,36.89-37.88,41.19-93.01,2.87-36.82-8.89-66.21-16.61-81.72Z"
        />
        <path
          style={styles.one}
          d="M553.56,252.61c-.46,5.53-1.76,28.77,14.62,49.83,13.22,17,30.37,22.81,36.54,24.58,1.2,.09,3.13,.07,5.32-.66,3.15-1.05,5.11-2.99,5.98-3.99,9.77-11.53,23.59-31.08,31.23-58.47,15.04-53.99-4.81-99.5-11.29-112.95-.5,9-3.3,39.18-27.24,65.11-17.81,19.29-38.38,26.55-47.84,29.23-.88,.23-2.86,.86-4.65,2.66s-2.43,3.77-2.66,4.65Z"
        />
      </g>
      <g>
        <path
          style={styles.two}
          d="M504.77,250.61c.46,5.53,1.76,28.77-14.62,49.83-13.22,17-30.37,22.81-36.54,24.58-1.2,.09-3.13,.07-5.32-.66-3.15-1.05-5.11-2.99-5.98-3.99-9.77-11.53-23.59-31.08-31.23-58.47-15.04-53.99,4.81-99.5,11.29-112.95,.5,9,3.3,39.18,27.24,65.11,17.81,19.29,38.38,26.55,47.84,29.23,.88,.23,2.86,.86,4.65,2.66s2.43,3.77,2.66,4.65Z"
        />
        <path
          style={styles.three}
          d="M422.39,148.96c-4.95,22.33-9.19,54.46-3.32,91.69,7.93,50.29,29.95,82.01,27.9,83.05-2.35,1.19-36.89-37.88-41.19-93.01-2.87-36.82,8.89-66.21,16.61-81.72Z"
        />
        <path
          style={styles.one}
          d="M504.77,250.61c.46,5.53,1.76,28.77-14.62,49.83-13.22,17-30.37,22.81-36.54,24.58-1.2,.09-3.13,.07-5.32-.66-3.15-1.05-5.11-2.99-5.98-3.99-9.77-11.53-23.59-31.08-31.23-58.47-15.04-53.99,4.81-99.5,11.29-112.95,.5,9,3.3,39.18,27.24,65.11,17.81,19.29,38.38,26.55,47.84,29.23,.88,.23,2.86,.86,4.65,2.66s2.43,3.77,2.66,4.65Z"
        />
      </g>
    </g>
  );
};

export default Naughty;
