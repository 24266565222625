const SideEye = () => {
  return (
    <g>
      <rect x="446" y="354" width="44" height="7" />
      <rect x="582" y="354" width="44" height="7" />
      <circle cx="480.5" cy="363.5" r="6.5" />
      <circle cx="617.5" cy="363.5" r="6.5" />
    </g>
  );
};

export default SideEye;
