import { Grid } from "@mui/material";
import { BiCheck } from "react-icons/bi";
import {
  black,
  card,
  cardVeryLight,
  container,
  purple,
  transition,
} from "../../utils/themeContstants";

const CustomRadio = ({ selected, onClick, color, checkColor }) => {
  const styles = {
    container: {
      borderRadius: 100,
      border: `2px solid ${cardVeryLight}`,
      backgroundColor: selected ? (color ? color : purple) : container,
      height: 30,
      width: 30,
      transition: transition,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: selected ? (color ? color : purple) : card,
      },
    },
  };

  return (
    <Grid item sx={styles.container} onClick={onClick}>
      {selected && (
        <BiCheck
          style={{ fontSize: 18, color: checkColor ? checkColor : container }}
        />
      )}
    </Grid>
  );
};

export default CustomRadio;
