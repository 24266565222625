const WrapItUpTwo = () => {
  const styles = {
    one: {
      fill: "#e0dddc",
    },
    two: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      fill: "#ababab",
    },
  };

  return (
    <g>
      <g>
        <g>
          <path
            style={styles.one}
            d="M665.5,366.5c-.18,3.18-.48,7.65-1,13-.13,1.35-.55,5.7-1,9-.56,2.23-1.04,4.85-1.53,7.64v.07s-1.03,5.09-1.03,5.09c-.65,2.53-1.46,5.26-2.44,8.2-33.14,17.61-92.23,42.31-165.83,37.43-19.22-1.27-36.69-4.38-52.15-8.32-5.94-6.21-12.02-13.43-17.76-21.69-4.69-6.76-8.58-13.38-11.81-19.74-2.98-7.32-4.03-12.07-8.2-19.17,17.52,5.55,39.04,10.68,63.89,13.11,12.78,1.25,24.71,1.61,35.6,1.43,1.55-.03,3.09-.29,4.57-.75,8.23-2.55,14.32-4.99,18.6-6.88,2.89-1.27,7.39-3.44,13.65-4.06,1.75-.17,4.4-.33,7.48-.1,8.54,.63,13.87,3.76,15.98,4.84,5,2.54,14.05,4.81,31.93,3.19,16.48-1.49,34.8-2.54,55.88-13.17,6.57-3.32,11.71-6.67,15.17-9.12Z"
          />
          <path
            style={styles.one}
            d="M655.87,418.83c-.29,.91-.59,1.83-.88,2.76-.17,.29-.33,.59-.49,.91-.87,1.74-1.04,2.84-1.46,5.16,.03-.08,0,.02,0,.02-1.02,3.17-1.94,5.99-2.54,7.82-4.58,13.84-16.98,36.5-52,75,.81-.51,1.59-1.01,2.36-1.52-8.19,6.06-14.89,9.73-16.36,10.52-7.5,4.06-18.87,9.21-33.87,12.47-6.89,1.5-12.94,3.31-21.45,3.87-6.8,.45-12.54,.11-16.68-.34,6.75-3.42,14.03-7.56,21.59-12.57,.27-.17,.54-.36,.82-.54,1.6-1.05,3.23-2.15,4.86-3.29,10.09-7.06,18.33-14.27,24.86-20.73,.42-.27,.85-.56,1.29-.86l27.48-18.3c9.47-5.07,16.36-9.13,22.05-12.87,4.9-2.95,10.38-6.82,15.83-11.83,.01,0,.02-.01,.02-.02,.4-.32,.8-.65,1.2-.99,8.18-6.81,12.73-13.36,18.63-24.95l1.14-2.28c1.13-2.28,2.32-4.75,3.6-7.44Z"
          />
          <path
            style={styles.one}
            d="M658.5,409.5c-1.28,4.76-3.22,10.53-6.23,16.77l-1.14,2.28c-1.61,3.11-3.49,6.32-5.69,9.56-4.4,6.48-9.26,11.89-14.14,16.38,0,.01,0,.02-.02,.02-5.05,4.17-9.76,7.82-15.83,11.83-4.74,2.86-8.93,4.85-12.02,6.19-8.05,3.18-17.58,6.45-28.45,9.25-.04,.02-.09,.03-.14,.04-2.53,.57-5.25,1.15-8.19,1.71-17.18,3.31-42.47,6.58-74.15,4.97-10.09-7.71-20.96-16.97-32-28-7.48-7.48-14.12-14.84-19.98-21.89,19.8,5.31,44.98,9.82,74.2,9.76,66.32-.13,117.06-23.73,143.78-38.87Z"
          />
          <path
            style={styles.one}
            d="M653.04,427.68c-.14,.78-.31,1.7-.54,2.82,0,0-.49,2.36-2,8-2.05,7.61-5.27,14.39-7,18-3.74,7.78-9.85,20.5-22,34-8.2,9.11-15.84,14.89-20,18-.21,.16-.43,.32-.64,.48-.77,.51-1.55,1.01-2.36,1.52,35.02-38.5,47.42-61.16,52-75,.6-1.83,1.52-4.65,2.54-7.82Z"
          />
          <path
            style={styles.one}
            d="M603.43,472.53l-10.03,6.68c-.35,.18-.7,.37-1.05,.56-11.4,6.08-19.86,13.25-26.43,17.74-.44,.3-.87,.59-1.29,.86-4.39,.96-9.06,1.86-13.99,2.67-14.31,2.37-27.33,3.41-38.53,3.75-6.54-5.43-13.07-10.86-19.61-16.29,15.29,1.38,35.31,1.73,58.35-1.77,8.58-1.31,16.6-3,23.99-4.91,.05-.01,.1-.02,.14-.04,16.31-3.73,24.48-7.52,28.45-9.25Z"
          />
          <path
            style={styles.one}
            d="M564.63,498.37c-4.6,4.46-9.75,9.04-15.46,13.6-4.82,3.83-9.59,7.29-14.26,10.42-.28,.18-.55,.37-.82,.54-8.11,5.25-15.79,9.36-22.59,12.57-3.55,.22-7.96,.32-13,0-2.6-.16-8.45-.63-15.83-2.23-9.68-2.11-16.62-5-23.17-7.77-5.38-2.28-12.27-5.51-20-10-8.48-6.89-26.85-22.9-35.22-41.22-.08-.17-.16-.34-.22-.51-2.99-7.15-4.77-13.62-5.71-17.07-2.45-8.93-3.81-17.25-4.52-24.67h.01c7.9,8.73,25.7,27.44,48,44.99,4.49,3.79,9.05,7.49,13.57,10.98,6.48,4.99,15.23,11.34,28.65,14.9,.05,.01,.1,.03,.15,.04,4.85,1.48,10.56,2.53,17.2,2.59,11.26,.2,25.69-.33,42.2-2.91,7.78-1.22,14.82-2.71,21.02-4.25Z"
          />
          <path
            style={styles.one}
            d="M512.11,504.79c-2.54,.37-6.29,.78-10.7,.74-1.63-.08-4.12-.25-7.07-.63-3.65-.47-7.02-1.14-10.13-1.96-.05-.01-.1-.03-.15-.04-6.2-1.9-10.97-4.49-14.4-6.54-9.74-5.83-19.12-12.5-27.82-19.34-23.35-19.71-44.68-41.56-48-44.98h-.01s-.06-.07-.09-.11c-1.71-1.89-2.94-3.3-3.64-4.1-.11-.13-.21-.25-.32-.38-.22-.27-.44-.54-.66-.82,0-.01-.02-.02-.03-.04-5.32-6.98-9.45-14.12-12.44-21.42-.01-.03-.03-.06-.04-.1-1.17-2.97-2.23-6.16-3.11-9.57-3.02-11.69-2.95-22.05-2.13-29.64,.37-2.45,.75-4.91,1.13-7.36,.59-.64,1.37-1.63,2-3,.73-1.61,.94-3.06,1-4,8.16,4.8,19.3,12.99,27.25,26.51,2.12,6.01,4.79,12.45,8.2,19.17,.18,.43,.36,.87,.55,1.32,6.38,15.1,14.34,24.45,26.17,38.34,27.14,31.87,66.3,61.79,74.44,67.95Z"
          />
          <path
            style={styles.one}
            d="M439.5,515.5c-10.71-6.29-24.14-15.82-37-30-25.56-28.17-34.55-58.84-38-75,2.26-14.65,4.51-29.31,6.76-43.96-1.42,13.27,.3,26.12,5.35,38.53,0,.04,.03,.07,.04,.1,3.66,9.25,8.43,16.4,12.44,21.42,.01,.02,.02,.03,.03,.04,.1,.13,.2,.27,.3,.4h0s.12,.16,.36,.42c.11,.13,.21,.25,.32,.38,1.35,1.63,2.59,3,3.64,4.1,.03,.04,.06,.07,.09,.1,.61,7.76,2.18,19.2,6.67,32.47,0,0,1.45,4.29,3,8,.18,.42,.36,.85,.56,1.27,.06,.17,.14,.34,.22,.51,.97,2.3,2.07,4.67,3.31,7.02,3.94,7.48,12.9,21.69,31.91,34.2Z"
          />
        </g>
        <g>
          <path
            style={styles.two}
            d="M402.75,378.01c17.52,5.55,39.04,10.68,63.89,13.11,12.78,1.25,24.71,1.61,35.6,1.43,1.55-.03,3.09-.29,4.57-.75,8.23-2.55,14.32-4.99,18.6-6.88,2.89-1.27,7.39-3.44,13.65-4.06,1.75-.17,4.4-.33,7.48-.1,8.54,.63,13.87,3.76,15.98,4.84,5,2.54,14.05,4.81,31.93,3.19,16.48-1.49,34.8-2.54,55.88-13.17,6.57-3.32,11.71-6.67,15.17-9.12-.18,3.18-.48,7.65-1,13-.13,1.35-.55,5.7-1,9-.4,2.93-1.04,6.66-2.13,11.09-.13,.56-.27,1.13-.43,1.71-.65,2.53-1.46,5.26-2.44,8.2-33.14,17.61-92.23,42.31-165.83,37.43-19.22-1.27-36.69-4.38-52.15-8.32-5.94-6.21-12.02-13.43-17.76-21.69-4.69-6.76-8.58-13.38-11.81-19.74-3.41-6.72-6.08-13.16-8.2-19.17Z"
          />
          <path
            style={styles.two}
            d="M574.98,481.78c16.31-3.73,24.48-7.52,28.45-9.25,3.09-1.34,7.28-3.33,12.02-6.19,4.9-2.95,10.38-6.82,15.83-11.83"
          />
          <path
            style={styles.two}
            d="M652.27,426.27c3.01-6.24,4.95-12.01,6.23-16.77-26.72,15.14-77.46,38.74-143.78,38.87-29.22,.06-54.4-4.45-74.2-9.76,5.86,7.05,12.5,14.41,19.98,21.89,11.04,11.03,21.91,20.29,32,28,31.68,1.61,56.97-1.66,74.15-4.97,2.94-.56,5.66-1.14,8.19-1.71"
          />
          <path
            style={styles.two}
            d="M631.3,454.49c4.88-4.49,9.74-9.9,14.14-16.38,2.2-3.24,4.08-6.45,5.69-9.56"
          />
          <path
            style={styles.two}
            d="M484.21,502.94c4.85,1.48,10.56,2.53,17.2,2.59,4.41,.04,8.16-.37,10.7-.74-8.14-6.16-47.3-36.08-74.44-67.95-11.83-13.89-19.79-23.24-26.17-38.34-.19-.45-.37-.89-.55-1.32-2.98-7.32-4.03-12.07-8.2-19.17-7.95-13.52-19.09-21.71-27.25-26.51-.06,.94-.27,2.39-1,4-.63,1.37-1.41,2.36-2,3-.1,.51-.2,1.02-.29,1.53"
          />
          <path
            style={styles.two}
            d="M393.84,432.04c7.9,8.72,25.7,27.43,48,44.98,8.7,6.84,18.08,13.51,27.82,19.34,3.43,2.05,8.2,4.64,14.4,6.54"
          />
          <path style={styles.two} d="M390.1,427.83c.7,.8,1.93,2.21,3.64,4.1" />
          <path
            style={styles.two}
            d="M389.12,426.63c.1,.13,.2,.27,.3,.4h0s.12,.16,.36,.42"
          />
          <path
            style={styles.two}
            d="M376.65,405.17c2.99,7.3,7.12,14.44,12.44,21.42"
          />
          <path
            style={styles.two}
            d="M371.78,362.62c-.21,1.31-.38,2.62-.52,3.92-1.42,13.27,.3,26.12,5.35,38.53"
          />
          <path
            style={styles.two}
            d="M564.63,498.37c-4.39,.96-9.06,1.86-13.99,2.67-14.31,2.37-27.33,3.41-38.53,3.75-6.54-5.43-13.07-10.86-19.61-16.29,15.29,1.38,35.31,1.73,58.35-1.77,8.58-1.31,16.6-3,23.99-4.91,.05-.01,.1-.02,.14-.04,10.87-2.8,20.4-6.07,28.45-9.25l-10.03,6.68-27.48,18.3"
          />
          <path
            style={styles.two}
            d="M534.91,522.39c1.6-1.05,3.23-2.15,4.86-3.29,10.09-7.06,18.33-14.27,24.86-20.73-6.2,1.54-13.24,3.03-21.02,4.25-16.51,2.58-30.94,3.11-42.2,2.91-1.63-.08-4.12-.25-7.07-.63-3.65-.47-7.02-1.14-10.13-1.96-.05-.01-.1-.03-.15-.04-13.42-3.56-22.17-9.91-28.65-14.9-4.52-3.49-9.08-7.19-13.57-10.98-23.35-19.71-44.68-41.56-48-44.98h-.01c.71,7.41,2.07,15.73,4.52,24.66,.94,3.45,2.72,9.92,5.71,17.07"
          />
          <path
            style={styles.two}
            d="M404.28,474.28c.97,2.3,2.07,4.67,3.31,7.02,3.94,7.48,12.9,21.69,31.91,34.2,7.73,4.49,14.62,7.72,20,10,6.55,2.77,13.49,5.66,23.17,7.77,7.38,1.6,13.23,2.07,15.83,2.23,5.04,.32,9.45,.22,13,0,6.8-3.21,14.48-7.32,22.59-12.57"
          />
          <path
            style={styles.two}
            d="M661.97,396.14c.49-2.79,.97-5.41,1.53-7.64l-1.53,7.64"
          />
          <path
            style={styles.two}
            d="M653.04,427.68c-.14,.78-.31,1.7-.54,2.82,0,0-.49,2.36-2,8-2.05,7.61-5.27,14.39-7,18-3.74,7.78-9.85,20.5-22,34-8.2,9.11-15.84,14.89-20,18-.21,.16-.43,.32-.64,.48-8.19,6.06-14.89,9.73-16.36,10.52-7.5,4.06-18.87,9.21-33.87,12.47-6.89,1.5-12.94,3.31-21.45,3.87-6.8,.45-12.54,.11-16.68-.34,6.75-3.42,14.03-7.56,21.59-12.57,.27-.17,.54-.36,.82-.54,4.67-3.13,9.44-6.59,14.26-10.42,5.71-4.56,10.86-9.14,15.46-13.6,.42-.27,.85-.56,1.29-.86,6.57-4.49,15.03-11.66,26.43-17.74,.35-.19,.7-.38,1.05-.56,9.47-5.07,16.36-9.13,22.05-12.87,6.07-4.01,10.78-7.66,15.83-11.83,.01,0,.02-.01,.02-.02,.4-.32,.8-.65,1.2-.99,8.18-6.81,12.73-13.36,18.63-24.95l1.14-2.28c1.13-2.28,2.32-4.75,3.6-7.44,.21-.44,.42-.88,.63-1.33,.35-.71,.69-1.46,1-2.24l.27-.69c1.66-4.46,2.7-9.85,3.6-14.98,.2-1.14,.4-2.27,.59-3.38"
          />
          <path
            style={styles.two}
            d="M656.83,417.65c-.05,.14-.1,.3-.16,.46-.61,1.62-1.15,2.53-1.68,3.48-.17,.29-.33,.59-.49,.91-.87,1.74-1.04,2.84-1.46,5.16"
          />
          <path
            style={styles.two}
            d="M658.58,411.53c-.33,1.11-.59,1.99-.8,2.73-.11,.38-.2,.7-.28,1-.16,.6-.27,1.08-.4,1.54"
          />
          <path
            style={styles.two}
            d="M661.96,396.21l-1.02,5.09-1.44,7.2c-.34,1.11-.63,2.06-.88,2.89"
          />
          <line
            style={styles.two}
            x1="661.96"
            y1="396.21"
            x2="661.97"
            y2="396.14"
          />
          <path
            style={styles.two}
            d="M659.49,407.52c-.25,1.25-.54,2.54-.87,3.87-.01,.04-.02,.09-.04,.14-.24,.99-.52,2.01-.81,3.04l-.27,.93c-.13,.44-.26,.87-.4,1.3-.08,.29-.17,.57-.27,.85-1.04,3.24-2.18,6.19-3.33,8.85-1.49,7.64-4.11,17.75-9,29-3.26,7.5-9.28,21.06-22,35-4.78,5.25-11.85,12.01-21.64,18.48-.77,.51-1.55,1.01-2.36,1.52,35.02-38.5,47.42-61.16,52-75,.6-1.83,1.52-4.65,2.54-7.82,0,0,.03-.1,0-.02,.63-1.95,1.29-4.02,1.95-6.07,.29-.93,.59-1.85,.88-2.76,1.89-5.91,3.51-11,3.62-11.31t.01-.02"
          />
          <path
            style={styles.two}
            d="M372.5,358.5c-.38,2.45-.76,4.91-1.13,7.36-.04,.23-.07,.45-.11,.68-2.25,14.65-4.5,29.31-6.76,43.96,3.45,16.16,12.44,46.83,38,75,12.86,14.18,26.29,23.71,37,30-8.48-6.89-26.85-22.9-35.22-41.22-.08-.17-.16-.34-.22-.51-.2-.42-.38-.85-.56-1.27-1.55-3.71-3-8-3-8-4.49-13.27-6.06-24.71-6.67-32.47-.03-.03-.06-.06-.09-.1-1.05-1.1-2.29-2.47-3.64-4.1-.11-.13-.21-.25-.32-.38-.22-.27-.44-.54-.66-.82,0-.01-.02-.02-.03-.04-4.01-5.02-8.78-12.17-12.44-21.42-.01-.03-.03-.06-.04-.1-1.17-2.97-2.23-6.16-3.11-9.57-3.02-11.69-2.95-22.05-2.13-29.64,.12-1.14,.26-2.23,.41-3.24,.14-.92,.28-1.78,.43-2.59,.1-.54,.19-1.05,.29-1.53Z"
          />
        </g>
      </g>
      <path
        style={styles.three}
        d="M556,383c.94,1.7,8.07,15.08,2,29-5.63,12.91-20.27,20.88-36,19,7.95,4.85,11.89,10.21,14,14,8.26,14.88,1.47,29.46-3,68-1.13,9.71-1.72,17.65-2.05,22.7,12.98-1.93,69.2-11.75,102.05-60.7,10.78-16.07,16.17-32.06,18.98-44.67,3.75-10.24,7.39-22.42,10.02-36.33,1.88-9.93,2.95-19.17,3.5-27.5-5.96,6.36-20.08,19.64-42.5,25.5-33.46,8.74-60.72-5.51-67-9Z"
      />
      <path
        style={styles.three}
        d="M366.49,397.56c3.62,12.68,14.18,43.28,43.51,69.44,27.79,24.78,57.46,32.21,70.48,34.68-28.26-16.37-49.22-33.02-63.48-45.68-22.28-19.78-30.6-31.93-36-42-6.87-12.82-10.24-24.57-12.01-32.71-.83,5.42-1.67,10.85-2.5,16.27Z"
      />
      <g>
        <path
          style={styles.two}
          d="M665.5,366.5c-.18,3.18-.48,7.65-1,13-.13,1.35-.55,5.7-1,9-.67,4.91-2.01,12.05-5,21-33.14,17.61-92.23,42.31-165.83,37.43-19.23-1.27-36.69-4.39-52.15-8.32-5.94-6.21-12.02-13.43-17.77-21.69-9.63-13.87-15.88-27.2-20.01-38.91,17.52,5.55,39.04,10.68,63.89,13.11,12.78,1.25,24.7,1.62,35.59,1.43,1.55-.03,3.09-.29,4.57-.75,8.23-2.55,14.33-4.99,18.6-6.88,2.89-1.27,7.39-3.44,13.65-4.06,1.76-.17,4.4-.33,7.48-.11,8.54,.63,13.87,3.77,15.98,4.84,4.99,2.54,14.05,4.82,31.92,3.2,16.49-1.49,34.8-2.55,55.88-13.18,6.57-3.31,11.71-6.67,15.17-9.12Z"
        />
        <path
          style={styles.two}
          d="M603.43,472.53c9.14-3.96,27.94-13.67,42.01-34.42,7.22-10.64,10.99-20.94,13.06-28.61-26.72,15.14-77.46,38.74-143.78,38.87-29.22,.06-54.39-4.45-74.19-9.76,5.86,7.05,12.49,14.41,19.98,21.89,11.04,11.03,21.91,20.29,32,28,31.68,1.61,56.97-1.66,74.15-4.97,21.97-4.23,32.18-9.01,36.79-11Z"
        />
        <path
          style={styles.two}
          d="M372.5,358.5c-4.83,24.22,.45,47.13,16.92,68.53,0,0,0,0,0,0,.12,.14,36.52,43.18,80.24,69.33,6.13,3.67,16.53,9.04,31.75,9.17,4.4,.04,8.15-.37,10.7-.74-8.14-6.16-47.3-36.09-74.44-67.96-11.83-13.89-19.79-23.23-26.17-38.34-3.42-8.09-4.33-12.97-8.75-20.49-7.95-13.52-19.09-21.71-27.25-26.51-.06,.94-.27,2.39-1,4-.63,1.37-1.41,2.36-2,3Z"
        />
        <path
          style={styles.two}
          d="M603.43,472.53l-38.8,25.84c-4.4,.95-9.07,1.85-14,2.67-14.31,2.36-27.33,3.4-38.52,3.75-6.54-5.43-13.07-10.86-19.61-16.29,15.29,1.38,35.31,1.73,58.35-1.77,21.26-3.24,39.02-8.84,52.59-14.2Z"
        />
        <path
          style={styles.two}
          d="M393.83,432.03c.71,7.42,2.06,15.74,4.52,24.67,1.27,4.64,4.04,14.75,9.24,24.6,3.94,7.48,12.91,21.69,31.91,34.2,7.73,4.49,14.62,7.72,20,10,6.55,2.77,13.49,5.66,23.17,7.77,7.38,1.6,13.24,2.07,15.83,2.23,5.04,.32,9.45,.22,13,0,8.37-3.95,18.07-9.26,28.27-16.4,10.09-7.06,18.33-14.27,24.87-20.73-6.21,1.54-13.25,3.03-21.03,4.24-16.51,2.58-30.94,3.11-42.19,2.92-1.63-.08-4.13-.25-7.08-.63-19.46-2.51-30.92-10.73-38.93-16.9-28.08-21.64-57.65-51.93-61.58-55.98Z"
        />
        <path
          style={styles.two}
          d="M663.5,388.5l-4,20c-.82,2.67-1.35,4.45-1.72,5.76-.52,1.81-.62,2.55-1.11,3.85-.79,2.12-1.47,3.01-2.17,4.39-1.17,2.33-1.07,3.5-2,8,0,0-.49,2.36-2,8-2.05,7.61-5.27,14.39-7,18-3.74,7.78-9.85,20.5-22,34-8.2,9.11-15.84,14.89-20,18-8.48,6.35-15.49,10.19-17,11-7.5,4.06-18.87,9.21-33.87,12.47-6.9,1.5-12.94,3.31-21.45,3.88-6.8,.45-12.54,.1-16.68-.34,11.16-5.66,23.78-13.27,36.67-23.53,5.72-4.55,10.86-9.13,15.47-13.59,6.75-4.39,15.56-12.12,27.72-18.6,21.15-11.27,29.65-17.53,40.15-26.27,10.47-8.72,15-17,24-36,4-8,5-21,7-29Z"
        />
        <path
          style={styles.two}
          d="M659.5,407.5c-.51,2.51-1.17,5.19-2,8-1.21,4.09-2.6,7.76-4,11-1.49,7.64-4.11,17.75-9,29-3.26,7.5-9.28,21.06-22,35-5.16,5.66-12.98,13.09-24,20,35.02-38.5,47.42-61.16,52-75,2.3-6.94,9.08-28.37,9-28Z"
        />
      </g>
      <path
        style={styles.two}
        d="M372.5,358.5c-2.67,17.33-5.33,34.67-8,52,3.45,16.16,12.44,46.83,38,75,12.86,14.18,26.29,23.71,37,30-8.75-7.11-28.05-23.94-36-43-1.55-3.71-3-8-3-8-4.49-13.27-6.06-24.71-6.67-32.47-5.26-5.52-15.48-17.75-20.33-36.53-4.16-16.12-2.46-29.71-1-37Z"
      />
    </g>
  );
};

export default WrapItUpTwo;
