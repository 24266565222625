const TurtleNeck = ({ color, skinColor }) => {
  const styles = {
    one: {
      fill: skinColor,
    },
    two: {
      fill: color,
    },
    three: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
  };

  return (
    <g>
      <path
        style={styles.two}
        d="m288.78,746.86c-.4-14.89.38-33.27,4.22-53.91,2.91-15.7,6.98-29.41,11.15-40.89,18.82-16.26,44.95-35.52,78.85-52.06,24.12-11.77,46.61-19.45,65.69-24.57,21.07,1.84,42.13,3.67,63.2,5.51,19.71,2.58,50.2,9.07,83.11,27.06,26.46,14.46,45.41,31.41,57.79,44.37,4.94,15.77,9.6,33.7,13.21,53.58,4.64,25.54,6.56,48.82,7.05,68.96-14.2,12.19-36.17,28.56-66.05,42-51.38,23.12-97.39,24.63-120,23.99-46.92-1.33-82.48-14.45-100-21.99-49.98-21.53-81.86-53.17-98.22-72.04Z"
      />
      <path
        style={styles.one}
        d="m518.87,533.53c-1.93,5.66-3.14,10.23-3.91,13.46-.37,1.56-.46,2.04-1.83,7.96-1.43,6.18-1.46,6.21-1.7,7.43-.91,4.8-1.06,8.01-1.17,10.7-.26,6.12.32,7.46,1.17,8.35.49.51,1.02.81,1.4.98,5.26,4.47,5.85,8.04,5.77,9.96-.35,9.12-16.94,15.17-27,16.97-23.01,4.12-44.54-8.01-48-17.09-.73-1.93-2.88-7.57-.23-11.22.48-.66,1.01-1.11,1.37-1.37.58-.37,1.48-1.02,2.38-2.02,0,0,1.01-.93,1.57-2.22,1.09-2.53,5.86-23.75,11.97-52.88,7.52,3.12,17.19,6.39,28.72,8.56,11.36,2.13,21.42,2.6,29.48,2.43Z"
      />
      <polygon
        style={styles.two}
        points="513.65 551.16 511.29 562.16 522.62 555.03 513.65 551.16"
      />
      <path d="m460,523c1.9,1.69,3.9,3.36,6,5,15.78,12.32,32.48,19.14,47,23,1.96-5.48,3.91-10.96,5.87-16.44-4.61.02-9.68-.16-15.13-.64-.79-.07-6.86-.61-12.73-1.62-12.45-2.13-23.75-6.29-31-9.31Z" />
      <g>
        <path d="m460.98,525.94s-11.31,49.7-11.31,49.7c0,0-.13.58-.13.58-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74l11.31-49.7s1.95.44,1.95.44h0Z" />
        <path d="m518.91,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59,0,0,1.88.69,1.88.69h0Z" />
      </g>
      <path
        style={styles.two}
        d="m423.13,583.22c12.84,25.56,39.03,41.07,66.13,39.33,33.94-2.19,51.98-30.31,53.74-33.15-1.16-.12-2.55-.39-4.04-.96-4.05-1.54-6.46-4.36-7.57-5.87-1.47-7.68-2.95-15.37-4.42-23.05-.03-.24-.36-2.72-2.49-3.78-.96-.48-1.88-.47-2.37-.42-4.03,2.1-8.71,4.04-14.03,5.44-24.17,6.33-45.44-2.82-54.08-7.19.29-1.78.59-3.57.88-5.35-1.62-.14-4.15-.12-6.91.95-6.63,2.58-9.02,9.07-9.42,10.21-.75,3.49-1.51,6.99-2.26,10.48l-4.29,5.75-2.8,4.77c-2.02.95-4.04,1.91-6.07,2.86Z"
      />
      <path
        style={styles.three}
        d="m429.07,578.63c-.25.92.03,1.9.69,2.59,3.14,3.26,7.75,7.44,13.95,11.3,19.28,12,38.36,11.19,54.15,10.52,0,0,23.29-.99,34.57-11.22.9-.81,3.34-3.03,2.87-5.22,0,0,0-.01,0-.02-.16-.72-.7-1.3-1.35-1.65-.72-.38-1.76-1.11-2.56-2.38-.91-1.44-1.04-2.86-1.04-3.57,0-.14,0-.28-.03-.42-.95-5.86-1.89-11.72-2.84-17.59-.02-.12-.04-.23-.08-.35-.22-.71-.64-1.78-1.49-2.86-.41-.52-.84-.95-1.26-1.3-.8-.67-1.92-.81-2.88-.38-6.74,3.02-19.97,7.76-36.95,6.5-17.51-1.29-30.17-8.3-36.34-12.34-.76-.5-1.71-.59-2.55-.24-.93.38-2.01.95-3.07,1.81-3.2,2.62-4.07,6.17-4.32,7.53-.03.15-.04.3-.04.45,0,1.85-.22,4.44-1.17,7.36-1.12,3.43-2.81,5.96-4.13,7.6-.32.39-.74.66-1.22.82-.66.22-1.65.7-2.35,1.76-.29.44-.47.88-.58,1.28Z"
      />
      <path
        style={styles.three}
        d="m454.89,548.2c-1.35-.1-3.27-.08-5.47.49-1.4.36-2.56.86-3.47,1.33"
      />
      <path
        style={styles.three}
        d="m524.66,556.48c-.99-.8-2.27-1.68-3.85-2.47-2.77-1.37-5.33-1.87-7.12-2.06"
      />
      <path
        style={styles.three}
        d="m429.2,580.36l-7.07,2.78c2.48,3.94,6.58,9.53,12.94,14.76,16.73,13.76,36.02,14.27,46.56,14.54,6.4.17,46.73,1.21,59.48-21.97.98-1.78,1.6-3.34,1.97-4.41"
      />
      <path style={styles.three} d="m449.88,551.16s-9.19,28.68-15.94,34.06" />
      <path style={styles.three} d="m458.22,555.67s-4.05,26.98-12.28,38.17" />
      <path style={styles.three} d="m465.88,558.75s-3.34,28.21-13.02,38.57" />
      <path style={styles.three} d="m472.82,560.75s-2.73,30.1-8.01,40.47" />
      <path style={styles.three} d="m480.48,562.16s-3.85,34.9-5.61,40.69" />
      <path style={styles.three} d="m487.89,562.77s-.39,29.84-2.15,40.6" />
      <path style={styles.three} d="m495.62,562.64s1.48,35.28,0,40.73" />
      <path style={styles.three} d="m502.37,561.87s4.14,26.83,2.41,40.47" />
      <path style={styles.three} d="m509.2,560.47s4.9,34.21,4.07,40.27" />
      <path style={styles.three} d="m515.78,558.49s6.69,31.07,6.84,39.26" />
      <path style={styles.three} d="m521.78,556.11s8.37,31.48,8.57,37.42" />
      <path style={styles.three} d="m454,553.56s-9.82,29.39-14.43,36.18" />
      <path style={styles.three} d="m429.77,581.22s-3.67,4.06-5.55,5.03" />
      <path style={styles.three} d="m436.29,587.22s-3.38,6.27-4.94,7.35" />
      <path style={styles.three} d="m442.85,591.97s-2.84,5.44-5.07,8.03" />
      <path style={styles.three} d="m449.33,595.67s-1.89,6.46-4.12,8.94" />
      <path
        style={styles.three}
        d="m456.86,598.89c-.13-.05-1.73,7.97-2.86,9.45"
      />
      <path style={styles.three} d="m465.88,601.46s-.97,8.62-1.71,9.47" />
      <path style={styles.three} d="m472.82,602.63s.53,7.66-.76,9.34" />
      <path style={styles.three} d="m480.48,602.86s.81,8.11,0,9.54" />
      <path style={styles.three} d="m487.89,603.36s.49,7.09,0,9.12" />
      <path style={styles.three} d="m494.45,603.37s1.7,7.79.55,9.03" />
      <path style={styles.three} d="m432.65,584.05s-2.97,6.39-4.78,6.84" />
      <path style={styles.three} d="m502.37,602.63s1.33,6.02.76,8.61" />
      <path style={styles.three} d="m509.2,601.61s1.63,6.84,1.07,8.31" />
      <path style={styles.three} d="m515.6,600.14s2.28,6.3,2.06,7.75" />
      <path style={styles.three} d="m521.78,598.09s2.96,5.86,2.88,6.99" />
      <path style={styles.three} d="m526.97,595.67s2.59,3.2,3.97,5.79" />
      <path style={styles.three} d="m531.43,592.69s4.89,1.87,5.67,3.73" />
      <path style={styles.three} d="m535.36,588.11s4.75-.27,6.13,1.63" />
    </g>
  );
};

export default TurtleNeck;
