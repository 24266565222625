const Yawn = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
  };

  return (
    <g transform="translate(507, 463)">
      <path d="M30.47,0c9.75,.21,19.08,1.98,27.99,5.45,2.35,.92,2.62,2.42,.56,3.85-1.14,.79-2.49,1.49-3.87,1.83-2.77,.68-5.13,1.94-7.39,3.42-4.75,3.11-9.46,6.26-14.74,8.68-4.74,2.17-9.77,3.31-15.09,3.48-1.54,.05-3.09,.1-4.62,.05-3.77-.13-7-1.62-9.92-3.54C.27,21.17-.49,18.26,.28,15,1.31,10.62,4.55,7.63,8.51,5.18,12.52,2.71,17.06,1.32,21.87,.72c2.85-.36,5.73-.49,8.6-.72Zm28.22,6.88c-2.79-.93-5.51-1.9-8.65-2.34,.15,.8,.22,1.31,.33,1.81,.42,1.85,.03,2.7-1.9,3.44-1.21,.46-2.51,.89-3.8,1-4.26,.36-8.53,.59-12.8,.81-1.39,.07-2.8-.14-4.2-.11-7.03,.14-14.05,.31-21.08,.47-2.5,.05-2.51,.04-3.32,2.05-.17,.43-.28,.88-.45,1.31-.99,2.38-.19,4.36,1.85,5.98,.98,.78,2.03,1.6,3.22,2.05,3.81,1.42,7.79,2,11.9,1.13,4.64-.98,8.95-2.59,13.04-4.79,6.05-3.26,11.92-6.81,18.57-9.13,1.72-.6,3.46-1.13,5.19-1.72,.88-.3,1.88-.51,2.1-1.97ZM6.4,9.47c.6,.14,.79,.24,.98,.23,4.58-.15,9.15-.33,13.73-.46,2.73-.08,5.47-.22,8.2-.1,5.41,.24,10.78-.1,16.11-.75,3.37-.41,3.66-.99,3.23-4.15-.23-.1-.48-.25-.76-.31-8.73-1.8-17.51-2.29-26.36-.77-4.3,.74-8.45,1.9-12.09,4.14-.99,.61-1.88,1.34-3.03,2.18Z" />
      <path d="M27.43,37.77c2.06-1.51,4.01-2.37,6.15-2.9,1.49-.37,2.98-.75,4.49-1.06,6.46-1.29,11.43-4.68,15.94-8.74,.54-.49,1.11-.95,2.02-1.73,.05,1.65-.73,2.49-1.39,3.35-4.33,5.6-10.32,8.98-17.79,10.43-2.95,.57-5.88,1.12-9.41,.65Z" />
      <path d="M58.69,6.88c-.22,1.46-1.22,1.67-2.1,1.97-1.72,.58-3.47,1.12-5.19,1.72-6.64,2.32-12.52,5.88-18.57,9.13-4.09,2.2-8.41,3.81-13.04,4.79-4.11,.87-8.09,.29-11.9-1.13-1.19-.44-2.23-1.27-3.22-2.05-2.04-1.62-2.85-3.59-1.85-5.98,.18-.43,.28-.88,.45-1.31,.81-2.01,.82-2,3.32-2.05,7.03-.15,14.05-.33,21.08-.47,1.4-.03,2.81,.18,4.2,.11,4.27-.21,8.54-.45,12.8-.81,1.29-.11,2.59-.54,3.8-1,1.93-.74,2.32-1.6,1.9-3.44-.11-.5-.19-1.01-.33-1.81,3.15,.43,5.87,1.41,8.65,2.34Z" />
      <path
        style={styles.one}
        d="M6.4,9.47c1.15-.83,2.04-1.57,3.03-2.18,3.65-2.24,7.79-3.41,12.09-4.14,8.85-1.51,17.63-1.03,26.36,.77,.28,.06,.53,.21,.76,.31,.43,3.17,.14,3.74-3.23,4.15-5.33,.65-10.7,.99-16.11,.75-2.72-.12-5.46,.02-8.2,.1-4.58,.13-9.15,.31-13.73,.46-.19,0-.38-.09-.98-.23Z"
      />
    </g>
  );
};

export default Yawn;
