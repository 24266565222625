import { Grid, Skeleton } from "@mui/material";
import { cardVeryLight } from "../../utils/themeContstants";

const DialogGlimmer = () => {
  const styles = {
    container: {
      width: "100%",
      padding: 2,
    },
    width: {
      width: "100%",
    },
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={{ xs: 2 }}
    >
      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Skeleton
              variant="text"
              height={45}
              width={200}
              animation="pulse"
              sx={{ bgcolor: cardVeryLight }}
            />
          </Grid>

          <Grid item>
            <Skeleton
              variant="text"
              height={30}
              width={300}
              animation="pulse"
              sx={{ bgcolor: cardVeryLight }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item sx={styles.width}>
            <Skeleton
              variant="text"
              height={45}
              width={"100%"}
              animation="pulse"
              sx={{ bgcolor: cardVeryLight }}
            />
          </Grid>

          <Grid item sx={styles.width}>
            <Skeleton
              variant="text"
              height={25}
              width={"100%"}
              animation="pulse"
              sx={{ bgcolor: cardVeryLight }}
            />
          </Grid>

          <Grid item sx={styles.width}>
            <Skeleton
              variant="text"
              height={25}
              width={"100%"}
              animation="pulse"
              sx={{ bgcolor: cardVeryLight }}
            />
          </Grid>

          <Grid item sx={styles.width}>
            <Skeleton
              variant="text"
              height={25}
              width={"100%"}
              animation="pulse"
              sx={{ bgcolor: cardVeryLight }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item sx={styles.width}>
            <Skeleton
              variant="text"
              height={35}
              width={"100%"}
              animation="pulse"
              sx={{ bgcolor: cardVeryLight }}
            />
          </Grid>

          <Grid item sx={styles.width}>
            <Skeleton
              variant="text"
              height={35}
              width={"100%"}
              animation="pulse"
              sx={{ bgcolor: cardVeryLight }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DialogGlimmer;
