import "../styles/Grillz.css";

const Grillz = () => {
  return (
    <g>
      <image
        class="grillz-5"
        width="434"
        height="308"
        transform="translate(-306 -7) scale(.75)"
      />
      <g>
        <path
          class="grillz-3"
          d="m517.22,484.07c.8-.12,1.57-.35,2.31-.66,5.16-2.17,13.34-4.89,23.32-5.27,7.11-.27,12.85.75,16.84,1.8.33.09.65.21.95.34,5.31,2.33,11.15,1.24,14.25-2.2,2.41-2.67,2.47-6,2.44-7.09.03-3.3-.4-10.58-5.48-17.17-1.29-1.68-2.67-3.04-4-4.15-.77-.64-1.67-1.14-2.65-1.41,0,0-.01,0-.02,0-2.18-.59-4.18-.33-5.56.02-.47.12-.92.28-1.35.49-2.97,1.39-7.28,2.99-12.73,3.74-7.57,1.04-13.99-.03-18.09-1.06-.25-.06-.5-.14-.75-.22-.37-.13-.73-.28-1.08-.46-1.65-.84-5.25-2.28-9.44-1.36-1,.22-1.87.53-2.61.88s-1.44.82-2.05,1.35c-12.94,11.32-14.63,24.83-8.7,30.05,3.96,3.49,10.95,2.92,14.4,2.38Z"
        />
        <polygon
          class="grillz-1"
          points="529.71 452.96 529.13 466.19 525.76 466.23 526.7 452.23 529.71 452.96"
        />
        <polygon
          class="grillz-1"
          points="518 450.89 517.27 466.96 513 467 514.19 450 518 450.89"
        />
        <polygon
          class="grillz-1"
          points="546 453.68 545.27 466.01 541 466.04 542.19 453 546 453.68"
        />
        <polygon
          class="grillz-1"
          points="563.03 450.12 560.39 465.03 557.85 464.7 560.89 448.96 563.03 450.12"
        />
        <polygon
          class="grillz-1"
          points="560.07 464.87 557.39 480.03 554.85 479.7 557.93 463.69 560.07 464.87"
        />
        <polygon
          class="grillz-1"
          points="571.91 454.58 570 464.99 567.85 464.7 570.08 453.72 571.91 454.58"
        />
        <polygon
          class="grillz-1"
          points="571.5 466.03 568.55 482.89 565.84 482.53 569.23 464.74 571.5 466.03"
        />
        <polygon
          class="grillz-1"
          points="546 465.67 545.27 478 541 478.03 542.19 464.99 546 465.67"
        />
        <polygon
          class="grillz-1"
          points="530 465.84 529.27 480.96 525 481 526.19 465 530 465.84"
        />
        <path
          class="grillz-1"
          d="m502.82,481.69l2.06-12.91-4.45.13c-.46,1.52-1.53,5.83.58,10.1.54,1.09,1.19,1.98,1.82,2.69Z"
        />
        <polygon
          class="grillz-1"
          points="505 468 509 455 500.75 467.69 505 468"
        />
        <polygon
          class="grillz-4"
          points="516 467.98 515.27 484.96 511 485 512.19 467.04 516 467.98"
        />
        <path
          class="grillz-6"
          d="m517.22,485.07c.8-.12,1.57-.35,2.31-.66,5.16-2.17,13.34-4.89,23.32-5.27,7.11-.27,12.85.75,16.84,1.8.33.09.65.21.95.34,5.31,2.33,11.15,1.24,14.25-2.2,2.41-2.67,2.47-6,2.44-7.09.03-3.3-.4-10.58-5.48-17.17-1.29-1.68-2.67-3.04-4-4.15-.77-.64-1.67-1.14-2.65-1.41,0,0-.01,0-.02,0-2.18-.59-4.18-.33-5.56.02-.47.12-.92.28-1.35.49-2.97,1.39-7.28,2.99-12.73,3.74-7.57,1.04-13.99-.03-18.09-1.06-.25-.06-.5-.14-.75-.22-.37-.13-.73-.28-1.08-.46-1.65-.84-5.25-2.28-9.44-1.36-1,.22-1.87.53-2.61.88s-1.44.82-2.05,1.35c-12.94,11.32-14.63,24.83-8.7,30.05,3.96,3.49,10.95,2.92,14.4,2.38Z"
        />
        <line class="grillz-6" x1="524.68" y1="482.48" x2="526.7" y2="452.23" />
        <line class="grillz-6" x1="540.92" y1="453.9" x2="540.78" y2="479.26" />
        <line
          class="grillz-6"
          x1="559.63"
          y1="449.29"
          x2="554.91"
          y2="479.92"
        />
        <line class="grillz-6" x1="569.33" y1="452.01" x2="565.7" y2="482.59" />
        <line class="grillz-6" x1="513.56" y1="451.28" x2="510.28" y2="485.4" />
        <path
          class="grillz-6"
          d="m500.42,468.78c13.51-1.74,28-3.02,43.07-3.42,12.44-.33,23.55-.02,33.3.64"
        />
        <path d="m560.07,491.57c-9.08,12.47-29.72,12.01-40.07,1.43,3.56.87,6.93,1.55,10.27,1.97,10.2,1.43,19.92-1,29.79-3.41h0Z" />
        <path
          class="grillz-2"
          d="m520.88,458.36c-3.2-2.31-6.06-5.03-8.89-7.76,3.09,2.44,6.17,4.9,8.89,7.76h0Z"
        />
        <path
          class="grillz-2"
          d="m521.33,453.81c-3.94.14-7.87-.24-11.78-.65,3.94.02,7.88.08,11.78.65h0Z"
        />
        <path
          class="grillz-2"
          d="m521.83,451.09c-3.45,1.92-7.12,3.36-10.79,4.78,3.52-1.77,7.05-3.52,10.79-4.78h0Z"
        />
        <path
          class="grillz-2"
          d="m519.04,449.81c-2.12,3.33-4.66,6.34-7.22,9.34,2.25-3.23,4.53-6.45,7.22-9.34h0Z"
        />
        <path
          class="grillz-2"
          d="m515.95,463c-.6-5.95-.16-11.91.02-17.88.17,5.96.59,11.93-.02,17.88h0Z"
        />
        <circle class="grillz-2" cx="515.87" cy="453.76" r="1.04" />
        <path
          class="grillz-2"
          d="m533.88,473.36c-3.2-2.31-6.06-5.03-8.89-7.76,3.09,2.44,6.17,4.9,8.89,7.76h0Z"
        />
        <path
          class="grillz-2"
          d="m534.33,468.81c-3.94.14-7.87-.24-11.78-.65,3.94.02,7.88.08,11.78.65h0Z"
        />
        <path
          class="grillz-2"
          d="m534.83,466.09c-3.45,1.92-7.12,3.36-10.79,4.78,3.52-1.77,7.05-3.52,10.79-4.78h0Z"
        />
        <path
          class="grillz-2"
          d="m532.04,464.81c-2.12,3.33-4.66,6.34-7.22,9.34,2.25-3.23,4.53-6.45,7.22-9.34h0Z"
        />
        <path
          class="grillz-2"
          d="m528.95,478c-.6-5.95-.16-11.91.02-17.88.17,5.96.59,11.93-.02,17.88h0Z"
        />
        <circle class="grillz-2" cx="528.87" cy="468.76" r="1.04" />
        <path
          class="grillz-2"
          d="m562.42,469.19c-2.03-1.29-3.72-2.99-5.4-4.71,1.93,1.42,3.84,2.87,5.4,4.71h0Z"
        />
        <path
          class="grillz-2"
          d="m562.69,466.42c-2.4.23-4.78-.07-7.15-.4,2.39-.06,4.79-.1,7.15.4h0Z"
        />
        <path
          class="grillz-2"
          d="m563,464.78c-2.03,1.29-4.29,2.11-6.55,2.9,2.1-1.14,4.22-2.26,6.55-2.9h0Z"
        />
        <path
          class="grillz-2"
          d="m561.31,464c-1.17,2.11-2.77,3.9-4.38,5.67,1.31-2.01,2.64-4,4.38-5.67h0Z"
        />
        <path
          class="grillz-2"
          d="m559.43,472c-.26-1.8-.5-3.62-.22-5.42.07-1.6.03-3.85.24-5.42.2,1.56.16,3.83.22,5.42.27,1.8.02,3.62-.24,5.42h0Z"
        />
        <circle class="grillz-2" cx="559.38" cy="466.39" r=".63" />
        <path
          class="grillz-2"
          d="m504.66,473.19c-1.37-1.36-2.31-3.03-3.22-4.71,1.24,1.46,2.45,2.94,3.22,4.71h0Z"
        />
        <path
          class="grillz-2"
          d="m504.82,470.42c-1.45.23-2.87-.07-4.27-.4,1.44-.06,2.89-.09,4.27.4h0Z"
        />
        <path
          class="grillz-2"
          d="m505,468.78c-1.08,1.25-2.49,2.09-3.91,2.9,1.19-1.12,2.4-2.23,3.91-2.9h0Z"
        />
        <path
          class="grillz-2"
          d="m503.99,468c-.54,2.04-1.57,3.86-2.62,5.67.7-1.97,1.42-3.93,2.62-5.67h0Z"
        />
        <path
          class="grillz-2"
          d="m502.87,476c-.26-1.8-.5-3.62-.23-5.42.07-1.6.03-3.85.23-5.42.2,1.56.16,3.83.23,5.42.27,1.8.03,3.62-.23,5.42h0Z"
        />
        <ellipse class="grillz-2" cx="502.84" cy="470.39" rx=".38" ry=".63" />
        <path
          class="grillz-2"
          d="m545.66,458.19c-1.37-1.36-2.31-3.03-3.22-4.71,1.24,1.46,2.45,2.94,3.22,4.71h0Z"
        />
        <path
          class="grillz-2"
          d="m545.82,455.42c-1.45.23-2.87-.07-4.27-.4,1.44-.06,2.89-.09,4.27.4h0Z"
        />
        <path
          class="grillz-2"
          d="m546,453.78c-1.08,1.25-2.49,2.09-3.91,2.9,1.19-1.12,2.4-2.23,3.91-2.9h0Z"
        />
        <path
          class="grillz-2"
          d="m544.99,453c-.54,2.04-1.57,3.86-2.62,5.67.7-1.97,1.42-3.93,2.62-5.67h0Z"
        />
        <path
          class="grillz-2"
          d="m543.87,461c-.26-1.8-.5-3.62-.23-5.42.07-1.6.03-3.85.23-5.42.2,1.56.16,3.83.23,5.42.27,1.8.03,3.62-.23,5.42h0Z"
        />
        <ellipse class="grillz-2" cx="543.84" cy="455.39" rx=".38" ry=".63" />
        <path
          class="grillz-2"
          d="m571.66,471.19c-1.37-1.36-2.31-3.03-3.22-4.71,1.24,1.46,2.45,2.94,3.22,4.71h0Z"
        />
        <path
          class="grillz-2"
          d="m571.82,468.42c-1.45.23-2.87-.07-4.27-.4,1.44-.06,2.89-.09,4.27.4h0Z"
        />
        <path
          class="grillz-2"
          d="m572,466.78c-1.08,1.25-2.49,2.09-3.91,2.9,1.19-1.12,2.4-2.23,3.91-2.9h0Z"
        />
        <path
          class="grillz-2"
          d="m570.99,466c-.54,2.04-1.57,3.86-2.62,5.67.7-1.97,1.42-3.93,2.62-5.67h0Z"
        />
        <path
          class="grillz-2"
          d="m569.87,474c-.26-1.8-.5-3.62-.23-5.42.07-1.6.03-3.85.23-5.42.2,1.56.16,3.83.23,5.42.27,1.8.03,3.62-.23,5.42h0Z"
        />
        <ellipse class="grillz-2" cx="569.84" cy="468.39" rx=".38" ry=".63" />
      </g>
    </g>
  );
};

export default Grillz;
