const DadHair = ({ color }) => {
  const styles = {
    one: {
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: color,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: color,
      strokeWidth: 2,
    },
  };

  return (
    <g transform="translate(314, 203)">
      <g>
        <path
          style={styles.three}
          d="M348.82,187.49c-.6-3.14-1.39-5.64-1.47-8.16-.07-2.38,.32-4.86,1.02-7.14,5.92-19.16-3.28-32.99-17.03-45.2-5.52-4.9-11.39-9.44-16.77-14.48-3.31-3.1-5.84-6.96-9.01-10.22-1.06-1.09-3.4-2.31-4.51-1.89-19.18,7.25-39,6.09-58.83,4.31-22.24-2-44.55-3.66-66.65-6.67-17.97-2.45-35.67-6.7-53.48-10.16-1.42-.28-2.84-.6-5.14-1.08,.38,2.32,.65,3.96,.92,5.6,2.47,15.3-2.29,20.39-14.17,23.12-9.15,2.1-18.65,3.99-24.4,11.93-5.02,6.94-9.28,14.44-13.26,22-1.32,2.5-2.36,6.72-5.6,7.51-2.48,.6-3.63-1.33-8.8-2.9-2.58-.78-7.95-2.41-13.01-.69-4.19,1.43-6.21,4.5-7.2,3.85-1.1-.73,1.8-4.34-.03-7.63-.63-1.13-1.34-1.32-2.53-3.3-.64-1.06-1.15-2.17-1.59-3.41-2.16-6.08-4.15-12.24-6.76-18.15-.98-2.22-3.13-4.41-5.34-5.59-3.41-1.82-4.75-3.75-4.27-7.66,.37-2.98-.38-6.26-1.36-9.18-2.47-7.31-6.03-14.33-7.89-21.76-2.5-10,2.09-18.39,11.35-23.5,1.05-.58,2.53-1.61,2.54-2.44,.01-.94-1.24-2.17-2.26-2.78-3.95-2.35-4.03-3.35,.05-5.56,10.35-5.6,21.81-6.71,33.33-7.52,1.3-.09,2.6-.12,4.95-.22-1.24-3.27-2.07-6.12-3.38-8.75-1.91-3.85-.69-6.64,2.83-8.54,15.29-8.25,31.58-13.23,49.26-11.66,21.77,1.94,42.97-.88,63.78-6.48,14.03-3.78,27.43-2.01,40.81,1.92,12.94,3.8,25.77,5.45,39.43,3.36,15.27-2.34,30.23,.14,44.15,7.16,8.44,4.25,9,8.08,1.36,14.25,1.51,.58,2.73,1.19,4.03,1.54,17.95,4.86,36.65,8.01,53.67,14.98,17.31,7.08,34.65,15.26,48.72,28.01,1.57,1.43,1.69,4.35,2.48,6.58-2.35,.6-4.69,1.67-7.05,1.71-4.39,.08-8.8-.43-13.2-.69-.16,.45-.33,.9-.49,1.35,1.21,.89,2.5,1.7,3.63,2.68,5.42,4.71,11.04,9.25,16.05,14.34,1.64,1.67,2.84,5.36,2.01,7.17-.73,1.59-4.58,1.86-7.07,2.69-.46,.15-.95,.2-1.52,.31-.18,3.79,.09,7.62-.63,11.27-2.68,13.62-8.58,25.82-17.77,36.6-7.81,9.16-15.28,18.6-23.92,29.17Z"
        />
        <path
          style={styles.one}
          d="M288.95,29.64c-1.69,5.26-5.19,13.79-12.62,22.43-7.51,8.74-15.77,13.79-20.93,16.45"
        />
        <path
          style={styles.one}
          d="M199.61,4.78c-5.69,6.22-12.8,13.08-21.58,19.86-14.25,11.01-28.21,18.24-39.41,22.98"
        />
        <path
          style={styles.three}
          d="M51.64,38.52c14.96,9.08,29.5,16.84,43.41,23.47,19.23,9.16,56.84,26.61,108.29,35.69,36.73,6.48,59.13,10.44,87.99,3.99,39.92-8.91,66.25-31.92,79.01-45"
        />
        <path
          style={styles.two}
          d="M260.66,7.71c-20.36,21.08-40.48,34.36-54.47,42.31-22.01,12.51-43.68,24.47-71.58,22.98-16.13-.86-30.12-5.99-30-6.54,.13-.58,15.49,4.98,34.13,3.39,23.74-2.02,40.93-14.62,57.63-26.85,9.49-6.96,22.59-17.85,35.67-33.73"
        />
        <path
          style={styles.two}
          d="M87.05,25.22c-2.47,1.93-6.63,5.71-9.69,11.68-3.02,5.91-3.57,11.26-3.64,14.27"
        />
        <path
          style={styles.two}
          d="M36.66,52.93c3.83,.62,11.15,1.28,19.38-1.51,4.03-1.37,7.18-3.19,9.46-4.78"
        />
        <path
          style={styles.two}
          d="M87.05,58.09c1.01-1.86,2.39-4.04,4.26-6.31,1.73-2.11,3.48-3.8,5.04-5.14"
        />
        <path
          style={styles.two}
          d="M79.67,54.32c-.63,1.1-1.66,2.58-3.28,3.99-2.69,2.35-5.59,3.29-7.17,3.68"
        />
        <line style={styles.two} x1="100.35" y1="64.48" x2="92.02" y2="70.2" />
        <path
          style={styles.two}
          d="M113.27,46.64c-2.53,1.59-5.23,3.47-8,5.69-4.15,3.32-7.52,6.65-10.22,9.67"
        />
        <path
          style={styles.one}
          d="M331.13,120.91c1.62-4.92,1.19-8.53,.39-11.01-3.01-9.31-15.49-16.03-15.49-16.03h0"
        />
        <path
          style={styles.one}
          d="M70.13,141.94c-4.95-.63-15.18-2.59-24.94-9.91-15.08-11.3-18-27.1-18.61-31.02"
        />
        <path
          style={styles.one}
          d="M79.31,127.45c-3.97-1.91-9.28-5.01-14.48-9.92-7.66-7.24-11.4-14.94-13.19-19.6"
        />
        <path
          style={styles.one}
          d="M96.19,117.32c-2.75-.86-7.2-2.64-11.46-6.33-6.45-5.59-8.58-12.2-9.3-15"
        />
      </g>
    </g>
  );
};

export default DadHair;
