import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import { Grid, Typography, Box } from "@mui/material";
import { normal, secondaryText } from "../../utils/themeContstants";

const ColorSelect = ({ avatar, selected, onChange, width = 426 }) => {
  const [color, setColor] = useState("#fff");

  const handleChange = (color) => {
    setColor(color);
    onChange(`${selected}Color`, color);
  };

  const styles = {
    container: {
      width,
      borderRadius: 1,
      boxSizing: "border-box",
      overflow: "hidden",
    },
    label: {
      fontSize: normal,
      fontWeight: 700,
      color: secondaryText,
    },
    width: {
      width: "100%",
    },
    colorPickerContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item sx={styles.width}>
          <Box sx={styles.colorPickerContainer}>
            <Typography sx={styles.label}>COLORS</Typography>
            <HexColorPicker color={color} onChange={handleChange} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ColorSelect;
