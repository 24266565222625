const BlackBull = ({ skinColor }) => {
  const styles = {
    one: {
      fill: "#827c79",
    },
    two: {
      fill: "#141311",
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#3f505f",
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#292824",
    },
    five: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#1e2c37",
    },
    six: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    seven: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#b3a674",
    },
    eight: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#d6bf85",
    },
    nine: {
      fill: "#3f505f",
    },
    ten: {
      fill: "#e0dfd9",
    },
    eleven: {
      fill: "#292824",
    },
    twelve: {
      fill: skinColor,
    },
    thirteen: {
      fill: "#bca471",
    },
    fourteen: {
      fill: "#d6bf85",
    },
    fifteen: {
      fill: "#999390",
    },
  };

  return (
    <g>
      <path
        style={styles.eleven}
        d="m288.78,746.86c-.4-14.89.38-33.27,4.22-53.91,2.91-15.7,6.98-29.41,11.15-40.89,18.82-16.26,44.95-35.52,78.85-52.06,24.12-11.77,46.61-19.45,65.69-24.57,21.07,1.84,42.13,3.67,63.2,5.51,19.71,2.58,50.2,9.07,83.11,27.06,26.46,14.46,45.41,31.41,57.79,44.37,4.94,15.77,9.6,33.7,13.21,53.58,4.64,25.54,6.56,48.82,7.05,68.96-14.2,12.19-36.17,28.56-66.05,42-51.38,23.12-97.39,24.63-120,23.99-46.92-1.33-82.48-14.45-100-21.99-49.98-21.53-81.86-53.17-98.22-72.04Z"
      />
      <path
        style={styles.twelve}
        d="m518.87,533.53c-1.93,5.66-3.14,10.23-3.91,13.46-.37,1.56-.46,2.04-1.83,7.96-1.43,6.18-1.46,6.21-1.7,7.43-.91,4.8-1.06,8.01-1.17,10.7-.26,6.12.32,7.46,1.17,8.35.49.51,1.02.81,1.4.98,5.26,4.47,5.85,8.04,5.77,9.96-.35,9.12-16.94,15.17-27,16.97-23.01,4.12-44.54-8.01-48-17.09-.73-1.93-2.88-7.57-.23-11.22.48-.66,1.01-1.11,1.37-1.37.58-.37,1.48-1.02,2.38-2.02,0,0,1.01-.93,1.57-2.22,1.09-2.53,5.86-23.75,11.97-52.88,7.52,3.12,17.19,6.39,28.72,8.56,11.36,2.13,21.42,2.6,29.48,2.43Z"
      />
      <path d="m460,523c1.9,1.69,3.9,3.36,6,5,15.78,12.32,32.48,19.14,47,23,1.96-5.48,3.91-10.96,5.87-16.44-4.61.02-9.68-.16-15.13-.64-.79-.07-6.86-.61-12.73-1.62-12.45-2.13-23.75-6.29-31-9.31Z" />
      <g>
        <path d="m460.98,525.94s-11.31,49.7-11.31,49.7c0,0-.13.58-.13.58-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74l11.31-49.7s1.95.44,1.95.44h0Z" />
        <path d="m518.91,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59,0,0,1.88.69,1.88.69h0Z" />
      </g>
      <path
        style={styles.eleven}
        d="m406.82,560.34c-1.54,3.06-3.47,7.75-4.31,13.76-.95,6.83-.1,12.48.71,16.01,4.73,5.34,12.17,13.03,22.39,20.88,11.18,8.58,55.19,42.39,87.51,29.05,16.97-7,50.38-53.4,38.25-39.62h0l-4.79-13.11c.18-3.05.12-7.02-.68-11.54-.79-4.46-2.07-8.15-3.26-10.94-12.25,10.1-24.51,20.21-36.76,30.31l-10.17,9.2-11.05,1.37-24.66-21.03-46.26-19.17-6.92-5.17Z"
      />
      <polygon
        style={styles.two}
        points="428.46 564.97 424.01 552.41 418.43 552.41 417.87 558.21 420.9 561.75 428.46 564.97"
      />
      <polygon
        style={styles.two}
        points="526.5 574.8 528.51 556.44 533.61 556.44 538.36 558.88 537.65 564.17 530.15 574.8 526.5 575.91 526.5 574.8"
      />
      <path
        style={styles.ten}
        d="m672.89,769.87l.15,5.04c-5.83,5.05-14.16,11.83-24.72,19.03-36.92,25.16-71.65,35.36-88.64,39.48-12.91,3.14-39.11,8.49-72.69,7.47-36.38-1.1-64.94-9.18-81.59-14.93-16.91-5.85-31.47-12.75-43.61-19.51,9.13,2.35,21.01,4.41,34.92,4.31,7.95-.06,15.12-.81,21.33-1.85,1.47-32.61,2.95-65.22,4.42-97.83,0,0,8.98,28.27,9.08,28.96l10.52,64.79,179.72-14.09,51.11-20.87Z"
      />
      <polygon
        style={styles.eight}
        points="415.01 673.44 393 810.3 375.78 808.61 402.23 818.39 422.46 675.29 415.01 673.44"
      />
      <path
        style={styles.fifteen}
        d="m672.43,770.06c-3.54,4.12-9.19,9.9-17.25,15.2-11.1,7.29-21.4,10.12-35.32,13.96-9.08,2.5-17.18,4.03-24.16,5.35-6.91,1.31-10.5,1.98-15.48,2.51-11.11,1.18-16.75.31-25.74.36-6.92.03-16.72.61-28.7,3.13-9.52,1.01-17.33,1.67-22.7,2.09-17.53,1.36-34.17,2.09-34.17,2.09-9.49.41-12.58.41-19.3,1.04-8.12.77-14.72,1.83-19.04,2.61-.7,0-1.9.09-3.2.72-2.2,1.07-3.17,2.97-3.46,3.59-.48,2.85-.97,5.69-1.45,8.54-3.86-.83-8.06-1.95-12.51-3.45-2.88-.97-5.57-2.01-8.06-3.07,7.53-41.46,15.07-82.91,22.6-124.37l14.48,97.89,4.11,7.43,109.93-8.02,82.2-10.96c12.4-5.55,24.81-11.09,37.21-16.64Z"
      />
      <ellipse
        style={styles.seven}
        cx="425.63"
        cy="661.4"
        rx="18.8"
        ry="14.58"
      />
      <path
        style={styles.five}
        d="m448.69,575.42l4.7-20.65c-5.12-2.28-11.8-5.86-18.72-11.47-6.89-5.58-11.77-11.33-15.07-15.85.4,5.84,1.36,12.99,3.52,20.93,2.03,7.45,4.6,13.75,7.04,18.78"
      />
      <path
        style={styles.five}
        d="m511.56,586.3c.41-10.21.82-20.42,1.22-30.63.83-.17,1.83-.48,2.89-1.02,2.73-1.4,4.22-3.55,4.89-4.7l9-11.15c-1.18,12.57-2.37,25.14-3.55,37.72"
      />
      <path
        style={styles.six}
        d="m424.49,552.97c-4.93-1.15-8.24-.3-10.16.51-9.41,3.97-14.52,19.1-10.96,37.37,3.5,2.45,9.2,5.92,16.83,8.22,7.92,2.39,13.24,2.11,26.41,3,5.48.37,13.64,1.03,23.68,2.28l10.57,3.13c4.72-.59,9.43-1.17,14.15-1.76,1.96-.96,4.95-2.2,8.74-2.93,5.18-1,8.44-.37,13.1-.39,3.7-.01,8.99-.43,15.46-2.35,2.25-.58,8.32-2.47,12.13-8.22,1.04-1.57,1.71-3.13,2.15-4.5.48-16.35-4.74-28.77-12.61-30.92-1.49-.41-3.51-.56-6.13.21"
      />
      <path
        style={styles.eight}
        d="m431.87,674.65c.08,18.58.98,38.85,3.13,60.52,2.46,24.77,6.18,47.47,10.43,67.83,4.17-.25,7.48-.81,9.69-1.27,4.42-.91,6.02-1.71,10-1.99,3.16-.22,3.5.19,6.65,0,4.53-.27,7.67-1.34,9.43-1.84,3.77-1.08,9.13-.96,19.69-.68,14.93.4,14.11,1.43,20.71.82,5.98-.55,6.27-1.36,15.91-2.48,3.77-.44,4.6-.41,13.57-1.17,10.75-.91,10.81-1.21,15.15-1.37,5.88-.22,6.99.15,11.59-.32,4.57-.47,7.55-1.26,10.57-1.96,2.22-.51,13.27-3.01,25.04-3.78,10.22-.67,13.1.47,18.13-1.57.99-.4,4.38-1.86,9.39-3.65.79-.28,1.49-.53,2.1-.74,1.15-.39,2.04-.69,2.35-.79.07-.02.11-.04.12-.04,1.71-.56,26.12-10.71,26.38-10.21.14.27-6.41,4.15-35.77,19.99-8.98,2.38-16.96,4.09-23.61,5.35-13.63,2.57-24.02,3.65-26.61,3.91-8.95.9-9.67.54-23.09,1.43-2.83.19-5.51.38-33.15,2.94-28.1,2.6-28.57,2.7-31.55,2.7-4.28,0-10.8-.18-19.17.88-1.69.21-3.66.5-6.78.78-6.34.57-11.01.52-12.78.52-4.68,0-11.7.33-21.78,1.83-5.05-45.01-10.1-90.01-15.15-135.02l9.41-.64Z"
      />
      <path
        style={styles.eight}
        d="m418.54,552.84c-.29.47-1.32,2.27-.89,4.55.66,3.49,4.11,4.82,4.3,4.89,6.19,3.77,11.37,6.58,14.87,8.41,13.67,7.16,20.46,9.19,30.91,16.24,4.28,2.89,7.57,5.54,9.59,7.24,1.3,1.15,2.71,2.57,4.11,4.3,2.38,2.95,3.93,5.83,4.96,8.15-1.71.26-3.43.52-5.14.79-2.08-3.07-4.82-6.52-8.42-9.92-8.5-8.02-17.61-11.83-23.48-13.7-5.68-2.16-19.73-7.59-32.28-13.5-3.36-1.58-9.06-4.35-9.39-8.67-.11-1.49.44-2.76.91-3.59.64-.97,2.44-3.39,5.74-4.57,1.68-.6,3.19-.68,4.21-.64Z"
      />
      <path
        style={styles.eight}
        d="m537.59,559.56c.03.23.05.47.07.7.51,8.52-10.36,15.85-17.15,19.68-5.79,3.89-11.97,8.47-18.33,13.84-4.79,4.05-9.11,8.06-12.98,11.93,1.94,0,3.87,0,5.81,0,2.79-3.07,6.24-6.45,10.43-9.85,4.31-3.49,8.46-6.22,12.13-8.35,5.68-3.64,26.87-17.55,24.26-24.26-.04-.1-.27-.53-.65-1.04-1.13-1.5-2.59-2.26-3.59-2.66Z"
      />
      <ellipse
        style={styles.four}
        cx="425.63"
        cy="661.4"
        rx="16.43"
        ry="12.74"
      />
      <path
        style={styles.eight}
        d="m539.86,675.73c.38-.16.71-.4.99-.71.86-.94,1.87-2.27,2.67-4.02.2-.45.38-.88.52-1.3.39-1.11,1.46-1.83,2.64-1.83h13.78c.82,0,1.58.37,2.12.98.61.69,1.38,1.43,2.32,2.15.9.69,1.78,1.21,2.55,1.6.3.15.62.24.95.28,1.76.18,3.95.24,6.42-.05,1.69-.2,3.19-.52,4.49-.88.52-.14.98-.43,1.35-.82.95-1.01,3.21-2.94,6.56-2.86,3.7.08,5.96,2.56,6.53,3.27.07.09.13.17.19.27,1.11,1.75,3.74,6.48,3.32,12.89-.24,3.56-1.34,6.42-2.34,8.36-.54,1.05-1.69,1.62-2.86,1.47-1.08-.14-2.36-.2-3.8-.05-2.14.23-3.89.85-5.16,1.45-.88.41-1.47,1.26-1.58,2.23-.04.39-.06.82-.04,1.28.01.21.03.42.06.62.17,1.28,1.25,2.26,2.53,2.35,1.03.07,2.27.32,3.54.95.06.03.12.06.17.09,1.1.57,1.66,1.82,1.42,3.04l-3.01,14.95c-.25,1.27-1.33,2.2-2.62,2.26-.13,0-.26.01-.39.02-.84.02-1.63,0-2.36-.08-1.48-.15-2.83.83-3.09,2.28-.13.72-.26,1.43-.39,2.15-.17.95.15,1.92.85,2.57l1.03.96c.89.83,1.15,2.13.65,3.23l-3.98,8.77c-.56,1.23-1.92,1.91-3.23,1.57-.74-.19-1.53-.35-2.38-.45-4.88-.56-8.97,1.12-11.48,2.54-1.18.67-2.67.4-3.54-.64l-5.26-6.26c-.81-.97-.88-2.36-.17-3.4h0c.48-.7.61-1.58.37-2.39l-.96-3.26c-.41-1.39-1.8-2.22-3.22-1.97-1.3.23-2.74.42-4.3.51-1.9.11-3.63.05-5.15-.08-1.01-.09-1.88-.73-2.29-1.65-1.68-3.74-3.37-7.48-5.05-11.22-.48-1.08-.25-2.34.6-3.17l4.04-3.96c.94-.92,1.11-2.37.43-3.49l-2.41-3.92c-.62-1.02-1.82-1.52-2.99-1.28-1.94.39-3.45.94-4.56,1.43-1.92.84-2.95,1.63-4.78,1.77-1.71.14-3.19-.37-4.25-.88-.21-.1-.41-.24-.59-.39-4.69-4.04-6.88-10.12-5.59-15.81.18-.78,2.03-8.36,9.26-10.83,4.73-1.61,8.92-.07,10.44.61.27.12.54.2.83.23,1.98.21,4.65.22,7.64-.57.95-.25,1.82-.56,2.6-.88Z"
      />
      <path
        style={styles.fourteen}
        d="m412.3,665.72c-.48-6.94,3.51-13.2,9.33-15.26,7.14-2.54,15.99,1.63,18.85,9.98-3.43-5.01-9.41-7.63-15.33-6.72-6.27.97-11.38,5.73-12.85,12Z"
      />
      <path
        style={styles.four}
        d="m550.04,675.5c-1.09,1.1-2.55,2.31-4.43,3.32-6.02,3.24-12.12,2.2-14.35,1.7-7.68-.68-14.02,2.88-15.52,7.83-.94,3.1-.46,8.31,3,10.43,1.52.93,3.15.99,4.17.91,2.39-.85,5.54-1.63,9.26-1.7,1.16-.02,2.25.03,3.26.13.72.08,1.41-.29,1.7-.91.33-.72.07-1.63-.65-2.09-4.13-1.52-8.26-3.04-12.39-4.57,2.61-.14,6.26-.05,10.43,1.04,2.87.75,5.24,1.78,7.08,2.74v2.87c-.68.76-2.73,3.23-2.65,6.65.1,4.25,3.44,8.23,8.35,9.65,2.45,4.89,4.8,10.68,6.52,17.35.46,1.79.85,3.53,1.17,5.22.21.5,1.01,2.23,2.87,3,2.23.92,5.08.08,6.91-2.18.17.14,2.06,1.67,4.43,1.01,1.86-.52,3.26-2.16,3.52-4.17v-27.54c.4-.31,3.59-2.82,3.65-7.16.07-4.92-3.95-7.65-4.22-7.83v-3.26c.7-.96,1.69-2.08,3.05-3.13,3.27-2.52,6.73-3.1,8.48-3.26.13.1.54.45.65,1.04.18.97-.62,1.67-.65,1.7-2.35,2-4.7,4-7.04,6-.25.49-.27,1.05,0,1.43.4.57,1.41.74,2.22.13,1.17-.67,2.56-1.34,4.15-1.93,3.05-1.13,5.83-1.57,7.98-1.72.52-1.15,1.02-2.7,1.04-4.57.08-6.16-5.12-10.14-5.87-10.7-3.11,1.18-7.17,2.38-12,3-6.61.84-12.22.27-16.17-.45.18-.06,2.93-.98,2.82-1.55-.1-.52-2.51-.48-2.82-.47-1.35-.17-2.7-.35-4.04-.52-.39-.65-.78-1.31-1.18-1.96l-1.4,2.48-5.25.67,3.91,1.36Z"
      />
      <path
        style={styles.fourteen}
        d="m546.13,708.96c-2.27-.5-4.02-2.2-4.43-4.3-.44-2.25.82-4,1.04-4.3.71.84,1.63,2.14,2.35,3.91.76,1.88.98,3.56,1.04,4.7Z"
      />
      <path
        style={styles.fourteen}
        d="m569.91,704.65c2.27-.5,4.02-2.2,4.43-4.3.44-2.25-.82-4-1.04-4.3-.71.84-1.63,2.14-2.35,3.91-.76,1.88-.98,3.56-1.04,4.7Z"
      />
      <path
        style={styles.fourteen}
        d="m552.36,685.05s4.01,2.35,9.59-1.76l-3.52,4.11s-.39,1.27.59,1.37,4.4.98,4.4.98l-4.5,1.08-4.4,5.22.29-3.85s-1.37-2.05-3.42-1.57l2.64-2.15-1.66-3.42Z"
      />
      <path
        style={styles.fourteen}
        d="m558.82,736.9l2.93-.49c-.15-1.45.73-2.8,2.05-3.23,1.45-.47,3.09.28,3.72,1.76h3.13l-1.17-12.91-4.74.59c.58,2.24-.65,4.11-1.81,4.3-1.17.2-2.98-1.18-3.13-3.52-1.6.16-3.2.33-4.79.49l3.82,13.01Z"
      />
      <path
        style={styles.one}
        d="m419.09,809c-1.9-13.51-3-30.32-1.36-49.45.99-11.49,2.79-21.85,4.9-30.92-1.18,26.79-2.36,53.58-3.54,80.36Z"
      />
      <path
        style={styles.nine}
        d="m419.61,527.46c1.21,6.17,3.55,14.58,8.28,23.74,5.1,9.86,11.12,16.99,15.72,21.65-2.11-.92-4.33-1.98-6.62-3.19-2.86-1.51-5.46-3.06-7.8-4.57-1.95-5.52-3.84-11.6-5.54-18.19-1.77-6.91-3.08-13.42-4.04-19.43Z"
      />
      <path
        style={styles.one}
        d="m437.61,810.3c-4.68-8.84-9.62-19.96-13.5-33.2-5.45-18.59-7-35.13-7.18-47.64h6.79l13.89,80.84Z"
      />
      <path
        style={styles.three}
        d="m403.57,808.91c.79.6,3.75,2.69,7.95,2.44,3.34-.2,5.64-1.77,6.52-2.44-.81-9.95-1.23-20.74-1.04-32.26.34-20.48,2.55-38.84,5.46-54.62v-46.75l-18.89,133.62Z"
      />
      <path
        style={styles.three}
        d="m435.68,802.12c-3.86-11.85-7.56-26.01-10.07-42.17-2.17-13.99-3.01-26.77-3.15-37.92v-46.75c2.6,23.7,5.13,47.4,7.58,71.11,1.92,18.57,3.8,37.15,5.63,55.73Z"
      />
      <path
        style={styles.fifteen}
        d="m361.8,806.45l11.99,2.52,32.32,11.77-4.21,3.97c-6.98-2.52-14.42-5.53-22.2-9.14-6.44-2.99-12.41-6.07-17.9-9.12Z"
      />
      <path
        style={styles.two}
        d="m478.05,797.17l2.82-83.85c11.99,27.84,23.97,55.67,35.96,83.51-6.53-.56-13.73-.86-21.49-.71-6.16.12-11.93.5-17.28,1.05Z"
      />
      <path
        style={styles.thirteen}
        d="m478.05,799.8c.08,2.12.17,4.24.25,6.36,1.01-.11,2.57-.27,4.5-.39,1.9-.12,3.1-.13,5.78-.2,1.42-.04,3.51-.1,6.11-.19,2.93,0,5.31-.1,6.9-.2,3.89-.22,5.04-.46,12.82-1.17,1.83-.17,3.32-.3,4.26-.37-.39-1.47-.78-2.95-1.18-4.42-2.01-.21-13.81-1.39-23.82-1.47-3.24-.03-6.87.06-11.35.98-1.8.37-3.27.78-4.26,1.08Z"
      />
      <path
        style={styles.thirteen}
        d="m582.24,793.07l-.9,5.64c3.59-.35,7.27-.76,11.02-1.24,6.03-.78,11.82-1.69,17.36-2.69-.57-2.1-1.14-4.21-1.71-6.31-1.74.21-3.5.43-5.28.68-3.71.52-8.28,1.17-13.96,2.38-1.61.34-3.85.85-6.54,1.54Z"
      />
      <path
        style={styles.two}
        d="m582.78,791l9.91-82.79,14.35,78.22c-3.51.4-7.21.93-11.09,1.63-4.71.85-9.11,1.86-13.17,2.93Z"
      />
      <polygon
        style={styles.two}
        points="313.8 772.2 394.37 686.52 350.19 799.47 313.8 772.2"
      />
      <path
        style={styles.two}
        d="m301.43,663.3c-.08.56-1.32,10.66,6.13,16.57,4.12,3.26,8.71,3.52,10.43,3.52,16.21-4.62,32.87-9.07,49.96-13.3,6.08-1.51,12.13-2.96,18.13-4.37-2.85,1.64-7.2,4.03-12.65,6.59-15.04,7.06-27.15,10.1-31.83,11.35-11.02,2.95-27.24,8.43-49.21,19.6,1.12-7.54,2.64-15.62,4.7-24.13,1.35-5.56,2.81-10.83,4.34-15.82Z"
      />
      <path
        style={styles.two}
        d="m290.71,715.84c12.16-5.4,24.33-10.8,36.49-16.21l-37.37,25.24c.29-3.01.59-6.02.88-9.03Z"
      />
      <path
        style={styles.two}
        d="m427.76,601.87c7.81,1.72,15.84,3.38,24.07,4.96,7.68,1.47,15.21,2.82,22.6,4.04,1.82-.96,3.64-1.92,5.47-2.89-3.22-.89-6.45-1.77-9.67-2.66-4.51-.64-9.17-1.21-13.96-1.7-9.99-1.02-19.51-1.56-28.5-1.76Z"
      />
      <path
        style={styles.two}
        d="m547.14,588.83c.19,1.89.16,4.71-1.23,7.5-2.37,4.72-7.23,7.9-13.89,8.51-11.54,1.05-23.08,2.11-34.62,3.16-1.67.64-3.5,1.23-5.49,1.73-6.79,1.7-12.87,1.65-17.48,1.14,1.82-.96,3.64-1.92,5.47-2.89l15.53-1.19c1.77-1.1,4.49-2.51,8.02-3.23,4.13-.84,6.76-.2,11.05-.1,3.12.07,7.6-.13,13.11-1.47,2.38-.17,10.86-1.08,16.43-7.89,1.47-1.8,2.44-3.65,3.09-5.28Z"
      />
    </g>
  );
};

export default BlackBull;
