import "./styles/Brazil.css";

const Brazil = () => {
  return (
    <g>
      <g id="Brazil_Jersey" data-name="Brazil Jersey">
        <path
          class="wc-brazil-11"
          d="m673.05,774.99c-10.77,9.78-27.1,22.99-49.05,35.01-33.44,18.31-66.72,26.66-95.49,29.93-30.81,3.51-56.43,1.19-71.51-.93-6.61-.93-12.97-2.07-19.07-3.37-42.6-9.09-72.54-26.03-84.93-33.63-30.34-18.6-51.24-39.78-64.22-55.07-.07-18.76,1.61-41.2,7.22-65.93,2.37-10.43,5.16-20.08,8.15-28.91,22.39-19.49,54.11-42.4,95.85-60.09,9.39-3.98,18.52-7.36,27.3-10.26,2.25-.74,4.47-1.45,6.67-2.13.6-.19,1.19-.37,1.78-.53h.03c.75-.25,1.49-.47,2.23-.68,3.63-1.08,7.19-2.07,10.68-2.98-.23.26-.46.53-.7.79-.18.21-.36.41-.54.62-.98,1.11-1.96,2.21-2.94,3.32-.08.1-.17.19-.26.29-.49.56-.98,1.13-1.48,1.69-.26.29-.51.58-.77.87-.35,1.67-.65,4.18,0,7,.15.66.34,1.29.58,1.9,1.12,2.97,3.14,5.38,5.51,7.36h0c1.23,1.04,2.57,1.95,3.92,2.77.36.22.73.43,1.09.63.02.01.04.02.06.04,1.07.59,2.13,1.13,3.15,1.63,1.32.63,2.57,1.18,3.68,1.67.44.2.89.39,1.33.56.02,0,.03.01.05.02,12.36,5.05,23.37,3.72,25.62,3.42,1.19-.16,2.4-.37,3.63-.62.01,0,.03,0,.04-.01,1.05-.21,2.12-.46,3.19-.74.04-.02.07-.02.1-.03,3.17-.84,6.34-1.94,9.31-3.24.48-.2.95-.42,1.41-.63.15-.07.3-.14.44-.21,2.34-1.11,4.51-2.37,6.42-3.7,2.09-1.45,3.85-3.03,5.13-4.65.02-.02.03-.04.04-.06,1.56-1.98,2.4-4.04,2.29-6.11-.08-1.3-.57-3.29-2.84-5.66-.28-.29-.59-.59-.93-.89-.52-.48-1.11-.96-1.79-1.45-.32-.25-.67-.49-1.03-.73-.13-.08-.26-.18-.4-.26h-.01s-.07-.06-.11-.08c.17.02.34.05.51.07.26.04.52.07.79.11h.01c.27.04.53.07.8.11.09.02.19.03.29.05h.01c.07.01.14.02.22.03h.01c.43.06.87.12,1.31.19,1.87.28,3.83.59,5.85.95,1.05.18,2.12.37,3.21.57.7.13,1.42.27,2.14.41.56.11,1.12.22,1.69.34,2.48.5,5.03,1.06,7.66,1.68,2.38.57,4.82,1.18,7.31,1.85.9.24,1.81.49,2.72.75,19.7,5.56,42.55,14.71,65.58,29.96,17.07,11.3,28.71,24.07,39,35,5.36,17.51,12.23,37.22,16,60,3.77,22.8,5.49,43.67,6.05,61.99Z"
        />
        <path
          class="wc-brazil-9"
          d="m444.64,580.44c-.75.92-1.42,1.89-1.89,2.88v-.45l.02-.74.03-1.67,1.45-.02h.39Z"
        />
        <polygon
          class="wc-brazil-6"
          points="510.74 580.52 511.37 581.7 519 589 520 585 510.74 580.52"
        />
        <path class="wc-brazil-6" d="m519,591c-1,0,0-4,0-4l1,3.81-1,.19Z" />
        <rect class="wc-brazil-6" x="512" y="581" width="2" height="1" />
        <polygon
          class="wc-brazil-11"
          points="487.59 610 476.9 610 472.49 654 489.84 653.04 492.98 610 487.59 610"
        />
        <path
          class="wc-brazil-5"
          d="m463.5,614.54l13.21-4.64-3.42,34.17s-6.09-26.35-9.79-29.52Z"
        />
        <path
          class="wc-brazil-1"
          d="m447,577c-.91.24-2.3.6-4,1-3.5.83-3.49.71-4,1-2.61,1.47-3.48,4.92-4,7-.26,1.01-.37,1.87-.43,2.42-.63,4.57-1.02,10.34-.51,16.96.57,7.39,2.08,13.61,3.6,18.33,4.45-.41,10.21-1.37,16.62-3.6,7.12-2.48,12.69-5.7,16.62-8.39,1.37-.23,2.74-.46,4.11-.69-2.31-.3-5.44-.87-9-2.03-2.84-.93-23.16-7.58-24-20-.13-1.87.02-6.35,5-12Z"
        />
        <path
          class="wc-brazil-12"
          d="m446.1,578.49c-3.26.76-4.4,4.36-4.63,7.34-.53,5.4,3.08,10.1,6.87,13.61,6.67,6.01,15.16,9.03,23.66,11.56-4.54-.21-8.92-1.54-13.17-3.14-8.91-3.57-20.83-11.08-19.06-22.23.48-3.44,2.29-7.54,6.13-8.12,0,0,.2.98.2.98h0Z"
        />
        <polygon
          class="wc-brazil-5"
          points="512 582.38 512 580.52 519 585 512 582.38"
        />
        <path
          class="wc-brazil-1"
          d="m511,581c.47-.01,1.16-.02,2,0,1.2.03,3.29.17,7,1,3.38.75,5.09,1.15,6,2,1.38,1.29,1.78,2.95,2,4,2.1,9.96,1,17,1,17-1.23,7.85-4.14,14.19-6.83,18.71-4.45-.41-10.21-1.37-16.62-3.6-7.12-2.48-12.69-5.7-16.62-8.39-1.37-.23-2.74-.46-4.11-.69,2.31-.3,5.44-.87,9-2.03,10.11-3.31,25-11.75,24-20-.18-1.51-1.1-4.65-6.82-8Z"
        />
        <path
          class="wc-brazil-12"
          d="m512.1,580.51c3.61.62,5.92,3.98,7.11,7.2,1.34,3.35.9,7.34-1.03,10.37-3.87,5.82-10.66,8.85-17.06,10.74-4.3,1.19-8.66,2.17-13.12,2.18,8.39-2.2,17.12-4.1,24.06-9.56,3.64-2.91,6.49-7.09,5.94-11.7-.65-3.39-2.5-7.35-6.09-8.25,0,0,.2-.98.2-.98h0Z"
        />
        <path d="m511.03,581.18c4.1,1.34,8.22,5.45,7.97,9.68-.03,1.54-.65,3.01-1.33,4.17-.62,1.24-1.42,2.43-2.36,3.42-5.43,5.78-13.95,9.52-20.95,11.19-10.46,2.81-21.74,1.91-31.85-1.86-5.03-1.82-9.83-4.35-14.1-7.64-4.77-3.62-8.37-8.17-7.52-14.21,0,0,0-.05,0-.05l.17-1.15.3-.58c-.19.16-.33.49-.31.74l.02-.19c.19-1.88,1.8-4.54,2.83-5.1,1.71-1.19,3.11-2.06,3.8-3.93.45-1.51.97-4.22,1.31-5.86,0,0,1.96.39,1.96.39l-.58,3.03c-.21,1.02-.33,1.98-.71,3.14-.67,2.15-2.75,3.84-4.43,4.92-.76.49-1.86,2.28-2.01,3.8,0,0,.02-.19.02-.19,0,.27-.14.63-.33.82l.3-.58-.13.99c-.73,5.08,2.51,8.94,6.74,12.16,4.05,3.11,8.64,5.53,13.47,7.27,14.89,5.54,31.2,4.21,44.55-3.82,3.16-2.03,6.31-4.33,8.01-7.72.9-1.51,1.32-2.88.99-4.52-.82-3.35-3.55-5.6-6.11-7.84,0,0,.27-.48.27-.48h0Z" />
        
        {/* L */}
        {/* <path
          class="wc-brazil-12"
          d="m380.01,683.13l.28-14.72-2.08.03-.35,16.41,10.67-.41.02-1.62-8.54.31Zm2.71-2.26l.2-12.48-2.07.02-.25,14.18,7.94-.28.02-1.63-5.84.19Z"
        /> */}
        {/* F */}
        {/* <path
          class="wc-brazil-12"
          d="m390,668.48l-.13,16.08,2.04-.08.08-14.46,7.57-.11v-1.54l-9.56.1Zm9.57,6.69l-5.06.11v-3.23s5.06-.09,5.06-.09v-1.55s-7.03.1-7.03.1l-.03,6.4,7.07-.18v-1.55Zm-7.08,2.24l-.03,7.05,2.01-.08.02-5.45,5.08-.15v-1.56l-7.08.19Z"
        /> */}
        {/* T */}
        {/* <path
          class="wc-brazil-12"
          d="m411.94,668.26l-11.14.12v1.54s11.17-.15,11.17-.15l-.03-1.51Zm-5.74,2.06l-5.39.08v1.55l3.54-.06.09,12.1,1.88-.07-.12-13.61Zm5.8,1.45l-.03-1.53-5.27.07.13,13.6,1.87-.07-.14-12.01,3.44-.06Z"
        /> */}

        {/* LFT */}
        {/* <path
          class="wc-brazil-7"
          d="m545.82,723.79l.25-10.31-1.9.02-.32,11.49,9.73-.28.02-1.14-7.79.22Zm2.48-1.58l.18-8.74h-1.89s-.23,9.95-.23,9.95l7.24-.19.02-1.14-5.33.13Z"
        />
        <path
          class="wc-brazil-7"
          d="m554.94,713.54l-.12,11.26,1.86-.06.07-10.13,6.9-.08v-1.08l-8.72.07Zm8.73,4.69l-4.62.08v-2.26s4.62-.06,4.62-.06v-1.09s-6.41.07-6.41.07l-.03,4.48,6.45-.12v-1.09Zm-6.46,1.57l-.03,4.94,1.83-.06.02-3.81,4.63-.1v-1.1l-6.46.13Z"
        />
        <path
          class="wc-brazil-7"
          d="m574.95,713.38l-10.16.08v1.08s10.19-.1,10.19-.1l-.03-1.05Zm-5.24,1.44l-4.91.06v1.09l3.22-.04.08,8.48,1.72-.05-.11-9.53Zm5.29,1.01l-.03-1.07-4.81.05.12,9.52,1.71-.05-.13-8.41,3.13-.04Z"
        /> */}
        <circle class="wc-brazil-12" cx="484" cy="617" r="4.5" />
        <circle class="wc-brazil-8" cx="486" cy="616" r="1" />
        <circle class="wc-brazil-8" cx="482" cy="616" r="1" />
        <circle class="wc-brazil-8" cx="484" cy="619" r="1" />
        <circle class="wc-brazil-12" cx="471.25" cy="617.25" r="3.75" />
        <circle class="wc-brazil-8" cx="472.92" cy="616.42" r=".83" />
        <circle class="wc-brazil-8" cx="469.58" cy="616.42" r=".83" />
        <circle class="wc-brazil-8" cx="471.25" cy="618.92" r=".83" />
        <polygon
          class="wc-brazil-7"
          points="527.53 642.41 528.7 639.3 529.64 642.44 532.92 642.49 530.27 644.28 531.29 647.42 528.45 645.47 525.33 647.74 526.89 644.24 523.67 642.36 527.53 642.41"
        />
        <polygon
          class="wc-brazil-7"
          points="538.9 633.72 540.07 630.61 541.01 633.75 544.29 633.79 541.65 635.59 542.66 638.72 539.82 636.78 536.71 639.04 538.26 635.54 535.04 633.66 538.9 633.72"
        />
        <polygon
          class="wc-brazil-7"
          points="566.55 634.32 567.71 631.21 568.66 634.35 571.93 634.39 569.29 636.19 570.31 639.33 567.46 637.38 564.35 639.65 565.91 636.14 562.69 634.27 566.55 634.32"
        />
        <polygon
          class="wc-brazil-7"
          points="577.96 642.13 579.13 639.02 580.08 642.16 583.35 642.21 580.71 644 581.73 647.14 578.88 645.19 575.77 647.46 577.32 643.96 574.11 642.08 577.96 642.13"
        />
        <path
          class="wc-brazil-3"
          d="m558.42,711.5c3.73-3.6,7.17-5.52,9.62-6.61,3.31-1.47,6.62-2.22,9.01-5.41,1.02-1.36,1.53-2.71,1.8-3.61v-28.85c-.21-1.5-.14-2.6,0-4.81.06-.93.14-1.66.6-2.4.68-1.11,1.8-1.6,2.4-1.8-3.13-1.39-6.59-3.16-10.22-5.41-4.85-3-8.83-6.15-12.02-9.01-1.15-.5-3.27-1.26-6.01-1.2-2.44.05-4.34.71-5.45,1.2-1.19,1.38-2.57,2.8-4.17,4.21-5.72,5.02-11.75,7.62-16.23,9.01.87,1.05,2.04,2.66,3,4.81,1.88,4.18,1.86,7.86,1.8,11.42-.11,6.97-.34,12.98-.6,18.03,1.04,2.18,2.85,5.23,6.01,7.81,3.58,2.92,6.35,3.17,11.42,6.01,2.31,1.29,5.52,3.36,9.01,6.61Z"
        />
        <rect
          class="wc-brazil-11"
          x="526.5"
          y="663.5"
          width="4"
          height="25"
          transform="translate(-147.5 1204.5) rotate(-90)"
        />
        <rect
          class="wc-brazil-11"
          x="581.5"
          y="661.5"
          width="4"
          height="25"
          transform="translate(-90.5 1257.5) rotate(-90)"
        />
        <rect class="wc-brazil-11" x="556" y="688" width="4" height="25" />
        <rect class="wc-brazil-11" x="553" y="634" width="4" height="25" />
        <g>
          <polygon
            class="wc-brazil-2"
            points="545.2 659.22 565.03 659.22 559.63 664.02 559.63 668.83 567.14 668.83 571.19 664.78 571.19 683.45 566.51 678.77 560.83 678.77 560.83 683.45 566.44 689.06 547.23 689.06 551.52 684.77 551.52 678.45 547.01 678.45 540.39 683.86 540.39 665.23 545.5 669.13 550.61 669.13 550.61 664.02 545.2 659.22"
          />
          {/* <path
            class="wc-brazil-10"
            d="m549.84,676.26v-5.91s-.8.02-.8.02v6.57s4.03-.16,4.03-.16v-.64s-3.23.12-3.23.12Zm3.23-.98l-2.22.08v-5.03s-.79.01-.79.01v5.7s3.02-.11,3.02-.11v-.65Z"
          />
          <path
            class="wc-brazil-10"
            d="m557.35,671.01v-.63s-3.7.02-3.7.02l-.13,6.48.78-.02.11-5.83,2.94-.02Zm-2,2.17l.03-1.31,1.96-.02v-.64s-2.72.02-2.72.02l-.05,2.6,2.73-.04v-.63s-1.95.02-1.95.02Zm1.95.81l-2.73.04-.05,2.82.77-.02.04-2.18,1.96-.03v-.63Z"
          />
          <path
            class="wc-brazil-10"
            d="m562.24,670.36l-4.39.02v.63s4.38-.03,4.38-.03v-.62Zm-2.28.84h-2.11s-.01.65-.01.65h1.38s-.09,4.9-.09,4.9l.73-.02.1-5.53Zm2.27.61v-.63s-2.07.01-2.07.01l-.1,5.53.73-.02.09-4.88h1.36Z"
          /> */}
        </g>
        <polygon
          class="wc-brazil-7"
          points="551.52 630.11 552.69 627 553.63 630.14 556.91 630.19 554.27 631.99 555.28 635.12 552.44 633.17 549.33 635.44 550.88 631.94 547.66 630.06 551.52 630.11"
        />
        <path
          class="wc-brazil-4"
          d="m553.51,643.05c-.71-.02-1.79.03-3.01.45-.85.29-1.52.67-2,1-1.77,2.2-4.69,5.28-9,8-4.27,2.69-8.27,3.98-11,4.64,1.13,1.13,3.35,3.12,4,6.36,1,5,1.51,9.47,1,11"
        />
        <path
          class="wc-brazil-4"
          d="m532.92,676.94v13.89c.5,1.34,1.52,3.55,3.58,5.66,1.64,1.68,3.33,2.67,4.5,3.24,3.89,1.63,8.15,3.81,12.5,6.76,1.23.84,2.4,1.68,3.5,2.53"
        />
        <path
          class="wc-brazil-4"
          d="m578.04,675.42v20.9c-.67,1.11-1.79,2.68-3.54,4.18-1.76,1.5-3.48,2.37-4.69,2.86-1.03.26-2.15.63-3.31,1.14-3.84,1.68-6.43,4.17-8,6"
        />
        <path
          class="wc-brazil-4"
          d="m578.04,672.52v-12.2c.11-.25.26-.53.46-.82.49-.72,1.06-1.2,1.5-1.5-3.07-1.26-6.68-3.02-10.5-5.5-4.17-2.7-7.48-5.53-10-8-.28-.27-.97-.85-2-1-.42-.06-.79-.03-1.08.02"
        />
      </g>
      <g id="Shoulder">
        <g>
          <path d="m289.61,748.67c-.91-1.03-1.83-2.05-2.71-3.1-.16-.19-.28-.51-.27-.76.05-2.47.11-4.94.2-7.41.05-1.47.14-2.94.27-4.41.38-4.26.69-8.53,1.2-12.78.63-5.25,1.33-10.5,2.18-15.72.92-5.63,2.04-11.23,3.07-16.84.73-3.99,1.22-8.03,2.23-11.95,1.74-6.77,3.67-13.51,5.8-20.17.76-2.37,2.14-4.61,3.59-6.66,1.05-1.49,2.62-2.65,4.07-3.82,6.08-4.88,12.09-9.86,18.34-14.52,4.77-3.55,9.8-6.77,14.82-9.97,13.26-8.43,27.33-15.36,41.35-22.38,13.87-6.94,28.29-12.49,43.1-17.07,6.81-2.11,13.66-4.07,20.67-5.5-.25,1.22-.39,1.42-1.42,1.69-13.06,3.43-25.64,8.26-38.17,13.21-8.35,3.3-16.39,7.25-24.35,11.37-6.67,3.45-13.39,6.81-19.95,10.47-6.61,3.68-13.14,7.53-19.55,11.57-8.21,5.17-16.25,10.61-24.38,15.91-4.23,2.76-8.05,6.02-11.92,9.25-1.81,1.51-2.89,3.25-3.44,5.46-1.94,7.73-3.97,15.45-5.84,23.2-1.14,4.69-2.15,9.42-3.07,14.16-.85,4.41-1.53,8.86-2.21,13.3-.68,4.45-1.32,8.9-1.89,13.37-.27,2.12-.34,4.26-.52,6.39-.31,3.75-.63,7.5-.94,11.25-.05.56,0,1.12-.02,1.68-.07,2.35-.19,4.71-.22,7.06-.01,1.19.13,2.38.21,3.57-.08.05-.15.1-.23.16Z" />
          <path d="m671.16,776.11c0-4.67.12-9.14-.03-13.6-.17-4.96-.57-9.92-.92-14.88-.2-2.89-.43-5.77-.74-8.65-.31-2.86-.75-5.71-1.14-8.57-.48-3.48-.94-6.96-1.49-10.43-.6-3.77-1.25-7.53-1.97-11.28-1.13-5.83-2.24-11.66-3.55-17.45-1.76-7.74-3.63-15.46-5.61-23.15-1.27-4.93-3.11-9.67-5.69-14.09-.28-.47-.65-.91-1.05-1.29-2.43-2.31-4.84-4.63-7.32-6.88-3.68-3.35-7.31-6.75-11.14-9.92-5.38-4.47-10.81-8.9-16.42-13.08-11.22-8.36-23.42-15.08-36.09-20.98-9.9-4.61-20-8.7-30.39-12.06-7.93-2.57-16.05-4.38-24.18-6.14-4.02-.87-8.05-1.74-12.06-2.64-.64-.14-1.24-.44-1.85-.66l.06-.31c2.3.29,4.62.48,6.9.9,4.7.87,9.37,1.93,14.07,2.79,7,1.29,13.88,3.04,20.66,5.17,8.46,2.65,16.72,5.82,24.85,9.37,9.84,4.3,19.41,9.09,28.57,14.7,7.87,4.82,15.45,10.07,22.57,15.95,4.99,4.12,9.86,8.38,14.76,12.61,2.38,2.06,4.85,4.05,6.96,6.36,1.57,1.73,3.02,3.79,3.84,5.96,4.11,10.81,7.26,21.92,9.88,33.18,1.93,8.31,3.77,16.64,5.47,25,1.07,5.28,1.85,10.63,2.69,15.96.7,4.44,1.34,8.9,1.89,13.36.36,2.91.59,5.84.74,8.76.37,7.24.63,14.48.99,21.71.05,1.07-.28,1.82-1.08,2.46-.68.54-1.33,1.11-2.17,1.81Z" />
        </g>
      </g>
    </g>
  );
};

export default Brazil;
