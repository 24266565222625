const CollegeHoodie = () => {
  const styles = {
    one: {
      fill: "#f2f2f2",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#77262c",
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#eae8e8",
    },
    five: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#fff",
    },
    six: {
      fill: "#77262c",
    },
  };

  return (
    <g>
      <path d="M450.27,570.05c-.01,1.33-.14,2.64-.28,3.95l-.12,.98c-.03,.33-.12,.72-.22,1.06-.2,.71-.51,1.4-.93,2.02-.81,1.27-2.06,2.17-3.12,2.89-.52,.36-.86,.67-1.21,1.11-.31,.44-.6,.92-.79,1.46-.41,1.07-.59,2.29-.62,3.5-.02,.61,0,1.22,.06,1.85,.05,.59,.15,1.15,.32,1.7,.69,2.22,2.35,4.17,4.18,5.87,1.85,1.71,3.95,3.2,6.12,4.54,2.15,1.38,4.47,2.48,6.82,3.51,4.73,1.97,9.73,3.33,14.8,4.05,1.25,.18,2.58,.33,3.76,.38,1.29,0,2.58,.03,3.86,0,2.56-.12,5.11-.35,7.63-.82,5.03-.91,9.97-2.48,14.48-4.86,2.25-1.18,4.45-2.51,6.39-4.11,.49-.39,.94-.83,1.41-1.24,.45-.43,.86-.89,1.29-1.33l.55-.71c.2-.23,.37-.47,.5-.72,.26-.42,.66-1.15,.94-1.65,.57-1.05,.89-2.1,.81-3.18-.05-1.09-.45-2.17-1.07-3.16-1.25-1.99-3.27-3.61-5.41-4.86l.98-1.74c1.2,.67,2.33,1.44,3.39,2.33,1.06,.89,2.04,1.91,2.83,3.13,.78,1.21,1.35,2.65,1.44,4.18,.14,1.54-.35,3.09-1.02,4.33-.35,.65-.58,1.07-.99,1.77-.18,.33-.38,.62-.6,.89l-.65,.84c-.47,.5-.93,1.03-1.43,1.5-.51,.45-1,.93-1.53,1.36-2.09,1.74-4.38,3.17-6.75,4.42-4.75,2.51-9.9,4.14-15.15,5.1-2.63,.49-5.29,.73-7.96,.86-1.33,.04-2.66,0-3.98,.01-1.44-.06-2.71-.21-4.05-.4-10.51-1.56-20.84-5.62-28.88-12.86-1.94-1.86-3.84-4.02-4.72-6.84-.21-.7-.34-1.44-.39-2.15-.05-.68-.07-1.38-.05-2.07,.05-1.39,.25-2.8,.79-4.18,.25-.69,.63-1.35,1.09-1.96,.45-.6,1.11-1.16,1.67-1.53,1.1-.73,2.01-1.41,2.67-2.33,.32-.46,.61-.95,.82-1.48,.11-.28,.18-.52,.27-.84l.26-.95c.37-1.26,.76-2.51,1.26-3.75l.54,.11Z" />
      <g>
        <path d="M289.61,748.67c-.91-1.03-1.83-2.05-2.71-3.1-.16-.19-.28-.51-.27-.76,.05-2.47,.11-4.94,.2-7.41,.05-1.47,.14-2.94,.27-4.41,.38-4.26,.69-8.53,1.2-12.78,.63-5.25,1.33-10.5,2.18-15.72,.92-5.63,2.04-11.23,3.07-16.84,.73-3.99,1.22-8.03,2.23-11.95,1.74-6.77,3.67-13.51,5.8-20.17,.76-2.37,2.14-4.61,3.59-6.66,1.05-1.49,2.62-2.65,4.07-3.82,6.08-4.88,12.09-9.86,18.34-14.52,4.77-3.55,9.8-6.77,14.82-9.97,13.26-8.43,27.33-15.36,41.35-22.38,13.87-6.94,28.29-12.49,43.1-17.07,6.81-2.11,13.66-4.07,20.67-5.5-.25,1.22-.39,1.42-1.42,1.69-13.06,3.43-25.64,8.26-38.17,13.21-8.35,3.3-16.39,7.25-24.35,11.37-6.67,3.45-13.39,6.81-19.95,10.47-6.61,3.68-13.14,7.53-19.55,11.57-8.21,5.17-16.25,10.61-24.38,15.91-4.23,2.76-8.05,6.02-11.92,9.25-1.81,1.51-2.89,3.25-3.44,5.46-1.94,7.73-3.97,15.45-5.84,23.2-1.14,4.69-2.15,9.42-3.07,14.16-.85,4.41-1.53,8.86-2.21,13.3-.68,4.45-1.32,8.9-1.89,13.37-.27,2.12-.34,4.26-.52,6.39-.31,3.75-.63,7.5-.94,11.25-.05,.56,0,1.12-.02,1.68-.07,2.35-.19,4.71-.22,7.06-.01,1.19,.13,2.38,.21,3.57-.08,.05-.15,.1-.23,.16Z" />
        <path d="M671.16,776.11c0-4.67,.12-9.14-.03-13.6-.17-4.96-.57-9.92-.92-14.88-.2-2.89-.43-5.77-.74-8.65-.31-2.86-.75-5.71-1.14-8.57-.48-3.48-.94-6.96-1.49-10.43-.6-3.77-1.25-7.53-1.97-11.28-1.13-5.83-2.24-11.66-3.55-17.45-1.76-7.74-3.63-15.46-5.61-23.15-1.27-4.93-3.11-9.67-5.69-14.09-.28-.47-.65-.91-1.05-1.29-2.43-2.31-4.84-4.63-7.32-6.88-3.68-3.35-7.31-6.75-11.14-9.92-5.38-4.47-10.81-8.9-16.42-13.08-11.22-8.36-23.42-15.08-36.09-20.98-9.9-4.61-20-8.7-30.39-12.06-7.93-2.57-16.05-4.38-24.18-6.14-4.02-.87-8.05-1.74-12.06-2.64-.64-.14-1.24-.44-1.85-.66l.06-.31c2.3,.29,4.62,.48,6.9,.9,4.7,.87,9.37,1.93,14.07,2.79,7,1.29,13.88,3.04,20.66,5.17,8.46,2.65,16.72,5.82,24.85,9.37,9.84,4.3,19.41,9.09,28.57,14.7,7.87,4.82,15.45,10.07,22.57,15.95,4.99,4.12,9.86,8.38,14.76,12.61,2.38,2.06,4.85,4.05,6.96,6.36,1.57,1.73,3.02,3.79,3.84,5.96,4.11,10.81,7.26,21.92,9.88,33.18,1.93,8.31,3.77,16.64,5.47,25,1.07,5.28,1.85,10.63,2.69,15.96,.7,4.44,1.34,8.9,1.89,13.36,.36,2.91,.59,5.84,.74,8.76,.37,7.24,.63,14.48,.99,21.71,.05,1.07-.28,1.82-1.08,2.46-.68,.54-1.33,1.11-2.17,1.81Z" />
      </g>
      <g>
        <path
          style={styles.six}
          d="M673.05,774.99c-10.77,9.78-27.1,22.99-49.05,35.01-69.26,37.93-137.84,33.1-167,29-52.73-7.41-89.83-28.31-104-37-30.34-18.6-51.24-39.78-64.22-55.07-.07-18.76,1.61-41.2,7.22-65.93,2.37-10.43,5.16-20.08,8.15-28.91,22.39-19.49,54.11-42.4,95.85-60.09,15.89-6.73,31.04-11.78,45.03-15.6,1.23-.34,2.45-.66,3.66-.98-.61,.69-1.22,1.38-1.82,2.07-.37,.41-.73,.82-1.09,1.23,0,0,0,.01-.01,.01-.82,.92-1.63,1.85-2.44,2.77-.45,.5-.89,1-1.33,1.5-.35,1.67-.65,4.18,0,7,1.6,6.94,7.87,11.11,13.37,13.86-.28,.28-.56,.57-.83,.86-.64,.66-1.25,1.36-1.86,2.08-.07,.08-.14,.16-.21,.25-19.01,22.9-6.45,51.86-4.96,55.15l.49-.15c.08-.8,.46-1.57,1.09-2.19,.3-.3,.65-.56,1.07-.76,1.42-.73,3.14-.7,4.49-.04,.1,.05,.19,.1,.28,.16-2.06-4.7-10.08-25.61,3.48-44.54,1.3-1.81,2.69-3.45,4.12-4.94,.57-.59,1.14-1.16,1.72-1.7,.1-.1,.21-.19,.31-.28,11.02,3.65,20.39,2.51,22.44,2.24,2.67-.36,5.47-.96,8.25-1.76,.01,0,.03,0,.04-.01,.6-.17,1.21-.35,1.81-.55,.31-.09,.61-.19,.92-.3,.02,0,.04-.01,.06-.02,.51-.17,1.03-.35,1.54-.54,.19-.06,.37-.13,.56-.2,.91-.34,1.81-.7,2.69-1.09l.02,.02c.96,.77,1.91,1.6,2.86,2.49,.58,.54,1.15,1.11,1.72,1.7,1.43,1.49,2.82,3.13,4.12,4.94,13.56,18.93,5.54,39.84,3.48,44.54,.09-.06,.18-.11,.28-.16,1.35-.66,3.07-.69,4.49,.04,.42,.2,.77,.46,1.07,.76,.63,.62,1.01,1.39,1.09,2.19l.49,.15c1.49-3.29,14.05-32.25-4.96-55.15-.07-.09-.14-.17-.21-.25-.61-.72-1.22-1.42-1.86-2.08-1.3-1.38-2.65-2.65-4.03-3.81,4.71-3.28,7.78-7.08,7.57-10.91-.11-1.89-1.1-5.21-7.11-9.07,23.67,3.14,61.63,11.59,100.11,37.07,17.07,11.3,30.5,23.47,40.79,34.4,5.36,17.51,10.44,37.82,14.21,60.6,3.77,22.8,5.49,43.67,6.05,61.99Z"
        />
        <path
          style={styles.four}
          d="M522.02,662.39c0,.33-.06,.66-.16,1-.11,.32-.27,.64-.47,.95l-5.1,7.29c-1.25,1.79-4.01,2.38-6.11,1.3-2.1-1.08-2.8-3.43-1.53-5.23l5.09-7.28c.34-.5,.79-.9,1.33-1.2,.09-.06,.18-.11,.28-.16,1.35-.66,3.07-.69,4.49,.04,.42,.2,.77,.46,1.07,.76,.63,.62,1.01,1.39,1.09,2.19,.01,.11,.02,.23,.02,.34Z"
        />
        <path
          style={styles.five}
          d="M478.22,598.56c-3.27,1.58-7.78,4.1-12.2,7.9-.19,.16-.38,.32-.57,.5-.3,.25-.59,.52-.89,.8-.1,.09-.21,.18-.31,.28-.58,.54-1.15,1.11-1.72,1.7-1.43,1.49-2.82,3.13-4.12,4.94-13.56,18.93-5.54,39.84-3.48,44.54-.09-.06-.18-.11-.28-.16-1.35-.66-3.07-.69-4.49,.04-.42,.2-.77,.46-1.07,.76-.63,.62-1.01,1.39-1.09,2.19l-.49,.15c-1.49-3.29-14.05-32.25,4.96-55.15,.07-.09,.14-.17,.21-.25,.61-.72,1.22-1.42,1.86-2.08,.27-.29,.55-.58,.83-.86,.34-.35,.69-.69,1.04-1.02,2.88-2.73,5.91-4.97,8.85-6.79,2.12-1.32,4.2-2.43,6.12-3.35,1.94-.84,4.31-.53,5.85,.77,1.58,1.32,1.98,3.38,.99,5.09Z"
        />
        <path
          style={styles.four}
          d="M459.82,672.93c-2.1,1.08-4.86,.49-6.11-1.3l-5.1-7.29c-.2-.31-.36-.63-.47-.95-.1-.34-.16-.67-.16-1,0-.11,.01-.23,.02-.34,.08-.8,.46-1.57,1.09-2.19,.3-.3,.65-.56,1.07-.76,1.42-.73,3.14-.7,4.49-.04,.1,.05,.19,.1,.28,.16,.54,.3,.99,.7,1.33,1.2l5.09,7.28c1.27,1.8,.57,4.15-1.53,5.23Z"
        />
        <path
          style={styles.five}
          d="M522.49,662.2l-.49-.15c-.08-.8-.46-1.57-1.09-2.19-.3-.3-.65-.56-1.07-.76-1.42-.73-3.14-.7-4.49-.04-.1,.05-.19,.1-.28,.16,2.06-4.7,10.08-25.61-3.48-44.54-1.3-1.81-2.69-3.45-4.12-4.94-.57-.59-1.14-1.16-1.72-1.7-.95-.89-1.9-1.72-2.86-2.49l-.02-.02c-.74-.6-1.49-1.17-2.23-1.7-3.28-2.38-6.41-4.09-8.86-5.27-.99-1.71-.59-3.77,.99-5.09,1.54-1.3,3.91-1.61,5.85-.77,1.14,.55,2.34,1.16,3.56,1.85,2.89,1.6,5.96,3.61,8.95,6.1,.1,.08,.2,.17,.3,.26,1.38,1.16,2.73,2.43,4.03,3.81,.64,.66,1.25,1.36,1.86,2.08,.07,.08,.14,.16,.21,.25,19.01,22.9,6.45,51.86,4.96,55.15Z"
        />
        <path
          style={styles.three}
          d="M681,324c-8.4-20.56-18.96-36.52-28-48-2.16-3.35-53.76-80.27-146-76-84.15,3.89-128.22,71.93-132,78-8.62,10.33-20.49,25.87-32.12,46.25-20.69,36.22-37.31,65.33-34.88,98.75,3.44,47.34,43.48,81.13,86,117,19.56,16.5,37.61,28.38,51.03,36.4,.62,.37,1.24,.73,1.84,1.09,.09,.05,.17,.1,.26,.15-.46,.34-.91,.7-1.35,1.08,0,0,0,.01-.01,.01-.93,.82-1.78,1.74-2.44,2.77-.69,1.06-1.17,2.22-1.33,3.5-.71,5.69,5.37,12.69,14.41,17.84,2.88-2.73,5.91-4.97,8.85-6.79,2.12-1.32,4.2-2.43,6.12-3.35,1.94-.84,4.31-.53,5.85,.77,1.58,1.32,1.98,3.38,.99,5.09-3.27,1.58-7.78,4.1-12.2,7.9-.19,.16-.38,.32-.57,.5,1.59,.56,3.23,1.06,4.89,1.48,4.44,1.13,9.08,1.72,13.66,1.56,.49-.02,.99-.04,1.49-.08,3.21-.22,6.53-.8,9.76-1.68,.01,0,.03,0,.04-.01,.72-.19,1.44-.4,2.15-.62,.93-.29,1.84-.61,2.73-.94,.93-.35,1.84-.72,2.72-1.12l-.02-.02c-.74-.6-1.49-1.17-2.23-1.7-3.28-2.38-6.41-4.09-8.86-5.27-.99-1.71-.59-3.77,.99-5.09,1.54-1.3,3.91-1.61,5.85-.77,1.14,.55,2.34,1.16,3.56,1.85,2.89,1.6,5.96,3.61,8.95,6.1,1.3-.69,5.67-3.22,7.87-8.65,1.58-3.91,1.25-7.4,1-9,15.34-1.27,77.02-8.15,124.83-60,63.2-68.54,48.06-156.99,46.17-167-2.12-9.54-5.26-20.39-10-32Zm-196.93,285.39c.11-.96,.47-3.89,.55-4.51-.16,1.8-.37,3.32-.55,4.51Zm9.13-44.6c-4.11,8.72-5.42,17.53-7.86,34.8-.2,1.44-1.18,9.17-1.34,10.41,2.77-21.92,6.11-50.09-3-66-2.82-4.92-9.75-14.51-27-21-9.91-4.55-23.89-12.25-38-25-8.91-8.05-28.5-26.18-39-57-4.65-13.64-13.5-39.62-3.06-68.18,7.25-19.82,20.78-30.91,29.48-39.48,24.01-23.62,52.27-28.4,66.19-31.24,32.94-6.71,59.07-.95,71.39,1.9,25.11,5.82,56.02,12.98,77,41,19.22,25.67,19.15,54.2,19,63-.89,53.44-41.68,86.6-48.13,91.68-10.07,10.89-19.65,16.85-26.21,20.2-14.99,7.67-24.64,7.11-40.66,16.12-9.21,5.19-15.79,10.87-19.65,14.54-4.79,5.79-7.56,10.88-9.15,14.25Z"
        />
        <path
          style={styles.two}
          d="M646.46,415.73c-.75,8.22-2.49,15.23-2.62,15.71-2.73,10.08-6.86,21.11-13.1,32.56-25.59,46.92-70.91,71.75-94.53,82.58-4.13,1.75-9.2,4.38-14.25,8.35-10.52,8.29-15.07,17.82-17.03,23.06l-2.75,16.56c-1.22-.69-2.42-1.3-3.56-1.85-1.94-.84-4.31-.53-5.85,.77-1.58,1.32-1.98,3.38-.99,5.09,2.45,1.18,5.58,2.89,8.86,5.27l-.46,2.79v.05c-.19,.05-.38,.1-.56,.15-.21,.06-.41,.12-.62,.18-.31,.12-.61,.24-.92,.36-.02,.01-.04,.02-.06,.02-.19,.08-.39,.16-.58,.23-.11,.02-.23,.05-.34,.07-2.04,.44-4.06,.88-6.1,1.32-1.84,.3-3.67,.61-5.51,.92-.16,.03-.33,.05-.49,.08-2.13,.26-4.87,.39-8,0-2.53-.32-4.76-.9-6.66-1.56-1.71-.6-3.16-1.26-4.31-1.86,0-.04-.01-.08-.01-.12,4.42-3.8,8.93-6.32,12.2-7.9,.99-1.71,.59-3.77-.99-5.09-1.54-1.3-3.91-1.61-5.85-.77-1.92,.92-4,2.03-6.12,3.35l-.96-13.06c-2.07-5.16-6.18-13.28-14.42-21.32-6.67-6.52-13.66-10.65-18.68-13.13-15.53-5.99-47.14-20.49-70.43-50.86-.12-.15-.23-.3-.34-.45-15.68-20.62-22.5-45.22-20.03-69.73,1.24-12.3,3.84-25.72,8.57-39.84,2.73-8.12,5.86-15.61,9.18-22.45,3.73-9.59,17.57-41.16,56.35-61.74,24.83-13.18,47.75-15.46,65.53-16.84,8.31-.64,20.65,.05,31.97,.37,15.34,.44,21.54,1,28,2,4.5,.7,12.43,1.96,22,5,17.88,5.69,30.11,13.99,33,16,18.2,12.65,27.97,27.29,33,35,5.91,9.05,14.25,20.64,17,38,.8,5.04,2.74,18.78,1.46,32.73Z"
        />
        <g>
          <path
            style={styles.one}
            d="M358.78,719.29l3.17-.03v-23.41s-3.17,0-3.17,0v-8.77h15.78v8.74s-3.14,0-3.14,0v5.83s9.44-.04,9.44-.04v-5.82s-3.15,0-3.15,0v-8.73s15.63,0,15.63,0l.02,8.69h-3.12s.04,23.25,.04,23.25l3.13-.03,.02,8.72-15.68,.16v-8.75s3.14-.03,3.14-.03v-8.75s-9.46,.06-9.46,.06v8.77s3.15-.03,3.15-.03v8.76s-15.81,.17-15.81,.17v-8.79Z"
          />
          <path
            style={styles.one}
            d="M396.53,718.98l3.11-.03,6.15-23.2h-3.09s-.02-8.67-.02-8.67h19.27l8.55,31.61,3.05-.03,.04,8.63-15.34,.16-.03-8.66,2.31-.02-.78-2.88-9.26,.07-.76,2.9,2.31-.02,.03,8.67-15.52,.16-.02-8.71Zm18.54-20.36l-2.28,8.66,4.62-.02-2.33-8.64Z"
          />
          <path
            style={styles.one}
            d="M436.61,718.65l3.04-.03-.1-22.95h-3.04s-.04-8.59-.04-8.59h27.22l6.03,5.69,.07,11.37-5.97,5.72-3.01,.02,4.57,8.53,4.5-.04,.05,8.54-15.06,.16-.05-8.58h1.51s-4.58-8.56-4.58-8.56l-3.02,.02,.04,8.59,3.02-.02,.04,8.58-15.2,.16-.04-8.62Zm24.14-20.17l-3.02-2.85-9.07,.02,.03,5.72,9.07-.03,2.99-2.86Z"
          />
          <path
            style={styles.one}
            d="M475.71,695.6h-2.98s-.05-8.51-.05-8.51h14.88l.06,8.48h-2.22s5.34,19.8,5.34,19.8l5.03-19.82h-2.21s-.06-8.46-.06-8.46h14.71l.07,8.44h-2.93s-7.86,31.11-7.86,31.11l-13.35,.14-8.42-31.17Z"
          />
          <path
            style={styles.one}
            d="M511.4,718.03l2.93-.02,5.64-22.51h-2.91s-.08-8.41-.08-8.41h18.15l8.26,30.68,2.88-.02,.09,8.37-14.45,.15-.08-8.4,2.17-.02-.75-2.8-8.72,.07-.7,2.82,2.18-.02,.08,8.42-14.61,.15-.07-8.45Zm17.33-19.75l-2.1,8.4,4.35-.02-2.25-8.38Z"
          />
          <path
            style={styles.one}
            d="M549.15,717.72l2.87-.02-.24-22.27h-2.86s-.09-8.34-.09-8.34h25.65l5.72,5.52,.14,11.04-5.59,5.56-2.84,.02,4.36,8.28,4.25-.04,.1,8.29-14.19,.15-.1-8.33h1.42s-4.37-8.31-4.37-8.31l-2.85,.02,.09,8.34,2.85-.02,.1,8.33-14.32,.15-.09-8.36Zm22.62-19.57l-2.87-2.76-8.54,.02,.06,5.55,8.55-.03,2.8-2.77Z"
          />
          <path
            style={styles.one}
            d="M583.32,717.44l2.81-.02-.28-22.07h-2.81s-.1-8.26-.1-8.26h22.4l5.63,5.48,.39,27.38-5.49,5.55-22.44,.24-.1-8.29Zm19.61-2.92l-.23-16.47-2.83-2.74h-5.59s.29,22.03,.29,22.03l5.6-.05,2.76-2.78Z"
          />
        </g>
      </g>
    </g>
  );
};

export default CollegeHoodie;
