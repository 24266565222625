const ESP = () => {
  const styles = {
    one: {
      fill: "none",
      stroke: "red",
      strokeMiterLimit: 10,
      strokeWidth: 3,
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="m543.65,274.35c-19.65,64.35-37.8,137.96-50.87,219.91-21.08,132.17-23.22,250.09-18,346.74"
      />
      <path
        style={styles.one}
        d="m291.99,750.49c1.1-15.79,3.5-34.17,8.28-54.31,3.84-16.18,8.52-30.58,13.3-43.04,18.86-13.64,96.1-66.15,200.03-52.65,63.03,8.19,107.67,37,129.45,53.43,2.47,13.06,4.82,26.36,7.04,39.91,5.07,30.91,9.08,60.9,12.22,89.84"
      />
    </g>
  );
};

export default ESP;
