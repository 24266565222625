import { AppBar, Grid, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  CLEAR_STORE,
  initialStore,
  SET_ERRORS,
  SET_JOIN_MATCH_ID,
  SET_NOTI_COUNT,
  SET_SNIPE_MATCH_ID,
  SET_SUCCESSES,
  SET_TOS_VERSION,
  SET_USER,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { isUserAuthorized } from "../../utils/helpers";
import {
  background,
  card,
  cardDark,
  cardLight,
  cardVeryLight,
  container,
  lightGrey,
  text,
} from "../../utils/themeContstants";
import RightNavButtons from "./RightNavButtons";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import RivoxLogo from "../custom/RivoxLogo";
import useMiddleware, {
  getAccessToken,
  saveAccessToken,
  saveRefreshToken,
} from "../../api/useMiddleware";
import { getUser, logout } from "../../api/user";
import useNotifications from "../../sockets/useNotifications";
import LoginRegisterModal from "./LoginRegisterModal";
import NewsModal from "../news/NewsModal";
import MoreMenu from "./MoreMenu";
import JoinMatch from "../matches/JoinMatch";
import BannedModal from "../home/BannedModal";
import MobileNotiNavbar from "../mobile/notifications/MobileNotiNavbar";
import SnipeModal from "../tokens/SnipeModal";
import UpdateTOSModal from "../support/UpdateTOSModal";
import PurchaseUnbanModal from "../public_profile/PurchaseUnbanModal";
import NavbarNavSection from "./navbar/NavbarNavSection";
import NavSearchBar from "./navbar/NavSearchBar";
import { BiLeftArrowAlt } from "react-icons/bi";
import CustomIconButton from "../custom/CustomIconButton";
import { FiMoreVertical } from "react-icons/fi";
import PayDebtModal from "../public_profile/PayDebtModal";
import PayPalDepositModal from "../wallet/PayPalDepositModal";
import PayUnlinkDebtModal from "../public_profile/PayUnlinkDebtModal";

const Navbar = () => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();
  const storeDispatch = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const isTablet = useMediaQuery("(max-width: 768px)");
  const { userData, notiCount } = useNotifications(
    store?.user?._id,
    getAccessToken()
  );
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const [logoHovered, setLogoHovered] = useState(false);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const [loading, setLoading] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [walletOpen, setWalletOpen] = useState(false);
  const [unbanOpen, setUnbanOpen] = useState(false);

  const [debtOpen, setDebtOpen] = useState(false);
  const [unlinkDebtOpen, setUnlinkDebtOpen] = useState(false);
  const [newsVersion, setNewsVersion] = useState(null);
  const [newsOpen, setNewsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [moreAnchor, setMoreAnchor] = useState(null);
  const [banOpen, setBanOpen] = useState(false);
  const [tosOpen, setTosOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [amount, setAmount] = useState(null);
  const [debtDescription, setDebtDescription] = useState(null);
  const [depositOpen, setDepositOpen] = useState(false);

  const handleMoreOpen = (e) => {
    e.stopPropagation();
    setMoreAnchor(e.currentTarget);
  };

  const handleMoreClose = (e) => {
    e?.stopPropagation();
    setMoreAnchor(null);
  };

  const handleCloseJoin = () => {
    storeDispatch({ type: SET_JOIN_MATCH_ID, payload: null });
  };

  const handleCloseSnipe = () => {
    storeDispatch({ type: SET_SNIPE_MATCH_ID, payload: null });
  };

  useEffect(() => {
    const path = location?.pathname;

    if (path === "/wagers/matches") {
      setSelected("games");
      return;
    }

    if (path === "/shop/avatars") {
      setSelected("shop");
      return;
    }

    setSelected(null);
  }, [location]);

  useEffect(() => {
    const error = searchParams.get("error");
    const success = searchParams.get("success");
    const message = searchParams.get("message");

    if (error && message) {
      const newErrors = store?.errors;
      newErrors.push(message);
      storeDispatch({ type: SET_ERRORS, payload: [...newErrors] });
      return;
    }

    if (success && message) {
      const newSuccesses = store?.successes;
      newSuccesses.push(message);
      storeDispatch({ type: SET_SUCCESSES, payload: [...newSuccesses] });
      return;
    }
  }, [searchParams, store?.errors, store?.successes, storeDispatch]);

  useEffect(() => {
    const localToken = localStorage.getItem("accessToken");
    if (accessToken !== localStorage.getItem("accessToken")) {
      setAccessToken(localToken);
    }
  }, [accessToken]);

  useEffect(() => {
    if (!store.user && !loading) {
      if (isUserAuthorized()) {
        setLoading(true);
        getUser(middleware).then((res) => {
          if (res?.error) {
            const newErrors = store?.errors;
            newErrors.push(res?.message);
            storeDispatch({ type: SET_ERRORS, payload: [...newErrors] });
            setLoading(false);
          } else {
            storeDispatch({ type: SET_USER, payload: res?.user });
            storeDispatch({
              type: SET_NOTI_COUNT,
              payload: res?.noti_count,
            });
            storeDispatch({
              type: SET_TOS_VERSION,
              payload: res?.tos_version,
            });
            setNewsVersion(res?.news_version);
            setLoading(false);
          }
        });
      } else {
        setLoading(false);
      }
    }
  }, [store.user, middleware, storeDispatch, accessToken, store?.errors]);

  useEffect(() => {
    if (store?.user) {
      if (store?.user?.behavior_info?.banned) {
        logout(middleware).then((res) => {
          if (res?.error) {
            const newErrors = store?.errors;
            newErrors.push(res?.message);
            storeDispatch({ type: SET_ERRORS, payload: [...newErrors] });
          } else {
            saveAccessToken(null);
            saveRefreshToken(null);
            storeDispatch({ type: CLEAR_STORE, payload: initialStore });
            navigate("/login");
          }
        });
      }
    }
  }, [store?.user, store?.errors, middleware, navigate, storeDispatch]);

  useEffect(() => {
    if (userData) {
      storeDispatch({ type: SET_USER, payload: { ...userData } });
    }
  }, [userData]);

  useEffect(() => {
    if (location?.pathname === "/signup" || location?.pathname === "/login") {
      setLoginOpen(true);
      return;
    }
  }, [location]);

  useEffect(() => {
    if (newsVersion) {
      if (store?.user?.info?.last_seen_news_version !== newsVersion) {
        setNewsOpen(true);
      }
    }
  }, [newsVersion]);

  // useEffect(() => {
  //   if (store?.tosVersion) {
  //     if (store?.user?.info?.last_agreed_tos_version !== store?.tosVersion) {
  //       setTosOpen(true);
  //       return;
  //     }
  //   }
  // }, [store?.tosVersion]);

  useEffect(() => {
    if (store?.user) {
      if (store?.user?.behavior_info?.has_debt) {
        if (
          store?.user?.behavior_info?.debt_price > store?.user?.funds?.balance
        ) {
          setAmount(
            parseFloat(
              parseFloat(store?.user?.behavior_info?.debt_price + 0.01) -
                parseFloat(store?.user?.funds?.balance)
            ).toFixed(2)
          );
          setDebtDescription(
            "You do not have enough funds to pay your debt. Please deposit the required funds to be able pay off your debt."
          );
          return setDepositOpen(true);
        } else {
          setDebtDescription(null);
          setAmount(null);
          return setUnbanOpen(true);
        }
      }
    }
  }, [store?.user?.behavior_info?.has_debt]);

  useEffect(() => {
    if (store?.user) {
      if (store?.user?.behavior_info?.has_match_debt) {
        if (
          store?.user?.funds?.balance <
          store?.user?.behavior_info?.debt_reset_price
        ) {
          setAmount(
            parseFloat(
              parseFloat(store?.user?.behavior_info?.debt_reset_price + 0.01) -
                parseFloat(store?.user?.funds?.balance)
            ).toFixed(2)
          );
          setDebtDescription(
            "You do not have enough funds to pay your match debt. Please deposit the required funds to be able pay off your match debt."
          );
          return setDepositOpen(true);
        } else {
          setDebtDescription(null);
          setAmount(null);
          return setDebtOpen(true);
        }
      }
    }
  }, [store?.user, store?.user?.behavior_info?.has_match_debt]);

  useEffect(() => {
    if (store?.user) {
      if (store?.user?.behavior_info?.has_unlink_debt) {
        if (
          store?.user?.funds?.balance <
          store?.user?.behavior_info?.unlink_debt_price
        ) {
          setAmount(
            parseFloat(
              parseFloat(store?.user?.behavior_info?.unlink_debt_price + 0.01) -
                parseFloat(store?.user?.funds?.balance)
            ).toFixed(2)
          );
          setDebtDescription(
            "You do not have enough funds to pay your unlink debt. Please deposit the required funds to be able pay off your unlink debt."
          );
          return setDepositOpen(true);
        } else {
          setDebtDescription(null);
          setAmount(null);
          return setUnlinkDebtOpen(true);
        }
      }
    }
  }, [store?.user, store?.user?.behavior_info?.has_unlink_debt]);

  useEffect(() => {
    if (notiCount) {
      storeDispatch({ type: SET_NOTI_COUNT, payload: notiCount });
    }
  }, [notiCount]);

  useEffect(() => {
    if (!store?.user) {
      const banned = searchParams.get("banned");
      const time = searchParams.get("time");
      const log = searchParams.get("log");
      if (banned && time && log) {
        setBanOpen(true);
      } else {
        // setSearchParams({});
      }
    }
  }, [searchParams, store?.user]);

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 102,
      width: "100%",
      backgroundColor: card,
      // borderBottom: `1px solid ${cardVeryLight}`,
      zIndex: 99,
      paddingLeft: 1,
      paddingRight: 1,
      boxShadow: `${cardDark} 0 4px 6px -1px`,
    },
    balanceContainer: {
      height: 40,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: card,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 1,
    },
    balance: {
      fontSize: 13,
      fontWeight: 700,
      color: text,
    },
  };

  return (
    <>
      <PayPalDepositModal
        open={depositOpen}
        onClose={() => setDepositOpen(false)}
        amount={amount}
        description={debtDescription}
      />

      <LoginRegisterModal
        open={loginOpen}
        onClose={() => setLoginOpen(false)}
      />
      <NewsModal open={newsOpen} onClose={() => setNewsOpen(false)} />
      <MoreMenu anchor={moreAnchor} handleClose={handleMoreClose} />
      <JoinMatch
        open={store?.joinMatchId}
        onClose={handleCloseJoin}
        match={null}
        matchId={store?.joinMatchId}
      />
      <SnipeModal
        open={store?.snipeMatchId}
        onClose={handleCloseSnipe}
        tokenId={store?.snipeMatchId}
      />
      <BannedModal open={banOpen} onClose={() => setBanOpen(false)} />
      <UpdateTOSModal open={tosOpen} onClose={() => setTosOpen(false)} />
      <PurchaseUnbanModal
        open={unbanOpen}
        onClose={() => setUnbanOpen(false)}
      />
      <PayDebtModal open={debtOpen} onClose={() => setDebtOpen(false)} />
      <PayUnlinkDebtModal
        open={unlinkDebtOpen}
        onClose={() => setUnlinkDebtOpen(false)}
      />

      <AppBar position="fixed" elevation={0} sx={styles.container}>
        {location?.pathname === "/notifications" ? (
          <MobileNotiNavbar />
        ) : (
          <Grid
            container
            justifyContent={isDesktop ? "center" : "start"}
            alignItems="center"
            sx={{
              position: "relative",
            }}
            wrap={"nowrap"}
          >
            <Grid item sx={{ flex: isDesktop ? 0.33 : 1 }}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 1 }}
              >
                <Grid
                  item
                  onMouseEnter={() => setLogoHovered(true)}
                  onMouseLeave={() => setLogoHovered(false)}
                  onClick={() => navigate("/")}
                  sx={{
                    cursor: logoHovered ? "pointer" : "default",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: focused ? -1 : null,
                  }}
                >
                  {!focused && <RivoxLogo size={72} />}
                </Grid>

                {/* {!isTablet && (
                  <CircleIconButton label="Search">
                    <IoSearch fontSize={20} color={lightGrey} />
                  </CircleIconButton>
                )} */}
                {!loading && store?.user && (
                  <NavSearchBar focused={focused} setFocused={setFocused} />
                )}

                {!isTablet && (
                  <Grid item sx={{ marginLeft: focused ? 2.2 : null }}>
                    <CustomIconButton
                      onClick={handleMoreOpen}
                      icon={
                        <FiMoreVertical style={{ color: text, fontSize: 20 }} />
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            {!loading && <>{isDesktop && <NavbarNavSection />}</>}
            {loading && <Grid item sx={{ flex: isDesktop ? 0.33 : 1 }} />}

            {/* {store?.user && isDesktop && (
              <Grid item>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid item sx={styles.balanceContainer}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 0.5 }}
                    >
                      <RivoxCoin size={20} />

                      <Grid item>
                        <Typography sx={styles.balance}>
                          {numFormatter(
                            store?.user?.funds?.balance < 1 ? 4 : 2
                          ).format(store?.user?.funds?.balance)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4,
                      backgroundColor: doubtedBlue,
                      height: 40,
                      padding: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transition: transition,
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "#147fff",
                      },
                    }}
                    id="wallet-anchor"
                    onClick={handleWalletOpen}
                  >
                    <BiWallet style={{ fontSize: 24, color: text }} />
                  </Grid>
                </Grid>
              </Grid>
            )} */}

            {!store?.searchOpen && <RightNavButtons loading={loading} />}
          </Grid>
        )}
      </AppBar>
    </>
  );
};

export default Navbar;
