const Crying = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "aqua",
    },
    three: {
      fill: "#050505",
    },
  };

  return (
    <g>
      <path d="M483.45,362.67c1.27,4.67,.98,8.98-1.59,13.25,3.7,.66,7.39,.96,10.69,2.69-.75-.11-1.51-.16-2.25-.32-8.62-1.85-16.95-.77-25.1,2.1-1.43,.5-2.84,1.04-4.28,1.51-4.32,1.41-7.49,5.13-7.63,9.29-.12,3.68-.08,7.37,0,11.06,.03,1.66-.82,2.81-2.12,3.77-1.2,.89-2.01,.69-2.64-.59-.2-.4-.34-.83-.4-1.27-.3-2.19-.63-4.39-.82-6.59-.21-2.38,.18-4.7,1.4-6.74-.11-.27-.13-.43-.23-.53-2.05-2.03-1.97-2.92,.47-4.58,.45-.31,.9-.64,1.41-.85,1.81-.78,2.89-1.93,3.17-3.85,.1-.71,.98-1.55,1.74-1.91,1.62-.76,3.39-1.25,5.33-1.93-2.52-2.27-3.76-5.03-3.72-8.25,.04-3.13,1.06-5.92,3.57-8.21-.12-.12-.23-.25-.35-.37-1.96,.58-3.97,1.06-5.87,1.77-5.33,1.98-10.13,4.85-14.77,7.91-1.72,1.13-3.32,1.44-5.38,.97-1.77-.4-3.66-.3-5.5-.48-1.25-.13-2.48-.37-3.72-.57,0-.17,0-.33,0-.5l3.94-.26c-1.15-.47-2.52-.31-3.76-1.51h6.18c-.36-1.31,.55-1.49,1.39-1.61,1.9-.27,3.82-.49,5.73-.69,1.87-.19,3.55-.76,5.21-1.61,5.96-3.04,12.2-5.34,19.13-5.67,4.42-.21,8.84-.34,13.25-.64,5.59-.37,10.83-2.02,15.95-3.88-1.2,1.4-1.9,1.78-6.7,3.18-2.51,.74-5.08,1.31-8.1,2.07,8.6,2.43,15.47,6.56,20.68,12.86-4.31-3.46-8.68-6.84-14.32-9.03Zm-15.22-3.04c-1.03,.07-2.47,.2-3.92,.27-1.02,.05-1.78,.46-2.52,1.11-3.17,2.82-3.73,6.3-2.87,10.01,1.02,4.41,6.3,7.24,11.09,6.28,2.74-.55,5.53-.91,8.31-1.25,.8-.1,1.29-.34,1.75-.94,2.71-3.53,3.19-7.47,2.61-11.61-.14-1.01-.74-1.49-1.76-1.85-3.97-1.38-8.08-1.92-12.7-2.02Zm-17.9,44.75c1.73-.95,1.18-2.22,1.19-3.3,.02-5.1-.79-10.25,1.61-15.7-1.61,1.12-3.14,1.41-3.99,2.75,1.8,1.28,1.97,2.77,.92,4.5-.34,.56-.56,1.24-.59,1.88-.15,3.23-.01,6.45,.87,9.88Zm12.81-25.51c-.51-.96-1.25-.89-1.96-.7-1.15,.31-2.29,.68-3.42,1.05-2.04,.68-3.21,1.88-2.72,3.99,2.7-1.45,5.41-2.9,8.1-4.34Z" />
      <path d="M578.62,378.43c3.63-1.54,7.32-1.84,10.7-2.43-.63-2.2-1.49-4.27-1.75-6.4-.26-2.15,.07-4.37,.15-6.77-5.28,2.12-9.71,5.4-13.82,9.09,.99-2.31,4.12-5.35,8.87-8.02,3.54-1.99,7.37-3.55,11.07-5.29-4.72-.96-9.58-2.13-13.83-4.78,1.49,.45,3,.89,4.48,1.37,5.53,1.77,11.2,2.61,17.07,2.53,9.78-.13,19.01,1.79,27.42,6.55,2.06,1.16,4.47,1.11,6.77,1.4,1.19,.15,2.41,.22,3.56,.52,.56,.14,.98,.71,1.89,1.42h5.22c-1.04,1.28-2.41,1.09-3.47,1.63,1.27,.05,2.54,.11,4.05,.17-1.06,1.06-2.36,.92-3.47,1.08-2.61,.36-5.24,.65-7.88,.79-.87,.04-1.88-.33-2.66-.76-2.06-1.15-3.96-2.57-6.05-3.65-3.57-1.84-7.21-3.58-10.9-5.21-1.41-.62-3.04-.83-4.74-1.08,2.24,2.46,3.33,5.19,3.33,8.33,0,3.18-1.01,5.94-3.77,8.31,.71,.22,1.2,.36,1.68,.52,1.28,.44,2.57,.84,3.81,1.36,1.07,.45,1.79,1.2,1.87,2.39,.1,1.46,1.01,2.42,2.39,3.1,.92,.45,1.79,1,2.6,1.6,1.79,1.33,1.81,2.22,.19,3.79-.22,.21-.39,.46-.64,.76,1.98,3.15,1.62,6.52,1.1,9.9-.21,1.39-.26,2.8-.61,4.16-.54,2.08-1.69,2.32-3.37,.82-1.02-.91-1.6-1.93-1.56-3.33,.09-3.54-.05-7.09,.05-10.63,.13-4.54-2.69-7.51-6.65-9.25-4.4-1.92-9.07-3.41-13.91-4.31-5.55-1.04-11.04-.82-16.54,.21-.55,.1-1.1,.21-1.66,.27-.22,.03-.46-.06-1-.14Zm24.75-18.8c-4.61,.1-8.72,.61-12.68,2.02-.88,.31-1.64,.65-1.76,1.6-.53,4.28-.31,8.42,2.74,12,.33,.39,.99,.66,1.55,.76,2.84,.48,5.72,.79,8.54,1.34,3.7,.72,8.55-1.27,10.17-4.41,1.7-3.32,1.9-8.51-2.33-12-.56-.46-1.34-.83-2.07-.95-1.5-.23-3.03-.26-4.16-.35Zm15.48,26.05c1.17,2.7,1.26,5.32,1.34,7.88,.08,2.72-.17,5.44-.24,8.16-.03,.96-.23,2.05,1.52,2.57,.26-2.41,.56-4.66,.74-6.92,.15-1.92,.09-3.83-1.04-5.58-.65-1.01-.41-1.98,.4-2.86,.29-.31,.58-.62,1.04-1.1-1.39-.8-2.57-1.47-3.76-2.15Zm-2.39-2.29c.72-2.62-1.03-3.63-3.21-4.4-3.12-1.1-3.53-1.13-4.95,.05,1.54,.67,3.01,1.2,4.36,1.93,1.35,.72,2.58,1.64,3.8,2.42Z" />
      <path d="M452.35,406.56c2.56,.71,3.82,2.63,3.06,4.43-.5,1.17-1.54,1.37-2.3,.34-.42-.56-.63-1.32-.72-2.02-.12-.85-.03-1.72-.03-2.76Z" />
      <path d="M619.26,406.61c0,1.03,.04,2.03-.02,3.02-.03,.42-.14,.93-.43,1.22-.44,.45-1.06,.76-1.6,1.13-.42-.56-1.08-1.09-1.2-1.7-.33-1.73,1-3.18,3.24-3.67Zm-.83,2.32c-.2-.11-.39-.22-.59-.33-.19,.54-.37,1.08-.56,1.62,.15,.05,.31,.1,.46,.15,.23-.48,.46-.97,.69-1.45Z" />
      <path d="M468.22,359.63c4.62,.1,8.73,.64,12.7,2.02,1.02,.35,1.62,.84,1.76,1.85,.58,4.15,.1,8.08-2.61,11.61-.46,.6-.95,.84-1.75,.94-2.78,.35-5.57,.7-8.31,1.25-4.8,.96-10.07-1.87-11.09-6.28-.86-3.71-.3-7.19,2.87-10.01,.73-.65,1.5-1.06,2.52-1.11,1.44-.07,2.88-.2,3.92-.27Z" />
      <path
        style={styles.two}
        d="M450.32,404.38c-.88-3.43-1.02-6.65-.87-9.88,.03-.64,.25-1.32,.59-1.88,1.05-1.73,.88-3.22-.92-4.5,.85-1.34,2.38-1.63,3.99-2.75-2.39,5.46-1.59,10.61-1.61,15.7,0,1.08,.54,2.36-1.19,3.3Z"
      />
      <path
        style={styles.two}
        d="M463.13,378.87c-2.69,1.44-5.4,2.89-8.1,4.34-.48-2.11,.68-3.31,2.72-3.99,1.13-.38,2.27-.74,3.42-1.05,.71-.19,1.44-.26,1.96,.7Z"
      />
      <path d="M603.36,359.63c1.13,.08,2.66,.12,4.16,.35,.73,.11,1.51,.49,2.07,.95,4.23,3.49,4.03,8.68,2.33,12-1.61,3.14-6.47,5.13-10.17,4.41-2.82-.55-5.7-.86-8.54-1.34-.56-.09-1.22-.37-1.55-.76-3.04-3.58-3.27-7.72-2.74-12,.12-.95,.88-1.29,1.76-1.6,3.96-1.41,8.07-1.92,12.68-2.02Z" />
      <path
        style={styles.two}
        d="M618.85,385.68c1.18,.68,2.36,1.35,3.76,2.15-.46,.49-.75,.79-1.04,1.1-.81,.87-1.06,1.84-.4,2.86,1.12,1.75,1.18,3.66,1.04,5.58-.18,2.26-.47,4.51-.74,6.92-1.74-.52-1.54-1.61-1.52-2.57,.07-2.72,.32-5.45,.24-8.16-.08-2.56-.17-5.17-1.34-7.88Z"
      />
      <path
        style={styles.two}
        d="M616.46,383.39c-1.22-.79-2.45-1.7-3.8-2.42-1.35-.72-2.82-1.26-4.36-1.93,1.42-1.18,1.83-1.15,4.95-.05,2.18,.77,3.93,1.78,3.21,4.4Z"
      />
      <path
        style={styles.three}
        d="M618.43,408.93c-.23,.48-.46,.97-.69,1.45-.15-.05-.31-.1-.46-.15,.19-.54,.37-1.08,.56-1.62,.2,.11,.39,.22,.59,.33Z"
      />
      <circle style={styles.one} cx="478" cy="368" r="6" />
      <circle style={styles.one} cx="594" cy="368" r="6" />
    </g>
  );
};

export default Crying;
