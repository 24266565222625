import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BiX } from "react-icons/bi";
import { RiErrorWarningFill } from "react-icons/ri";
import { searchForUser } from "../../api/search";
import useMiddleware from "../../api/useMiddleware";
import { tipUser } from "../../api/user";
import Avatar from "../../avatar/Avatar";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { currencyFormatter, numFormatter } from "../../utils/helpers";
import {
  black,
  card,
  cardLight,
  cardVeryLight,
  green,
  large,
  modalShadow,
  normal,
  secondaryText,
  small,
  text,
  transition,
} from "../../utils/themeContstants";
import CurrencyInput from "../custom/CurrencyInput";
import CustomCheckbox from "../custom/CustomCheckbox";
import RivoxCoin from "../custom/RivoxCoin";
import PrimaryButton from "../custom/PrimaryButton";
import SecondaryButton from "../custom/SecondaryButton";
import StaffSearchResultItem from "../staff/StaffSearchResultItem";
import SearchBar from "./SearchBar";
import { FaCrown } from "react-icons/fa";

const TipModal = ({ open, onClose, userToTip = null }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const isDesktop = useMediaQuery("(min-width:1025px)");

  const [query, setQuery] = useState("");
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [amount, setAmount] = useState("");
  const [tipLoading, setTipLoading] = useState(false);
  const [understands, setUnderstands] = useState(false);
  const [closeHovered, setCloseHovered] = useState(false);

  const handleClose = () => {
    setQuery("");
    setResults(null);
    setLoading(false);
    setSelectedUser(null);
    setAmount("");
    setTipLoading(false);
    setUnderstands(false);
    setCloseHovered(false);
    onClose();
  };

  const handleSearch = (searchQuery) => {
    if (
      searchQuery === "" ||
      searchQuery == null ||
      searchQuery?.length >= 21
    ) {
      setResults(null);
      return;
    }

    setSelectedUser(null);
    setResults(null);
    setLoading(true);
    setUnderstands(false);
    setAmount("");
    setCloseHovered(false);
    searchForUser(middleware, searchQuery).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setResults(res?.userArray);
      }
    });
  };

  const handleUserClick = (user) => {
    setResults(null);
    setQuery("");
    setLoading(false);
    setUnderstands(false);
    setAmount("");
    setCloseHovered(false);
    setSelectedUser(user);
  };

  const handleClearUser = () => {
    setResults(null);
    setQuery("");
    setAmount("");
    setLoading(false);
    setUnderstands(false);
    setSelectedUser(false);
    setCloseHovered(false);
  };

  const handleTip = () => {
    setTipLoading(true);
    tipUser(middleware, parseFloat(amount), selectedUser?.user_id).then(
      (res) => {
        setTipLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          updateStore({ type: SET_SUCCESSES, payload: res?.message });
          handleClose();
        }
      }
    );
  };

  useEffect(() => {
    if (open && userToTip) {
      setSelectedUser(userToTip);
    }
  }, [open, userToTip]);

  const styles = {
    container: {
      backgroundColor: "transparent",
      minWidth: isDesktop ? 700 : "97%",
      maxWidth: 700,
      alignSelf: "start",
      marginTop: isDesktop ? 35 : 20,
      boxShadow: "none",
    },
    width: {
      width: "100%",
    },
    vipWithdrawals: {
      color: "rgb(255, 255, 193)",
      textShadow: "rgb(255 93 0) 0px 0px 10px",
      fontSize: 11,
      fontWeight: 500,
      opacity: 0.8,
    },
    warning: {
      fontSize: small,
      fontWeight: 500,
      color: secondaryText,
      maxWidth: 400,
    },
    noResultsText: {
      fontSize: large,
      color: text,
      fontWeight: 600,
    },
    userContainer: {
      borderRadius: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
      padding: 2,
      width: "100%",
    },
    username: {
      fontSize: large,
      fontWeight: 800,
      color: text,
      textShadow: `1px 2px 8px ${black}`,
    },
    epic: {
      fontWeight: 500,
      color: secondaryText,
      fontSize: large,
      textShadow: `1px 2px 8px ${black}`,
    },
    value: {
      fontSize: normal,
      color: text,
      fontWeight: 500,
    },
    label: {
      fontWeight: 700,
      color: text,
      fontSize: normal,
    },
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: styles.container }}
      BackdropProps={{ style: { background: "rgba(16, 18, 27, 0.7)" } }}
    >
      <DialogTitle>
        <Grid item sx={styles.width}>
          <SearchBar
            placeholder="Search users to tip..."
            value={query}
            onChange={(val) => {
              setQuery(val);
              handleSearch(val);
            }}
            onKeyPress={() => handleSearch(query)}
            onPaste={(val) => {
              setQuery(val);
              handleSearch(val);
            }}
          />
        </Grid>
      </DialogTitle>

      <DialogContent
        sx={{
          backgroundColor: "transparent",
          marginBottom: 2,
        }}
      >
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 3 }}
        >
          {loading && (
            <Grid
              item
              alignSelf="center"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 4,
              }}
            >
              <CircularProgress size={30} sx={{ color: secondaryText }} />
            </Grid>
          )}

          {!loading &&
            query !== "" &&
            query != null &&
            (results == null || results?.length < 1) && (
              <Grid item sx={{ width: "100%", padding: 2 }}>
                <Typography sx={styles.noResultsText}>
                  No results for{" "}
                  <span style={{ fontWeight: 800 }}>{query}</span>
                </Typography>
              </Grid>
            )}

          {loading ||
          results?.length < 1 ||
          results == null ||
          query === "" ||
          query == null ? null : (
            <Grid item sx={styles.width}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap={{ xs: 1 }}
              >
                {results?.map((user, i) => (
                  <StaffSearchResultItem
                    user={user}
                    onClick={() => handleUserClick(user)}
                    key={i}
                  />
                ))}
              </Grid>
            </Grid>
          )}

          {!loading && selectedUser && (
            <Grid item sx={styles.width} alignSelf="center">
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 2 }}
              >
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    gap={{ xs: 2 }}
                  >
                    <Grid item>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="start"
                      >
                        <Grid item sx={{ marginLeft: -2 }}>
                          <Avatar
                            size={175}
                            bgColor={card}
                            avatar={selectedUser?.avatar}
                          />
                        </Grid>

                        <Grid item>
                          <Typography sx={styles.username}>
                            {selectedUser?.username}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingRight: 2,
                        transition: transition,
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                      onMouseEnter={() => setCloseHovered(true)}
                      onMouseLeave={() => setCloseHovered(false)}
                      onClick={handleClearUser}
                    >
                      <BiX
                        style={{
                          fontSize: 42,
                          color: closeHovered ? text : secondaryText,
                          transition: transition,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.userContainer}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 2 }}
                    sx={{
                      padding: 2,
                      backgroundColor: cardLight,
                      borderRadius: 1,
                    }}
                  >
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: normal,
                              color: secondaryText,
                              fontWeight: 600,
                            }}
                          >
                            Your current balance
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            justifyContent="start"
                            alignItems="center"
                            gap={{ xs: 0.5 }}
                          >
                            <RivoxCoin size={32} />

                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: 32,
                                  color: text,
                                  fontWeight: 700,
                                }}
                              >
                                {numFormatter().format(
                                  store?.user?.funds?.balance
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                        gap={{ xs: 1 }}
                      >
                        <Grid item sx={styles.width}>
                          <CurrencyInput
                            value={amount}
                            onChange={(value) => setAmount(value)}
                            autoFocus={true}
                          />
                        </Grid>

                        {amount !== "" &&
                          amount != null &&
                          (store?.user?.premium_info?.active
                            ? amount < 0.1
                            : amount < 1) && (
                            <Grid item sx={styles.width}>
                              <Grid
                                container
                                justifyContent="start"
                                alignItems="center"
                                gap={{ xs: 1 }}
                              >
                                <Grid item>
                                  <RiErrorWarningFill
                                    style={{
                                      color: secondaryText,
                                      fontSize: 14,
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontSize: small,
                                      fontWeight: 500,
                                      color: secondaryText,
                                    }}
                                  >
                                    Minimum tipping amount is{" "}
                                    {store?.user?.premium_info?.active
                                      ? "$0.10"
                                      : "$1.00"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Divider
                        sx={{ width: "100%", backgroundColor: cardVeryLight }}
                      />
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                        gap={{ xs: 1 }}
                      >
                        <Grid item sx={styles.width}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography sx={styles.value}>
                                {store?.user?.premium_info?.active
                                  ? "Fees (0%)"
                                  : "Fees (5%)"}
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography sx={styles.label}>
                                {store?.user?.premium_info?.active
                                  ? currencyFormatter().format(amount)
                                  : currencyFormatter().format(amount * 0.05)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        {!store?.user?.premium_info?.active && (
                          <Grid item>
                            <Typography sx={styles.vipWithdrawals}>
                              <FaCrown
                                style={{
                                  color: secondaryText,
                                  fontSize: 11,
                                }}
                              />{" "}
                              {"VIP members get 0% fees."}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                        gap={{ xs: 1 }}
                      >
                        <Grid item sx={styles.width}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography sx={styles.value}>
                                Sending{" "}
                                <span style={{ fontWeight: 800 }}>
                                  {selectedUser?.username}
                                </span>
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Grid
                                container
                                justifyContent="start"
                                alignItems="center"
                                gap={{ xs: 0.5 }}
                              >
                                <Grid
                                  item
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <RivoxCoin size={20} />
                                </Grid>

                                <Grid item>
                                  <Typography sx={styles.label}>
                                    {store?.user?.premium_info?.active
                                      ? numFormatter().format(amount)
                                      : numFormatter().format(
                                          amount - amount * 0.05
                                        )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                        gap={{ xs: 1 }}
                      >
                        <Grid item sx={styles.width}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography sx={styles.value}>
                                Your balance after
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Grid
                                container
                                justifyContent="start"
                                alignItems="center"
                                gap={{ xs: 0.5 }}
                              >
                                <Grid
                                  item
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <RivoxCoin size={20} />
                                </Grid>

                                <Grid item>
                                  <Typography sx={styles.label}>
                                    {numFormatter().format(
                                      store?.user?.funds?.balance - amount
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Divider
                        sx={{ width: "100%", backgroundColor: cardVeryLight }}
                      />
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 2 }}
                      >
                        <Grid>
                          <CustomCheckbox
                            checked={understands}
                            onChange={(value) => setUnderstands(value)}
                            color={green}
                          />
                        </Grid>

                        <Grid>
                          <Typography
                            sx={{
                              fontSize: small,
                              color: secondaryText,
                              fontWeight: 600,
                              maxWidth: 400,
                            }}
                          >
                            By checking this box you understand that this tip is
                            irreversible and will come out of your balance.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      {selectedUser && (
        <DialogActions>
          <Grid
            item
            sx={{
              width: "100%",
              paddingLeft: 2,
              paddingRight: 2,
              paddingBottom: 2,
            }}
          >
            <Grid
              container
              justifyContent="end"
              alignItems="center"
              gap={{ xs: 2 }}
            >
              <Grid item>
                {<SecondaryButton label="Cancel" onClick={handleClose} />}
              </Grid>

              <Grid item>
                {
                  <PrimaryButton
                    type="green"
                    label="Send Tip"
                    loading={tipLoading}
                    disabled={
                      amount === "" ||
                      amount == null ||
                      (store?.user?.premium_info?.active
                        ? amount < 0.1
                        : amount < 1) ||
                      amount > store?.user?.funds?.balance ||
                      !selectedUser ||
                      !understands
                    }
                    onClick={handleTip}
                  />
                }
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default TipModal;
