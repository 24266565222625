const DealWithIt = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
  };

  return (
    <g>
      <rect style={styles.two} x="427" y="339" width="238" height="14" />
      <rect style={styles.two} x="348" y="339" width="84" height="19" />
      <rect style={styles.two} x="415" y="351" width="114" height="14" />
      <rect style={styles.two} x="427" y="361" width="92" height="17" />
      <rect style={styles.two} x="438" y="375" width="69" height="20" />
      <rect style={styles.two} x="553" y="351" width="91" height="14" />
      <rect style={styles.two} x="561" y="361" width="70" height="19" />
      <rect style={styles.two} x="573" y="375" width="48" height="20" />
      <rect style={styles.two} x="341" y="352" width="20" height="8" />
      <rect style={styles.one} x="484" y="352" width="11" height="14" />
      <rect style={styles.one} x="507" y="352" width="11" height="14" />
      <rect style={styles.one} x="620" y="352" width="11" height="14" />
      <rect style={styles.one} x="609" y="366" width="11" height="14" />
      <rect style={styles.one} x="473" y="366" width="11" height="14" />
      <rect style={styles.one} x="496" y="366" width="11" height="14" />
    </g>
  );
};

export default DealWithIt;
