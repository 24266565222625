import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BiError, BiX } from "react-icons/bi";
import { FaRegCheckCircle } from "react-icons/fa";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { AlertTypeEnum } from "../../utils/enums";
import {
  card,
  cardVeryLight,
  green,
  menuShadow,
  red,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import "../styles/Alert.css";

const AlertMessage = () => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [timer, setTimer] = useState(null);
  const [hovered, setHovered] = useState(false);

  const clearValues = () => {
    setMessage(null);
    setType(null);
    setTimer(null);
    setHovered(false);
    setOpen(false);
  };

  useEffect(() => {
    if (store.errors) {
      setMessage(store.errors);
      setOpen(true);
      setType(AlertTypeEnum.ERROR);
      return;
    }

    if (store.successes) {
      setMessage(store.successes);
      setOpen(true);
      setType(AlertTypeEnum.SUCCESS);
      return;
    }

    clearValues();
  }, [store.errors, store.successes]);

  useEffect(() => {
    if (open && type === AlertTypeEnum.SUCCESS && timer == null) {
      setTimer(5000);
      document.getElementById("alert-progress").classList?.add("active");
      return;
    }

    if (open && type === AlertTypeEnum.ERROR && timer == null) {
      setTimer(5000);
      document.getElementById("alert-progress").classList?.add("active");
      return;
    }
  }, [open, timer, type]);

  useEffect(() => {
    if (timer === 0) {
      setTimer(null);
      updateStore({
        type: type === AlertTypeEnum.SUCCESS ? SET_SUCCESSES : SET_ERRORS,
        payload: null,
      });
      clearValues();
    }
  }, [timer, type, updateStore]);

  useEffect(() => {
    if (timer !== 0 && timer != null) {
      const timerId = setTimeout(() => {
        setTimer(timer - 1000);
      }, 1000);
      return () => clearTimeout(timerId);
    }
  });

  const styles = {
    container: {
      borderRadius: 1,
      backgroundColor: card,
      position: "fixed",
      boxShadow: menuShadow,
      bottom: 100,
      left: 20,
      minWidth: 300,
      maxWidth: 300,
      minHeight: 60,
      zIndex: 9999999999999,
    },
    progress: {
      width: "100%",
      height: 4,
      backgroundColor: cardVeryLight,
      borderRadius: 1,
    },
    label: {
      fontSize: 16,
      fontWeight: 800,
      color: text,
    },
    message: {
      fontSize: 14,
      fontWeight: 500,
      color: secondaryText,
    },
  };

  return (
    open && (
      <Grid sx={styles.container}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          wrap="nowrap"
        >
          <Grid item sx={{ width: "100%", padding: 2 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              gap={{ xs: 2 }}
              wrap="nowrap"
            >
              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 2 }}
                  wrap="nowrap"
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {type === AlertTypeEnum.SUCCESS ? (
                      <FaRegCheckCircle
                        style={{ color: green, fontSize: 20 }}
                      />
                    ) : (
                      <BiError style={{ color: red, fontSize: 20 }} />
                    )}
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={styles.label}>
                          {type === AlertTypeEnum.SUCCESS ? "Success" : "Error"}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.message}>{message}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sx={{ "&:hover": { cursor: "pointer" }, marginTop: 0.5 }}
                onClick={() => {
                  updateStore({
                    type:
                      type === AlertTypeEnum.SUCCESS
                        ? SET_SUCCESSES
                        : SET_ERRORS,
                    payload: null,
                  });
                  clearValues();
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <BiX
                  style={{
                    color: hovered ? text : secondaryText,
                    fontSize: 22,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item id="alert-progress" sx={styles.progress}></Grid>
        </Grid>
      </Grid>
    )
  );
};

export default AlertMessage;
