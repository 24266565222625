import { Grid } from "@mui/material";
import Logo from "../../assets/logo.svg";

const RivoxLogo = ({ size }) => {
  return (
    <Grid
      item
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <img draggable={false} height={size} width={size} src={Logo} />
    </Grid>
  );
};

export default RivoxLogo;
