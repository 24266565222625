import { Grid, Skeleton } from "@mui/material";
import { cardVeryLight } from "../../utils/themeContstants";

const ShopItemSimpleGlimmer = ({ numItems, size }) => {
  const array = Array.apply(null, { length: numItems }).map(
    Number.call,
    Number
  );

  return (
    <Grid item>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        gap={{ xs: 1 }}
      >
        {array.map((_, i) => (
          <Grid item key={i}>
            <Skeleton
              variant="rectangular"
              width={size}
              height={size}
              sx={{ bgcolor: cardVeryLight, borderRadius: 2 }}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ShopItemSimpleGlimmer;
