import "../styles/Gojo.css";

const Gojo = () => {
  return (
    <g>
      <path
        class="gojo-2"
        d="m371.38,358.72c-.12-1.27-.14-3.34.62-5.72.56-1.76,1.36-3.1,2-4,.8-2.44,1.59-4.88,2.39-7.32v-23.98c30.92-12.74,83.82-29.89,151.11-29.2,55.41.57,100,13.04,128.98,23.68,3.1,4.27,6.2,8.55,9.3,12.82v50.09c-11.3,6.41-25.27,13.18-41.78,18.91-12.91,4.48-24.91,7.48-35.5,9.5-17.39-8.55-34.78-17.1-52.17-25.65h-32.9l-40.93,19.65c-4.78-1.05-12.46-2.98-21.5-6.5-19.11-7.43-26.45-14.97-47-24-5.06-2.22-12.76-5.34-22.62-8.28Z"
      />
      <path
        class="gojo-1"
        d="m518.42,294.22c-23.36.12-55.06,3.02-90.42,14.78-18.69,6.22-34.72,13.69-48,21v11.43c9.3-1.47,20.06-2.49,32-2.43,6.78.03,13.14.41,19,1-14.33,4.67-28.67,9.33-43,14l69,25-85.62-20.28,2.62-9.72,2.39-7.32v-23.98c17.1-7.25,39.57-14.95,66.61-19.7,29.78-5.23,55.73-5.23,75.42-3.78Z"
      />
      <path
        class="gojo-1"
        d="m414,332l64,6,25,10-18-22,18,2.39c-4.45-2.82-11.58-6.54-21-8.39-6.27-1.23-11.78-1.29-16-1-17.33,4.33-34.67,8.67-52,13Z"
      />
      <polygon class="gojo-1" points="436 359 492 361 477 369 436 359" />
      <path
        class="gojo-1"
        d="m547,365c9.15,3.82,21.69,7.96,37,10,18.49,2.46,34.14.93,45-1-14.33,4-28.67,8-43,12l-39-21Z"
      />
      <polygon
        class="gojo-1"
        points="665.78 348 657.09 345.99 625.94 356.83 657.87 356.83 665.78 356.83 665.78 348"
      />
      <path
        class="gojo-1"
        d="m542,354l18.12-29.78c11.96-.3,26.14.27,41.88,2.78,10.55,1.68,20.08,3.96,28.48,6.43-19.49,1.19-38.99,2.38-58.48,3.57l-9,8,33.57.99-36.44,10.84-18.12-2.83Z"
      />
      <path
        class="gojo-1"
        d="m594,315l71.78,21.2v-11.2c-12.17-2.71-25.47-5.14-39.78-7-11.24-1.46-21.93-2.42-32-3Z"
      />
      <path
        class="gojo-3"
        d="m371.38,358.72c-.12-1.27-.14-3.34.62-5.72.56-1.76,1.36-3.1,2-4,.8-2.44,1.59-4.88,2.39-7.32v-23.98c30.92-12.74,83.82-29.89,151.11-29.2,55.41.57,100,13.04,128.98,23.68,3.1,4.27,6.2,8.55,9.3,12.82v50.09c-11.3,6.41-25.27,13.18-41.78,18.91-12.91,4.48-24.91,7.48-35.5,9.5-17.39-8.55-34.78-17.1-52.17-25.65h-32.9l-40.93,19.65c-4.78-1.05-12.46-2.98-21.5-6.5-19.11-7.43-26.45-14.97-47-24-5.06-2.22-12.76-5.34-22.62-8.28Z"
      />
    </g>
  );
};

export default Gojo;
