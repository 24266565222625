import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { FaCoins } from "react-icons/fa";
import { getPendingWithdrawals } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { PENDING_WITHDRAWAL_LIMIT } from "../../utils/limits";
import {
  card,
  green,
  modalShadow,
  normal,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import usePagination from "../../utils/usePagination";
import NotSelected from "../custom/NotSelected";
import DisputeGlimmer from "../glimmers/DisputeGlimmer";
import PendingWithdrawalItem from "./PendingWithdrawalItem";
import { currencyFormatter } from "../../utils/helpers";

const PendingWithdrawals = () => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [withdrawals, setWithdrawals] = useState(null);
  const [withdrawalTotal, setWithdrawalTotal] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const handleGetPendingWithdrawals = () => {
    getPendingWithdrawals(middleware, skip).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.withdrawals?.length >= PENDING_WITHDRAWAL_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setWithdrawalTotal(res?.total_withdrawal_info);
        setWithdrawals(
          withdrawals ? [...withdrawals, ...res?.withdrawals] : res?.withdrawals
        );
      }
    });
  };

  usePagination(bottom, () => setSkip(withdrawals?.length));

  useEffect(() => {
    if (withdrawals == null) {
      handleGetPendingWithdrawals();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetPendingWithdrawals();
    }
  }, [skip]);

  const styles = {
    width: {
      width: "100%",
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    totalLabel: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 600,
    },
    totalValue: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    container: {
      borderRadius: 2,
      padding: 2,
      backgroundColor: card,
      boxShadow: modalShadow,
      flexGrow: 1,
    },
  };

  return (
    <>
      <Grid item>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.icon}>
            <FaCoins style={{ fontSize: 22, color: text }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.title}>PENDING WITHDRAWALS</Typography>
          </Grid>
        </Grid>
      </Grid>

      {loading && <DisputeGlimmer numItems={3} />}

      {!loading && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            {(withdrawals?.length < 1 || !withdrawals) && (
              <Grid item alignSelf="center">
                <NotSelected label="NO WITHDRAWALS" />
              </Grid>
            )}

            {withdrawals && withdrawals?.length > 0 && (
              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="row"
                  alignItems="start"
                  justifyContent="left"
                  gap={{ xs: 1 }}
                >
                  <Grid item sx={styles.container}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={(styles.totalLabel, { color: green })}>
                          Pending Profit
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={styles.totalValue}>
                          {currencyFormatter().format(
                            withdrawalTotal[0]?.sum_withdrawals_before_tax
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={styles.container}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={(styles.totalLabel, { color: green })}>
                          Total Owed
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={styles.totalValue}>
                          {currencyFormatter().format(
                            withdrawalTotal[0]?.sum_withdrawals
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={styles.container}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={(styles.totalLabel, { color: green })}>
                          # Withdrawals
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={styles.totalValue}>
                          {withdrawalTotal[0]?.sum}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {withdrawals?.map((withdrawal, i) => (
                    <PendingWithdrawalItem
                      withdrawal={withdrawal}
                      key={i}
                      setWithdrawals={setWithdrawals}
                      withdrawals={withdrawals}
                    />
                  ))}
                  {hasMore && (
                    <div style={{ width: "100%" }} ref={bottom}>
                      <DisputeGlimmer numItems={1} />
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PendingWithdrawals;
