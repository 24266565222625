import { Grid } from "@mui/material";
import { MatchStateEnum } from "../../utils/enums";
import ScrollableContainer from "../custom/ScrollableContainer";
import StepperItem from "../custom/StepperItem";

const MatchPageState = ({ match }) => {
  const styles = {
    width: {
      width: "100%",
      zIndex: 10,
    },
  };

  const getIsStateFinished = (state) => {
    if (
      match?.state === MatchStateEnum.CANCEL ||
      match?.state === MatchStateEnum.COMPLETED
    ) {
      return true;
    }

    switch (state) {
      case MatchStateEnum.WAITING:
        if (match?.state !== MatchStateEnum.WAITING) return true;

        return false;
      case MatchStateEnum.READY:
        if (match?.readied_users?.readied != null) {
          return !Object.values(match?.readied_users?.readied)?.includes(false);
        }
        return false;
      case MatchStateEnum.PLAYING:
        return (
          match?.submits?.creating_submit != null ||
          match?.submits?.joining_submit != null
        );
      case MatchStateEnum.SUBMIT:
        return (
          match?.state === MatchStateEnum.DISPUTE ||
          match?.state === MatchStateEnum.COMPLETED
        );
      case MatchStateEnum.DISPUTE:
        return match?.winners?.length > 0;
      default:
        return false;
    }
  };

  return (
    <Grid item sx={styles.width}>
      <ScrollableContainer>
        <StepperItem
          title="Waiting"
          description="Waiting for an opponent to accept this match"
          current={match?.state === MatchStateEnum.WAITING}
          finished={getIsStateFinished(MatchStateEnum.WAITING)}
          isLast={false}
          minWidth={250}
        />
        <StepperItem
          title="Ready Up"
          description="All players must ready up to start competing"
          current={match?.state === MatchStateEnum.READY}
          finished={getIsStateFinished(MatchStateEnum.READY)}
          isLast={false}
          minWidth={250}
        />
        <StepperItem
          title="Playing"
          description="Start competing now"
          current={match?.state === MatchStateEnum.PLAYING}
          finished={getIsStateFinished(MatchStateEnum.PLAYING)}
          isLast={false}
          minWidth={250}
        />
        <StepperItem
          title="Submitting"
          description="Submit your results to conclude the match"
          current={match?.state === MatchStateEnum.SUBMIT}
          finished={getIsStateFinished(MatchStateEnum.SUBMIT)}
          isLast={false}
          minWidth={250}
        />
        {match?.state === MatchStateEnum.DISPUTE && (
          <StepperItem
            title="Disputed"
            description="Both teams have submitted a win"
            current={match?.state === MatchStateEnum.DISPUTE}
            finished={getIsStateFinished(MatchStateEnum.DISPUTE)}
            isLast={false}
            minWidth={250}
          />
        )}
        {match?.state !== MatchStateEnum.CANCEL && (
          <StepperItem
            title="Completed"
            description="This match has concluded"
            current={match?.state === MatchStateEnum.COMPLETED}
            finished={getIsStateFinished(MatchStateEnum.COMPLETED)}
            isLast={true}
            minWidth={250}
          />
        )}
        {match?.state === MatchStateEnum.CANCEL && (
          <StepperItem
            title="Canceled"
            description="This match has been canceled"
            current={match?.state === MatchStateEnum.CANCEL}
            finished={getIsStateFinished(MatchStateEnum.CANCEL)}
            isLast={true}
            minWidth={250}
          />
        )}
      </ScrollableContainer>
    </Grid>
  );
};

export default MatchPageState;
