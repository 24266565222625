import { Grid, Typography, useMediaQuery } from "@mui/material";
import { getGameModeStringFromArray } from "../../utils/helpers";
import { normal, secondaryText, text } from "../../utils/themeContstants";

const WarzoneRules = ({ token }) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const styles = {
    label: {
      fontSize: 11,
      fontWeight: 600,
      color: secondaryText,
    },
    value: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    nonBoldValue: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="start"
      justifyContent="center"
      gap={{ xs: 2 }}
      sx={{ maxWidth: isDesktop ? 275 : "100%" }}
    >
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>MODE</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              {token?.team_size === 2 ? (
                <>
                  {token?.game_mode_options?.options[0] === "wz_resurgence"
                    ? "Warzone Resurgence Squads"
                    : "Warzone Squads"}
                </>
              ) : (
                <>
                  {token?.game_mode_options?.options[0] === "wz_resurgence"
                    ? "Warzone Resurgence Duos"
                    : "Warzone Duos"}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>PC PLAYER REQUIREMENTS</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              <li>
                Any player that is forced to stream a handcam on another
                platform is also forced to stream a handcam on Rivox
              </li>
              {token?.extra_options?.must_stream && (
                <>
                  <li>All PC Players must stream</li>

                  <li>
                    When streaming, you must have VODs enabled so a staff member
                    may review the stream if there is an issue
                  </li>
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>HOW TO WIN</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              {token?.game_mode_options?.options[0] === "wz_survival" ? (
                <>
                  <li>
                    To "win a map" the team that survives the longest in each
                    map wins
                  </li>
                </>
              ) : (
                <>
                  <li>
                    To "win a map" you must have the most kills in that map
                  </li>
                  {token?.best_of === 1 && (
                    <li>
                      The team that has the most kills after the first map will
                      win the match
                    </li>
                  )}

                  {token?.best_of === 3 && (
                    <li>The team that wins two maps wins the match</li>
                  )}

                  <li>
                    The only kills that count in a kill race are "Operator
                    Kills"
                  </li>
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>TIE BREAKERS</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              {token?.game_mode_options?.options[0] === "wz_survival" ? (
                <>
                  <li>
                    If there is a tie in survival, the team with the most kills
                    will win the map
                  </li>
                </>
              ) : (
                <>
                  <li>
                    If you and your opponent tie in kills, you will replay the
                    map until a winner is decided
                  </li>
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>RESTRICTED ITEMS</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              <li>Birdseye has been banned from all Warzone matches</li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>JAILBREAKS</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              <li>
                Once all members of a team are dead (and no members on the team
                have a gulag anymore) that is the final score of the match.
              </li>

              <li>
                If both members of a team are dead and a jailbreak occurs, you
                must immediately down yourself from hitting the ground on
                respawn
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>
              GULAG & RE-DEPLOY TOKENS/PACKS
            </Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              <li>
                Gulag tokens/packs & re-deploy tokens/packs are not allowed. If
                used, you immediately forfeit the match to your opponent.
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>KILLS AFTER DYING</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              <li>
                30 seconds after the last player on the team has been fully
                eliminated (not knocked down), that is the final score of the
                match
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>MISC RULES & GRIEFING</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              <li>
                If you pull your opponents out of the match within 30 seconds of
                them knocking an enemy you forfeit the match.
              </li>

              <li>
                If you break any of the Warzone rules on the rules list, you
                forfeit the match
              </li>

              <li>
                Do not excessively ping to keep your opponent from hearing
                teammate comms or game sound
              </li>

              <li>
                If you enter an opponents vehicle, anything that they do is fair
                game (driving you into zone, driving you out of bounds, jumping
                out of the vehicle, etc)
              </li>

              <li>
                Giving up your opponents location to other enemies in the
                Warzone match is NOT allowed
              </li>

              <li>
                Sabotaging your opponents from reviving their teammate is NOT
                allowed
              </li>

              <li>
                A staff member may determine if an action is considered
                "griefing" or "sabotaging"
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WarzoneRules;
