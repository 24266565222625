const Worry = () => {
  return (
    <g>
      <path d="M645.96,362.96c-1.58-1.58-3.32-3.01-4.71-4.77-8.14-10.39-18.64-16.54-30.71-19.55-7.43-1.85-15.02-2.96-22.56-4.31-3.52-.63-6.52-2.27-9.1-4.89-1.57-1.59-2.35-3.56-2.87-5.79,7.46,1.92,14.65,3.62,21.77,5.64,6.33,1.8,12.68,3.62,18.81,6.05,8.5,3.37,16.02,8.75,22.87,15.11,1.95,1.81,3.46,4.19,5.05,6.4,1.2,1.67,1.61,3.71,1.43,6.11Z" />
      <path d="M496.14,316.29c-.64,2.58-1.53,4.51-3.07,6.13-3.1,3.26-6.95,4.49-11.12,5.22-7.95,1.4-16,2.25-23.76,4.8-10.76,3.54-20.2,9.31-27.51,18.77-1.31,1.69-3,3.04-4.88,4.9-.06-2.93,.63-4.98,1.9-6.67,1.9-2.52,3.78-5.18,6.09-7.22,6.72-5.94,13.99-11.05,22.21-14.26,4.73-1.85,9.66-3.07,14.48-4.64,6.52-2.13,13.11-3.88,19.84-5.11,1.8-.33,3.52-1.15,5.81-1.93Z" />
    </g>
  );
};

export default Worry;
