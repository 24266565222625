const SquidwardPierced = () => {
  const styles = {
    one: {
      fill: "#f9d438",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      fill: "#f9e698",
    },
  };

  return (
    <g>
      <g id="droopy">
        <path d="M517.1,381.54c1.77,1.16,2.99,2.8,3.94,4.69,2.55,5.03,3.06,10.32,1.23,15.56-1.53,4.37-.89,8.29,.91,12.31,2.38,5.3,5.88,9.61,10.69,12.93,5.68,3.91,11.16,1.83,14.75-1.37,3.71-3.3,5.65-7.57,6.69-12.27,2.66-11.98,.93-23.23-5.87-33.58-1.81-2.75-4.06-5.08-6.79-6.97-3.4-2.35-7.1-3.48-11.25-3.05-.52,.05-1.04,.07-1.74,.11,.49-1.13,1.4-1.16,2.19-1.3,3.5-.62,6.83-.05,10.01,1.47,5.39,2.58,9.28,6.76,12.31,11.75,4.1,6.75,6.28,14.09,6.75,21.99,.46,7.76-1.11,14.94-5.53,21.39-1.91,2.78-4.26,5.14-7.44,6.55-4.63,2.05-9.13,1.44-13.5-.77-6.13-3.11-10.88-7.65-14.34-13.6-2.97-5.12-3.77-10.42-2.14-16.09,.34-1.19,.61-2.4,.95-3.59,1.36-4.86,.69-9.51-1.5-14-.29-.6-.51-1.23-.77-1.85,.15-.1,.29-.2,.44-.3Z" />
      </g>
      <g id="Nose_Ring" data-name="Nose Ring">
        <g>
          <circle style={styles.one} cx="532" cy="414" r="4.5" />
          <path
            style={styles.two}
            d="M530.36,410.73c.12-.09,1.4-.94,2.86-.41,1.17,.42,1.98,1.56,2.05,2.86-.44-.53-1.37-1.49-2.86-2.05-.79-.29-1.51-.39-2.05-.41Z"
          />
        </g>
      </g>
    </g>
  );
};

export default SquidwardPierced;
