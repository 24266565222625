import { Divider, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getSensitiveMatch } from "../../api/matches";
import useMiddleware from "../../api/useMiddleware";
import { snipeUsers } from "../../api/user";
import Avatar from "../../avatar/Avatar";
import { TOKEN_PAGE_ID } from "../../sockets/socketConstants";
import useMatch from "../../sockets/useMatch";
import {
  SET_ERRORS,
  SET_JOIN_MATCH_ID,
  SET_SEARCH_USER,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { getMatchTitle } from "../../utils/helpers";
import {
  card,
  cardDark,
  cardVeryLight,
  doubtedBlue,
  normal,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from "../../utils/themeContstants";
import CustomCheckbox from "../custom/CustomCheckbox";
import CustomModal from "../custom/CustomModal";
import SecondaryButton from "../custom/SecondaryButton";

const SnipeModal = ({ open, onClose }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const { matchToRemove } = useMatch(TOKEN_PAGE_ID);

  const [fetchLoading, setFetchLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [understands, setUnderstands] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [usernameHovered, setUsernameHovered] = useState(false);
  const [opponents, setOpponents] = useState(null);
  const [teamArray, setTeamArray] = useState(null);

  const handleOpenProfile = (user_id) => {
    updateStore({ type: SET_SEARCH_USER, payload: user_id });
  };

  const handleClose = () => {
    setUnderstands(false);
    setShouldShow(false);
    setLoading(false);
    setHovered(false);
    setUsernameHovered(false);
    setOpponents(null);
    setToken(null);
    setTeamArray(null);
    onClose();
  };

  const handleFetchMatch = () => {
    getSensitiveMatch(middleware, store?.snipeMatchId).then((res) => {
      setFetchLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
        handleClose();
      } else {
        setToken(res?.match);
      }
    });
  };

  const handleShowOpponents = () => {
    setLoading(true);
    snipeUsers(middleware, token?._id).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        setShouldShow(true);
        setUnderstands(false);
        setOpponents(res?.users);
      }
    });
  };

  const handleJoinMatch = () => {
    handleClose();
    updateStore({ type: SET_JOIN_MATCH_ID, payload: token?._id });
  };

  useEffect(() => {
    if (matchToRemove && open) {
      if (matchToRemove?._id === token?._id) {
        handleClose();
      }
    }
  }, [token, matchToRemove]);

  useEffect(() => {
    if (!open) {
      return setFetchLoading(true);
    }

    if (token == null && store?.snipeMatchId && open) {
      handleFetchMatch();
    }
  }, [open, store?.snipeMatchId, token]);

  useEffect(() => {
    if (token) {
      if (teamArray == null) {
        setTeamArray(
          Array.apply(null, {
            length: token?.team_size,
          }).map(Number.call, Number)
        );
      }
    }
  }, [token]);

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    width: {
      width: "100%",
    },
    username: {
      fontSize: normal,
      transition: transition,
      color: text,
      fontWeight: 700,
    },
    epic: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 600,
    },
  };

  return (
    <CustomModal
      title={
        token
          ? `View Opponents in ${getMatchTitle(
              token?.game_match_type,
              token?.team_size,
              token?.game_map_type,
              token?.extra_options,
              token?.game_mode_options
            )}`
          : null
      }
      open={open}
      onClose={handleClose}
      primaryButton={
        shouldShow ? (
          <SecondaryButton
            label="Accept Match"
            bg={doubtedBlue}
            onClick={handleJoinMatch}
          />
        ) : (
          <SecondaryButton
            label="View Opponents"
            bg={doubtedBlue}
            onClick={handleShowOpponents}
            loading={loading}
            disabled={
              !understands ||
              store?.user?.premium_info?.snipes < 1 ||
              shouldShow
            }
          />
        )
      }
      titleSize="large"
      secondaryButton={<SecondaryButton label="Cancel" onClick={handleClose} />}
      loading={fetchLoading}
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: normal,
                  color: secondaryText,
                  fontWeight: 600,
                }}
              >
                "View Opponents" Remaining
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={{ fontSize: 32, color: text, fontWeight: 700 }}>
                {store?.user?.premium_info?.snipes}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <Divider sx={{ width: "100%", backgroundColor: cardVeryLight }} />
        </Grid>

        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: normal,
                  color: secondaryText,
                  fontWeight: 600,
                }}
              >
                Opponents
              </Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: shouldShow ? 4 : 1 }}
              >
                {!shouldShow && (
                  <>
                    {teamArray?.map((_, i) => (
                      <Grid item sx={{ marginLeft: -2, marginTop: -2 }} key={i}>
                        <Avatar size={115} avatar={null} bgColor={cardDark} />
                      </Grid>
                    ))}
                  </>
                )}

                {shouldShow && opponents && (
                  <>
                    {opponents?.map((user, i) => (
                      <Grid item key={i}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                        >
                          <Grid
                            item
                            onMouseEnter={() => setHovered(user?._id)}
                            onMouseLeave={() => setHovered(null)}
                            onClick={() => handleOpenProfile(user?._id)}
                            sx={{ marginLeft: -2 }}
                          >
                            <Avatar
                              size={115}
                              avatar={user?.new_avatar}
                              bgColor={hovered === user?._id ? card : cardDark}
                              sx={{
                                cursor:
                                  hovered === user?._id ? "pointer" : "default",
                                transition: transition,
                              }}
                            />
                          </Grid>

                          <Grid item>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              gap={{ xs: 1 }}
                            >
                              <Grid
                                item
                                onMouseEnter={() =>
                                  setUsernameHovered(user?._id)
                                }
                                onMouseLeave={() => setUsernameHovered(null)}
                                onClick={() => handleOpenProfile(user?._id)}
                              >
                                <Typography
                                  sx={{
                                    ...styles.username,
                                    cursor:
                                      usernameHovered === user?._id
                                        ? "pointer"
                                        : "default",
                                    color:
                                      usernameHovered === user?._id
                                        ? yellow
                                        : user?.premium_info?.active
                                        ? "rgb(255, 255, 193)"
                                        : text,
                                    textShadow: user?.premium_info?.active
                                      ? "rgb(255 93 0) 0px 0px 10px"
                                      : null,
                                  }}
                                >
                                  {user?._id === store?.user?._id
                                    ? "Me"
                                    : user?.username}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {!shouldShow && (
          <Grid item sx={styles.width}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              gap={{ xs: 2 }}
            >
              <Grid>
                <CustomCheckbox
                  checked={understands}
                  onChange={(value) => setUnderstands(value)}
                  color={doubtedBlue}
                  disabled={loading}
                  checkColor={text}
                />
              </Grid>

              <Grid>
                <Typography
                  sx={{
                    fontSize: small,
                    color: secondaryText,
                    fontWeight: 600,
                    maxWidth: 400,
                  }}
                >
                  By checking this box you understand that you will only have{" "}
                  {store?.user?.premium_info?.snipes - 1}{" "}
                  <b style={{ color: text }}>"View Opponents"</b> left after you
                  view these opponents
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </CustomModal>
  );
};

export default SnipeModal;
