import { Grid } from "@mui/material";
import ggCoin from "../../assets/doubted-coin.svg";
import ggCoinBlue from "../../assets/doubted-coin-blue.svg";
import ggCoinOrange from "../../assets/doubted-coin-orange.svg";
import ggCoinGold from "../../assets/doubted-coin-yellow.svg";
import ggCoinPurple from "../../assets/doubted-coin-purple.svg";
import dggCoin from "../../assets/gg_coin.svg";

const DoubtedCoin = ({ size, type }) => {
  const getCoinType = () => {
    switch (type) {
      case "blue":
        return ggCoinBlue;
      case "orange":
        return ggCoinOrange;
      case "gold":
        return ggCoinGold;
      case "purple":
        return ggCoinPurple;
      default:
        return ggCoin;
    }
  };
  return (
    <Grid
      item
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <img draggable={false} height={size} width={size} src={dggCoin} />
    </Grid>
  );
};

export default DoubtedCoin;
