import { Grid, Typography } from "@mui/material";
import { MdOutlineTimer } from "react-icons/md";
import { MatchStateEnum } from "../../utils/enums";
import {
  cardVeryLight,
  doubtedOrange,
  normal,
  offWhite,
  secondaryText,
  text,
  yellow,
} from "../../utils/themeContstants";
import Countdown from "react-countdown";
import { FaCrown } from "react-icons/fa";

const MatchTimer = ({ match }) => {
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return "Expired";
    } else {
      return (
        <span
          style={{
            color: minutes === 0 && seconds > 0 ? doubtedOrange : text,
          }}
        >
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </span>
      );
    }
  };

  const getTimerLabel = () => {
    switch (match?.state) {
      case MatchStateEnum.WAITING:
        return "Expires in";
      case MatchStateEnum.READY:
        return "Cancels in";
      case MatchStateEnum.SUBMIT:
        return "Submit results";
      default:
        return null;
    }
  };

  const styles = {
    timerLabel: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
    },
    vipWithdrawals: {
      color: "rgb(255, 255, 193)",
      textShadow: "rgb(255 93 0) 0px 0px 10px",
      fontSize: 12,
      fontWeight: 500,
      opacity: 0.8,
    },
    timerValue: {
      fontSize: 28,
      fontWeight: 800,
      color: text,
    },
  };

  return (
    <Grid
      item
      sx={{
        width: "100%",
        padding: 2,
        borderRadius: 2,
        border: `1px solid ${cardVeryLight}`,
      }}
    >
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        gap={{ xs: 1 }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MdOutlineTimer
            style={{
              fontSize: 60,
              color: offWhite,
            }}
          />
        </Grid>

        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={styles.timerLabel}>{getTimerLabel()}</Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.timerValue}>
                <Countdown
                  date={new Date(match?.expire_time)}
                  renderer={renderer}
                />
              </Typography>
            </Grid>

            {!match?.premium_submit &&
              match.state === MatchStateEnum.SUBMIT && (
                <Grid item>
                  <Typography sx={styles.vipWithdrawals}>
                    VIP timers are 3 minutes.
                  </Typography>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MatchTimer;
