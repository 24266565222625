const Tech = ({ color }) => {
  const styles = {
    one: {
      fill: "#6a6a6a",
    },
    two: {
      fill: "#323232",
    },
    three: {
      fill: color,
    },
    four: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="m455,548c-4.41-1.65-8.23-2.51-11-3-5.84-1.02-12.85-2.15-20,1-.87.38-1.56.75-2,1,4.04,5.31,8.99,11.12,15,17,4.21,4.11,8.34,7.69,12.25,10.77,1.92-8.92,3.84-17.85,5.75-26.77Z"
      />
      <path
        style={styles.three}
        d="m288.78,746.86c12.78,14.75,33.1,35.07,62.22,53.14,67.84,42.09,134.74,41.71,154,41,90.07-3.3,150.14-50.71,168.05-66.01-.49-20.15-2.41-43.44-7.05-68.99-3.61-19.9-8.27-37.85-13.21-53.63-10.81-11.13-25.57-24.22-44.79-36.37-14.07-8.9-27.43-15.32-39-20-57-1.67-114-3.33-171-5-15.12,7.51-31.68,16.71-49,28-17.08,11.13-32,22.4-44.85,33.06-4.57,12.38-9.16,27.84-12.15,45.94-3.09,18.73-3.65,35.36-3.22,48.86Z"
      />
      <g>
        <path d="m461.36,526.05s-11.31,49.7-11.31,49.7c0,0-.13.58-.13.58-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74,0,0,11.31-49.7,11.31-49.7l1.95.44h0Z" />
        <path d="m519.29,536.69c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59,0,0,1.88.69,1.88.69h0Z" />
      </g>
      <path
        style={styles.three}
        d="m421,547c4.09,6.23,10.78,14.87,21,23,9.58,7.62,18.85,12.08,25.57,14.7,1.84.63,4.77,1.4,8.43,1.3,4.02-.1,7.15-1.19,9-2,1.81.46,3.84,1.1,6,2,1.54.64,2.94,1.32,4.18,2,42.01,5.19,62.95,8.52,62.82,10-.21,2.35-53.54.01-160-7,1.2-6.02,3.46-14.14,8-23,4.86-9.49,10.58-16.41,15-21Z"
      />
      <path
        style={styles.four}
        d="m500,631.65c-5.06-.53-11.21-1.58-18-3.65-6.8-2.07-12.5-4.62-17-7-.68-.79-1.98-2.08-4-3-1.6-.72-3.05-.94-4-1-25.29-5.35-50.58-10.71-75.88-16.06,1.9-1.8,4.5-3.95,7.88-5.94,3.37-1.98,6.5-3.22,9-4,.61-3.71,1.55-7.75,3-12,5.23-15.35,14.43-25.85,21-32,3.2,4.9,7.44,10.45,13,16,11.09,11.07,22.72,17.4,31,21,1.55.77,4.77,2.11,9,2,2.49-.07,4.57-.61,6.13-1.19,1.86-.69,3.85-1.04,5.83-.82.02,0,.03,0,.05,0,3.71.43,6.46,1.99,8,3.04,1.67,8.53,3.09,17.88,4,27.96.52,5.79.84,11.35,1,16.65Z"
      />
      <path
        style={styles.one}
        d="m514.26,553.27l-5.26,30.73c1.05.54,7.53,3.77,15,1,8.8-3.27,11.24-11.99,11.46-12.82.59-.42,1.37-1.05,2.19-1.92,3.62-3.81,4.87-9,4.35-9.26-.25-.13-1.11.83-1,1,.13.2,1.87-.32,2-1,.3-1.51-7.18-4.49-13-6-3.58-.93-8.92-1.92-15.74-1.73Z"
      />
      <path d="m381.15,599.94c19.3.86,38.85-2.53,56.85-9.94-17.73,8.02-37.3,12.14-56.9,11.94l.05-2h0Z" />
      <path
        style={styles.three}
        d="m500,590c2-1.33,4-2.67,6-4,7.57-.6,13.12-1,16-1,.69,0,2.38.03,4-1,1.07-.68,1.73-1.57,2.09-2.17,3.4-4.24,6.79-8.48,10.19-12.72.92-.62,2.56-1.9,3.72-4.1.68-1.28.99-2.47,1.14-3.35.38,2.25.69,4.71.86,7.35.31,4.57.17,8.71-.18,12.29.18.9.96,4.34,4.18,6.71,2.03,1.49,4.09,1.88,5.14,2,1.62.21,14.09,1.94,14.86,7,.05.31.05.65,0,1-4.92-.26-9.92-.59-15-1-18.75-1.52-36.44-3.96-53-7Z"
      />
      <path
        style={styles.four}
        d="m417,552c1.31-2.55,2.86-4.08,4-5,6.26-5.04,15.38-3.56,25-2,3.97.64,7.16,1.45,9.16,2"
      />
      <path
        style={styles.four}
        d="m514,554c4.65-.79,13.4-2.01,23,3,2.35,1.23,4.34,2.62,6,4-.02.98-.2,2.46-1,4-.57,1.09-1.31,1.96-2.1,2.63-2.93,2.51-5.43,5.45-7.35,8.8-.87,1.52-2.02,3.1-3.55,4.57-1.37,1.32-2.76,2.29-4,3-2.05.43-4.39.8-7,1-2.99.23-5.68.17-8,0,.42-10.45,1.85-20.57,4-31Z"
      />
      <path
        style={styles.four}
        d="m543,561c.92,4.19,1.6,9.68,1,16-.05.58-.12,1.14-.19,1.69-.57,4.42,1.87,8.79,6.08,10.27.01,0,.04.01.11.04,3.63,1.26,6.38,1.86,7,2,1.79.42,4.58,1.42,9,4,1.91-.03,2.67.49,3,1,1.64,2.54-4.09,10.15-10.97,14.3-2.34,1.41-4.48,2.22-6.03,2.7-1,.28-2.49.83-4,2-.88.68-1.53,1.4-2,2,0,0,0,0,0,0-2.1,3.27-4.11,2.51-14.95,9.96-.03.02-.04.03-.05.04-3.38,2.33-6.01,4.81-8,7-1.47.75-4.28,1.92-8,2-4.14.08-7.26-1.24-8.76-2l-.24-48c1.33-.33,2.67-.67,4-1"
      />
      <path
        style={styles.two}
        d="m376,690c13.22,7.45,28.57,15.06,46,22,12.33,4.91,24.07,8.84,35,12,14,7.67,28,15.33,42,23v-7l-41.87-21.54-81.13-33.99v5.52Z"
      />
      <path
        style={styles.four}
        d="m506,586l-4.42,2.95c-.96.64-2.21.68-3.21.09l-3.37-1.99"
      />
      <polyline
        style={styles.four}
        points="500 631.65 499 840 503 840 506.24 634"
      />
      <polygon
        style={styles.two}
        points="505 742 594 695 606 690 606 699.35 591 704 572.96 715.41 505 747 505 742"
      />
      <path
        style={styles.four}
        d="m376,690c12.83,7.09,27.51,14.3,44,21,13.04,5.3,25.46,9.56,37,13,14.33,7.64,27.67,15.36,42,23v-7l-41.87-21.54-81.13-33.99v5.52Z"
      />
      <path
        style={styles.four}
        d="m505,742l77-40,12-7,12-5v9.35c-2.75.4-6.2,1.15-10,2.65-4.76,1.88-8.39,4.26-10.91,6.21-4.04,2.4-8.09,4.8-12.13,7.2l-67.96,31.59"
      />
      <path d="m377.1,814.44c4.19-14.39,6.05-29.81,3.98-44.72-.75-5.01-2.13-9.88-4-14.55-1.27-11.79-2.4-23.59-2.63-35.45-.02-2.95-.03-5.92.43-8.85.21-.93.31-2.06,1.13-2.66-.78.63-.83,1.76-1,2.68-.33,2.92-.19,5.88-.05,8.81.63,11.32,2.58,24.62,4.03,34.82,5.89,14.39,5.86,30.41,3.45,45.54-.84,5.06-1.91,10.04-3.41,14.99,0,0-1.9-.62-1.9-.62h0Z" />
      <rect
        style={styles.two}
        x="320"
        y="678"
        width="10"
        height="77"
        rx="5"
        ry="5"
      />
      <path d="m588.48,824.22c-.03-8.3-.23-16.63-.66-24.93-.62-7.63-.56-15.81-3.13-23.07-.22-.46-.49-.93-.91-1.24.85.62,1.21,1.7,1.58,2.65,2.16,6.98,2.68,14.36,3.46,21.59.77,8.31,1.29,16.62,1.66,24.96,0,0-2,.04-2,.04h0Z" />
      <path d="m622,675c0,31.33-3.72,62.7-11.03,93.24l-1.94-.49c7.92-30.23,12.3-61.43,12.97-92.76Z" />
      <rect
        style={styles.four}
        x="320"
        y="678"
        width="10"
        height="77"
        rx="5"
        ry="5"
      />
      <path
        style={styles.three}
        d="m400,590c-.33.33-.66.67-1,1-4.16,4.07-8.57,7.35-13,10,10.33-2.33,20.67-4.67,31-7-5.67-1.33-11.33-2.67-17-4Z"
      />
      <path
        style={styles.two}
        d="m398.25,656.17c-2.93,2.91-5.75,6.1-8,9.58-.56.87-.8,1.94-.26,2.88s1.65,1.27,2.65,1.28c1.9.02,3.86-.88,5.6-1.53,2.26-.84,4.48-1.78,6.64-2.81.73-.35.92-1.39.54-2.05-.44-.75-1.32-.89-2.05-.54-1.97.95-3.99,1.8-6.04,2.55-1.04.38-2.09.76-3.15,1.09-.29.09-.82.24-1.01.27-.24.03-.47,0-.71.03.12,0-.1.02-.07-.01-.2-.12-.12,0,.24.37.08.15.07.28-.01.38.02,0,.36-.59.4-.64.29-.43.6-.86.91-1.28.6-.82,1.21-1.62,1.85-2.4,1.44-1.76,2.98-3.43,4.59-5.03,1.37-1.36-.75-3.48-2.12-2.12h0Z"
      />
      <path
        style={styles.two}
        d="m404.23,662.09c-2.64,8.04-4.82,16.22-6.51,24.51-.38,1.88,2.51,2.69,2.89.8,1.69-8.29,3.87-16.47,6.51-24.51.6-1.84-2.29-2.63-2.89-.8h0Z"
      />
      <path
        style={styles.two}
        d="m406.24,664.07c4.28-.79,8.45-2.05,12.42-3.83.74-.33.92-1.41.54-2.05-.45-.76-1.31-.87-2.05-.54-3.73,1.67-7.69,2.79-11.7,3.53-.8.15-1.25,1.11-1.05,1.85.23.84,1.05,1.19,1.85,1.05h0Z"
      />
      <path
        style={styles.two}
        d="m404.58,668.98c4.69-1.47,9.46-2.67,14.28-3.63.79-.16,1.25-1.1,1.05-1.85-.23-.83-1.05-1.21-1.85-1.05-4.82.96-9.59,2.16-14.28,3.63-1.84.57-1.05,3.47.8,2.89h0Z"
      />
      <path
        style={styles.two}
        d="m424.34,654.75c-2.95,4.95-5.52,10.13-7.64,15.5-.36.92-.66,1.86-.42,2.85.27,1.11,1.17,1.91,2.27,2.14,1.34.28,2.77-.18,4.04-.6s2.56-.98,3.75-1.65c1.68-.95.17-3.54-1.51-2.59-.88.49-1.78.92-2.73,1.25-.51.18-1.03.34-1.55.47-.26.07-.52.14-.78.18-.2.03-.49-.06-.65,0,.23-.07-.03.32.08.06.07-.16.07-.42.16-.67.36-1.05.82-2.08,1.26-3.09,1.83-4.24,3.96-8.35,6.32-12.32.99-1.66-1.6-3.17-2.59-1.51h0Z"
      />
    </g>
  );
};

export default Tech;
