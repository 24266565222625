import { Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { IoLockClosed } from "react-icons/io5";
import { StoreContext } from "../../store/Store";
import { AvatarRarityEnum } from "../../utils/enums";
import { hexToRgbA } from "../../utils/helpers";
import {
  blue,
  emojiYellow,
  green,
  offWhite,
  purple,
  red,
  text,
} from "../../utils/themeContstants";

const SelectedPreview = ({ option }) => {
  const store = useContext(StoreContext);

  const getSelectedBackground = () => {
    switch (option?.rarity) {
      case AvatarRarityEnum.COMMON:
        return "rgba(215, 217, 231, 0.3)";
      case AvatarRarityEnum.UNCOMMON:
        return "rgba(21, 226, 164, 0.5)";
      case AvatarRarityEnum.RARE:
        return "rgba(21, 117, 226, 0.5)";
      case AvatarRarityEnum.LEGENDARY:
        return "rgba(112, 82, 221, 0.5)";
      case AvatarRarityEnum.EXOTIC:
        return "rgba(251, 139, 36, 0.5)";
      case AvatarRarityEnum.VIP:
        return "rgba(255, 84, 101, 0.5)";
      case AvatarRarityEnum.GODLIKE:
        return "rgba(255, 0, 110, 0.5)";
      case AvatarRarityEnum.EMOJI:
        return hexToRgbA(emojiYellow, 0.5);
      default:
        return null;
    }
  };

  const getSelectedColor = () => {
    switch (option?.rarity) {
      case AvatarRarityEnum.COMMON:
        return "#D7D9E7";
      case AvatarRarityEnum.UNCOMMON:
        return green;
      case AvatarRarityEnum.RARE:
        return blue;
      case AvatarRarityEnum.LEGENDARY:
        return purple;
      case AvatarRarityEnum.EXOTIC:
        return "#fb8b24";
      case AvatarRarityEnum.VIP:
        return red;
      case AvatarRarityEnum.GODLIKE:
        return text;
      case AvatarRarityEnum.EMOJI:
        return emojiYellow;
      default:
        return null;
    }
  };

  const getRarityText = () => {
    switch (option?.rarity) {
      case AvatarRarityEnum.COMMON:
        return "COMMON";
      case AvatarRarityEnum.UNCOMMON:
        return "UNCOMMON";
      case AvatarRarityEnum.RARE:
        return "RARE";
      case AvatarRarityEnum.LEGENDARY:
        return "LEGENDARY";
      case AvatarRarityEnum.EXOTIC:
        return "EXOTIC";
      case AvatarRarityEnum.VIP:
        return "MEMBERS ONLY";
      case AvatarRarityEnum.GODLIKE:
        return "GODLIKE";
      case AvatarRarityEnum.EMOJI:
        return "EMOJI";
      default:
        return null;
    }
  };

  const styles = {
    container: {
      position: "absolute",
      bottom: 12,
      left: 12,
      right: 12,
      borderRadius: 1,
      overflow: "hidden",
    },
  };

  return option?.value == null ? null : (
    <Grid item sx={styles.container}>
      {!store?.user?.new_locker?.options[option?.category]?.includes(
        option?._id?.toString()
      ) && (
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IoLockClosed
            style={{
              fontSize: 24,
              color: offWhite,
              position: "absolute",
              top: 6,
              right: 6,
            }}
          />
        </Grid>
      )}

      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
      >
        <Grid
          item
          sx={{
            padding: 1,
            paddingLeft: 2,
            paddingRight: 2,
            boxSizing: "border-box",
            backgroundColor: getSelectedBackground(),
            width: "100%",
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: 13,
                  color: getSelectedColor(),
                  fontWeight: 800,
                }}
              >
                {getRarityText()}
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={{ fontSize: 22, color: text, fontWeight: 800 }}>
                {option?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectedPreview;
