import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getNotiTimestamp,
  getReadableDateString,
} from "../../../utils/helpers";
import {
  card,
  cardVeryLight,
  normal,
  offWhite,
  small,
  text,
} from "../../../utils/themeContstants";
import SecondaryButton from "../../custom/SecondaryButton";
import ActivityItemPreviewModal from "./ActivityItemPreviewModal";

const ActivityItem = ({ activityItem, user = false }) => {
  const navigate = useNavigate();

  const [previewOpen, setPreviewOpen] = useState(false);

  const styles = {
    width: {
      width: "100%",
    },
    imageContainer: {
      width: "100%",
      position: "relative",
      opacity: 1,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      overflow: "hidden",
      "&:hover": {
        cursor: "pointer",
        opacity: 0.6,
      },
    },
    bottomContainer: {
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
      backgroundColor: card,
      border: `1px solid ${cardVeryLight}`,
      padding: 2,
      marginTop: -1,
      width: "100%",
    },
  };

  return (
    <>
      <ActivityItemPreviewModal
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        activityItem={activityItem}
      />

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid
            item
            sx={styles.imageContainer}
            onClick={() => setPreviewOpen(true)}
          >
            <img
              draggable={false}
              src={`data:image/jpeg;base64,${activityItem?.dataurl}`}
              alt="screenshot"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
          </Grid>

          <Grid item sx={styles.bottomContainer}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography
                      sx={{ fontSize: normal, fontWeight: 700, color: text }}
                    >
                      {activityItem?.user_id}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      sx={{ fontSize: small, fontWeight: 500, color: offWhite }}
                    >
                      {getNotiTimestamp(new Date(activityItem?.timestamp))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {!user && (
                <Grid item sx={styles.width}>
                  <SecondaryButton
                    fullWidth
                    label="View user"
                    size="small"
                    onClick={() => {
                      navigate("/staff/search", {
                        state: {
                          user_id: activityItem?.user_id,
                        },
                      });
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ActivityItem;
