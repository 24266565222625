const FutureVision = () => {
  const styles = {
    one: {
      fill: "#e3e6eb",
    },
    two: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#8f76a0",
    },
    five: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#b42036",
    },
    six: {
      fill: "#a79ab8",
    },
    seven: {
      opacity: 0.25,
    },
  };

  return (
    <g>
      <g>
        <path
          style={styles.one}
          d="M419.07,353.72c6.95-1.96,28.35-7.01,51.85,2.97,14.66,6.22,23.96,15.67,28.74,21.35-2.91,0-6.05,.13-9.38,.45-5.94,.57-11.24,1.62-15.79,2.82-3.09,.67-7.48,1.39-12.77,1.46-2.71,.04-12.18,.17-20.08-2.93-14.96-5.88-21.19-22.18-22.58-26.11Z"
        />
        <path
          style={styles.five}
          d="M442.85,352.33c-.31,.65-4.62,10.16,.87,18.58,.68,1.05,4.54,6.91,11.95,7.64,8.15,.8,13.34-5.31,13.65-5.69,5.45-6.66,2.85-14.72,2.68-15.2-3.22-1.84-8.44-4.29-15.31-5.36-5.68-.88-10.5-.5-13.84,.03Z"
        />
        <path d="M416.95,353.17c.85-.77,2.46-1.32,3.62-1.65,6.38-1.93,13.02-2.99,19.7-3.08,22.97-.58,45.89,10.46,59.75,28.77,0,0,1.92,2.46,1.92,2.47,0,0-3.07,0-3.07,0-7.74,0-15.48,.94-23,2.77-7.77,2.03-15.93,2.39-23.91,1.59-13.08-1.08-23.05-7.65-29.86-18.78-1.92-3.12-3.55-6.4-4.84-9.83-.3-.68-.4-1.58-.31-2.26h0Zm2.66,1.38c.25-.04,.41-.2,.39-.34,0,0,.06,.15,.06,.15l.13,.33c5.67,14.51,15.94,25.15,32.09,26.35,7.64,.76,15.45,.42,22.89-1.52,7.75-1.89,15.73-2.85,23.7-2.85,0,0-1.14,2.48-1.15,2.46-19.89-21.64-47.75-29.62-76.35-24.34,0,0-.67,.12-.67,.12l-.34,.06c-.11,.02-.25,.05-.24,.03-.09-.02-.18-.26-.52-.46h0Z" />
        <path
          style={styles.two}
          d="M451.03,352.5c-3.32,4.12-4.36,9.31-2.48,13.48,.32,.7,1.95,4.32,5.86,5.47,3.9,1.14,8.64-.59,10.63-4.34,2.31-4.36,.41-10.45-4.76-13"
        />
        <circle style={styles.three} cx="456.81" cy="362.06" r="2.41" />
        <g>
          <circle style={styles.three} cx="449.29" cy="367.88" r="2" />
          <path d="M445.89,364.82c-.04,.19-.28,1.3,.42,2.37,.65,.99,1.64,1.25,1.86,1.3,.29-.67,.58-1.34,.86-2-.36-.06-.81-.16-1.29-.36-.9-.38-1.51-.92-1.86-1.3Z" />
        </g>
        <path
          style={styles.seven}
          d="M436.93,378.09c-.58-.57-6.15-17.08-6.15-17.08,5.98-1.28,13.58-2.2,22.3-1.55,22.67,1.71,38.68,13.02,45.7,18.73-5.5-5.53-14.06-12.72-26.05-18.16-23.53-10.68-45.39-7.88-54.46-6.17,1.64,4.12,4.45,9.76,9.27,15.45,3.25,3.84,6.57,6.7,9.38,8.78Z"
        />
        <g>
          <circle style={styles.three} cx="464.21" cy="369.29" r="2" />
          <path d="M461.62,373.06c.2,.02,1.33,.11,2.29-.73,.89-.77,1.02-1.79,1.04-2.01-.7-.2-1.4-.4-2.1-.59,0,.36-.05,.82-.19,1.32-.26,.94-.72,1.61-1.04,2.01Z" />
        </g>
      </g>
      <g>
        <path
          style={styles.six}
          d="M643.1,354.94c-6.71-2.06-27.39-7.46-50.46,1.7-14.39,5.71-23.65,14.68-28.42,20.09,2.83,.07,5.87,.27,9.1,.65,5.76,.69,10.89,1.83,15.28,3.1,2.98,.72,7.24,1.52,12.38,1.72,2.63,.1,11.83,.45,19.58-2.39,14.67-5.38,21.1-21.07,22.54-24.86Z"
        />
        <path
          style={styles.four}
          d="M611.58,353.42c2.9,3.14,3.89,7.61,2.52,11.51-1.04,2.94-3.74,6.48-7.86,6.87-3.48,.33-6.51-1.73-8.05-4.17-2.46-3.89-1.76-9.73,2.03-13.98l11.37-.23Z"
        />
        <path
          style={styles.two}
          d="M591.41,357.68c-2.26,5.73-1.07,12.18,3.01,16.52,3.79,4.02,9.35,5.48,14.21,4.47,7.74-1.61,11.78-9.15,12.58-14.95,.68-4.91-.92-8.88-1.81-10.71"
        />
        <path
          style={styles.two}
          d="M585.13,361.18c-.66,1.76-.89,3.32-.93,4.58-.28,8.46,7.35,14.25,8.55,15.14"
        />
        <path
          style={styles.two}
          d="M627.79,353.65c.56,2.36,1.01,5.51,.76,9.18-.54,7.83-3.98,13.49-5.9,16.18"
        />
        <path
          style={styles.two}
          d="M633.47,354.28c.53,1.76,.99,3.99,1.05,6.58,.15,6.32-2.16,11.17-3.55,13.6"
        />
        <path
          style={styles.two}
          d="M577.56,365.44c-.08,1.86,.03,4.71,1.12,7.95,.77,2.3,1.81,4.11,2.69,5.39"
        />
        <path
          style={styles.two}
          d="M570.99,370.73c.01,1.02,.1,2.23,.34,3.57,.22,1.19,.52,2.24,.82,3.12"
        />
        <circle style={styles.three} cx="605.92" cy="362.62" r="1.95" />
        <path
          style={styles.seven}
          d="M624.18,378.12c.58-.54,6.37-16.46,6.37-16.46-5.78-1.38-13.15-2.45-21.64-2.01-22.07,1.14-37.89,11.77-44.85,17.16,5.47-5.25,13.95-12.04,25.73-17.06,23.12-9.84,44.3-6.62,53.07-4.75-1.69,3.97-4.55,9.39-9.37,14.81-3.25,3.65-6.54,6.36-9.32,8.32Z"
        />
        <path d="M642.37,355.82c-.33,.2-.41,.44-.49,.46-.23-.01-.91-.18-1.2-.23-27.67-5.74-54.89,1.35-74.72,21.89,0,.01-1.09-2.49-1.09-2.49,7.76,.19,15.49,1.3,22.98,3.32,7.18,2.06,14.76,2.56,22.19,2,15.7-.79,25.93-10.9,31.76-24.85,0,0,.13-.32,.13-.32,0,0,.06-.15,.06-.15-.01,.14,.12,.32,.38,.37h0Zm2.7-1.32c.08,.67-.04,1.55-.35,2.22-1.34,3.3-2.99,6.46-4.94,9.45-6.86,10.65-16.77,16.85-29.49,17.59-7.78,.59-15.71,.05-23.22-2.1-7.26-1.95-14.76-3.03-22.28-3.21l-3.06-.07s1.97-2.42,1.97-2.42c13.88-17.51,36.44-27.73,58.76-26.63,6.5,.24,12.92,1.44,19.08,3.47,1.12,.36,2.71,.93,3.51,1.72h0Z" />
      </g>
    </g>
  );
};

export default FutureVision;
