const Suspicious = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
  };
  return (
    <g>
      <path d="M603.35,364.47c-10.42,.52-17.83,5.7-22.71,15.05-1.12-1.43-1.19-2.68-.63-3.81,.87-1.77,1.66-3.74,3.02-5.08,7.74-7.62,17.08-10.13,27.68-8.03,.93,.18,1.81,.61,2.71,.93,6.36,2.24,6.25,9.21,3.33,12.71-3.03,3.64-6.94,5.16-11.63,4.77-3.61-.3-6.93-4.29-6.15-8.47,.59-3.19,1.99-5.82,4.37-8.08Z" />
      <path d="M477.26,361.13c-4.89-.1-9.23,1.48-13.25,4.04-4.07,2.59-6.58,6.58-9.28,10.78-.94-1.69-1.01-3.1-.33-4.38,.9-1.67,1.8-3.47,3.13-4.76,7.26-7.07,16.07-9.46,25.98-7.99,1.78,.27,3.52,1.01,5.2,1.73,4.77,2.05,4.77,7.57,3.22,10.59-.57,1.11-1.42,2.11-2.32,2.99-2.33,2.28-5.24,3.33-8.44,3.49-5.52,.27-9.16-4.25-7.8-9.62,.66-2.6,1.89-4.88,3.9-6.87Z" />
      <circle style={styles.one} cx="486.5" cy="365.5" r="2.5" />
      <circle style={styles.one} cx="612.5" cy="370.5" r="2.5" />
    </g>
  );
};

export default Suspicious;
