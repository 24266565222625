const MimeHat = () => {
  const styles = {
    one: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#fff",
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#333",
    },
  };

  return (
    <g>
      <path
        style={styles.two}
        d="m374.31,354.85c-1.25,17.06-1.19,44.99,8.91,77.32,10.22,32.7,26.34,55.88,37.18,69.2,20.99,18.67,41.55,25.9,52.74,28.92,19.92,5.37,36.25,4.42,50.17,3.61,15.33-.89,31.87-1.85,51.63-10.07,30.68-12.76,47.67-34.09,51.13-38.58,10.99-14.23,15.93-27.51,24.43-50.34,3.16-8.49,7.02-18.97,10.16-33.27,2.88-13.08,6.87-31.25,4.78-52.5-2.57-26.24-13.41-47.09-23.15-61.17-26.76-5.88-79.37-13.73-142.46,2.15-62.4,15.71-104.7,46.99-125.52,64.72Z"
      />
      <path
        style={styles.three}
        d="m360.92,355.43c-2.11-.09-4.14-.83-5.73-2.22-6.08-5.31-17.33-17.18-20.11-35.3-3.51-22.87,7.87-46.59,29-60.65,26.56-18.13,111.89-71.27,217.13-52.83,27.7,4.86,51.35,13.72,70.58,23.21,1.97.97,3.52,2.64,4.35,4.67,2.54,6.27,6.34,19.17,1.77,33.95-2.7,8.75-7.38,15.07-11.15,19.1-2.12,2.27-5.22,3.3-8.28,2.75-35.98-6.49-107.98-13.7-186.01,19.24-33.12,13.98-59.03,31.7-78.17,47.51-2.56,2.12-6.04,2.58-9.16,1.43-.02,0-.04-.02-.06-.02-1.52-.55-2.95-.77-4.17-.83Z"
      />
      <path
        style={styles.three}
        d="m436.31,219.62c-.84-2.24-2.61-6.09-6.26-9.71-2.98-2.94-6.05-4.59-8.09-5.48-2.24-.36-8.66-1.7-13.04-7.3-.85-1.08-5.75-7.36-3.39-12.26,1.85-3.85,7.13-4.67,9.78-5.09,12.45-1.95,23.38,6.01,27.65,9.52,3.62,2.67,7.71,6.1,11.87,10.43,3.68,3.84,6.64,7.59,9,10.95l-27.52,8.93Z"
      />
      <path
        style={styles.one}
        d="m423.13,350c-1.28.43-2.64-.35-2.93-1.57-.3-1.24.59-2.57,1.96-2.74,7.32-4.54,15.87-9.21,25.63-13.5,15.9-6.99,30.62-11.06,42.85-13.5.47,1.67.94,3.94.98,6.65.05,3.08-.47,5.64-.98,7.43-9.11-.95-24.21-1.3-41.48,4.3-11.2,3.64-19.91,8.67-26.02,12.91Z"
      />
      <path
        style={styles.two}
        d="m424.3,372.11c5.74-3.89,19.11-11.82,37.96-12.91,21.83-1.26,37.83,7.42,43.63,10.96-12.59,5.54-25.17,11.09-37.76,16.63-2.06.91-5.23,1.62-9.2,1.57-3.62-.05-6.56-.87-8.48-1.57-3.86-2.07-7.75-4.2-11.67-6.39-4.94-2.76-9.76-5.52-14.48-8.28Z"
      />
      <path
        style={styles.one}
        d="m453.32,360.26c3.02,11.93,14.03,17.57,21.36,14.88,4.97-1.82,8.43-7.52,9.09-14.05-3.61-.86-7.92-1.6-12.8-1.89-6.95-.41-12.97.21-17.66,1.05Z"
      />
      <ellipse style={styles.two} cx="478.45" cy="364.88" rx="2.59" ry="2.56" />
      <polygon
        style={styles.one}
        points="457.17 359.39 460.21 339.34 467.64 359.39 457.17 359.39"
      />
      <path
        style={styles.one}
        d="m456.29,388.35l9.39,19.3,3.85-21.49s-8.34,3.43-13.24,2.18Z"
      />
      <g>
        <path
          style={styles.one}
          d="m628.15,352.93c1.28.43,2.64-.35,2.93-1.57.3-1.24-.59-2.57-1.96-2.74-7.32-4.54-15.87-9.21-25.63-13.5-15.9-6.99-30.62-11.06-42.85-13.5-.47,1.67-.94,3.94-.98,6.65-.05,3.08.47,5.64.98,7.43,9.11-.95,24.21-1.3,41.48,4.3,11.2,3.64,19.91,8.67,26.02,12.91Z"
        />
        <path
          style={styles.two}
          d="m626.97,375.04c-5.74-3.89-19.11-11.82-37.96-12.91-21.83-1.26-37.83,7.42-43.63,10.96,12.59,5.54,25.17,11.09,37.76,16.63,2.06.91,5.23,1.62,9.2,1.57,3.62-.05,6.56-.87,8.48-1.57,3.86-2.07,7.75-4.2,11.67-6.39,4.94-2.76,9.76-5.52,14.48-8.28Z"
        />
        <path
          style={styles.one}
          d="m597.95,363.19c-3.02,11.93-14.03,17.57-21.36,14.88-4.97-1.82-8.43-7.52-9.09-14.05,3.61-.86,7.92-1.6,12.8-1.89,6.95-.41,12.97.21,17.66,1.05Z"
        />
        <ellipse
          style={styles.two}
          cx="572.82"
          cy="367.82"
          rx="2.59"
          ry="2.56"
        />
        <polygon
          style={styles.one}
          points="594.1 362.33 591.07 342.27 583.63 362.33 594.1 362.33"
        />
        <path
          style={styles.one}
          d="m594.98,391.29l-9.39,19.3-3.85-21.49s8.34,3.43,13.24,2.18Z"
        />
      </g>
      <path
        style={styles.one}
        d="m474.78,465.63l33.46-4.11c2.32-1.26,6.54-3.15,12.13-3.52,4.43-.29,8.05.48,10.37,1.17,1.63-.82,4.28-1.86,7.63-1.96,7.25-.21,12.26,4.14,13.5,5.28,5.25.73,11.01,1.29,17.22,1.57,5.81.26,11.24.22,16.24,0-8.12.95-16.62,2.29-25.43,4.11-2.45.51-4.86,1.04-7.23,1.59-6.57,6.59-12.59,8.88-16.83,9.76-12.4,2.58-20.72-4.53-44.8-10.57-6.84-1.71-12.53-2.73-16.24-3.33Z"
      />
      <path
        style={styles.two}
        d="m513.72,471.5c.58.56,8.14,7.65,17.56,5.3,4.47-1.11,7.35-3.86,8.66-5.3-3.02-.39-6.29-.67-9.78-.78-6.11-.2-11.64.17-16.43.78Z"
      />
      <path d="m350.87,350.28c9.62-6.96,23.83-15.84,33.83-22.42,10.13-6.56,23.93-15.94,34.21-22.1-12.78,16.31-29.7,29.07-48,38.57-6.15,3.1-12.5,5.83-19.17,7.76l-.88-1.8h0Z" />
      <path d="m654.94,230.18c-17.19,16.28-44.95,28-68.64,28.65,17.77-5.59,35.11-11.93,51.51-20.75,5.44-2.87,10.65-6.09,15.89-9.47l1.24,1.57h0Z" />
      <path d="m495,424.48c20.78,9.54,40.18,10.54,61.83,3.13-17.4,14.74-45.61,12.26-61.83-3.13h0Z" />
    </g>
  );
};

export default MimeHat;
