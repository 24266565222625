const Foggy = () => {
  const styles = {
    one: {
      opacity: 0.7,
    },
    two: {
      fill: "#fff",
      stroke: "#fff",
      strokeMiterLimit: 10,
    },
  };

  return (
    <g>
      <g style={styles.one}>
        <g>
          <path
            style={styles.two}
            d="M340.08,763.13c1.94-.64,3.64-1.19,5.34-1.75-12.83,1.73-21.17-2.56-26.56-11.42-.88,.48-1.91,1.53-2.91,1.5-11.56-.25-21.86-3.08-29.72-10.67-1.89-1.82-5.11-6.35-5.82-12.79-.95-8.6,3.34-18.05,10.88-21.91,3.47-1.78,6.62-1.86,9.82-1.94,4.68-.12,7.5,.92,21.95,4.66,2.1,.54,3.81,.98,4.83,1.23,.15-.29,.31-.57,.46-.86-2.39-.88-4.78-1.77-7.56-2.8,4.34-12.53,13.67-21.82,28.17-26.82,21.91-7.55,41.52-4.67,57.35,9.65-.48-.97-.65-2.25-1.5-2.87-3.74-2.72-1.84-5.23,1.01-8.06,10.26-10.2,26.44-13.59,36.34-6.9,3.02,2.04,4.79,5.29,7.33,8.22-2.19-2.03-3.36-4.04-4.02-5.51-1.13-2.53-.87-3.7-2.29-5.04-1.78-1.69-3.55-1.13-4.36-2.71-.8-1.56,.19-3.67,.52-4.59,7.16-19.78,25.27-34.92,47.96-39.04,29.99-5.43,53.97,12.55,49.84,37.17-.68,4.08-2,8.17-2.09,12.38,1.38-2.74,2.77-5.49,4.15-8.23,15.5-1.78,28.63,7.55,27.65,19.76-9.01,3.15-17.4,7.09-23.19,13.87,.62-.23,1.42-.34,1.84-.69,11.74-10.01,26.15-13.28,41.47-14.41,27.15-1.99,49.86,2.72,65.09,16.49,8.95,8.1,12.21,18.05,8.41,30.07-8.87,1.49-17.3,3.07-24.85,7.46,14.4-4.92,28.57-8.08,41.34-3.8,4.27,1.43,11.76,4.68,13.8,11.22,3.17,10.19-9.26,22.11-17.31,27.24-18.62,11.87-41.02,4.79-45.85,3.14,2.36-2.34,4.75-4.71,7.15-7.09-.34-.21-.68-.41-1.03-.62-10.6,9.98-24.02,15.94-39.81,18.02-15.6,2.05-28.37-.27-38.44-6.85,2.25-2.45,4.33-4.7,6.41-6.96-15.63,13.83-34.49,21.5-56.31,23.22-21.12,1.66-38.35-2.7-50.81-14.11-.33,.27-.67,.55-1,.82,2.05,1.83,4.09,3.66,6.42,5.73-8.97,4.58-18.55,6.84-28.6,7.15-21,.64-36.84-5.41-47.66-18.38-.59-.71-1.14-1.1-1.71-1.26-1.49-.42-2.93,.39-3.13,.49-1.73,.89-11.94-1.66-38.99-11.42Z"
          />
          <path
            style={styles.two}
            d="M686.5,762.5c-6.57,6.51-16.29,15.5-29,25-40.82,30.51-79.7,41.75-92,45-9.87,2.61-42.68,10.66-86,8-33.94-2.09-60.28-9.87-75-15-44.71-15.59-73.93-38.62-84-47-17.14-14.27-29.6-28.36-38-39,134.67,7.67,269.33,15.33,404,23Z"
          />
        </g>
        <path
          style={styles.two}
          d="M322.5,710.5c-14.03,4.23-28.07,8.45-42.1,12.68-.13,2.13-.14,4.6,.1,7.32,.27,3.11,.8,5.85,1.39,8.14,2.27,1.38,4.54,2.76,6.81,4.13,11.26-10.76,22.53-21.52,33.79-32.28Z"
        />
        <path
          style={styles.two}
          d="M688.98,740.55c.76,2.06,1.74,5.56,1.52,9.95-.28,5.48-2.28,9.52-3.46,11.54-2.18,.15-4.36,.31-6.54,.46l-3.46-23,11.94,1.05Z"
        />
      </g>
    </g>
  );
};

export default Foggy;
