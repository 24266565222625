const Rawr = () => {
  const styles = {
    one: {
      fill: "#992937",
    },
    two: {
      fill: "#af353e",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 3,
    },
  };

  return (
    <g>
      <path
        style={styles.two}
        d="M510.94,462.81c.36,33.96,24.28,54.29,41.89,49.61,14.55-3.87,27.75-25.59,26.35-51.64-4.5-3.58-10.68-7.43-18.49-9.6-23.93-6.66-44.42,7.65-49.75,11.64Z"
      />
      <path
        style={styles.one}
        d="M512.54,463.52c3.12-2.64,16.71-13.55,36.46-12.52,14.5,.76,24.6,7.55,28.5,10.5-9.17-.52-19.03-.74-29.5-.5-12.77,.29-24.63,1.23-35.46,2.52Z"
      />
    </g>
  );
};

export default Rawr;
