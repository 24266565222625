const Angry = () => {
  return (
    <g>
      <path d="M637.73,331.75c-1.3-.41-2.62-.76-3.9-1.24-5.25-1.94-10.63-3.26-16.07-4.13-7.42-1.19-14.16,1.13-20.09,6.31-5.02,4.39-9.71,9.32-14.76,13.66-4.24,3.65-8.8,6.79-13.2,10.19-1.11,.86-2.26,1.14-3.54,.76-1.38-.4-1.94-1.59-2.34-3.07-.36-1.34,.25-2.1,.98-2.79,2.24-2.09,4.51-4.12,6.76-6.21,5.4-5.02,10.74-10.13,16.2-15.05,4-3.6,8.34-6.71,13.16-8.49,3.76-1.39,7.75-1.59,11.73-1.1,3.97,.48,7.79,1.66,11.5,3.29,3.84,1.69,7.62,3.56,11.41,5.42,.86,.42,1.62,1.13,2.43,1.71-.09,.24-.18,.47-.27,.71Z" />
      <path d="M426.9,325.07c.47-.41,.88-.95,1.4-1.22,6.59-3.44,13.21-6.8,20.31-8.61,4.71-1.2,9.44-1.06,14.08,.11,2.37,.6,4.65,1.97,6.81,3.35,7.25,4.64,13.67,10.66,19.96,16.88,2.54,2.52,5.23,4.83,7.85,7.25,.91,.85,1.8,1.72,2.67,2.63,.68,.72,1.37,1.38,.98,2.77-.41,1.49-.95,2.67-2.33,3.08-1.28,.39-2.43,.11-3.54-.75-4.34-3.35-8.77-6.55-13.03-10.03-2.66-2.18-5.05-4.81-7.6-7.17-2.92-2.71-5.79-5.49-8.84-7.98-3.56-2.9-7.72-4.14-11.94-5.05-4.48-.96-8.79,.31-13.08,1.42-4.23,1.09-8.41,2.49-12.61,3.74-.28,.08-.58,.09-.86,.13l-.23-.55Z" />
    </g>
  );
};

export default Angry;
