import { useContext } from "react";
import { MdAccountBalanceWallet } from "react-icons/md";
import { SET_WALLET_OPEN, StoreDispatch } from "../../../store/Store";
import { offWhite } from "../../../utils/themeContstants";
import CustomModal from "../../custom/CustomModal";
import WalletContent from "../../wallet_menu/WalletContent";

const MobileWalletModal = ({ open, onClose }) => {
  const updateStore = useContext(StoreDispatch);

  const handleClose = () => {
    updateStore({ type: SET_WALLET_OPEN, payload: false });
    onClose();
  };

  return (
    <>
      <CustomModal
        title="Wallet"
        icon={
          <MdAccountBalanceWallet style={{ color: offWhite, fontSize: 24 }} />
        }
        open={open}
        onClose={handleClose}
      >
        <WalletContent onClose={handleClose} />
      </CustomModal>
    </>
  );
};

export default MobileWalletModal;
