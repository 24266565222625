const Blinding = () => {
  const styles = {
    one: {
      fill: "#ddd",
    },
    two: {
      fill: "#f4f4f4",
    },
    three: {
      fill: "#f4f4f4",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    four: {
      fill: "#fff",
    },
    five: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    six: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
  };

  return (
    <g>
      <path
        style={styles.three}
        d="M506.11,724.28s-.56-4.92-4.52-11.36c-.42-.68-.92-1.37-1.65-2.02-.63-.56-1.23-.91-1.6-1.11-.33-.09-1.09-.24-1.98,.05-1.12,.37-1.72,1.21-1.91,1.49-1.8,2.27-3.01,4.39-3.82,6.01,0,0-2.14,4.39-3.04,8.8,0,0,0,0,0,.01-.71,2.47-5.46,19.79,1.61,25.5,.45,.36,.95,.68,1.52,.95,.03,.01,.06,.02,.1,.01,1.23-.21,2.83-.62,4.59-1.46,1.48-.71,2.66-1.52,3.54-2.23,.02-.02,.04-.04,.05-.06,.55-1.24,1.1-2.49,1.65-3.73,.22-.45,.56-1.15,.95-2.01,2-4.37,3.6-7.85,4.28-12.06,.22-1.36,.59-3.72,.24-6.79Zm-5.94,9.77c-.98,2.88-2.38,6.67-4.33,11.03-.49,1.57-2,2.42-3.29,2.04-1.01-.3-1.78-1.31-1.91-2.53-.57-4.68-.9-12.79,2.39-21.68,1.27-3.43,2.82-6.27,4.29-8.53,.49,.72,1.17,1.75,1.91,3.04,.73,1.29,2.28,3.99,2.86,6.07,.45,1.6,.5,3.48-1.9,10.56Z"
      />
      <path
        style={styles.three}
        d="M468.4,725.77l-6.87-7.43c-1.09-.89-2.56-.84-3.39-.03-1.02,.99-1,3.02,.34,4.18,2.87,2.71,5.73,5.41,8.59,8.12-1.66,4.54-3.28,9.77-4.58,15.66-.87,3.95-1.5,7.7-1.93,11.16,.04,.36,.3,2.27,1.93,3.55,1.19,.93,2.44,1.01,2.9,1.02-.16-.68-.32-1.35-.48-2.03l5.31-24.83,7.7,9.66c.55,1.17,1.63,1.76,2.4,1.53,.95-.28,1.71-1.91,.96-3.54-.54-1.51-1.31-3.25-2.4-5.07-2.2-3.67-4.8-6.12-6.73-7.63,1.93-5.73,3.85-11.45,5.77-17.16l6.72,3.6c-.21,1.46,.71,2.8,1.92,3.05,1.34,.28,2.78-.85,2.87-2.5,1.85-2.13,3.7-4.26,5.54-6.39,.02-.13,.33-2.98-1.72-4.67-1.39-1.14-2.96-1.02-3.33-.98-1.03,.59-2.06,1.18-3.09,1.78l-6.65-.48-6.1,5.03-5.68,14.41Z"
      />
      <path
        style={styles.three}
        d="M482.56,706.51c-.2-.41-.31-.88-.31-1.38,0-1.54,1.07-2.77,2.4-2.75s2.39,1.27,2.39,2.81c0,.6-.17,1.16-.45,1.61m1.77-.86c.08-.39,.11-.81,.11-1.24,0-2.93-1.82-5.32-4.07-5.35s-4.08,2.32-4.08,5.26c0,.62,.08,1.21,.23,1.76"
      />
      <path
        style={styles.three}
        d="M540.79,586.92c-.36,.65-.17,1.72,.05,1.97,.52,.59,1.07,1.34,1.57,2.28,4.75,9.02-2.11,23.98-10.96,43.27-2.31,5.03-7.48,15.88-15.53,27.75-2.36,3.49-4.85,6.87-8.05,10.49,0,0-5.48,6.13-15.68,12.92-.72,.48-2.11,1.37-4.06,1.82-.54,.13-1.65,.38-2.86,.26-2.44-.24-4.26-1.9-5.29-2.91-14.32-14.05-24.61-30.81-24.61-30.81-1.77-2.88-5.12-8.48-9.03-15.91-1.56-2.96-3.21-6.23-4.92-9.82,0,0-9.8-20.55-11.95-39.83-.22-1.98,0-3.82,0-3.82,.08-.66,.19-1.25,.31-1.75,.13-.55-.04-1.16-.43-1.51-.26-.24-.57-.45-.93-.6-1.33-.54-2.9,.05-4.1,1.49-.25,.3-.37,.73-.32,1.16,.69,5.89,1.96,13.8,4.42,22.83,2.94,10.81,6.24,18.27,9.88,26.39,5.92,13.22,10.62,23.73,19.12,35.35,4.59,6.27,11.76,14.89,22.04,23.13,1.33,1.07,3.79,3.05,7.13,3.25,2.35,.14,4.61-.61,9.14-3.64,6.52-4.36,10.62-8.62,10.62-8.62,4.66-4.84,11.23-11.75,18.59-23.43,5.11-8.09,8.15-14.61,11.98-22.79,3.72-7.96,5.89-12.64,7.96-19.69,5.31-18.13,2.38-26.02,1.49-27.32-1.53-2.23-3.12-2.56-3.42-2.61-.05,0-.1,0-.1,0-.26-.02-1.61-.13-2.07,.71Z"
      />
      <g>
        <path
          style={styles.one}
          d="M498.76,686.68c0,.16-.01,.32-.03,.47,0,.03,0,.06-.02,.1,0,.06-.02,.11-.03,.17,0,.02,0,.04-.01,.06,0,.03-.02,.06-.03,.09,0,.03-.02,.05-.02,.08,0,.01,0,.02,0,.03,0,.03-.02,.07-.03,.1,0,0,0,.01,0,.02,0,.03-.02,.06-.03,.08,0,.02-.01,.03-.02,.05-.03,.08-.07,.16-.11,.24-.02,.03-.03,.06-.05,.09-.13,.23-.29,.45-.47,.64-.29,.31-.65,.57-1.05,.75-.04,.02-.09,.04-.13,.06-.06,.02-.11,.04-.17,.06-.02,0-.04,.01-.06,.02-.03,.01-.07,.02-.11,.03-.06,.02-.12,.03-.17,.04-.03,0-.07,.01-.1,.02,0,0,0,0-.01,0-.13,.02-.26,.04-.39,.05-.04,0-.08,0-.11,0-.05,0-.1,0-.15,0-.05,0-.09,0-.13,0-.06,0-.11,0-.17-.01-.05,0-.1-.01-.14-.02,0,0-.02,0-.03,0-.03,0-.06,0-.08-.01-.13-.02-.25-.05-.38-.09-.03,0-.07-.02-.1-.03-.03,0-.06-.02-.08-.03-.14-.05-.28-.11-.41-.18-.03-.01-.05-.02-.07-.04-.02-.01-.05-.03-.07-.04-.46-.27-.86-.65-1.14-1.1-.19-.3-.34-.63-.42-.98-.06-.25-.1-.52-.1-.79,0-.43,.08-.85,.24-1.22,.16-.4,.4-.76,.7-1.06,.44-.45,1.02-.76,1.66-.89,.01,0,.02,0,.03,0,.06-.01,.13-.03,.2-.04,.16-.02,.33-.03,.5-.03,.12,0,.23,.01,.34,.02,.03,0,.06,0,.09,.01,.06,0,.11,.02,.17,.03,.15,.03,.3,.07,.44,.11,.97,.33,1.74,1.08,2.1,2.02,.05,.15,.1,.3,.13,.45,0,.04,.02,.07,.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03,0,.04,0,.03,0,.06,.01,.09,0,.03,0,.07,.01,.1,0,.03,0,.05,0,.08,0,.03,0,.06,0,.09,0,.05,0,.1,0,.15Z"
        />
        <path
          style={styles.two}
          d="M497.37,684.03c-.65,.94-1.3,1.89-1.95,2.83h0s-1.53-3.16-1.53-3.16c.19-.1,.46-.22,.8-.3,.01,0,.02,0,.03,0,.06-.01,.13-.03,.2-.04,.1-.02,.21-.03,.33-.03,.18,0,.35,0,.52,.02,.03,0,.06,0,.09,.01,.06,0,.11,.02,.17,.03,.15,.03,.3,.08,.43,.13,.45,.16,.77,.39,.92,.5Z"
        />
        <path
          style={styles.two}
          d="M496.83,689.64s-.09,.04-.13,.06c-.06,.02-.11,.04-.17,.06-.02,0-.04,.01-.06,.02-.03,.01-.07,.02-.11,.03-.06,.02-.12,.03-.17,.04-.03,0-.07,.02-.1,.02,0,0,0,0-.01,0-.13,.02-.26,.04-.39,.05-.04,0-.08,0-.11,0-.05,0-.1,0-.15,0-.05,0-.09,0-.13,0-.06,0-.11,0-.17-.01-.05,0-.1-.01-.14-.02,0,0-.02,0-.03,0-.03,0-.06,0-.08-.01-.13-.02-.25-.05-.38-.09-.03,0-.07-.02-.1-.03-.03,0-.06-.02-.08-.03-.14-.05-.28-.11-.41-.18-.03-.01-.05-.02-.07-.04-.02-.01-.05-.03-.07-.04,.44-.76,1.63-2.54,1.67-2.59h0s1.41,2.77,1.41,2.77Z"
        />
        <path
          style={styles.two}
          d="M498.76,686.68c0,.16-.01,.32-.03,.47,0,.03,0,.06-.02,.1,0,.06-.02,.11-.03,.17,0,.02,0,.04-.01,.06,0,.03-.02,.06-.03,.09,0,.03-.02,.05-.02,.08,0,.01,0,.02,0,.03,0,.03-.02,.07-.03,.1,0,0,0,.01,0,.02,0,.03-.02,.06-.03,.08,0,.02-.01,.03-.02,.05-.03,.08-.07,.16-.11,.24-.02,.03-.03,.06-.05,.09-.13,.23-.29,.45-.47,.64l-2.46-2.03,3.13-1.33c.05,.15,.1,.3,.13,.45,0,.04,.02,.07,.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03,0,.04,0,.03,0,.06,.01,.09,0,.03,0,.07,.01,.1,0,.03,0,.05,0,.08,0,.03,0,.06,0,.09,0,.05,0,.1,0,.15Z"
        />
        <path
          style={styles.two}
          d="M495.42,686.86h0s-2.82,1.49-2.82,1.49c-.19-.3-.34-.63-.42-.98-.06-.25-.1-.52-.1-.79,0-.43,.08-.85,.24-1.22,.16-.4,.4-.76,.7-1.06,.6,.65,2.34,2.51,2.4,2.56h0Z"
        />
        <path
          style={styles.four}
          d="M496.45,683.52h0s-1.03,2.55-1.03,2.55l-.74-2.66h0s.02,0,.03,0c.06-.01,.13-.03,.2-.04,.1-.02,.21-.03,.33-.03,.18,0,.35,0,.52,.02,.03,0,.06,0,.09,.01,.06,0,.11,.02,.17,.03,.15,.03,.3,.07,.44,.11Z"
        />
        <path
          style={styles.four}
          d="M498.76,686.68c0,.16-.01,.32-.03,.47,0,.03,0,.06-.02,.1,0,.06-.02,.11-.03,.17,0,.02,0,.04-.01,.06,0,.03-.02,.06-.03,.09,0,.03-.02,.05-.02,.08,0,.01,0,.02,0,.03,0,.03-.02,.07-.03,.1,0,0,0,.01,0,.02,0,.03-.02,.06-.03,.08,0,.02-.01,.03-.02,.05l-2.28-1.04,2.48-.75s0,.03,0,.04c0,.03,0,.06,.01,.09,0,.03,0,.07,.01,.1,0,.03,0,.05,0,.08,0,.03,0,.06,0,.09,0,.05,0,.1,0,.15Z"
        />
        <path
          style={styles.two}
          d="M496.07,689.87c-.13,.02-.26,.04-.39,.05-.04,0-.08,0-.11,0-.05,0-.1,0-.15,0-.05,0-.09,0-.13,0-.06,0-.11,0-.17-.01-.05,0-.1-.01-.14-.02,0,0-.02,0-.03,0-.03,0-.06,0-.08-.01-.13-.02-.25-.05-.38-.09-.03,0-.07-.02-.1-.03l.99-2.41,.69,2.54Z"
        />
        <path
          style={styles.four}
          d="M494.89,686.8l-2.71,.57c-.06-.25-.1-.52-.1-.79,0-.43,.08-.85,.24-1.22l2.57,1.45Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M505.41,682.26c0,.16-.01,.32-.03,.47,0,.03,0,.06-.02,.1,0,.06-.02,.11-.03,.17,0,.02,0,.04-.01,.06,0,.03-.02,.06-.02,.09,0,.03-.02,.05-.02,.08,0,.01,0,.02,0,.03,0,.03-.02,.07-.03,.1,0,0,0,.01,0,.02,0,.03-.02,.06-.03,.08,0,.01-.01,.03-.02,.04-.03,.08-.07,.16-.11,.24-.02,.03-.03,.06-.05,.09-.13,.23-.29,.45-.47,.64-.29,.31-.65,.57-1.05,.74-.04,.02-.09,.04-.13,.06-.06,.02-.11,.04-.17,.06-.02,0-.04,.01-.06,.02-.03,.01-.07,.02-.11,.03-.06,.02-.12,.03-.17,.04-.03,0-.07,.01-.1,.02,0,0,0,0-.01,0-.13,.02-.25,.04-.39,.05-.04,0-.08,0-.11,0-.05,0-.1,0-.15,0-.05,0-.09,0-.13,0-.06,0-.11,0-.17-.01-.05,0-.1-.01-.14-.02,0,0-.02,0-.03,0-.03,0-.06,0-.08-.01-.13-.02-.25-.05-.38-.09-.03,0-.07-.02-.1-.03-.03,0-.06-.02-.08-.03-.14-.05-.28-.11-.41-.18-.02-.01-.05-.02-.07-.04-.02-.01-.05-.03-.07-.04-.46-.27-.86-.65-1.14-1.1-.19-.3-.34-.63-.42-.98-.06-.25-.1-.52-.1-.79,0-.43,.08-.85,.24-1.22,.16-.4,.4-.76,.7-1.05,.44-.44,1.01-.76,1.66-.89,.01,0,.02,0,.03,0,.06-.01,.13-.03,.2-.04,.16-.02,.33-.03,.5-.03,.12,0,.23,.01,.34,.02,.03,0,.06,0,.09,.01,.06,0,.11,.02,.17,.03,.15,.03,.29,.07,.44,.11,.97,.33,1.74,1.08,2.09,2.02,.05,.15,.1,.3,.13,.45,0,.04,.02,.07,.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03,0,.04,0,.03,0,.06,.01,.09,0,.03,0,.07,.01,.1,0,.03,0,.05,0,.08,0,.03,0,.06,0,.09,0,.05,0,.1,0,.15Z"
        />
        <path
          style={styles.two}
          d="M504.03,679.61c-.65,.94-1.29,1.88-1.94,2.83h0s-1.53-3.16-1.53-3.16c.19-.1,.45-.22,.79-.3,.01,0,.02,0,.03,0,.06-.01,.13-.03,.2-.04,.1-.02,.21-.03,.33-.03,.18,0,.35,0,.52,.03,.03,0,.06,0,.09,.01,.06,0,.11,.02,.17,.03,.15,.03,.3,.08,.43,.13,.45,.16,.76,.39,.92,.5Z"
        />
        <path
          style={styles.two}
          d="M503.5,685.21s-.09,.04-.13,.06c-.06,.02-.11,.04-.17,.06-.02,0-.04,.01-.06,.02-.03,.01-.07,.02-.11,.03-.06,.02-.12,.03-.17,.04-.03,0-.07,.02-.1,.02,0,0,0,0-.01,0-.13,.02-.25,.04-.39,.05-.04,0-.08,0-.11,0-.05,0-.1,0-.15,0-.05,0-.09,0-.13,0-.06,0-.11,0-.17-.01-.05,0-.1-.01-.14-.02,0,0-.02,0-.03,0-.03,0-.06,0-.08-.01-.13-.02-.25-.05-.38-.09-.03,0-.07-.02-.1-.03-.03,0-.06-.02-.08-.03-.14-.05-.28-.11-.41-.18-.02-.01-.05-.02-.07-.04-.02-.01-.05-.03-.07-.04,.43-.75,1.63-2.53,1.67-2.59h0s1.41,2.77,1.41,2.77Z"
        />
        <path
          style={styles.two}
          d="M505.41,682.26c0,.16-.01,.32-.03,.47,0,.03,0,.06-.02,.1,0,.06-.02,.11-.03,.17,0,.02,0,.04-.01,.06,0,.03-.02,.06-.02,.09,0,.03-.02,.05-.02,.08,0,.01,0,.02,0,.03,0,.03-.02,.07-.03,.1,0,0,0,.01,0,.02,0,.03-.02,.06-.03,.08,0,.01-.01,.03-.02,.04-.03,.08-.07,.16-.11,.24-.02,.03-.03,.06-.05,.09-.13,.23-.29,.45-.47,.64l-2.45-2.03,3.12-1.32c.05,.15,.1,.3,.13,.45,0,.04,.02,.07,.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03,0,.04,0,.03,0,.06,.01,.09,0,.03,0,.07,.01,.1,0,.03,0,.05,0,.08,0,.03,0,.06,0,.09,0,.05,0,.1,0,.15Z"
        />
        <path
          style={styles.two}
          d="M502.09,682.44h0s-2.81,1.49-2.81,1.49c-.19-.3-.34-.63-.42-.98-.06-.25-.1-.52-.1-.79,0-.43,.08-.85,.24-1.22,.16-.4,.4-.76,.7-1.05,.6,.65,2.33,2.51,2.39,2.56h0Z"
        />
        <path
          style={styles.four}
          d="M503.12,679.09h0s-1.03,2.55-1.03,2.55l-.73-2.65h0s.02,0,.03,0c.06-.01,.13-.03,.2-.04,.1-.02,.21-.03,.33-.03,.18,0,.35,0,.52,.03,.03,0,.06,0,.09,.01,.06,0,.11,.02,.17,.03,.15,.03,.29,.07,.44,.11Z"
        />
        <path
          style={styles.four}
          d="M505.41,682.26c0,.16-.01,.32-.03,.47,0,.03,0,.06-.02,.1,0,.06-.02,.11-.03,.17,0,.02,0,.04-.01,.06,0,.03-.02,.06-.02,.09,0,.03-.02,.05-.02,.08,0,.01,0,.02,0,.03,0,.03-.02,.07-.03,.1,0,0,0,.01,0,.02,0,.03-.02,.06-.03,.08,0,.01-.01,.03-.02,.04l-2.27-1.05,2.47-.75s0,.03,0,.04c0,.03,0,.06,.01,.09,0,.03,0,.07,.01,.1,0,.03,0,.05,0,.08,0,.03,0,.06,0,.09,0,.05,0,.1,0,.15Z"
        />
        <path
          style={styles.two}
          d="M502.73,685.44c-.13,.02-.25,.04-.39,.05-.04,0-.08,0-.11,0-.05,0-.1,0-.15,0-.05,0-.09,0-.13,0-.06,0-.11,0-.17-.01-.05,0-.1-.01-.14-.02,0,0-.02,0-.03,0-.03,0-.06,0-.08-.01-.13-.02-.25-.05-.38-.09-.03,0-.07-.02-.1-.03l.99-2.41,.69,2.54Z"
        />
        <path
          style={styles.four}
          d="M501.56,682.37l-2.7,.57c-.06-.25-.1-.52-.1-.79,0-.43,.08-.85,.24-1.22l2.56,1.45Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M510.37,677.22c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52-.24,.26-.53,.46-.86,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.09,.03-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.38-.22-.7-.53-.93-.9-.16-.24-.27-.52-.35-.8-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-1,.13-.33,.33-.62,.57-.86,.36-.36,.83-.62,1.35-.72,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.41-.02,.09,0,.19,0,.28,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.36,.09,.79,.27,1.42,.88,1.71,1.65,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M509.24,675.06c-.53,.77-1.06,1.54-1.59,2.31h0s-1.25-2.58-1.25-2.58c.15-.08,.37-.18,.65-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.27-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.13,.03,.24,.06,.35,.1,.36,.13,.62,.32,.75,.41Z"
        />
        <path
          style={styles.two}
          d="M508.81,679.63s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.09,.03-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.62,1.33-2.07,1.36-2.11h0s1.15,2.26,1.15,2.26Z"
        />
        <path
          style={styles.two}
          d="M510.37,677.22c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52l-2-1.66,2.55-1.08c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M507.66,677.36h0s-2.29,1.21-2.29,1.21c-.16-.24-.27-.52-.35-.8-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-1,.13-.33,.33-.62,.57-.86,.49,.53,1.91,2.05,1.95,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M508.5,674.63h0s-.84,2.08-.84,2.08l-.6-2.17h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.27-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.36,.09Z"
        />
        <path
          style={styles.four}
          d="M510.37,677.22c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04l-1.86-.86,2.02-.61s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M508.18,679.82c-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03l.81-1.97,.56,2.07Z"
        />
        <path
          style={styles.four}
          d="M507.22,677.31l-2.21,.46c-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-1l2.09,1.18Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M514.63,671.77c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52-.24,.25-.53,.46-.85,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.09,.03-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.38-.22-.7-.53-.93-.9-.16-.24-.27-.52-.34-.8-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-1,.13-.32,.33-.62,.57-.86,.36-.36,.83-.62,1.35-.72,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.41-.02,.09,0,.19,0,.28,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.09,.79,.27,1.42,.88,1.7,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M513.51,669.61c-.53,.77-1.06,1.54-1.58,2.31h0s-1.24-2.58-1.24-2.58c.15-.08,.37-.18,.65-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.27-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.13,.03,.24,.06,.35,.1,.36,.13,.62,.32,.75,.41Z"
        />
        <path
          style={styles.two}
          d="M513.07,674.18s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.09,.03-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.62,1.33-2.06,1.36-2.11h0s1.15,2.26,1.15,2.26Z"
        />
        <path
          style={styles.two}
          d="M514.63,671.77c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52l-2-1.66,2.54-1.07c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M511.92,671.91h0s-2.29,1.21-2.29,1.21c-.16-.24-.27-.52-.34-.8-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-1,.13-.32,.33-.62,.57-.86,.49,.53,1.9,2.05,1.95,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M512.76,669.18h0s-.84,2.08-.84,2.08l-.6-2.17h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.27-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.09Z"
        />
        <path
          style={styles.four}
          d="M514.63,671.77c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04l-1.85-.86,2.01-.6s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M512.45,674.37c-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03l.81-1.97,.56,2.07Z"
        />
        <path
          style={styles.four}
          d="M511.49,671.86l-2.2,.46c-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-1l2.09,1.19Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M518.42,666.83c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52-.24,.25-.53,.46-.85,.6-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.38-.22-.7-.53-.93-.9-.16-.24-.27-.52-.34-.8-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-1,.13-.32,.33-.62,.57-.86,.36-.36,.82-.62,1.35-.72,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.41-.02,.09,0,.19,0,.28,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.09,.79,.27,1.42,.88,1.7,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M517.29,664.66c-.53,.77-1.05,1.53-1.58,2.3h0s-1.24-2.58-1.24-2.58c.15-.08,.37-.18,.65-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.27-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.06,.35,.1,.36,.13,.62,.32,.74,.41Z"
        />
        <path
          style={styles.two}
          d="M516.86,669.23s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.61,1.33-2.06,1.36-2.11h0s1.15,2.26,1.15,2.26Z"
        />
        <path
          style={styles.two}
          d="M518.42,666.83c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52l-2-1.66,2.54-1.07c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M515.71,666.96h0s-2.29,1.2-2.29,1.2c-.16-.24-.27-.52-.34-.8-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-1,.13-.32,.33-.62,.57-.86,.49,.53,1.9,2.05,1.94,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M516.55,664.24h0s-.84,2.07-.84,2.07l-.6-2.17h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.27-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.09Z"
        />
        <path
          style={styles.four}
          d="M518.42,666.83c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04l-1.85-.86,2.01-.6s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M516.23,669.42c-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03l.81-1.96,.56,2.07Z"
        />
        <path
          style={styles.four}
          d="M515.28,666.91l-2.2,.46c-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-1l2.08,1.19Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M522.19,662.39c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52-.24,.25-.53,.46-.85,.6-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.38-.22-.7-.53-.93-.9-.16-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-1,.13-.32,.33-.61,.57-.86,.36-.36,.82-.62,1.34-.72,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.41-.02,.09,0,.19,0,.28,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.09,.79,.27,1.41,.88,1.7,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M521.07,660.23c-.53,.77-1.05,1.53-1.58,2.3h0s-1.24-2.58-1.24-2.58c.15-.08,.37-.18,.64-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.06,.35,.1,.36,.13,.62,.32,.74,.41Z"
        />
        <path
          style={styles.two}
          d="M520.63,664.79s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.61,1.32-2.06,1.35-2.1h0s1.14,2.26,1.14,2.26Z"
        />
        <path
          style={styles.two}
          d="M522.19,662.39c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52l-1.99-1.66,2.54-1.07c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M519.49,662.53h0s-2.28,1.2-2.28,1.2c-.16-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-1,.13-.32,.33-.61,.57-.86,.49,.53,1.9,2.05,1.94,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M520.33,659.8h0s-.83,2.07-.83,2.07l-.6-2.17h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.09Z"
        />
        <path
          style={styles.four}
          d="M522.19,662.39c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04l-1.85-.86,2.01-.6s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M520.01,664.98c-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03l.81-1.96,.56,2.07Z"
        />
        <path
          style={styles.four}
          d="M519.06,662.47l-2.2,.45c-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-1l2.08,1.19Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M525.02,657.94c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52-.24,.25-.53,.46-.85,.6-.04,.02-.07,.03-.11,.04-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.38-.22-.69-.53-.93-.9-.16-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-.99,.13-.32,.33-.61,.57-.86,.36-.36,.82-.61,1.34-.72,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.41-.02,.09,0,.19,0,.28,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1,.79,.27,1.41,.88,1.7,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M523.9,655.78c-.53,.76-1.05,1.53-1.58,2.3h0s-1.24-2.58-1.24-2.58c.15-.08,.37-.18,.64-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.06,.35,.11,.36,.14,.62,.32,.74,.41Z"
        />
        <path
          style={styles.two}
          d="M523.46,660.34s-.07,.03-.11,.04c-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.61,1.32-2.06,1.35-2.1h0s1.14,2.26,1.14,2.26Z"
        />
        <path
          style={styles.two}
          d="M525.02,657.94c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52l-1.99-1.66,2.53-1.06c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M522.32,658.07h0s-2.28,1.2-2.28,1.2c-.16-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-.99,.13-.32,.33-.61,.57-.86,.49,.53,1.89,2.05,1.94,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M523.16,655.35h0s-.83,2.07-.83,2.07l-.6-2.17h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1Z"
        />
        <path
          style={styles.four}
          d="M525.02,657.94c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04l-1.85-.86,2.01-.6s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M522.84,660.52c-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03l.81-1.96,.56,2.07Z"
        />
        <path
          style={styles.four}
          d="M521.89,658.02l-2.19,.45c-.05-.21-.08-.42-.08-.65,0-.35,.07-.69,.2-.99l2.08,1.19Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M527.85,653.5c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52-.24,.25-.53,.46-.85,.6-.04,.02-.07,.03-.11,.04-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.38-.22-.69-.53-.93-.9-.16-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.33-.61,.57-.85,.36-.36,.82-.61,1.34-.72,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.41-.02,.09,0,.19,0,.28,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1,.78,.27,1.41,.89,1.69,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M526.72,651.33c-.53,.76-1.05,1.53-1.57,2.29h0s-1.24-2.58-1.24-2.58c.15-.08,.37-.18,.64-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.07,.35,.11,.36,.14,.62,.32,.74,.41Z"
        />
        <path
          style={styles.two}
          d="M526.29,655.89s-.07,.03-.11,.04c-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.61,1.32-2.05,1.35-2.1h0s1.14,2.26,1.14,2.26Z"
        />
        <path
          style={styles.two}
          d="M527.85,653.5c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52l-1.99-1.66,2.53-1.06c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M525.15,653.63h0s-2.28,1.2-2.28,1.2c-.16-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.33-.61,.57-.85,.49,.53,1.89,2.05,1.94,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M525.99,650.91h0s-.83,2.07-.83,2.07l-.59-2.16h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1Z"
        />
        <path
          style={styles.four}
          d="M527.85,653.5c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04l-1.84-.86,2-.6s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M525.67,656.07c-.1,.02-.21,.03-.31,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.3-.08-.03,0-.05-.02-.08-.03l.8-1.96,.56,2.07Z"
        />
        <path
          style={styles.four}
          d="M524.72,653.57l-2.19,.45c-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99l2.07,1.19Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M529.73,648.53c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52-.24,.25-.53,.46-.85,.6-.04,.02-.07,.03-.11,.04-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.37-.23-.69-.53-.92-.9-.16-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.57-.85,.36-.36,.82-.61,1.34-.71,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.41-.02,.09,0,.18,0,.28,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1,.78,.27,1.41,.89,1.69,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M528.61,646.37c-.52,.76-1.05,1.53-1.57,2.29h0s-1.24-2.58-1.24-2.58c.15-.08,.37-.18,.64-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.07,.35,.11,.36,.14,.62,.32,.74,.42Z"
        />
        <path
          style={styles.two}
          d="M528.17,650.92s-.07,.03-.11,.04c-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.61,1.32-2.05,1.35-2.1h0s1.14,2.26,1.14,2.26Z"
        />
        <path
          style={styles.two}
          d="M529.73,648.53c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.1,.19-.23,.36-.38,.52l-1.99-1.66,2.53-1.06c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M527.03,648.66h0s-2.27,1.19-2.27,1.19c-.16-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.57-.85,.49,.53,1.89,2.05,1.93,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M527.87,645.94h0s-.83,2.07-.83,2.07l-.59-2.16h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1Z"
        />
        <path
          style={styles.four}
          d="M529.73,648.53c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.03,.07,0,.01,0,.03-.02,.04l-1.84-.86,2-.59s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M527.56,651.11c-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.3-.08-.03,0-.05-.02-.08-.03l.8-1.96,.55,2.07Z"
        />
        <path
          style={styles.four}
          d="M526.6,648.6l-2.19,.45c-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99l2.07,1.19Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M533.02,644.11c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.38,.52-.24,.25-.52,.46-.85,.6-.04,.02-.07,.03-.11,.04-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.04-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.37-.23-.69-.54-.92-.9-.16-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.57-.85,.36-.36,.82-.61,1.34-.71,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.41-.02,.09,0,.18,0,.28,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1,.78,.27,1.41,.89,1.69,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M531.9,641.95c-.52,.76-1.05,1.53-1.57,2.29h0s-1.23-2.58-1.23-2.58c.15-.08,.37-.18,.64-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.07,.35,.11,.36,.14,.62,.32,.74,.42Z"
        />
        <path
          style={styles.two}
          d="M531.47,646.5s-.07,.03-.11,.04c-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.04-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.61,1.32-2.05,1.35-2.09h0s1.14,2.26,1.14,2.26Z"
        />
        <path
          style={styles.two}
          d="M533.02,644.11c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.38,.52l-1.99-1.66,2.52-1.05c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M530.33,644.23h0s-2.27,1.19-2.27,1.19c-.16-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.57-.85,.49,.53,1.89,2.05,1.93,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M531.16,641.52h0s-.83,2.06-.83,2.06l-.59-2.16h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1Z"
        />
        <path
          style={styles.four}
          d="M533.02,644.11c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04l-1.84-.87,2-.59s0,.02,0,.04c0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M530.85,646.68c-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.04-.3-.08-.03,0-.05-.02-.08-.03l.8-1.95,.55,2.07Z"
        />
        <path
          style={styles.four}
          d="M529.9,644.18l-2.19,.44c-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99l2.07,1.19Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M534.89,639.66c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.38,.52-.24,.25-.52,.46-.85,.6-.04,.02-.07,.03-.11,.04-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.37-.23-.69-.54-.92-.9-.15-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.57-.85,.36-.36,.82-.61,1.34-.71,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.4-.02,.09,0,.18,0,.27,.02,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1,.78,.27,1.41,.89,1.69,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M533.78,637.49c-.52,.76-1.05,1.52-1.57,2.29h0s-1.23-2.58-1.23-2.58c.15-.08,.37-.18,.64-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.42,.02,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.07,.35,.11,.36,.14,.62,.32,.74,.42Z"
        />
        <path
          style={styles.two}
          d="M533.34,642.04s-.07,.03-.11,.04c-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.23-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.61,1.32-2.05,1.35-2.09h0s1.14,2.26,1.14,2.26Z"
        />
        <path
          style={styles.two}
          d="M534.89,639.66c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.38,.52l-1.98-1.67,2.52-1.05c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M532.21,639.78h0s-2.27,1.19-2.27,1.19c-.15-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.57-.85,.49,.53,1.89,2.05,1.93,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M533.04,637.07h0s-.83,2.06-.83,2.06l-.59-2.16h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.42,.02,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1Z"
        />
        <path
          style={styles.four}
          d="M534.89,639.66c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04l-1.84-.87,2-.59s0,.02,0,.04c0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M532.73,642.23c-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03l.8-1.95,.55,2.07Z"
        />
        <path
          style={styles.four}
          d="M531.78,639.72l-2.18,.44c-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99l2.07,1.19Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M537.71,634.73c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.38,.51-.24,.25-.52,.45-.85,.6-.04,.02-.07,.03-.11,.04-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.22-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.37-.23-.69-.54-.92-.9-.15-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.57-.85,.36-.36,.82-.61,1.34-.71,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.4-.02,.09,0,.18,.01,.27,.02,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1,.78,.27,1.4,.89,1.69,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M536.59,632.57c-.52,.76-1.05,1.52-1.57,2.28h0s-1.23-2.58-1.23-2.58c.15-.08,.37-.18,.64-.23,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.42,.02,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.07,.35,.11,.36,.14,.62,.32,.74,.42Z"
        />
        <path
          style={styles.two}
          d="M536.16,637.11s-.07,.03-.11,.04c-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.22-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.61,1.32-2.04,1.35-2.09h0s1.14,2.26,1.14,2.26Z"
        />
        <path
          style={styles.two}
          d="M537.71,634.73c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.38,.51l-1.98-1.67,2.52-1.05c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M535.02,634.85h0s-2.27,1.18-2.27,1.18c-.15-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.57-.85,.49,.53,1.88,2.06,1.93,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M535.85,632.14h0s-.83,2.06-.83,2.06l-.59-2.16h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.42,.02,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1Z"
        />
        <path
          style={styles.four}
          d="M537.71,634.73c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04l-1.84-.87,2-.59s0,.02,0,.04c0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M535.54,637.3c-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03l.8-1.95,.55,2.07Z"
        />
        <path
          style={styles.four}
          d="M534.6,634.79l-2.18,.44c-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99l2.07,1.2Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M539.58,629.79c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.38,.51-.24,.25-.52,.45-.84,.59-.04,.02-.07,.03-.11,.04-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.22-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.37-.23-.69-.54-.92-.91-.15-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.56-.85,.36-.36,.82-.61,1.34-.71,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.4-.02,.09,0,.18,.01,.27,.02,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1,.78,.27,1.4,.89,1.69,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M538.46,627.62c-.52,.76-1.04,1.52-1.57,2.28h0s-1.23-2.58-1.23-2.58c.15-.08,.37-.17,.64-.23,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.42,.03,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.07,.35,.11,.36,.14,.62,.32,.74,.42Z"
        />
        <path
          style={styles.two}
          d="M538.03,632.17s-.07,.03-.11,.04c-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.22-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.61,1.31-2.04,1.34-2.09h0s1.14,2.26,1.14,2.26Z"
        />
        <path
          style={styles.two}
          d="M539.58,629.79c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.38,.51l-1.98-1.67,2.52-1.05c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M536.9,629.9h0s-2.27,1.18-2.27,1.18c-.15-.24-.27-.51-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.56-.85,.49,.53,1.88,2.06,1.93,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M537.73,627.2h0s-.83,2.06-.83,2.06l-.59-2.16h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.42,.03,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1Z"
        />
        <path
          style={styles.four}
          d="M539.58,629.79c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04l-1.83-.87,1.99-.58s0,.02,0,.04c0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M537.42,632.35c-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03l.8-1.95,.55,2.07Z"
        />
        <path
          style={styles.four}
          d="M536.47,629.85l-2.18,.44c-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99l2.06,1.2Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M541.45,624.85c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.38,.51-.24,.25-.52,.45-.84,.59-.04,.02-.07,.03-.11,.04-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.22-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.37-.23-.69-.54-.92-.91-.15-.24-.27-.52-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.56-.85,.36-.36,.82-.61,1.33-.71,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.4-.02,.09,0,.18,.01,.27,.02,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1,.78,.28,1.4,.89,1.68,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M540.34,622.68c-.52,.76-1.04,1.52-1.56,2.28h0s-1.23-2.58-1.23-2.58c.15-.08,.37-.17,.64-.23,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.42,.03,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.07,.35,.11,.36,.14,.62,.32,.74,.42Z"
        />
        <path
          style={styles.two}
          d="M539.91,627.22s-.07,.03-.11,.04c-.05,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.22-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.61,1.31-2.04,1.34-2.08h0s1.13,2.26,1.13,2.26Z"
        />
        <path
          style={styles.two}
          d="M541.45,624.85c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.38,.51l-1.98-1.67,2.52-1.04c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M538.77,624.96h0s-2.26,1.18-2.26,1.18c-.15-.24-.27-.52-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.56-.85,.49,.53,1.88,2.06,1.92,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M539.6,622.25h0s-.83,2.06-.83,2.06l-.59-2.16h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.42,.03,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.1Z"
        />
        <path
          style={styles.four}
          d="M541.45,624.85c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04l-1.83-.87,1.99-.58s0,.02,0,.04c0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M539.29,627.41c-.1,.02-.21,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03l.8-1.95,.55,2.07Z"
        />
        <path
          style={styles.four}
          d="M538.35,624.9l-2.18,.43c-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99l2.06,1.2Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M543.33,619.91c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.38,.51-.24,.25-.52,.45-.84,.59-.04,.02-.07,.03-.11,.04-.04,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.2,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.11-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.22-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.37-.23-.69-.54-.92-.91-.15-.24-.27-.52-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.56-.85,.36-.36,.82-.61,1.33-.7,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.4-.02,.09,0,.18,.01,.27,.02,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.06,.35,.1,.78,.28,1.4,.89,1.68,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M542.21,617.75c-.52,.76-1.04,1.52-1.56,2.27h0s-1.23-2.58-1.23-2.58c.15-.08,.37-.17,.64-.23,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.41,.03,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.07,.35,.11,.36,.14,.62,.32,.74,.42Z"
        />
        <path
          style={styles.two}
          d="M541.78,622.28s-.07,.03-.11,.04c-.04,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.2,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.11-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.22-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.61,1.31-2.04,1.34-2.08h0s1.13,2.26,1.13,2.26Z"
        />
        <path
          style={styles.two}
          d="M543.33,619.91c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.38,.51l-1.98-1.67,2.51-1.04c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M540.65,620.02h0s-2.26,1.18-2.26,1.18c-.15-.24-.27-.52-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.56-.85,.49,.53,1.88,2.06,1.92,2.11h0Z"
        />
        <path
          style={styles.four}
          d="M541.48,617.32h0s-.83,2.06-.83,2.06l-.59-2.16h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.41,.03,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.06,.35,.1Z"
        />
        <path
          style={styles.four}
          d="M543.33,619.91c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04l-1.83-.87,1.99-.58s0,.02,0,.04c0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M541.16,622.47c-.1,.02-.2,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.11-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03l.8-1.94,.55,2.07Z"
        />
        <path
          style={styles.four}
          d="M540.22,619.96l-2.18,.43c-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99l2.06,1.2Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M545.19,614.98c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.37,.51-.24,.25-.52,.45-.84,.59-.04,.02-.07,.03-.11,.04-.04,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.2,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.11-.02,0,0-.01,0-.02,0-.02,0-.04,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.22-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.37-.23-.69-.54-.92-.91-.15-.24-.27-.52-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.56-.85,.36-.36,.82-.6,1.33-.7,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.4-.02,.09,0,.18,.01,.27,.02,.02,0,.05,0,.07,.01,.04,0,.09,.02,.13,.03,.12,.03,.24,.06,.35,.1,.78,.28,1.4,.89,1.68,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M544.08,612.81c-.52,.76-1.04,1.51-1.56,2.27h0s-1.23-2.58-1.23-2.58c.15-.08,.37-.17,.64-.23,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.41,.03,.02,0,.05,0,.07,.01,.04,0,.09,.02,.13,.03,.12,.03,.24,.07,.35,.11,.36,.14,.62,.32,.74,.42Z"
        />
        <path
          style={styles.two}
          d="M543.65,617.35s-.07,.03-.11,.04c-.04,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.2,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.11-.02,0,0-.01,0-.02,0-.02,0-.04,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.22-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.61,1.31-2.03,1.34-2.08h0s1.13,2.26,1.13,2.26Z"
        />
        <path
          style={styles.two}
          d="M545.19,614.98c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.02,.05-.04,.07-.1,.19-.23,.36-.37,.51l-1.98-1.67,2.51-1.04c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M542.52,615.08h0s-2.26,1.17-2.26,1.17c-.15-.24-.27-.52-.34-.8-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99,.13-.32,.32-.61,.56-.85,.48,.53,1.88,2.06,1.92,2.11h0Z"
        />
        <path
          style={styles.four}
          d="M543.35,612.38h0s-.83,2.05-.83,2.05l-.59-2.16h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.41,.03,.02,0,.05,0,.07,.01,.04,0,.09,.02,.13,.03,.12,.03,.24,.06,.35,.1Z"
        />
        <path
          style={styles.four}
          d="M545.19,614.98c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.07,0,.01,0,.03-.02,.04l-1.83-.87,1.99-.58s0,.02,0,.04c0,.02,0,.05,.01,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M543.04,617.53c-.1,.02-.2,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.11-.02,0,0-.01,0-.02,0-.02,0-.04,0-.07-.01-.1-.02-.2-.05-.3-.08-.03,0-.05-.02-.08-.03l.8-1.94,.55,2.07Z"
        />
        <path
          style={styles.four}
          d="M542.09,615.02l-2.17,.43c-.05-.21-.08-.42-.08-.64,0-.35,.07-.69,.19-.99l2.06,1.2Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M546.6,609.53c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.07,0,.01,0,.03-.01,.04-.02,.07-.05,.13-.08,.2-.01,.02-.02,.05-.03,.07-.09,.19-.21,.36-.34,.51-.22,.25-.48,.45-.77,.59-.03,.02-.06,.03-.1,.04-.04,.02-.08,.03-.12,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.08,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.28,.03-.03,0-.06,0-.08,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.08,0-.13-.01-.03,0-.07-.01-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.19-.05-.28-.08-.02,0-.05-.02-.07-.03-.02,0-.04-.02-.06-.02-.1-.04-.2-.09-.3-.15-.02,0-.04-.02-.05-.03-.02-.01-.03-.02-.05-.03-.34-.23-.63-.54-.84-.91-.14-.24-.25-.51-.31-.8-.05-.21-.07-.42-.07-.64,0-.35,.06-.69,.18-.99,.12-.32,.29-.61,.51-.85,.33-.36,.74-.61,1.22-.7,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.12-.02,.24-.02,.37-.02,.08,0,.17,.01,.25,.02,.02,0,.04,0,.07,.01,.04,0,.08,.02,.12,.03,.11,.02,.22,.06,.32,.1,.71,.28,1.28,.89,1.54,1.66,.04,.12,.07,.24,.1,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,0,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M545.58,607.37c-.48,.76-.95,1.52-1.43,2.27h0s-1.12-2.58-1.12-2.58c.14-.08,.33-.17,.58-.23,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.13,0,.26,0,.38,.03,.02,0,.04,0,.07,.01,.04,0,.08,.02,.12,.03,.11,.03,.22,.07,.32,.11,.33,.14,.56,.32,.67,.42Z"
        />
        <path
          style={styles.two}
          d="M545.19,611.91s-.06,.03-.1,.04c-.04,.02-.08,.03-.12,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.08,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.28,.03-.03,0-.06,0-.08,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.08,0-.13-.01-.03,0-.07-.01-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.19-.05-.28-.08-.02,0-.05-.02-.07-.03-.02,0-.04-.02-.06-.02-.1-.04-.2-.09-.3-.15-.02,0-.04-.02-.05-.03-.02-.01-.03-.02-.05-.03,.32-.61,1.2-2.04,1.22-2.08h0s1.03,2.26,1.03,2.26Z"
        />
        <path
          style={styles.two}
          d="M546.6,609.53c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.07,0,.01,0,.03-.01,.04-.02,.07-.05,.13-.08,.2-.01,.02-.02,.05-.03,.07-.09,.19-.21,.36-.34,.51l-1.8-1.67,2.29-1.04c.04,.12,.07,.24,.1,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,0,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M544.16,609.65h0s-2.06,1.18-2.06,1.18c-.14-.24-.25-.51-.31-.8-.05-.21-.07-.42-.07-.64,0-.35,.06-.69,.18-.99,.12-.32,.29-.61,.51-.85,.44,.53,1.71,2.06,1.75,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M544.91,606.94h0s-.75,2.05-.75,2.05l-.54-2.16h0s.02,0,.02,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.13,0,.26,0,.38,.03,.02,0,.04,0,.07,.01,.04,0,.08,.02,.12,.03,.11,.02,.22,.06,.32,.1Z"
        />
        <path
          style={styles.four}
          d="M546.6,609.53c0,.13,0,.26-.03,.38,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.07,0,.01,0,.03-.01,.04l-1.67-.87,1.82-.58s0,.02,0,.04c0,.02,0,.05,0,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M544.63,612.09c-.09,.02-.19,.03-.28,.03-.03,0-.06,0-.08,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.08,0-.13-.01-.03,0-.07-.01-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.19-.05-.28-.08-.02,0-.05-.02-.07-.03l.73-1.94,.5,2.06Z"
        />
        <path
          style={styles.four}
          d="M543.77,609.59l-1.99,.43c-.05-.21-.07-.42-.07-.64,0-.35,.06-.69,.18-.99l1.88,1.2Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M547.71,604.08c0,.13,0,.26-.02,.38,0,.03,0,.05-.01,.08,0,.05-.01,.09-.02,.14,0,.02,0,.03,0,.05,0,.03-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.07,0,.01,0,.03-.01,.04-.02,.07-.05,.13-.07,.2-.01,.02-.02,.05-.03,.07-.08,.19-.19,.36-.31,.51-.19,.25-.43,.45-.69,.59-.03,.02-.06,.03-.09,.04-.04,.02-.07,.03-.11,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.11,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.08,.02-.17,.03-.25,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06-.01-.09-.02,0,0-.01,0-.02,0-.02,0-.04,0-.05-.01-.08-.02-.17-.05-.25-.08-.02,0-.04-.02-.07-.03-.02,0-.04-.02-.05-.02-.09-.04-.18-.09-.27-.15-.02,0-.03-.02-.05-.03-.02-.01-.03-.02-.05-.03-.3-.23-.56-.54-.75-.9-.13-.24-.22-.51-.28-.8-.04-.21-.06-.42-.06-.64,0-.35,.06-.69,.16-.99,.11-.32,.26-.61,.46-.85,.29-.36,.67-.61,1.09-.71,0,0,.01,0,.02,0,.04-.01,.09-.02,.13-.03,.11-.02,.22-.02,.33-.02,.08,0,.15,.01,.22,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.03,.1,.02,.19,.06,.29,.1,.64,.27,1.15,.89,1.38,1.66,.04,.12,.06,.24,.09,.37,0,.03,.01,.06,.01,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,0,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M546.8,601.93c-.43,.76-.85,1.52-1.28,2.28h0s-1.01-2.57-1.01-2.57c.12-.08,.3-.17,.52-.23,0,0,.01,0,.02,0,.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.21-.02,.12,0,.23,0,.34,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.03,.1,.03,.2,.07,.28,.11,.29,.14,.5,.32,.6,.42Z"
        />
        <path
          style={styles.two}
          d="M546.45,606.46s-.06,.03-.09,.04c-.04,.02-.07,.03-.11,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.11,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.08,.02-.17,.03-.25,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06-.01-.09-.02,0,0-.01,0-.02,0-.02,0-.04,0-.05-.01-.08-.02-.17-.05-.25-.08-.02,0-.04-.02-.07-.03-.02,0-.04-.02-.05-.02-.09-.04-.18-.09-.27-.15-.02,0-.03-.02-.05-.03-.02-.01-.03-.02-.05-.03,.29-.61,1.07-2.04,1.1-2.08h0s.93,2.26,.93,2.26Z"
        />
        <path
          style={styles.two}
          d="M547.71,604.08c0,.13,0,.26-.02,.38,0,.03,0,.05-.01,.08,0,.05-.01,.09-.02,.14,0,.02,0,.03,0,.05,0,.03-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.07,0,.01,0,.03-.01,.04-.02,.07-.05,.13-.07,.2-.01,.02-.02,.05-.03,.07-.08,.19-.19,.36-.31,.51l-1.62-1.66,2.06-1.05c.04,.12,.06,.24,.09,.37,0,.03,.01,.06,.01,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,0,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M545.52,604.2h0s-1.85,1.18-1.85,1.18c-.13-.24-.22-.51-.28-.8-.04-.21-.06-.42-.06-.64,0-.35,.06-.69,.16-.99,.11-.32,.26-.61,.46-.85,.4,.53,1.54,2.05,1.57,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M546.2,601.5h0s-.68,2.06-.68,2.06l-.48-2.16h0s.01,0,.02,0c.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.21-.02,.12,0,.23,0,.34,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.03,.1,.02,.19,.06,.29,.1Z"
        />
        <path
          style={styles.four}
          d="M547.71,604.08c0,.13,0,.26-.02,.38,0,.03,0,.05-.01,.08,0,.05-.01,.09-.02,.14,0,.02,0,.03,0,.05,0,.03-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.07,0,.01,0,.03-.01,.04l-1.5-.87,1.63-.58s0,.02,0,.04c0,.02,0,.05,0,.07,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M545.95,606.64c-.08,.02-.17,.03-.25,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06-.01-.09-.02,0,0-.01,0-.02,0-.02,0-.04,0-.05-.01-.08-.02-.17-.05-.25-.08-.02,0-.04-.02-.07-.03l.65-1.94,.45,2.06Z"
        />
        <path
          style={styles.four}
          d="M545.17,604.14l-1.78,.44c-.04-.21-.06-.42-.06-.64,0-.35,.06-.69,.16-.99l1.69,1.19Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M547.97,599.22c0,.1,0,.2-.02,.29,0,.02,0,.04,0,.06,0,.04-.01,.07-.02,.1,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03-.01,.05,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,.01,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.1-.06,.15,0,.02-.02,.04-.03,.06-.07,.14-.16,.28-.26,.4-.17,.19-.37,.35-.59,.46-.02,.01-.05,.02-.08,.03-.03,.01-.06,.03-.1,.04-.01,0-.02,0-.04,.01-.02,0-.04,.01-.06,.02-.03,0-.07,.02-.1,.03-.02,0-.04,0-.06,.01,0,0,0,0,0,0-.07,.01-.14,.02-.22,.02-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.03,0-.05,0-.08,0-.03,0-.06,0-.1-.01-.03,0-.05,0-.08-.01,0,0,0,0-.01,0-.02,0-.03,0-.05,0-.07-.02-.14-.04-.21-.06-.02,0-.04-.01-.06-.02-.02,0-.03-.01-.05-.02-.08-.03-.16-.07-.23-.12-.01,0-.03-.02-.04-.02-.01,0-.03-.02-.04-.03-.26-.18-.48-.42-.65-.7-.11-.19-.19-.4-.24-.62-.04-.16-.06-.33-.06-.5,0-.27,.05-.53,.14-.76,.09-.25,.23-.47,.4-.65,.25-.27,.57-.47,.94-.54,0,0,.01,0,.02,0,.04,0,.07-.02,.11-.02,.09-.01,.19-.02,.28-.01,.07,0,.13,0,.19,.02,.02,0,.03,0,.05,0,.03,0,.06,.01,.09,.02,.08,.02,.17,.05,.25,.08,.55,.21,.99,.69,1.18,1.28,.03,.09,.06,.19,.07,.28,0,.02,0,.04,.01,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M547.18,597.55c-.37,.58-.73,1.17-1.1,1.75h0s-.86-1.99-.86-1.99c.11-.06,.26-.13,.45-.18,0,0,.01,0,.02,0,.04,0,.07-.02,.11-.02,.06,0,.12-.01,.18-.02,.1,0,.2,0,.29,.02,.02,0,.03,0,.05,0,.03,0,.06,.01,.09,.02,.09,.02,.17,.05,.24,.08,.25,.11,.43,.25,.52,.32Z"
        />
        <path
          style={styles.two}
          d="M546.88,601.04s-.05,.02-.08,.03c-.03,.01-.06,.03-.1,.04-.01,0-.02,0-.04,.01-.02,0-.04,.01-.06,.02-.03,0-.07,.02-.1,.03-.02,0-.04,0-.06,.01,0,0,0,0,0,0-.07,.01-.14,.02-.22,.02-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.03,0-.05,0-.08,0-.03,0-.06,0-.1-.01-.03,0-.05,0-.08-.01,0,0,0,0-.01,0-.02,0-.03,0-.05,0-.07-.02-.14-.04-.21-.06-.02,0-.04-.01-.06-.02-.02,0-.03-.01-.05-.02-.08-.03-.16-.07-.23-.12-.01,0-.03-.02-.04-.02-.01,0-.03-.02-.04-.03,.25-.47,.92-1.57,.94-1.6h0s.8,1.75,.8,1.75Z"
        />
        <path
          style={styles.two}
          d="M547.97,599.22c0,.1,0,.2-.02,.29,0,.02,0,.04,0,.06,0,.04-.01,.07-.02,.1,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03-.01,.05,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,.01,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.1-.06,.15,0,.02-.02,.04-.03,.06-.07,.14-.16,.28-.26,.4l-1.39-1.29,1.77-.8c.03,.09,.06,.19,.07,.28,0,.02,0,.04,.01,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M546.08,599.3h0s-1.59,.9-1.59,.9c-.11-.19-.19-.4-.24-.62-.04-.16-.06-.33-.06-.5,0-.27,.05-.53,.14-.76,.09-.25,.23-.47,.4-.65,.34,.41,1.32,1.59,1.35,1.63h0Z"
        />
        <path
          style={styles.four}
          d="M546.67,597.21h0s-.58,1.58-.58,1.58l-.42-1.67h0s.01,0,.02,0c.04,0,.07-.02,.11-.02,.06,0,.12-.01,.18-.02,.1,0,.2,0,.29,.02,.02,0,.03,0,.05,0,.03,0,.06,.01,.09,.02,.08,.02,.17,.05,.25,.08Z"
        />
        <path
          style={styles.four}
          d="M547.97,599.22c0,.1,0,.2-.02,.29,0,.02,0,.04,0,.06,0,.04-.01,.07-.02,.1,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03-.01,.05,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,.01,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03l-1.29-.67,1.4-.44s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M546.45,601.18c-.07,.01-.14,.02-.22,.02-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.03,0-.05,0-.08,0-.03,0-.06,0-.1-.01-.03,0-.05,0-.08-.01,0,0,0,0-.01,0-.02,0-.03,0-.05,0-.07-.02-.14-.04-.21-.06-.02,0-.04-.01-.06-.02l.56-1.5,.39,1.59Z"
        />
        <path
          style={styles.four}
          d="M545.78,599.25l-1.53,.33c-.04-.16-.06-.33-.06-.5,0-.27,.05-.53,.14-.76l1.45,.93Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M547.97,595.23c0,.1,0,.2-.02,.29,0,.02,0,.04,0,.06,0,.04-.01,.07-.02,.1,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03-.01,.05,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,.01,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.1-.06,.15,0,.02-.02,.04-.03,.05-.07,.14-.16,.28-.26,.39-.17,.19-.37,.35-.59,.46-.02,.01-.05,.02-.08,.03-.03,.01-.06,.03-.1,.04-.01,0-.02,0-.04,.01-.02,0-.04,.01-.06,.02-.03,0-.07,.02-.1,.02-.02,0-.04,0-.06,.01,0,0,0,0,0,0-.07,.01-.14,.02-.22,.02-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.03,0-.05,0-.08,0-.03,0-.06,0-.1-.01-.03,0-.05,0-.08-.01,0,0,0,0-.01,0-.02,0-.03,0-.05,0-.07-.02-.14-.04-.21-.06-.02,0-.04-.01-.06-.02-.02,0-.03-.01-.05-.02-.08-.03-.16-.07-.23-.12-.01,0-.03-.02-.04-.02-.01,0-.03-.02-.04-.03-.26-.18-.48-.42-.65-.7-.11-.19-.19-.4-.24-.62-.04-.16-.06-.33-.06-.5,0-.27,.05-.53,.14-.76,.09-.25,.23-.47,.4-.65,.25-.27,.57-.47,.94-.54,0,0,.01,0,.02,0,.04,0,.07-.02,.11-.02,.09-.01,.19-.02,.28-.01,.07,0,.13,0,.19,.02,.02,0,.03,0,.05,0,.03,0,.06,.01,.09,.02,.08,.02,.17,.05,.25,.08,.55,.21,.99,.69,1.18,1.29,.03,.09,.06,.19,.07,.28,0,.02,0,.04,.01,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M547.18,593.56c-.37,.58-.73,1.17-1.1,1.75h0s-.86-1.99-.86-1.99c.11-.06,.26-.13,.45-.18,0,0,.01,0,.02,0,.04,0,.07-.02,.11-.02,.06,0,.12-.01,.18-.02,.1,0,.2,0,.29,.02,.02,0,.03,0,.05,0,.03,0,.06,.01,.09,.02,.09,.02,.17,.05,.24,.08,.25,.11,.43,.25,.52,.32Z"
        />
        <path
          style={styles.two}
          d="M546.88,597.06s-.05,.02-.08,.03c-.03,.01-.06,.03-.1,.04-.01,0-.02,0-.04,.01-.02,0-.04,.01-.06,.02-.03,0-.07,.02-.1,.02-.02,0-.04,0-.06,.01,0,0,0,0,0,0-.07,.01-.14,.02-.22,.02-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.03,0-.05,0-.08,0-.03,0-.06,0-.1-.01-.03,0-.05,0-.08-.01,0,0,0,0-.01,0-.02,0-.03,0-.05,0-.07-.02-.14-.04-.21-.06-.02,0-.04-.01-.06-.02-.02,0-.03-.01-.05-.02-.08-.03-.16-.07-.23-.12-.01,0-.03-.02-.04-.02-.01,0-.03-.02-.04-.03,.25-.47,.92-1.57,.94-1.6h0s.8,1.75,.8,1.75Z"
        />
        <path
          style={styles.two}
          d="M547.97,595.23c0,.1,0,.2-.02,.29,0,.02,0,.04,0,.06,0,.04-.01,.07-.02,.1,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03-.01,.05,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,.01,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.1-.06,.15,0,.02-.02,.04-.03,.05-.07,.14-.16,.28-.26,.39l-1.39-1.29,1.77-.8c.03,.09,.06,.19,.07,.28,0,.02,0,.04,.01,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M546.08,595.31h0s-1.59,.9-1.59,.9c-.11-.19-.19-.4-.24-.62-.04-.16-.06-.33-.06-.5,0-.27,.05-.53,.14-.76,.09-.25,.23-.47,.4-.65,.34,.41,1.32,1.59,1.35,1.63h0Z"
        />
        <path
          style={styles.four}
          d="M546.67,593.23h0s-.58,1.58-.58,1.58l-.42-1.67h0s.01,0,.02,0c.04,0,.07-.02,.11-.02,.06,0,.12-.01,.18-.02,.1,0,.2,0,.29,.02,.02,0,.03,0,.05,0,.03,0,.06,.01,.09,.02,.08,.02,.17,.05,.25,.08Z"
        />
        <path
          style={styles.four}
          d="M547.97,595.23c0,.1,0,.2-.02,.29,0,.02,0,.04,0,.06,0,.04-.01,.07-.02,.1,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03-.01,.05,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,.01,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03l-1.29-.68,1.4-.44s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M546.45,597.19c-.07,.01-.14,.02-.22,.02-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.03,0-.05,0-.08,0-.03,0-.06,0-.1-.01-.03,0-.05,0-.08-.01,0,0,0,0-.01,0-.02,0-.03,0-.05,0-.07-.02-.14-.04-.21-.06-.02,0-.04-.01-.06-.02l.56-1.5,.39,1.59Z"
        />
        <path
          style={styles.four}
          d="M545.78,595.26l-1.53,.33c-.04-.16-.06-.33-.06-.5,0-.27,.05-.53,.14-.76l1.45,.93Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M547.5,591.22c0,.1,0,.2-.01,.29,0,.02,0,.04,0,.06,0,.04,0,.07-.01,.11,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03,0,.05,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,.01,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.01,.05-.03,.1-.05,.15,0,.02-.01,.04-.02,.06-.05,.14-.12,.28-.19,.4-.12,.19-.27,.35-.44,.46-.02,.01-.04,.02-.06,.03-.02,.01-.05,.03-.07,.04,0,0-.02,0-.03,.01-.01,0-.03,.01-.04,.02-.02,.01-.05,.02-.07,.03-.01,0-.03,0-.04,.01,0,0,0,0,0,0-.05,.01-.11,.02-.16,.03-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.04,0-.06,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06-.01,0,0,0,0-.01,0-.01,0-.02,0-.03,0-.05-.01-.11-.03-.16-.06-.01,0-.03-.01-.04-.02-.01,0-.02-.01-.03-.02-.06-.03-.12-.07-.17-.12-.01,0-.02-.02-.03-.02,0,0-.02-.02-.03-.03-.19-.17-.36-.41-.47-.69-.08-.19-.14-.4-.18-.62-.03-.16-.04-.33-.04-.5,0-.27,.04-.53,.1-.76,.07-.25,.17-.47,.29-.66,.18-.28,.42-.47,.69-.55,0,0,0,0,.01,0,.03,0,.05-.02,.08-.02,.07-.01,.14-.02,.21-.02,.05,0,.09,0,.14,.02,.01,0,.03,0,.04,0,.02,0,.05,.01,.07,.02,.06,.02,.12,.04,.18,.07,.4,.21,.72,.68,.87,1.28,.02,.09,.04,.19,.05,.28,0,.02,0,.04,0,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M546.92,589.56c-.27,.59-.54,1.17-.81,1.76h0s-.63-1.98-.63-1.98c.08-.06,.19-.14,.33-.18,0,0,0,0,.01,0,.03,0,.05-.02,.08-.02,.04,0,.09-.02,.14-.02,.08,0,.15,0,.21,.02,.01,0,.03,0,.04,0,.02,0,.05,.01,.07,.02,.06,.02,.12,.05,.18,.08,.19,.1,.32,.25,.38,.32Z"
        />
        <path
          style={styles.two}
          d="M546.7,593.06s-.04,.02-.06,.03c-.02,.01-.05,.03-.07,.04,0,0-.02,0-.03,.01-.01,0-.03,.01-.04,.02-.02,.01-.05,.02-.07,.03-.01,0-.03,0-.04,.01,0,0,0,0,0,0-.05,.01-.11,.02-.16,.03-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.04,0-.06,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06-.01,0,0,0,0-.01,0-.01,0-.02,0-.03,0-.05-.01-.11-.03-.16-.06-.01,0-.03-.01-.04-.02-.01,0-.02-.01-.03-.02-.06-.03-.12-.07-.17-.12-.01,0-.02-.02-.03-.02,0,0-.02-.02-.03-.03,.18-.47,.68-1.58,.69-1.61h0s.59,1.74,.59,1.74Z"
        />
        <path
          style={styles.two}
          d="M547.5,591.22c0,.1,0,.2-.01,.29,0,.02,0,.04,0,.06,0,.04,0,.07-.01,.11,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03,0,.05,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,.01,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.01,.05-.03,.1-.05,.15,0,.02-.01,.04-.02,.06-.05,.14-.12,.28-.19,.4l-1.02-1.28,1.3-.81c.02,.09,.04,.19,.05,.28,0,.02,0,.04,0,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M546.12,591.32h0s-1.17,.92-1.17,.92c-.08-.19-.14-.4-.18-.62-.03-.16-.04-.33-.04-.5,0-.27,.04-.53,.1-.76,.07-.25,.17-.47,.29-.66,.25,.41,.97,1.58,.99,1.62h0Z"
        />
        <path
          style={styles.four}
          d="M546.55,589.23h0s-.43,1.59-.43,1.59l-.31-1.66h0s0,0,.01,0c.03,0,.05-.02,.08-.02,.04,0,.09-.02,.14-.02,.08,0,.15,0,.21,.02,.01,0,.03,0,.04,0,.02,0,.05,.01,.07,.02,.06,.02,.12,.04,.18,.07Z"
        />
        <path
          style={styles.four}
          d="M547.5,591.22c0,.1,0,.2-.01,.29,0,.02,0,.04,0,.06,0,.04,0,.07-.01,.11,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03,0,.05,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,.01,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03l-.95-.66,1.03-.46s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M546.38,593.2c-.05,.01-.11,.02-.16,.03-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.04,0-.06,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06-.01,0,0,0,0-.01,0-.01,0-.02,0-.03,0-.05-.01-.11-.03-.16-.06-.01,0-.03-.01-.04-.02l.41-1.5,.28,1.59Z"
        />
        <path
          style={styles.four}
          d="M545.9,591.28l-1.12,.34c-.03-.16-.04-.33-.04-.5,0-.27,.04-.53,.1-.76l1.06,.92Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M546.42,589.19c0,.1,0,.2-.01,.29,0,.02,0,.04,0,.06,0,.04,0,.07-.01,.11,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03,0,.05,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,.01,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.01,.05-.03,.1-.05,.15,0,.02-.01,.04-.02,.06-.05,.14-.12,.28-.19,.4-.12,.19-.27,.35-.44,.46-.02,.01-.04,.02-.06,.03-.02,.01-.05,.03-.07,.04,0,0-.02,0-.03,.01-.01,0-.03,.01-.04,.02-.02,.01-.05,.02-.07,.03-.01,0-.03,0-.04,.01,0,0,0,0,0,0-.05,.01-.11,.02-.16,.03-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.04,0-.06,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06-.01,0,0,0,0-.01,0-.01,0-.02,0-.03,0-.05-.01-.11-.03-.16-.06-.01,0-.03-.01-.04-.02-.01,0-.02-.01-.03-.02-.06-.03-.12-.07-.17-.12-.01,0-.02-.02-.03-.02,0,0-.02-.02-.03-.03-.19-.17-.36-.41-.47-.7-.08-.19-.14-.4-.18-.62-.03-.16-.04-.33-.04-.5,0-.27,.04-.53,.1-.76,.07-.25,.17-.47,.29-.66,.18-.28,.42-.47,.69-.55,0,0,0,0,.01,0,.03,0,.05-.02,.08-.02,.07-.01,.14-.02,.21-.02,.05,0,.1,0,.14,.02,.01,0,.03,0,.04,0,.02,0,.05,.01,.07,.02,.06,.02,.12,.04,.18,.07,.4,.21,.72,.68,.87,1.28,.02,.09,.04,.19,.05,.28,0,.02,0,.04,0,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M545.85,587.52c-.27,.59-.54,1.17-.81,1.76h0s-.64-1.98-.64-1.98c.08-.06,.19-.14,.33-.18,0,0,0,0,.01,0,.03,0,.05-.02,.08-.02,.04,0,.09-.02,.14-.02,.08,0,.15,0,.21,.02,.01,0,.03,0,.04,0,.02,0,.05,.01,.07,.02,.06,.02,.12,.05,.18,.08,.19,.1,.32,.25,.38,.32Z"
        />
        <path
          style={styles.two}
          d="M545.62,591.03s-.04,.02-.06,.03c-.02,.01-.05,.03-.07,.04,0,0-.02,0-.03,.01-.01,0-.03,.01-.04,.02-.02,.01-.05,.02-.07,.03-.01,0-.03,0-.04,.01,0,0,0,0,0,0-.05,.01-.11,.02-.16,.03-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.04,0-.06,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06-.01,0,0,0,0-.01,0-.01,0-.02,0-.03,0-.05-.01-.11-.03-.16-.06-.01,0-.03-.01-.04-.02-.01,0-.02-.01-.03-.02-.06-.03-.12-.07-.17-.12-.01,0-.02-.02-.03-.02,0,0-.02-.02-.03-.03,.18-.47,.68-1.58,.69-1.61h0s.59,1.74,.59,1.74Z"
        />
        <path
          style={styles.two}
          d="M546.42,589.19c0,.1,0,.2-.01,.29,0,.02,0,.04,0,.06,0,.04,0,.07-.01,.11,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03,0,.05,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,.01,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.01,.05-.03,.1-.05,.15,0,.02-.01,.04-.02,.06-.05,.14-.12,.28-.19,.4l-1.02-1.28,1.3-.81c.02,.09,.04,.19,.05,.28,0,.02,0,.04,0,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M545.04,589.28h0s-1.17,.92-1.17,.92c-.08-.19-.14-.4-.18-.62-.03-.16-.04-.33-.04-.5,0-.27,.04-.53,.1-.76,.07-.25,.17-.47,.29-.66,.25,.41,.97,1.58,.99,1.62h0Z"
        />
        <path
          style={styles.four}
          d="M545.47,587.2h0s-.43,1.59-.43,1.59l-.31-1.66h0s0,0,.01,0c.03,0,.05-.02,.08-.02,.04,0,.09-.02,.14-.02,.08,0,.15,0,.21,.02,.01,0,.03,0,.04,0,.02,0,.05,.01,.07,.02,.06,.02,.12,.04,.18,.07Z"
        />
        <path
          style={styles.four}
          d="M546.42,589.19c0,.1,0,.2-.01,.29,0,.02,0,.04,0,.06,0,.04,0,.07-.01,.11,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03,0,.05,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,.01,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03l-.95-.67,1.03-.46s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M545.31,591.17c-.05,.01-.11,.02-.16,.03-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.04,0-.06,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06-.01,0,0,0,0-.01,0-.01,0-.02,0-.03,0-.05-.01-.11-.03-.16-.06-.01,0-.03-.01-.04-.02l.41-1.5,.29,1.59Z"
        />
        <path
          style={styles.four}
          d="M544.82,589.24l-1.12,.34c-.03-.16-.04-.33-.04-.5,0-.27,.04-.53,.1-.76l1.06,.92Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M543.62,588.59c0,.1,0,.2-.01,.3,0,.02,0,.04,0,.06,0,.04,0,.07-.01,.11,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03,0,.05,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,.01,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.01,.05-.03,.1-.05,.15,0,.02-.01,.04-.02,.06-.05,.14-.12,.28-.19,.4-.12,.19-.27,.35-.44,.46-.02,.01-.04,.02-.06,.03-.02,.01-.05,.03-.07,.04,0,0-.02,0-.03,.01-.01,0-.03,.01-.04,.02-.02,.01-.05,.02-.07,.03-.01,0-.03,0-.04,.01,0,0,0,0,0,0-.05,.01-.11,.02-.16,.03-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.04,0-.06,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06-.01,0,0,0,0-.01,0-.01,0-.02,0-.03,0-.05-.01-.11-.03-.16-.06-.01,0-.03-.01-.04-.02-.01,0-.02-.01-.03-.02-.06-.03-.12-.07-.17-.12-.01,0-.02-.02-.03-.02,0,0-.02-.02-.03-.03-.19-.17-.36-.41-.48-.7-.08-.19-.14-.4-.18-.62-.03-.16-.04-.33-.04-.5,0-.27,.04-.53,.1-.76,.07-.25,.17-.47,.29-.66,.18-.28,.42-.47,.69-.55,0,0,0,0,.01,0,.03,0,.05-.02,.08-.02,.07-.01,.14-.02,.21-.02,.05,0,.1,0,.14,.02,.01,0,.03,0,.04,0,.02,0,.05,.01,.07,.02,.06,.02,.12,.04,.18,.07,.4,.21,.73,.68,.87,1.28,.02,.09,.04,.19,.05,.28,0,.02,0,.04,0,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M543.04,586.92c-.27,.59-.54,1.17-.81,1.76h0s-.64-1.98-.64-1.98c.08-.06,.19-.14,.33-.18,0,0,0,0,.01,0,.03,0,.05-.02,.08-.02,.04,0,.09-.02,.14-.02,.08,0,.15,0,.21,.02,.01,0,.03,0,.04,0,.02,0,.05,.01,.07,.02,.06,.02,.12,.05,.18,.08,.19,.1,.32,.25,.38,.32Z"
        />
        <path
          style={styles.two}
          d="M542.82,590.43s-.04,.02-.06,.03c-.02,.01-.05,.03-.07,.04,0,0-.02,0-.03,.01-.01,0-.03,.01-.04,.02-.02,.01-.05,.02-.07,.03-.01,0-.03,0-.04,.01,0,0,0,0,0,0-.05,.01-.11,.02-.16,.03-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.04,0-.06,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06-.01,0,0,0,0-.01,0-.01,0-.02,0-.03,0-.05-.01-.11-.03-.16-.06-.01,0-.03-.01-.04-.02-.01,0-.02-.01-.03-.02-.06-.03-.12-.07-.17-.12-.01,0-.02-.02-.03-.02,0,0-.02-.02-.03-.03,.18-.47,.68-1.58,.69-1.61h0s.59,1.74,.59,1.74Z"
        />
        <path
          style={styles.two}
          d="M543.62,588.59c0,.1,0,.2-.01,.3,0,.02,0,.04,0,.06,0,.04,0,.07-.01,.11,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03,0,.05,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,.01,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.01,.05-.03,.1-.05,.15,0,.02-.01,.04-.02,.06-.05,.14-.12,.28-.19,.4l-1.02-1.28,1.3-.81c.02,.09,.04,.19,.05,.28,0,.02,0,.04,0,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M542.23,588.68h0s-1.17,.92-1.17,.92c-.08-.19-.14-.4-.18-.62-.03-.16-.04-.33-.04-.5,0-.27,.04-.53,.1-.76,.07-.25,.17-.47,.29-.66,.25,.41,.97,1.58,.99,1.62h0Z"
        />
        <path
          style={styles.four}
          d="M542.66,586.59h0s-.43,1.59-.43,1.59l-.31-1.67h0s0,0,.01,0c.03,0,.05-.02,.08-.02,.04,0,.09-.02,.14-.02,.08,0,.15,0,.21,.02,.01,0,.03,0,.04,0,.02,0,.05,.01,.07,.02,.06,.02,.12,.04,.18,.07Z"
        />
        <path
          style={styles.four}
          d="M543.62,588.59c0,.1,0,.2-.01,.3,0,.02,0,.04,0,.06,0,.04,0,.07-.01,.11,0,.01,0,.02,0,.04,0,.02,0,.04-.01,.06,0,.02,0,.03,0,.05,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,.01,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03l-.95-.67,1.03-.46s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M542.5,590.57c-.05,.01-.11,.02-.16,.03-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.04,0-.06,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06-.01,0,0,0,0-.01,0-.01,0-.02,0-.03,0-.05-.01-.11-.03-.16-.06-.01,0-.03-.01-.04-.02l.41-1.5,.29,1.59Z"
        />
        <path
          style={styles.four}
          d="M542.01,588.64l-1.13,.34c-.03-.16-.04-.33-.04-.5,0-.27,.04-.53,.1-.76l1.07,.92Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M544.55,590.71c0,.08,0,.16-.01,.24,0,.02,0,.03,0,.05,0,.03,0,.06-.01,.08,0,.01,0,.02,0,.03,0,.02,0,.03,0,.05,0,.01,0,.03,0,.04,0,0,0,.01,0,.02,0,.02,0,.03-.01,.05,0,0,0,0,0,0,0,.01,0,.03-.01,.04,0,0,0,.02,0,.02-.01,.04-.02,.08-.04,.12,0,.01-.01,.03-.02,.04-.04,.12-.09,.22-.15,.32-.1,.16-.21,.28-.34,.37-.01,0-.03,.02-.04,.03-.02,.01-.04,.02-.06,.03,0,0-.01,0-.02,.01-.01,0-.02,.01-.03,.02-.02,0-.04,.02-.06,.02-.01,0-.02,0-.03,.01,0,0,0,0,0,0-.04,.01-.08,.02-.12,.02-.01,0-.02,0-.04,0-.02,0-.03,0-.05,0-.01,0-.03,0-.04,0-.02,0-.04,0-.06,0-.02,0-.03,0-.05,0,0,0,0,0,0,0,0,0-.02,0-.03,0-.04-.01-.08-.03-.12-.05-.01,0-.02-.01-.03-.02,0,0-.02-.01-.03-.01-.05-.03-.09-.06-.13-.09,0,0-.02-.01-.02-.02,0,0-.02-.01-.02-.02-.15-.14-.28-.33-.37-.56-.06-.15-.11-.32-.14-.49-.02-.13-.03-.26-.03-.4,0-.22,.03-.42,.08-.61,.05-.2,.13-.38,.23-.53,.14-.22,.33-.38,.54-.44,0,0,0,0,.01,0,.02,0,.04-.01,.06-.02,.05-.01,.11-.01,.16-.01,.04,0,.07,0,.11,.01,.01,0,.02,0,.03,0,.02,0,.04,.01,.05,.02,.05,.02,.1,.04,.14,.06,.31,.17,.56,.55,.68,1.02,.02,.07,.03,.15,.04,.23,0,.02,0,.03,0,.05,0,0,0,.01,0,.02,0,0,0,.01,0,.02,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.01,0,.03,0,.04,0,.01,0,.03,0,.04,0,.02,0,.05,0,.07Z"
        />
        <path
          style={styles.two}
          d="M544.1,589.37c-.21,.47-.42,.94-.63,1.41h0s-.5-1.59-.5-1.59c.06-.05,.15-.11,.26-.15,0,0,0,0,.01,0,.02,0,.04-.01,.06-.02,.03,0,.07-.01,.11-.01,.06,0,.11,0,.17,.01,.01,0,.02,0,.03,0,.02,0,.04,.01,.05,.02,.05,.02,.1,.04,.14,.07,.14,.08,.25,.2,.3,.26Z"
        />
        <path
          style={styles.two}
          d="M543.93,592.18s-.03,.02-.04,.03c-.02,.01-.04,.02-.06,.03,0,0-.01,0-.02,.01-.01,0-.02,.01-.03,.02-.02,0-.04,.02-.06,.02-.01,0-.02,0-.03,.01,0,0,0,0,0,0-.04,.01-.08,.02-.12,.02-.01,0-.02,0-.04,0-.02,0-.03,0-.05,0-.01,0-.03,0-.04,0-.02,0-.04,0-.06,0-.02,0-.03,0-.05,0,0,0,0,0,0,0,0,0-.02,0-.03,0-.04-.01-.08-.03-.12-.05-.01,0-.02-.01-.03-.02,0,0-.02-.01-.03-.01-.05-.03-.09-.06-.13-.09,0,0-.02-.01-.02-.02,0,0-.02-.01-.02-.02,.14-.38,.53-1.26,.54-1.29h0s.46,1.39,.46,1.39Z"
        />
        <path
          style={styles.two}
          d="M544.55,590.71c0,.08,0,.16-.01,.24,0,.02,0,.03,0,.05,0,.03,0,.06-.01,.08,0,.01,0,.02,0,.03,0,.02,0,.03,0,.05,0,.01,0,.03,0,.04,0,0,0,.01,0,.02,0,.02,0,.03-.01,.05,0,0,0,0,0,0,0,.01,0,.03-.01,.04,0,0,0,.02,0,.02-.01,.04-.02,.08-.04,.12,0,.01-.01,.03-.02,.04-.04,.12-.09,.22-.15,.32l-.8-1.03,1.01-.65c.02,.07,.03,.15,.04,.23,0,.02,0,.03,0,.05,0,0,0,.01,0,.02,0,0,0,.01,0,.02,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.01,0,.03,0,.04,0,.01,0,.03,0,.04,0,.02,0,.05,0,.07Z"
        />
        <path
          style={styles.two}
          d="M543.47,590.79h0s-.91,.74-.91,.74c-.06-.15-.11-.32-.14-.49-.02-.13-.03-.26-.03-.4,0-.22,.03-.42,.08-.61,.05-.2,.13-.38,.23-.53,.2,.33,.76,1.26,.77,1.29h0Z"
        />
        <path
          style={styles.four}
          d="M543.81,589.11h0s-.33,1.27-.33,1.27l-.24-1.33h0s0,0,.01,0c.02,0,.04-.01,.06-.02,.03,0,.07-.01,.11-.01,.06,0,.11,0,.17,.01,.01,0,.02,0,.03,0,.02,0,.04,.01,.05,.02,.05,.02,.1,.04,.14,.06Z"
        />
        <path
          style={styles.four}
          d="M544.55,590.71c0,.08,0,.16-.01,.24,0,.02,0,.03,0,.05,0,.03,0,.06-.01,.08,0,.01,0,.02,0,.03,0,.02,0,.03,0,.05,0,.01,0,.03,0,.04,0,0,0,.01,0,.02,0,.02,0,.03-.01,.05,0,0,0,0,0,0,0,.01,0,.03-.01,.04,0,0,0,.02,0,.02l-.74-.53,.8-.37s0,.01,0,.02c0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.01,0,.03,0,.04,0,.01,0,.03,0,.04,0,.02,0,.05,0,.07Z"
        />
        <path
          style={styles.two}
          d="M543.68,592.29s-.08,.02-.12,.02c-.01,0-.02,0-.04,0-.02,0-.03,0-.05,0-.01,0-.03,0-.04,0-.02,0-.04,0-.06,0-.02,0-.03,0-.05,0,0,0,0,0,0,0,0,0-.02,0-.03,0-.04-.01-.08-.03-.12-.05-.01,0-.02-.01-.03-.02l.32-1.21,.22,1.28Z"
        />
        <path
          style={styles.four}
          d="M543.3,590.75l-.88,.28c-.02-.13-.03-.26-.03-.4,0-.22,.03-.42,.08-.61l.83,.73Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M493.72,690.04c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.23,.37-.38,.52-.24,.26-.53,.46-.86,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.09,.03-.14,.04-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.07-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.38-.22-.7-.53-.94-.9-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.69,.2-1,.13-.33,.33-.62,.58-.87,.36-.37,.83-.62,1.36-.73,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.03,.41-.02,.09,0,.19,0,.28,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.02,.12,.02,.24,.06,.36,.09,.8,.27,1.43,.88,1.72,1.66,.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M492.59,687.87c-.53,.77-1.06,1.55-1.6,2.32h0s-1.25-2.59-1.25-2.59c.15-.08,.37-.18,.65-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.09-.01,.17-.02,.27-.03,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.02,.13,.03,.24,.06,.35,.1,.37,.13,.63,.32,.75,.41Z"
        />
        <path
          style={styles.two}
          d="M492.15,692.46s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.09,.03-.14,.04-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.07-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.36-.62,1.34-2.08,1.37-2.12h0s1.16,2.27,1.16,2.27Z"
        />
        <path
          style={styles.two}
          d="M493.72,690.04c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.23,.37-.38,.52l-2.02-1.66,2.57-1.09c.04,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M490.99,690.19h0s-2.31,1.22-2.31,1.22c-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.69,.2-1,.13-.33,.33-.62,.58-.87,.5,.53,1.92,2.05,1.96,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M491.84,687.45h0s-.84,2.09-.84,2.09l-.6-2.18h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.09-.01,.17-.02,.27-.03,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.02,.12,.02,.24,.06,.36,.09Z"
        />
        <path
          style={styles.four}
          d="M493.72,690.04c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04l-1.87-.85,2.03-.61s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M491.52,692.65c-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.07-.03,0-.05-.02-.08-.03l.82-1.98,.56,2.08Z"
        />
        <path
          style={styles.four}
          d="M490.55,690.14l-2.22,.47c-.05-.21-.08-.43-.08-.65,0-.36,.07-.69,.2-1l2.1,1.18Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M487.51,690.45c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.38,.52-.24,.26-.53,.47-.86,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.04-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.07-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.38-.22-.71-.53-.94-.9-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1,.13-.33,.33-.62,.58-.87,.36-.37,.83-.63,1.36-.73,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.03,.41-.02,.1,0,.19,0,.28,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.02,.12,.02,.24,.06,.36,.09,.8,.27,1.43,.88,1.72,1.66,.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M486.37,688.28c-.53,.77-1.07,1.55-1.6,2.32h0s-1.26-2.59-1.26-2.59c.15-.08,.37-.18,.65-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.09-.01,.17-.02,.27-.03,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.02,.13,.03,.25,.06,.35,.1,.37,.13,.63,.32,.75,.41Z"
        />
        <path
          style={styles.two}
          d="M485.93,692.87s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.04-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.07-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.36-.62,1.34-2.08,1.37-2.13h0s1.16,2.27,1.16,2.27Z"
        />
        <path
          style={styles.two}
          d="M487.51,690.45c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.38,.52l-2.02-1.66,2.57-1.09c.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M484.77,690.6h0s-2.31,1.22-2.31,1.22c-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1,.13-.33,.33-.62,.58-.87,.5,.53,1.92,2.05,1.97,2.1h0Z"
        />
        <path
          style={styles.four}
          d="M485.62,687.85h0s-.85,2.09-.85,2.09l-.6-2.18h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.09-.01,.17-.02,.27-.03,.15,0,.29,0,.42,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.02,.12,.02,.24,.06,.36,.09Z"
        />
        <path
          style={styles.four}
          d="M487.51,690.45c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04l-1.87-.86,2.04-.62s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M485.3,693.07c-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.21-.04-.31-.07-.03,0-.05-.02-.08-.03l.82-1.98,.56,2.08Z"
        />
        <path
          style={styles.four}
          d="M484.33,690.55l-2.23,.47c-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1l2.11,1.18Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M482.91,709.35c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.17-.22,.34-.35,.48-.22,.24-.49,.43-.79,.56-.03,.01-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.49-.86-.82-.14-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.79,.33-.34,.76-.57,1.25-.67,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.02,.38-.02,.09,0,.17,0,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.8,1.58,1.51,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M481.87,707.37c-.49,.71-.98,1.42-1.46,2.13h0s-1.15-2.36-1.15-2.36c.14-.07,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.34,.12,.58,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M481.46,711.58s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.91,1.26-1.95h0s1.06,2.07,1.06,2.07Z"
        />
        <path
          style={styles.two}
          d="M482.91,709.35c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.17-.22,.34-.35,.48l-1.85-1.51,2.35-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M480.4,709.5h0s-2.12,1.13-2.12,1.13c-.14-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.79,.45,.48,1.76,1.87,1.8,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M481.18,706.99h0s-.77,1.92-.77,1.92l-.55-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M482.91,709.35c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.72-.78,1.87-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M480.89,711.75c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.81,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M480,709.45l-2.04,.44c-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92l1.93,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M487.7,709.41c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.79,.56-.03,.01-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.82-.14-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.79,.33-.34,.76-.57,1.25-.67,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.02,.38-.02,.09,0,.17,0,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.8,1.57,1.51,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M486.66,707.42c-.49,.71-.97,1.42-1.46,2.13h0s-1.15-2.36-1.15-2.36c.14-.07,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.34,.12,.58,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M486.26,711.63s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.91,1.25-1.95h0s1.06,2.07,1.06,2.07Z"
        />
        <path
          style={styles.two}
          d="M487.7,709.41c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.85-1.51,2.35-1c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M485.2,709.55h0s-2.11,1.13-2.11,1.13c-.14-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.79,.45,.48,1.76,1.87,1.8,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M485.97,707.04h0s-.77,1.91-.77,1.91l-.55-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M487.7,709.41c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.71-.77,1.86-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M485.68,711.8c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.81,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M484.8,709.51l-2.03,.44c-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92l1.93,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M492.96,707.95c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.79,.56-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.53-.79,.33-.33,.76-.57,1.24-.67,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.02,.38-.02,.09,0,.17,0,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.8,1.57,1.51,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M491.92,705.97c-.49,.71-.97,1.42-1.46,2.13h0s-1.15-2.36-1.15-2.36c.14-.07,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.33,.12,.57,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M491.52,710.17s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.22-1.9,1.25-1.94h0s1.06,2.07,1.06,2.07Z"
        />
        <path
          style={styles.two}
          d="M492.96,707.95c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.84-1.51,2.35-1c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M490.46,708.1h0s-2.11,1.12-2.11,1.12c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.53-.79,.45,.48,1.75,1.87,1.79,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M491.23,705.59h0s-.77,1.91-.77,1.91l-.55-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M492.96,707.95c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.71-.77,1.86-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M490.94,710.35c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.81,.51,1.9Z"
        />
        <path
          style={styles.four}
          d="M490.06,708.05l-2.03,.44c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92l1.92,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M492.96,712.99c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.79,.56-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.53-.79,.33-.34,.76-.57,1.24-.67,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.02,.38-.02,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.8,1.57,1.51,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M491.92,711.02c-.49,.71-.97,1.42-1.46,2.13h0s-1.15-2.36-1.15-2.36c.14-.07,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.33,.12,.57,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M491.52,715.21s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.22-1.9,1.25-1.95h0s1.06,2.07,1.06,2.07Z"
        />
        <path
          style={styles.two}
          d="M492.96,712.99c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.84-1.51,2.35-1c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M490.46,713.14h0s-2.11,1.13-2.11,1.13c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.53-.79,.45,.48,1.75,1.87,1.79,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M491.23,710.63h0s-.77,1.91-.77,1.91l-.55-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M492.96,712.99c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.71-.77,1.86-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M490.94,715.39c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.81,.51,1.9Z"
        />
        <path
          style={styles.four}
          d="M490.06,713.1l-2.03,.44c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92l1.92,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M500.11,712.56c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.04,.07-.1,.17-.21,.33-.35,.48-.22,.24-.49,.43-.78,.56-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.85-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.52-.79,.33-.33,.76-.57,1.24-.67,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.02,.38-.02,.09,0,.17,0,.25,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.72,.24,1.3,.8,1.57,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M499.07,710.59c-.49,.71-.97,1.42-1.45,2.12h0s-1.14-2.35-1.14-2.35c.14-.07,.34-.17,.59-.23,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.06,.32,.09,.33,.12,.57,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M498.67,714.78s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.22-1.9,1.25-1.94h0s1.05,2.06,1.05,2.06Z"
        />
        <path
          style={styles.two}
          d="M500.11,712.56c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.04,.07-.1,.17-.21,.33-.35,.48l-1.84-1.5,2.34-1c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M497.62,712.71h0s-2.1,1.12-2.1,1.12c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.52-.79,.45,.48,1.75,1.86,1.79,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M498.39,710.2h0s-.77,1.91-.77,1.91l-.55-1.98h0s.02,0,.02,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M500.11,712.56c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.7-.77,1.85-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M498.1,714.96c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02l.74-1.81,.51,1.89Z"
        />
        <path
          style={styles.four}
          d="M497.22,712.67l-2.02,.44c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92l1.92,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M495.34,717.55c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.79,.56-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.53-.79,.33-.34,.76-.57,1.24-.67,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.02,.38-.02,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.8,1.57,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M494.3,715.58c-.49,.71-.97,1.42-1.46,2.13h0s-1.15-2.36-1.15-2.36c.14-.08,.34-.17,.6-.23,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.33,.12,.57,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M493.9,719.77s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.22-1.9,1.25-1.95h0s1.06,2.07,1.06,2.07Z"
        />
        <path
          style={styles.two}
          d="M495.34,717.55c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.84-1.5,2.34-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M492.85,717.71h0s-2.11,1.13-2.11,1.13c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.53-.79,.45,.48,1.75,1.86,1.79,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M493.62,715.2h0s-.77,1.91-.77,1.91l-.55-1.98h0s.02,0,.02,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M495.34,717.55c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.71-.77,1.86-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M493.33,719.95c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02l.74-1.81,.51,1.9Z"
        />
        <path
          style={styles.four}
          d="M492.45,717.66l-2.03,.44c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92l1.92,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M493.44,722.58c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.79,.56-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.53-.79,.33-.34,.76-.58,1.24-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.02,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.8,1.57,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M492.4,720.6c-.49,.71-.97,1.42-1.46,2.13h0s-1.15-2.35-1.15-2.35c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.33,.12,.57,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M491.99,724.8s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.22-1.91,1.25-1.95h0s1.06,2.07,1.06,2.07Z"
        />
        <path
          style={styles.two}
          d="M493.44,722.58c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.84-1.5,2.34-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M490.94,722.73h0s-2.11,1.13-2.11,1.13c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.53-.79,.45,.48,1.75,1.86,1.79,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M491.71,720.22h0s-.77,1.91-.77,1.91l-.55-1.98h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M493.44,722.58c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.71-.77,1.86-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M491.42,724.98c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.81,.51,1.9Z"
        />
        <path
          style={styles.four}
          d="M490.54,722.69l-2.03,.44c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92l1.92,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M492.48,727.11c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.79,.56-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.53-.79,.33-.34,.76-.58,1.24-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.02,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.79,1.57,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M491.44,725.14c-.49,.71-.97,1.42-1.46,2.13h0s-1.15-2.35-1.15-2.35c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.33,.12,.57,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M491.04,729.33s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.22-1.91,1.25-1.95h0s1.06,2.06,1.06,2.06Z"
        />
        <path
          style={styles.two}
          d="M492.48,727.11c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.84-1.5,2.35-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M489.98,727.27h0s-2.11,1.13-2.11,1.13c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.53-.79,.45,.48,1.75,1.86,1.79,1.9h0Z"
        />
        <path
          style={styles.four}
          d="M490.76,724.76h0s-.77,1.91-.77,1.91l-.55-1.98h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M492.48,727.11c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.71-.77,1.86-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M490.47,729.52c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.81,.51,1.9Z"
        />
        <path
          style={styles.four}
          d="M489.58,727.22l-2.03,.44c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92l1.92,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M491.05,731.64c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.79,.57-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.33-.34,.76-.58,1.24-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.02,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.79,1.57,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M490.01,729.67c-.49,.71-.97,1.42-1.46,2.13h0s-1.15-2.35-1.15-2.35c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.34,.12,.57,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M489.61,733.87s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.91,1.25-1.95h0s1.06,2.06,1.06,2.06Z"
        />
        <path
          style={styles.two}
          d="M491.05,731.64c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.85-1.5,2.35-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M488.55,731.8h0s-2.11,1.13-2.11,1.13c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.45,.48,1.76,1.86,1.8,1.9h0Z"
        />
        <path
          style={styles.four}
          d="M489.32,729.29h0s-.77,1.92-.77,1.92l-.55-1.98h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M491.05,731.64c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.71-.77,1.86-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M489.03,734.05c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.81,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M488.15,731.76l-2.03,.45c-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92l1.92,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M490.57,737.19c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.79,.57-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.33-.34,.76-.58,1.24-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.03,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.79,1.57,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M489.53,735.22c-.49,.71-.97,1.42-1.46,2.14h0s-1.15-2.35-1.15-2.35c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.34,.12,.57,.28,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M489.13,739.42s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.91,1.25-1.95h0s1.06,2.06,1.06,2.06Z"
        />
        <path
          style={styles.two}
          d="M490.57,737.19c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.85-1.5,2.35-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M488.07,737.35h0s-2.11,1.14-2.11,1.14c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.45,.48,1.76,1.86,1.8,1.9h0Z"
        />
        <path
          style={styles.four}
          d="M488.84,734.84h0s-.77,1.92-.77,1.92l-.55-1.98h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M490.57,737.19c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.71-.76,1.86-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M488.55,739.6c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.81,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M487.67,737.31l-2.03,.45c-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92l1.92,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M490.57,741.73c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.18-.21,.34-.35,.48-.22,.24-.49,.43-.79,.57-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.33-.34,.76-.58,1.24-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.03,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.79,1.57,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M489.53,739.76c-.49,.71-.97,1.43-1.46,2.14h0s-1.15-2.35-1.15-2.35c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.34,.12,.57,.28,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M489.13,743.96s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.91,1.25-1.95h0s1.06,2.06,1.06,2.06Z"
        />
        <path
          style={styles.two}
          d="M490.57,741.73c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.18-.21,.34-.35,.48l-1.85-1.5,2.35-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M488.07,741.9h0s-2.11,1.14-2.11,1.14c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.45,.48,1.76,1.86,1.8,1.9h0Z"
        />
        <path
          style={styles.four}
          d="M488.84,739.38h0s-.77,1.92-.77,1.92l-.55-1.98h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M490.57,741.73c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.71-.76,1.86-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M488.55,744.14c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.82,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M487.67,741.85l-2.03,.45c-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92l1.92,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M491.05,746.78c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.18-.21,.34-.35,.48-.22,.24-.49,.43-.79,.57-.03,.02-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.81-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.33-.34,.76-.58,1.24-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.03,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.79,1.57,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M490.01,744.81c-.49,.71-.97,1.43-1.46,2.14h0s-1.15-2.35-1.15-2.35c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.34,.12,.57,.28,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M489.61,749.01s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.91,1.25-1.95h0s1.06,2.06,1.06,2.06Z"
        />
        <path
          style={styles.two}
          d="M491.05,746.78c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.18-.21,.34-.35,.48l-1.85-1.49,2.35-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M488.55,746.95h0s-2.11,1.14-2.11,1.14c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.45,.48,1.76,1.85,1.8,1.9h0Z"
        />
        <path
          style={styles.four}
          d="M489.32,744.43h0s-.77,1.92-.77,1.92l-.55-1.98h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M491.05,746.78c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.71-.76,1.86-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M489.03,749.19c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02l.75-1.82,.52,1.89Z"
        />
        <path
          style={styles.four}
          d="M488.15,746.9l-2.03,.45c-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92l1.92,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M494.87,749.82c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.18-.21,.34-.35,.48-.22,.24-.49,.43-.79,.57-.03,.02-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.81-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.53-.8,.33-.34,.76-.58,1.24-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.03,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.79,1.57,1.49,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M493.83,747.85c-.49,.71-.97,1.43-1.46,2.14h0s-1.15-2.35-1.15-2.35c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.33,.12,.57,.28,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M493.43,752.04s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.22-1.91,1.25-1.95h0s1.06,2.06,1.06,2.06Z"
        />
        <path
          style={styles.two}
          d="M494.87,749.82c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.18-.21,.34-.35,.48l-1.84-1.49,2.34-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M492.37,749.98h0s-2.11,1.14-2.11,1.14c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.53-.8,.45,.48,1.75,1.85,1.79,1.89h0Z"
        />
        <path
          style={styles.four}
          d="M493.14,747.47h0s-.77,1.92-.77,1.92l-.55-1.98h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M494.87,749.82c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.71-.76,1.86-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M492.85,752.23c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02l.74-1.81,.51,1.89Z"
        />
        <path
          style={styles.four}
          d="M491.97,749.94l-2.03,.45c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92l1.92,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M499.63,747.31c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.78,.57-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.81-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.52-.8,.33-.34,.76-.58,1.24-.68,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.03,.09,0,.17,0,.25,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.72,.24,1.3,.79,1.57,1.49,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M498.6,745.34c-.49,.71-.97,1.42-1.45,2.14h0s-1.14-2.35-1.14-2.35c.14-.08,.34-.17,.59-.23,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.06,.32,.09,.33,.12,.57,.28,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M498.2,749.54s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.02,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.22-1.91,1.25-1.95h0s1.05,2.06,1.05,2.06Z"
        />
        <path
          style={styles.two}
          d="M499.63,747.31c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.84-1.49,2.34-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M497.14,747.48h0s-2.1,1.14-2.1,1.14c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.52-.8,.45,.48,1.75,1.85,1.79,1.89h0Z"
        />
        <path
          style={styles.four}
          d="M497.91,744.96h0s-.77,1.92-.77,1.92l-.55-1.98h0s.02,0,.02,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M499.63,747.31c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.7-.76,1.85-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M497.62,749.72c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.02,0-.05-.01-.07-.02l.74-1.81,.51,1.89Z"
        />
        <path
          style={styles.four}
          d="M496.74,747.43l-2.02,.45c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92l1.92,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M502.01,742.79c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.78,.57-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.85-.81-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.52-.79,.33-.34,.76-.58,1.24-.68,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.37-.03,.09,0,.17,0,.25,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.72,.24,1.3,.79,1.56,1.49,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M500.98,740.82c-.48,.71-.97,1.42-1.45,2.13h0s-1.14-2.35-1.14-2.35c.14-.08,.34-.17,.59-.23,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.06,.32,.09,.33,.12,.57,.28,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M500.58,745.01s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.22-1.91,1.25-1.95h0s1.05,2.06,1.05,2.06Z"
        />
        <path
          style={styles.two}
          d="M502.01,742.79c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.84-1.49,2.34-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M499.52,742.95h0s-2.1,1.14-2.1,1.14c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.52-.79,.45,.48,1.75,1.85,1.79,1.89h0Z"
        />
        <path
          style={styles.four}
          d="M500.29,740.44h0s-.77,1.91-.77,1.91l-.55-1.98h0s.02,0,.02,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M502.01,742.79c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.7-.76,1.85-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M500.01,745.19c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02l.74-1.81,.51,1.89Z"
        />
        <path
          style={styles.four}
          d="M499.13,742.91l-2.02,.45c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92l1.92,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M503.92,738.77c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.78,.56-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.85-.81-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.52-.79,.33-.34,.76-.58,1.24-.68,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.37-.03,.09,0,.17,0,.25,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.72,.24,1.3,.79,1.56,1.49,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M502.88,736.8c-.48,.71-.97,1.42-1.45,2.13h0s-1.14-2.35-1.14-2.35c.14-.08,.34-.17,.59-.23,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.06,.32,.09,.33,.12,.57,.28,.68,.37Z"
        />
        <path
          style={styles.two}
          d="M502.48,740.99s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.32-.57,1.22-1.91,1.25-1.95h0s1.05,2.06,1.05,2.06Z"
        />
        <path
          style={styles.two}
          d="M503.92,738.77c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.84-1.49,2.33-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M501.43,738.93h0s-2.1,1.13-2.1,1.13c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.52-.79,.45,.48,1.75,1.85,1.79,1.9h0Z"
        />
        <path
          style={styles.four}
          d="M502.2,736.42h0s-.77,1.91-.77,1.91l-.55-1.98h0s.02,0,.02,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M503.92,738.77c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.7-.76,1.85-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M501.91,741.17c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02l.74-1.81,.51,1.89Z"
        />
        <path
          style={styles.four}
          d="M501.03,738.89l-2.02,.45c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92l1.91,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M504.87,734.24c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.78,.56-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.85-.81-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.52-.79,.33-.34,.76-.58,1.24-.68,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.37-.02,.09,0,.17,0,.25,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.72,.24,1.3,.79,1.56,1.49,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M503.83,732.28c-.48,.71-.97,1.42-1.45,2.13h0s-1.14-2.35-1.14-2.35c.14-.08,.34-.17,.59-.23,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.38,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.06,.32,.09,.33,.12,.57,.28,.68,.37Z"
        />
        <path
          style={styles.two}
          d="M503.43,736.46s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.32-.57,1.22-1.9,1.25-1.95h0s1.05,2.06,1.05,2.06Z"
        />
        <path
          style={styles.two}
          d="M504.87,734.24c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.84-1.49,2.33-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M502.38,734.41h0s-2.1,1.13-2.1,1.13c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92,.12-.3,.3-.57,.52-.79,.45,.48,1.75,1.85,1.79,1.9h0Z"
        />
        <path
          style={styles.four}
          d="M503.15,731.9h0s-.77,1.91-.77,1.91l-.55-1.98h0s.02,0,.02,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.38,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M504.87,734.24c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.7-.76,1.85-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M502.86,736.65c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02l.74-1.81,.51,1.89Z"
        />
        <path
          style={styles.four}
          d="M501.98,734.36l-2.02,.45c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.92l1.91,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M506.77,729.23c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.48,.43-.78,.56-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.85-.81-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.91,.12-.3,.3-.57,.52-.79,.33-.34,.76-.58,1.24-.68,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.37-.02,.09,0,.17,0,.25,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.72,.24,1.3,.79,1.56,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M505.73,727.26c-.48,.71-.97,1.42-1.45,2.13h0s-1.14-2.35-1.14-2.35c.14-.08,.34-.17,.59-.23,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.38,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.06,.32,.09,.33,.12,.57,.28,.68,.37Z"
        />
        <path
          style={styles.two}
          d="M505.33,731.44s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.32-.57,1.22-1.9,1.24-1.94h0s1.05,2.06,1.05,2.06Z"
        />
        <path
          style={styles.two}
          d="M506.77,729.23c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.04,.07-.1,.17-.21,.34-.35,.48l-1.83-1.5,2.33-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M504.28,729.38h0s-2.1,1.13-2.1,1.13c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.91,.12-.3,.3-.57,.52-.79,.45,.48,1.74,1.86,1.78,1.9h0Z"
        />
        <path
          style={styles.four}
          d="M505.05,726.88h0s-.77,1.91-.77,1.91l-.55-1.98h0s.02,0,.02,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.38,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M506.77,729.23c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.7-.76,1.85-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M504.76,731.62c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02l.74-1.81,.51,1.89Z"
        />
        <path
          style={styles.four}
          d="M503.88,729.34l-2.02,.44c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.91l1.91,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M506.29,724.19c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.48,.43-.78,.56-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.85-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.91,.12-.3,.3-.57,.52-.79,.33-.34,.76-.57,1.24-.67,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.37-.02,.09,0,.17,0,.25,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.72,.24,1.3,.79,1.56,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M505.26,722.22c-.48,.71-.97,1.42-1.45,2.13h0s-1.14-2.35-1.14-2.35c.14-.08,.34-.17,.59-.23,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.38,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.06,.32,.09,.33,.12,.57,.29,.68,.37Z"
        />
        <path
          style={styles.two}
          d="M504.86,726.41s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.32-.57,1.22-1.9,1.25-1.94h0s1.05,2.06,1.05,2.06Z"
        />
        <path
          style={styles.two}
          d="M506.29,724.19c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.04,.07-.1,.17-.21,.34-.35,.48l-1.83-1.5,2.33-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M503.8,724.35h0s-2.1,1.13-2.1,1.13c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.91,.12-.3,.3-.57,.52-.79,.45,.48,1.74,1.86,1.78,1.9h0Z"
        />
        <path
          style={styles.four}
          d="M504.57,721.84h0s-.77,1.91-.77,1.91l-.55-1.98h0s.02,0,.02,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.03,.14,0,.26,0,.38,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M506.29,724.19c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.7-.77,1.85-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M504.29,726.59c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02l.74-1.81,.51,1.89Z"
        />
        <path
          style={styles.four}
          d="M503.41,724.3l-2.02,.44c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.91l1.91,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M504.87,719.15c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.04,.07-.1,.17-.21,.33-.35,.48-.22,.24-.49,.43-.78,.56-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.85-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.91,.12-.3,.3-.57,.52-.79,.33-.33,.76-.57,1.24-.67,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.02,.37-.02,.09,0,.17,0,.25,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.72,.24,1.3,.79,1.56,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M503.83,717.18c-.48,.71-.97,1.42-1.45,2.12h0s-1.14-2.35-1.14-2.35c.14-.07,.34-.17,.59-.23,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.14,0,.26,0,.38,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.06,.32,.09,.33,.12,.57,.29,.68,.37Z"
        />
        <path
          style={styles.two}
          d="M503.43,721.37s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.32-.57,1.22-1.9,1.25-1.94h0s1.05,2.06,1.05,2.06Z"
        />
        <path
          style={styles.two}
          d="M504.87,719.15c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.04,.07-.1,.17-.21,.33-.35,.48l-1.84-1.5,2.33-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M502.38,719.3h0s-2.1,1.13-2.1,1.13c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.91,.12-.3,.3-.57,.52-.79,.45,.48,1.75,1.86,1.79,1.9h0Z"
        />
        <path
          style={styles.four}
          d="M503.15,716.8h0s-.77,1.91-.77,1.91l-.55-1.98h0s.02,0,.02,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.14,0,.26,0,.38,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M504.87,719.15c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.7-.77,1.85-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M502.86,721.55c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02l.74-1.81,.51,1.89Z"
        />
        <path
          style={styles.four}
          d="M501.98,719.26l-2.02,.44c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.91l1.91,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M502.96,716.11c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.04,.07-.1,.17-.21,.33-.35,.48-.22,.24-.49,.43-.78,.56-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.85-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.91,.12-.3,.3-.57,.52-.79,.33-.33,.76-.57,1.24-.67,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.02,.37-.02,.09,0,.17,0,.25,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.72,.24,1.3,.8,1.56,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M501.93,714.14c-.48,.71-.97,1.42-1.45,2.12h0s-1.14-2.35-1.14-2.35c.14-.07,.34-.17,.59-.23,0,0,.02,0,.02,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.06,.32,.09,.33,.12,.57,.29,.68,.37Z"
        />
        <path
          style={styles.two}
          d="M501.53,718.33s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.32-.57,1.22-1.9,1.25-1.94h0s1.05,2.06,1.05,2.06Z"
        />
        <path
          style={styles.two}
          d="M502.96,716.11c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.04,.07-.1,.17-.21,.33-.35,.48l-1.84-1.5,2.34-1c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M500.48,716.27h0s-2.1,1.13-2.1,1.13c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.91,.12-.3,.3-.57,.52-.79,.45,.48,1.75,1.86,1.79,1.9h0Z"
        />
        <path
          style={styles.four}
          d="M501.25,713.76h0s-.77,1.91-.77,1.91l-.55-1.98h0s.02,0,.02,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.24-.02,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M502.96,716.11c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.02,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.7-.77,1.85-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M500.96,718.51c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.02,0-.05-.01-.07-.02l.74-1.81,.51,1.89Z"
        />
        <path
          style={styles.four}
          d="M500.08,716.22l-2.02,.44c-.05-.19-.07-.39-.07-.59,0-.32,.06-.63,.18-.91l1.92,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M485.79,713.93c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.79,.56-.03,.01-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.82-.14-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.79,.33-.34,.76-.58,1.25-.67,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.02,.38-.02,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.8,1.58,1.51,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M484.74,711.95c-.49,.71-.98,1.42-1.46,2.13h0s-1.15-2.36-1.15-2.36c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.34,.12,.58,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M484.34,716.15s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.91,1.26-1.95h0s1.06,2.07,1.06,2.07Z"
        />
        <path
          style={styles.two}
          d="M485.79,713.93c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.85-1.51,2.35-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M483.28,714.08h0s-2.12,1.13-2.12,1.13c-.14-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.79,.45,.48,1.76,1.87,1.8,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M484.06,711.57h0s-.77,1.92-.77,1.92l-.55-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M485.79,713.93c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.71-.77,1.86-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M483.76,716.33c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.81,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M482.88,714.03l-2.04,.44c-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92l1.93,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M478.59,712.85c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.17-.22,.34-.35,.48-.22,.24-.49,.43-.79,.56-.03,.01-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.49-.86-.82-.14-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.33-.34,.77-.58,1.25-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.02,.38-.02,.09,0,.17,0,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.32,.8,1.58,1.51,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M477.55,710.86c-.49,.71-.98,1.42-1.47,2.13h0s-1.15-2.36-1.15-2.36c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.33,.09,.34,.12,.58,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M477.14,715.07s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.91,1.26-1.95h0s1.06,2.07,1.06,2.07Z"
        />
        <path
          style={styles.two}
          d="M478.59,712.85c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.17-.22,.34-.35,.48l-1.86-1.51,2.36-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M476.08,713h0s-2.12,1.13-2.12,1.13c-.14-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.46,.48,1.76,1.87,1.8,1.92h0Z"
        />
        <path
          style={styles.four}
          d="M476.86,710.48h0s-.78,1.92-.78,1.92l-.55-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M478.59,712.85c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.72-.77,1.87-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M476.57,715.25c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.82,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M475.68,712.95l-2.04,.44c-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92l1.93,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M477.15,716.88c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48-.22,.24-.49,.43-.79,.56-.03,.01-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.48-.86-.82-.14-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.33-.34,.77-.58,1.25-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.02,.38-.02,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.32,.8,1.58,1.51,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M476.1,714.9c-.49,.71-.98,1.42-1.47,2.14h0s-1.15-2.36-1.15-2.36c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.33,.09,.34,.12,.58,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M475.7,719.11s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.91,1.26-1.95h0s1.06,2.07,1.06,2.07Z"
        />
        <path
          style={styles.two}
          d="M477.15,716.88c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48l-1.86-1.51,2.36-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M474.64,717.03h0s-2.12,1.13-2.12,1.13c-.14-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.46,.48,1.77,1.87,1.81,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M475.41,714.51h0s-.78,1.92-.78,1.92l-.55-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M477.15,716.88c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.72-.77,1.87-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M475.12,719.29c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.82,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M474.23,716.99l-2.04,.44c-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92l1.94,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M474.75,722.43c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48-.22,.24-.49,.43-.79,.57-.03,.01-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.48-.86-.82-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.34-.34,.77-.58,1.25-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.02,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.32,.8,1.58,1.51,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M473.7,720.44c-.49,.71-.98,1.43-1.47,2.14h0s-1.16-2.36-1.16-2.36c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.06,.33,.09,.34,.12,.58,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M473.29,724.66s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.91,1.26-1.96h0s1.07,2.07,1.07,2.07Z"
        />
        <path
          style={styles.two}
          d="M474.75,722.43c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48l-1.86-1.51,2.36-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M472.23,722.58h0s-2.13,1.13-2.13,1.13c-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.46,.48,1.77,1.87,1.81,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M473.01,720.06h0s-.78,1.92-.78,1.92l-.56-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M474.75,722.43c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.72-.77,1.87-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M472.72,724.84c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.82,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M471.83,722.54l-2.05,.44c-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92l1.94,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M472.82,726.46c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48-.22,.24-.49,.43-.79,.57-.03,.01-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.48-.86-.82-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.34-.34,.77-.58,1.25-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.02,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.32,.8,1.58,1.51,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M471.77,724.48c-.49,.71-.98,1.43-1.47,2.14h0s-1.16-2.36-1.16-2.36c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.06,.33,.09,.34,.12,.58,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M471.37,728.69s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.92,1.26-1.96h0s1.07,2.07,1.07,2.07Z"
        />
        <path
          style={styles.two}
          d="M472.82,726.46c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48l-1.86-1.51,2.36-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M470.3,726.62h0s-2.13,1.14-2.13,1.14c-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.46,.48,1.77,1.87,1.81,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M471.08,724.1h0s-.78,1.92-.78,1.92l-.56-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M472.82,726.46c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.72-.77,1.87-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M470.79,728.88c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.82,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M469.9,726.57l-2.05,.45c-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92l1.94,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M471.86,732.03c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48-.22,.24-.49,.43-.79,.57-.03,.01-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.48-.86-.82-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.34-.34,.77-.58,1.25-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.02,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.32,.8,1.58,1.51,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M470.81,730.05c-.49,.71-.98,1.43-1.47,2.14h0s-1.16-2.36-1.16-2.36c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.06,.33,.09,.34,.12,.58,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M470.4,734.26s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.24-1.92,1.26-1.96h0s1.07,2.07,1.07,2.07Z"
        />
        <path
          style={styles.two}
          d="M471.86,732.03c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48l-1.86-1.51,2.37-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M469.34,732.19h0s-2.13,1.14-2.13,1.14c-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.46,.48,1.77,1.87,1.81,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M470.12,729.66h0s-.78,1.92-.78,1.92l-.56-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M471.86,732.03c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.72-.77,1.87-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M469.83,734.44c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.82,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M468.94,732.14l-2.05,.45c-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92l1.94,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M470.41,737.09c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48-.22,.24-.49,.43-.79,.57-.03,.02-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.48-.87-.82-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.34-.34,.77-.58,1.26-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.03,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.32,.8,1.59,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M469.36,735.1c-.49,.71-.98,1.43-1.47,2.15h0s-1.16-2.36-1.16-2.36c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.06,.33,.09,.34,.12,.58,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M468.96,739.32s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.24-1.92,1.26-1.96h0s1.07,2.07,1.07,2.07Z"
        />
        <path
          style={styles.two}
          d="M470.41,737.09c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48l-1.86-1.5,2.37-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M467.89,737.25h0s-2.13,1.14-2.13,1.14c-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.46,.48,1.77,1.87,1.81,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M468.67,734.72h0s-.78,1.93-.78,1.93l-.56-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M470.41,737.09c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.72-.77,1.87-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M468.38,739.51c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.01-.07-.02l.75-1.82,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M467.49,737.2l-2.05,.45c-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92l1.94,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M468.97,742.15c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48-.22,.24-.49,.43-.79,.57-.03,.02-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.48-.87-.82-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.34-.34,.77-.58,1.26-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.03,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.32,.79,1.59,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M467.92,740.17c-.49,.72-.98,1.43-1.47,2.15h0s-1.16-2.36-1.16-2.36c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.06,.33,.09,.34,.12,.58,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M467.51,744.39s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.24-1.92,1.26-1.96h0s1.07,2.07,1.07,2.07Z"
        />
        <path
          style={styles.two}
          d="M468.97,742.15c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48l-1.86-1.5,2.37-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M466.44,742.31h0s-2.13,1.14-2.13,1.14c-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.46,.48,1.77,1.87,1.81,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M467.23,739.79h0s-.78,1.93-.78,1.93l-.56-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M468.97,742.15c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.73-.77,1.88-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M466.93,744.57c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.01-.07-.02l.75-1.82,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M466.04,742.27l-2.05,.45c-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92l1.94,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M468,747.21c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.49-.22,.24-.49,.43-.79,.57-.03,.02-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.48-.87-.82-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.34-.34,.77-.58,1.26-.69,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.03,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.32,.79,1.59,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M466.95,745.23c-.49,.72-.98,1.43-1.47,2.15h0s-1.16-2.36-1.16-2.36c.14-.08,.35-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.06,.33,.09,.34,.12,.58,.28,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M466.55,749.45s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.24-1.92,1.27-1.96h0s1.07,2.07,1.07,2.07Z"
        />
        <path
          style={styles.two}
          d="M468,747.21c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.49l-1.86-1.5,2.37-1.03c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M465.48,747.38h0s-2.13,1.15-2.13,1.15c-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.46,.48,1.77,1.86,1.81,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M466.26,744.85h0s-.78,1.93-.78,1.93l-.56-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M468,747.21c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.73-.76,1.88-.59s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M465.97,749.64c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.01-.07-.02l.75-1.82,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M465.08,747.34l-2.05,.45c-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92l1.94,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M466.56,752.28c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.49-.22,.24-.49,.43-.8,.57-.03,.02-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.06-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.48-.87-.82-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.34-.34,.77-.58,1.26-.69,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.03,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.32,.79,1.59,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M465.5,750.3c-.49,.72-.98,1.43-1.47,2.15h0s-1.16-2.36-1.16-2.36c.14-.08,.35-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.06,.33,.09,.34,.12,.58,.28,.7,.37Z"
        />
        <path
          style={styles.two}
          d="M465.1,754.52s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.24-1.92,1.27-1.97h0s1.07,2.07,1.07,2.07Z"
        />
        <path
          style={styles.two}
          d="M466.56,752.28c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.49l-1.86-1.5,2.37-1.03c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M464.03,752.45h0s-2.13,1.15-2.13,1.15c-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.46,.48,1.77,1.86,1.81,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M464.81,749.92h0s-.78,1.93-.78,1.93l-.56-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M466.56,752.28c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.73-.76,1.88-.59s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M464.52,754.71c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.06-.03,0-.05-.01-.07-.02l.75-1.83,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M463.63,752.41l-2.05,.46c-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92l1.94,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M466.07,757.35c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.49-.22,.24-.49,.43-.8,.57-.03,.02-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.06-.03,0-.05-.02-.08-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.48-.87-.82-.15-.22-.26-.47-.32-.74-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.34-.34,.77-.58,1.26-.69,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.03,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.74,.24,1.32,.79,1.59,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M465.02,755.37c-.49,.72-.98,1.44-1.47,2.15h0s-1.16-2.36-1.16-2.36c.14-.08,.35-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.06,.33,.09,.34,.12,.58,.28,.7,.37Z"
        />
        <path
          style={styles.two}
          d="M464.61,759.59s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.06-.03,0-.05-.01-.08-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.24-1.93,1.27-1.97h0s1.07,2.07,1.07,2.07Z"
        />
        <path
          style={styles.two}
          d="M466.07,757.35c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.49l-1.87-1.5,2.37-1.03c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M463.54,757.52h0s-2.13,1.15-2.13,1.15c-.15-.22-.26-.47-.32-.74-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.46,.48,1.77,1.86,1.81,1.9h0Z"
        />
        <path
          style={styles.four}
          d="M464.33,754.99h0s-.78,1.93-.78,1.93l-.56-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M466.07,757.35c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.73-.76,1.88-.59s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M464.03,759.78c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.06-.03,0-.05-.01-.08-.02l.75-1.83,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M463.14,757.48l-2.05,.46c-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92l1.94,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M476.19,735.6c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48-.22,.24-.49,.43-.79,.57-.03,.01-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.48-.86-.82-.14-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.33-.34,.77-.58,1.25-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.03,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.32,.8,1.58,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M475.14,733.62c-.49,.71-.98,1.43-1.47,2.14h0s-1.15-2.36-1.15-2.36c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.22,.06,.33,.09,.34,.12,.58,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M474.74,737.83s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.92,1.26-1.96h0s1.07,2.07,1.07,2.07Z"
        />
        <path
          style={styles.two}
          d="M476.19,735.6c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48l-1.86-1.5,2.36-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M473.67,735.76h0s-2.12,1.14-2.12,1.14c-.14-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.46,.48,1.77,1.86,1.81,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M474.45,733.24h0s-.78,1.92-.78,1.92l-.56-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M476.19,735.6c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.72-.77,1.87-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M474.16,738.01c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.82,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M473.27,735.71l-2.04,.45c-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92l1.94,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M479.07,739.66c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48-.22,.24-.49,.43-.79,.57-.03,.01-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.48-.86-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.33-.34,.77-.58,1.25-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.03,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.79,1.58,1.5,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M478.03,737.68c-.49,.71-.98,1.43-1.47,2.14h0s-1.15-2.36-1.15-2.36c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.33,.09,.34,.12,.58,.28,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M477.62,741.89s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.92,1.26-1.96h0s1.06,2.07,1.06,2.07Z"
        />
        <path
          style={styles.two}
          d="M479.07,739.66c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48l-1.86-1.5,2.36-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M476.56,739.82h0s-2.12,1.14-2.12,1.14c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.46,.48,1.76,1.86,1.8,1.9h0Z"
        />
        <path
          style={styles.four}
          d="M477.34,737.3h0s-.78,1.92-.78,1.92l-.55-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M479.07,739.66c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.72-.77,1.87-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M477.05,742.08c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.82,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M476.16,739.78l-2.04,.45c-.05-.19-.07-.39-.07-.59,0-.33,.06-.64,.18-.92l1.93,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M481.47,743.03c0,.11,0,.22-.02,.33,0,.02,0,.04-.01,.07,0,.04-.01,.08-.02,.12,0,.01,0,.03,0,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.04-.02,.05,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.11-.08,.17-.01,.02-.02,.04-.03,.06-.09,.16-.19,.31-.32,.45-.2,.22-.44,.4-.71,.53-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.04-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.09-.01-.17-.03-.25-.06-.02,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.1-.03-.19-.08-.28-.12-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.19-.58-.45-.77-.76-.13-.21-.23-.44-.29-.68-.04-.18-.07-.36-.07-.55,0-.3,.06-.59,.16-.85,.11-.28,.27-.53,.47-.74,.3-.31,.69-.54,1.12-.63,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.22-.02,.34-.02,.08,0,.15,0,.23,.01,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.04,.29,.08,.66,.22,1.18,.73,1.42,1.39,.04,.1,.07,.21,.09,.31,0,.02,.01,.05,.01,.07,0,0,0,.01,0,.02,0,.01,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.05,0,.07,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.03,0,.07,0,.1Z"
        />
        <path
          style={styles.two}
          d="M480.53,741.2c-.44,.66-.88,1.32-1.31,1.99h0s-1.03-2.18-1.03-2.18c.13-.07,.31-.16,.54-.21,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.01,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.29,.08,.3,.11,.52,.26,.62,.34Z"
        />
        <path
          style={styles.two}
          d="M480.17,745.1s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.04-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.09-.01-.17-.03-.25-.06-.02,0-.04-.01-.07-.02-.02,0-.04-.01-.06-.02-.1-.03-.19-.08-.28-.12-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.53,1.1-1.78,1.13-1.81h0s.95,1.91,.95,1.91Z"
        />
        <path
          style={styles.two}
          d="M481.47,743.03c0,.11,0,.22-.02,.33,0,.02,0,.04-.01,.07,0,.04-.01,.08-.02,.12,0,.01,0,.03,0,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.04-.02,.05,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.11-.08,.17-.01,.02-.02,.04-.03,.06-.09,.16-.19,.31-.32,.45l-1.66-1.39,2.11-.95c.04,.1,.07,.21,.09,.31,0,.02,.01,.05,.01,.07,0,0,0,.01,0,.02,0,.01,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.05,0,.07,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.03,0,.07,0,.1Z"
        />
        <path
          style={styles.two}
          d="M479.22,743.18h0s-1.9,1.06-1.9,1.06c-.13-.21-.23-.44-.29-.68-.04-.18-.07-.36-.07-.55,0-.3,.06-.59,.16-.85,.11-.28,.27-.53,.47-.74,.41,.45,1.58,1.72,1.62,1.76h0Z"
        />
        <path
          style={styles.four}
          d="M479.92,740.85h0s-.69,1.78-.69,1.78l-.5-1.84h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.01,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.04,.29,.08Z"
        />
        <path
          style={styles.four}
          d="M481.47,743.03c0,.11,0,.22-.02,.33,0,.02,0,.04-.01,.07,0,.04-.01,.08-.02,.12,0,.01,0,.03,0,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.04-.02,.05,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.54-.71,1.67-.54s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.05,0,.07,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.03,0,.07,0,.1Z"
        />
        <path
          style={styles.two}
          d="M479.66,745.27c-.09,.02-.17,.03-.26,.04-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.09-.01-.17-.03-.25-.06-.02,0-.04-.01-.07-.02l.67-1.69,.46,1.76Z"
        />
        <path
          style={styles.four}
          d="M478.86,743.14l-1.83,.42c-.04-.18-.07-.36-.07-.55,0-.3,.06-.59,.16-.85l1.73,.98Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M462.69,720.81c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48-.22,.24-.49,.43-.8,.57-.03,.01-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.02-.08-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.49-.87-.82-.15-.22-.26-.47-.32-.74-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92,.12-.3,.31-.57,.53-.8,.34-.34,.77-.58,1.26-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.02,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.09,.01,.13,.02,.11,.02,.22,.05,.33,.08,.74,.24,1.32,.8,1.59,1.51,.04,.11,.08,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M461.64,718.82c-.49,.71-.98,1.43-1.48,2.14h0s-1.16-2.37-1.16-2.37c.14-.08,.35-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.09,.01,.13,.02,.12,.02,.23,.06,.33,.09,.34,.12,.58,.29,.7,.37Z"
        />
        <path
          style={styles.two}
          d="M461.23,723.04s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.01-.08-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.24-1.92,1.27-1.96h0s1.07,2.08,1.07,2.08Z"
        />
        <path
          style={styles.two}
          d="M462.69,720.81c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48l-1.87-1.51,2.37-1.02c.04,.11,.08,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M460.16,720.96h0s-2.14,1.14-2.14,1.14c-.15-.22-.26-.47-.32-.74-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92,.12-.3,.31-.57,.53-.8,.46,.48,1.78,1.88,1.82,1.92h0Z"
        />
        <path
          style={styles.four}
          d="M460.94,718.43h0s-.78,1.93-.78,1.93l-.56-2h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.09,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M462.69,720.81c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.73-.77,1.88-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M460.65,723.22c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.01-.08-.02l.75-1.82,.52,1.91Z"
        />
        <path
          style={styles.four}
          d="M459.76,720.91l-2.06,.44c-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92l1.95,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M467.04,725.41c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48-.22,.24-.49,.43-.8,.57-.03,.01-.07,.03-.1,.04-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.65-.48-.87-.82-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.34-.34,.77-.58,1.26-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.03,.38-.02,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.32,.8,1.59,1.51,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M465.99,723.42c-.49,.71-.98,1.43-1.47,2.14h0s-1.16-2.37-1.16-2.37c.14-.08,.35-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.06,.33,.09,.34,.12,.58,.29,.7,.37Z"
        />
        <path
          style={styles.two}
          d="M465.58,727.64s-.07,.03-.1,.04c-.04,.02-.09,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.14-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.24-1.92,1.27-1.96h0s1.07,2.08,1.07,2.08Z"
        />
        <path
          style={styles.two}
          d="M467.04,725.41c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.09,.18-.01,.02-.02,.05-.04,.07-.1,.18-.22,.34-.35,.48l-1.86-1.51,2.37-1.02c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M464.51,725.56h0s-2.13,1.14-2.13,1.14c-.15-.22-.25-.47-.32-.74-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92,.12-.3,.3-.57,.53-.8,.46,.48,1.77,1.87,1.81,1.92h0Z"
        />
        <path
          style={styles.four}
          d="M465.29,723.04h0s-.78,1.92-.78,1.92l-.56-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.03,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M467.04,725.41c0,.12,0,.24-.03,.36,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.73-.77,1.88-.58s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M465,727.82c-.1,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.29-.07-.03,0-.05-.01-.07-.02l.75-1.82,.52,1.91Z"
        />
        <path
          style={styles.four}
          d="M464.11,725.52l-2.05,.45c-.05-.19-.07-.39-.07-.6,0-.33,.06-.64,.18-.92l1.94,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M489.14,716.99c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48-.22,.24-.49,.43-.79,.56-.03,.01-.07,.03-.1,.04-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03-.35-.2-.64-.48-.86-.82-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92,.12-.3,.3-.57,.53-.79,.33-.34,.76-.58,1.25-.68,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.12-.02,.25-.02,.38-.02,.09,0,.17,0,.26,.01,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.24,1.31,.8,1.57,1.51,.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M488.09,715.01c-.49,.71-.97,1.42-1.46,2.13h0s-1.15-2.36-1.15-2.36c.14-.08,.34-.17,.6-.23,0,0,.02,0,.03,0,.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.02,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.06,.32,.09,.34,.12,.58,.29,.69,.37Z"
        />
        <path
          style={styles.two}
          d="M487.69,719.21s-.07,.03-.1,.04c-.04,.02-.08,.03-.13,.05-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.04,.01-.09,.02-.13,.03-.03,0-.05,.01-.08,.02,0,0,0,0-.01,0-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.04-.21-.08-.31-.13-.02,0-.04-.02-.05-.03-.02,0-.04-.02-.05-.03,.33-.57,1.23-1.91,1.25-1.95h0s1.06,2.07,1.06,2.07Z"
        />
        <path
          style={styles.two}
          d="M489.14,716.99c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03-.03,.06-.05,.12-.08,.18-.01,.02-.02,.05-.04,.07-.1,.17-.21,.34-.35,.48l-1.85-1.51,2.35-1.01c.04,.11,.07,.22,.1,.34,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M486.63,717.14h0s-2.11,1.13-2.11,1.13c-.14-.22-.25-.47-.32-.73-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92,.12-.3,.3-.57,.53-.79,.45,.48,1.76,1.87,1.8,1.91h0Z"
        />
        <path
          style={styles.four}
          d="M487.41,714.63h0s-.77,1.91-.77,1.91l-.55-1.99h0s.02,0,.03,0c.05-.01,.1-.02,.15-.03,.08-.01,.16-.02,.25-.02,.14,0,.26,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M489.14,716.99c0,.12,0,.24-.03,.35,0,.02,0,.05-.01,.07,0,.04-.02,.08-.03,.13,0,.02,0,.03-.01,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.01,.03l-1.71-.77,1.86-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M487.12,719.39c-.09,.02-.19,.03-.29,.04-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13,0-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.07-.03,0-.05-.01-.07-.02l.75-1.81,.52,1.9Z"
        />
        <path
          style={styles.four}
          d="M486.23,717.1l-2.03,.44c-.05-.19-.07-.39-.07-.59,0-.32,.06-.64,.18-.92l1.93,1.07Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M481.76,687.84c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.38,.52-.24,.26-.54,.47-.87,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.04-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.07-.03,0-.06-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.38-.22-.71-.53-.94-.9-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1,.13-.33,.33-.62,.58-.87,.37-.37,.84-.63,1.37-.73,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.13-.02,.27-.03,.41-.02,.1,0,.19,0,.28,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.02,.12,.02,.24,.06,.36,.09,.8,.27,1.44,.88,1.73,1.66,.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M480.62,685.66c-.54,.77-1.07,1.55-1.6,2.33h0s-1.26-2.59-1.26-2.59c.15-.08,.38-.18,.66-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.09-.01,.17-.02,.27-.03,.15,0,.29,0,.43,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.02,.13,.03,.25,.06,.36,.1,.37,.13,.63,.32,.76,.41Z"
        />
        <path
          style={styles.two}
          d="M480.18,690.26s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.04-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.07-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.36-.62,1.35-2.08,1.38-2.13h0s1.16,2.27,1.16,2.27Z"
        />
        <path
          style={styles.two}
          d="M481.76,687.84c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.38,.52l-2.03-1.66,2.58-1.09c.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M479.01,687.99h0s-2.32,1.22-2.32,1.22c-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1,.13-.33,.33-.62,.58-.87,.5,.53,1.93,2.06,1.97,2.11h0Z"
        />
        <path
          style={styles.four}
          d="M479.87,685.24h0s-.85,2.09-.85,2.09l-.61-2.18h0s.02,0,.03,0c.05-.01,.11-.02,.17-.03,.09-.01,.17-.02,.27-.03,.15,0,.29,0,.43,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.02,.12,.02,.24,.06,.36,.09Z"
        />
        <path
          style={styles.four}
          d="M481.76,687.84c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04l-1.88-.86,2.04-.62s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M479.55,690.46c-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.07-.03,0-.05-.02-.08-.03l.82-1.98,.57,2.08Z"
        />
        <path
          style={styles.four}
          d="M478.58,687.93l-2.23,.47c-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1l2.12,1.19Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M477.44,683.72c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53-.24,.26-.54,.47-.87,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.04-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.06-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.38-.22-.71-.54-.94-.91-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.33-.62,.58-.87,.37-.37,.84-.63,1.37-.73,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.13-.02,.27-.03,.41-.02,.1,0,.19,0,.28,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.03,.12,.02,.24,.06,.36,.09,.8,.27,1.44,.89,1.73,1.66,.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M476.29,681.54c-.54,.78-1.07,1.55-1.61,2.33h0s-1.26-2.6-1.26-2.6c.15-.08,.38-.18,.66-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.03,.15,0,.29,0,.43,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.03,.13,.03,.25,.06,.36,.1,.37,.13,.63,.32,.76,.41Z"
        />
        <path
          style={styles.two}
          d="M475.85,686.15s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.04-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.36-.62,1.35-2.08,1.38-2.13h0s1.17,2.28,1.17,2.28Z"
        />
        <path
          style={styles.two}
          d="M477.44,683.72c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53l-2.03-1.67,2.58-1.09c.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M474.69,683.87h0s-2.32,1.22-2.32,1.22c-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.33-.62,.58-.87,.5,.53,1.93,2.06,1.98,2.11h0Z"
        />
        <path
          style={styles.four}
          d="M475.54,681.12h0s-.85,2.1-.85,2.1l-.61-2.18h0s.02,0,.03,0c.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.03,.15,0,.29,0,.43,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.03,.12,.02,.24,.06,.36,.09Z"
        />
        <path
          style={styles.four}
          d="M477.44,683.72c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04l-1.88-.86,2.05-.61s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M475.22,686.34c-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03l.82-1.98,.57,2.09Z"
        />
        <path
          style={styles.four}
          d="M474.25,683.82l-2.24,.47c-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01l2.12,1.19Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M473.11,678.59c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53-.24,.26-.54,.47-.87,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.06-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.38-.23-.71-.54-.95-.91-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.33-.62,.58-.87,.37-.37,.84-.63,1.37-.73,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.14-.02,.27-.03,.42-.02,.1,0,.19,0,.28,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.03,.12,.02,.24,.06,.36,.09,.8,.27,1.44,.89,1.73,1.67,.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M471.96,676.41c-.54,.78-1.07,1.55-1.61,2.33h0s-1.27-2.6-1.27-2.6c.15-.08,.38-.18,.66-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.03,.15,0,.29,0,.43,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.03,.13,.03,.25,.06,.36,.1,.37,.14,.63,.32,.76,.42Z"
        />
        <path
          style={styles.two}
          d="M471.52,681.02s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.36-.62,1.35-2.08,1.38-2.13h0s1.17,2.28,1.17,2.28Z"
        />
        <path
          style={styles.two}
          d="M473.11,678.59c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53l-2.04-1.67,2.59-1.09c.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M470.35,678.73h0s-2.33,1.22-2.33,1.22c-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.33-.62,.58-.87,.5,.53,1.94,2.07,1.98,2.11h0Z"
        />
        <path
          style={styles.four}
          d="M471.21,675.98h0s-.85,2.1-.85,2.1l-.61-2.19h0s.02,0,.03,0c.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.03,.15,0,.29,0,.43,.02,.03,0,.05,0,.07,.01,.05,0,.09,.02,.14,.03,.12,.02,.24,.06,.36,.09Z"
        />
        <path
          style={styles.four}
          d="M473.11,678.59c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04l-1.89-.86,2.05-.61s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M470.89,681.21c-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03l.82-1.98,.57,2.09Z"
        />
        <path
          style={styles.four}
          d="M469.92,678.68l-2.24,.47c-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01l2.12,1.19Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M468.78,674.46c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53-.24,.26-.54,.47-.87,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.06-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.38-.23-.71-.54-.95-.91-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.33-.62,.58-.87,.37-.37,.84-.63,1.38-.73,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.14-.02,.27-.02,.42-.02,.1,0,.19,0,.28,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.24,.06,.36,.09,.8,.27,1.45,.89,1.74,1.67,.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M467.63,672.27c-.54,.78-1.08,1.55-1.61,2.33h0s-1.27-2.6-1.27-2.6c.15-.08,.38-.18,.66-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.03,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.13,.03,.25,.06,.36,.11,.37,.14,.64,.32,.76,.42Z"
        />
        <path
          style={styles.two}
          d="M467.18,676.89s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.36-.62,1.35-2.08,1.38-2.13h0s1.17,2.28,1.17,2.28Z"
        />
        <path
          style={styles.two}
          d="M468.78,674.46c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53l-2.04-1.67,2.59-1.08c.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M466.01,674.6h0s-2.33,1.22-2.33,1.22c-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.33-.62,.58-.87,.5,.53,1.94,2.07,1.98,2.12h0Z"
        />
        <path
          style={styles.four}
          d="M466.87,671.85h0s-.85,2.1-.85,2.1l-.61-2.19h0s.02,0,.03,0c.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.03,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.24,.06,.36,.09Z"
        />
        <path
          style={styles.four}
          d="M468.78,674.46c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04l-1.89-.87,2.05-.61s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M466.55,677.08c-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03l.82-1.98,.57,2.09Z"
        />
        <path
          style={styles.four}
          d="M465.57,674.55l-2.25,.46c-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01l2.13,1.2Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M464.92,669.32c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53-.24,.26-.54,.47-.87,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.06-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.39-.23-.71-.54-.95-.91-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.33-.62,.58-.87,.37-.37,.84-.62,1.38-.73,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.14-.02,.28-.02,.42-.02,.1,0,.19,0,.28,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.25,.06,.36,.1,.81,.27,1.45,.89,1.74,1.67,.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M463.76,667.13c-.54,.78-1.08,1.55-1.62,2.33h0s-1.27-2.61-1.27-2.61c.15-.08,.38-.18,.66-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.13,.03,.25,.07,.36,.11,.37,.14,.64,.32,.76,.42Z"
        />
        <path
          style={styles.two}
          d="M463.32,671.74s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.36-.62,1.36-2.08,1.39-2.13h0s1.17,2.29,1.17,2.29Z"
        />
        <path
          style={styles.two}
          d="M464.92,669.32c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53l-2.04-1.68,2.6-1.08c.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.02,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M462.15,669.46h0s-2.34,1.22-2.34,1.22c-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.33-.62,.58-.87,.5,.54,1.94,2.07,1.99,2.12h0Z"
        />
        <path
          style={styles.four}
          d="M463.01,666.7h0s-.85,2.1-.85,2.1l-.61-2.19h0s.02,0,.03,0c.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.25,.06,.36,.1Z"
        />
        <path
          style={styles.four}
          d="M464.92,669.32c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04l-1.89-.87,2.06-.61s0,.02,0,.04c0,.02,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M462.68,671.94c-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03l.83-1.99,.57,2.09Z"
        />
        <path
          style={styles.four}
          d="M461.71,669.4l-2.25,.46c-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01l2.13,1.2Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M461.05,664.67c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53-.24,.26-.54,.47-.87,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.06-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.39-.23-.71-.54-.95-.91-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.33-.62,.58-.87,.37-.37,.84-.62,1.38-.73,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.14-.02,.28-.02,.42-.02,.1,0,.19,0,.28,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.25,.06,.36,.1,.81,.27,1.45,.89,1.74,1.68,.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M459.89,662.48c-.54,.78-1.08,1.55-1.62,2.33h0s-1.27-2.61-1.27-2.61c.15-.08,.38-.18,.66-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.13,.03,.25,.07,.36,.11,.37,.14,.64,.32,.76,.42Z"
        />
        <path
          style={styles.two}
          d="M459.45,667.1s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.36-.62,1.36-2.08,1.39-2.13h0s1.17,2.29,1.17,2.29Z"
        />
        <path
          style={styles.two}
          d="M461.05,664.67c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53l-2.05-1.68,2.6-1.08c.05,.12,.08,.24,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M458.28,664.81h0s-2.34,1.22-2.34,1.22c-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.33-.62,.58-.87,.5,.54,1.95,2.08,1.99,2.12h0Z"
        />
        <path
          style={styles.four}
          d="M459.13,662.05h0s-.86,2.1-.86,2.1l-.61-2.19h0s.02,0,.03,0c.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.25,.06,.36,.1Z"
        />
        <path
          style={styles.four}
          d="M461.05,664.67c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04l-1.89-.87,2.06-.61s0,.02,0,.04c0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M458.81,667.29c-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03l.83-1.99,.57,2.1Z"
        />
        <path
          style={styles.four}
          d="M457.83,664.75l-2.25,.46c-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01l2.13,1.2Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M458.14,660.04c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53-.24,.26-.54,.47-.87,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.06-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.39-.23-.71-.54-.95-.91-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.33-.62,.58-.87,.37-.37,.85-.62,1.38-.73,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.14-.02,.28-.02,.42-.02,.1,0,.19,0,.28,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.25,.06,.36,.1,.81,.27,1.45,.9,1.74,1.68,.05,.12,.08,.25,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M456.99,657.85c-.54,.78-1.08,1.55-1.62,2.33h0s-1.27-2.61-1.27-2.61c.15-.08,.38-.18,.66-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.13,.03,.25,.07,.36,.11,.37,.14,.64,.32,.76,.42Z"
        />
        <path
          style={styles.two}
          d="M456.54,662.47s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.03-.14,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.36-.62,1.36-2.08,1.39-2.13h0s1.17,2.29,1.17,2.29Z"
        />
        <path
          style={styles.two}
          d="M458.14,660.04c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53l-2.05-1.68,2.6-1.08c.05,.12,.08,.25,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M455.37,660.18h0s-2.34,1.22-2.34,1.22c-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.33-.62,.58-.87,.5,.54,1.95,2.08,1.99,2.13h0Z"
        />
        <path
          style={styles.four}
          d="M456.23,657.42h0s-.86,2.1-.86,2.1l-.61-2.2h0s.02,0,.03,0c.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.25,.06,.36,.1Z"
        />
        <path
          style={styles.four}
          d="M458.14,660.04c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04l-1.9-.87,2.06-.61s0,.02,0,.04c0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M455.91,662.66c-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08,0-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.04-.31-.08-.03,0-.05-.02-.08-.03l.83-1.99,.57,2.1Z"
        />
        <path
          style={styles.four}
          d="M454.93,660.12l-2.26,.46c-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01l2.14,1.2Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M454.75,654.38c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53-.25,.26-.54,.46-.87,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.02-.15,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.06-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.39-.23-.72-.54-.95-.92-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.34-.62,.58-.87,.37-.37,.85-.62,1.38-.73,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.14-.02,.28-.02,.42-.02,.1,0,.19,0,.28,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.25,.06,.36,.1,.81,.28,1.45,.9,1.75,1.68,.05,.12,.08,.25,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M453.59,652.18c-.54,.78-1.08,1.55-1.62,2.33h0s-1.28-2.62-1.28-2.62c.16-.08,.38-.18,.66-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.13,.03,.25,.07,.36,.11,.37,.14,.64,.32,.76,.42Z"
        />
        <path
          style={styles.two}
          d="M453.15,656.81s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.02-.15,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.36-.62,1.36-2.08,1.39-2.13h0s1.18,2.3,1.18,2.3Z"
        />
        <path
          style={styles.two}
          d="M454.75,654.38c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53l-2.05-1.69,2.61-1.08c.05,.12,.08,.25,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M451.97,654.51h0s-2.35,1.21-2.35,1.21c-.16-.25-.28-.52-.35-.81-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01,.13-.33,.34-.62,.58-.87,.5,.54,1.95,2.08,2,2.13h0Z"
        />
        <path
          style={styles.four}
          d="M452.83,651.75h0s-.86,2.1-.86,2.1l-.61-2.2h0s.02,0,.03,0c.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.25,.06,.36,.1Z"
        />
        <path
          style={styles.four}
          d="M454.75,654.38c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04l-1.9-.88,2.07-.6s0,.02,0,.04c0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M452.51,657c-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.05-.02-.08-.03l.83-1.99,.57,2.1Z"
        />
        <path
          style={styles.four}
          d="M451.53,654.45l-2.26,.46c-.05-.21-.08-.43-.08-.65,0-.36,.07-.7,.2-1.01l2.14,1.21Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M451.84,648.72c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53-.25,.26-.54,.46-.88,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.02-.15,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.06-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.39-.23-.72-.54-.95-.92-.16-.25-.28-.52-.35-.82-.05-.21-.08-.43-.08-.66,0-.36,.07-.7,.2-1.01,.13-.33,.34-.62,.59-.87,.37-.37,.85-.62,1.38-.73,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.14-.02,.28-.02,.42-.02,.1,0,.19,0,.28,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.25,.06,.36,.1,.81,.28,1.46,.9,1.75,1.69,.05,.12,.08,.25,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M450.68,646.52c-.54,.78-1.08,1.55-1.62,2.33h0s-1.28-2.62-1.28-2.62c.16-.08,.38-.18,.66-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.13,.03,.25,.07,.36,.11,.37,.14,.64,.33,.77,.42Z"
        />
        <path
          style={styles.two}
          d="M450.23,651.15s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.03-.05,.01-.1,.02-.15,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.09-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.36-.62,1.36-2.08,1.39-2.13h0s1.18,2.3,1.18,2.3Z"
        />
        <path
          style={styles.two}
          d="M451.84,648.72c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.53l-2.05-1.69,2.61-1.07c.05,.12,.08,.25,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M449.06,648.85h0s-2.35,1.21-2.35,1.21c-.16-.25-.28-.52-.35-.82-.05-.21-.08-.43-.08-.66,0-.36,.07-.7,.2-1.01,.13-.33,.34-.62,.59-.87,.5,.54,1.95,2.09,2,2.13h0Z"
        />
        <path
          style={styles.four}
          d="M449.92,646.09h0s-.86,2.1-.86,2.1l-.61-2.2h0s.02,0,.03,0c.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.25,.06,.36,.1Z"
        />
        <path
          style={styles.four}
          d="M451.84,648.72c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04l-1.9-.88,2.07-.6s0,.02,0,.04c0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M449.59,651.34c-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.05-.02-.08-.03l.83-1.99,.57,2.1Z"
        />
        <path
          style={styles.four}
          d="M448.61,648.79l-2.26,.45c-.05-.21-.08-.43-.08-.66,0-.36,.07-.7,.2-1.01l2.14,1.21Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M449.9,644.09c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.52-.25,.26-.54,.46-.88,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.1,.02-.15,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.06-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.1-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.39-.23-.72-.54-.96-.92-.16-.25-.28-.52-.35-.82-.05-.21-.08-.43-.08-.66,0-.36,.07-.7,.2-1.01,.13-.33,.34-.62,.59-.87,.37-.37,.85-.62,1.39-.72,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.14-.02,.28-.02,.42-.02,.1,0,.19,0,.28,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.25,.06,.36,.1,.81,.28,1.46,.9,1.75,1.69,.05,.12,.08,.25,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M448.74,641.89c-.54,.78-1.08,1.55-1.63,2.33h0s-1.28-2.62-1.28-2.62c.16-.08,.38-.18,.66-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.13,.03,.25,.07,.36,.11,.37,.14,.64,.33,.77,.42Z"
        />
        <path
          style={styles.two}
          d="M448.29,646.52s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.1,.02-.15,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.1-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.36-.62,1.36-2.08,1.4-2.13h0s1.18,2.3,1.18,2.3Z"
        />
        <path
          style={styles.two}
          d="M449.9,644.09c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.13-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.52l-2.06-1.69,2.61-1.07c.05,.12,.08,.25,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M447.11,644.22h0s-2.35,1.21-2.35,1.21c-.16-.25-.28-.52-.35-.82-.05-.21-.08-.43-.08-.66,0-.36,.07-.7,.2-1.01,.13-.33,.34-.62,.59-.87,.5,.54,1.95,2.09,2,2.14h0Z"
        />
        <path
          style={styles.four}
          d="M447.97,641.46h0s-.86,2.1-.86,2.1l-.61-2.2h0s.02,0,.03,0c.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.02,.25,.06,.36,.1Z"
        />
        <path
          style={styles.four}
          d="M449.9,644.09c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04l-1.9-.88,2.07-.6s0,.02,0,.04c0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M447.65,646.71c-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.05-.02-.08-.03l.83-1.99,.57,2.1Z"
        />
        <path
          style={styles.four}
          d="M446.67,644.16l-2.26,.45c-.05-.21-.08-.43-.08-.66,0-.36,.07-.7,.2-1.01l2.14,1.21Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M446.98,639.95c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.14-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.52-.25,.26-.54,.46-.88,.61-.04,.02-.07,.03-.11,.05-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.1,.02-.15,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.03-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.32-.08-.03,0-.06-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.1-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.39-.23-.72-.55-.96-.92-.16-.25-.28-.52-.35-.82-.05-.21-.08-.43-.08-.66,0-.36,.07-.7,.2-1.01,.13-.33,.34-.62,.59-.87,.37-.36,.85-.62,1.39-.72,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.14-.02,.28-.02,.42-.02,.1,0,.19,.01,.29,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.03,.25,.06,.36,.1,.81,.28,1.46,.9,1.75,1.69,.05,.12,.08,.25,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M445.82,637.75c-.54,.78-1.09,1.55-1.63,2.33h0s-1.28-2.62-1.28-2.62c.16-.08,.38-.18,.67-.24,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.3,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.13,.03,.25,.07,.36,.11,.37,.14,.64,.33,.77,.42Z"
        />
        <path
          style={styles.two}
          d="M445.37,642.38s-.07,.03-.11,.05c-.05,.02-.09,.04-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.1,.02-.15,.03-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.03-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.32-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.12-.04-.23-.1-.34-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.36-.62,1.37-2.08,1.4-2.13h0s1.18,2.3,1.18,2.3Z"
        />
        <path
          style={styles.two}
          d="M446.98,639.95c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04-.03,.07-.06,.14-.09,.2-.01,.02-.03,.05-.04,.07-.11,.19-.24,.37-.39,.52l-2.06-1.7,2.62-1.07c.05,.12,.08,.25,.11,.37,0,.03,.01,.06,.02,.09,0,0,0,.02,0,.03,0,.01,0,.02,0,.04,0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M444.19,640.07h0s-2.35,1.21-2.35,1.21c-.16-.25-.28-.52-.35-.82-.05-.21-.08-.43-.08-.66,0-.36,.07-.7,.2-1.01,.13-.33,.34-.62,.59-.87,.5,.54,1.96,2.09,2,2.14h0Z"
        />
        <path
          style={styles.four}
          d="M445.05,637.31h0s-.86,2.1-.86,2.1l-.62-2.2h0s.02,0,.03,0c.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.02,.15,0,.3,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.03,.25,.06,.36,.1Z"
        />
        <path
          style={styles.four}
          d="M446.98,639.95c0,.13,0,.26-.03,.39,0,.03,0,.05-.01,.08,0,.05-.02,.09-.03,.14,0,.02,0,.03-.01,.05,0,.03-.01,.05-.02,.08,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.05-.03,.07,0,.01,0,.03-.02,.04l-1.91-.88,2.07-.6s0,.02,0,.04c0,.03,0,.05,.01,.08,0,.03,0,.06,0,.09,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.04,0,.08,0,.12Z"
        />
        <path
          style={styles.two}
          d="M444.73,642.57c-.11,.02-.21,.03-.32,.03-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.32-.08-.03,0-.05-.02-.08-.03l.83-1.99,.57,2.11Z"
        />
        <path
          style={styles.four}
          d="M443.74,640.02l-2.27,.45c-.05-.21-.08-.43-.08-.66,0-.36,.07-.7,.2-1.01l2.15,1.22Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M444.06,634.11c0,.12,0,.25-.03,.37,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.05,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.02,.03-.03,.06-.06,.13-.09,.19-.01,.02-.02,.05-.04,.07-.1,.18-.23,.34-.38,.49-.24,.24-.52,.44-.84,.57-.04,.01-.07,.03-.11,.04-.04,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.2,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.11-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.04-.3-.07-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.22-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03-.37-.22-.69-.51-.92-.87-.15-.23-.27-.49-.34-.77-.05-.2-.08-.41-.08-.62,0-.34,.07-.66,.19-.95,.13-.31,.32-.58,.56-.81,.36-.34,.82-.58,1.33-.68,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.13-.02,.27-.02,.4-.02,.09,0,.18,0,.27,.02,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.09,.78,.26,1.4,.85,1.68,1.59,.04,.11,.08,.23,.11,.35,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.03,0,.01,0,.02,0,.03,0,.02,0,.05,.01,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.05,0,.07,0,.04,0,.08,0,.11Z"
        />
        <path
          style={styles.two}
          d="M442.94,632.03c-.52,.73-1.04,1.46-1.56,2.19h0s-1.23-2.47-1.23-2.47c.15-.08,.37-.17,.64-.22,0,0,.02,0,.03,0,.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.41,.02,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.03,.24,.06,.35,.1,.36,.13,.62,.31,.74,.4Z"
        />
        <path
          style={styles.two}
          d="M442.51,636.39s-.07,.03-.11,.04c-.04,.02-.09,.03-.14,.05-.02,0-.03,.01-.05,.02-.03,0-.06,.02-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,.01-.08,.02,0,0,0,0-.01,0-.1,.02-.2,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.11-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.04-.3-.07-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.02-.11-.04-.22-.09-.33-.15-.02,0-.04-.02-.06-.03-.02-.01-.04-.02-.06-.03,.35-.58,1.31-1.96,1.34-2h0s1.13,2.17,1.13,2.17Z"
        />
        <path
          style={styles.two}
          d="M444.06,634.11c0,.12,0,.25-.03,.37,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.05,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.02,.03-.03,.06-.06,.13-.09,.19-.01,.02-.02,.05-.04,.07-.1,.18-.23,.34-.38,.49l-1.98-1.6,2.51-1c.04,.11,.08,.23,.11,.35,0,.03,.01,.05,.02,.08,0,0,0,.02,0,.03,0,.01,0,.02,0,.03,0,.02,0,.05,.01,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.05,0,.07,0,.04,0,.08,0,.11Z"
        />
        <path
          style={styles.two}
          d="M441.38,634.22h0s-2.26,1.13-2.26,1.13c-.15-.23-.27-.49-.34-.77-.05-.2-.08-.41-.08-.62,0-.34,.07-.66,.19-.95,.13-.31,.32-.58,.56-.81,.48,.51,1.88,1.97,1.92,2.02h0Z"
        />
        <path
          style={styles.four}
          d="M442.21,631.63h0s-.83,1.97-.83,1.97l-.59-2.07h0s.02,0,.03,0c.05-.01,.11-.02,.16-.03,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.41,.02,.02,0,.05,0,.07,.01,.05,0,.09,.02,.13,.03,.12,.02,.24,.06,.35,.09Z"
        />
        <path
          style={styles.four}
          d="M444.06,634.11c0,.12,0,.25-.03,.37,0,.02,0,.05-.01,.07,0,.04-.02,.09-.03,.13,0,.02,0,.03-.01,.05,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.03-.02,.05-.03,.08,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.02,.03l-1.83-.83,1.99-.56s0,.02,0,.03c0,.02,0,.05,.01,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.05,0,.07,0,.04,0,.08,0,.11Z"
        />
        <path
          style={styles.two}
          d="M441.9,636.57c-.1,.02-.2,.03-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.11-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.04-.3-.07-.03,0-.05-.02-.08-.03l.8-1.87,.55,1.98Z"
        />
        <path
          style={styles.four}
          d="M440.95,634.17l-2.18,.42c-.05-.2-.08-.41-.08-.62,0-.34,.07-.66,.19-.95l2.06,1.15Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M441.97,628.7c0,.11,0,.22-.03,.33,0,.02,0,.04-.01,.07,0,.04-.02,.08-.03,.12,0,.01,0,.03-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.04-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.02,.03-.03,.06-.06,.11-.09,.17-.01,.02-.02,.04-.04,.06-.1,.16-.23,.31-.38,.44-.24,.22-.52,.39-.84,.51-.04,.01-.07,.03-.11,.04-.05,.02-.09,.03-.14,.04-.02,0-.03,0-.05,.01-.03,0-.06,.01-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,0-.08,.01,0,0,0,0-.01,0-.1,.01-.21,.02-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.01,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.04-.3-.07-.03,0-.05-.02-.08-.02-.02,0-.05-.01-.07-.02-.11-.04-.22-.08-.33-.13-.02,0-.04-.02-.06-.03-.02,0-.04-.02-.06-.03-.37-.2-.69-.47-.92-.78-.15-.21-.27-.45-.34-.69-.05-.18-.08-.37-.08-.56,0-.3,.07-.59,.19-.85,.13-.28,.32-.53,.56-.73,.36-.31,.82-.52,1.33-.61,0,0,.02,0,.03,0,.05,0,.11-.02,.16-.02,.13-.01,.27-.02,.4-.01,.09,0,.18,0,.27,.02,.02,0,.05,0,.07,0,.05,0,.09,.01,.13,.02,.12,.02,.24,.05,.35,.08,.78,.24,1.4,.77,1.68,1.44,.04,.1,.08,.21,.11,.32,0,.02,.01,.05,.02,.07,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.04,.01,.06,0,.02,0,.05,0,.07,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.03,0,.07,0,.1Z"
        />
        <path
          style={styles.two}
          d="M440.85,626.83c-.52,.65-1.04,1.31-1.56,1.96h0s-1.23-2.23-1.23-2.23c.15-.07,.37-.15,.64-.2,0,0,.02,0,.03,0,.05,0,.11-.02,.16-.02,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.42,.02,.02,0,.05,0,.07,0,.05,0,.09,.01,.13,.02,.12,.03,.24,.06,.35,.09,.36,.12,.62,.28,.74,.36Z"
        />
        <path
          style={styles.two}
          d="M440.42,630.75s-.07,.03-.11,.04c-.05,.02-.09,.03-.14,.04-.02,0-.03,0-.05,.01-.03,0-.06,.01-.09,.02-.05,.01-.09,.02-.14,.03-.03,0-.06,0-.08,.01,0,0,0,0-.01,0-.1,.01-.21,.02-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.01,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.04-.3-.07-.03,0-.05-.01-.08-.02-.02,0-.05-.01-.07-.02-.11-.04-.22-.08-.33-.13-.02,0-.04-.02-.06-.03-.02,0-.04-.02-.06-.03,.35-.53,1.31-1.76,1.34-1.8h0s1.13,1.96,1.13,1.96Z"
        />
        <path
          style={styles.two}
          d="M441.97,628.7c0,.11,0,.22-.03,.33,0,.02,0,.04-.01,.07,0,.04-.02,.08-.03,.12,0,.01,0,.03-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.04-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.02,.03-.03,.06-.06,.11-.09,.17-.01,.02-.02,.04-.04,.06-.1,.16-.23,.31-.38,.44l-1.98-1.45,2.52-.89c.04,.1,.08,.21,.11,.32,0,.02,.01,.05,.02,.07,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.04,.01,.06,0,.02,0,.05,0,.07,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.03,0,.07,0,.1Z"
        />
        <path
          style={styles.two}
          d="M439.29,628.79h0s-2.26,1.01-2.26,1.01c-.15-.21-.27-.45-.34-.69-.05-.18-.08-.37-.08-.56,0-.3,.07-.59,.19-.85,.13-.28,.32-.53,.56-.73,.49,.46,1.88,1.78,1.92,1.82h0Z"
        />
        <path
          style={styles.four}
          d="M440.12,626.46h0s-.83,1.78-.83,1.78l-.59-1.87h0s.02,0,.03,0c.05,0,.11-.02,.16-.02,.08-.01,.17-.02,.26-.02,.15,0,.28,0,.42,.02,.02,0,.05,0,.07,0,.05,0,.09,.01,.13,.02,.12,.02,.24,.05,.35,.08Z"
        />
        <path
          style={styles.four}
          d="M441.97,628.7c0,.11,0,.22-.03,.33,0,.02,0,.04-.01,.07,0,.04-.02,.08-.03,.12,0,.01,0,.03-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.04-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.05-.03,.07,0,0,0,0,0,.01,0,.02-.02,.04-.02,.06,0,.01,0,.02-.02,.03l-1.83-.76,1.99-.5s0,.02,0,.03c0,.02,0,.04,.01,.06,0,.02,0,.05,0,.07,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.03,0,.07,0,.1Z"
        />
        <path
          style={styles.two}
          d="M439.81,630.91c-.1,.01-.21,.02-.31,.03-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.07,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08,0-.12-.01,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.1-.02-.2-.04-.3-.07-.03,0-.05-.01-.08-.02l.8-1.68,.55,1.79Z"
        />
        <path
          style={styles.four}
          d="M438.86,628.74l-2.18,.37c-.05-.18-.08-.37-.08-.56,0-.3,.07-.59,.19-.85l2.06,1.04Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M439.67,623.81c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39-.22,.19-.49,.34-.79,.45-.03,.01-.07,.02-.1,.03-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03-.35-.17-.65-.41-.86-.7-.14-.19-.25-.39-.32-.61-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.65,.33-.27,.76-.46,1.25-.53,0,0,.02,0,.03,0,.05,0,.1-.02,.15-.02,.12-.01,.25-.02,.38-.01,.09,0,.17,0,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.21,1.31,.69,1.58,1.28,.04,.09,.07,.18,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M438.63,622.15c-.49,.58-.98,1.15-1.47,1.73h0s-1.15-1.97-1.15-1.97c.14-.06,.34-.13,.6-.17,0,0,.02,0,.03,0,.05,0,.1-.02,.15-.02,.08,0,.16-.01,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.05,.32,.08,.34,.11,.58,.25,.69,.32Z"
        />
        <path
          style={styles.two}
          d="M438.22,625.62s-.07,.02-.1,.03c-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03,.33-.46,1.23-1.55,1.26-1.58h0s1.06,1.73,1.06,1.73Z"
        />
        <path
          style={styles.two}
          d="M439.67,623.81c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39l-1.85-1.28,2.36-.78c.04,.09,.07,.18,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M437.16,623.88h0s-2.12,.89-2.12,.89c-.14-.19-.25-.39-.32-.61-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.65,.45,.41,1.76,1.58,1.8,1.62h0Z"
        />
        <path
          style={styles.four}
          d="M437.94,621.82h0s-.77,1.57-.77,1.57l-.55-1.65h0s.02,0,.03,0c.05,0,.1-.02,.15-.02,.08,0,.16-.01,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M439.67,623.81c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03l-1.72-.67,1.87-.43s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M437.65,625.75c-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02l.75-1.48,.52,1.58Z"
        />
        <path
          style={styles.four}
          d="M436.76,623.84l-2.04,.32c-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75l1.93,.92Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M437.85,619.68c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39-.22,.19-.49,.34-.79,.45-.03,.01-.07,.02-.1,.03-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03-.35-.17-.65-.41-.86-.7-.14-.19-.25-.39-.32-.61-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.65,.33-.27,.76-.46,1.25-.53,0,0,.02,0,.03,0,.05,0,.1-.02,.15-.02,.12-.01,.25-.02,.38-.01,.09,0,.17,0,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.21,1.31,.69,1.58,1.28,.04,.09,.07,.18,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M436.81,618.02c-.49,.58-.98,1.15-1.47,1.73h0s-1.15-1.98-1.15-1.98c.14-.06,.34-.13,.6-.17,0,0,.02,0,.03,0,.05,0,.1-.02,.15-.02,.08,0,.16-.01,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.05,.33,.08,.34,.11,.58,.25,.69,.32Z"
        />
        <path
          style={styles.two}
          d="M436.4,621.48s-.07,.02-.1,.03c-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03,.33-.46,1.23-1.55,1.26-1.58h0s1.06,1.73,1.06,1.73Z"
        />
        <path
          style={styles.two}
          d="M437.85,619.68c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39l-1.85-1.29,2.36-.78c.04,.09,.07,.18,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M435.34,619.75h0s-2.12,.89-2.12,.89c-.14-.19-.25-.39-.32-.61-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.65,.45,.41,1.76,1.58,1.8,1.62h0Z"
        />
        <path
          style={styles.four}
          d="M436.12,617.69h0s-.78,1.57-.78,1.57l-.55-1.65h0s.02,0,.03,0c.05,0,.1-.02,.15-.02,.08,0,.16-.01,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M437.85,619.68c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03l-1.72-.68,1.87-.43s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M435.83,621.62c-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02l.75-1.48,.52,1.58Z"
        />
        <path
          style={styles.four}
          d="M434.94,619.7l-2.04,.32c-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75l1.93,.93Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M436.39,615.04c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39-.22,.19-.49,.34-.79,.45-.03,.01-.07,.02-.1,.03-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03-.35-.18-.65-.41-.86-.7-.14-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.33-.27,.77-.46,1.25-.53,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.12-.01,.25-.02,.38-.01,.09,0,.17,0,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.22,1.32,.69,1.58,1.28,.04,.09,.07,.18,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M435.34,613.38c-.49,.58-.98,1.15-1.47,1.73h0s-1.15-1.98-1.15-1.98c.14-.06,.34-.13,.6-.17,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.05,.33,.08,.34,.11,.58,.25,.69,.32Z"
        />
        <path
          style={styles.two}
          d="M434.94,616.85s-.07,.02-.1,.03c-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03,.33-.46,1.23-1.55,1.26-1.58h0s1.06,1.74,1.06,1.74Z"
        />
        <path
          style={styles.two}
          d="M436.39,615.04c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39l-1.86-1.29,2.36-.78c.04,.09,.07,.18,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M433.87,615.11h0s-2.12,.89-2.12,.89c-.14-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.46,.41,1.76,1.58,1.8,1.62h0Z"
        />
        <path
          style={styles.four}
          d="M434.65,613.05h0s-.78,1.57-.78,1.57l-.55-1.66h0s.02,0,.03,0c.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.02,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M436.39,615.04c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03l-1.72-.68,1.87-.43s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M434.36,616.98c-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02l.75-1.48,.52,1.58Z"
        />
        <path
          style={styles.four}
          d="M433.47,615.06l-2.04,.32c-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75l1.93,.93Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M434.92,610.91c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39-.22,.19-.49,.34-.79,.45-.03,.01-.07,.02-.1,.03-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03-.35-.18-.65-.42-.86-.7-.14-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.33-.27,.77-.46,1.25-.53,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.12-.01,.25-.01,.38-.01,.09,0,.17,0,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08,.73,.22,1.32,.69,1.58,1.28,.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M433.87,609.25c-.49,.58-.98,1.15-1.47,1.73h0s-1.15-1.98-1.15-1.98c.14-.06,.34-.13,.6-.17,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.12,.02,.22,.05,.33,.08,.34,.11,.58,.25,.69,.32Z"
        />
        <path
          style={styles.two}
          d="M433.47,612.72s-.07,.02-.1,.03c-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03,.33-.46,1.23-1.55,1.26-1.58h0s1.06,1.74,1.06,1.74Z"
        />
        <path
          style={styles.two}
          d="M434.92,610.91c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39l-1.86-1.29,2.36-.78c.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M432.41,610.98h0s-2.12,.88-2.12,.88c-.14-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.46,.41,1.77,1.59,1.81,1.62h0Z"
        />
        <path
          style={styles.four}
          d="M433.18,608.92h0s-.78,1.57-.78,1.57l-.55-1.66h0s.02,0,.03,0c.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.12,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M434.92,610.91c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03l-1.72-.68,1.87-.43s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M432.89,612.85c-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02l.75-1.48,.52,1.58Z"
        />
        <path
          style={styles.four}
          d="M432,610.93l-2.04,.32c-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75l1.94,.93Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M433.45,606.78c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39-.22,.19-.49,.34-.79,.45-.03,.01-.07,.02-.1,.03-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03-.35-.18-.65-.42-.86-.7-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.33-.27,.77-.46,1.25-.53,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.12-.01,.25-.01,.38-.01,.09,0,.17,0,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.22,1.32,.69,1.58,1.28,.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M432.41,605.12c-.49,.58-.98,1.15-1.47,1.73h0s-1.15-1.98-1.15-1.98c.14-.06,.34-.13,.6-.17,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.22,.05,.33,.08,.34,.11,.58,.25,.69,.33Z"
        />
        <path
          style={styles.two}
          d="M432,608.59s-.07,.02-.1,.03c-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03,.33-.46,1.23-1.55,1.26-1.58h0s1.07,1.74,1.07,1.74Z"
        />
        <path
          style={styles.two}
          d="M433.45,606.78c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39l-1.86-1.29,2.36-.78c.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M430.94,606.85h0s-2.12,.88-2.12,.88c-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.46,.41,1.77,1.59,1.81,1.62h0Z"
        />
        <path
          style={styles.four}
          d="M431.72,604.79h0s-.78,1.57-.78,1.57l-.56-1.66h0s.02,0,.03,0c.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M433.45,606.78c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03l-1.72-.68,1.87-.43s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M431.42,608.72c-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02l.75-1.48,.52,1.58Z"
        />
        <path
          style={styles.four}
          d="M430.54,606.8l-2.04,.31c-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75l1.94,.93Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M432.47,602.67c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39-.22,.19-.49,.34-.79,.45-.03,.01-.07,.02-.1,.03-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03-.35-.18-.65-.42-.86-.7-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.34-.27,.77-.46,1.25-.53,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.12-.01,.25-.01,.38-.01,.09,0,.17,0,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.22,1.32,.69,1.58,1.29,.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M431.43,601c-.49,.58-.98,1.15-1.47,1.73h0s-1.16-1.98-1.16-1.98c.14-.06,.34-.13,.6-.17,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.05,.33,.09,.34,.11,.58,.25,.69,.33Z"
        />
        <path
          style={styles.two}
          d="M431.02,604.47s-.07,.02-.1,.03c-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03,.33-.46,1.23-1.55,1.26-1.58h0s1.07,1.74,1.07,1.74Z"
        />
        <path
          style={styles.two}
          d="M432.47,602.67c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39l-1.86-1.29,2.36-.78c.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M429.96,602.73h0s-2.13,.88-2.13,.88c-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.46,.41,1.77,1.59,1.81,1.63h0Z"
        />
        <path
          style={styles.four}
          d="M430.74,600.67h0s-.78,1.57-.78,1.57l-.56-1.66h0s.02,0,.03,0c.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M432.47,602.67c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03l-1.72-.68,1.87-.43s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M430.44,604.61c-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02l.75-1.48,.52,1.58Z"
        />
        <path
          style={styles.four}
          d="M429.56,602.68l-2.05,.31c-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75l1.94,.93Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M431.5,599.06c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39-.22,.19-.49,.34-.79,.45-.03,.01-.07,.02-.1,.03-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03-.35-.18-.65-.42-.86-.7-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.34-.27,.77-.46,1.25-.53,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.12-.01,.25-.01,.38,0,.09,0,.17,0,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.22,1.32,.69,1.58,1.29,.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M430.45,597.39c-.49,.58-.98,1.15-1.47,1.73h0s-1.16-1.98-1.16-1.98c.14-.06,.34-.13,.6-.17,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.05,.33,.09,.34,.11,.58,.25,.69,.33Z"
        />
        <path
          style={styles.two}
          d="M430.04,600.86s-.07,.02-.1,.03c-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03,.33-.46,1.23-1.55,1.26-1.58h0s1.07,1.74,1.07,1.74Z"
        />
        <path
          style={styles.two}
          d="M431.5,599.06c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39l-1.86-1.3,2.36-.77c.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M428.98,599.12h0s-2.13,.88-2.13,.88c-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.46,.41,1.77,1.59,1.81,1.63h0Z"
        />
        <path
          style={styles.four}
          d="M429.76,597.06h0s-.78,1.57-.78,1.57l-.56-1.66h0s.02,0,.03,0c.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M431.5,599.06c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03l-1.72-.68,1.87-.43s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M429.46,601c-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02l.75-1.48,.52,1.59Z"
        />
        <path
          style={styles.four}
          d="M428.58,599.07l-2.05,.31c-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75l1.94,.94Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M430.52,594.94c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39-.22,.19-.49,.34-.79,.45-.03,.01-.07,.02-.1,.03-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03-.35-.18-.65-.42-.86-.7-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.34-.27,.77-.46,1.25-.53,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.12-.01,.25-.01,.38,0,.09,0,.17,0,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.22,1.32,.7,1.58,1.29,.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M429.47,593.27c-.49,.57-.98,1.15-1.47,1.73h0s-1.16-1.98-1.16-1.98c.14-.06,.34-.13,.6-.17,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.05,.33,.09,.34,.11,.58,.25,.69,.33Z"
        />
        <path
          style={styles.two}
          d="M429.06,596.74s-.07,.02-.1,.03c-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03,.33-.46,1.23-1.55,1.26-1.58h0s1.07,1.74,1.07,1.74Z"
        />
        <path
          style={styles.two}
          d="M430.52,594.94c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39l-1.86-1.3,2.36-.77c.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M428,595h0s-2.13,.88-2.13,.88c-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.46,.41,1.77,1.59,1.81,1.63h0Z"
        />
        <path
          style={styles.four}
          d="M428.78,592.94h0s-.78,1.56-.78,1.56l-.56-1.66h0s.02,0,.03,0c.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M430.52,594.94c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03l-1.72-.68,1.87-.42s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M428.48,596.88c-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02l.75-1.48,.52,1.59Z"
        />
        <path
          style={styles.four}
          d="M427.59,594.95l-2.05,.31c-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75l1.94,.94Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M429.54,590.31c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39-.22,.19-.49,.34-.79,.44-.03,.01-.07,.02-.1,.03-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03-.35-.18-.65-.42-.86-.7-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.34-.27,.77-.46,1.25-.52,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.12-.01,.25-.01,.38,0,.09,0,.17,.01,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.22,1.32,.7,1.58,1.29,.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M428.49,588.64c-.49,.57-.98,1.15-1.47,1.72h0s-1.16-1.99-1.16-1.99c.14-.06,.34-.13,.6-.17,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.05,.33,.09,.34,.11,.58,.25,.69,.33Z"
        />
        <path
          style={styles.two}
          d="M428.08,592.11s-.07,.02-.1,.03c-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03,.33-.46,1.24-1.55,1.26-1.58h0s1.07,1.74,1.07,1.74Z"
        />
        <path
          style={styles.two}
          d="M429.54,590.31c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39l-1.86-1.3,2.37-.77c.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M427.01,590.36h0s-2.13,.88-2.13,.88c-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.46,.41,1.77,1.59,1.81,1.63h0Z"
        />
        <path
          style={styles.four}
          d="M427.8,588.3h0s-.78,1.56-.78,1.56l-.56-1.66h0s.02,0,.03,0c.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M429.54,590.31c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03l-1.72-.69,1.87-.42s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M427.5,592.24c-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02l.75-1.48,.52,1.59Z"
        />
        <path
          style={styles.four}
          d="M426.61,590.32l-2.05,.31c-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75l1.94,.94Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M429.05,586.2c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39-.22,.19-.49,.34-.79,.44-.03,.01-.07,.02-.1,.03-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03-.35-.18-.65-.42-.87-.7-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.34-.27,.77-.45,1.25-.52,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.12-.01,.25-.01,.38,0,.09,0,.17,.01,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.22,1.32,.7,1.58,1.29,.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M428,584.53c-.49,.57-.98,1.15-1.47,1.72h0s-1.16-1.99-1.16-1.99c.14-.06,.34-.13,.6-.17,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.05,.33,.09,.34,.11,.58,.25,.69,.33Z"
        />
        <path
          style={styles.two}
          d="M427.59,588s-.07,.02-.1,.03c-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03,.33-.46,1.24-1.54,1.26-1.58h0s1.07,1.74,1.07,1.74Z"
        />
        <path
          style={styles.two}
          d="M429.05,586.2c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39l-1.86-1.3,2.37-.77c.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M426.52,586.26h0s-2.13,.87-2.13,.87c-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.46,.41,1.77,1.6,1.81,1.63h0Z"
        />
        <path
          style={styles.four}
          d="M427.31,584.2h0s-.78,1.56-.78,1.56l-.56-1.66h0s.02,0,.03,0c.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M429.05,586.2c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,.01,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03l-1.72-.69,1.87-.42s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M427.01,588.13c-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02l.75-1.48,.52,1.59Z"
        />
        <path
          style={styles.four}
          d="M426.12,586.21l-2.05,.31c-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75l1.94,.94Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M429.54,582.64c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,0,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39-.22,.19-.49,.34-.79,.44-.03,.01-.07,.02-.1,.03-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03-.35-.18-.65-.42-.86-.7-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.34-.27,.77-.45,1.25-.52,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.12-.01,.25-.01,.38,0,.09,0,.17,.01,.26,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08,.73,.22,1.32,.7,1.58,1.29,.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M428.49,580.97c-.49,.57-.98,1.15-1.47,1.72h0s-1.16-1.99-1.16-1.99c.14-.06,.34-.13,.6-.17,0,0,.02,0,.03,0,.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.12,.02,.23,.05,.33,.09,.34,.11,.58,.25,.69,.33Z"
        />
        <path
          style={styles.two}
          d="M428.08,584.44s-.07,.02-.1,.03c-.04,.01-.09,.03-.13,.04-.02,0-.03,0-.05,.01-.03,0-.05,.01-.08,.02-.04,0-.09,.02-.13,.02-.03,0-.05,0-.08,.01,0,0,0,0-.01,0-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02-.02,0-.04-.01-.06-.02-.11-.03-.21-.07-.31-.12-.02,0-.04-.02-.05-.02-.02,0-.04-.02-.05-.03,.33-.46,1.24-1.54,1.26-1.58h0s1.07,1.74,1.07,1.74Z"
        />
        <path
          style={styles.two}
          d="M429.54,582.64c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,0,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03-.03,.05-.05,.1-.09,.15-.01,.02-.02,.04-.04,.05-.1,.14-.22,.27-.35,.39l-1.86-1.3,2.37-.77c.04,.09,.07,.19,.1,.28,0,.02,.01,.04,.02,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M427.01,582.7h0s-2.13,.87-2.13,.87c-.15-.19-.25-.4-.32-.62-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75,.12-.24,.3-.46,.53-.64,.46,.41,1.77,1.6,1.81,1.63h0Z"
        />
        <path
          style={styles.four}
          d="M427.8,580.64h0s-.78,1.56-.78,1.56l-.56-1.66h0s.02,0,.03,0c.05,0,.1-.01,.15-.02,.08,0,.16-.01,.25-.01,.14,0,.27,0,.39,.02,.02,0,.05,0,.07,0,.04,0,.08,.01,.13,.02,.11,.02,.22,.05,.33,.08Z"
        />
        <path
          style={styles.four}
          d="M429.54,582.64c0,.1,0,.2-.03,.29,0,.02,0,.04-.01,.06,0,.04-.02,.07-.03,.1,0,.01,0,.02-.01,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.02,.04-.03,.06,0,0,0,0,0,0,0,.02-.02,.03-.02,.05,0,0,0,.02-.01,.03l-1.72-.69,1.87-.42s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.04,0,.05,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M427.5,584.57c-.1,.01-.19,.02-.29,.02-.03,0-.06,0-.09,0-.04,0-.07,0-.11,0-.03,0-.07,0-.1,0-.04,0-.09,0-.13-.01-.04,0-.07,0-.11-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.1-.02-.19-.04-.28-.06-.03,0-.05-.01-.07-.02l.75-1.48,.52,1.59Z"
        />
        <path
          style={styles.four}
          d="M426.61,582.65l-2.05,.31c-.05-.16-.07-.32-.07-.49,0-.27,.06-.52,.18-.75l1.94,.94Z"
        />
      </g>
      <path
        style={styles.six}
        d="M484.23,693.86h0c.42,.14,.85,.25,1.31,.34h0"
      />
      <path
        style={styles.six}
        d="M482.45,693.11c-1.28-.67-2.28-1.48-2.98-2.04-10.28-8.24-17.45-16.85-22.04-23.13-8.5-11.62-13.21-22.12-19.13-35.35-3.64-8.12-6.94-15.58-9.88-26.39-2.46-9.03-3.72-16.94-4.42-22.83-.05-.43,.07-.86,.32-1.16,1.2-1.44,2.76-2.04,4.1-1.49,.36,.15,.67,.36,.93,.6,.39,.35,.56,.96,.43,1.51-.12,.5-.23,1.09-.31,1.75,0,0-.22,1.84,0,3.82,2.15,19.28,11.95,39.83,11.95,39.83,1.71,3.59,3.36,6.86,4.92,9.82,3.91,7.43,7.27,13.03,9.04,15.91,0,0,10.28,16.76,24.61,30.81,1.03,1.01,2.85,2.67,5.29,2.91,1.21,.12,2.32-.13,2.86-.26,1.95-.45,3.35-1.35,4.06-1.82,10.21-6.78,15.68-12.91,15.68-12.91,3.21-3.62,5.69-7.01,8.05-10.49,8.05-11.86,13.22-22.72,15.53-27.74,8.84-19.29,15.7-34.26,10.96-43.27-.5-.95-1.05-1.7-1.57-2.28-.23-.25-.41-1.32-.05-1.97,.46-.84,1.81-.73,2.07-.71,0,0,.05,0,.1,0,.3,.05,1.89,.38,3.42,2.61,.89,1.3,3.83,9.19-1.49,27.32-2.07,7.05-4.24,11.74-7.96,19.69-3.83,8.19-6.88,14.7-11.98,22.79-7.37,11.68-13.93,18.59-18.59,23.43,0,0-4.1,4.26-10.62,8.62-3.85,2.58-6.06,3.5-8.08,3.64"
      />
      <path
        style={styles.six}
        d="M506.11,724.28s-.56-4.92-4.52-11.36c-.42-.68-.92-1.37-1.65-2.02-.63-.56-1.23-.91-1.6-1.11-.33-.09-1.09-.24-1.98,.05-1.12,.37-1.72,1.21-1.91,1.49-1.8,2.27-3.01,4.39-3.82,6.01,0,0-2.14,4.39-3.04,8.8,0,0,0,0,0,.01-.71,2.47-5.46,19.79,1.61,25.5,.45,.36,.95,.68,1.52,.95,.03,.01,.06,.02,.1,.01,1.23-.21,2.83-.62,4.59-1.46,1.48-.71,2.66-1.52,3.54-2.23,.02-.02,.04-.04,.05-.06,.55-1.24,1.1-2.49,1.65-3.73,.22-.45,.56-1.15,.95-2.01,2-4.37,3.6-7.85,4.28-12.06,.22-1.36,.59-3.72,.24-6.79Zm-5.94,9.77c-.98,2.88-2.38,6.67-4.33,11.03-.49,1.57-2,2.42-3.29,2.04-1.01-.3-1.78-1.31-1.91-2.53-.57-4.68-.9-12.79,2.39-21.68,1.27-3.43,2.82-6.27,4.29-8.53,.49,.72,1.17,1.75,1.91,3.04,.73,1.29,2.28,3.99,2.86,6.07,.45,1.6,.5,3.48-1.9,10.56Z"
      />
      <path
        style={styles.six}
        d="M468.4,725.77l-6.87-7.43c-1.09-.89-2.56-.84-3.39-.03-1.02,.99-1,3.02,.34,4.18,2.87,2.71,5.73,5.41,8.59,8.12-1.66,4.54-3.28,9.77-4.58,15.66-.87,3.95-1.5,7.7-1.93,11.16,.04,.36,.3,2.27,1.93,3.55,1.19,.93,2.44,1.01,2.9,1.02-.16-.68-.32-1.35-.48-2.03l5.31-24.83,7.7,9.66c.55,1.17,1.63,1.76,2.4,1.53,.95-.28,1.71-1.91,.96-3.54-.54-1.51-1.31-3.25-2.4-5.07-2.2-3.67-4.8-6.12-6.73-7.63,1.93-5.73,3.85-11.45,5.77-17.16l6.72,3.6c-.21,1.46,.71,2.8,1.92,3.05,1.34,.28,2.78-.85,2.87-2.5,1.85-2.13,3.7-4.26,5.54-6.39,.02-.13,.33-2.98-1.72-4.67-1.39-1.14-2.96-1.02-3.33-.98-1.03,.59-2.06,1.18-3.09,1.78l-6.65-.48-6.1,5.03-5.68,14.41Z"
      />
      <path
        style={styles.six}
        d="M484.64,692.28c-1.85-.03-3.36,1.54-3.36,3.49s1.5,3.56,3.36,3.58,3.35-1.54,3.35-3.49-1.5-3.56-3.35-3.58Zm.9,1.92h0c.33,.33,.53,.83,.53,1.38,0,.97-.64,1.76-1.44,1.75s-1.44-.81-1.44-1.79c0-.8,.43-1.46,1.02-1.68h0"
      />
      <path
        style={styles.five}
        d="M482.56,706.51c-.2-.41-.31-.88-.31-1.38,0-1.54,1.07-2.77,2.4-2.75s2.39,1.27,2.39,2.81c0,.6-.17,1.16-.45,1.61m1.77-.86c.08-.39,.11-.81,.11-1.24,0-2.93-1.82-5.32-4.07-5.35s-4.08,2.32-4.08,5.26c0,.62,.08,1.21,.23,1.76"
      />
      <path
        style={styles.two}
        d="M484.64,692.28c-1.85-.03-3.36,1.54-3.36,3.49s1.5,3.56,3.36,3.58,3.35-1.54,3.35-3.49-1.5-3.56-3.35-3.58Zm0,5.05c-.79-.01-1.44-.81-1.44-1.79s.64-1.76,1.44-1.75,1.44,.81,1.44,1.79-.64,1.76-1.44,1.75Z"
      />
      <path
        style={styles.three}
        d="M532.09,582.91c-.3,.6-.14,1.58,.04,1.82,.43,.54,.89,1.23,1.31,2.1,3.95,8.3-1.76,22.09-9.11,39.85-1.92,4.63-6.22,14.63-12.91,25.55-1.97,3.21-4.03,6.33-6.69,9.66,0,0-4.55,5.65-13.04,11.9-.6,.44-1.76,1.26-3.38,1.68-.45,.12-1.37,.35-2.38,.24-2.03-.22-3.54-1.75-4.4-2.68-11.91-12.94-20.46-28.38-20.46-28.38-1.47-2.66-4.26-7.81-7.51-14.65-1.3-2.73-2.67-5.74-4.09-9.05,0,0-8.14-18.93-9.93-36.68-.18-1.82,0-3.52,0-3.52,.07-.61,.16-1.15,.26-1.61,.11-.51-.03-1.07-.36-1.39-.22-.22-.47-.42-.77-.55-1.11-.5-2.41,.05-3.41,1.38-.21,.28-.31,.67-.26,1.07,.58,5.43,1.63,12.71,3.67,21.03,2.44,9.95,5.19,16.83,8.21,24.3,4.92,12.18,8.83,21.85,15.9,32.55,3.81,5.78,9.77,13.71,18.32,21.3,1.11,.98,3.15,2.81,5.93,3,1.95,.13,3.83-.56,7.6-3.35,5.42-4.02,8.83-7.94,8.83-7.94,3.87-4.46,9.33-10.82,15.46-21.58,4.25-7.45,6.78-13.45,9.96-20.99,3.09-7.33,4.89-11.64,6.61-18.14,4.42-16.69,1.98-23.96,1.24-25.16-1.27-2.05-2.59-2.36-2.85-2.4-.04,0-.08,0-.08,0-.22-.02-1.34-.12-1.72,.66Z"
      />
      <g>
        <path
          style={styles.one}
          d="M497.14,674.79c0,.15,0,.29-.03,.43,0,.03,0,.06-.01,.09,0,.05-.02,.1-.03,.15,0,.02,0,.04-.01,.05,0,.03-.01,.06-.02,.09,0,.02-.01,.05-.02,.07,0,0,0,.02,0,.03,0,.03-.02,.06-.03,.09,0,0,0,.01,0,.01,0,.03-.02,.05-.03,.08,0,.01,0,.03-.02,.04-.03,.08-.06,.15-.09,.22-.01,.03-.03,.06-.04,.08-.11,.21-.24,.41-.39,.59-.24,.29-.54,.52-.87,.69-.04,.02-.07,.04-.11,.05-.05,.02-.09,.04-.14,.06-.02,0-.03,.01-.05,.02-.03,.01-.06,.02-.09,.03-.05,.02-.1,.03-.14,.04-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.06-.02-.08-.03-.02,0-.05-.02-.07-.03-.12-.05-.23-.1-.34-.17-.02-.01-.04-.02-.06-.03-.02-.01-.04-.03-.06-.04-.39-.25-.71-.6-.95-1.01-.16-.28-.28-.58-.35-.91-.05-.23-.08-.48-.08-.73,0-.4,.07-.78,.2-1.13,.13-.37,.33-.7,.58-.97,.37-.41,.84-.7,1.38-.82,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.14-.02,.28-.03,.42-.03,.1,0,.19,0,.28,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.03,.25,.06,.36,.11,.81,.3,1.45,.99,1.74,1.86,.05,.13,.08,.27,.11,.41,0,.03,.01,.06,.02,.1,0,.01,0,.02,0,.03,0,.01,0,.03,0,.04,0,.03,0,.06,.01,.08,0,.03,0,.06,0,.1,0,.03,0,.05,0,.08,0,.03,0,.05,0,.08,0,.05,0,.09,0,.13Z"
        />
        <path
          style={styles.two}
          d="M495.99,672.35c-.54,.87-1.08,1.74-1.62,2.61h0s-1.27-2.91-1.27-2.91c.15-.09,.38-.2,.66-.27,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.03,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.13,.03,.25,.07,.36,.12,.37,.15,.64,.36,.76,.46Z"
        />
        <path
          style={styles.two}
          d="M495.54,677.51s-.07,.04-.11,.05c-.05,.02-.09,.04-.14,.06-.02,0-.03,.01-.05,.02-.03,.01-.06,.02-.09,.03-.05,.02-.1,.03-.14,.04-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.03-.12-.05-.23-.1-.34-.17-.02-.01-.04-.02-.06-.03-.02-.01-.04-.03-.06-.04,.36-.7,1.36-2.34,1.39-2.39h0s1.17,2.55,1.17,2.55Z"
        />
        <path
          style={styles.two}
          d="M497.14,674.79c0,.15,0,.29-.03,.43,0,.03,0,.06-.01,.09,0,.05-.02,.1-.03,.15,0,.02,0,.04-.01,.05,0,.03-.01,.06-.02,.09,0,.02-.01,.05-.02,.07,0,0,0,.02,0,.03,0,.03-.02,.06-.03,.09,0,0,0,.01,0,.01,0,.03-.02,.05-.03,.08,0,.01,0,.03-.02,.04-.03,.08-.06,.15-.09,.22-.01,.03-.03,.06-.04,.08-.11,.21-.24,.41-.39,.59l-2.05-1.87,2.6-1.22c.05,.13,.08,.27,.11,.41,0,.03,.01,.06,.02,.1,0,.01,0,.02,0,.03,0,.01,0,.03,0,.04,0,.03,0,.06,.01,.08,0,.03,0,.06,0,.1,0,.03,0,.05,0,.08,0,.03,0,.05,0,.08,0,.05,0,.09,0,.13Z"
        />
        <path
          style={styles.two}
          d="M494.37,674.96h0s-2.34,1.37-2.34,1.37c-.16-.28-.28-.58-.35-.91-.05-.23-.08-.48-.08-.73,0-.4,.07-.78,.2-1.13,.13-.37,.33-.7,.58-.97,.5,.6,1.95,2.31,1.99,2.36h0Z"
        />
        <path
          style={styles.four}
          d="M495.23,671.87h0s-.86,2.35-.86,2.35l-.61-2.45h0s.02,0,.03,0c.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.03,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.03,.25,.06,.36,.11Z"
        />
        <path
          style={styles.four}
          d="M497.14,674.79c0,.15,0,.29-.03,.43,0,.03,0,.06-.01,.09,0,.05-.02,.1-.03,.15,0,.02,0,.04-.01,.05,0,.03-.01,.06-.02,.09,0,.02-.01,.05-.02,.07,0,0,0,.02,0,.03,0,.03-.02,.06-.03,.09,0,0,0,.01,0,.01,0,.03-.02,.05-.03,.08,0,.01,0,.03-.02,.04l-1.9-.96,2.06-.69s0,.03,0,.04c0,.03,0,.06,.01,.08,0,.03,0,.06,0,.1,0,.03,0,.05,0,.08,0,.03,0,.05,0,.08,0,.05,0,.09,0,.13Z"
        />
        <path
          style={styles.two}
          d="M494.91,677.73c-.11,.02-.21,.03-.32,.04-.03,0-.06,0-.1,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.1,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.05-.02-.08-.03l.83-2.22,.57,2.34Z"
        />
        <path
          style={styles.four}
          d="M493.93,674.9l-2.25,.53c-.05-.23-.08-.48-.08-.73,0-.4,.07-.78,.2-1.13l2.13,1.33Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M502.68,670.72c0,.15,0,.29-.03,.43,0,.03,0,.06-.01,.09,0,.05-.02,.1-.03,.15,0,.02,0,.04-.01,.05,0,.03-.01,.06-.02,.09,0,.02-.01,.05-.02,.07,0,0,0,.02,0,.03,0,.03-.02,.06-.03,.09,0,0,0,.01,0,.01,0,.03-.02,.05-.03,.08,0,.01,0,.03-.02,.04-.03,.08-.06,.15-.09,.22-.01,.03-.03,.06-.04,.08-.11,.21-.24,.41-.39,.59-.24,.29-.54,.52-.87,.68-.04,.02-.07,.04-.11,.05-.05,.02-.09,.04-.14,.06-.02,0-.03,.01-.05,.02-.03,.01-.06,.02-.09,.03-.05,.02-.1,.03-.14,.04-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.06-.02-.08-.03-.02,0-.05-.02-.07-.03-.12-.05-.23-.1-.34-.17-.02-.01-.04-.02-.06-.03-.02-.01-.04-.03-.06-.04-.38-.25-.71-.6-.95-1.01-.16-.28-.28-.58-.35-.91-.05-.23-.08-.48-.08-.73,0-.4,.07-.78,.2-1.13,.13-.37,.33-.7,.58-.97,.37-.41,.84-.7,1.38-.82,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.14-.02,.27-.03,.42-.03,.1,0,.19,0,.28,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.03,.25,.06,.36,.11,.8,.3,1.45,.99,1.74,1.86,.05,.13,.08,.27,.11,.41,0,.03,.01,.06,.02,.1,0,.01,0,.02,0,.03,0,.01,0,.03,0,.04,0,.03,0,.06,.01,.08,0,.03,0,.06,0,.1,0,.03,0,.05,0,.08,0,.03,0,.05,0,.08,0,.05,0,.09,0,.13Z"
        />
        <path
          style={styles.two}
          d="M501.53,668.28c-.54,.87-1.08,1.74-1.61,2.6h0s-1.27-2.91-1.27-2.91c.15-.09,.38-.2,.66-.27,0,0,.02,0,.03,0,.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.03,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.13,.03,.25,.07,.36,.12,.37,.15,.64,.36,.76,.46Z"
        />
        <path
          style={styles.two}
          d="M501.08,673.43s-.07,.04-.11,.05c-.05,.02-.09,.04-.14,.06-.02,0-.03,.01-.05,.02-.03,.01-.06,.02-.09,.03-.05,.02-.1,.03-.14,.04-.03,0-.06,.01-.09,.02,0,0,0,0-.01,0-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.05-.02-.08-.03-.02,0-.05-.02-.07-.03-.12-.05-.23-.1-.34-.17-.02-.01-.04-.02-.06-.03-.02-.01-.04-.03-.06-.04,.36-.7,1.36-2.33,1.39-2.38h0s1.17,2.55,1.17,2.55Z"
        />
        <path
          style={styles.two}
          d="M502.68,670.72c0,.15,0,.29-.03,.43,0,.03,0,.06-.01,.09,0,.05-.02,.1-.03,.15,0,.02,0,.04-.01,.05,0,.03-.01,.06-.02,.09,0,.02-.01,.05-.02,.07,0,0,0,.02,0,.03,0,.03-.02,.06-.03,.09,0,0,0,.01,0,.01,0,.03-.02,.05-.03,.08,0,.01,0,.03-.02,.04-.03,.08-.06,.15-.09,.22-.01,.03-.03,.06-.04,.08-.11,.21-.24,.41-.39,.59l-2.04-1.87,2.59-1.22c.05,.13,.08,.27,.11,.41,0,.03,.01,.06,.02,.1,0,.01,0,.02,0,.03,0,.01,0,.03,0,.04,0,.03,0,.06,.01,.08,0,.03,0,.06,0,.1,0,.03,0,.05,0,.08,0,.03,0,.05,0,.08,0,.05,0,.09,0,.13Z"
        />
        <path
          style={styles.two}
          d="M499.91,670.88h0s-2.33,1.37-2.33,1.37c-.16-.28-.28-.58-.35-.91-.05-.23-.08-.48-.08-.73,0-.4,.07-.78,.2-1.13,.13-.37,.33-.7,.58-.97,.5,.6,1.94,2.31,1.99,2.36h0Z"
        />
        <path
          style={styles.four}
          d="M500.77,667.8h0s-.85,2.34-.85,2.34l-.61-2.44h0s.02,0,.03,0c.05-.01,.11-.02,.17-.03,.09-.01,.18-.02,.27-.03,.15,0,.29,0,.43,.02,.03,0,.05,0,.08,.01,.05,0,.09,.02,.14,.03,.12,.03,.25,.06,.36,.11Z"
        />
        <path
          style={styles.four}
          d="M502.68,670.72c0,.15,0,.29-.03,.43,0,.03,0,.06-.01,.09,0,.05-.02,.1-.03,.15,0,.02,0,.04-.01,.05,0,.03-.01,.06-.02,.09,0,.02-.01,.05-.02,.07,0,0,0,.02,0,.03,0,.03-.02,.06-.03,.09,0,0,0,.01,0,.01,0,.03-.02,.05-.03,.08,0,.01,0,.03-.02,.04l-1.89-.96,2.05-.69s0,.03,0,.04c0,.03,0,.06,.01,.08,0,.03,0,.06,0,.1,0,.03,0,.05,0,.08,0,.03,0,.05,0,.08,0,.05,0,.09,0,.13Z"
        />
        <path
          style={styles.two}
          d="M500.45,673.65c-.1,.02-.21,.03-.32,.04-.03,0-.06,0-.09,0-.04,0-.08,0-.12,0-.04,0-.08,0-.11,0-.05,0-.09,0-.14-.01-.04,0-.08-.01-.12-.02,0,0-.01,0-.02,0-.02,0-.05,0-.07-.01-.11-.02-.21-.05-.31-.08-.03,0-.05-.02-.08-.03l.82-2.22,.57,2.34Z"
        />
        <path
          style={styles.four}
          d="M499.47,670.82l-2.25,.52c-.05-.23-.08-.48-.08-.73,0-.4,.07-.78,.2-1.13l2.13,1.33Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M506.8,666.08c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.17-.19,.34-.32,.48-.2,.23-.44,.43-.71,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.58-.49-.77-.83-.13-.23-.23-.47-.29-.74-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.16-.92,.11-.3,.27-.57,.48-.79,.3-.33,.69-.57,1.12-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.3,.09,.66,.25,1.18,.81,1.42,1.52,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M505.86,664.08c-.44,.71-.88,1.42-1.32,2.13h0s-1.04-2.38-1.04-2.38c.13-.07,.31-.17,.54-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.03,.2,.06,.29,.1,.3,.12,.52,.29,.62,.38Z"
        />
        <path
          style={styles.two}
          d="M505.5,668.3s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.01-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.57,1.11-1.9,1.13-1.94h0s.96,2.09,.96,2.09Z"
        />
        <path
          style={styles.two}
          d="M506.8,666.08c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.17-.19,.34-.32,.48l-1.67-1.53,2.12-.99c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M504.54,666.21h0s-1.91,1.12-1.91,1.12c-.13-.23-.23-.47-.29-.74-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.16-.92,.11-.3,.27-.57,.48-.79,.41,.49,1.58,1.89,1.62,1.93h0Z"
        />
        <path
          style={styles.four}
          d="M505.24,663.69h0s-.7,1.92-.7,1.92l-.5-2h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.3,.09Z"
        />
        <path
          style={styles.four}
          d="M506.8,666.08c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.54-.79,1.68-.56s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M504.98,668.47c-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.04-.02-.07-.02l.67-1.81,.46,1.91Z"
        />
        <path
          style={styles.four}
          d="M504.18,666.16l-1.83,.42c-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.16-.92l1.74,1.09Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M510.34,661.06c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.32,.48-.2,.23-.44,.42-.71,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.58-.49-.77-.83-.13-.23-.23-.47-.29-.74-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.16-.92,.11-.3,.27-.57,.47-.79,.3-.33,.69-.57,1.12-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.29,.09,.66,.25,1.18,.81,1.42,1.52,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M509.4,659.07c-.44,.71-.88,1.42-1.32,2.12h0s-1.03-2.38-1.03-2.38c.13-.07,.31-.16,.54-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.03,.2,.06,.29,.1,.3,.12,.52,.29,.62,.38Z"
        />
        <path
          style={styles.two}
          d="M509.04,663.27s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.01-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.57,1.1-1.9,1.13-1.94h0s.95,2.08,.95,2.08Z"
        />
        <path
          style={styles.two}
          d="M510.34,661.06c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.32,.48l-1.66-1.53,2.12-.99c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M508.09,661.19h0s-1.9,1.11-1.9,1.11c-.13-.23-.23-.47-.29-.74-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.16-.92,.11-.3,.27-.57,.47-.79,.41,.49,1.58,1.89,1.62,1.93h0Z"
        />
        <path
          style={styles.four}
          d="M508.79,658.68h0s-.7,1.91-.7,1.91l-.5-2h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M510.34,661.06c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.54-.79,1.67-.56s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M508.52,663.45c-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02l.67-1.81,.46,1.91Z"
        />
        <path
          style={styles.four}
          d="M507.73,661.14l-1.83,.42c-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.16-.92l1.73,1.09Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M513.49,656.51c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.32,.48-.2,.23-.44,.42-.71,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.58-.49-.77-.83-.13-.23-.23-.47-.29-.74-.04-.19-.07-.39-.07-.59,0-.33,.06-.64,.16-.92,.11-.3,.27-.57,.47-.79,.3-.33,.69-.57,1.12-.66,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.29,.09,.65,.25,1.18,.81,1.41,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M512.55,654.51c-.44,.71-.88,1.41-1.31,2.12h0s-1.03-2.38-1.03-2.38c.13-.07,.31-.16,.54-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.03,.2,.06,.29,.1,.3,.12,.52,.29,.62,.38Z"
        />
        <path
          style={styles.two}
          d="M512.19,658.72s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.57,1.1-1.9,1.13-1.94h0s.95,2.08,.95,2.08Z"
        />
        <path
          style={styles.two}
          d="M513.49,656.51c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.32,.48l-1.66-1.53,2.11-.99c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M511.24,656.63h0s-1.9,1.11-1.9,1.11c-.13-.23-.23-.47-.29-.74-.04-.19-.07-.39-.07-.59,0-.33,.06-.64,.16-.92,.11-.3,.27-.57,.47-.79,.41,.49,1.58,1.89,1.62,1.93h0Z"
        />
        <path
          style={styles.four}
          d="M511.93,654.12h0s-.69,1.91-.69,1.91l-.5-2h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M513.49,656.51c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.54-.79,1.67-.55s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M511.67,658.89c-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02l.67-1.81,.46,1.91Z"
        />
        <path
          style={styles.four}
          d="M510.88,656.58l-1.83,.42c-.04-.19-.07-.39-.07-.59,0-.33,.06-.64,.16-.92l1.73,1.09Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M516.63,652.42c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.48-.2,.23-.44,.42-.71,.55-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.11,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.58-.49-.77-.83-.13-.23-.23-.47-.29-.74-.04-.19-.07-.39-.07-.59,0-.33,.06-.64,.16-.92,.11-.3,.27-.57,.47-.79,.3-.33,.68-.57,1.12-.66,0,0,.02,0,.02,0,.04-.01,.09-.02,.13-.03,.11-.02,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,0,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09,.65,.25,1.18,.81,1.41,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M515.69,650.43c-.44,.71-.87,1.41-1.31,2.12h0s-1.03-2.37-1.03-2.37c.13-.07,.31-.16,.54-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.02,.11,.02,.1,.03,.2,.06,.29,.1,.3,.12,.52,.29,.62,.38Z"
        />
        <path
          style={styles.two}
          d="M515.33,654.63s-.06,.03-.09,.04c-.04,.02-.08,.03-.11,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.57,1.1-1.9,1.13-1.94h0s.95,2.08,.95,2.08Z"
        />
        <path
          style={styles.two}
          d="M516.63,652.42c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.48l-1.66-1.53,2.11-.98c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M514.38,652.54h0s-1.9,1.11-1.9,1.11c-.13-.23-.23-.47-.29-.74-.04-.19-.07-.39-.07-.59,0-.33,.06-.64,.16-.92,.11-.3,.27-.57,.47-.79,.41,.49,1.58,1.89,1.61,1.93h0Z"
        />
        <path
          style={styles.four}
          d="M515.07,650.04h0s-.69,1.91-.69,1.91l-.5-2h0s.02,0,.02,0c.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M516.63,652.42c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.54-.79,1.67-.55s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M514.81,654.8c-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02l.67-1.81,.46,1.91Z"
        />
        <path
          style={styles.four}
          d="M514.02,652.49l-1.83,.42c-.04-.19-.07-.39-.07-.59,0-.33,.06-.64,.16-.92l1.73,1.09Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M518.98,648.32c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.48-.2,.23-.44,.42-.71,.55-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.11,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.58-.49-.77-.83-.13-.23-.23-.47-.29-.74-.04-.19-.07-.39-.07-.59,0-.33,.06-.63,.16-.92,.11-.3,.27-.57,.47-.79,.3-.33,.68-.57,1.12-.66,0,0,.02,0,.02,0,.04-.01,.09-.02,.13-.03,.11-.02,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09,.65,.25,1.17,.81,1.41,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M518.04,646.33c-.44,.7-.87,1.41-1.31,2.11h0s-1.03-2.37-1.03-2.37c.13-.07,.31-.16,.54-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.03,.2,.06,.29,.1,.3,.12,.52,.29,.62,.38Z"
        />
        <path
          style={styles.two}
          d="M517.68,650.53s-.06,.03-.09,.04c-.04,.02-.08,.03-.11,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.56,1.1-1.89,1.12-1.93h0s.95,2.08,.95,2.08Z"
        />
        <path
          style={styles.two}
          d="M518.98,648.32c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.48l-1.66-1.53,2.11-.98c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M516.73,648.44h0s-1.89,1.1-1.89,1.1c-.13-.23-.23-.47-.29-.74-.04-.19-.07-.39-.07-.59,0-.33,.06-.63,.16-.92,.11-.3,.27-.57,.47-.79,.41,.49,1.57,1.89,1.61,1.93h0Z"
        />
        <path
          style={styles.four}
          d="M517.43,645.94h0s-.69,1.91-.69,1.91l-.5-1.99h0s.02,0,.02,0c.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M518.98,648.32c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.53-.79,1.67-.55s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M517.17,650.7c-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02l.67-1.8,.46,1.91Z"
        />
        <path
          style={styles.four}
          d="M516.37,648.39l-1.82,.42c-.04-.19-.07-.39-.07-.59,0-.33,.06-.63,.16-.92l1.73,1.09Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M521.32,644.23c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.48-.2,.23-.44,.42-.71,.55-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.11,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.58-.49-.77-.83-.13-.23-.23-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.33,.06-.63,.16-.92,.11-.3,.27-.56,.47-.79,.3-.33,.68-.57,1.12-.66,0,0,.02,0,.02,0,.04-.01,.09-.02,.13-.03,.11-.02,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09,.65,.25,1.17,.82,1.41,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M520.39,642.23c-.44,.7-.87,1.41-1.31,2.11h0s-1.03-2.37-1.03-2.37c.13-.07,.31-.16,.53-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.03,.2,.06,.29,.1,.3,.12,.52,.29,.62,.38Z"
        />
        <path
          style={styles.two}
          d="M520.03,646.43s-.06,.03-.09,.04c-.04,.02-.08,.03-.11,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.56,1.1-1.89,1.12-1.93h0s.95,2.08,.95,2.08Z"
        />
        <path
          style={styles.two}
          d="M521.32,644.23c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.48l-1.65-1.53,2.1-.98c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M519.08,644.35h0s-1.89,1.1-1.89,1.1c-.13-.23-.23-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.33,.06-.63,.16-.92,.11-.3,.27-.56,.47-.79,.41,.49,1.57,1.89,1.61,1.93h0Z"
        />
        <path
          style={styles.four}
          d="M519.78,641.84h0s-.69,1.9-.69,1.9l-.49-1.99h0s.02,0,.02,0c.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M521.32,644.23c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.53-.79,1.67-.55s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M519.52,646.6c-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02l.67-1.8,.46,1.91Z"
        />
        <path
          style={styles.four}
          d="M518.73,644.29l-1.82,.41c-.04-.19-.07-.39-.07-.59,0-.33,.06-.63,.16-.92l1.72,1.1Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M522.89,639.66c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.48-.2,.23-.44,.42-.71,.55-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.11,.05-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.58-.49-.77-.83-.13-.23-.23-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.79,.3-.33,.68-.56,1.11-.66,0,0,.02,0,.02,0,.04-.01,.09-.02,.13-.03,.11-.01,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09,.65,.25,1.17,.82,1.41,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M521.96,637.66c-.44,.7-.87,1.41-1.31,2.11h0s-1.03-2.37-1.03-2.37c.12-.07,.31-.16,.53-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.03,.2,.06,.29,.1,.3,.13,.51,.3,.62,.38Z"
        />
        <path
          style={styles.two}
          d="M521.6,641.86s-.06,.03-.09,.04c-.04,.02-.08,.03-.11,.05-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.56,1.1-1.89,1.12-1.93h0s.95,2.08,.95,2.08Z"
        />
        <path
          style={styles.two}
          d="M522.89,639.66c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.48l-1.65-1.53,2.1-.97c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M520.65,639.77h0s-1.89,1.1-1.89,1.1c-.13-.23-.23-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.79,.41,.49,1.57,1.89,1.61,1.93h0Z"
        />
        <path
          style={styles.four}
          d="M521.34,637.27h0s-.69,1.9-.69,1.9l-.49-1.99h0s.02,0,.02,0c.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M522.89,639.66c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.53-.8,1.66-.55s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M521.08,642.03c-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02l.67-1.8,.46,1.91Z"
        />
        <path
          style={styles.four}
          d="M520.29,639.72l-1.82,.41c-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91l1.72,1.1Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M525.62,635.58c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.48-.2,.23-.44,.42-.7,.55-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.11,.05-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.58-.49-.77-.83-.13-.23-.23-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.79,.3-.33,.68-.56,1.11-.66,0,0,.02,0,.02,0,.04-.01,.09-.02,.13-.03,.11-.01,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09,.65,.25,1.17,.82,1.41,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M524.69,633.59c-.44,.7-.87,1.4-1.31,2.11h0s-1.03-2.37-1.03-2.37c.12-.07,.31-.16,.53-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.03,.2,.06,.29,.1,.3,.13,.51,.3,.62,.38Z"
        />
        <path
          style={styles.two}
          d="M524.33,637.78s-.06,.03-.09,.04c-.04,.02-.08,.03-.11,.05-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.56,1.1-1.89,1.12-1.93h0s.95,2.08,.95,2.08Z"
        />
        <path
          style={styles.two}
          d="M525.62,635.58c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.48l-1.65-1.53,2.1-.97c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M523.39,635.7h0s-1.89,1.1-1.89,1.1c-.13-.23-.23-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.79,.4,.49,1.57,1.89,1.61,1.93h0Z"
        />
        <path
          style={styles.four}
          d="M524.08,633.2h0s-.69,1.9-.69,1.9l-.49-1.99h0s.02,0,.02,0c.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M525.62,635.58c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.53-.8,1.66-.54s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M523.82,637.95c-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02l.67-1.8,.46,1.9Z"
        />
        <path
          style={styles.four}
          d="M523.03,635.64l-1.82,.41c-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91l1.72,1.1Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M527.18,631.48c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.47-.2,.23-.44,.42-.7,.55-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.57-.49-.77-.83-.13-.23-.23-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.78,.3-.33,.68-.56,1.11-.65,0,0,.02,0,.02,0,.04-.01,.09-.02,.13-.03,.11-.01,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09,.65,.25,1.17,.82,1.4,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M526.25,629.49c-.44,.7-.87,1.4-1.3,2.1h0s-1.03-2.37-1.03-2.37c.12-.07,.31-.16,.53-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.03,.2,.06,.29,.1,.3,.13,.51,.3,.61,.38Z"
        />
        <path
          style={styles.two}
          d="M525.89,633.68s-.06,.03-.09,.04c-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.56,1.1-1.88,1.12-1.93h0s.95,2.08,.95,2.08Z"
        />
        <path
          style={styles.two}
          d="M527.18,631.48c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.47l-1.65-1.53,2.1-.97c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M524.95,631.59h0s-1.89,1.09-1.89,1.09c-.13-.23-.23-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.78,.4,.49,1.57,1.89,1.6,1.94h0Z"
        />
        <path
          style={styles.four}
          d="M525.64,629.1h0s-.69,1.9-.69,1.9l-.49-1.99h0s.02,0,.02,0c.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M527.18,631.48c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.53-.8,1.66-.54s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M525.38,633.85c-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02l.67-1.8,.46,1.9Z"
        />
        <path
          style={styles.four}
          d="M524.59,631.54l-1.82,.41c-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91l1.72,1.1Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M529.52,626.95c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.47-.2,.23-.44,.42-.7,.55-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,0-.07,.01,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.57-.49-.77-.83-.13-.23-.23-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.78,.3-.33,.68-.56,1.11-.65,0,0,.02,0,.02,0,.04-.01,.09-.02,.13-.03,.11-.01,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09,.65,.25,1.17,.82,1.4,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M528.59,624.95c-.43,.7-.87,1.4-1.3,2.1h0s-1.02-2.37-1.02-2.37c.12-.07,.31-.16,.53-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.03,.2,.06,.29,.1,.3,.13,.51,.3,.61,.38Z"
        />
        <path
          style={styles.two}
          d="M528.23,629.14s-.06,.03-.09,.04c-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.01,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.56,1.09-1.88,1.12-1.92h0s.94,2.08,.94,2.08Z"
        />
        <path
          style={styles.two}
          d="M529.52,626.95c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.47l-1.65-1.53,2.09-.97c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M527.29,627.06h0s-1.88,1.09-1.88,1.09c-.13-.23-.23-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.78,.4,.49,1.57,1.89,1.6,1.94h0Z"
        />
        <path
          style={styles.four}
          d="M527.98,624.56h0s-.69,1.9-.69,1.9l-.49-1.99h0s.02,0,.02,0c.04-.01,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M529.52,626.95c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.53-.8,1.66-.54s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M527.72,629.31c-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02l.67-1.8,.46,1.9Z"
        />
        <path
          style={styles.four}
          d="M526.93,627l-1.81,.4c-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91l1.72,1.1Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M531.08,622.39c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.47-.2,.23-.44,.42-.7,.55-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,0-.07,.01,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.09-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.57-.49-.77-.83-.13-.23-.23-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.78,.3-.33,.68-.56,1.11-.65,0,0,.02,0,.02,0,.04,0,.09-.02,.13-.03,.11-.01,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.02,.1,.02,.2,.05,.29,.09,.65,.25,1.17,.82,1.4,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M530.15,620.4c-.43,.7-.87,1.4-1.3,2.1h0s-1.02-2.37-1.02-2.37c.12-.07,.3-.16,.53-.22,0,0,.01,0,.02,0,.04,0,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.02,.1,.03,.2,.06,.29,.1,.3,.13,.51,.3,.61,.38Z"
        />
        <path
          style={styles.two}
          d="M529.79,624.58s-.06,.03-.09,.04c-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.01,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.09-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.56,1.09-1.88,1.12-1.92h0s.94,2.08,.94,2.08Z"
        />
        <path
          style={styles.two}
          d="M531.08,622.39c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.47l-1.65-1.54,2.09-.96c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M528.85,622.5h0s-1.88,1.09-1.88,1.09c-.13-.23-.23-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.78,.4,.49,1.57,1.89,1.6,1.94h0Z"
        />
        <path
          style={styles.four}
          d="M529.54,620.01h0s-.69,1.9-.69,1.9l-.49-1.99h0s.02,0,.02,0c.04,0,.09-.02,.13-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M531.08,622.39c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.52-.8,1.66-.54s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M529.28,624.75c-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02l.67-1.79,.46,1.9Z"
        />
        <path
          style={styles.four}
          d="M528.49,622.45l-1.81,.4c-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91l1.72,1.1Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M532.64,617.84c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.47-.2,.23-.43,.42-.7,.55-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,0-.07,.01,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.09-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.57-.49-.76-.83-.13-.23-.22-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.78,.3-.33,.68-.56,1.11-.65,0,0,.02,0,.02,0,.04,0,.09-.02,.13-.02,.11-.01,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.02,.1,.02,.2,.05,.29,.09,.65,.25,1.17,.82,1.4,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M531.71,615.85c-.43,.7-.87,1.4-1.3,2.1h0s-1.02-2.37-1.02-2.37c.12-.07,.3-.16,.53-.21,0,0,.01,0,.02,0,.04,0,.09-.02,.13-.02,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.02,.1,.03,.2,.06,.29,.1,.3,.13,.51,.3,.61,.39Z"
        />
        <path
          style={styles.two}
          d="M531.35,620.03s-.06,.03-.09,.04c-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.01,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.09-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.56,1.09-1.88,1.12-1.92h0s.94,2.08,.94,2.08Z"
        />
        <path
          style={styles.two}
          d="M532.64,617.84c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.47l-1.64-1.54,2.09-.96c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M530.41,617.95h0s-1.88,1.09-1.88,1.09c-.13-.23-.22-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.78,.4,.49,1.56,1.9,1.6,1.94h0Z"
        />
        <path
          style={styles.four}
          d="M531.1,615.45h0s-.69,1.89-.69,1.89l-.49-1.99h0s.02,0,.02,0c.04,0,.09-.02,.13-.02,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M532.64,617.84c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.52-.8,1.66-.54s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M530.84,620.2c-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02l.66-1.79,.46,1.9Z"
        />
        <path
          style={styles.four}
          d="M530.05,617.89l-1.81,.4c-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91l1.72,1.1Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M534.19,613.29c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.47-.2,.23-.43,.42-.7,.55-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,0-.07,.01,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.09-.04-.19-.09-.27-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.57-.5-.76-.83-.13-.23-.22-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.78,.3-.33,.68-.56,1.11-.65,0,0,.02,0,.02,0,.04,0,.09-.02,.13-.02,.11-.01,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.02,.1,.02,.2,.05,.29,.09,.65,.25,1.16,.82,1.4,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M533.26,611.3c-.43,.7-.87,1.4-1.3,2.09h0s-1.02-2.37-1.02-2.37c.12-.07,.3-.16,.53-.21,0,0,.01,0,.02,0,.04,0,.09-.02,.13-.02,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.34,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.02,.1,.03,.2,.06,.29,.1,.3,.13,.51,.3,.61,.39Z"
        />
        <path
          style={styles.two}
          d="M532.91,615.48s-.06,.03-.09,.04c-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.01,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.09-.04-.19-.09-.27-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.56,1.09-1.88,1.12-1.92h0s.94,2.08,.94,2.08Z"
        />
        <path
          style={styles.two}
          d="M534.19,613.29c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.47l-1.64-1.54,2.09-.96c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M531.96,613.4h0s-1.88,1.08-1.88,1.08c-.13-.23-.22-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.78,.4,.49,1.56,1.9,1.6,1.94h0Z"
        />
        <path
          style={styles.four}
          d="M532.65,610.91h0s-.69,1.89-.69,1.89l-.49-1.99h0s.02,0,.02,0c.04,0,.09-.02,.13-.02,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.34,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M534.19,613.29c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.52-.8,1.66-.53s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M532.4,615.65c-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02l.66-1.79,.46,1.9Z"
        />
        <path
          style={styles.four}
          d="M531.61,613.34l-1.81,.4c-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91l1.71,1.11Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M535.75,608.75c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.47-.2,.23-.43,.42-.7,.54-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,0-.07,.01,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.09-.04-.19-.09-.27-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.21-.57-.5-.76-.84-.13-.23-.22-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.78,.3-.33,.68-.56,1.11-.65,0,0,.02,0,.02,0,.04,0,.09-.02,.13-.02,.11-.01,.22-.02,.33-.02,.08,0,.15,.01,.23,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.02,.1,.02,.2,.05,.29,.09,.65,.25,1.16,.82,1.4,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M534.82,606.76c-.43,.7-.87,1.4-1.3,2.09h0s-1.02-2.37-1.02-2.37c.12-.07,.3-.16,.53-.21,0,0,.01,0,.02,0,.04,0,.09-.02,.13-.02,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.34,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.02,.1,.03,.2,.06,.29,.1,.3,.13,.51,.3,.61,.39Z"
        />
        <path
          style={styles.two}
          d="M534.46,610.93s-.06,.03-.09,.04c-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.01,0,0,0,0,0,0-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.02-.06-.02-.09-.04-.19-.09-.27-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.56,1.09-1.87,1.11-1.91h0s.94,2.08,.94,2.08Z"
        />
        <path
          style={styles.two}
          d="M535.75,608.75c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.04-.03,.07-.09,.17-.19,.33-.31,.47l-1.64-1.54,2.09-.95c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M533.52,608.85h0s-1.88,1.08-1.88,1.08c-.13-.23-.22-.47-.28-.74-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91,.11-.3,.27-.56,.47-.78,.4,.49,1.56,1.9,1.6,1.94h0Z"
        />
        <path
          style={styles.four}
          d="M534.21,606.36h0s-.69,1.89-.69,1.89l-.49-1.99h0s.02,0,.02,0c.04,0,.09-.02,.13-.02,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.34,.02,.02,0,.04,0,.06,.01,.04,0,.07,.02,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M535.75,608.75c0,.12,0,.24-.02,.35,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.52-.81,1.65-.53s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M533.95,611.1c-.08,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02l.66-1.79,.46,1.9Z"
        />
        <path
          style={styles.four}
          d="M533.17,608.8l-1.81,.4c-.04-.19-.07-.39-.07-.59,0-.32,.06-.63,.16-.91l1.71,1.11Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M536.91,603.74c0,.12,0,.24-.02,.35,0,.02,0,.05,0,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02,0,.05-.02,.07,0,.02,0,.04-.01,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.04,.12-.07,.18,0,.02-.02,.04-.03,.07-.08,.17-.17,.33-.28,.47-.18,.23-.4,.42-.64,.55-.03,.01-.05,.03-.08,.04-.03,.02-.07,.03-.1,.04-.01,0-.03,.01-.04,.01-.02,0-.04,.02-.06,.02-.03,.01-.07,.02-.11,.03-.02,0-.04,0-.06,.01,0,0,0,0,0,0-.08,.02-.16,.03-.24,.03-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.03,0-.07,0-.1-.01-.03,0-.06,0-.09-.02,0,0-.01,0-.02,0-.02,0-.03,0-.05-.01-.08-.02-.15-.04-.23-.07-.02,0-.04-.02-.06-.02-.02,0-.03-.02-.05-.02-.09-.04-.17-.09-.25-.14-.02,0-.03-.02-.04-.03-.01,0-.03-.02-.04-.03-.28-.21-.52-.49-.7-.83-.12-.23-.21-.47-.26-.74-.04-.19-.06-.39-.06-.59,0-.32,.05-.63,.15-.91,.1-.3,.24-.56,.43-.78,.27-.33,.62-.56,1.01-.65,0,0,.01,0,.02,0,.04,0,.08-.02,.12-.02,.1-.01,.2-.02,.31-.02,.07,0,.14,0,.21,.02,.02,0,.04,0,.06,.01,.03,0,.07,.02,.1,.02,.09,.02,.18,.05,.27,.09,.59,.25,1.06,.82,1.28,1.53,.03,.11,.06,.22,.08,.34,0,.03,0,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M536.07,601.75c-.4,.7-.79,1.4-1.19,2.09h0s-.93-2.37-.93-2.37c.11-.07,.28-.16,.48-.21,0,0,.01,0,.02,0,.04,0,.08-.02,.12-.02,.06-.01,.13-.02,.2-.02,.11,0,.21,0,.31,.02,.02,0,.04,0,.06,.01,.03,0,.07,.02,.1,.02,.09,.03,.18,.06,.26,.1,.27,.13,.47,.3,.56,.38Z"
        />
        <path
          style={styles.two}
          d="M535.74,605.92s-.05,.03-.08,.04c-.03,.02-.07,.03-.1,.04-.01,0-.03,.01-.04,.01-.02,0-.04,.02-.06,.02-.03,.01-.07,.02-.11,.03-.02,0-.04,.01-.06,.01,0,0,0,0,0,0-.08,.02-.16,.03-.24,.03-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.03,0-.07,0-.1-.01-.03,0-.06,0-.09-.02,0,0-.01,0-.02,0-.02,0-.03,0-.05-.01-.08-.02-.15-.04-.23-.07-.02,0-.04-.02-.06-.02-.02,0-.03-.02-.05-.02-.09-.04-.17-.09-.25-.14-.02,0-.03-.02-.04-.03-.01,0-.03-.02-.04-.03,.27-.56,1-1.88,1.02-1.92h0s.86,2.08,.86,2.08Z"
        />
        <path
          style={styles.two}
          d="M536.91,603.74c0,.12,0,.24-.02,.35,0,.02,0,.05,0,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02,0,.05-.02,.07,0,.02,0,.04-.01,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.04,.12-.07,.18,0,.02-.02,.04-.03,.07-.08,.17-.17,.33-.28,.47l-1.5-1.54,1.91-.96c.03,.11,.06,.22,.08,.34,0,.03,0,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M534.88,603.84h0s-1.71,1.08-1.71,1.08c-.12-.23-.21-.47-.26-.74-.04-.19-.06-.39-.06-.59,0-.32,.05-.63,.15-.91,.1-.3,.24-.56,.43-.78,.37,.49,1.43,1.89,1.46,1.94h0Z"
        />
        <path
          style={styles.four}
          d="M535.51,601.35h0s-.63,1.89-.63,1.89l-.45-1.99h0s.01,0,.02,0c.04,0,.08-.02,.12-.02,.06-.01,.13-.02,.2-.02,.11,0,.21,0,.31,.02,.02,0,.04,0,.06,.01,.03,0,.07,.02,.1,.02,.09,.02,.18,.05,.27,.09Z"
        />
        <path
          style={styles.four}
          d="M536.91,603.74c0,.12,0,.24-.02,.35,0,.02,0,.05,0,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02,0,.05-.02,.07,0,.02,0,.04-.01,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.39-.8,1.51-.53s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M535.28,606.09c-.08,.02-.16,.03-.24,.03-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.03,0-.07,0-.1-.01-.03,0-.06,0-.09-.02,0,0-.01,0-.02,0-.02,0-.03,0-.05-.01-.08-.02-.15-.04-.23-.07-.02,0-.04-.02-.06-.02l.61-1.79,.42,1.9Z"
        />
        <path
          style={styles.four}
          d="M534.56,603.79l-1.65,.4c-.04-.19-.06-.39-.06-.59,0-.32,.05-.63,.15-.91l1.56,1.1Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M537.84,598.72c0,.12,0,.24-.02,.35,0,.02,0,.05,0,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02,0,.05-.01,.07,0,.02,0,.04-.01,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.04,.12-.06,.18,0,.02-.02,.04-.03,.07-.07,.17-.16,.33-.26,.47-.16,.23-.36,.42-.57,.55-.02,.01-.05,.03-.07,.04-.03,.02-.06,.03-.09,.04-.01,0-.02,.01-.03,.01-.02,0-.04,.02-.06,.02-.03,.01-.06,.02-.1,.03-.02,0-.04,0-.06,.01,0,0,0,0,0,0-.07,.02-.14,.03-.21,.03-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.02,0-.05,0-.07,0-.03,0-.06,0-.09-.01-.03,0-.05,0-.08-.02,0,0,0,0-.01,0-.01,0-.03,0-.05-.01-.07-.02-.14-.04-.21-.07-.02,0-.04-.02-.05-.02-.02,0-.03-.02-.05-.02-.08-.04-.15-.09-.22-.14-.01,0-.03-.02-.04-.03-.01,0-.03-.02-.04-.03-.25-.21-.47-.49-.62-.83-.1-.22-.18-.47-.23-.74-.04-.19-.05-.39-.05-.59,0-.32,.05-.63,.13-.91,.09-.3,.22-.56,.38-.78,.24-.33,.55-.56,.91-.65,0,0,.01,0,.02,0,.04,0,.07-.02,.11-.03,.09-.01,.18-.02,.27-.02,.06,0,.13,0,.19,.02,.02,0,.03,0,.05,.01,.03,0,.06,.02,.09,.02,.08,.02,.16,.05,.24,.09,.53,.25,.95,.82,1.14,1.53,.03,.11,.05,.22,.07,.34,0,.03,0,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M537.08,596.73c-.35,.7-.71,1.4-1.06,2.1h0s-.84-2.37-.84-2.37c.1-.07,.25-.16,.43-.22,0,0,.01,0,.02,0,.04,0,.07-.02,.11-.03,.06-.01,.12-.02,.18-.02,.1,0,.19,0,.28,.02,.02,0,.03,0,.05,.01,.03,0,.06,.02,.09,.02,.08,.03,.16,.06,.24,.1,.24,.13,.42,.3,.5,.38Z"
        />
        <path
          style={styles.two}
          d="M536.79,600.91s-.05,.03-.07,.04c-.03,.02-.06,.03-.09,.04-.01,0-.02,.01-.03,.01-.02,0-.04,.02-.06,.02-.03,.01-.06,.02-.1,.03-.02,0-.04,.01-.06,.01,0,0,0,0,0,0-.07,.02-.14,.03-.21,.03-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.02,0-.05,0-.07,0-.03,0-.06,0-.09-.01-.03,0-.05,0-.08-.02,0,0,0,0-.01,0-.01,0-.03,0-.05-.01-.07-.02-.14-.04-.21-.07-.02,0-.04-.02-.05-.02-.02,0-.03-.02-.05-.02-.08-.04-.15-.09-.22-.14-.01,0-.03-.02-.04-.03-.01,0-.03-.02-.04-.03,.24-.56,.89-1.88,.91-1.92h0s.77,2.08,.77,2.08Z"
        />
        <path
          style={styles.two}
          d="M537.84,598.72c0,.12,0,.24-.02,.35,0,.02,0,.05,0,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02,0,.05-.01,.07,0,.02,0,.04-.01,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.04,.12-.06,.18,0,.02-.02,.04-.03,.07-.07,.17-.16,.33-.26,.47l-1.34-1.53,1.71-.96c.03,.11,.05,.22,.07,.34,0,.03,0,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M536.02,598.83h0s-1.54,1.09-1.54,1.09c-.1-.22-.18-.47-.23-.74-.04-.19-.05-.39-.05-.59,0-.32,.05-.63,.13-.91,.09-.3,.22-.56,.38-.78,.33,.49,1.28,1.89,1.31,1.93h0Z"
        />
        <path
          style={styles.four}
          d="M536.58,596.34h0s-.56,1.89-.56,1.89l-.4-1.99h0s.01,0,.02,0c.04,0,.07-.02,.11-.03,.06-.01,.12-.02,.18-.02,.1,0,.19,0,.28,.02,.02,0,.03,0,.05,.01,.03,0,.06,.02,.09,.02,.08,.02,.16,.05,.24,.09Z"
        />
        <path
          style={styles.four}
          d="M537.84,598.72c0,.12,0,.24-.02,.35,0,.02,0,.05,0,.07,0,.04-.01,.08-.02,.13,0,.01,0,.03,0,.04,0,.02,0,.05-.01,.07,0,.02,0,.04-.01,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.24-.8,1.35-.54s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M536.37,601.08c-.07,.02-.14,.03-.21,.03-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.02,0-.05,0-.07,0-.03,0-.06,0-.09-.01-.03,0-.05,0-.08-.02,0,0,0,0-.01,0-.01,0-.03,0-.05-.01-.07-.02-.14-.04-.21-.07-.02,0-.04-.02-.05-.02l.54-1.79,.38,1.9Z"
        />
        <path
          style={styles.four}
          d="M535.73,598.77l-1.48,.4c-.04-.19-.05-.39-.05-.59,0-.32,.05-.63,.13-.91l1.4,1.1Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M538.05,594.24c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.04-.01,.05,0,.02,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.02,.05-.03,.09-.05,.14,0,.02-.01,.03-.02,.05-.06,.13-.13,.25-.22,.36-.14,.18-.31,.32-.49,.42-.02,.01-.04,.02-.06,.03-.03,.01-.05,.02-.08,.03-.01,0-.02,0-.03,.01-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.02,0-.03,0-.05,.01,0,0,0,0,0,0-.06,.01-.12,.02-.18,.02-.02,0-.04,0-.05,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.02,0-.04,0-.07-.01,0,0,0,0-.01,0-.01,0-.03,0-.04,0-.06-.01-.12-.03-.18-.06-.02,0-.03-.01-.05-.02-.01,0-.03-.01-.04-.02-.07-.03-.13-.07-.19-.11-.01,0-.02-.01-.03-.02-.01,0-.02-.02-.03-.02-.22-.16-.4-.38-.54-.64-.09-.17-.16-.37-.2-.57-.03-.15-.05-.3-.05-.46,0-.25,.04-.49,.11-.7,.08-.23,.19-.43,.33-.6,.21-.25,.48-.43,.78-.5,0,0,.01,0,.02,0,.03,0,.06-.01,.09-.02,.08-.01,.16-.02,.24-.01,.05,0,.11,0,.16,.02,.01,0,.03,0,.04,0,.03,0,.05,.01,.08,.02,.07,.02,.14,.04,.2,.07,.46,.2,.82,.64,.98,1.18,.03,.08,.05,.17,.06,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M537.4,592.7c-.31,.54-.61,1.08-.91,1.61h0s-.72-1.83-.72-1.83c.09-.06,.21-.12,.37-.16,0,0,.01,0,.02,0,.03,0,.06-.01,.09-.02,.05,0,.1-.01,.15-.02,.09,0,.17,0,.24,.02,.01,0,.03,0,.04,0,.03,0,.05,.01,.08,.02,.07,.02,.14,.05,.2,.08,.21,.1,.36,.23,.43,.3Z"
        />
        <path
          style={styles.two}
          d="M537.15,595.92s-.04,.02-.06,.03c-.03,.01-.05,.02-.08,.03-.01,0-.02,0-.03,.01-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.02,0-.03,0-.05,.01,0,0,0,0,0,0-.06,.01-.12,.02-.18,.02-.02,0-.04,0-.05,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.02,0-.04,0-.07-.01,0,0,0,0-.01,0-.01,0-.03,0-.04,0-.06-.01-.12-.03-.18-.06-.02,0-.03-.01-.05-.02-.01,0-.03-.01-.04-.02-.07-.03-.13-.07-.19-.11-.01,0-.02-.01-.03-.02-.01,0-.02-.02-.03-.02,.2-.43,.77-1.45,.79-1.48h0s.66,1.61,.66,1.61Z"
        />
        <path
          style={styles.two}
          d="M538.05,594.24c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.04-.01,.05,0,.02,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.02,.05-.03,.09-.05,.14,0,.02-.01,.03-.02,.05-.06,.13-.13,.25-.22,.36l-1.16-1.19,1.47-.74c.03,.08,.05,.17,.06,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M536.48,594.31h0s-1.32,.83-1.32,.83c-.09-.17-.16-.37-.2-.57-.03-.15-.05-.3-.05-.46,0-.25,.04-.49,.11-.7,.08-.23,.19-.43,.33-.6,.28,.38,1.1,1.46,1.12,1.5h0Z"
        />
        <path
          style={styles.four}
          d="M536.97,592.39h0s-.48,1.46-.48,1.46l-.35-1.54h0s.01,0,.02,0c.03,0,.06-.01,.09-.02,.05,0,.1-.01,.15-.02,.09,0,.17,0,.24,.02,.01,0,.03,0,.04,0,.03,0,.05,.01,.08,.02,.07,.02,.14,.04,.2,.07Z"
        />
        <path
          style={styles.four}
          d="M538.05,594.24c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.04-.01,.05,0,.02,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03l-1.07-.62,1.16-.41s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M536.79,596.05c-.06,.01-.12,.02-.18,.02-.02,0-.04,0-.05,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.02,0-.04,0-.07-.01,0,0,0,0-.01,0-.01,0-.03,0-.04,0-.06-.01-.12-.03-.18-.06-.02,0-.03-.01-.05-.02l.47-1.38,.32,1.47Z"
        />
        <path
          style={styles.four}
          d="M536.23,594.27l-1.27,.3c-.03-.15-.05-.3-.05-.46,0-.25,.04-.49,.11-.7l1.21,.85Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M538.05,590.56c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.04-.01,.05,0,.02,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.02,.05-.03,.09-.05,.14,0,.02-.01,.03-.02,.05-.06,.13-.13,.25-.22,.36-.14,.18-.31,.32-.49,.42-.02,.01-.04,.02-.06,.03-.03,.01-.05,.02-.08,.03-.01,0-.02,0-.03,.01-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.02,0-.03,0-.05,.01,0,0,0,0,0,0-.06,.01-.12,.02-.18,.02-.02,0-.04,0-.05,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.02,0-.04,0-.07-.01,0,0,0,0-.01,0-.01,0-.03,0-.04,0-.06-.01-.12-.03-.18-.06-.02,0-.03-.01-.05-.02-.01,0-.03-.01-.04-.02-.07-.03-.13-.07-.19-.11-.01,0-.02-.01-.03-.02-.01,0-.02-.02-.03-.02-.22-.16-.4-.38-.54-.64-.09-.17-.16-.37-.2-.57-.03-.15-.05-.3-.05-.46,0-.25,.04-.49,.11-.7,.08-.23,.19-.43,.33-.6,.21-.25,.48-.43,.78-.5,0,0,.01,0,.02,0,.03,0,.06-.01,.09-.02,.08-.01,.16-.01,.24-.01,.05,0,.11,0,.16,.02,.01,0,.03,0,.04,0,.03,0,.05,.01,.08,.02,.07,.02,.14,.04,.2,.07,.46,.2,.82,.64,.98,1.18,.03,.08,.05,.17,.06,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M537.4,589.02c-.31,.54-.61,1.08-.91,1.61h0s-.72-1.83-.72-1.83c.09-.06,.21-.12,.37-.16,0,0,.01,0,.02,0,.03,0,.06-.01,.09-.02,.05,0,.1-.01,.15-.02,.09,0,.17,0,.24,.02,.01,0,.03,0,.04,0,.03,0,.05,.01,.08,.02,.07,.02,.14,.05,.2,.08,.21,.1,.36,.23,.43,.3Z"
        />
        <path
          style={styles.two}
          d="M537.15,592.25s-.04,.02-.06,.03c-.03,.01-.05,.02-.08,.03-.01,0-.02,0-.03,.01-.02,0-.03,.01-.05,.02-.03,0-.05,.02-.08,.02-.02,0-.03,0-.05,.01,0,0,0,0,0,0-.06,.01-.12,.02-.18,.02-.02,0-.04,0-.05,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.02,0-.04,0-.07-.01,0,0,0,0-.01,0-.01,0-.03,0-.04,0-.06-.01-.12-.03-.18-.06-.02,0-.03-.01-.05-.02-.01,0-.03-.01-.04-.02-.07-.03-.13-.07-.19-.11-.01,0-.02-.01-.03-.02-.01,0-.02-.02-.03-.02,.2-.43,.77-1.44,.79-1.48h0s.66,1.61,.66,1.61Z"
        />
        <path
          style={styles.two}
          d="M538.05,590.56c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.04-.01,.05,0,.02,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.02,.05-.03,.09-.05,.14,0,.02-.01,.03-.02,.05-.06,.13-.13,.25-.22,.36l-1.16-1.19,1.47-.73c.03,.08,.05,.17,.06,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M536.48,590.64h0s-1.32,.83-1.32,.83c-.09-.17-.16-.37-.2-.57-.03-.15-.05-.3-.05-.46,0-.25,.04-.49,.11-.7,.08-.23,.19-.43,.33-.6,.28,.38,1.1,1.46,1.12,1.5h0Z"
        />
        <path
          style={styles.four}
          d="M536.97,588.72h0s-.48,1.46-.48,1.46l-.35-1.54h0s.01,0,.02,0c.03,0,.06-.01,.09-.02,.05,0,.1-.01,.15-.02,.09,0,.17,0,.24,.02,.01,0,.03,0,.04,0,.03,0,.05,.01,.08,.02,.07,.02,.14,.04,.2,.07Z"
        />
        <path
          style={styles.four}
          d="M538.05,590.56c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.04-.01,.05,0,.02,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03l-1.07-.62,1.16-.41s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M536.79,592.37c-.06,.01-.12,.02-.18,.02-.02,0-.04,0-.05,0-.02,0-.05,0-.07,0-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.02,0-.04,0-.07-.01,0,0,0,0-.01,0-.01,0-.03,0-.04,0-.06-.01-.12-.03-.18-.06-.02,0-.03-.01-.05-.02l.47-1.38,.32,1.47Z"
        />
        <path
          style={styles.four}
          d="M536.23,590.59l-1.27,.3c-.03-.15-.05-.3-.05-.46,0-.25,.04-.49,.11-.7l1.21,.86Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M537.66,586.87c0,.09,0,.18-.01,.27,0,.02,0,.04,0,.05,0,.03,0,.07-.01,.1,0,.01,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.03,0,.04,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.01,.05-.02,.09-.04,.14,0,.02-.01,.03-.02,.05-.04,.13-.1,.26-.16,.37-.1,.18-.22,.32-.36,.42-.02,.01-.03,.02-.05,.03-.02,.01-.04,.02-.06,.03,0,0-.01,0-.02,.01-.01,0-.02,.01-.04,.02-.02,0-.04,.02-.06,.02-.01,0-.02,0-.04,.01,0,0,0,0,0,0-.04,.01-.09,.02-.13,.02-.01,0-.03,0-.04,0-.02,0-.03,0-.05,0-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.03,0-.05-.01,0,0,0,0,0,0,0,0-.02,0-.03,0-.04-.01-.09-.03-.13-.05-.01,0-.02-.01-.03-.02,0,0-.02-.01-.03-.02-.05-.03-.1-.07-.14-.11,0,0-.02-.01-.02-.02,0,0-.02-.02-.02-.02-.16-.16-.3-.38-.39-.64-.07-.17-.12-.36-.15-.57-.02-.15-.03-.3-.03-.46,0-.25,.03-.49,.08-.7,.06-.23,.14-.43,.24-.6,.15-.25,.35-.43,.57-.51,0,0,0,0,.01,0,.02,0,.05-.01,.07-.02,.06-.01,.11-.02,.17-.01,.04,0,.08,0,.12,.01,.01,0,.02,0,.03,0,.02,0,.04,.01,.06,.02,.05,.02,.1,.04,.15,.07,.33,.19,.6,.63,.72,1.17,.02,.08,.03,.17,.05,.26,0,.02,0,.04,0,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M537.18,585.34c-.22,.54-.45,1.08-.67,1.62h0s-.53-1.83-.53-1.83c.06-.06,.16-.13,.27-.17,0,0,0,0,.01,0,.02,0,.05-.01,.07-.02,.04,0,.07-.01,.11-.02,.06,0,.12,0,.18,.02,.01,0,.02,0,.03,0,.02,0,.04,.01,.06,.02,.05,.02,.1,.05,.15,.07,.15,.1,.26,.23,.32,.29Z"
        />
        <path
          style={styles.two}
          d="M537,588.57s-.03,.02-.05,.03c-.02,.01-.04,.02-.06,.03,0,0-.01,0-.02,.01-.01,0-.02,.01-.04,.02-.02,0-.04,.02-.06,.02-.01,0-.02,0-.04,.01,0,0,0,0,0,0-.04,.01-.09,.02-.13,.02-.01,0-.03,0-.04,0-.02,0-.03,0-.05,0-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.03,0-.05-.01,0,0,0,0,0,0,0,0-.02,0-.03,0-.04-.01-.09-.03-.13-.05-.01,0-.02-.01-.03-.02,0,0-.02-.01-.03-.02-.05-.03-.1-.07-.14-.11,0,0-.02-.01-.02-.02,0,0-.02-.02-.02-.02,.15-.43,.56-1.45,.58-1.48h0s.49,1.6,.49,1.6Z"
        />
        <path
          style={styles.two}
          d="M537.66,586.87c0,.09,0,.18-.01,.27,0,.02,0,.04,0,.05,0,.03,0,.07-.01,.1,0,.01,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.03,0,.04,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.01,.05-.02,.09-.04,.14,0,.02-.01,.03-.02,.05-.04,.13-.1,.26-.16,.37l-.85-1.18,1.08-.75c.02,.08,.03,.17,.05,.26,0,.02,0,.04,0,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M536.51,586.96h0s-.97,.84-.97,.84c-.07-.17-.12-.36-.15-.57-.02-.15-.03-.3-.03-.46,0-.25,.03-.49,.08-.7,.06-.23,.14-.43,.24-.6,.21,.38,.81,1.45,.83,1.49h0Z"
        />
        <path
          style={styles.four}
          d="M536.87,585.04h0s-.35,1.46-.35,1.46l-.25-1.53h0s0,0,.01,0c.02,0,.05-.01,.07-.02,.04,0,.07-.01,.11-.02,.06,0,.12,0,.18,.02,.01,0,.02,0,.03,0,.02,0,.04,.01,.06,.02,.05,.02,.1,.04,.15,.07Z"
        />
        <path
          style={styles.four}
          d="M537.66,586.87c0,.09,0,.18-.01,.27,0,.02,0,.04,0,.05,0,.03,0,.07-.01,.1,0,.01,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.03,0,.04,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03l-.79-.61,.86-.42s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M536.73,588.7s-.09,.02-.13,.02c-.01,0-.03,0-.04,0-.02,0-.03,0-.05,0-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.03,0-.05-.01,0,0,0,0,0,0,0,0-.02,0-.03,0-.04-.01-.09-.03-.13-.05-.01,0-.02-.01-.03-.02l.34-1.38,.24,1.47Z"
        />
        <path
          style={styles.four}
          d="M536.33,586.92l-.93,.32c-.02-.15-.03-.3-.03-.46,0-.25,.03-.49,.08-.7l.88,.84Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M536.77,585c0,.09,0,.18-.01,.27,0,.02,0,.04,0,.05,0,.03,0,.07-.01,.1,0,.01,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.03,0,.04,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.01,.05-.02,.09-.04,.14,0,.02-.01,.03-.02,.05-.04,.13-.1,.26-.16,.37-.1,.18-.22,.32-.36,.42-.02,.01-.03,.02-.05,.03-.02,.01-.04,.02-.06,.03,0,0-.01,0-.02,.01-.01,0-.02,.01-.04,.02-.02,0-.04,.02-.06,.02-.01,0-.02,0-.04,.01,0,0,0,0,0,0-.04,.01-.09,.02-.13,.02-.01,0-.03,0-.04,0-.02,0-.03,0-.05,0-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.03,0-.05-.01,0,0,0,0,0,0,0,0-.02,0-.03,0-.04-.01-.09-.03-.13-.05-.01,0-.02-.01-.03-.02,0,0-.02-.01-.03-.02-.05-.03-.1-.07-.14-.11,0,0-.02-.01-.02-.02,0,0-.02-.02-.02-.02-.16-.16-.3-.38-.39-.64-.07-.17-.12-.36-.15-.57-.02-.15-.03-.3-.03-.46,0-.25,.03-.49,.08-.7,.06-.23,.14-.43,.24-.6,.15-.25,.35-.43,.57-.51,0,0,0,0,.01,0,.02,0,.05-.01,.07-.02,.06-.01,.11-.02,.17-.01,.04,0,.08,0,.12,.01,.01,0,.02,0,.03,0,.02,0,.04,.01,.06,.02,.05,.02,.1,.04,.15,.07,.33,.19,.6,.63,.72,1.18,.02,.08,.03,.17,.05,.26,0,.02,0,.04,0,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M536.29,583.47c-.22,.54-.45,1.08-.67,1.62h0s-.53-1.83-.53-1.83c.06-.06,.16-.13,.27-.17,0,0,0,0,.01,0,.02,0,.05-.01,.07-.02,.04,0,.07-.01,.11-.02,.06,0,.12,0,.18,.02,.01,0,.02,0,.03,0,.02,0,.04,.01,.06,.02,.05,.02,.1,.05,.15,.08,.15,.1,.26,.23,.32,.29Z"
        />
        <path
          style={styles.two}
          d="M536.1,586.69s-.03,.02-.05,.03c-.02,.01-.04,.02-.06,.03,0,0-.01,0-.02,.01-.01,0-.02,.01-.04,.02-.02,0-.04,.02-.06,.02-.01,0-.02,0-.04,.01,0,0,0,0,0,0-.04,.01-.09,.02-.13,.02-.01,0-.03,0-.04,0-.02,0-.03,0-.05,0-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.03,0-.05-.01,0,0,0,0,0,0,0,0-.02,0-.03,0-.04-.01-.09-.03-.13-.05-.01,0-.02-.01-.03-.02,0,0-.02-.01-.03-.02-.05-.03-.1-.07-.14-.11,0,0-.02-.01-.02-.02,0,0-.02-.02-.02-.02,.15-.43,.56-1.45,.58-1.48h0s.49,1.6,.49,1.6Z"
        />
        <path
          style={styles.two}
          d="M536.77,585c0,.09,0,.18-.01,.27,0,.02,0,.04,0,.05,0,.03,0,.07-.01,.1,0,.01,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.03,0,.04,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.01,.05-.02,.09-.04,.14,0,.02-.01,.03-.02,.05-.04,.13-.1,.26-.16,.37l-.85-1.18,1.08-.75c.02,.08,.03,.17,.05,.26,0,.02,0,.04,0,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M535.61,585.09h0s-.97,.84-.97,.84c-.07-.17-.12-.36-.15-.57-.02-.15-.03-.3-.03-.46,0-.25,.03-.49,.08-.7,.06-.23,.14-.43,.24-.6,.21,.38,.81,1.45,.83,1.49h0Z"
        />
        <path
          style={styles.four}
          d="M535.97,583.17h0s-.36,1.46-.36,1.46l-.25-1.53h0s0,0,.01,0c.02,0,.05-.01,.07-.02,.04,0,.07-.01,.11-.02,.06,0,.12,0,.18,.02,.01,0,.02,0,.03,0,.02,0,.04,.01,.06,.02,.05,.02,.1,.04,.15,.07Z"
        />
        <path
          style={styles.four}
          d="M536.77,585c0,.09,0,.18-.01,.27,0,.02,0,.04,0,.05,0,.03,0,.07-.01,.1,0,.01,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.03,0,.04,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03l-.79-.61,.86-.42s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M535.84,586.82s-.09,.02-.13,.02c-.01,0-.03,0-.04,0-.02,0-.03,0-.05,0-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.03,0-.05-.01,0,0,0,0,0,0,0,0-.02,0-.03,0-.04-.01-.09-.03-.13-.05-.01,0-.02-.01-.03-.02l.34-1.38,.24,1.47Z"
        />
        <path
          style={styles.four}
          d="M535.43,585.05l-.93,.32c-.02-.15-.03-.3-.03-.46,0-.25,.03-.49,.08-.7l.89,.84Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M534.44,584.45c0,.09,0,.18-.01,.27,0,.02,0,.04,0,.05,0,.03,0,.07-.01,.1,0,.01,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.03,0,.04,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.01,.05-.02,.09-.04,.14,0,.02-.01,.03-.02,.05-.04,.13-.1,.26-.16,.37-.1,.18-.22,.32-.36,.42-.02,.01-.03,.02-.05,.03-.02,.01-.04,.02-.06,.03,0,0-.01,0-.02,.01-.01,0-.02,.01-.04,.02-.02,0-.04,.02-.06,.02-.01,0-.02,0-.04,.01,0,0,0,0,0,0-.04,.01-.09,.02-.13,.02-.01,0-.03,0-.04,0-.02,0-.03,0-.05,0-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.03,0-.05-.01,0,0,0,0,0,0,0,0-.02,0-.03,0-.04-.01-.09-.03-.13-.05-.01,0-.02-.01-.03-.02,0,0-.02-.01-.03-.02-.05-.03-.1-.07-.14-.11,0,0-.02-.01-.02-.02,0,0-.02-.02-.02-.02-.16-.16-.3-.38-.4-.64-.07-.17-.12-.37-.15-.57-.02-.15-.03-.3-.03-.46,0-.25,.03-.49,.08-.7,.06-.23,.14-.43,.24-.6,.15-.25,.35-.43,.57-.51,0,0,0,0,.01,0,.02,0,.05-.01,.07-.02,.06-.01,.11-.02,.17-.01,.04,0,.08,0,.12,.01,.01,0,.02,0,.03,0,.02,0,.04,.01,.06,.02,.05,.02,.1,.04,.15,.07,.34,.19,.6,.63,.72,1.18,.02,.08,.03,.17,.05,.26,0,.02,0,.04,0,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M533.96,582.91c-.22,.54-.45,1.08-.67,1.62h0s-.53-1.83-.53-1.83c.06-.06,.16-.13,.27-.17,0,0,0,0,.01,0,.02,0,.05-.01,.07-.02,.04,0,.07-.01,.11-.02,.06,0,.12,0,.18,.02,.01,0,.02,0,.03,0,.02,0,.04,.01,.06,.02,.05,.02,.1,.05,.15,.08,.15,.1,.26,.23,.32,.29Z"
        />
        <path
          style={styles.two}
          d="M533.77,586.14s-.03,.02-.05,.03c-.02,.01-.04,.02-.06,.03,0,0-.01,0-.02,.01-.01,0-.02,.01-.04,.02-.02,0-.04,.02-.06,.02-.01,0-.02,0-.04,.01,0,0,0,0,0,0-.04,.01-.09,.02-.13,.02-.01,0-.03,0-.04,0-.02,0-.03,0-.05,0-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.03,0-.05-.01,0,0,0,0,0,0,0,0-.02,0-.03,0-.04-.01-.09-.03-.13-.05-.01,0-.02-.01-.03-.02,0,0-.02-.01-.03-.02-.05-.03-.1-.07-.14-.11,0,0-.02-.01-.02-.02,0,0-.02-.02-.02-.02,.15-.43,.56-1.45,.58-1.48h0s.49,1.6,.49,1.6Z"
        />
        <path
          style={styles.two}
          d="M534.44,584.45c0,.09,0,.18-.01,.27,0,.02,0,.04,0,.05,0,.03,0,.07-.01,.1,0,.01,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.03,0,.04,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03-.01,.05-.02,.09-.04,.14,0,.02-.01,.03-.02,.05-.04,.13-.1,.26-.16,.37l-.85-1.18,1.08-.75c.02,.08,.03,.17,.05,.26,0,.02,0,.04,0,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M533.28,584.54h0s-.97,.84-.97,.84c-.07-.17-.12-.37-.15-.57-.02-.15-.03-.3-.03-.46,0-.25,.03-.49,.08-.7,.06-.23,.14-.43,.24-.6,.21,.38,.81,1.46,.83,1.49h0Z"
        />
        <path
          style={styles.four}
          d="M533.64,582.61h0s-.36,1.46-.36,1.46l-.25-1.53h0s0,0,.01,0c.02,0,.05-.01,.07-.02,.04,0,.07-.01,.11-.02,.06,0,.12,0,.18,.02,.01,0,.02,0,.03,0,.02,0,.04,.01,.06,.02,.05,.02,.1,.04,.15,.07Z"
        />
        <path
          style={styles.four}
          d="M534.44,584.45c0,.09,0,.18-.01,.27,0,.02,0,.04,0,.05,0,.03,0,.07-.01,.1,0,.01,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.03,0,.04,0,0,0,.01,0,.02,0,.02,0,.04-.01,.06,0,0,0,0,0,0,0,.02,0,.03-.01,.05,0,0,0,.02,0,.03l-.79-.61,.86-.42s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M533.51,586.27s-.09,.02-.13,.02c-.01,0-.03,0-.04,0-.02,0-.03,0-.05,0-.02,0-.03,0-.05,0-.02,0-.04,0-.06,0-.02,0-.03,0-.05-.01,0,0,0,0,0,0,0,0-.02,0-.03,0-.04-.01-.09-.03-.13-.05-.01,0-.02-.01-.03-.02l.34-1.39,.24,1.47Z"
        />
        <path
          style={styles.four}
          d="M533.1,584.5l-.94,.32c-.02-.15-.03-.3-.03-.46,0-.25,.03-.49,.08-.7l.89,.85Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M535.21,586.4c0,.07,0,.15,0,.22,0,.01,0,.03,0,.04,0,.03,0,.05,0,.08,0,0,0,.02,0,.03,0,.01,0,.03,0,.04,0,.01,0,.02,0,.04,0,0,0,0,0,.01,0,.02,0,.03,0,.05,0,0,0,0,0,0,0,.01,0,.03,0,.04,0,0,0,.01,0,.02,0,.04-.02,.08-.03,.11,0,.01,0,.03-.01,.04-.03,.11-.08,.21-.13,.29-.08,.14-.18,.26-.28,.34-.01,0-.02,.02-.04,.03-.02,.01-.03,.02-.05,.03,0,0-.01,0-.02,0,0,0-.02,0-.03,.01-.02,0-.03,.01-.05,.02,0,0-.02,0-.03,0,0,0,0,0,0,0-.03,.01-.07,.02-.1,.02-.01,0-.02,0-.03,0-.01,0-.03,0-.04,0-.01,0-.02,0-.04,0-.02,0-.03,0-.05,0-.01,0-.03,0-.04,0,0,0,0,0,0,0,0,0-.02,0-.02,0-.03-.01-.07-.03-.1-.04,0,0-.02-.01-.03-.02,0,0-.02,0-.02-.01-.04-.02-.08-.05-.11-.09,0,0-.01-.01-.02-.02,0,0-.01-.01-.02-.02-.12-.13-.23-.3-.31-.51-.05-.14-.09-.29-.11-.46-.02-.12-.03-.24-.03-.37,0-.2,.02-.39,.06-.56,.04-.18,.11-.35,.19-.48,.12-.2,.27-.35,.45-.41,0,0,0,0,0,0,.02,0,.04-.01,.05-.02,.04,0,.09-.01,.14-.01,.03,0,.06,0,.09,.01,0,0,.02,0,.02,0,.02,0,.03,0,.04,.01,.04,.01,.08,.03,.12,.05,.26,.15,.47,.5,.56,.94,.01,.07,.03,.14,.04,.21,0,.02,0,.03,0,.05,0,0,0,0,0,.02,0,0,0,.01,0,.02,0,.01,0,.03,0,.04,0,.02,0,.03,0,.05,0,.01,0,.03,0,.04,0,.01,0,.03,0,.04,0,.02,0,.04,0,.07Z"
        />
        <path
          style={styles.two}
          d="M534.84,585.17c-.17,.43-.35,.87-.52,1.3h0s-.41-1.46-.41-1.46c.05-.05,.12-.1,.21-.13,0,0,0,0,0,0,.02,0,.04-.01,.05-.02,.03,0,.06-.01,.09-.01,.05,0,.09,0,.14,.01,0,0,.02,0,.02,0,.02,0,.03,0,.04,.01,.04,.02,.08,.04,.12,.06,.12,.08,.21,.18,.25,.24Z"
        />
        <path
          style={styles.two}
          d="M534.69,587.76s-.02,.02-.04,.03c-.02,.01-.03,.02-.05,.03,0,0-.01,0-.02,0,0,0-.02,0-.03,.01-.02,0-.03,.01-.05,.02,0,0-.02,0-.03,0,0,0,0,0,0,0-.03,.01-.07,.02-.1,.02-.01,0-.02,0-.03,0-.01,0-.03,0-.04,0-.01,0-.02,0-.04,0-.02,0-.03,0-.05,0-.01,0-.03,0-.04,0,0,0,0,0,0,0,0,0-.02,0-.02,0-.03-.01-.07-.03-.1-.04,0,0-.02,0-.03-.02,0,0-.02,0-.02-.01-.04-.02-.08-.05-.11-.09,0,0-.01-.01-.02-.02,0,0-.01-.01-.02-.02,.12-.35,.44-1.16,.45-1.19h0s.38,1.28,.38,1.28Z"
        />
        <path
          style={styles.two}
          d="M535.21,586.4c0,.07,0,.15,0,.22,0,.01,0,.03,0,.04,0,.03,0,.05,0,.08,0,0,0,.02,0,.03,0,.01,0,.03,0,.04,0,.01,0,.02,0,.04,0,0,0,0,0,.01,0,.02,0,.03,0,.05,0,0,0,0,0,0,0,.01,0,.03,0,.04,0,0,0,.01,0,.02,0,.04-.02,.08-.03,.11,0,.01,0,.03-.01,.04-.03,.11-.08,.21-.13,.29l-.66-.94,.84-.6c.01,.07,.03,.14,.04,.21,0,.02,0,.03,0,.05,0,0,0,0,0,.02,0,0,0,.01,0,.02,0,.01,0,.03,0,.04,0,.02,0,.03,0,.05,0,.01,0,.03,0,.04,0,.01,0,.03,0,.04,0,.02,0,.04,0,.07Z"
        />
        <path
          style={styles.two}
          d="M534.32,586.47h0s-.76,.68-.76,.68c-.05-.14-.09-.29-.11-.46-.02-.12-.03-.24-.03-.37,0-.2,.02-.39,.06-.56,.04-.18,.11-.35,.19-.48,.16,.3,.63,1.16,.64,1.19h0Z"
        />
        <path
          style={styles.four}
          d="M534.59,584.93h0s-.28,1.17-.28,1.17l-.2-1.23h0s0,0,0,0c.02,0,.04-.01,.05-.02,.03,0,.06-.01,.09-.01,.05,0,.09,0,.14,.01,0,0,.02,0,.02,0,.02,0,.03,0,.04,.01,.04,.01,.08,.03,.12,.05Z"
        />
        <path
          style={styles.four}
          d="M535.21,586.4c0,.07,0,.15,0,.22,0,.01,0,.03,0,.04,0,.03,0,.05,0,.08,0,0,0,.02,0,.03,0,.01,0,.03,0,.04,0,.01,0,.02,0,.04,0,0,0,0,0,.01,0,.02,0,.03,0,.05,0,0,0,0,0,0,0,.01,0,.03,0,.04,0,0,0,.01,0,.02l-.61-.49,.67-.34s0,.01,0,.02c0,.01,0,.03,0,.04,0,.02,0,.03,0,.05,0,.01,0,.03,0,.04,0,.01,0,.03,0,.04,0,.02,0,.04,0,.07Z"
        />
        <path
          style={styles.two}
          d="M534.49,587.86s-.07,.02-.1,.02c-.01,0-.02,0-.03,0-.01,0-.03,0-.04,0-.01,0-.02,0-.04,0-.02,0-.03,0-.05,0-.01,0-.03,0-.04,0,0,0,0,0,0,0,0,0-.02,0-.02,0-.03-.01-.07-.03-.1-.04,0,0-.02,0-.03-.02l.27-1.11,.18,1.17Z"
        />
        <path
          style={styles.four}
          d="M534.17,586.44l-.73,.25c-.02-.12-.03-.24-.03-.37,0-.2,.02-.39,.06-.56l.69,.68Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M492.96,677.88c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.19,.34-.32,.48-.2,.24-.44,.43-.72,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.1-.04-.19-.08-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.32-.21-.58-.49-.78-.83-.13-.23-.23-.48-.29-.74-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.16-.92,.11-.3,.27-.57,.48-.8,.3-.34,.69-.58,1.13-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.23-.02,.34-.02,.08,0,.16,0,.23,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.3,.09,.66,.25,1.19,.81,1.43,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M492.01,675.88c-.44,.71-.88,1.43-1.33,2.14h0s-1.04-2.38-1.04-2.38c.13-.07,.31-.17,.54-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.03,.2,.06,.29,.1,.3,.12,.52,.29,.63,.38Z"
        />
        <path
          style={styles.two}
          d="M491.65,680.11s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.04-.01-.07-.02-.02,0-.04-.01-.06-.02-.1-.04-.19-.08-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.3-.57,1.11-1.91,1.14-1.96h0s.96,2.09,.96,2.09Z"
        />
        <path
          style={styles.two}
          d="M492.96,677.88c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.19,.34-.32,.48l-1.68-1.53,2.13-1c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M490.69,678.02h0s-1.92,1.13-1.92,1.13c-.13-.23-.23-.48-.29-.74-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.16-.92,.11-.3,.27-.57,.48-.8,.41,.49,1.6,1.89,1.63,1.93h0Z"
        />
        <path
          style={styles.four}
          d="M491.39,675.49h0s-.7,1.92-.7,1.92l-.5-2h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.3,.09Z"
        />
        <path
          style={styles.four}
          d="M492.96,677.88c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.55-.79,1.69-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M491.13,680.29c-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.04-.01-.07-.02l.68-1.82,.47,1.92Z"
        />
        <path
          style={styles.four}
          d="M490.32,677.97l-1.85,.43c-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.16-.92l1.75,1.09Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M487,678.52c0,.07,0,.14-.02,.21,0,.01,0,.03,0,.04,0,.03-.01,.05-.02,.08,0,0,0,.02,0,.03,0,.01,0,.03-.01,.04,0,.01,0,.02-.01,.04,0,0,0,0,0,.01,0,.01-.01,.03-.02,.05,0,0,0,0,0,0,0,.01-.01,.03-.02,.04,0,0,0,.01-.01,.02-.02,.04-.04,.07-.06,.11,0,.01-.02,.03-.03,.04-.07,.11-.16,.2-.26,.29-.17,.14-.37,.26-.59,.34-.02,0-.05,.02-.08,.03-.03,.01-.06,.02-.1,.03-.01,0-.02,0-.04,0-.02,0-.04,0-.06,.01-.03,0-.07,.01-.1,.02-.02,0-.04,0-.06,0,0,0,0,0,0,0-.07,.01-.14,.02-.22,.02-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.05,0-.08,0,0,0,0,0-.01,0-.02,0-.03,0-.05,0-.07-.01-.14-.02-.21-.04-.02,0-.04,0-.06-.01-.02,0-.03,0-.05-.01-.08-.02-.16-.05-.23-.08-.01,0-.03-.01-.04-.02-.01,0-.03-.01-.04-.02-.26-.12-.48-.3-.65-.5-.11-.14-.19-.29-.24-.45-.04-.12-.06-.24-.06-.36,0-.2,.05-.39,.14-.56,.09-.18,.23-.34,.4-.48,.25-.2,.57-.35,.94-.41,0,0,.01,0,.02,0,.04,0,.07-.01,.11-.02,.09,0,.19-.01,.28-.01,.07,0,.13,0,.19,0,.02,0,.03,0,.05,0,.03,0,.06,0,.09,.01,.08,.01,.17,.03,.25,.05,.55,.15,.98,.49,1.18,.92,.03,.07,.06,.13,.07,.2,0,.02,0,.03,.01,.05,0,0,0,0,0,.01,0,0,0,.01,0,.02,0,.01,0,.03,0,.04,0,.02,0,.03,0,.05,0,.01,0,.02,0,.04,0,.01,0,.03,0,.04,0,.02,0,.04,0,.07Z"
        />
        <path
          style={styles.two}
          d="M486.22,677.32c-.37,.43-.73,.86-1.1,1.29h0s-.86-1.44-.86-1.44c.11-.05,.26-.1,.45-.14,0,0,.01,0,.02,0,.04,0,.07-.01,.11-.02,.06,0,.12-.01,.18-.01,.1,0,.2,0,.29,.01,.02,0,.03,0,.05,0,.03,0,.06,0,.09,.01,.09,.02,.17,.04,.24,.06,.25,.07,.43,.18,.52,.23Z"
        />
        <path
          style={styles.two}
          d="M485.91,679.87s-.05,.02-.08,.03c-.03,.01-.06,.02-.1,.03-.01,0-.02,0-.04,0-.02,0-.04,0-.06,.01-.03,0-.07,.01-.1,.02-.02,0-.04,0-.06,0,0,0,0,0,0,0-.07,.01-.14,.02-.22,.02-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.05,0-.08,0,0,0,0,0-.01,0-.02,0-.03,0-.05,0-.07-.01-.14-.02-.21-.04-.02,0-.04,0-.06-.01-.02,0-.03,0-.05-.01-.08-.02-.16-.05-.23-.08-.01,0-.03-.01-.04-.02-.01,0-.03-.01-.04-.02,.25-.34,.92-1.15,.94-1.18h0s.8,1.26,.8,1.26Z"
        />
        <path
          style={styles.two}
          d="M487,678.52c0,.07,0,.14-.02,.21,0,.01,0,.03,0,.04,0,.03-.01,.05-.02,.08,0,0,0,.02,0,.03,0,.01,0,.03-.01,.04,0,.01,0,.02-.01,.04,0,0,0,0,0,.01,0,.01-.01,.03-.02,.05,0,0,0,0,0,0,0,.01-.01,.03-.02,.04,0,0,0,.01-.01,.02-.02,.04-.04,.07-.06,.11,0,.01-.02,.03-.03,.04-.07,.11-.16,.2-.26,.29l-1.39-.92,1.77-.6c.03,.07,.06,.13,.07,.2,0,.02,0,.03,.01,.05,0,0,0,0,0,.01,0,0,0,.01,0,.02,0,.01,0,.03,0,.04,0,.02,0,.03,0,.05,0,.01,0,.02,0,.04,0,.01,0,.03,0,.04,0,.02,0,.04,0,.07Z"
        />
        <path
          style={styles.two}
          d="M485.12,678.61h0s-1.59,.68-1.59,.68c-.11-.14-.19-.29-.24-.45-.04-.12-.06-.24-.06-.36,0-.2,.05-.39,.14-.56,.09-.18,.23-.34,.4-.48,.34,.29,1.32,1.14,1.35,1.17h0Z"
        />
        <path
          style={styles.four}
          d="M485.7,677.08h0s-.58,1.16-.58,1.16l-.42-1.21h0s.01,0,.02,0c.04,0,.07-.01,.11-.02,.06,0,.12-.01,.18-.01,.1,0,.2,0,.29,.01,.02,0,.03,0,.05,0,.03,0,.06,0,.09,.01,.08,.01,.17,.03,.25,.05Z"
        />
        <path
          style={styles.four}
          d="M487,678.52c0,.07,0,.14-.02,.21,0,.01,0,.03,0,.04,0,.03-.01,.05-.02,.08,0,0,0,.02,0,.03,0,.01,0,.03-.01,.04,0,.01,0,.02-.01,.04,0,0,0,0,0,.01,0,.01-.01,.03-.02,.05,0,0,0,0,0,0,0,.01-.01,.03-.02,.04,0,0,0,.01-.01,.02l-1.29-.47,1.4-.34s0,.01,0,.02c0,.01,0,.03,0,.04,0,.02,0,.03,0,.05,0,.01,0,.02,0,.04,0,.01,0,.03,0,.04,0,.02,0,.04,0,.07Z"
        />
        <path
          style={styles.two}
          d="M485.48,679.98c-.07,.01-.14,.02-.22,.02-.02,0-.04,0-.06,0-.03,0-.05,0-.08,0-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.05,0-.08,0,0,0,0,0-.01,0-.02,0-.03,0-.05,0-.07-.01-.14-.02-.21-.04-.02,0-.04,0-.06-.01l.56-1.1,.39,1.16Z"
        />
        <path
          style={styles.four}
          d="M484.82,678.58l-1.53,.26c-.04-.12-.06-.24-.06-.36,0-.2,.05-.39,.14-.56l1.45,.66Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M483.02,675.85c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48-.2,.24-.45,.43-.72,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.32-.21-.59-.49-.78-.83-.13-.23-.23-.48-.29-.74-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.48-.8,.3-.34,.7-.58,1.14-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.23-.02,.34-.02,.08,0,.16,0,.23,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.3,.09,.66,.25,1.2,.81,1.44,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M482.06,673.85c-.44,.71-.89,1.43-1.33,2.14h0s-1.05-2.39-1.05-2.39c.13-.07,.31-.17,.55-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.11,.03,.2,.06,.3,.1,.31,.12,.53,.29,.63,.38Z"
        />
        <path
          style={styles.two}
          d="M481.7,678.09s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.01-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.3-.57,1.12-1.92,1.14-1.96h0s.97,2.1,.97,2.1Z"
        />
        <path
          style={styles.two}
          d="M483.02,675.85c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48l-1.69-1.53,2.14-1c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M480.73,675.99h0s-1.93,1.13-1.93,1.13c-.13-.23-.23-.48-.29-.74-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.48-.8,.41,.49,1.6,1.9,1.64,1.94h0Z"
        />
        <path
          style={styles.four}
          d="M481.44,673.46h0s-.7,1.93-.7,1.93l-.5-2.01h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.3,.09Z"
        />
        <path
          style={styles.four}
          d="M483.02,675.85c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.56-.79,1.7-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M481.17,678.27c-.09,.02-.17,.03-.26,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.04-.02-.07-.02l.68-1.82,.47,1.92Z"
        />
        <path
          style={styles.four}
          d="M480.37,675.94l-1.86,.43c-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93l1.76,1.09Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M479.42,672.07c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48-.2,.24-.45,.43-.72,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.32-.21-.59-.49-.79-.83-.13-.23-.23-.48-.29-.75-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.48-.8,.3-.34,.7-.58,1.14-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.23-.02,.34-.02,.08,0,.16,0,.23,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.3,.09,.67,.25,1.2,.82,1.44,1.53,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M478.47,670.06c-.45,.71-.89,1.43-1.34,2.14h0s-1.05-2.39-1.05-2.39c.13-.07,.31-.17,.55-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.11,.03,.2,.06,.3,.1,.31,.12,.53,.29,.63,.38Z"
        />
        <path
          style={styles.two}
          d="M478.1,674.3s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.01-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.3-.57,1.12-1.92,1.15-1.96h0s.97,2.1,.97,2.1Z"
        />
        <path
          style={styles.two}
          d="M479.42,672.07c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48l-1.69-1.54,2.15-1c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M477.14,672.2h0s-1.93,1.13-1.93,1.13c-.13-.23-.23-.48-.29-.75-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.48-.8,.41,.49,1.61,1.9,1.64,1.94h0Z"
        />
        <path
          style={styles.four}
          d="M477.84,669.67h0s-.71,1.93-.71,1.93l-.51-2.01h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.3,.09Z"
        />
        <path
          style={styles.four}
          d="M479.42,672.07c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.56-.79,1.7-.57s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M477.58,674.48c-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.05-.02-.07-.02l.68-1.83,.47,1.92Z"
        />
        <path
          style={styles.four}
          d="M476.77,672.15l-1.86,.43c-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93l1.76,1.1Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M475.83,667.34c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48-.2,.24-.45,.43-.72,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.32-.21-.59-.49-.79-.84-.13-.23-.23-.48-.29-.75-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.48-.8,.31-.34,.7-.58,1.14-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.23-.02,.35-.02,.08,0,.16,0,.23,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.3,.09,.67,.25,1.2,.82,1.44,1.54,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M474.87,665.33c-.45,.71-.89,1.43-1.34,2.14h0s-1.05-2.39-1.05-2.39c.13-.07,.31-.17,.55-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.11,.03,.2,.06,.3,.1,.31,.12,.53,.29,.63,.38Z"
        />
        <path
          style={styles.two}
          d="M474.5,669.57s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.3-.57,1.12-1.92,1.15-1.96h0s.97,2.1,.97,2.1Z"
        />
        <path
          style={styles.two}
          d="M475.83,667.34c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48l-1.69-1.54,2.15-1c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M473.53,667.47h0s-1.94,1.12-1.94,1.12c-.13-.23-.23-.48-.29-.75-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.48-.8,.42,.49,1.61,1.9,1.65,1.95h0Z"
        />
        <path
          style={styles.four}
          d="M474.24,664.93h0s-.71,1.93-.71,1.93l-.51-2.01h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.3,.09Z"
        />
        <path
          style={styles.four}
          d="M475.83,667.34c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.57-.79,1.7-.56s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M473.98,669.75c-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.05-.02-.07-.02l.68-1.83,.47,1.92Z"
        />
        <path
          style={styles.four}
          d="M473.17,667.42l-1.86,.43c-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93l1.76,1.1Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M472.22,663.53c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48-.2,.24-.45,.43-.72,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.32-.21-.59-.5-.79-.84-.13-.23-.23-.48-.29-.75-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.48-.8,.31-.34,.7-.58,1.14-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.23-.02,.35-.02,.08,0,.16,0,.23,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.3,.09,.67,.25,1.2,.82,1.44,1.54,.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M471.26,661.52c-.45,.71-.89,1.43-1.34,2.14h0s-1.05-2.4-1.05-2.4c.13-.07,.31-.17,.55-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.12,0,.24,0,.36,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.11,.03,.21,.06,.3,.1,.31,.12,.53,.3,.63,.38Z"
        />
        <path
          style={styles.two}
          d="M470.9,665.77s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.3-.57,1.13-1.92,1.15-1.96h0s.97,2.1,.97,2.1Z"
        />
        <path
          style={styles.two}
          d="M472.22,663.53c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48l-1.7-1.54,2.16-1c.04,.11,.07,.22,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M469.92,663.67h0s-1.94,1.12-1.94,1.12c-.13-.23-.23-.48-.29-.75-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.48-.8,.42,.49,1.61,1.91,1.65,1.95h0Z"
        />
        <path
          style={styles.four}
          d="M470.64,661.13h0s-.71,1.93-.71,1.93l-.51-2.02h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.12,0,.24,0,.36,.02,.02,0,.04,0,.06,0,.04,0,.08,.01,.11,.02,.1,.02,.2,.05,.3,.09Z"
        />
        <path
          style={styles.four}
          d="M472.22,663.53c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.57-.8,1.71-.56s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M470.37,665.95c-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.26-.07-.02,0-.05-.02-.07-.02l.69-1.83,.47,1.93Z"
        />
        <path
          style={styles.four}
          d="M469.56,663.61l-1.87,.43c-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93l1.77,1.1Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M469.01,658.8c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48-.2,.24-.45,.43-.72,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.32-.21-.59-.5-.79-.84-.13-.23-.23-.48-.29-.75-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.48-.8,.31-.34,.7-.58,1.14-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.23-.02,.35-.02,.08,0,.16,0,.24,.02,.02,0,.04,0,.06,0,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.3,.09,.67,.25,1.2,.82,1.45,1.54,.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M468.05,656.78c-.45,.71-.9,1.43-1.34,2.14h0s-1.06-2.4-1.06-2.4c.13-.07,.31-.17,.55-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.24,0,.36,.02,.02,0,.04,0,.06,0,.04,0,.08,.02,.11,.02,.11,.03,.21,.06,.3,.1,.31,.13,.53,.3,.63,.38Z"
        />
        <path
          style={styles.two}
          d="M467.68,661.03s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.3-.57,1.13-1.92,1.15-1.96h0s.97,2.11,.97,2.11Z"
        />
        <path
          style={styles.two}
          d="M469.01,658.8c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48l-1.7-1.54,2.16-1c.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M466.71,658.93h0s-1.94,1.12-1.94,1.12c-.13-.23-.23-.48-.29-.75-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.48-.8,.42,.49,1.61,1.91,1.65,1.95h0Z"
        />
        <path
          style={styles.four}
          d="M467.42,656.39h0s-.71,1.93-.71,1.93l-.51-2.02h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.24,0,.36,.02,.02,0,.04,0,.06,0,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.3,.09Z"
        />
        <path
          style={styles.four}
          d="M469.01,658.8c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.57-.8,1.71-.56s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M467.16,661.21c-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02l.69-1.83,.47,1.93Z"
        />
        <path
          style={styles.four}
          d="M466.34,658.88l-1.87,.43c-.04-.19-.07-.39-.07-.6,0-.33,.06-.64,.17-.93l1.77,1.1Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M465.8,654.52c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48-.2,.24-.45,.43-.73,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.32-.21-.59-.5-.79-.84-.13-.23-.23-.48-.29-.75-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.48-.8,.31-.34,.7-.57,1.15-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.23-.02,.35-.02,.08,0,.16,0,.24,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.3,.09,.67,.25,1.21,.82,1.45,1.54,.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M464.84,652.5c-.45,.71-.9,1.43-1.35,2.14h0s-1.06-2.4-1.06-2.4c.13-.07,.31-.17,.55-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.24,0,.36,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.11,.03,.21,.06,.3,.1,.31,.13,.53,.3,.63,.39Z"
        />
        <path
          style={styles.two}
          d="M464.47,656.76s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.3-.57,1.13-1.92,1.15-1.96h0s.98,2.11,.98,2.11Z"
        />
        <path
          style={styles.two}
          d="M465.8,654.52c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48l-1.7-1.55,2.16-1c.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M463.49,654.65h0s-1.95,1.12-1.95,1.12c-.13-.23-.23-.48-.29-.75-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.48-.8,.42,.49,1.62,1.91,1.65,1.96h0Z"
        />
        <path
          style={styles.four}
          d="M464.21,652.11h0s-.71,1.93-.71,1.93l-.51-2.02h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.24,0,.36,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.3,.09Z"
        />
        <path
          style={styles.four}
          d="M465.8,654.52c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.58-.8,1.71-.56s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M463.94,656.93c-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02l.69-1.83,.47,1.93Z"
        />
        <path
          style={styles.four}
          d="M463.13,654.6l-1.87,.42c-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93l1.77,1.11Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M463.38,650.26c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48-.2,.24-.45,.43-.73,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.32-.21-.59-.5-.79-.84-.13-.23-.23-.48-.29-.75-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.49-.8,.31-.34,.7-.57,1.15-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.23-.02,.35-.02,.08,0,.16,0,.24,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.3,.09,.67,.25,1.21,.83,1.45,1.55,.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M462.42,648.24c-.45,.71-.9,1.43-1.35,2.14h0s-1.06-2.41-1.06-2.41c.13-.07,.32-.17,.55-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.24,0,.36,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.11,.03,.21,.06,.3,.1,.31,.13,.53,.3,.63,.39Z"
        />
        <path
          style={styles.two}
          d="M462.05,652.49s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.28-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.3-.57,1.13-1.92,1.16-1.96h0s.98,2.11,.98,2.11Z"
        />
        <path
          style={styles.two}
          d="M463.38,650.26c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48l-1.7-1.55,2.17-.99c.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M461.08,650.38h0s-1.95,1.12-1.95,1.12c-.13-.23-.23-.48-.29-.75-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.49-.8,.42,.49,1.62,1.92,1.66,1.96h0Z"
        />
        <path
          style={styles.four}
          d="M461.79,647.84h0s-.71,1.93-.71,1.93l-.51-2.02h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.24,0,.36,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.3,.09Z"
        />
        <path
          style={styles.four}
          d="M463.38,650.26c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.58-.8,1.71-.56s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M461.52,652.67c-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02l.69-1.83,.48,1.93Z"
        />
        <path
          style={styles.four}
          d="M460.71,650.33l-1.87,.42c-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93l1.78,1.11Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M460.56,645.04c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48-.2,.24-.45,.43-.73,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.29-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.32-.21-.59-.5-.79-.84-.13-.23-.23-.48-.29-.75-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.49-.8,.31-.34,.7-.57,1.15-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.23-.02,.35-.02,.08,0,.16,0,.24,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.3,.09,.67,.25,1.21,.83,1.45,1.55,.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.03,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M459.6,643.02c-.45,.71-.9,1.43-1.35,2.14h0s-1.06-2.41-1.06-2.41c.13-.07,.32-.17,.55-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.24,0,.36,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.11,.03,.21,.06,.3,.1,.31,.13,.53,.3,.64,.39Z"
        />
        <path
          style={styles.two}
          d="M459.23,647.28s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.29-.14-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.3-.57,1.13-1.92,1.16-1.96h0s.98,2.11,.98,2.11Z"
        />
        <path
          style={styles.two}
          d="M460.56,645.04c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48l-1.71-1.55,2.17-.99c.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.03,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M458.25,645.16h0s-1.95,1.12-1.95,1.12c-.13-.23-.23-.48-.29-.75-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.49-.8,.42,.5,1.62,1.92,1.66,1.96h0Z"
        />
        <path
          style={styles.four}
          d="M458.97,642.62h0s-.71,1.93-.71,1.93l-.51-2.02h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.24,0,.36,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.2,.05,.3,.09Z"
        />
        <path
          style={styles.four}
          d="M460.56,645.04c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.58-.81,1.72-.56s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M458.7,647.45c-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02l.69-1.83,.48,1.93Z"
        />
        <path
          style={styles.four}
          d="M457.88,645.11l-1.88,.42c-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93l1.78,1.11Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M458.14,639.83c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48-.2,.24-.45,.43-.73,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.29-.14-.02,0-.03-.02-.05-.03-.02-.01-.03-.02-.05-.03-.32-.21-.6-.5-.79-.85-.13-.23-.23-.48-.29-.75-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.49-.8,.31-.34,.7-.57,1.15-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.23-.02,.35-.02,.08,0,.16,0,.24,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.21,.05,.3,.09,.67,.25,1.21,.83,1.45,1.55,.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.03,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M457.18,637.8c-.45,.71-.9,1.43-1.35,2.14h0s-1.06-2.41-1.06-2.41c.13-.07,.32-.17,.55-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.24,0,.36,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.11,.03,.21,.06,.3,.1,.31,.13,.53,.3,.64,.39Z"
        />
        <path
          style={styles.two}
          d="M456.81,642.06s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.29-.14-.02,0-.03-.02-.05-.03-.02-.01-.03-.02-.05-.03,.3-.57,1.13-1.92,1.16-1.96h0s.98,2.12,.98,2.12Z"
        />
        <path
          style={styles.two}
          d="M458.14,639.83c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48l-1.71-1.56,2.17-.99c.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.03,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M455.83,639.95h0s-1.95,1.12-1.95,1.12c-.13-.23-.23-.48-.29-.75-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.49-.8,.42,.5,1.62,1.92,1.66,1.97h0Z"
        />
        <path
          style={styles.four}
          d="M456.54,637.4h0s-.71,1.93-.71,1.93l-.51-2.03h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.24,0,.36,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.11,.02,.1,.02,.21,.05,.3,.09Z"
        />
        <path
          style={styles.four}
          d="M458.14,639.83c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.58-.81,1.72-.55s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M456.28,642.24c-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02l.69-1.83,.48,1.94Z"
        />
        <path
          style={styles.four}
          d="M455.46,639.89l-1.88,.42c-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93l1.78,1.12Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M456.53,635.57c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48-.2,.24-.45,.43-.73,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.29-.14-.02,0-.03-.02-.05-.03-.02-.01-.03-.02-.05-.03-.32-.21-.6-.5-.79-.85-.13-.23-.23-.48-.29-.75-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.49-.8,.31-.34,.7-.57,1.15-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.23-.02,.35-.02,.08,0,.16,0,.24,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.12,.02,.1,.02,.21,.05,.3,.09,.67,.26,1.21,.83,1.45,1.55,.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.03,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M455.56,633.54c-.45,.71-.9,1.43-1.35,2.14h0s-1.06-2.41-1.06-2.41c.13-.07,.32-.17,.55-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.25,0,.36,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.12,.02,.11,.03,.21,.06,.3,.1,.31,.13,.53,.3,.64,.39Z"
        />
        <path
          style={styles.two}
          d="M455.19,637.8s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.29-.14-.02,0-.03-.02-.05-.03-.02-.01-.03-.02-.05-.03,.3-.57,1.13-1.92,1.16-1.96h0s.98,2.12,.98,2.12Z"
        />
        <path
          style={styles.two}
          d="M456.53,635.57c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48l-1.71-1.56,2.17-.99c.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.03,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M454.21,635.68h0s-1.95,1.12-1.95,1.12c-.13-.23-.23-.48-.29-.75-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.49-.8,.42,.5,1.62,1.92,1.66,1.97h0Z"
        />
        <path
          style={styles.four}
          d="M454.93,633.14h0s-.71,1.93-.71,1.93l-.51-2.03h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.25,0,.36,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.12,.02,.1,.02,.21,.05,.3,.09Z"
        />
        <path
          style={styles.four}
          d="M456.53,635.57c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.58-.81,1.72-.55s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M454.66,637.98c-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02l.69-1.83,.48,1.94Z"
        />
        <path
          style={styles.four}
          d="M453.84,635.63l-1.88,.42c-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93l1.78,1.12Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M454.1,631.75c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48-.2,.24-.45,.43-.73,.56-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.29-.14-.02,0-.03-.02-.05-.03-.02-.01-.03-.02-.05-.03-.32-.21-.6-.5-.8-.85-.13-.23-.23-.48-.29-.75-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.49-.8,.31-.34,.71-.57,1.15-.67,0,0,.02,0,.02,0,.04-.01,.09-.02,.14-.03,.11-.02,.23-.02,.35-.02,.08,0,.16,0,.24,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.12,.02,.1,.02,.21,.05,.3,.09,.67,.26,1.21,.83,1.46,1.56,.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.03,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M453.14,629.72c-.45,.71-.9,1.43-1.35,2.14h0s-1.06-2.42-1.06-2.42c.13-.07,.32-.16,.55-.22,0,0,.01,0,.02,0,.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.25,0,.36,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.12,.02,.11,.03,.21,.06,.3,.1,.31,.13,.53,.3,.64,.39Z"
        />
        <path
          style={styles.two}
          d="M452.76,633.99s-.06,.03-.09,.04c-.04,.02-.08,.03-.12,.05-.01,0-.03,.01-.04,.02-.02,0-.05,.02-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.02,0,0,0,0,0,0-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02-.02,0-.04-.02-.06-.02-.1-.04-.19-.09-.29-.14-.02,0-.03-.02-.05-.03-.02-.01-.03-.02-.05-.03,.3-.57,1.14-1.92,1.16-1.96h0s.98,2.12,.98,2.12Z"
        />
        <path
          style={styles.two}
          d="M454.1,631.75c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.18-.01,.02-.02,.05-.03,.07-.09,.18-.2,.34-.32,.48l-1.71-1.56,2.18-.99c.04,.11,.07,.23,.09,.34,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.03,0,.01,0,.02,0,.03,0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M451.78,631.87h0s-1.96,1.11-1.96,1.11c-.13-.23-.23-.48-.29-.75-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93,.11-.3,.28-.57,.49-.8,.42,.5,1.63,1.93,1.66,1.97h0Z"
        />
        <path
          style={styles.four}
          d="M452.5,629.32h0s-.72,1.93-.72,1.93l-.51-2.03h0s.02,0,.02,0c.04-.01,.09-.02,.14-.03,.07-.01,.15-.02,.23-.02,.13,0,.25,0,.36,.02,.02,0,.04,0,.06,.01,.04,0,.08,.02,.12,.02,.1,.02,.21,.05,.3,.09Z"
        />
        <path
          style={styles.four}
          d="M454.1,631.75c0,.12,0,.24-.02,.36,0,.02,0,.05-.01,.07,0,.04-.01,.09-.02,.13,0,.02,0,.03,0,.04,0,.02-.01,.05-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.02,0,.02,0,.02-.01,.05-.02,.08,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.58-.81,1.72-.55s0,.02,0,.03c0,.02,0,.05,0,.07,0,.03,0,.05,0,.08,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.04,0,.07,0,.11Z"
        />
        <path
          style={styles.two}
          d="M452.23,634.16c-.09,.02-.18,.03-.27,.03-.03,0-.05,0-.08,0-.03,0-.07,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.12-.01-.03,0-.07,0-.1-.02,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.18-.04-.26-.07-.02,0-.05-.02-.07-.02l.69-1.83,.48,1.94Z"
        />
        <path
          style={styles.four}
          d="M451.41,631.81l-1.88,.41c-.04-.19-.07-.4-.07-.6,0-.33,.06-.64,.17-.93l1.78,1.12Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M451.67,626.37c0,.11,0,.23-.02,.34,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.12,0,.01,0,.03,0,.04,0,.02-.01,.04-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.17-.01,.02-.02,.04-.03,.06-.09,.17-.19,.32-.31,.45-.2,.22-.43,.4-.7,.53-.03,.01-.06,.03-.09,.04-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.01-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,0-.07,.01,0,0,0,0,0,0-.08,.02-.17,.02-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.02-.07-.02-.02,0-.04-.01-.06-.02-.09-.04-.19-.08-.27-.13-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.2-.57-.47-.76-.8-.13-.22-.22-.45-.28-.71-.04-.18-.07-.37-.07-.57,0-.31,.06-.61,.16-.87,.11-.28,.27-.54,.47-.75,.3-.32,.68-.54,1.11-.62,0,0,.02,0,.02,0,.04,0,.09-.02,.13-.02,.11-.01,.22-.02,.34-.02,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.11,.02,.1,.02,.2,.05,.29,.09,.65,.24,1.16,.79,1.4,1.47,.04,.1,.07,.21,.09,.32,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.1Z"
        />
        <path
          style={styles.two}
          d="M450.74,624.46c-.43,.67-.87,1.34-1.3,2.01h0s-1.02-2.28-1.02-2.28c.12-.07,.3-.15,.53-.21,0,0,.01,0,.02,0,.04,0,.09-.02,.13-.02,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.34,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.11,.02,.1,.03,.2,.06,.29,.09,.3,.12,.51,.28,.61,.37Z"
        />
        <path
          style={styles.two}
          d="M450.39,628.47s-.06,.03-.09,.04c-.04,.02-.08,.03-.11,.04-.01,0-.03,.01-.04,.01-.02,0-.05,.01-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,.01-.07,.01,0,0,0,0,0,0-.08,.02-.17,.02-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.01-.07-.02-.02,0-.04-.01-.06-.02-.09-.04-.19-.08-.27-.13-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.54,1.09-1.8,1.12-1.84h0s.94,2,.94,2Z"
        />
        <path
          style={styles.two}
          d="M451.67,626.37c0,.11,0,.23-.02,.34,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.12,0,.01,0,.03,0,.04,0,.02-.01,.04-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03-.02,.06-.05,.12-.08,.17-.01,.02-.02,.04-.03,.06-.09,.17-.19,.32-.31,.45l-1.64-1.47,2.09-.92c.04,.1,.07,.21,.09,.32,0,.03,.01,.05,.01,.08,0,0,0,.02,0,.02,0,.01,0,.02,0,.03,0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.1Z"
        />
        <path
          style={styles.two}
          d="M449.44,626.47h0s-1.88,1.04-1.88,1.04c-.13-.22-.22-.45-.28-.71-.04-.18-.07-.37-.07-.57,0-.31,.06-.61,.16-.87,.11-.28,.27-.54,.47-.75,.4,.47,1.56,1.82,1.6,1.86h0Z"
        />
        <path
          style={styles.four}
          d="M450.14,624.08h0s-.69,1.82-.69,1.82l-.49-1.91h0s.02,0,.02,0c.04,0,.09-.02,.13-.02,.07-.01,.14-.02,.22-.02,.12,0,.24,0,.34,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.11,.02,.1,.02,.2,.05,.29,.09Z"
        />
        <path
          style={styles.four}
          d="M451.67,626.37c0,.11,0,.23-.02,.34,0,.02,0,.05-.01,.07,0,.04-.01,.08-.02,.12,0,.01,0,.03,0,.04,0,.02-.01,.04-.02,.07,0,.02-.01,.04-.02,.06,0,0,0,.01,0,.02,0,.02-.01,.05-.02,.07,0,0,0,0,0,.01,0,.02-.01,.04-.02,.06,0,.01,0,.02-.01,.03l-1.52-.77,1.65-.52s0,.02,0,.03c0,.02,0,.04,0,.07,0,.02,0,.05,0,.07,0,.02,0,.04,0,.06,0,.02,0,.04,0,.06,0,.04,0,.07,0,.1Z"
        />
        <path
          style={styles.two}
          d="M449.88,628.63c-.08,.02-.17,.02-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06-.01-.09-.02-.17-.04-.25-.07-.02,0-.04-.01-.07-.02l.66-1.72,.46,1.82Z"
        />
        <path
          style={styles.four}
          d="M449.09,626.42l-1.81,.39c-.04-.18-.07-.37-.07-.57,0-.31,.06-.61,.16-.87l1.71,1.06Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M449.94,621.39c0,.1,0,.21-.02,.3,0,.02,0,.04-.01,.06,0,.04-.01,.07-.02,.11,0,.01,0,.03,0,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,.01,0,.02-.01,.04-.02,.05,0,0,0,.02-.01,.03-.02,.05-.05,.11-.08,.16-.01,.02-.02,.04-.03,.06-.09,.15-.19,.29-.31,.41-.2,.2-.43,.36-.7,.47-.03,.01-.06,.02-.09,.03-.04,.01-.08,.03-.11,.04-.01,0-.03,0-.04,.01-.02,0-.05,.01-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,0-.07,.01,0,0,0,0,0,0-.08,.01-.17,.02-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.09-.02-.17-.04-.25-.06-.02,0-.04-.01-.07-.02-.02,0-.04-.01-.06-.02-.09-.03-.19-.08-.28-.12-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03-.31-.18-.57-.43-.76-.72-.13-.2-.22-.41-.28-.64-.04-.16-.07-.34-.07-.51,0-.28,.06-.55,.16-.79,.11-.26,.27-.48,.47-.67,.3-.28,.68-.48,1.11-.56,0,0,.02,0,.02,0,.04,0,.09-.02,.13-.02,.11-.01,.22-.02,.34-.01,.08,0,.15,0,.23,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.11,.02,.1,.02,.2,.05,.29,.08,.65,.22,1.17,.71,1.4,1.33,.04,.09,.07,.19,.09,.29,0,.02,.01,.05,.01,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M449.01,619.67c-.43,.6-.87,1.21-1.3,1.81h0s-1.02-2.05-1.02-2.05c.12-.06,.3-.14,.53-.18,0,0,.01,0,.02,0,.04,0,.09-.02,.13-.02,.07,0,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.11,.02,.1,.02,.2,.05,.29,.09,.3,.11,.51,.26,.61,.33Z"
        />
        <path
          style={styles.two}
          d="M448.65,623.28s-.06,.02-.09,.03c-.04,.01-.08,.03-.11,.04-.01,0-.03,0-.04,.01-.02,0-.05,.01-.07,.02-.04,.01-.08,.02-.12,.03-.02,0-.05,0-.07,.01,0,0,0,0,0,0-.08,.01-.17,.02-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.09-.02-.17-.04-.25-.06-.02,0-.04-.01-.07-.02-.02,0-.04-.01-.06-.02-.09-.03-.19-.08-.28-.12-.02,0-.03-.02-.05-.03-.02,0-.03-.02-.05-.03,.29-.48,1.09-1.62,1.12-1.66h0s.94,1.8,.94,1.8Z"
        />
        <path
          style={styles.two}
          d="M449.94,621.39c0,.1,0,.21-.02,.3,0,.02,0,.04-.01,.06,0,.04-.01,.07-.02,.11,0,.01,0,.03,0,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,.01,0,.02-.01,.04-.02,.05,0,0,0,.02-.01,.03-.02,.05-.05,.11-.08,.16-.01,.02-.02,.04-.03,.06-.09,.15-.19,.29-.31,.41l-1.64-1.33,2.09-.82c.04,.09,.07,.19,.09,.29,0,.02,.01,.05,.01,.07,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M447.71,621.48h0s-1.88,.93-1.88,.93c-.13-.2-.22-.41-.28-.64-.04-.16-.07-.34-.07-.51,0-.28,.06-.55,.16-.79,.11-.26,.27-.48,.47-.67,.4,.42,1.56,1.64,1.6,1.68h0Z"
        />
        <path
          style={styles.four}
          d="M448.4,619.32h0s-.69,1.64-.69,1.64l-.49-1.72h0s.02,0,.02,0c.04,0,.09-.02,.13-.02,.07,0,.14-.02,.22-.02,.12,0,.24,0,.35,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.11,.02,.1,.02,.2,.05,.29,.08Z"
        />
        <path
          style={styles.four}
          d="M449.94,621.39c0,.1,0,.21-.02,.3,0,.02,0,.04-.01,.06,0,.04-.01,.07-.02,.11,0,.01,0,.03,0,.04,0,.02-.01,.04-.02,.06,0,.02-.01,.03-.02,.05,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,.01,0,.02-.01,.04-.02,.05,0,0,0,.02-.01,.03l-1.52-.7,1.66-.46s0,.02,0,.03c0,.02,0,.04,0,.06,0,.02,0,.04,0,.07,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.03,0,.06,0,.09Z"
        />
        <path
          style={styles.two}
          d="M448.14,623.42c-.08,.01-.17,.02-.26,.03-.03,0-.05,0-.08,0-.03,0-.06,0-.1,0-.03,0-.06,0-.09,0-.04,0-.08,0-.11-.01-.03,0-.06,0-.1-.01,0,0-.01,0-.02,0-.02,0-.04,0-.06,0-.09-.02-.17-.04-.25-.06-.02,0-.04-.01-.07-.02l.66-1.55,.46,1.65Z"
        />
        <path
          style={styles.four}
          d="M447.35,621.43l-1.81,.34c-.04-.16-.07-.34-.07-.51,0-.28,.06-.55,.16-.79l1.72,.96Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M448.03,616.89c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36-.18,.18-.41,.32-.66,.41-.03,.01-.06,.02-.08,.03-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.06,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11,0-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.17-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02-.29-.16-.54-.38-.72-.64-.12-.17-.21-.36-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.69,.1-.23,.25-.43,.44-.59,.28-.25,.64-.42,1.04-.49,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.1-.01,.21-.01,.31-.01,.07,0,.14,0,.21,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07,.61,.2,1.09,.63,1.31,1.18,.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M447.16,615.36c-.41,.53-.81,1.06-1.22,1.59h0s-.96-1.82-.96-1.82c.12-.05,.29-.12,.5-.16,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.06,0,.13-.01,.2-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.1,.02,.19,.05,.27,.08,.28,.1,.48,.23,.57,.3Z"
        />
        <path
          style={styles.two}
          d="M446.82,618.55s-.06,.02-.08,.03c-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.06,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11,0-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.17-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02,.27-.43,1.02-1.43,1.05-1.46h0s.88,1.6,.88,1.6Z"
        />
        <path
          style={styles.two}
          d="M448.03,616.89c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36l-1.54-1.18,1.96-.72c.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M445.94,616.95h0s-1.76,.82-1.76,.82c-.12-.17-.21-.36-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.69,.1-.23,.25-.43,.44-.59,.38,.38,1.46,1.45,1.5,1.49h0Z"
        />
        <path
          style={styles.four}
          d="M446.59,615.06h0s-.64,1.44-.64,1.44l-.46-1.52h0s.01,0,.02,0c.04,0,.08-.01,.13-.02,.06,0,.13-.01,.2-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07Z"
        />
        <path
          style={styles.four}
          d="M448.03,616.89c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03l-1.43-.62,1.55-.4s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M446.34,618.68c-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11,0-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02l.62-1.37,.43,1.46Z"
        />
        <path
          style={styles.four}
          d="M445.61,616.91l-1.7,.3c-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.69l1.61,.85Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M446.52,613.08c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36-.18,.18-.41,.32-.66,.41-.03,.01-.06,.02-.08,.03-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.06,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11,0-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.17-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02-.29-.16-.54-.38-.72-.64-.12-.17-.21-.36-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.28-.25,.64-.42,1.04-.49,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.1-.01,.21-.01,.31-.01,.07,0,.14,0,.21,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07,.61,.2,1.09,.63,1.31,1.18,.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M445.65,611.55c-.41,.53-.81,1.06-1.22,1.59h0s-.96-1.82-.96-1.82c.12-.05,.29-.12,.5-.16,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.06,0,.13-.01,.2-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.1,.02,.19,.05,.27,.08,.28,.1,.48,.23,.57,.3Z"
        />
        <path
          style={styles.two}
          d="M445.31,614.74s-.06,.02-.08,.03c-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.06,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11,0-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.17-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02,.27-.43,1.02-1.43,1.05-1.46h0s.88,1.6,.88,1.6Z"
        />
        <path
          style={styles.two}
          d="M446.52,613.08c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36l-1.54-1.18,1.96-.72c.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M444.43,613.15h0s-1.76,.82-1.76,.82c-.12-.17-.21-.36-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.38,.38,1.47,1.46,1.5,1.49h0Z"
        />
        <path
          style={styles.four}
          d="M445.07,611.25h0s-.64,1.44-.64,1.44l-.46-1.52h0s.01,0,.02,0c.04,0,.08-.01,.13-.02,.06,0,.13-.01,.2-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07Z"
        />
        <path
          style={styles.four}
          d="M446.52,613.08c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03l-1.43-.62,1.55-.4s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M444.83,614.87c-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11,0-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02l.62-1.37,.43,1.46Z"
        />
        <path
          style={styles.four}
          d="M444.09,613.1l-1.7,.29c-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7l1.61,.85Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M445.3,608.81c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36-.18,.18-.41,.32-.66,.41-.03,.01-.06,.02-.08,.03-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.06,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.17-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02-.29-.16-.54-.38-.72-.64-.12-.17-.21-.36-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.28-.25,.64-.42,1.04-.49,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.1-.01,.21-.01,.31-.01,.07,0,.14,0,.21,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07,.61,.2,1.09,.64,1.31,1.18,.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M444.43,607.28c-.41,.53-.81,1.06-1.22,1.59h0s-.96-1.82-.96-1.82c.12-.05,.29-.12,.5-.16,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.07,0,.13-.01,.2-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.1,.02,.19,.05,.27,.08,.28,.1,.48,.23,.58,.3Z"
        />
        <path
          style={styles.two}
          d="M444.09,610.47s-.06,.02-.08,.03c-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.06,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.17-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02,.27-.43,1.02-1.43,1.05-1.46h0s.88,1.6,.88,1.6Z"
        />
        <path
          style={styles.two}
          d="M445.3,608.81c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36l-1.54-1.19,1.96-.72c.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M443.21,608.88h0s-1.76,.82-1.76,.82c-.12-.17-.21-.36-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.38,.38,1.47,1.46,1.5,1.49h0Z"
        />
        <path
          style={styles.four}
          d="M443.86,606.98h0s-.64,1.44-.64,1.44l-.46-1.52h0s.01,0,.02,0c.04,0,.08-.01,.13-.02,.07,0,.13-.01,.2-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07Z"
        />
        <path
          style={styles.four}
          d="M445.3,608.81c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03l-1.43-.62,1.55-.4s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M443.61,610.6c-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02l.62-1.36,.43,1.46Z"
        />
        <path
          style={styles.four}
          d="M442.87,608.83l-1.7,.29c-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7l1.61,.86Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M444.08,605.01c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36-.18,.17-.41,.32-.66,.41-.03,.01-.06,.02-.08,.03-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02-.29-.16-.54-.38-.72-.64-.12-.17-.21-.36-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.28-.25,.64-.42,1.04-.49,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.1-.01,.21-.01,.31,0,.07,0,.14,0,.21,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07,.61,.2,1.09,.64,1.31,1.18,.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M443.21,603.48c-.41,.53-.81,1.06-1.22,1.59h0s-.96-1.82-.96-1.82c.12-.05,.29-.12,.5-.16,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.07,0,.13-.01,.2-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.1,.02,.19,.05,.27,.08,.28,.1,.48,.23,.58,.3Z"
        />
        <path
          style={styles.two}
          d="M442.87,606.67s-.06,.02-.08,.03c-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02,.27-.43,1.02-1.43,1.05-1.46h0s.88,1.6,.88,1.6Z"
        />
        <path
          style={styles.two}
          d="M444.08,605.01c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36l-1.54-1.19,1.96-.72c.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M441.99,605.07h0s-1.77,.81-1.77,.81c-.12-.17-.21-.36-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.38,.38,1.47,1.46,1.5,1.49h0Z"
        />
        <path
          style={styles.four}
          d="M442.64,603.17h0s-.65,1.44-.65,1.44l-.46-1.53h0s.01,0,.02,0c.04,0,.08-.01,.13-.02,.07,0,.13-.01,.2-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07Z"
        />
        <path
          style={styles.four}
          d="M444.08,605.01c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03l-1.43-.63,1.55-.4s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M442.39,606.8c-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02l.62-1.36,.43,1.46Z"
        />
        <path
          style={styles.four}
          d="M441.65,605.03l-1.7,.29c-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7l1.61,.86Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M442.86,601.21c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36-.18,.17-.41,.32-.66,.41-.03,.01-.06,.02-.08,.03-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02-.29-.16-.54-.38-.72-.64-.12-.17-.21-.36-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.28-.25,.64-.42,1.04-.49,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.1-.01,.21-.01,.31,0,.07,0,.14,0,.21,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07,.61,.2,1.09,.64,1.31,1.18,.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M441.99,599.67c-.41,.53-.81,1.06-1.22,1.59h0s-.96-1.82-.96-1.82c.12-.05,.29-.12,.5-.16,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.07,0,.13-.01,.2-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.1,.02,.19,.05,.27,.08,.28,.1,.48,.23,.58,.3Z"
        />
        <path
          style={styles.two}
          d="M441.65,602.87s-.06,.02-.08,.03c-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02,.27-.43,1.03-1.43,1.05-1.46h0s.89,1.6,.89,1.6Z"
        />
        <path
          style={styles.two}
          d="M442.86,601.21c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36l-1.54-1.19,1.96-.72c.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M440.77,601.27h0s-1.77,.81-1.77,.81c-.12-.17-.21-.36-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.38,.38,1.47,1.46,1.5,1.5h0Z"
        />
        <path
          style={styles.four}
          d="M441.41,599.37h0s-.65,1.44-.65,1.44l-.46-1.53h0s.01,0,.02,0c.04,0,.08-.01,.13-.02,.07,0,.13-.01,.2-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07Z"
        />
        <path
          style={styles.four}
          d="M442.86,601.21c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03l-1.43-.63,1.55-.39s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M441.17,602.99c-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02l.62-1.36,.43,1.46Z"
        />
        <path
          style={styles.four}
          d="M440.43,601.22l-1.7,.29c-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7l1.61,.86Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M442.04,597.41c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36-.18,.17-.41,.31-.66,.41-.03,.01-.06,.02-.08,.03-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02-.29-.16-.54-.38-.72-.65-.12-.17-.21-.36-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.28-.25,.64-.42,1.04-.49,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.1,0,.21-.01,.32,0,.07,0,.14,0,.21,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07,.61,.2,1.09,.64,1.32,1.18,.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M441.17,595.88c-.41,.53-.81,1.06-1.22,1.59h0s-.96-1.82-.96-1.82c.12-.05,.29-.12,.5-.16,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.07,0,.13-.01,.21-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.1,.02,.19,.05,.27,.08,.28,.1,.48,.23,.58,.3Z"
        />
        <path
          style={styles.two}
          d="M440.84,599.08s-.06,.02-.08,.03c-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02,.27-.43,1.03-1.43,1.05-1.46h0s.89,1.6,.89,1.6Z"
        />
        <path
          style={styles.two}
          d="M442.04,597.41c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36l-1.54-1.19,1.96-.71c.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M439.95,597.47h0s-1.77,.81-1.77,.81c-.12-.17-.21-.36-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.38,.38,1.47,1.46,1.5,1.5h0Z"
        />
        <path
          style={styles.four}
          d="M440.6,595.57h0s-.65,1.44-.65,1.44l-.46-1.53h0s.01,0,.02,0c.04,0,.08-.01,.13-.02,.07,0,.13-.01,.21-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07Z"
        />
        <path
          style={styles.four}
          d="M442.04,597.41c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03l-1.43-.63,1.56-.39s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M440.36,599.2c-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02l.62-1.36,.43,1.46Z"
        />
        <path
          style={styles.four}
          d="M439.62,597.43l-1.7,.29c-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7l1.61,.86Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M441.23,594.09c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36-.18,.17-.41,.31-.66,.41-.03,.01-.06,.02-.08,.03-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02-.29-.16-.54-.38-.72-.65-.12-.17-.21-.37-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.28-.25,.64-.42,1.04-.49,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.1,0,.21-.01,.32,0,.07,0,.14,0,.21,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07,.61,.2,1.1,.64,1.32,1.19,.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M440.36,592.56c-.41,.53-.81,1.06-1.22,1.59h0s-.96-1.83-.96-1.83c.12-.05,.29-.12,.5-.16,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.07,0,.13-.01,.21-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.1,.02,.19,.05,.27,.08,.28,.1,.48,.23,.58,.3Z"
        />
        <path
          style={styles.two}
          d="M440.02,595.75s-.06,.02-.08,.03c-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02,.27-.43,1.03-1.42,1.05-1.46h0s.89,1.6,.89,1.6Z"
        />
        <path
          style={styles.two}
          d="M441.23,594.09c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36l-1.55-1.19,1.97-.71c.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M439.14,594.15h0s-1.77,.81-1.77,.81c-.12-.17-.21-.37-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.38,.38,1.47,1.47,1.5,1.5h0Z"
        />
        <path
          style={styles.four}
          d="M439.79,592.25h0s-.65,1.44-.65,1.44l-.46-1.53h0s.01,0,.02,0c.04,0,.08-.01,.13-.02,.07,0,.13-.01,.21-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07Z"
        />
        <path
          style={styles.four}
          d="M441.23,594.09c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03l-1.43-.63,1.56-.39s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M439.54,595.88c-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02l.62-1.36,.43,1.46Z"
        />
        <path
          style={styles.four}
          d="M438.8,594.1l-1.7,.29c-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7l1.61,.86Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M440.42,590.3c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36-.18,.17-.41,.31-.66,.41-.03,.01-.06,.02-.08,.03-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02-.29-.16-.54-.38-.72-.65-.12-.17-.21-.37-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.28-.25,.64-.42,1.04-.48,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.1,0,.21-.01,.32,0,.07,0,.14,0,.21,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07,.61,.2,1.1,.64,1.32,1.19,.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M439.54,588.76c-.41,.53-.82,1.06-1.22,1.59h0s-.96-1.83-.96-1.83c.12-.05,.29-.12,.5-.16,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.07,0,.13-.01,.21-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.1,.02,.19,.05,.27,.08,.28,.1,.48,.23,.58,.3Z"
        />
        <path
          style={styles.two}
          d="M439.21,591.96s-.06,.02-.08,.03c-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02,.27-.43,1.03-1.42,1.05-1.45h0s.89,1.6,.89,1.6Z"
        />
        <path
          style={styles.two}
          d="M440.42,590.3c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36l-1.55-1.19,1.97-.71c.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M438.32,590.35h0s-1.77,.81-1.77,.81c-.12-.17-.21-.37-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.38,.38,1.47,1.47,1.5,1.5h0Z"
        />
        <path
          style={styles.four}
          d="M438.97,588.45h0s-.65,1.44-.65,1.44l-.46-1.53h0s.01,0,.02,0c.04,0,.08-.01,.13-.02,.07,0,.13-.01,.21-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07Z"
        />
        <path
          style={styles.four}
          d="M440.42,590.3c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03l-1.43-.63,1.56-.39s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M438.73,592.08c-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02l.62-1.36,.43,1.46Z"
        />
        <path
          style={styles.four}
          d="M437.99,590.31l-1.7,.29c-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7l1.61,.86Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M439.6,586.03c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36-.18,.17-.41,.31-.66,.41-.03,.01-.06,.02-.08,.03-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02-.29-.16-.54-.39-.72-.65-.12-.17-.21-.37-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.28-.25,.64-.42,1.04-.48,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.1,0,.21-.01,.32,0,.07,0,.14,0,.21,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07,.61,.2,1.1,.64,1.32,1.19,.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M438.73,584.5c-.41,.53-.82,1.06-1.22,1.59h0s-.96-1.83-.96-1.83c.12-.05,.29-.12,.5-.16,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.07,0,.13-.01,.21-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.1,.02,.19,.05,.27,.08,.28,.1,.48,.23,.58,.3Z"
        />
        <path
          style={styles.two}
          d="M438.39,587.69s-.06,.02-.08,.03c-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02,.27-.43,1.03-1.42,1.05-1.45h0s.89,1.61,.89,1.61Z"
        />
        <path
          style={styles.two}
          d="M439.6,586.03c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36l-1.55-1.2,1.97-.71c.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M437.51,586.08h0s-1.77,.81-1.77,.81c-.12-.17-.21-.37-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.38,.38,1.47,1.47,1.5,1.5h0Z"
        />
        <path
          style={styles.four}
          d="M438.16,584.19h0s-.65,1.44-.65,1.44l-.46-1.53h0s.01,0,.02,0c.04,0,.08-.01,.13-.02,.07,0,.13-.01,.21-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07Z"
        />
        <path
          style={styles.four}
          d="M439.6,586.03c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03l-1.43-.63,1.56-.39s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M437.91,587.81c-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02l.62-1.36,.43,1.46Z"
        />
        <path
          style={styles.four}
          d="M437.17,586.04l-1.7,.28c-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7l1.61,.87Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M439.19,582.25c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36-.18,.17-.41,.31-.66,.41-.03,.01-.06,.02-.08,.03-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02-.29-.16-.54-.39-.72-.65-.12-.17-.21-.37-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.28-.25,.64-.42,1.04-.48,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.1,0,.21-.01,.32,0,.07,0,.14,0,.21,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07,.61,.2,1.1,.64,1.32,1.19,.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M438.32,580.71c-.41,.53-.82,1.06-1.22,1.59h0s-.96-1.83-.96-1.83c.12-.05,.29-.12,.5-.16,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.07,0,.13-.01,.21-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.1,.02,.19,.05,.27,.08,.28,.1,.48,.23,.58,.3Z"
        />
        <path
          style={styles.two}
          d="M437.99,583.91s-.06,.02-.08,.03c-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02,.27-.43,1.03-1.42,1.05-1.45h0s.89,1.61,.89,1.61Z"
        />
        <path
          style={styles.two}
          d="M439.19,582.25c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36l-1.55-1.2,1.97-.71c.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M437.1,582.3h0s-1.77,.81-1.77,.81c-.12-.17-.21-.37-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.38,.38,1.47,1.47,1.5,1.5h0Z"
        />
        <path
          style={styles.four}
          d="M437.75,580.4h0s-.65,1.44-.65,1.44l-.46-1.53h0s.01,0,.02,0c.04,0,.08-.01,.13-.02,.07,0,.13-.01,.21-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07Z"
        />
        <path
          style={styles.four}
          d="M439.19,582.25c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03l-1.43-.63,1.56-.39s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M437.5,584.03c-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02l.63-1.36,.43,1.46Z"
        />
        <path
          style={styles.four}
          d="M436.76,582.25l-1.7,.28c-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7l1.61,.87Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M439.6,578.97c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36-.18,.17-.41,.31-.66,.41-.03,.01-.06,.02-.08,.03-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02-.29-.16-.54-.39-.72-.65-.12-.17-.21-.37-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.28-.25,.64-.42,1.04-.48,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.1,0,.21-.01,.32,0,.07,0,.14,0,.21,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07,.61,.2,1.1,.64,1.32,1.19,.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M438.73,577.43c-.41,.53-.82,1.06-1.22,1.59h0s-.96-1.83-.96-1.83c.12-.05,.29-.12,.5-.16,0,0,.01,0,.02,0,.04,0,.08-.01,.13-.02,.07,0,.13-.01,.21-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.1,.02,.19,.05,.27,.08,.28,.1,.48,.23,.58,.3Z"
        />
        <path
          style={styles.two}
          d="M438.39,580.63s-.06,.02-.08,.03c-.04,.01-.07,.02-.11,.03-.01,0-.03,0-.04,.01-.02,0-.04,.01-.07,.02-.04,0-.07,.02-.11,.02-.02,0-.04,0-.07,.01,0,0,0,0,0,0-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02-.02,0-.04-.01-.05-.02-.09-.03-.18-.07-.26-.11-.02,0-.03-.01-.04-.02-.01,0-.03-.02-.04-.02,.27-.42,1.03-1.42,1.05-1.45h0s.89,1.61,.89,1.61Z"
        />
        <path
          style={styles.two}
          d="M439.6,578.97c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03-.02,.05-.04,.09-.07,.14,0,.02-.02,.03-.03,.05-.08,.13-.18,.25-.29,.36l-1.55-1.2,1.97-.71c.03,.08,.06,.17,.08,.26,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M437.51,579.02h0s-1.77,.8-1.77,.8c-.12-.17-.21-.37-.27-.57-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7,.1-.23,.25-.43,.44-.59,.38,.38,1.47,1.47,1.5,1.51h0Z"
        />
        <path
          style={styles.four}
          d="M438.16,577.12h0s-.65,1.44-.65,1.44l-.46-1.53h0s.01,0,.02,0c.04,0,.08-.01,.13-.02,.07,0,.13-.01,.21-.01,.11,0,.22,0,.32,.02,.02,0,.04,0,.06,0,.04,0,.07,.01,.1,.02,.09,.02,.19,.04,.27,.07Z"
        />
        <path
          style={styles.four}
          d="M439.6,578.97c0,.09,0,.18-.02,.27,0,.02,0,.04,0,.05,0,.03-.01,.06-.02,.1,0,.01,0,.02,0,.03,0,.02,0,.03-.02,.05,0,.01,0,.03-.01,.04,0,0,0,.01,0,.02,0,.02-.01,.04-.02,.06,0,0,0,0,0,0,0,.02-.01,.03-.02,.05,0,0,0,.02-.01,.03l-1.43-.63,1.56-.39s0,.02,0,.03c0,.02,0,.04,0,.05,0,.02,0,.04,0,.06,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05,0,.03,0,.06,0,.08Z"
        />
        <path
          style={styles.two}
          d="M437.91,580.75c-.08,.01-.16,.02-.24,.02-.02,0-.05,0-.07,0-.03,0-.06,0-.09,0-.03,0-.06,0-.08,0-.04,0-.07,0-.11-.01-.03,0-.06,0-.09-.01,0,0-.01,0-.02,0-.02,0-.04,0-.05,0-.08-.01-.16-.03-.24-.06-.02,0-.04-.01-.06-.02l.62-1.36,.43,1.46Z"
        />
        <path
          style={styles.four}
          d="M437.17,578.97l-1.7,.28c-.04-.15-.06-.3-.06-.45,0-.25,.05-.48,.15-.7l1.61,.87Z"
        />
      </g>
      <path
        style={styles.six}
        d="M485.06,681.41h0c.35,.13,.71,.23,1.09,.31h0"
      />
      <path
        style={styles.six}
        d="M483.59,680.71c-1.06-.62-1.9-1.37-2.48-1.88-8.55-7.59-14.51-15.52-18.32-21.3-7.07-10.7-10.98-20.37-15.9-32.55-3.02-7.48-5.77-14.35-8.21-24.31-2.04-8.32-3.09-15.6-3.67-21.03-.04-.4,.06-.79,.27-1.07,1-1.33,2.3-1.87,3.41-1.37,.3,.13,.55,.33,.77,.55,.32,.32,.46,.88,.35,1.39-.1,.46-.19,1-.26,1.61,0,0-.18,1.7,0,3.52,1.79,17.75,9.93,36.68,9.93,36.68,1.42,3.3,2.79,6.32,4.09,9.04,3.25,6.84,6.04,12,7.51,14.65,0,0,8.55,15.44,20.45,28.38,.85,.93,2.37,2.46,4.4,2.68,1,.11,1.93-.12,2.38-.24,1.62-.42,2.78-1.24,3.38-1.68,8.49-6.25,13.04-11.89,13.04-11.89,2.66-3.33,4.73-6.45,6.69-9.66,6.69-10.93,10.99-20.92,12.91-25.55,7.35-17.77,13.05-31.55,9.11-39.85-.41-.87-.87-1.56-1.3-2.1-.19-.23-.34-1.22-.04-1.81,.39-.78,1.51-.67,1.72-.66,0,0,.04,0,.08,0,.25,.04,1.57,.35,2.84,2.4,.74,1.2,3.18,8.46-1.23,25.16-1.72,6.5-3.52,10.81-6.62,18.14-3.18,7.54-5.72,13.54-9.96,20.99-6.12,10.76-11.58,17.12-15.46,21.58,0,0-3.41,3.92-8.83,7.94-3.2,2.37-5.04,3.23-6.71,3.35"
      />
    </g>
  );
};

export default Blinding;
