const Hearts = () => {
  const styles = {
    one: {
      fill: "#ed1c24",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      fill: "#ff7bac",
      strokeMiterLimit: 10,
      stroke: "#ff7bac",
    },
  };

  return (
    <g>
      <path
        style={styles.two}
        d="M440.5,363.5c-.58,.01-5.05,.18-8,4-2.68,3.47-2.11,7.35-2,8,.27-1.46,1.1-4.9,4-8,2.21-2.37,4.64-3.49,6-4Z"
      />
      <g>
        <g>
          <path d="M451.13,355.2c.57-1.28,1.2-2.75,1.88-4.19,2.85-5.97,7.15-10.57,13.18-13.39,5.74-2.69,11.85-1.51,16.31,3.01,2.47,2.5,4.04,5.54,5.13,8.84,2.37,7.22,2.69,14.55,1.23,22-1.45,7.39-4.45,14.14-8.21,20.6-1.65,2.83-3.89,4.67-7.2,5.43-15.42,3.56-29.65,1.03-42.68-7.98-3.19-2.2-5.84-4.97-7.71-8.41-3.37-6.18-2.33-12.86,2.8-17.72,3.88-3.68,8.57-5.87,13.79-6.78,3.68-.64,7.42-.92,11.49-1.4Zm1.52,1.69c-1.28,.2-2.47,.36-3.65,.56-5.46,.92-10.77,2.29-15.63,5.08-2.25,1.29-4.2,2.89-5.78,4.95-2.27,2.97-2.72,6.22-1.56,9.73,.85,2.58,2.48,4.64,4.43,6.45,2.84,2.64,6.13,4.58,9.73,6,6.45,2.54,13.16,4.07,20.07,4.22,4.76,.1,9.55-.5,14.31-.89,.69-.06,1.6-.51,1.97-1.07,1.72-2.59,3.63-5.13,4.83-7.95,3.98-9.32,5.09-19.09,3.69-29.1-.55-3.93-1.99-7.57-4.48-10.71-3.18-4.01-7.32-5.21-12.17-3.59-2.4,.8-4.5,2.08-6.29,3.84-3.72,3.65-6.97,7.67-9.48,12.48Z" />
          <path d="M604.9,355.42c4.48,.57,8.45,.92,12.36,1.62,4.39,.79,8.33,2.74,11.8,5.58,6.53,5.35,7.71,13.05,3.09,20.11-1.77,2.71-4.03,4.96-6.63,6.86-6.78,4.96-14.35,7.97-22.67,9.12-5.17,.71-10.37,.83-15.53,.08-2.36-.34-4.67-1.08-6.96-1.75-1.86-.54-3.15-1.93-4.13-3.49-6.2-9.86-10.21-20.42-9.9-32.27,.13-4.83,1.08-9.53,3.04-13.97,.77-1.74,1.74-3.44,2.9-4.94,4.64-6.01,11.64-7.58,18.4-4.12,5.68,2.91,9.75,7.41,12.45,13.17,.67,1.44,1.3,2.9,1.79,4.01Zm-34.58,7.33c.25,2.94,.34,5.91,.79,8.81,1.15,7.34,3.32,14.3,8.04,20.25,.64,.81,1.27,1.42,2.3,1.5,4.53,.34,9.08,1.03,13.6,.89,4.2-.12,8.4-.96,12.54-1.78,5.2-1.03,10.15-2.84,14.59-5.83,2.82-1.89,5.29-4.13,6.95-7.14,2.35-4.26,2.04-8.6-1.14-12.23-1.4-1.59-3.1-3.03-4.91-4.13-2.17-1.31-4.52-2.45-6.95-3.17-3.58-1.06-7.29-1.7-10.95-2.49-1-.22-1.86-.29-2.48-1.47-2.28-4.35-5.43-8.07-8.95-11.47-2.04-1.97-4.47-3.36-7.25-4.04-3.58-.87-6.82-.19-9.57,2.36-2.09,1.94-3.48,4.35-4.52,6.98-1.65,4.17-2.04,8.51-2.1,12.95Z" />
          <path
            style={styles.one}
            d="M452.65,356.89c2.5-4.81,5.76-8.83,9.48-12.48,1.79-1.75,3.89-3.04,6.29-3.84,4.85-1.61,8.99-.42,12.17,3.59,2.49,3.15,3.93,6.79,4.48,10.71,1.4,10.02,.28,19.79-3.69,29.1-1.21,2.83-3.12,5.37-4.83,7.95-.37,.56-1.28,1.01-1.97,1.07-4.77,.39-9.55,.99-14.31,.89-6.91-.14-13.62-1.68-20.07-4.22-3.6-1.42-6.89-3.35-9.73-6-1.95-1.81-3.57-3.88-4.43-6.45-1.16-3.51-.71-6.76,1.56-9.73,1.58-2.06,3.53-3.66,5.78-4.95,4.86-2.79,10.17-4.16,15.63-5.08,1.18-.2,2.36-.36,3.65-.56Z"
          />
          <path
            style={styles.one}
            d="M570.32,362.75c.06-4.44,.46-8.79,2.1-12.95,1.04-2.63,2.42-5.04,4.52-6.98,2.75-2.55,5.99-3.22,9.57-2.36,2.78,.67,5.21,2.07,7.25,4.04,3.52,3.4,6.67,7.12,8.95,11.47,.62,1.18,1.47,1.25,2.48,1.47,3.66,.79,7.37,1.43,10.95,2.49,2.42,.72,4.78,1.86,6.95,3.17,1.81,1.1,3.51,2.54,4.91,4.13,3.18,3.63,3.49,7.97,1.14,12.23-1.66,3.01-4.14,5.25-6.95,7.14-4.44,2.99-9.39,4.8-14.59,5.83-4.14,.82-8.35,1.66-12.54,1.78-4.52,.13-9.07-.55-13.6-.89-1.03-.08-1.66-.69-2.3-1.5-4.72-5.95-6.9-12.91-8.04-20.25-.45-2.91-.54-5.87-.79-8.81Z"
          />
        </g>
        <path
          style={styles.two}
          d="M617.2,364.68c.58,.01,5.05,.18,8,4,2.68,3.47,2.11,7.35,2,8-.27-1.46-1.1-4.9-4-8-2.21-2.37-4.64-3.49-6-4Z"
        />
      </g>
    </g>
  );
};

export default Hearts;
