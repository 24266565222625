const Yum = () => {
  const styles = {
    one: {
      fill: "#c1272d",
    },
  };

  return (
    <g transform="translate(475, 445)">
      <path d="M92.33,25.29h-5.3c3.9-2.57,8.19-1.96,10.56,1.4,.61,.87,.87,2.05,1.04,3.12,.05,.35-.7,.82-1.33,1.5-.56-1.11-.88-1.89-1.32-2.6-.45-.72-1.02-1.37-1.77-2.37-.79,1.34-1.39,2.38-2,3.42-4.87,8.18-11.46,14.5-20.47,17.81-3.26,1.2-6.86,1.7-10.35,2.01-13.29,1.2-26.28-.35-38.76-5.22-7.02-2.74-13.47-6.37-18.35-12.37C-1.29,25.16-2.4,15.64,6.89,7.49,10.57,4.27,14.61,1.71,19.32,.43c5.94-1.61,11.55,1.37,13.48,6.88,1.2,3.42,.84,6.57-2.01,9-3.08,2.62-3.07,5.66-2.85,9.5,.26,4.57,2.93,6.37,6.04,8.24,12.46,7.49,25.83,9.32,39.83,5.71,7.87-2.03,14.05-6.66,18.52-14.48ZM26.06,29.59c-.23-1.41-.39-2.56-.6-3.71-.73-4.07-.39-7.73,3.02-10.73,2.33-2.06,2.49-3.98,1.21-6.84-1.71-3.83-6.69-6.01-11.27-4.84-4.75,1.22-8.74,3.92-12.72,6.64-1.28,.88-1.28,2.06-1.12,3.36,.47,3.97,4.07,7.48,8.6,8.58,.24-2.66,.06-5.47,.85-7.98,.87-2.75,2.93-4.87,6.13-5.23-.41,.59-.98,.96-1.53,1.36-3.88,2.78-4.9,6.23-3.15,10.61,1.23,3.08,6.24,7.38,10.57,8.78ZM2.48,16.92c-.86,4.27,.23,8,2.56,11.46,2.56,3.79,6.23,6.28,10.18,8.32,11,5.69,22.86,8,35.13,8.47,2.78,.11,5.58-.06,8.37-.11,.03-.26,.06-.52,.09-.78-11.79-1.28-22.92-4.19-31.81-12.73-2.5,1.14-4.39,.12-6.25-1.43-3.38-2.81-6.01-6.84-11.05-7.1-.22-.01-.45-.22-.64-.39-2.08-1.79-4.14-3.59-6.58-5.71Z" />
      <path
        style={styles.one}
        d="M26.06,29.59c-4.34-1.4-9.34-5.7-10.57-8.78-1.75-4.38-.73-7.84,3.15-10.61,.55-.4,1.13-.77,1.53-1.36-3.21,.36-5.27,2.48-6.13,5.23-.79,2.51-.61,5.33-.85,7.98-4.54-1.1-8.13-4.61-8.6-8.58-.15-1.3-.16-2.48,1.12-3.36,3.98-2.72,7.97-5.42,12.72-6.64,4.58-1.17,9.56,1.01,11.27,4.84,1.28,2.86,1.13,4.78-1.21,6.84-3.4,3-3.75,6.66-3.02,10.73,.21,1.15,.37,2.3,.6,3.71Z"
      />
      <path d="M2.48,16.92c2.44,2.12,4.51,3.92,6.58,5.71,.19,.16,.42,.38,.64,.39,5.04,.27,7.67,4.29,11.05,7.1,1.86,1.55,3.76,2.57,6.25,1.43,8.88,8.55,20.02,11.45,31.81,12.73-.03,.26-.06,.52-.09,.78-2.79,.04-5.58,.21-8.37,.11-12.27-.47-24.13-2.78-35.13-8.47-3.95-2.04-7.63-4.54-10.18-8.32-2.34-3.46-3.43-7.19-2.56-11.46Z" />
    </g>
  );
};

export default Yum;
