const Smirk = () => {
  return (
    <g transform="translate(495, 455)">
      <path d="M68.76,10.23c-.49,1.35-1.41,2.35-2.52,3.16-5.2,3.8-10.7,7-16.85,8.85-2.96,.89-5.92,1.88-8.96,2.41-11.08,1.94-20.67-1.32-28.57-10.04-1.99-2.2-3.79-4.57-4.88-7.51,.56,.67,1.13,1.33,1.66,2.02,2.32,3,4.74,5.91,7.78,8.13,6.91,5.03,14.47,6.97,22.69,5.45,9.19-1.71,17.52-5.58,25.33-10.79,1.24-.83,2.6-1.45,3.91-2.17,.13,.16,.27,.33,.4,.49Z" />
      <path d="M34.05,35.5c2.62-.18,5.23-.35,7.85-.53,3.41-.23,6.69-.97,9.85-2.32,1.99-.85,3.73-1.98,4.91-3.93,.22-.37,.61-.62,.98-.99,1.03,1.01,.68,1.93,.19,2.85-1.17,2.2-3.07,3.45-5.29,4.1-3.92,1.14-7.92,1.85-12.05,1.59-2.14-.13-4.29-.19-6.43-.27,0-.17,0-.34-.01-.5Z" />
      <path d="M.53,13.81c-.2-.59-1.53-4.66,1-8.71C3.96,1.21,9.58,.03,9.57,0c0-.01-1.6,.53-3.51,1.85-.99,.68-2.19,1.53-3.29,3.03-1.05,1.43-1.58,2.86-1.84,3.99" />
    </g>
  );
};

export default Smirk;
