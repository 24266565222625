const Pipe = () => {
  const styles = {
    one: {
      fill: "#ba6d43",
    },
    two: {
      fill: "#8c6444",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      fill: "#995122",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    five: {
      fill: "#f7cd00",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    six: {
      fill: "#ac7d53",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    seven: {
      fill: "#513521",
    },
    eight: {
      fill: "#161616",
    },
  };

  return (
    <g>
      <path
        style={styles.four}
        d="m561.21,467.57c0-.25-8.28-1.72-17.31,1.49-9.75,3.46-15.37,10.59-15.15,10.86.22.28,6.85-7.14,18.48-10.53,7.81-2.28,13.99-1.6,13.98-1.82Z"
      />
      <path
        style={styles.three}
        d="m647.09,497.85c4.79,21.77-.69,41.28-13.43,48.72-2.67,1.56-6.89,3.3-13.04,3.39-1.14-.07-2.77-.23-4.7-.65-5.27-1.15-11.76-4.02-20.74-13.57-10.11-10.76-16.41-22.59-20.09-30.91-1.58-3.74-4.09-8.49-8.1-13.29-7.41-8.88-16.04-13.24-20.98-15.27-2.6-.81-3.91-3.35-3.3-5.27.66-2.08,3.67-3.48,6.36-2.09,3.74,1.61,8.28,3.98,12.99,7.49,7.1,5.29,11.81,11.09,14.74,15.39,4.09,6.17,7.89,11.54,11.22,16.04,6.82,9.23,10.51,13.36,15.78,13.83,1.53.14,2.81-.07,3.65-.26.65-7.85,1.3-15.7,1.96-23.54"
      />
      <path
        style={styles.one}
        d="m610.7,546.3c2.85,1.58,9.44,4.63,16.8,2.77,11.99-3.03,16.56-16.69,17.59-19.77,2.59-7.74,2.35-16.33,2.39-16.33.02,0-.11,1.51-.2,2.28-2.54,22.86-16.11,29.87-16.11,29.87-7.84,4.05-16.28,2.39-20.48,1.17Z"
      />
      <path
        style={styles.eight}
        d="m581.53,517.4l7.81-7.77-17.89-24.64c-3.24-2.87-6.95-5.88-11.17-8.89-3.92-2.79-7.7-5.17-11.23-7.2-1.02,2.45-2.04,4.91-3.05,7.36,3.27,1.27,7.25,3.2,11.35,6.2,2.01,1.47,3.73,2.96,5.2,4.39,1.6,1.21,3.69,2.98,5.82,5.42,2.14,2.44,3.45,4.55,4.43,6.14.83,1.35,1.91,3.33,4.3,8.51,1.97,4.26,3.43,7.89,4.42,10.48Z"
      />
      <ellipse
        style={styles.six}
        cx="628.24"
        cy="497.85"
        rx="18.85"
        ry="6.98"
      />
      <ellipse style={styles.two} cx="628.24" cy="497.85" rx="11" ry="4.07" />
      <path
        style={styles.five}
        d="m581.53,517.4s3.8-7.93,7.81-7.77l5.56,6.83s-4.39,2.17-6.69,10.99l-6.69-10.05Z"
      />
      <path
        style={styles.five}
        d="m546,476.26c-2.8-.81-4.01-3.81-3.06-5.82.9-1.9,3.73-2.9,6.11-1.55-.71.71-1.88,2.08-2.55,4.14-.41,1.27-.51,2.41-.51,3.22Z"
      />
      <path
        style={styles.five}
        d="m608.66,506.67c3.06,1.81,11.82,6.43,23.43,5.2,7.93-.84,13.71-4.04,16.71-6v3.75c-3.26,1.87-8.94,4.55-16.45,5.25-11.66,1.07-20.5-3.31-23.94-5.25l.25-2.96Z"
      />
      <path d="m608.32,522.14c-.43,2.4-.14,4.82.96,7.03-1.71-1.9-2.73-4.5-2.96-7.05,0,0,2,.02,2,.02h0Z" />
      <path
        style={styles.seven}
        d="m618.63,498.45c1.12.81,5.41,3.72,11.31,3.13,4.1-.41,6.96-2.3,8.22-3.25-1.62.9-4.46,2.21-8.22,2.51-5.45.44-9.59-1.47-11.31-2.4Z"
      />
    </g>
  );
};

export default Pipe;
