const Hoodie = ({ color }) => {
  const styles = {
    one: {
      fill: color,
      filter: "brightness(85%)",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#eaeaea",
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: color,
    },
    five: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#fff",
    },
    six: {
      fill: color,
    },
    seven: {
      fill: "none",
    },
  };
  return (
    <g>
      <path
        style={styles.six}
        d="M340,632c96.52-12.46,142.44-22.53,161-30,8.59-3.46,31.09-13.21,57.78-8.04,.51,.1,1.05,.21,1.13,.23,10.13,2.14,20.55,5.99,40.09,17.81,7.9,4.78,16.27,9.84,27,18,10.68,8.12,19.26,15.94,25.79,22.4,6.51,22.65,12.37,48.64,16.21,77.6,2.1,15.83,3.38,30.87,4.05,44.99-15.67,13.54-40.62,31.99-75.05,46.01-17.5,7.13-52.83,19.38-99,20-23.4,.31-88.23-1.87-153-44-26.37-17.15-45.06-35.97-57.22-50.07"
      />
      <polygon
        style={styles.seven}
        points="451.09 576 442.5 577.9 461 589 451.09 576"
      />
      <path
        style={styles.one}
        d="M452,556.5l-3.73,18.83-6.48,1.67c-1.98-1.41-4.86-3.83-7.44-7.57-2.52-3.64-7.2-4.63-7.85-6.96,.78-.9,2.05-2.14,3.92-2.98,1.57-.71,2.79-.81,4.9-.99,0,0,3.52-.35,9.81-.99,1.62-.17,3.99-.46,6.87-.99Z"
      />
      <path
        style={styles.one}
        d="M512.93,559.14c-.64,7.95-1.28,15.9-1.93,23.86,.33,.56,4,4,4,3,0-.3,.63,.04,1,0,2.32-1.16,10.2-5.47,13.54-14.71,1.31-3.61,1.53-6.9,1.46-9.29-1.7-.74-3.71-1.45-6-2-4.79-1.15-9-1.14-12.07-.86Z"
      />
      <path
        style={styles.four}
        d="M406.71,591.44c-.7-.3-.84-1.24-.25-1.72,5.14-4.24,11.24-10.47,16.04-19.22,1.36-2.49,2.47-4.91,3.39-7.21,.08-.19,.2-.35,.38-.46,.54-.35,1.1-.73,1.66-1.14,.2-.14,.4-.29,.59-.43,.51-.38,1.23-.21,1.51,.36,2.25,4.59,6.33,10.35,12.48,15.88,6.78,6.1,13.07,8.25,19,11,7.77,3.61,18.48,9.93,30.15,21.74,.52,.53,.32,1.43-.38,1.68-.52,.18-1.11,.38-1.76,.58-5.17,1.57-12.5,2.58-24,0-10.3-2.31-31.67-9.22-58.79-21.06Z"
      />
      <path
        style={styles.four}
        d="M531,562c.93,.09,2.67,.38,4.5,1.5,1.93,1.18,3.01,2.7,3.5,3.5,.3,1.37,.49,2.53,1.5,4.5,1.02,1.99,2.19,3.5,3.14,4.55,1.65,1.14,3.81,2.89,5.86,5.45,2.47,3.09,3.79,6.12,4.51,8.2,2.08,1.76,4.17,3.52,6.25,5.27-3.1,1.58-6.35,3.09-9.76,4.53-20.99,8.82-41.12,11.58-57.76,11.86l-6.97-6.92c9.22-5.45,16.88-10.21,22.73-13.93,.83-.53,5.43-3.47,11-8,8.14-6.62,10.09-9.9,11-13,.59-2.03,.7-4.05,.7-4.05,.07-1.41-.06-2.6-.2-3.45Z"
      />
      <ellipse
        style={styles.two}
        cx="476.5"
        cy="606"
        rx="6"
        ry="2.5"
        transform="translate(-132.58 133.44) rotate(-14.02)"
      />
      <ellipse
        style={styles.two}
        cx="497.5"
        cy="606"
        rx="2.5"
        ry="6"
        transform="translate(-147.17 1052.26) rotate(-85.31)"
      />
      <g>
        <path
          style={styles.five}
          d="M476.55,606.46l-1.1,.37c-1.14,.39-1.87,1.51-1.77,2.72l3.91,43.79c.01,.12,.01,.24,0,.36-.24,4.48-.05,10.72,1.9,17.8,1.04,3.78,2.36,7.03,3.68,9.73,.34,.69,.37,1.5,.07,2.21-1.57,3.68-3.15,7.37-4.75,11.07-1.97,4.57-3.94,9.12-5.92,13.65-.34,.77-.33,1.69,.15,2.39,.44,.64,1.12,1.01,1.77,.96,.82-.06,1.6-.8,1.83-1.83,3.77-5.41,5.93-10.06,7.17-13.17,1.18-2.98,1.81-5.29,2-6,.79-2.96,1.19-5.58,1.41-7.65,.03-.31,0-.6-.07-.9-.86-3.33-1.65-6.81-2.34-10.45-2.3-12.07-3.06-23.21-3-33-.5-9.9-.99-19.79-1.49-29.69-.09-1.74-1.82-2.92-3.47-2.35Z"
        />
        <path
          style={styles.three}
          d="M477.5,697.5c1.36,1.1,2.73,2.2,4.09,3.29-1.7,3.24-3.4,6.47-5.09,9.71-.59,1.06-1.9,1.5-3,1-1.11-.5-1.65-1.81-1.21-2.95,1.74-3.68,3.47-7.36,5.21-11.05Z"
        />
      </g>
      <g>
        <path
          style={styles.five}
          d="M498.06,604.8l1.14,.22c1.19,.23,2.06,1.24,2.12,2.45l2.17,43.92c0,.12,.02,.24,.04,.35,.86,4.41,1.53,10.61,.58,17.89-.5,3.89-1.37,7.29-2.3,10.14-.24,.73-.16,1.53,.24,2.2,2.06,3.43,4.14,6.86,6.23,10.31,2.58,4.25,5.16,8.49,7.75,12.7,.44,.72,.56,1.63,.18,2.38-.35,.69-.97,1.16-1.62,1.2-.82,.05-1.69-.57-2.07-1.56-4.48-4.84-7.26-9.15-8.92-12.06-1.58-2.78-2.52-4.99-2.81-5.67-1.19-2.82-1.95-5.36-2.45-7.38-.07-.3-.09-.6-.06-.9,.39-3.41,.69-6.97,.87-10.67,.61-12.27-.17-23.41-1.58-33.1-.88-9.87-1.75-19.74-2.63-29.61-.15-1.74,1.4-3.14,3.11-2.81Z"
        />
        <path
          style={styles.three}
          d="M509.68,695.1c-1.2,1.28-2.4,2.55-3.6,3.83,2.13,2.97,4.26,5.94,6.38,8.91,.73,.97,2.09,1.22,3.11,.58,1.03-.65,1.38-2.02,.79-3.09-2.23-3.41-4.46-6.81-6.69-10.22Z"
        />
      </g>
      <path
        style={styles.six}
        d="M288.78,746.93c.11-3.66,1.19-38.28,6.22-59.93,2.33-10.01,5-19,5-19s.7-2.34,5-16h0c34.35-25.95,52.12-37.05,62-42,.21-.11,.83-.41,12-6,8.67-4.33,17.33-8.67,26-13l59.17,22.82-175.39,133.11Z"
      />
    </g>
  );
};

export default Hoodie;
