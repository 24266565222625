const Handlebar = ({ color }) => {
  const styles = {
    one: {
      fill: color,
      stroke: "#000",
      strokeMiterLimit: 10,
    },
  };

  return (
    <g transform="translate(455, 420)">
      <path
        style={styles.one}
        d="M86.03,27.84c-4.64,8.32-11.7,11.75-20.65,11.4-2.49-.1-5.02-.91-7.4-1.77-3.22-1.16-6.55,.38-7.12,3.75-.37,2.2-.47,4.45-.6,6.68-.47,8.06-.86,16.12-1.35,24.18-1.03,16.68-10.43,27.26-25.02,33.96-2.45,1.13-4.93,2.26-7.72,2.49-6.89,.56-13.69-3.34-15.33-11.8-.27-1.4-.29-2.86-.34-4.3-.03-.95,.03-1.94,1.21-2.58,.34,.33,.78,.65,1.09,1.07,2.46,3.35,5.71,5.01,9.8,3.91,4.2-1.14,6.96-3.93,7.38-8.44,.6-6.52,1.23-13.05,1.51-19.59,.5-11.65,.69-23.31,1.15-34.97,.08-2.12,.47-4.35,1.29-6.29C28.23,15.2,35.62,7.74,45.71,2.96c3.87-1.83,8.07-1.87,12.23-1.83,5.47,.04,10.84,.79,15.96,2.83,4.4,1.75,8.23,4.25,10.83,8.34,.16,.25,.41,.45,.71,.77,.67-1.03,1.23-1.99,1.88-2.86,2.42-3.23,5.69-5.33,9.38-6.83,6.68-2.7,13.65-3.24,20.74-2.66,2.9,.24,5.6,1.32,8.12,2.74,7.19,4.06,12.92,9.63,17.02,16.8,1.44,2.51,2.42,5.18,2.73,8.11,1.21,11.36,2.4,22.72,3.79,34.05,.71,5.78,1.73,11.53,2.83,17.25,.67,3.51,3.19,5.53,6.61,6.1,3.28,.55,5.69-1.08,7.4-3.81,.37-.6,.76-1.18,1.31-2.03,.42,.58,.9,.95,.98,1.39,1.13,6.13-.81,13.84-8.06,16.68-2.02,.79-4.06,1.05-6.11,.61-7.42-1.61-14.04-4.8-19.48-10.2-5.43-5.38-8.43-11.97-9.5-19.46-1.14-7.99-2.18-15.99-3.28-23.99-.26-1.9-.5-3.81-.95-5.66-.91-3.7-3.94-5.04-7.25-3.34-8.95,4.62-20.78,1.68-26.52-6.59-.31-.45-.63-.9-1.06-1.51Z"
      />
    </g>
  );
};

export default Handlebar;
