const MessyBuns = ({ color }) => {
  const styles = {
    one: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: color,
    },
  };

  return (
    <g>
      <path
        style={styles.two}
        d="M375.45,264.32s0,0,0,0c-7.7-8.81-4.54-23.31-3.45-28.32,3.99-18.32,32.21-46.23,69-53,31.99-5.89,60.9,5.96,66,9,3.2,1.91,6.7,3.27,10,5,3.16,1.66,5.6,3.01,9,3,2.12,0,3.86-.54,5-1,3.36-.56,29.42-4.43,49,14,11.33,10.66,14.84,23.47,16,29-41.25-15.09-76.13-17.32-98-17-12.08,.18-31.09,.58-55,7-30.83,8.27-53.58,22.19-67.55,32.32Z"
      />
      <path
        style={styles.two}
        d="M493.58,301.09c-26.88,31.3-49.3,53.67-65.58,68.91-11.58,10.85-26.85,24.45-39,48-6.53,12.65-10.03,24.06-12,32-3.52-1.56-7.04-3.12-10.55-4.69,12.25-33.55,28.56-55.63,39.55-68.31,4.55-5.25,11.78-13.59,23-23,17.43-14.62,26.07-16.36,44-32,12.42-10.84,20.66-21.01,20.58-20.91Z"
      />
      <path
        style={styles.two}
        d="M678,392c-4.39-8.56-42.66-80.67-110-88-28.86-3.14-75.39,1.97-105.81,27.92-7.45,6.36-16.18,12.98-31.19,19.08-23.59,9.59-46.27,9.05-62,7-1.58-.85-9.58-4.97-19-2-13.11,4.13-16.45,18.05-16.59,18.67-2.72-13.96-5.44-27.93-8.17-41.89l5.49-11.53c6.99-11.82,15.91-24.9,27.26-38.25,5.89-6.92,11.76-13.13,17.45-18.68,22.68-14.62,69.97-40.69,132.55-40.32,38.14,.23,68.65,9.95,88,18,44.45,17.08,60.2,40.87,66,52,3.42,6.56,6.77,15.23,9,21,3.9,10.08,10.63,28.96,16,55,6.47,31.37,12.89,62.5,8,102-2.07,16.7-7,41.74-20.22,70.67-.45,1,16.76-40.32,14.22-92.67-1.18-24.35-6.42-44.31-11-58Z"
      />
      <path
        style={styles.one}
        d="M325.25,332.78c3.96,4.17,9.8,9.45,17.75,14.22,8,4.8,15.44,7.47,21,9"
      />
      <path
        style={styles.one}
        d="M342,303c3.37,12.67,9.12,20.31,14,25,24.28,23.32,75.07,19.64,125.28-11.15"
      />
      <path
        style={styles.two}
        d="M579,522c-1.37,8.09-.79,14.58,0,19,2.17,12.13,8.58,23.3,8.9,23.17,.02,0,.02-.05,.02-.07-.1-2.83-6.17-34.06,3.09-47.1,.63-.89,1.87-2.44,4-4-2.3,1.92-5.28,4.06-9,6-2.51,1.31-4.88,2.28-7,3Z"
      />
      <path
        style={styles.two}
        d="M595,514c-.44,14.51,.55,47.3,20,82,16.62,29.64,38.8,46.58,50.78,54.48,5.24,3.06,12.04,7.94,18.22,15.52,14.29,17.54,15.32,37.38,15.34,44.58,.32-9.73-.35-25.06-6.34-42.58-5.45-15.95-13.23-27.65-19-35-.86-1.45-2.19-3.57-4-6-7.82-10.52-15.97-15.26-21-19-16.15-12-22.83-30.47-27-42-3.43-9.49-4.88-17.76-5.57-23.33-.64-8.41-.63-19.28,1.57-31.67,1.51-8.52,3.73-15.9,6-22-3.7,4.33-8.32,9.15-14,14-5.3,4.52-10.42,8.13-15,11Z"
      />
      <path
        style={styles.two}
        d="M641,462c-1.71,4.7-3.48,11.17-4,19-.75,11.38,1.49,20.61,3.39,26.35,8.54,18.55,17.08,37.1,25.61,55.65,2.32,4.59,5.79,13,6,24,.29,15.33-5.93,26.53-8.76,31.01,1.52-5.1,3.85-15.57,.76-28.01-1.73-6.98-4.62-12.36-7-16-3.41-3.86-7.43-9.16-11-16-2.89-5.54-4.76-10.7-6-15-2.84-5.87-5.69-12.89-8-21-3.98-13.98-5-26.45-5-36,2.33-4,4.67-8,7-12s4.67-8,7-12Z"
      />
      <path
        style={styles.two}
        d="M353,435c4.31,12.47,9.88,32.29,11,57,1.49,32.7-6.4,39.06-6.07,76.56,.2,22.63,.79,51.32,17.07,76.44,3.6,5.56,19.28,28.39,23.73,26.17,.12-.06,.21-.12,.27-.17-2.36-1.95-27.09-23.09-24-55,1.83-18.86,12.4-31.4,16.59-35.88,4.57-4.16,10.19-10.11,15.41-18.12,4.67-7.16,7.67-13.91,9.63-19.33,1.8-4.97,3.77-12.03,4.37-20.67,.61-8.84-.43-16.24-1.57-21.47-6.31-5.27-13.4-12.03-20.43-20.53-9.88-11.95-16.54-23.59-21-33-2.69,.07-8.07-.14-14-3-5.88-2.83-9.38-6.86-11-9Z"
      />
      <path
        style={styles.one}
        d="M373.3,446.76s11.7,34.11,5.7,62.68-14.7,81.37-10.85,103.47,17.23,46.62,17.23,46.62"
      />
      <path
        style={styles.two}
        d="M379,450c-1.14-.24-2.92-.65-5.1-1.37-5.57-1.83-13.79-5.65-20.9-13.63-10.49-11.78-11.77-25.02-12-29,1.92,1.44,6.05,4.12,12,5,5.4,.8,9.75-.27,12-1,4.67,13.33,9.33,26.67,14,40Z"
      />
      <path
        style={styles.one}
        d="M400.65,481.47c4.21,16.93,6.83,40.34-4.57,61.2-6.4,11.72-13,14.89-19.09,26.33-6.57,12.35-11.48,32.32-2,65"
      />
      <path d="M428.29,238.71c-10.22-.64-19.98-7.41-24.22-16.66,6.13,7.84,14.78,12.91,24.47,14.67,0,0-.25,1.98-.25,1.98h0Z" />
      <path d="M400.21,250.86c-8.78,.48-14.7-5.03-17.86-12.76,1.38,1.32,2.67,2.68,3.99,3.98,3.77,4.18,8.46,6.14,13.97,6.79,0,0-.1,2-.1,2h0Z" />
      <path d="M358.98,283.18c-.92,10.54,3.68,21.14,12.6,26.99,2.87,2.03,6.09,3.64,9.59,5.01-1.89,.12-3.8-.09-5.66-.53-9.63-2.15-17.15-10.71-18.89-20.31-.69-3.84-.63-7.8,.39-11.52,0,0,1.97,.35,1.97,.35h0Z" />
      <path d="M372.1,268.19c3.43,7.01,9.12,12.82,16.29,15.99,2.4,1.07,4.93,1.9,7.58,2.46-8.29,.23-16.53-3.89-21.64-10.41-1.74-2.18-3.12-4.65-4.07-7.27l1.84-.78h0Z" />
      <path d="M511.38,225.4c-3.2-13.31-11.6-25.14-23.49-32.14,0,0,.06-.12,.06-.12,9.52,4.45,17.43,12.31,21.99,21.8,1.52,3.19,2.69,6.56,3.39,10.02l-1.95,.44h0Z" />
      <path d="M522.71,198.52c7.87,3.15,14.08,10.92,14.64,19.47-.82-1.93-1.7-3.76-2.74-5.49-3.01-5.19-7.47-9.35-12.75-12.17,0,0,.84-1.81,.84-1.81h0Z" />
      <path d="M539.11,197.38c16.78,7.12,31.58,18.74,42.42,33.35-11.28-14.09-26.35-25.07-43.17-31.49,0,0,.75-1.85,.75-1.85h0Z" />
      <path d="M463.13,226.81c31.44,3.41,61.17,19.92,80.37,45.06,0,0-.16,.13-.16,.13-14.81-18.12-35.18-31.6-57.68-38.01-7.45-2.15-15.11-3.55-22.82-4.19l.29-2.99h0Z" />
      <path d="M509.57,222.61c20.96,9.2,38.78,26.17,46.97,47.68-6.88-15.6-18.57-28.69-33.11-37.41-4.79-2.92-9.82-5.47-15-7.49,0,0,1.14-2.78,1.14-2.78h0Z" />
      <path d="M590.29,244.71c-10.04,1.64-20.1,6.77-25.39,15.72-.31,.61-1.08,2.17-1.4,2.79,0,0-1.91-.89-1.91-.89l1.12-1.94c5.7-9.54,16.84-14.58,27.58-15.68h0Z" />
      <path d="M559.22,285.52c.31-4.16,2.98-7.22,5.05-10.58,6.52-9.34,15.02-19.41,26.88-21.28-11.03,3.63-18.04,13.74-23.92,23.16-2.1,3.22-3.24,6.87-6.19,9.53,0,0-1.82-.84-1.82-.84h0Z" />
      <path d="M575.68,272c9.31-5.67,20.31-8.66,31.17-7.62,4.89,.37,9.64,1.95,14.06,3.95-10.94-4.71-22.95-4.31-33.94,.01-3.56,1.4-7.07,3.17-10.15,5.29l-1.15-1.64h0Z" />
      <path
        style={styles.two}
        d="M548,302c-18.17,14.55-58.67,51.1-79,113-29.15,88.78,4.61,164.49,12.86,181.79-11.04-15.23-53.26-77.16-44.86-163.79,9.41-97.08,75.38-152.75,88-163,1.46,.45,3.71,1.31,6,3,5.13,3.77,6.43,8.55,11,18,1.24,2.56,3.21,6.41,6,11Z"
      />
      <path
        style={styles.two}
        d="M558,303c12.91,1.25,36.23,5.44,58,22,30.8,23.44,39.03,56.83,44,77,18.85,76.53-17.07,144.69-29.79,166.56,12.9-17.64,28.7-43.94,38.79-78.56,9.37-32.14,10.06-58.53,10-70-.11-21.09-.24-46.5-12-73-19.37-43.64-58.89-64.57-75.78-72.22-5.97,5.93-12.69,12.07-20.22,18.22-4.42,3.61-8.76,6.94-13,10Z"
      />
      <path
        style={styles.two}
        d="M586,219c-2.33-2.33-4.67-4.67-7-7,20.71-12.14,42.24-4.64,47,7,3.56,8.71-1.5,21.63-12.33,30.84-5.89-2.61-11.78-5.23-17.67-7.84,9.21-3.28,14.84-10.17,14-15-.97-5.58-11.09-11.37-24-8Z"
      />
    </g>
  );
};

export default MessyBuns;
