import { Grid, Skeleton } from "@mui/material";
import { cardVeryLight } from "../../utils/themeContstants";

const NotificationGlimmer = ({ numItems }) => {
  const array = Array.apply(null, { length: numItems }).map(
    Number.call,
    Number
  );

  const styles = {
    container: {
      width: "100%",
      padding: 1,
      paddingLeft: 0,
      paddingRight: 2,
      borderRadius: 1,
    },
    width: {
      width: "100%",
    },
  };

  return (
    <Grid item>
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 1 }}
      >
        {array.map((_, i) => (
          <Grid item sx={styles.container} key={i}>
            <Grid
              container
              justifyContent="start"
              alignItems="start"
              wrap="nowrap"
              gap={{ xs: 1.5 }}
            >
              <Grid item>
                <Skeleton
                  variant="circular"
                  animation="pulse"
                  sx={{ bgcolor: cardVeryLight, width: 60, height: 60 }}
                />
              </Grid>

              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 0.5 }}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Skeleton
                          variant="text"
                          animation="pulse"
                          sx={{
                            bgcolor: cardVeryLight,
                            height: 30,
                            width: 150,
                          }}
                        />
                      </Grid>

                      <Grid item>
                        <Skeleton
                          variant="text"
                          animation="pulse"
                          sx={{
                            bgcolor: cardVeryLight,
                            height: 20,
                            width: 200,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Skeleton
                      variant="text"
                      animation="pulse"
                      sx={{
                        bgcolor: cardVeryLight,
                        height: 15,
                        width: 125,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default NotificationGlimmer;
