const Unamused = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
  };

  return (
    <g>
      <path d="M442.82,355.15c-1.3,.77-2.55,1.51-3.8,2.24-.15-.12-.29-.24-.44-.36,.68-1,1.37-1.99,2.15-3.13-1.11-.88-2.3-1.84-3.49-2.79,2.25-.19,3.57,1.33,5.48,1.66,2.09-1.02,4.14-2.15,6.31-3.08,6.37-2.71,13.08-3.57,20.14-3,8.66,.69,16.82,2.59,24.1,6.91,1.32,.78,2.41,1.86,3.61,2.8-.1,.14-.2,.28-.3,.42-.33-.07-.73-.07-.99-.24-6.61-4.17-14.06-6.42-22.04-7.39-5.31-.64-10.63-.99-15.97-.03-4.26,.76-8.18,2.19-12.18,4.48,4.78,.94,9.09,.77,13.41-.14,2.18-.46,4.35-1.11,6.56-1.24,8.04-.47,15.83,.51,23.19,3.58,1.79,.75,3.56,1.55,5.35,2.31,2.79,1.19,4.16,3.29,4.97,5.99-4.39,.37-7.79-1.73-11.51-3.17-1.45,2.43-3.23,4.44-5.6,6.02-4.15,2.77-8.78,4.19-13.99,3.97-5.24-.22-9.75-3.53-10.98-8.02-.24-.88-.42-1.77-.68-2.93-2.65,1.18-5.22,2.23-7.68,3.46-3.25,1.63-6.53,3.07-10.29,3.57-4.88,.65-9.46-.29-13.87-1.96-2.66-1-5.14-2.36-7.81-3.61,.85-1.4,2-1.02,3.03-.85,2.2,.37,4.38,.88,6.59,1.16,2.19,.28,4.41,.36,7.15,.56-2.26-1.38-4.07-2.46-5.86-3.57-.93-.58-1.81-1.22-1.48-2.55,1.28-.66,2.53-.42,3.78,.24,1.11,.59,2.19,1.23,3.35,1.74,3.18,1.41,6.51,2,10.03,1.12,2.39-.6,4.77-1.23,7.67-1.99-2.95-.83-5.33-1.5-7.91-2.22Zm15.48,4.31c.45,4.1,3.27,6.75,7.75,7.6,7.05,1.34,14.73-2.72,16.97-7.55-5.82-3.35-18.02-3.44-24.72-.05Z" />
      <path d="M643.7,355.93c-.65,3.19-4.01,3.62-6.5,5.56,1.47-.12,2.48-.22,3.49-.29,1.12-.08,2.25-.07,3.35-.24,1.78-.28,3.54-.67,5.31-1.01q1.25-.24,2.65,.77c-3.48,1.52-6.59,3.35-10.27,4.29-5.88,1.5-11.41,.98-16.69-1.77-1.63-.85-3.33-1.59-5.22-2.48-.26,.82-.47,1.45-.65,2.09-1.31,4.71-5.78,7.94-11.26,7.88-8.37-.1-14.8-3.11-18.5-9.99-.07-.13-.22-.23-.46-.47-3.21,1.26-6.27,3.09-10.17,2.88,.48-2.24,1.58-3.97,3.74-5.07,7.56-3.89,15.69-5.94,24.46-5.63,2.21,.08,4.44,.54,6.59,1.06,3.39,.82,6.81,.9,10.27,.67,.58-.04,1.15-.21,2.1-.39-.72-.48-1.13-.81-1.6-1.05-6.24-3.14-12.96-3.89-20.02-3.26-8.21,.73-15.92,2.65-22.74,6.91-.18,.11-.43,.12-.95,.26,.55-1.42,1.65-2.1,2.73-2.77,4.45-2.77,9.44-4.47,14.72-5.41,6.73-1.2,13.54-1.79,20.21,.22,2.92,.88,5.74,2.1,8.43,3.44,1.49,.75,2.62,.89,3.99,.03,.69-.43,1.49-.73,2.61-1.26-.56,1.62-2.08,2.08-2.92,3.13,.59,.83,1.19,1.69,1.83,2.6-.4,.11-.63,.23-.69,.19-2.07-1.72-4.32-1.78-6.76-.65-.67,.31-1.46,.41-2.19,.62-.05,.02-.07,.13-.1,.19,5.55,2.94,10.81,2.97,16.22,.09q2.53-1.34,4.96-1.15Zm-50.42,2.61c1.47,5.46,11.19,11.15,19.61,7.56,2.86-1.22,4.27-3.36,4.84-6.11-8-3.68-15.99-4.38-24.45-1.45Z" />
      <path d="M458.31,359.45c6.7-3.39,18.9-3.3,24.72,.05-2.25,4.82-9.93,8.89-16.97,7.55-4.48-.85-7.29-3.51-7.75-7.6Z" />
      <path d="M593.29,358.54c8.45-2.93,16.45-2.22,24.45,1.45-.57,2.75-1.98,4.89-4.84,6.11-8.42,3.59-18.14-2.11-19.61-7.56Z" />
      <circle style={styles.one} cx="475.5" cy="361.5" r="2.5" />
      <circle style={styles.one} cx="609.5" cy="361.5" r="2.5" />
    </g>
  );
};

export default Unamused;
