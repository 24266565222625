const OnePiece = () => {
  const styles = {
    one: {
      fill: "#e8e4d4",
    },
    two: {
      fill: "#dad4bb",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    four: {
      fill: "#c4bc97",
    },
    five: {
      fill: "#9e1608",
    },
    six: {
      fill: "#c0240f",
    },
    seven: {
      fill: "#c6be9e",
    },
  };

  return (
    <g>
      <path
        style={styles.two}
        d="m387.23,305.01c30.81,11.17,91.17,28.12,165.36,16.25,42.64-6.82,76.52-21.03,99.95-33.32,1.02-5.84,14.03-88.83-45.91-142.61-9.23-8.28-47.11-40.03-102.38-36.57-8.57.54-48.9,3.88-82,34-41.14,37.43-54.56,101.13-35.01,162.24Z"
      />
      <path
        style={styles.three}
        d="m378.8,261.16c31.83,8.65,80.42,17.92,139.67,14.2,58.36-3.67,104.81-18.55,135.14-30.9.49,5.25.8,10.93.81,16.99.01,9.81-.76,18.71-1.87,26.51-24.83,13.03-70.51,32.92-131.17,36.76-60.31,3.82-107.88-9.97-134.14-19.69-2.43-7.55-4.7-16.27-6.34-26.05-1.06-6.33-1.72-12.3-2.1-17.81Z"
      />
      <path
        style={styles.two}
        d="m378.4,248.64c-15.44,2.17-38.18,7.19-63.08,19.81-26.52,13.43-44.77,30.06-55.76,41.75-.39.97-.89,2.56-.91,4.57-.03,2.17.51,3.88.91,4.88,5.23,4.74,11.1,9.48,17.67,14.02,31.81,21.98,64.93,28.88,87.76,31.08,3.65.39,8.43,1.27,13.71,3.35,3.81,1.5,6.97,3.26,9.45,4.88,37.11,6.49,88.13,11.19,147.79,3.96,39.83-4.83,74.01-13.82,101.47-23.16,4.86-2.89,10.76-5.91,17.67-8.53,10.69-4.06,20.4-5.88,28.03-6.7,9.98-.87,23.52-3.43,37.78-10.67,4.58-2.32,8.63-4.82,12.19-7.31,7.03-.23,20.03-1.7,32.91-10.06,4.87-3.16,8.68-6.62,11.58-9.75.45-.68,1.48-2.42,1.52-4.88.04-2.63-1.08-4.51-1.52-5.18-4.76-3.64-9.84-7.22-15.24-10.67-38.16-24.37-77.69-32.94-108.73-35.57.49,5.25.8,10.93.81,16.99.01,9.81-.76,18.71-1.87,26.51-24.78,12.99-68.45,31.84-126.04,36.39-62.73,4.96-112.35-9.38-139.27-19.33-2.4-7.44-4.69-16.18-6.34-26.05-1.9-11.36-2.51-21.62-2.5-30.33Z"
      />
      <path
        style={styles.four}
        d="m577.37,370.92c19.06-14.15,27.57-18.31,31.69-19.14.95-.19,5.65-1,11.12-3.81,1.62-.83,2.88-1.62,3.66-2.13,1.21-5.2-.05-8.52-.74-9.94-3.55-7.33-14.81-11.16-27.45-7.82-4.4,1.51-9.28,2.83-14.63,3.74-24.1,4.1-44.58-2.29-56.47-7.31,42.8-17.74,85.59-35.48,128.39-53.22,65.57,7.5,98.62,16.57,99.13,27.22.15,3-2.29,6.13-7.31,9.38,7.5-9.42,17.75-14.02,26-11.41,1.78.56,4.27,1.71,6.81,4.27-4.49,4.35-12.32,10.81-23.71,15.22-8.14,3.15-15.45,4.23-20.78,4.59-7.49,5.05-14.44,8.41-19.93,10.65-8.46,3.45-15.41,5.04-18.29,5.65-7.43,1.56-9.49,1.14-17.15,2.4-4.92.81-12.13,2.3-20.83,5.32-2.38.97-6.01,2.51-10.37,4.58-5.02,2.39-8.6,4.34-9.13,4.62-5.09,2.73-20.24,7.67-60.03,17.15Z"
      />
      <path
        style={styles.six}
        d="m378.8,261.16c32.79,8.86,83.18,18.35,144.54,13.79,55.63-4.13,100.25-18.32,130.27-30.5.58,6.23.92,13.19.77,20.75-.16,8.38-.88,16.01-1.84,22.74-24.87,13.08-70.8,33.13-131.84,36.87-59.95,3.67-107.26-10.07-133.47-19.81-2.51-7.82-4.85-16.86-6.49-27-.97-5.96-1.58-11.6-1.94-16.86Z"
      />
      <path
        style={styles.five}
        d="m469.68,275.58c1.03,6.69,1.8,14.47,1.86,23.14.07,9.63-.74,18.21-1.86,25.46,24.29,2.22,58.94,2.68,99.17-5.96,35.33-7.58,63.58-19.74,83.7-30.27,1-5.96,1.78-12.78,2.03-20.31.29-8.68-.18-16.48-.96-23.18-20.58,8.35-45.51,16.63-74.35,22.63-42.75,8.89-80.44,9.94-109.58,8.49Z"
      />
      <path
        style={styles.seven}
        d="m614.96,257.63c3.17-10.75,10.27-40.1-1.42-74.1-15.08-43.84-50.54-63.79-58.91-68.23,13.15,4.39,39.34,15.17,62.16,40.6,31.66,35.26,35.97,75.45,36.82,88.55-12.88,4.39-25.77,8.79-38.65,13.18Z"
      />
      <path
        style={styles.one}
        d="m396.68,265.1c-2.34-17.45-4-48.56,8.53-83.64,6.68-18.7,15.54-33.36,23.24-43.96-9.01,7.55-19.84,18.48-29.18,33.6-22.79,36.87-21.81,74.46-20.47,90.06,5.96,1.31,11.92,2.63,17.88,3.94Z"
      />
      <path
        style={styles.one}
        d="m275.4,321.93c9.16-15.36,19.22-24.84,26.92-30.73,32.14-24.57,78.58-29.04,78.44-29.61,0,0-.01,0-.02-.01-.74-.27-1.5-3.35-2.03-13.09-14.17,2.03-32.27,6.13-52.01,14.68-32.34,14-54.34,33.57-67.14,47.03,3.59,1.15,8.96,3.51,13.41,8.53.95,1.07,1.75,2.15,2.44,3.2Z"
      />
      <path
        style={styles.three}
        d="m387.23,305.01c30.81,11.17,91.17,28.12,165.36,16.25,42.64-6.82,76.52-21.03,99.95-33.32,1.02-5.84,14.03-88.83-45.91-142.61-9.23-8.28-47.11-40.03-102.38-36.57-8.57.54-48.9,3.88-82,34-41.14,37.43-54.56,101.13-35.01,162.24Z"
      />
      <path
        style={styles.three}
        d="m378.8,261.16c31.83,8.65,80.42,17.92,139.67,14.2,58.36-3.67,104.81-18.55,135.14-30.9.49,5.25.8,10.93.81,16.99.01,9.81-.76,18.71-1.87,26.51-24.83,13.03-70.51,32.92-131.17,36.76-60.31,3.82-107.88-9.97-134.14-19.69-2.43-7.55-4.7-16.27-6.34-26.05-1.06-6.33-1.72-12.3-2.1-17.81Z"
      />
      <path
        style={styles.three}
        d="m378.4,248.64c-15.44,2.17-38.18,7.19-63.08,19.81-26.52,13.43-44.77,30.06-55.76,41.75-.39.97-.89,2.56-.91,4.57-.03,2.17.51,3.88.91,4.88,5.23,4.74,11.1,9.48,17.67,14.02,31.81,21.98,64.93,28.88,87.76,31.08,3.65.39,8.43,1.27,13.71,3.35,3.81,1.5,6.97,3.26,9.45,4.88,37.11,6.49,88.13,11.19,147.79,3.96,39.83-4.83,74.01-13.82,101.47-23.16,4.86-2.89,10.76-5.91,17.67-8.53,10.69-4.06,20.4-5.88,28.03-6.7,9.98-.87,23.52-3.43,37.78-10.67,4.58-2.32,8.63-4.82,12.19-7.31,7.03-.23,20.03-1.7,32.91-10.06,4.87-3.16,8.68-6.62,11.58-9.75.45-.68,1.48-2.42,1.52-4.88.04-2.63-1.08-4.51-1.52-5.18-4.76-3.64-9.84-7.22-15.24-10.67-38.16-24.37-77.69-32.94-108.73-35.57.49,5.25.8,10.93.81,16.99.01,9.81-.76,18.71-1.87,26.51-24.78,12.99-68.45,31.84-126.04,36.39-62.73,4.96-112.35-9.38-139.27-19.33-2.4-7.44-4.69-16.18-6.34-26.05-1.9-11.36-2.51-21.62-2.5-30.33Z"
      />
      <path style={styles.three} d="m396.68,257.63v-32.6" />
      <path style={styles.three} d="m404.45,260.07v-21.79" />
      <path style={styles.three} d="m409.17,259.15v-17.83" />
      <line style={styles.three} x1="414.5" y1="246.81" x2="414.5" y2="262.2" />
      <line
        style={styles.three}
        x1="418.31"
        y1="250.93"
        x2="418.31"
        y2="266.32"
      />
      <line
        style={styles.three}
        x1="424.56"
        y1="251.54"
        x2="424.56"
        y2="266.93"
      />
      <line
        style={styles.three}
        x1="432.02"
        y1="251.99"
        x2="432.02"
        y2="267.38"
      />
      <line
        style={styles.three}
        x1="437.36"
        y1="253.06"
        x2="437.36"
        y2="266.77"
      />
      <line
        style={styles.three}
        x1="445.74"
        y1="253.06"
        x2="445.74"
        y2="266.32"
      />
      <path style={styles.three} d="m275.4,321.93s30.47,21.18,64.6,29.71" />
      <line
        style={styles.three}
        x1="275.4"
        y1="307.3"
        x2="281.34"
        y2="299.68"
      />
      <line
        style={styles.three}
        x1="302.9"
        y1="285.53"
        x2="313.49"
        y2="278.35"
      />
      <line
        style={styles.three}
        x1="307.54"
        y1="287.1"
        x2="318.13"
        y2="279.93"
      />
      <line
        style={styles.three}
        x1="312.6"
        y1="289.84"
        x2="323.2"
        y2="282.66"
      />
      <line
        style={styles.three}
        x1="318.38"
        y1="292.94"
        x2="325.84"
        y2="287.89"
      />
      <line
        style={styles.three}
        x1="326.51"
        y1="292.79"
        x2="333.97"
        y2="287.73"
      />
      <line
        style={styles.three}
        x1="329.79"
        y1="270.61"
        x2="338.44"
        y2="268.06"
      />
      <line
        style={styles.three}
        x1="284.01"
        y1="308.27"
        x2="289.95"
        y2="300.66"
      />
      <line
        style={styles.three}
        x1="291.17"
        y1="308.09"
        x2="297.11"
        y2="300.47"
      />
      <line
        style={styles.three}
        x1="300.23"
        y1="307.99"
        x2="306.17"
        y2="300.37"
      />
      <line
        style={styles.three}
        x1="306.32"
        y1="307.99"
        x2="312.27"
        y2="300.37"
      />
      <line
        style={styles.three}
        x1="312.19"
        y1="307.9"
        x2="318.13"
        y2="300.28"
      />
      <line
        style={styles.three}
        x1="316.91"
        y1="308.09"
        x2="322.86"
        y2="300.47"
      />
      <line
        style={styles.three}
        x1="288.8"
        y1="326.11"
        x2="304.2"
        y2="334.72"
      />
      <line
        style={styles.three}
        x1="297.87"
        y1="326.11"
        x2="309.09"
        y2="332.39"
      />
      <line
        style={styles.three}
        x1="304.2"
        y1="325.31"
        x2="314.45"
        y2="329.45"
      />
      <line
        style={styles.three}
        x1="317.28"
        y1="338.93"
        x2="327.53"
        y2="343.07"
      />
      <line
        style={styles.three}
        x1="320.14"
        y1="335.63"
        x2="330.39"
        y2="339.77"
      />
      <line
        style={styles.three}
        x1="327.79"
        y1="334.41"
        x2="338.04"
        y2="338.55"
      />
      <path
        style={styles.three}
        d="m397.9,346.96c2.84,1.04,6.08,2.03,9.69,2.83,5.73,1.28,10.91,1.75,15.24,1.84"
      />
      <path
        style={styles.three}
        d="m405.62,342.14c2.16.57,4.62,1.11,7.37,1.53,4.36.66,8.33.84,11.65.81"
      />
      <path
        style={styles.three}
        d="m418.79,338.04c1.32.24,2.82.46,4.5.61,2.67.25,5.1.27,7.14.19"
      />
      <path
        style={styles.three}
        d="m389.46,326.94c1.02.87,2.21,1.81,3.59,2.78,2.19,1.55,4.29,2.78,6.09,3.74"
      />
      <path
        style={styles.three}
        d="m394.43,324.2c1.02.87,2.21,1.81,3.59,2.78,2.19,1.55,4.29,2.78,6.09,3.74"
      />
      <path
        style={styles.three}
        d="m400.12,319.73c1.02.87,2.21,1.81,3.59,2.78,2.19,1.55,4.29,2.78,6.09,3.74"
      />
      <path
        style={styles.three}
        d="m422.74,328.95c1.27.42,2.73.85,4.37,1.24,2.61.62,5.01.98,7.05,1.2"
      />
      <path
        style={styles.three}
        d="m424,326.87c1.27.42,2.73.85,4.37,1.24,2.61.62,5.01.98,7.05,1.2"
      />
      <path
        style={styles.three}
        d="m423.83,324.18c1.27.42,2.73.85,4.37,1.24,2.61.62,5.01.98,7.05,1.2"
      />
      <path
        style={styles.three}
        d="m443.41,328.64c1.27.42,2.73.85,4.37,1.24,2.61.62,5.01.98,7.05,1.2"
      />
      <path
        style={styles.three}
        d="m443.02,333.82c1.27.42,2.73.85,4.37,1.24,2.61.62,5.01.98,7.05,1.2"
      />
      <line
        style={styles.three}
        x1="308.84"
        y1="321.32"
        x2="321.06"
        y2="325.87"
      />
      <line
        style={styles.three}
        x1="404.97"
        y1="178.66"
        x2="409.17"
        y2="168.91"
      />
      <line
        style={styles.three}
        x1="415.12"
        y1="184.75"
        x2="418.31"
        y2="173.78"
      />
      <line style={styles.three} x1="424" y1="183.13" x2="424.63" y2="173.78" />
      <line
        style={styles.three}
        x1="559.09"
        y1="128.69"
        x2="571.69"
        y2="142.09"
      />
      <line
        style={styles.three}
        x1="554.62"
        y1="135.39"
        x2="559.09"
        y2="142.09"
      />
      <line
        style={styles.three}
        x1="480.27"
        y1="123.81"
        x2="468.9"
        y2="138.74"
      />
      <line
        style={styles.three}
        x1="604.19"
        y1="220.1"
        x2="604.19"
        y2="211.57"
      />
      <line
        style={styles.three}
        x1="608.66"
        y1="216.91"
        x2="608.66"
        y2="207.1"
      />
      <line
        style={styles.three}
        x1="615.57"
        y1="212.01"
        x2="615.57"
        y2="200.6"
      />
      <line
        style={styles.three}
        x1="621.25"
        y1="212.01"
        x2="621.25"
        y2="196.94"
      />
      <line
        style={styles.three}
        x1="627.35"
        y1="209.54"
        x2="627.35"
        y2="188.41"
      />
      <path style={styles.three} d="m592.81,253.06s4.06-6.75,5.69-11.73" />
      <path style={styles.three} d="m601.35,251.99s4.47-4.88,5.28-13.71" />
      <line
        style={styles.three}
        x1="611.91"
        y1="249.18"
        x2="611.91"
        y2="230.66"
      />
      <line
        style={styles.three}
        x1="615.57"
        y1="242.85"
        x2="615.57"
        y2="230.66"
      />
      <line
        style={styles.three}
        x1="625.32"
        y1="238.28"
        x2="625.32"
        y2="225.03"
      />
      <line
        style={styles.three}
        x1="631.01"
        y1="236.76"
        x2="631.01"
        y2="220.1"
      />
      <line
        style={styles.three}
        x1="418.79"
        y1="225.03"
        x2="418.79"
        y2="207.1"
      />
      <line
        style={styles.three}
        x1="424.63"
        y1="230.66"
        x2="424.63"
        y2="216.06"
      />
      <line
        style={styles.three}
        x1="430.44"
        y1="223.36"
        x2="430.44"
        y2="233.91"
      />
      <line
        style={styles.three}
        x1="437.36"
        y1="225.03"
        x2="437.36"
        y2="233.91"
      />
      <line
        style={styles.three}
        x1="443.02"
        y1="223.36"
        x2="443.02"
        y2="233.91"
      />
      <line
        style={styles.three}
        x1="412.02"
        y1="216.91"
        x2="412.02"
        y2="204.47"
      />
      <line
        style={styles.three}
        x1="404.12"
        y1="209.54"
        x2="404.12"
        y2="193.69"
      />
      <path style={styles.three} d="m586.72,334.72s9.75.66,11.78-2.33" />
      <path style={styles.three} d="m595.66,338.89s4.88-1.08,10.97-4.48" />
      <path style={styles.three} d="m595.66,343.07s11.38-1.34,19.91-6.29" />
      <path style={styles.three} d="m608.66,344.48s3.66,1.11,12.59-4.55" />
      <line
        style={styles.three}
        x1="627.35"
        y1="331.08"
        x2="635.07"
        y2="327.38"
      />
      <line
        style={styles.three}
        x1="631.01"
        y1="334.41"
        x2="644.01"
        y2="329.86"
      />
      <line
        style={styles.three}
        x1="635.07"
        y1="338.89"
        x2="654.41"
        y2="332.14"
      />
      <path style={styles.three} d="m635.07,344.48s44.29-10.9,47.94-14.62" />
      <path style={styles.three} d="m575.75,359.05s13-.26,36.16-7.41" />
      <path style={styles.three} d="m678.74,265.6s4.06,1.63,8.53,5.69" />
      <path style={styles.three} d="m684.64,261.74s5.48,1.42,11.17,6.7" />
      <path style={styles.three} d="m694.18,259.1s9.34,1.42,11.17,5.99" />
      <line
        style={styles.three}
        x1="661.27"
        y1="251.99"
        x2="676.1"
        y2="255.45"
      />
      <line
        style={styles.three}
        x1="661.27"
        y1="257.07"
        x2="668.69"
        y2="259.1"
      />
      <line
        style={styles.three}
        x1="659.04"
        y1="261.74"
        x2="663.71"
        y2="262.1"
      />
      <path style={styles.three} d="m624.1,307.66s9.75-3.86,12.39-7.11" />
      <path style={styles.three} d="m651.93,298.51s-12.59,8.74-16.86,9.95" />
      <path style={styles.three} d="m703.53,303.49s11.38-2.34,13-4.98" />
      <path style={styles.three} d="m714.09,307.66s12.19-6.7,14.02-9.14" />
      <line
        style={styles.three}
        x1="726.48"
        y1="307.66"
        x2="734.2"
        y2="303.08"
      />
      <path
        style={styles.three}
        d="m731.56,310.3c1.02-.41,10.16-6.2,10.16-6.2"
      />
      <line
        style={styles.three}
        x1="754.31"
        y1="305.37"
        x2="766.71"
        y2="297.3"
      />
      <line
        style={styles.three}
        x1="678.74"
        y1="312.51"
        x2="687.28"
        y2="309.69"
      />
      <line
        style={styles.three}
        x1="686.06"
        y1="314.77"
        x2="698.45"
        y2="310.3"
      />
      <path
        style={styles.three}
        d="m692.25,318.22c2.74-.41,12.09-3.05,15.74-5.69"
      />
      <path
        style={styles.three}
        d="m698.45,319.64c2.44-.61,11.58-1.22,15.64-4.88"
      />
      <line
        style={styles.three}
        x1="757.16"
        y1="314.77"
        x2="757.16"
        y2="308.47"
      />
      <line
        style={styles.three}
        x1="765.99"
        y1="310.5"
        x2="765.99"
        y2="304.1"
      />
      <line
        style={styles.three}
        x1="772.8"
        y1="305.37"
        x2="772.8"
        y2="298.51"
      />
      <line
        style={styles.three}
        x1="365.7"
        y1="359.05"
        x2="386.83"
        y2="362.5"
      />
      <path style={styles.three} d="m380.73,357.22s63.18,16.05,121.68,13.41" />
      <line
        style={styles.three}
        x1="351.37"
        y1="362.98"
        x2="351.37"
        y2="358.64"
      />
      <line
        style={styles.three}
        x1="354.32"
        y1="363.44"
        x2="354.32"
        y2="356.11"
      />
      <line
        style={styles.three}
        x1="397.39"
        y1="374.79"
        x2="397.39"
        y2="369.41"
      />
      <line
        style={styles.three}
        x1="419.38"
        y1="377.13"
        x2="419.38"
        y2="372.1"
      />
      <path style={styles.three} d="m269.63,328.09s0-8.3,0-7.69" />
    </g>
  );
};

export default OnePiece;
