import { Divider, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { currencyFormatter, getReadableDateString } from "../../utils/helpers";
import {
  cardLight,
  cardVeryLight,
  doubtedBlue,
  normal,
  secondaryText,
  small,
  text,
} from "../../utils/themeContstants";
import CustomModal from "../custom/CustomModal";
import RivoxCoin from "../custom/RivoxCoin";
import paypalMethod from "../../assets/paypal-method.svg";
import { PAYPAL_URL } from "../../utils/authURL";
import useMiddleware from "../../api/useMiddleware";
import PayPalCheckoutButton from "./PayPalCheckoutButton";
import { createPayPalOrder, kinguinCheckout, poofCheckout, sellixCheckout, shopifyCheckout } from "../../api/user";
import CashAppCheckout from "./CashAppCheckout";
import ConfirmCashAppModal from "./ConfirmCashAppModal";
import { useNavigate } from "react-router-dom";
import ShopifyCheckoutButton from "./ShopifyCheckoutButton";
import CryptoCheckout from "./CryptoCheckout";

const PayPalDepositModal = ({
  open,
  onClose,
  amount,
  closeWallet,
  description = null,
}) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [cashAppLoading, setCashAppLoading] = useState(false);
  const [shopifyLoading, setShopifyLoading] = useState(false);
  const [kinguinPaypalLoading, setKinguinPaypalLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [termsHovered, setTermsHovered] = useState(false);

  const handleClose = (e) => {
    setLoading(false);
    setCashAppLoading(false);
    setConfirmOpen(false);
    setTermsHovered(false);
    closeWallet(e);
    onClose();
  };

  // const renderPaypalButtons = (paypal) => {
  //   window.paypal
  //     .Buttons({
  //       style: {
  //         color: "blue",
  //         label: "pay",
  //       },
  //       createOrder: (_, actions, __) => {
  //         return actions.order.create({
  //           intent: "CAPTURE",
  //           purchase_units: [
  //             {
  //               description: "PlayAgain Funds",
  //               amount: {
  //                 currency_code: "USD",
  //                 value: amount,
  //               },
  //               custom_id: store?.user?._id,
  //               invoice_id: null,
  //             },
  //           ],
  //         });
  //       },
  //       onApprove: async (data, actions) => {
  //         await actions.order.capture();
  //         updateStore({
  //           type: SET_SUCCESSES,
  //           payload: `${numFormatter().format(
  //             amount
  //           )} has been added to your funds. It may take a second to see the changes.`,
  //         });
  //         onClose();
  //       },
  //       onError: () => {
  //         updateStore({
  //           type: SET_ERRORS,
  //           payload: "Transaction not completed. Please try again later.",
  //         });
  //         onClose();
  //       },
  //     })
  //     .render(paypal.current);
  // };

  const handlePayPal = () => {
    setLoading(true);
    createPayPalOrder(middleware, parseFloat(amount)).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        window.open(res?.link?.href, "_self");
      }
    });
  };

  const handlePoof = () => {
    setCashAppLoading(true);
    poofCheckout(middleware, parseFloat(amount)).then((res) => {
      setCashAppLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        window.open(res?.url, "_self");
      }
    });
  };

  const handleSellix = () => {
    setCashAppLoading(true);
    sellixCheckout(middleware, parseFloat(amount)).then((res) => {
      console.log("amount", amount)
      setCashAppLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        window.open(res?.checkout_url, "_self");
      }
    });
  };

  const handleKinguin = () => {
    setShopifyLoading(true);
    kinguinCheckout(middleware, parseFloat(amount)).then((res) => {
      setShopifyLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        window.open(res?.checkout_url, "_self");
      }
    });
  };

  const handleKinguinPaypal = () => {
    setKinguinPaypalLoading(true);
    kinguinCheckout(middleware, parseFloat(amount)).then((res) => {
      setKinguinPaypalLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        window.open(res?.checkout_url, "_self");
      }
    });
  };

  const handleShopify = () => {
    setShopifyLoading(true);
    shopifyCheckout(middleware, parseFloat(amount)).then((res) => {
      console.log("amount", amount)
      setShopifyLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        window.open(res?.checkout_url, "_self");
      }
    });
  };

  const styles = {
    width: {
      width: "100%",
    },
    container: {
      width: "100%",
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
    },
    value: {
      fontSize: 16,
      fontWeight: 800,
      color: text,
    },
    meta: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 400,
      textAlign: "center",
      maxWidth: 500,
    },
    warning: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
      maxWidth: 400,
    },
    paymentContainer: {
      width: "100%",
      borderRadius: 1,
      border: `1px solid ${cardVeryLight}`,
      padding: 2,
      backgroundColor: cardLight,
    },
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
      maxWidth: 500,
    },
  };

  return (
    <>
      {/* <ConfirmCashAppModal
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onSuccess={handlePoof}
      /> */}

      <CustomModal
        open={open}
        onClose={onClose}
        title="Complete Purchase"
        titleSize="large"
        bottomContent={
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              {/* <Grid item sx={styles.width}>
                <PayPalCheckoutButton
                  loading={loading}
                  onClick={handlePayPal}
                  disabled={!store?.user?.connections?.paypal?.email}
                />
              </Grid> */}

              <Grid item sx={styles.width}>
                <PayPalCheckoutButton
                  loading={kinguinPaypalLoading}
                  onClick={handleKinguinPaypal}
                />
              </Grid>
              {/* 
              <Grid item sx={styles.width}>
                <CashAppCheckout
                  loading={cashAppLoading}
                  onClick={handleSellix}
                />
              </Grid> */}

              <Grid item sx={styles.width}>
                <CryptoCheckout
                  loading={cashAppLoading}
                  onClick={handleSellix}
                />
              </Grid>


              <Grid item sx={styles.width}>
                <ShopifyCheckoutButton
                  loading={shopifyLoading}
                  onClick={handleKinguin}
                />
              </Grid>

              <Grid item sx={{ width: "100%", marginTop: 1 }}>
                <Typography sx={styles.meta}>
                  By purchasing these coins you agree to our{" "}
                  <span
                    style={{
                      fontWeight: 700,
                      color: termsHovered ? doubtedBlue : text,
                      cursor: termsHovered ? "pointer" : "default",
                    }}
                    onMouseEnter={() => setTermsHovered(true)}
                    onMouseLeave={() => setTermsHovered(false)}
                    onClick={(e) => {
                      navigate("/support/terms");
                      handleClose(e);
                    }}
                  >
                    Terms of Service
                  </span>{" "}
                  and you are aware that when you purchase these coins, you
                  cannot cancel or refund the order once they are received.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        }
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={{ xs: 2 }}
        >
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              {description && (
                <>
                  <Grid item>
                    <Typography sx={styles.description}>
                      {description}
                    </Typography>
                  </Grid>

                  <Grid item sx={styles.width}>
                    <Divider
                      sx={{
                        width: "100%",
                        backgroundColor: text,
                        opacity: 0.15,
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid item>
                <Typography
                  sx={{ fontSize: normal, fontWeight: 700, color: text }}
                >
                  Purchase Summary
                </Typography>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RivoxCoin size={40} />
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: normal,
                            color: text,
                            fontWeight: 700,
                          }}
                        >
                          {amount} Coins
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: normal,
                            color: secondaryText,
                            fontWeight: 500,
                          }}
                        >
                          One-time charge on {getReadableDateString(new Date())}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <Divider
              sx={{ width: "100%", backgroundColor: text, opacity: 0.15 }}
            />
          </Grid>

          <Grid item sx={styles.container}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography sx={styles.label}>Tax</Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.label}>
                      {currencyFormatter().format(0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography sx={{ ...styles.value, fontWeight: 600 }}>
                      Total
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.value}>
                      {currencyFormatter().format(amount)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item sx={styles.paymentContainer}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      sx={{ fontSize: normal, fontWeight: 700, color: text }}
                    >
                      Payment method
                    </Typography>
                  </Grid>

                  {!store?.user?.connections?.paypal?.email && (
                    <Grid item>
                      <Typography
                        sx={{
                          fontSize: normal,
                          textDecoration: "underline",
                          color: secondaryText,
                          fontWeight: 700,
                          "&:hover": {
                            cursor: "pointer",
                            color: text,
                          },
                        }}
                        onClick={() => window.open(PAYPAL_URL, "_self")}
                      >
                        Link PayPal
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={paypalMethod}
                      width={35}
                      draggable={false}
                      alt="paypal-payment-method"
                    />
                  </Grid>

                  {!store?.user?.connections?.paypal?.email && (
                    <Grid item>
                      <Typography
                        sx={{
                          fontSize: normal,
                          fontWeight: 400,
                          color: secondaryText,
                        }}
                      >
                        No PayPal Linked
                      </Typography>
                    </Grid>
                  )}

                  {store?.user?.connections?.paypal?.email && (
                    <Grid item>
                      <Typography
                        sx={{ fontSize: normal, fontWeight: 400, color: text }}
                      >
                        {store?.user?.connections?.paypal?.email}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </CustomModal>
    </>
  );
};

export default PayPalDepositModal;
