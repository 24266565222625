import { Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { FaUserSlash } from "react-icons/fa";
import { StoreContext } from "../../store/Store";
import {
  card,
  cardLight,
  cardVeryLight,
  doubtedBlue,
  doubtedOrange,
  normal,
  offWhite,
  purple,
  secondaryText,
  small,
  text,
  yellow,
} from "../../utils/themeContstants";
import MatchTeamItem from "./MatchTeamItem";

const MatchCancelTeamItem = ({ team, isCreatingTeam, token }) => {
  const store = useContext(StoreContext);

  const shouldShowTeam = () => {
    if (
      !token?.joining_team ||
      Object.values(token?.readied_users?.readied)?.includes(false)
    ) {
      if (isCreatingTeam) {
        if (token?.creating_team_ids?.includes(store?.user?._id)) {
          return true;
        }
      }

      if (!isCreatingTeam) {
        if (token?.joining_team_ids?.includes(store?.user?._id)) {
          return true;
        }
      }

      return false;
    }

    return true;
  };

  const styles = {
    container: {
      border: `1px solid ${cardVeryLight}`,
      borderRadius: 2,
      flexGrow: 1,
    },
    width: {
      width: "100%",
    },
    teamName: {
      color: text,
      fontWeight: 800,
      textTransform: "uppercase",
      letterSpacing: 0.5,
      fontSize: normal,
    },
    username: {
      fontSize: normal,
      color: text,
      fontWeight: 700,
    },
    epic: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 600,
    },
  };

  return (
    <>
      {shouldShowTeam() && (
        <MatchTeamItem
          isCreatingTeam={isCreatingTeam}
          team={team}
          token={token}
        />
      )}

      {!shouldShowTeam() && (
        <Grid item sx={styles.container}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            <Grid
              item
              sx={{
                width: "100%",
                padding: 2,
                borderBottom: `1px solid ${cardVeryLight}`,
                backgroundColor: card,
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                sx={{
                  borderLeft: `4px solid ${
                    isCreatingTeam ? doubtedBlue : doubtedOrange
                  }`,
                  paddingLeft: 1,
                }}
              >
                <Grid item>
                  <Typography
                    sx={{ fontSize: small, fontWeight: 600, color: offWhite }}
                  >
                    Canceled
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={styles.teamName}>Canceled</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ width: "100%", padding: 2 }}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 1 }}
              >
                {token?.creating_team?.users?.map((_, i) => (
                  <Grid item key={i}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 2 }}
                    >
                      <Grid item>
                        <FaUserSlash
                          style={{ fontSize: 70, color: secondaryText }}
                        />
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          alignItems="start"
                          justifyContent="center"
                        >
                          <Grid item>
                            <Typography sx={styles.username}>
                              Canceled
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography sx={styles.epic}>Canceled</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MatchCancelTeamItem;
