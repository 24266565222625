const Joint = () => {
  const styles = {
    one: {
      fill: "none",
      strokeWidth: 0.75,
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#fff",
    },
  };

  return (
    <g transform="translate(520, 470)">
      <path
        style={styles.three}
        d="M18.36,4.91c-.31-1.03,.26-2.09,1.16-2.38,.76-.24,1.65,.08,2.13,.82,2.21,2.61,4.11,4.63,5.41,5.98,2.45,2.53,3.3,3.18,5.43,5.56,1.49,1.67,2.54,2.99,4.12,4.97,1.78,2.24,3.23,4.16,4.27,5.59,3.1,2.51,6.37,5.56,9.58,9.24,1.03,1.19,1.99,2.36,2.89,3.52,1.68,1.66,3.42,3.5,5.18,5.55,2.18,2.54,4.06,5.01,5.68,7.33,1.87,1.91,3.87,4.18,5.86,6.87,2.56,3.47,4.49,6.79,5.94,9.7,.88,.94,1.89,2.05,3,3.29,1.68,1.88,2.33,2.67,2.89,3.87,.42,.9,.64,1.69,.76,2.22-.36,.88-1.08,2.27-2.48,3.5-.46,.41-1.4,1.22-2.89,1.73-1.11,.38-2.1,.44-2.74,.44-1.37-.93-2.79-1.96-4.22-3.1-3.55-2.82-6.49-5.7-8.9-8.41-3.21-4.38-6.41-8.76-9.62-13.14-1.58-1.78-3.33-4.01-5.02-6.71-1.65-2.64-2.86-5.12-3.75-7.28-1.4-1.51-2.89-3.27-4.35-5.29-2.07-2.85-3.65-5.59-4.87-8.02-1.1-1.56-2.24-3.35-3.35-5.35-1.28-2.32-2.29-4.52-3.1-6.52-1.44-1.78-2.92-3.78-4.4-6-1.85-2.8-3.38-5.49-4.62-7.97Z"
      />
      <path
        style={styles.one}
        d="M27.21,18.68c.64,.04,1.58,.15,2.71,.44,.96,.25,2.61,.77,5.86,2.81,1.56,.98,3.62,2.38,5.9,4.3"
      />
      <path
        style={styles.one}
        d="M34,31.11c.54-.03,1.34-.06,2.3,0,2.84,.15,4.94,.86,7.05,1.58,2.56,.89,4.41,1.83,5.05,2.17,1.83,.96,3.36,1.98,4.6,2.91"
      />
      <path
        style={styles.one}
        d="M43.04,44.07c3.25-.02,8.47,.39,14.19,2.93,3.09,1.37,5.53,3.01,7.35,4.46"
      />
      <path
        style={styles.one}
        d="M52.38,58.59c3.73,.42,8.92,1.43,14.65,3.99,3.6,1.61,6.54,3.44,8.83,5.1"
      />
      <path
        style={styles.one}
        d="M61.52,70.96c.96-.38,2.42-.83,4.33-.98,3.09-.23,5.63,.49,6.97,.87,4.61,1.33,7.94,3.79,9.64,5.22"
      />
      <path d="M74.57,82.72c.55-1.01,1.47-2.43,2.95-3.81,1.66-1.54,4.33-3.31,4.91-2.77,.53,.51-.66,3.08-2.54,4.67-1.95,1.64-4.24,1.87-5.31,1.9Z" />
      <path
        style={styles.two}
        d="M33.47,1.55c0-.25-8.28-1.72-17.31,1.49C6.4,6.5,.79,13.63,1.01,13.9c.22,.28,6.85-7.14,18.48-10.53,7.81-2.28,13.99-1.6,13.98-1.82Z"
      />
    </g>
  );
};

export default Joint;
