import { Divider, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { StoreContext } from "../../store/Store";
import { secondaryText, text } from "../../utils/themeContstants";
import UpdateTOSModal from "../support/UpdateTOSModal";
import PayPalDepositModal from "../wallet/PayPalDepositModal";
import DepositItem from "./DepositItem";

const DepositWalletMenu = ({ closeWallet }) => {
  const store = useContext(StoreContext);

  const [amount, setAmount] = useState(null);
  const [open, setOpen] = useState(false);
  const [tosOpen, setTosOpen] = useState(false);

  const handleOpenPayPal = (depoAmount) => {
    // if (store?.user?.info?.last_agreed_tos_version !== store?.tosVersion) {
    //   setTosOpen(true);
    //   return;
    // }

    setAmount(depoAmount);
    setOpen(true);
  };

  const styles = {
    width: {
      width: "100%",
    },
    meta: {
      fontSize: 12,
      color: secondaryText,
    },
  };

  return (
    <>
      <PayPalDepositModal
        open={open}
        onClose={() => setOpen(false)}
        amount={amount}
        closeWallet={closeWallet}
      />
      <UpdateTOSModal open={tosOpen} onClose={() => setTosOpen(false)} />

      <Grid item sx={{ width: "100%", marginTop: 1 }}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={{ fontSize: 13, fontWeight: 500, color: text }}>
              Prices are shown in <span style={{ fontWeight: 800 }}>USD</span>
            </Typography>
          </Grid>

          <DepositItem amount={5} onClick={() => handleOpenPayPal(5)} />

          <Grid item sx={styles.width}>
            <Divider
              sx={{ width: "100%", backgroundColor: text, opacity: 0.15 }}
            />
          </Grid>

          <DepositItem amount={10} onClick={() => handleOpenPayPal(10)} />

          <Grid item sx={styles.width}>
            <Divider
              sx={{ width: "100%", backgroundColor: text, opacity: 0.15 }}
            />
          </Grid>

          <DepositItem amount={25} onClick={() => handleOpenPayPal(25)} />

          <Grid item sx={styles.width}>
            <Divider
              sx={{ width: "100%", backgroundColor: text, opacity: 0.15 }}
            />
          </Grid>

          <DepositItem amount={50} onClick={() => handleOpenPayPal(50)} />

          <Grid item sx={styles.width}>
            <Divider
              sx={{ width: "100%", backgroundColor: text, opacity: 0.15 }}
            />
          </Grid>

          <DepositItem amount={100} onClick={() => handleOpenPayPal(100)} />

          <Grid item sx={styles.width}>
            <Divider
              sx={{ width: "100%", backgroundColor: text, opacity: 0.15 }}
            />
          </Grid>

          <Grid item>
            <Typography sx={styles.meta}>
              Funds will not be available for withdrawal immediately once you
              deposit. Any funds won through playing matches will become
              available to withdraw.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DepositWalletMenu;
