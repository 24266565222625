const Doctor = () => {
  const styles = {
    one: {
      fill: "#3f3f3f",
    },
    two: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
      fill: "#9e9d9d",
    },
    three: {
      strokeMiterLimit: 10,
      stroke: "#000",
      fill: "none",
    },
    four: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
      fill: "#7a7878",
    },
    five: {
      stroke: "#848383",
      strokeWidth: 4,
      fill: "none",
      strokeMiterLimit: 10,
    },
    six: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
      fill: "none",
    },
    seven: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
      fill: "#5b5757",
    },
    eight: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
      fill: "#353434",
    },
    nine: {
      fill: "#666464",
    },
    ten: {
      fill: "#848383",
    },
    eleven: {
      fill: "#999",
    },
  };

  return (
    <g>
      <path
        style={styles.eight}
        d="M381.44,307.82c-.62-1.38-1.45-3.83-1.02-6.82,.19-1.35,.6-2.5,1.02-3.41,6.97-9.62,18.24-22.64,35.61-34.94,24.57-17.4,49.61-25.05,64.61-28.55,2.37-2.27,4.75-4.55,7.12-6.82,7.5-.73,15.85-1.22,24.93-1.28,8.47-.05,16.31,.29,23.4,.85,5.1,1.77,12.5,4.38,21.37,7.67,4.57,1.7,20.32,7.55,28.49,11.08,32.8,14.17,51.68,37.64,52.91,39.2,5.29,6.74,12.5,17.6,17.15,32.62,1.79,1.66,5.27,5.37,6.25,10.84,.42,2.34,.28,4.39,0,5.97,2.92,6.09,5.73,12.92,8.14,20.45,4.49,14.02,6.43,26.79,7.12,37.5,1.02,9.37,2.03,18.75,3.05,28.12,17.93,12.54,40.73,31.4,61.05,57.95,11.21,14.65,20.7,35.9,39.68,78.4,2.91,6.53,7.85,17.95,6.1,32.38-.33,2.71-.81,4.88-1.13,6.16-.53,1.74-1.81,4.64-4.98,6.63-5.46,3.43-14.4,2.82-21.68-1.98-28.05-14.11-56.09-28.22-84.14-42.33-15.8-7.46-34.48-15.18-55.96-22.16-25.45-8.26-48.97-13.57-69.19-17.04-18.67-1.06-38.39-2.71-59.01-5.11-13.02-1.52-25.58-3.24-37.65-5.11-16.54-2.83-29.22-7.42-37.65-11.08-6.76-2.93-16.37-7.18-26.45-15.34-4.03-3.26-7.07-6.28-9.16-8.52-8.48-33.8-16.96-67.61-25.44-101.41-.02-7.52,.71-16.49,3.05-26.42,1.41-5.98,3.19-11.39,5.09-16.19,2.03-1.14,4.07-2.27,6.1-3.41,1.02-3.71,3.02-8.65,7.12-13.64,1.34-1.62,2.72-3.04,4.07-4.26Z"
      />
      <path
        style={styles.four}
        d="M448.52,375.52c7.94-20.5,30.93-35.32,58.07-35.32,23.86,0,44.52,11.46,54.56,28.17,4.15,6.9,6.49,14.71,6.49,22.97,0,25.68-22.6,46.93-52.04,50.58-.3,.03-.59,.07-.91,.1-1.97,.2-34.45,3.04-55.41-18.72-20.19-20.96-11.48-45.81-10.75-47.77Z"
      />
      <ellipse
        style={styles.two}
        cx="505.06"
        cy="388.78"
        rx="40.19"
        ry="32.38"
      />
      <path
        style={styles.seven}
        d="M448.59,375.14c-6.94,15.8-5.52,40.23,7.12,52.84,14.29,14.24,38.06,19.81,59.88,13.93-5.31,.63-14.07,1.1-24.27-1.15-4.07-.9-20.33-4.86-32.56-17.9-16.31-17.38-10.83-44.28-10.17-47.72Z"
      />
      <path
        style={styles.four}
        d="M665.2,409.14c3.85-3.12,9.17-8.49,11.31-16.11,1.33-4.72,.96-8.62,0-14.49-1.22-7.51-3.34-17.75-7.2-30.01-.02-.05-.03-.1-.05-.15-.01-.02-.02-.04-.03-.06-7.42-14.95-23.95-23.13-36.47-20.9-15.67,2.78-29.39,22.61-19.33,43.46,.71,1.18,1.57,2.91,2.03,5.11,.45,2.12,.38,3.95,.2,5.24,3.6,1.62,7.28,3.37,11,5.26,7.34,3.7,14.9,7.92,22.53,12.73,.03,.02,.05,.03,.08,.05,1.23,.78,2.47,1.58,3.71,2.39"
      />
      <path
        style={styles.two}
        d="M652.99,401.67c5.42-2.05,9.86-6.72,11.16-13.18,.76-3.79,1.21-7.9,1.17-12.27-.07-7.54-.18-18.44-8.14-26.74-.05-.06-.1-.11-.15-.16-7.55-7.74-20.34-6.86-26.73,1.87-.56,.76-1.1,1.56-1.61,2.41-5.65,9.4-6.15,21.42-2.02,32.9"
      />
      <path
        style={styles.seven}
        d="M644.63,328.11s-.02,0-.03,0c-2.23-.85-10.27-3.62-19.47-1.11-12.27,3.35-16.45,13.31-17.3,15.34-4.65,11.1,.1,24.71,3.05,24.71-.45-1.89-.92-4.52-1.02-7.67-.11-3.46-.3-9.67,3.05-16.19,1.46-2.84,6.51-12.67,18.31-15.34,5.64-1.27,10.62-.42,13.4,.26Z"
      />
      <path
        style={styles.six}
        d="M561.15,368.37c.84-.97,1.69-1.94,2.53-2.91,7.8-7.54,19.1-11.12,29.4-9.06,7.24,1.45,11.9,5.28,14,7.28,.31,.7,.85,1.62,1.77,2.52,.34,.33,.69,.61,1.02,.85,1.19,1.28,2.37,2.56,3.56,3.83"
      />
      <path
        style={styles.six}
        d="M615.46,375.99c.07,1.75,.13,3.49,.2,5.24,8.77,4.26,17.9,9.02,27.27,14.36,2.12,1.21,4.2,2.42,6.26,3.63,.03,.02,.07,.04,.1,.06,1.3,.77,2.59,1.54,3.87,2.32,4.16,2.51,8.18,5.03,12.04,7.54,5.8,3.76,11.26,7.49,16.4,11.16"
      />
      <path
        style={styles.six}
        d="M381.44,307.82c9.91,3.33,22.76,8.8,35.61,17.9,6.12,4.33,11.16,8.72,15.26,12.78,7.73,8.28,15.46,16.57,23.18,24.85"
      />
      <ellipse
        style={styles.four}
        cx="446.05"
        cy="325.71"
        rx="8.75"
        ry="5.8"
        transform="translate(-94.61 212.87) rotate(-24.35)"
      />
      <ellipse
        style={styles.four}
        cx="421.63"
        cy="308.67"
        rx="8.46"
        ry="5.99"
        transform="translate(-100.55 289.14) rotate(-34.07)"
      />
      <ellipse
        style={styles.four}
        cx="377.28"
        cy="356.81"
        rx="7.95"
        ry="6.14"
        transform="translate(-110.88 533.85) rotate(-63.34)"
      />
      <ellipse
        style={styles.four}
        cx="369.43"
        cy="339.59"
        rx="7.93"
        ry="5.42"
        transform="translate(-88.6 544.54) rotate(-66.7)"
      />
      <ellipse
        style={styles.four}
        cx="365.02"
        cy="367.53"
        rx="7.14"
        ry="4.81"
        transform="translate(-25.24 708.14) rotate(-86.31)"
      />
      <ellipse
        style={styles.four}
        cx="464.72"
        cy="245.96"
        rx="8.98"
        ry="4.16"
        transform="translate(-51.75 147.37) rotate(-17.08)"
      />
      <path
        style={styles.four}
        d="M520.84,238.97c0,2.32-3.85,4.2-8.61,4.2s-8.61-1.88-8.61-4.2,3.85-4.2,8.61-4.2,8.61,1.88,8.61,4.2Z"
      />
      <path
        style={styles.four}
        d="M542.71,250.29c0,2.82-3.74,5.11-8.35,5.11s-8.35-2.29-8.35-5.11c0-2.82,3.74-5.11,8.35-5.11s8.35,2.29,8.35,5.11Z"
      />
      <path
        style={styles.four}
        d="M548.08,237.94c1.39,2.78-.65,5.54-4.86,5.54s-7.63-2.76-7.63-5.54c0-2.28,2.37-3.84,5.29-3.84s6.07,1.56,7.21,3.84Z"
      />
      <ellipse
        style={styles.four}
        cx="396.19"
        cy="292.48"
        rx="8.46"
        ry="5.99"
        transform="translate(-95.84 272.11) rotate(-34.07)"
      />
      <g>
        <path
          style={styles.one}
          d="M433.33,339.59c8.82-5.88,32.84-20.29,68.17-22.4,42.65-2.55,73.03,14.53,81.4,19.6,8.27-16.76,25.89-27.84,44.77-28.12,14.6-.22,25.32,6.09,29.36,8.76,1.41,1.14,4.25,3.74,5.75,7.86,1.44,3.97,.9,7.34,.51,8.95,1.25,3.43,2.51,6.85,3.76,10.28-2.85-4.08-8.7-11-19.03-15.39-3.64-1.55-8.52-3.56-15.26-3.41-7.42,.17-12.85,2.87-15.26,4.26-3.84,2.12-9.53,5.87-14.24,11.93-2.52,3.24-4.14,6.33-5.2,8.9,.47,2.56,.93,5.12,1.4,7.68-2.38-1.12-6.75-2.79-12.48-2.94-10.34-.27-17.49,4.62-19.33,5.97-2.16-4.15-4.33-8.3-6.49-12.45-4.97-2.37-40.47-18.65-76.94-5.45-14.46,5.23-23.99,13.26-29.49,18.9-7.13-7.64-14.25-15.28-21.38-22.92Z"
        />
        <path
          style={styles.one}
          d="M561.15,368.37c33.32,19.31,82.43,52.75,126.05,105.2,40.1,48.22,60.33,94.22,70.94,125.68,7.42,4.32,15.96,5.89,21.65,3.01,5.24-2.66,6.53-8.31,8.14-15.34,1.29-5.67,1.18-10.46,.87-13.64-5.59-22.34-16.22-51.69-37.5-82.66-43-62.56-104.35-95.22-135.63-109.38,.09-2.14-.12-5.41-1.73-9.08-2.7-6.14-7.74-9.68-10.04-11.1-1.77-1.15-9.58-6-20.48-5.09-14.39,1.2-21.57,11.37-22.26,12.4Z"
        />
        <path
          style={styles.one}
          d="M360.58,390.91c43.91,35.59,77.68,61.32,90.56,69.88,8.7,5.78,17.3,10.23,17.3,10.23,8.01,4.14,15.16,7.07,21.37,9.37,21.26,7.9,42.81,12.68,50.87,14.49,0,0,30.61,6.85,58,13.64,68.09,16.86,116.59,53.38,117.01,52.84,.16-.21-7.69-4.96-14.24-8.52-55.08-29.95-149.21-47.53-181.31-53.1l-138.69-26.59c-6.95-27.41-13.91-54.83-20.86-82.24Z"
        />
        <path
          style={styles.nine}
          d="M465.08,385.44c-1.36,3.66-3.73,11.96,.3,20.81,6.51,14.31,26.14,22.19,42.73,20.45,20.8-2.17,38.35-19.61,37.65-41.23,.77,20.73-19.99,37.22-42.73,36.12-21.12-1.02-38.77-16.96-37.94-36.15Z"
        />
        <path
          style={styles.five}
          d="M451.14,377.27c6.19-16.44,23.4-32.33,47.82-34.94,26.94-2.88,50.39,11.69,60.03,28.98,9,16.15,4.05,31.26,3.05,34.09-6.03,17.18-23.75,31.72-46.8,34.94-2.12,.3-32.36,4.06-52.91-15.34-21.08-19.9-12.07-45.4-11.19-47.72Z"
        />
        <path
          style={styles.five}
          d="M617.82,382.29c.42-1.71,.8-4.38,.19-7.57-.78-4.03-2.79-6.95-4.07-8.52-5.52-18.26,6.46-34.24,19.33-36.64,13.81-2.58,34.53,9.34,36.32,30.05,.94,3.18,2.2,7.79,3.36,13.41,1.86,9,2.85,13.58,2.03,17.9-.61,3.26-2.55,9.45-10.17,16.19"
        />
        <path
          style={styles.ten}
          d="M493.6,357.73c-16.34,4.35-27.64,16.45-28.22,29.76-.69,15.95,14.14,30.86,34.96,33.44-3.08-2.2-16.29-12.14-18.68-29.18-2.62-18.64,9.69-31.72,11.94-34.02Z"
        />
        <path
          style={styles.ten}
          d="M639.48,344.51c-2.5,4.29-7.32,14.02-6.22,26.79,.99,11.49,6.29,19.83,9.27,23.86-5.56-3.24-11.12-6.48-16.68-9.72-1.2-4.25-6.05-23.43,7.41-36.29,1.63-1.56,3.66-3.15,6.22-4.64Z"
        />
        <path
          style={styles.eleven}
          d="M446.03,319.62c3.77-.32,7.13,1,8.15,3.11,1.46,3-2.45,6.32-5.09,7.67-3.75,1.91-7.73,1.26-8.81,1.06,4.12-.6,7.25-3.13,7.79-6.17,.53-2.98-1.64-5.25-2.05-5.67Z"
        />
        <path
          style={styles.eleven}
          d="M420.6,302.58c3.77-.32,7.13,1,8.15,3.11,1.46,3-2.45,6.32-5.09,7.67-3.75,1.91-7.73,1.26-8.81,1.06,4.12-.6,7.25-3.13,7.79-6.17,.53-2.98-1.64-5.25-2.05-5.67Z"
        />
        <path
          style={styles.eleven}
          d="M470.93,241.62c1.91,1.37,1.98,3.73,.28,5.65-2.43,2.74-7.45,3.43-9.92,3.26-3.52-.24-4.54-2.39-4.78-2.99,2.34,1.33,6.23,.86,9.54-1.05,3.25-1.88,4.64-4.41,4.89-4.87Z"
        />
        <path
          style={styles.eleven}
          d="M519.79,236.46c1.27,1.8,.33,4.05-2.14,5.42-3.52,1.95-8.68,1.27-11.01,.46-3.31-1.16-3.38-3.46-3.36-4.09,1.71,1.87,5.67,2.46,9.7,1.52,3.95-.92,6.38-2.94,6.81-3.32Z"
        />
        <path
          style={styles.eleven}
          d="M542.18,247.54c1.27,1.8,.33,4.05-2.14,5.42-3.52,1.95-8.68,1.27-11.01,.46-3.31-1.16-3.38-3.46-3.36-4.09,1.71,1.87,5.67,2.46,9.7,1.52,3.95-.92,6.38-2.94,6.81-3.32Z"
        />
        <path
          style={styles.eleven}
          d="M547.2,237.12c1.13,1.84,.77,3.92-.77,5-2.2,1.54-5.9,.41-7.64-.59-2.47-1.42-2.83-3.63-2.9-4.23,1.44,1.95,4.29,2.89,6.98,2.37,2.64-.51,4.06-2.22,4.32-2.54Z"
        />
        <path
          style={styles.eleven}
          d="M395.56,285.29c3.59-.36,6.79,1.13,7.77,3.49,1.39,3.37-2.33,7.09-4.85,8.6-3.57,2.14-7.37,1.42-8.39,1.19,3.93-.67,6.91-3.51,7.42-6.92,.51-3.35-1.56-5.89-1.95-6.35Z"
        />
        <path
          style={styles.eleven}
          d="M369.97,332.16c2.16-.38,4.08,1.2,4.67,3.7,.84,3.58-1.4,7.54-2.91,9.14-2.15,2.28-4.43,1.51-5.04,1.26,2.36-.71,4.15-3.73,4.46-7.35,.3-3.55-.94-6.26-1.17-6.75Z"
        />
        <path
          style={styles.eleven}
          d="M378.11,349.21c2.16-.38,4.08,1.2,4.67,3.7,.84,3.58-1.4,7.54-2.91,9.14-2.15,2.28-4.43,1.51-5.04,1.26,2.36-.71,4.15-3.73,4.46-7.35,.3-3.55-.94-6.26-1.17-6.75Z"
        />
        <path
          style={styles.eleven}
          d="M366.1,360.28c1.62-.38,3.06,1.2,3.5,3.7,.63,3.58-1.05,7.54-2.19,9.14-1.61,2.28-3.32,1.51-3.78,1.26,1.77-.71,3.11-3.73,3.35-7.35,.23-3.55-.7-6.26-.88-6.75Z"
        />
        <line
          style={styles.three}
          x1="482.66"
          y1="361.89"
          x2="512.24"
          y2="420.31"
        />
        <polyline
          style={styles.three}
          points="478.89 364.2 479.26 364.91 481.92 370.16 484 374.29 486.6 379.4 488.32 382.81 490.47 387.07 492.63 391.33 494.36 394.74 496.09 398.15 498.25 402.41 500.83 407.53 502.56 410.93 504.72 415.19 507.7 421.09"
        />
        <polyline
          style={styles.three}
          points="474.66 367.59 475.96 370.16 478.05 374.29 480.64 379.4 482.36 382.81 484.52 387.07 486.69 391.33 488.41 394.74 490.14 398.15 492.29 402.41 494.88 407.53 496.61 410.93 498.76 415.19 501.73 421.05"
        />
        <polyline
          style={styles.three}
          points="496.3 420.39 496.3 420.38 493.68 415.19 491.52 410.93 489.79 407.53 487.21 402.41 485.05 398.15 483.32 394.74 481.6 391.33 479.43 387.07 477.28 382.81 475.56 379.4 472.96 374.29 471.36 371.12"
        />
        <polyline
          style={styles.three}
          points="468 376.22 469.6 379.4 471.33 382.81 473.49 387.07 475.65 391.33 477.37 394.74 479.1 398.15 481.25 402.41 483.84 407.53 485.57 410.93 487.73 415.19 489.47 418.64"
        />
        <polyline
          style={styles.three}
          points="465.38 382.81 465.5 383.04 465.5 383.05 467.54 387.07 469.7 391.33 471.42 394.74 473.14 398.15 475.3 402.41 477.9 407.53 479.62 410.93 481.76 415.16 481.77 415.19"
        />
        <line
          style={styles.three}
          x1="487.74"
          y1="359.33"
          x2="517.78"
          y2="418.6"
        />
        <line
          style={styles.three}
          x1="491.81"
          y1="358.48"
          x2="521.85"
          y2="417.75"
        />
        <line
          style={styles.three}
          x1="496.9"
          y1="356.78"
          x2="526.48"
          y2="415.19"
        />
        <polyline
          style={styles.three}
          points="530.55 414.34 530.35 413.94 528.82 410.93 527.1 407.53 524.51 402.41 522.35 398.15 520.63 394.74 518.9 391.33 516.74 387.07 514.59 382.81 512.86 379.4 510.27 374.29 508.18 370.16 505.55 364.97 503.44 360.78 501.29 356.54"
        />
        <polyline
          style={styles.three}
          points="506.3 356.41 508.52 360.78 510.65 364.98 513.26 370.16 515.36 374.29 517.94 379.4 519.67 382.81 521.83 387.07 523.99 391.33 525.72 394.74 527.44 398.15 529.6 402.41 532.19 407.53 533.91 410.93 534.05 411.21"
        />
        <polyline
          style={styles.three}
          points="513.16 357.05 515.06 360.78 517.19 364.99 519.81 370.16 521.89 374.29 524.49 379.4 526.21 382.81 528.36 387.07 530.52 391.33 532.25 394.74 533.98 398.15 536.14 402.41 538.4 406.88 538.72 407.53"
        />
        <polyline
          style={styles.three}
          points="520.13 358.74 521.16 360.78 523.3 365.01 525.91 370.16 528 374.29 530.59 379.4 532.31 382.81 534.47 387.07 536.63 391.33 538.36 394.74 540.09 398.15 541.91 401.74 541.91 401.75 542.24 402.41"
        />
        <polyline
          style={styles.three}
          points="544.66 394.3 543.17 391.33 541.01 387.07 538.85 382.81 537.12 379.4 534.54 374.29 532.44 370.16 529.85 365.03 528.58 362.52 528.58 362.51"
        />
        <polyline
          style={styles.three}
          points="525.28 360.78 521.16 360.78 515.06 360.78 508.52 360.78 503.44 360.78 498.93 360.78 492.99 360.78 488.48 360.78 484.85 360.78"
        />
        <polyline
          style={styles.three}
          points="532.38 365.03 529.85 365.03 523.3 365.01 517.19 364.99 510.65 364.98 505.55 364.97 501.04 364.97 495.09 364.95 490.58 364.94 484.19 364.92 479.26 364.91 478.61 364.91"
        />
        <polyline
          style={styles.three}
          points="537.95 370.16 532.44 370.16 525.91 370.16 519.81 370.16 513.26 370.16 508.18 370.16 503.68 370.16 497.73 370.16 493.23 370.16 486.84 370.16 481.92 370.16 475.96 370.16 472.18 370.16"
        />
        <polyline
          style={styles.three}
          points="541.69 374.29 541.02 374.29 534.54 374.29 528 374.29 521.89 374.29 515.36 374.29 510.27 374.29 505.77 374.29 499.82 374.29 495.33 374.29 488.94 374.29 484 374.29 478.05 374.29 472.96 374.29 469.1 374.29"
        />
        <polyline
          style={styles.three}
          points="543.53 379.4 537.12 379.4 530.59 379.4 524.49 379.4 517.94 379.4 512.86 379.4 508.36 379.4 502.42 379.4 497.92 379.4 491.52 379.4 486.6 379.4 480.64 379.4 475.56 379.4 469.6 379.4 466.59 379.4 466.4 379.4"
        />
        <polyline
          style={styles.three}
          points="465.38 382.81 465.55 382.81 471.33 382.81 477.28 382.81 482.36 382.81 488.32 382.81 493.25 382.81 499.64 382.81 504.15 382.81 510.09 382.81 514.59 382.81 519.67 382.81 526.21 382.81 532.31 382.81 538.85 382.81 544.57 382.81"
        />
        <polyline
          style={styles.three}
          points="464.92 387.07 467.54 387.07 473.49 387.07 479.43 387.07 484.52 387.07 490.47 387.07 495.41 387.07 501.81 387.07 506.3 387.07 512.25 387.07 516.74 387.07 521.83 387.07 528.36 387.07 534.47 387.07 541.01 387.07 545.2 387.07"
        />
        <line
          style={styles.three}
          x1="464.36"
          y1="391.33"
          x2="545.76"
          y2="391.33"
        />
        <polyline
          style={styles.three}
          points="544.75 394.74 544.57 394.74 538.36 394.74 532.25 394.74 525.72 394.74 520.63 394.74 516.12 394.74 510.19 394.74 505.69 394.74 499.29 394.74 494.36 394.74 488.41 394.74 483.32 394.74 477.37 394.74 471.42 394.74 465.55 394.74"
        />
        <polyline
          style={styles.three}
          points="466.59 398.15 473.14 398.15 479.1 398.15 485.05 398.15 490.14 398.15 496.09 398.15 501.01 398.15 507.41 398.15 511.92 398.15 517.85 398.15 522.35 398.15 527.44 398.15 533.98 398.15 540.09 398.15 543.53 398.15"
        />
        <polyline
          style={styles.three}
          points="468.6 402.41 475.3 402.41 481.25 402.41 487.21 402.41 492.29 402.41 498.25 402.41 503.17 402.41 509.58 402.41 514.08 402.41 520.01 402.41 524.51 402.41 529.6 402.41 536.14 402.41 541.53 402.41 542.24 402.41"
        />
        <polyline
          style={styles.three}
          points="472.29 407.53 477.9 407.53 483.84 407.53 489.79 407.53 494.88 407.53 500.83 407.53 505.77 407.53 512.17 407.53 516.67 407.53 522.59 407.53 527.1 407.53 532.19 407.53 537.84 407.53 538.72 407.53"
        />
        <polyline
          style={styles.three}
          points="475.76 410.93 479.62 410.93 485.57 410.93 491.52 410.93 496.61 410.93 502.56 410.93 507.5 410.93 513.9 410.93 518.39 410.93 524.32 410.93 528.82 410.93 533.91 410.93 534.37 410.93"
        />
        <polyline
          style={styles.three}
          points="481.77 415.19 481.82 415.19 487.73 415.19 493.68 415.19 498.76 415.19 504.72 415.19 509.65 415.19 516.05 415.19 520.56 415.19 526.48 415.19 528.31 415.19"
        />
        <line
          style={styles.three}
          x1="628.69"
          y1="353.6"
          x2="648.53"
          y2="344.51"
        />
        <polyline
          style={styles.three}
          points="652.18 345.82 651.77 346.05 648.8 347.73 645.83 349.43 642.86 351.12 639.89 352.81 636.92 354.5 633.91 356.21 630.94 357.91 627.97 359.6 625.95 360.75 625.41 361.05"
        />
        <polyline
          style={styles.three}
          points="623.95 367 624.23 366.84 626.2 365.71 628.21 364.55 631.18 362.84 634.15 361.13 637.16 359.39 640.13 357.69 643.1 355.98 646.07 354.27 649.04 352.56 652 350.85 654.97 349.14 656.13 348.47"
        />
        <polyline
          style={styles.three}
          points="658.73 351.25 658.06 351.61 655.17 353.19 652.2 354.82 649.22 356.44 646.25 358.06 643.28 359.69 640.31 361.32 637.34 362.94 634.33 364.58 634.33 364.59 631.34 366.21 631.34 366.22 628.37 367.84 626.35 368.95 623.97 370.25"
        />
        <polyline
          style={styles.three}
          points="624.06 374.45 626.55 373.1 628.58 371.99 631.55 370.36 634.52 368.74 637.54 367.09 640.51 365.47 643.48 363.84 646.45 362.21 649.43 360.59 652.41 358.97 655.38 357.34 658.27 355.76 660.23 354.69"
        />
        <line
          style={styles.three}
          x1="662.27"
          y1="357.24"
          x2="624.84"
          y2="377.7"
        />
        <line
          style={styles.three}
          x1="663.28"
          y1="361.51"
          x2="625.86"
          y2="381.96"
        />
        <line
          style={styles.three}
          x1="663.28"
          y1="365.77"
          x2="625.86"
          y2="386.22"
        />
        <polyline
          style={styles.three}
          points="664.3 370.03 662.03 371.26 659.1 372.87 656.21 374.45 653.24 376.07 650.27 377.7 647.3 379.32 647.3 379.33 644.32 380.94 641.35 382.57 638.38 384.2 635.36 385.84 632.39 387.46 632.39 387.47 630.53 388.49 630.32 388.6 630.26 388.64"
        />
        <polyline
          style={styles.three}
          points="665.29 374.01 665.29 374.02 662.26 375.67 659.32 377.27 656.43 378.85 656.43 378.86 653.45 380.48 650.48 382.1 647.51 383.72 644.54 385.35 641.56 386.98 638.59 388.6 635.58 390.25 634.72 390.72 634.43 390.87 634.35 390.92"
        />
        <polyline
          style={styles.three}
          points="637.88 392.96 638.06 392.86 638.3 392.72 638.78 392.46 641.75 390.84 644.72 389.21 647.7 387.58 650.67 385.96 653.65 384.34 656.62 382.72 656.62 382.71 659.51 381.13 662.44 379.53 665.3 377.97"
        />
        <polyline
          style={styles.three}
          points="665.05 382.36 662.64 383.68 659.71 385.28 656.82 386.86 653.85 388.49 650.87 390.11 647.9 391.73 644.93 393.36 642.1 394.91 641.96 394.99 641.91 395.01 641.67 395.14"
        />
        <polyline
          style={styles.three}
          points="664.64 385.7 662.8 386.71 659.86 388.32 656.97 389.89 653.99 391.52 651.02 393.15 648.05 394.77 645.08 396.4 644.83 396.53 644.69 396.61 644.44 396.74"
        />
        <polyline
          style={styles.three}
          points="663.11 391.91 663.05 391.95 660.12 393.55 657.23 395.13 654.25 396.75 651.28 398.38 649.45 399.38 649.44 399.39 649.22 399.5"
        />
        <polyline
          style={styles.three}
          points="654.46 401.03 654.46 401.02 654.25 396.75 653.99 391.52 653.85 388.49 653.65 384.34 653.45 380.48 653.24 376.07 653.01 371.38 652.8 367.23 652.58 362.54 652.41 358.97 652.2 354.82 652 350.85 651.77 346.05 651.75 345.61"
        />
        <line
          style={styles.three}
          x1="648.64"
          y1="344.51"
          x2="651.41"
          y2="401.03"
        />
        <polyline
          style={styles.three}
          points="648.26 398.94 648.25 398.67 648.23 398.63 648.05 394.77 647.9 391.73 647.7 387.58 647.51 383.72 647.3 379.33 647.06 374.63 646.86 370.48 646.63 365.79 646.45 362.21 646.25 358.06 646.07 354.27 645.83 349.43 645.65 345.83 645.59 344.51"
        />
        <polyline
          style={styles.three}
          points="645.11 397.13 645.1 396.84 645.09 396.69 645.08 396.4 644.93 393.36 644.72 389.21 644.54 385.35 644.32 380.94 644.09 376.26 643.89 372.11 643.66 367.41 643.48 363.84 643.28 359.69 643.1 355.98 642.86 351.12 642.67 347.2 642.54 344.51"
        />
        <polyline
          style={styles.three}
          points="641.98 395.32 641.96 395.04 641.96 394.99 641.95 394.82 641.75 390.84 641.56 386.98 641.35 382.57 641.12 377.88 640.92 373.74 640.69 369.04 640.51 365.47 640.31 361.32 640.13 357.69 639.89 352.81 639.68 348.56 639.49 344.74 639.48 344.51"
        />
        <polyline
          style={styles.three}
          points="636.5 345.92 636.7 349.93 636.92 354.5 637.16 359.39 637.34 362.94 637.54 367.09 637.71 370.67 637.94 375.35 638.14 379.5 638.38 384.2 638.59 388.6 638.78 392.46 638.8 393 638.82 393.28 638.82 393.28 638.83 393.51"
        />
        <polyline
          style={styles.three}
          points="633.5 347.85 633.67 351.31 633.91 356.21 634.15 361.13 634.33 364.58 634.33 364.59 634.52 368.74 634.7 372.31 634.93 377 634.93 377.01 635.13 381.15 635.13 381.16 635.36 385.84 635.58 390.25 635.63 391.21 635.63 391.22 635.64 391.53 635.65 391.67"
        />
        <polyline
          style={styles.three}
          points="632.5 389.86 632.5 389.83 632.48 389.52 632.48 389.51 632.39 387.47 632.39 387.46 632.16 382.78 631.96 378.63 631.72 373.94 631.55 370.36 631.34 366.22 631.34 366.21 631.18 362.84 630.94 357.91 630.68 352.69 630.59 350.81 630.59 350.8"
        />
        <polyline
          style={styles.three}
          points="629.37 388.16 629.36 388.05 629.36 388.04 629.36 387.87 629.19 384.4 628.98 380.25 628.75 375.56 628.58 371.99 628.37 367.84 628.21 364.55 627.97 359.6 627.76 355.27"
        />
        <polyline
          style={styles.three}
          points="625.89 359.6 625.89 359.61 625.95 360.75 626.2 365.71 626.35 368.95 626.55 373.1 626.74 376.67 626.96 381.35 626.96 381.36 627.16 385.5 627.22 386.78 627.22 386.82 627.24 387.06"
        />
        <polyline
          style={styles.three}
          points="654.89 347.45 654.97 349.14 655.17 353.19 655.38 357.34 655.55 360.92 655.78 365.61 655.98 369.76 655.98 369.76 656.21 374.45 656.43 378.85 656.43 378.86 656.62 382.71 656.62 382.72 656.82 386.86 656.97 389.89 657.23 395.13 657.42 399.22"
        />
        <polyline
          style={styles.three}
          points="658 350.38 658.06 351.61 658.27 355.76 658.44 359.33 658.67 364.03 658.88 368.18 659.1 372.87 659.32 377.27 659.51 381.13 659.71 385.28 659.86 388.32 660.12 393.55 660.26 396.55"
        />
        <polyline
          style={styles.three}
          points="661.24 355.05 661.37 357.73 661.6 362.43 661.81 366.58 662.03 371.26 662.26 375.67 662.44 379.53 662.64 383.68 662.8 386.71 663.05 391.95 663.05 392.03 663.05 392.04"
        />
        <path
          style={styles.six}
          d="M381.44,307.82c-.62-1.38-1.45-3.83-1.02-6.82,.19-1.35,.6-2.5,1.02-3.41,6.97-9.62,18.24-22.64,35.61-34.94,24.57-17.4,49.61-25.05,64.61-28.55,2.37-2.27,4.75-4.55,7.12-6.82,7.5-.73,15.85-1.22,24.93-1.28,8.47-.05,16.31,.29,23.4,.85,5.1,1.77,12.5,4.38,21.37,7.67,4.57,1.7,20.32,7.55,28.49,11.08,32.8,14.17,51.68,37.64,52.91,39.2,5.29,6.74,12.5,17.6,17.15,32.62,1.79,1.66,5.27,5.37,6.25,10.84,.42,2.34,.28,4.39,0,5.97,2.92,6.09,5.73,12.92,8.14,20.45,4.49,14.02,6.43,26.79,7.12,37.5,1.02,9.37,2.03,18.75,3.05,28.12,17.93,12.54,40.73,31.4,61.05,57.95,11.21,14.65,20.7,35.9,39.68,78.4,2.91,6.53,7.85,17.95,6.1,32.38-.33,2.71-.81,4.88-1.13,6.16-.53,1.74-1.81,4.64-4.98,6.63-5.46,3.43-14.4,2.82-21.68-1.98-28.05-14.11-56.09-28.22-84.14-42.33-15.8-7.46-34.48-15.18-55.96-22.16-25.45-8.26-48.97-13.57-69.19-17.04-18.67-1.06-38.39-2.71-59.01-5.11-13.02-1.52-25.58-3.24-37.65-5.11-16.54-2.83-29.22-7.42-37.65-11.08-6.76-2.93-16.37-7.18-26.45-15.34-4.03-3.26-7.07-6.28-9.16-8.52-8.48-33.8-16.96-67.61-25.44-101.41-.02-7.52,.71-16.49,3.05-26.42,1.41-5.98,3.19-11.39,5.09-16.19,2.03-1.14,4.07-2.27,6.1-3.41,1.02-3.71,3.02-8.65,7.12-13.64,1.34-1.62,2.72-3.04,4.07-4.26Z"
        />
        <ellipse
          style={styles.six}
          cx="505.06"
          cy="388.78"
          rx="40.19"
          ry="32.38"
        />
        <path
          style={styles.six}
          d="M448.52,375.52c7.94-20.5,30.93-35.32,58.07-35.32,23.86,0,44.52,11.46,54.56,28.17,4.15,6.9,6.49,14.71,6.49,22.97,0,25.68-22.6,46.93-52.04,50.58-.3,.03-.59,.07-.91,.1"
        />
        <path
          style={styles.six}
          d="M448.59,375.14c-6.94,15.8-5.52,40.23,7.12,52.84,14.29,14.24,38.06,19.81,59.88,13.93-5.31,.63-14.07,1.1-24.27-1.15-4.07-.9-20.33-4.86-32.56-17.9-16.31-17.38-10.83-44.28-10.17-47.72Z"
        />
        <path
          style={styles.six}
          d="M665.2,409.14c3.85-3.12,9.17-8.49,11.31-16.11,1.33-4.72,.96-8.62,0-14.49-1.22-7.51-3.34-17.75-7.2-30.01-.02-.05-.03-.1-.05-.15-.01-.02-.02-.04-.03-.06-7.42-14.95-23.95-23.13-36.47-20.9-15.67,2.78-29.39,22.61-19.33,43.46,.71,1.18,1.57,2.91,2.03,5.11,.45,2.12,.38,3.95,.2,5.24,3.6,1.62,7.28,3.37,11,5.26,7.34,3.7,14.9,7.92,22.53,12.73,.03,.02,.05,.03,.08,.05,1.23,.78,2.47,1.58,3.71,2.39"
        />
        <path
          style={styles.six}
          d="M652.99,401.67c5.42-2.05,9.86-6.72,11.16-13.18,.76-3.79,1.21-7.9,1.17-12.27-.07-7.54-.18-18.44-8.14-26.74-.05-.06-.1-.11-.15-.16-7.55-7.74-20.34-6.86-26.73,1.87-.56,.76-1.1,1.56-1.61,2.41-5.65,9.4-6.15,21.42-2.02,32.9"
        />
        <path
          style={styles.six}
          d="M644.63,328.11s-.02,0-.03,0c-2.23-.85-10.27-3.62-19.47-1.11-12.27,3.35-16.45,13.31-17.3,15.34-4.65,11.1,.1,24.71,3.05,24.71"
        />
        <path
          style={styles.six}
          d="M561.15,368.37c.84-.97,1.69-1.94,2.53-2.91,7.8-7.54,19.1-11.12,29.4-9.06,7.24,1.45,11.9,5.28,14,7.28,.31,.7,.85,1.62,1.77,2.52,.34,.33,.69,.61,1.02,.85,1.19,1.28,2.37,2.56,3.56,3.83"
        />
        <path
          style={styles.six}
          d="M615.46,375.99c.07,1.75,.13,3.49,.2,5.24,8.77,4.26,17.9,9.02,27.27,14.36,2.12,1.21,4.2,2.42,6.26,3.63,.03,.02,.07,.04,.1,.06,1.3,.77,2.59,1.54,3.87,2.32,4.16,2.51,8.18,5.03,12.04,7.54,5.8,3.76,11.26,7.49,16.4,11.16"
        />
        <path
          style={styles.six}
          d="M381.44,307.82c9.91,3.33,22.76,8.8,35.61,17.9,6.12,4.33,11.16,8.72,15.26,12.78,7.73,8.28,15.46,16.57,23.18,24.85"
        />
        <ellipse
          style={styles.six}
          cx="446.05"
          cy="325.71"
          rx="8.75"
          ry="5.8"
          transform="translate(-94.61 212.87) rotate(-24.35)"
        />
        <ellipse
          style={styles.six}
          cx="421.63"
          cy="308.67"
          rx="8.46"
          ry="5.99"
          transform="translate(-100.55 289.14) rotate(-34.07)"
        />
        <ellipse
          style={styles.six}
          cx="377.28"
          cy="356.81"
          rx="7.95"
          ry="6.14"
          transform="translate(-110.88 533.85) rotate(-63.34)"
        />
        <ellipse
          style={styles.six}
          cx="369.43"
          cy="339.59"
          rx="7.93"
          ry="5.42"
          transform="translate(-88.6 544.54) rotate(-66.7)"
        />
        <ellipse
          style={styles.six}
          cx="365.02"
          cy="367.53"
          rx="7.14"
          ry="4.81"
          transform="translate(-25.24 708.14) rotate(-86.31)"
        />
        <ellipse
          style={styles.six}
          cx="464.72"
          cy="245.96"
          rx="8.98"
          ry="4.16"
          transform="translate(-51.75 147.37) rotate(-17.08)"
        />
        <path
          style={styles.six}
          d="M520.84,238.97c0,2.32-3.85,4.2-8.61,4.2s-8.61-1.88-8.61-4.2,3.85-4.2,8.61-4.2,8.61,1.88,8.61,4.2Z"
        />
        <path
          style={styles.six}
          d="M542.71,250.29c0,2.82-3.74,5.11-8.35,5.11s-8.35-2.29-8.35-5.11c0-2.82,3.74-5.11,8.35-5.11s8.35,2.29,8.35,5.11Z"
        />
        <path
          style={styles.six}
          d="M548.08,237.94c1.39,2.78-.65,5.54-4.86,5.54s-7.63-2.76-7.63-5.54c0-2.28,2.37-3.84,5.29-3.84s6.07,1.56,7.21,3.84Z"
        />
        <ellipse
          style={styles.six}
          cx="396.19"
          cy="292.48"
          rx="8.46"
          ry="5.99"
          transform="translate(-95.84 272.11) rotate(-34.07)"
        />
      </g>
    </g>
  );
};

export default Doctor;
