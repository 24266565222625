import React from 'react';

const Emote = ({ type }) => {
  const getEmoteContent = (type) => {
    switch (type) {
      case 'fire_flames':
        return {
          content: (
            <img
              src="https://i.imgur.com/esOgZOO.gif" //fire-flames.gif
              alt="Fire Flames"
              style={{
                width: '140%',
                height: '130%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-55%',
          translateY: '-45%',
        };
      case 'fire_eyes':
        return {
          content: (
            <img
              src="https://i.imgur.com/m34v0Bl.gif" //fire-flames.gif
              alt="Fire Flames"
              style={{
                width: '160%',
                height: '160%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-72%',
          translateY: '-22%',
        };
      case 'alex_made_it_rain':
        return {
          content: (
            <img
              src="https://i.imgur.com/jFn6bMr.gif" //alex-made-it-rain.gif
              alt="Alex"
              style={{
                width: '130%',
                height: '130%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-55%',
          translateY: '-10%',
        };
      case 'money_arm':
        return {
          content: (
            <img
              src="https://i.imgur.com/CVBGDXH.gif"
              alt="Alex"
              style={{
                width: '140%',
                height: '140%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-16%',
          translateY: '-10%',
        };
      case 'da_dogg':
        return {
          content: (
            <img
              src="https://i.imgur.com/ExctR2A.gif"
              alt="Alex"
              style={{
                width: '140%',
                height: '140%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-65%',
          translateY: '-15%',
        };
      case 'glytch':
        return {
          content: (
            <img
              src="https://i.imgur.com/QUvFgea.gif"
              alt="Alex"
              style={{
                width: '150%',
                height: '150%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-67%',
          translateY: '-20%',
        };
      // Add more cases for other emotes if needed
      default:
        return null;
    }
  };

  const emoteData = getEmoteContent(type);

  if (!emoteData) return null;

  return (
    <div
      style={{
        position: 'absolute',
        top: '30%',
        left: '45%',
        transform: `translate(${emoteData.translateX}, ${emoteData.translateY})`,
        pointerEvents: 'none', // ensures the emote doesn't interfere with interactions
      }}
    >
      {emoteData.content}
    </div>
  );
};

export default Emote;
