import React from 'react';
import { Grid, Typography } from '@mui/material';
import { card, text } from "../../utils/themeContstants";
import RivoxCoin from '../custom/RivoxCoin';

const XpPerWin = ({ data }) => {
  const styles = {
    container: {
      marginTop: '10px',
      padding: '16px',
      backgroundColor: card,
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    barWrapper: {
      display: 'flex',
      flexDirection: 'column-reverse', // Change direction to column-reverse
      alignItems: 'center',
      marginRight: '3px',
    },
    label: {
      fontSize: '11px',
      fontWeight: 'bold',
      color: text,
      marginBottom: '4px',
      display: 'inline-flex',
      alignItems: 'center',
    },
    bar: {
      width: '20px',
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderRadius: '4px',
      marginBottom: '4px',
    },
    value: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: text,
      marginTop: '4px',
    },
  };

  // Calculate the max XP gain to use for scaling the bar heights
  const maxXP = Math.max(...data.map(item => item["XP Gain per Match"]));

  // Reverse the order of the data array
  const reversedData = [...data].reverse();

  const extractMonetaryValue = (value) => {
    // Assuming the value is a string starting with $
    return value.startsWith('$') ? value.substring(1) : value;
  };
  
  return (
    <div style={styles.container}>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
        {reversedData.map((item, index) => (
          <div key={index} style={styles.barWrapper}>
            <div style={styles.label}>
              <RivoxCoin size={20} /> {extractMonetaryValue(item["Monetary Value of Match"])}
            </div>
            <div style={{ 
              ...styles.bar, 
              height: `${(item["XP Gain per Match"] / maxXP) * 100}px`, // Adjusted calculation for height
              minHeight: '10px', // Ensuring a minimum height so bars are always visible
            }}></div>
            <div style={styles.value}>+{item["XP Gain per Match"]}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default XpPerWin;
