const GraphicBlazer = ({ color }) => {
  const styles = {
    one: {
      stroke: "#c3d4dd",
      strokeWidth: 3,
      strokeMiterLimit: 10,
      fill: "none",
    },
    two: {
      strokeMiterLimit: 10,
      fill: "none",
      stroke: "#000",
    },
    three: {
      strokeMiterLimit: 10,
      stroke: "#000",
      fill: "#2b2929",
      strokeWidth: 2,
    },
    four: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
    },
    five: {
      strokeMiterLimit: 10,
      fill: "none",
      stroke: "#000",
      strokeWidth: 2,
    },
    six: {
      fill: color,
    },
    seven: {
      fill: "#3d3d3d",
    },
    eight: {
      fill: "#a7bac6",
    },
    nine: {
      fill: "#69747c",
    },
    ten: {
      fill: "#59646b",
    },
    eleven: {
      fill: "#858d93",
    },
    twelve: {
      fill: "#c7d8e0",
    },
    thirteen: {
      fill: "#7a542e",
    },
    fourteen: {
      fill: "#8e643f",
    },
  };

  return (
    <g>
      <g id="Blazer_tee_1" data-name="Blazer tee 1">
        <path
          style={styles.six}
          d="M426,707c-4.25,2.21-32.12,17.28-38,48-6.59,34.45,18.96,65.09,43,77,2.09,1.04,9.32,4.48,26,7,15.03,2.27,94.49,14.26,128-32,4.42-6.1,10.29-16.32,13-32-12.57-7.49-39.77-26.21-54-61-4.65-11.36-6.92-22.02-8-31,24.43-7.04,41.24-20.47,42-35,.13-2.52,.06-10.43-17-29-9.82-10.7-25.55-25.23-49.11-38.07,6.01,3.85,7,7.17,7.11,9.07,.51,9.09-17.54,18.05-32,20-2.33,.31-14.09,1.73-27-4-5.91-2.62-15.91-6.96-18-16-.65-2.82-.35-5.33,0-7,2.23-2.53,4.46-5.05,6.69-7.58-26.5,11.95-44.48,34.22-45.69,58.58-.57,11.46,2.68,21.15,6,28,3.43,2.51,8.94,7.27,13,15,6.92,13.18,4.84,25.92,4,30Z"
        />
        <path d="M450.27,570.05c-.01,1.33-.14,2.64-.28,3.95l-.12,.98c-.03,.33-.12,.72-.22,1.06-.2,.71-.51,1.4-.93,2.02-.81,1.27-2.06,2.17-3.12,2.89-.52,.36-.86,.67-1.21,1.11-.31,.44-.6,.92-.79,1.46-.41,1.07-.59,2.29-.62,3.5-.02,.61,0,1.22,.06,1.85,.05,.59,.15,1.15,.32,1.7,.69,2.22,2.35,4.17,4.18,5.87,1.85,1.71,3.95,3.2,6.12,4.54,2.15,1.38,4.47,2.48,6.82,3.51,4.73,1.97,9.73,3.33,14.8,4.05,1.25,.18,2.58,.33,3.76,.38,1.29,0,2.58,.03,3.86,0,2.56-.12,5.11-.35,7.63-.82,5.03-.91,9.97-2.48,14.48-4.86,2.25-1.18,4.45-2.51,6.39-4.11,.49-.39,.94-.83,1.41-1.24,.45-.43,.86-.89,1.29-1.33l.55-.71c.2-.23,.37-.47,.5-.72,.26-.42,.66-1.15,.94-1.65,.57-1.05,.89-2.1,.81-3.18-.05-1.09-.45-2.17-1.07-3.16-1.25-1.99-3.27-3.61-5.41-4.86l.98-1.74c1.2,.67,2.33,1.44,3.39,2.33,1.06,.89,2.04,1.91,2.83,3.13,.78,1.21,1.35,2.65,1.44,4.18,.14,1.54-.35,3.09-1.02,4.33-.35,.65-.58,1.07-.99,1.77-.18,.33-.38,.62-.6,.89l-.65,.84c-.47,.5-.93,1.03-1.43,1.5-.51,.45-1,.93-1.53,1.36-2.09,1.74-4.38,3.17-6.75,4.42-4.75,2.51-9.9,4.14-15.15,5.1-2.63,.49-5.29,.73-7.96,.86-1.33,.04-2.66,0-3.98,.01-1.44-.06-2.71-.21-4.05-.4-10.51-1.56-20.84-5.62-28.88-12.86-1.94-1.86-3.84-4.02-4.72-6.84-.21-.7-.34-1.44-.39-2.15-.05-.68-.07-1.38-.05-2.07,.05-1.39,.25-2.8,.79-4.18,.25-.69,.63-1.35,1.09-1.96,.45-.6,1.11-1.16,1.67-1.53,1.1-.73,2.01-1.41,2.67-2.33,.32-.46,.61-.95,.82-1.48,.11-.28,.18-.52,.27-.84l.26-.95c.37-1.26,.76-2.51,1.26-3.75l.54,.11Z" />
        <g>
          <g>
            <g>
              <path
                style={styles.eight}
                d="M491.32,671.96c4.07,11.56,3.97,21.84,3.37,28.15-3.52-9.27-7.03-18.53-10.55-27.8l-10.34,27.8c-1.03-5.81-2.24-17.63,3.16-30.45,2.13-5.04,4.77-9.05,7.18-12.08,2.44,3.66,5.1,8.46,7.18,14.37Z"
              />
              <path
                style={styles.nine}
                d="M484.14,672.31c3.52,9.27,7.03,18.53,10.55,27.8-.76,.46-2,1.35-3.08,2.87-1.13,1.59-1.55,3.14-1.72,4.02-.77-.52-2.6-1.61-5.17-1.72-3.11-.14-5.35,1.22-6.1,1.72-.19-.94-.66-2.62-1.95-4.31-1.01-1.32-2.12-2.13-2.87-2.59l10.34-27.8Zm3.16,25.5c-1.02-2.21-2.04-4.41-3.05-6.62l-2.84,6.63c.35,.68,.72,1.55,1.01,2.57,.22,.79,.34,1.52,.41,2.15,.43,.09,.97,.16,1.6,.15,.57,0,1.07-.07,1.47-.15-.06-.71-.05-1.95,.54-3.3,.26-.59,.57-1.07,.86-1.44Z"
              />
              <path
                style={styles.eight}
                d="M493.33,714.33c0,2.86-1.36,5.43-3.46,7.09-1.44,1.15-3.23,1.86-5.16,1.96-2.24,.11-4.35-.65-6.02-1.97-2.09-1.66-3.46-4.22-3.46-7.08,0-4.91,4.05-9.05,9.05-9.05s9.05,4.13,9.05,9.05Zm-2.59-.14c0-3.49-2.83-6.32-6.32-6.32s-6.32,2.83-6.32,6.32,2.83,6.32,6.32,6.32,6.32-2.83,6.32-6.32Z"
              />
              <path
                style={styles.thirteen}
                d="M492.18,731.7c.2,.7,.43,1.81,.29,3.17-.1,.96-.34,1.73-.57,2.3-.2-.07-.39-.13-.59-.2-.09-.03-.18-.06-.27-.09-1.24-.41-2.47-.82-3.7-1.24-3.53-1.17-7.06-2.35-10.58-3.53l-.36-.12c-.19-.3-.66-1.15-.57-2.3,.04-.51,.18-.92,.32-1.24,.09-.2,.18-.36,.26-.48,2.62,1.35,6.32,2.84,10.92,3.45,1.53,.2,2.96,.28,4.27,.28,.21,0,.41,0,.61,0Z"
              />
              <path
                style={styles.nine}
                d="M491.9,725.39l-.32,6.31c-2.07-.26-4.62-.75-7.43-1.72-3.21-1.1-5.79-2.49-7.71-3.72l-.03-.6,2-2,2.87,2.01h7.18l1.44-1.44v-.57c.67,.57,1.34,1.15,2.01,1.72Z"
              />
              <path
                style={styles.thirteen}
                d="M491.61,749.81c.07,1.15-.1,2.12-.29,2.8-.26-.19-.52-.37-.78-.56-2.85-2.04-5.71-4.08-8.56-6.13,.77-.5,1.56-1.07,2.35-1.7,1.3,1.2,3.09,2.75,5.84,3.58,.21,.06,.4,.11,.57,.16,.23,.06,.42,.1,.57,.13,.13,.47,.24,1.06,.29,1.72Z"
              />
              <path
                style={styles.nine}
                d="M491.31,736.97l-.56,10.98c-.17-.04-.37-.09-.57-.16-2.75-.82-4.54-2.38-5.84-3.58,.51-.41,1.02-.84,1.53-1.3,2.34-2.12,4.01-4.27,5.17-6.03,.09,.03,.18,.06,.27,.09Z"
              />
              <path
                style={styles.thirteen}
                d="M487.33,735.64c1.24,.41,2.47,.82,3.7,1.24-1.16,1.76-2.83,3.91-5.17,6.03-.51,.47-1.02,.9-1.53,1.3-.8,.63-1.59,1.2-2.35,1.7-1.59,1.04-3.08,1.81-4.35,2.39-.23,.1-.45,.2-.66,.29-.16-.62-.32-1.49-.29-2.52,.02-.8,.15-1.48,.29-2.01,.14-.06,.28-.13,.42-.2,.32-.15,.67-.32,1.02-.51,.9-.47,1.89-1.06,2.92-1.79,.64-.45,1.3-.96,1.96-1.53,1.78-1.53,3.1-3.09,4.05-4.39Z"
              />
              <path
                style={styles.nine}
                d="M490.54,752.04l-.22,4.29-1.32,1.32v.97c-1.32,.2-2.85,.35-4.57,.36-1.85,0-3.49-.15-4.88-.36v-.79l-1.44-.56-.49-8.98c1.27-.57,2.77-1.35,4.35-2.39,2.86,2.04,5.71,4.08,8.56,6.13Z"
              />
              <path
                style={styles.nine}
                d="M489.89,723.67v.57l-1.44,1.44h-7.18l-2.87-2.01v-2.23h.28l.02-.03c1.66,1.32,3.78,2.08,6.02,1.97,1.93-.09,3.72-.81,5.16-1.96v.02s.01,2.23,.01,2.23Z"
              />
              <path
                style={styles.thirteen}
                d="M484.14,729.99c2.81,.97,5.36,1.46,7.43,1.72-1.31,0-2.74-.08-4.27-.28-4.6-.61-8.3-2.09-10.92-3.45-.07,.12-.17,.28-.26,.48-.03-.24-.04-.5-.03-.77,.02-.59,.14-1.09,.26-1.46l.07,.04c1.92,1.22,4.51,2.61,7.71,3.72Z"
              />
              <path
                style={styles.nine}
                d="M476.75,732.12c3.53,1.18,7.06,2.35,10.58,3.53-.95,1.3-2.27,2.86-4.05,4.39-.66,.57-1.32,1.07-1.96,1.53-1.17-.97-2.58-2.06-4.24-3.25l-.34-6.2Z"
              />
              <path
                style={styles.thirteen}
                d="M481.32,741.57c-1.03,.73-2.02,1.32-2.92,1.79-.36-.26-.73-.52-1.09-.78-.22-.15-.43-.31-.64-.46-.19-.61-.38-1.53-.28-2.65,.05-.56,.16-1.04,.28-1.44,.14,.1,.28,.2,.41,.29,1.66,1.18,3.06,2.27,4.24,3.25Z"
              />
            </g>
            <g>
              <path
                style={styles.five}
                d="M473.8,700.11c-1.03-5.81-2.24-17.63,3.16-30.45,2.13-5.04,4.77-9.05,7.18-12.08,2.44,3.66,5.1,8.46,7.18,14.37,4.07,11.56,3.97,21.84,3.37,28.15-.76,.46-2,1.35-3.08,2.87-1.13,1.59-1.55,3.14-1.72,4.02"
              />
              <polyline
                style={styles.five}
                points="478.68 721.43 478.4 721.43 478.4 723.67 481.27 725.68 488.45 725.68 489.89 724.24 489.89 723.67 489.89 721.44"
              />
              <line
                style={styles.five}
                x1="477.38"
                y1="743.86"
                x2="477.31"
                y2="742.58"
              />
              <line
                style={styles.five}
                x1="491.31"
                y1="736.97"
                x2="490.75"
                y2="747.95"
              />
              <path
                style={styles.five}
                d="M477.62,748.3l.49,8.98,1.44,.56v.79c1.39,.22,3.04,.37,4.88,.36,1.72,0,3.25-.16,4.57-.36v-.97l1.32-1.32,.22-4.29"
              />
              <polyline
                style={styles.five}
                points="476.43 726.27 476.4 725.67 478.4 723.67"
              />
              <line
                style={styles.five}
                x1="477.08"
                y1="738.32"
                x2="476.75"
                y2="732.12"
              />
              <path
                style={styles.five}
                d="M489.89,723.67c.67,.57,1.34,1.15,2.01,1.72l-.32,6.31"
              />
              <path
                style={styles.five}
                d="M491.03,736.88c.09,.03,.18,.06,.27,.09,.2,.06,.39,.13,.59,.2,.23-.56,.48-1.34,.57-2.3,.14-1.36-.09-2.48-.29-3.17-.2,0-.4,0-.61,0-1.31,0-2.74-.08-4.27-.28-4.6-.61-8.3-2.09-10.92-3.45-.07,.12-.17,.28-.26,.48-.14,.32-.28,.74-.32,1.24-.09,1.15,.39,2,.57,2.3l.36,.12c3.53,1.18,7.06,2.35,10.58,3.53"
              />
              <path
                style={styles.five}
                d="M477.38,743.86c-.14,.07-.28,.14-.42,.2-.14,.53-.26,1.22-.29,2.01-.03,1.03,.12,1.9,.29,2.52,.21-.09,.44-.19,.66-.29,1.27-.57,2.77-1.35,4.35-2.39,.77-.5,1.56-1.07,2.35-1.7,.51-.41,1.02-.84,1.53-1.3,2.34-2.12,4.01-4.27,5.17-6.03-1.24-.41-2.47-.82-3.7-1.24-.95,1.3-2.27,2.86-4.05,4.39-.66,.57-1.32,1.07-1.96,1.53-1.03,.73-2.02,1.32-2.92,1.79-.35,.19-.7,.36-1.02,.51Z"
              />
              <path
                style={styles.five}
                d="M478.4,743.36c-.36-.26-.73-.52-1.09-.78-.22-.15-.43-.31-.64-.46-.19-.61-.38-1.53-.28-2.65,.05-.56,.16-1.04,.28-1.44,.14,.1,.28,.2,.41,.29,1.66,1.18,3.06,2.27,4.24,3.25"
              />
              <path
                style={styles.five}
                d="M484.33,744.22c1.3,1.2,3.09,2.75,5.84,3.58,.21,.06,.4,.11,.57,.16,.23,.06,.42,.1,.57,.13,.13,.47,.24,1.06,.29,1.72,.07,1.15-.1,2.12-.29,2.8-.26-.19-.52-.37-.78-.56-2.85-2.04-5.71-4.08-8.56-6.13"
              />
              <path
                style={styles.five}
                d="M476.13,728.45c-.03-.24-.04-.5-.03-.77,.02-.59,.14-1.09,.26-1.46l.07,.04c1.92,1.22,4.51,2.61,7.71,3.72,2.81,.97,5.36,1.46,7.43,1.72"
              />
              <path
                style={styles.five}
                d="M489.87,721.42c2.1-1.67,3.46-4.24,3.46-7.09,0-4.92-4.06-9.05-9.05-9.05s-9.05,4.14-9.05,9.05c0,2.86,1.37,5.41,3.46,7.08,1.66,1.32,3.78,2.08,6.02,1.97,1.93-.09,3.72-.81,5.16-1.96Z"
              />
              <circle style={styles.five} cx="484.43" cy="714.19" r="6.32" />
              <path
                style={styles.five}
                d="M494.69,700.11c-3.52-9.27-7.03-18.53-10.55-27.8l-10.34,27.8c.75,.46,1.87,1.27,2.87,2.59,1.29,1.69,1.76,3.37,1.95,4.31,.74-.5,2.98-1.87,6.1-1.72,2.57,.12,4.4,1.2,5.17,1.72,.27-1.08,.88-2.85,2.3-4.6,.87-1.07,1.78-1.8,2.51-2.3Z"
              />
              <path
                style={styles.five}
                d="M486.44,699.25c-.59,1.35-.6,2.59-.54,3.3-.41,.08-.9,.14-1.47,.15-.62,0-1.17-.06-1.6-.15-.07-.63-.19-1.36-.41-2.15-.28-1.03-.65-1.89-1.01-2.57l2.84-6.63c1.02,2.21,2.04,4.41,3.05,6.62-.29,.36-.6,.84-.86,1.44Z"
              />
            </g>
          </g>
          <path
            style={styles.twelve}
            d="M473.8,699.7c-.88-5.07-1.82-14.66,1.72-25.73,2.36-7.39,5.9-12.87,8.62-16.39-2.47,4.43-5.16,10.21-7.18,17.25-2.85,9.9-3.32,18.61-3.16,24.87Z"
          />
          <path
            style={styles.twelve}
            d="M494.69,699.7c.5-5.87,.53-15.13-2.8-25.73-2.18-6.94-5.17-12.43-7.76-16.39,2.32,4.94,4.69,10.91,6.61,17.82,2.56,9.25,3.6,17.57,3.95,24.29Z"
          />
          <polygon
            style={styles.eleven}
            points="473.8 700.11 484.14 675.68 494.69 700.41 484.14 672.09 473.8 700.11"
          />
          <circle style={styles.one} cx="484.28" cy="714.33" r="8.62" />
          <path
            style={styles.fourteen}
            d="M475.8,730.09c1.87,.95,3.98,1.9,6.33,2.77,3.75,1.39,7.22,2.25,10.22,2.79-.15,.51-.3,1.01-.46,1.52l-15.8-5.46-.29-1.62Z"
          />
          <path
            style={styles.fourteen}
            d="M476.75,747.51c2.44-1.15,5.66-3.02,8.83-6.03,1.82-1.72,3.23-3.45,4.31-4.98,.38,.16,.77,.32,1.15,.47-1.18,1.74-2.85,3.86-5.17,5.94-3.27,2.94-6.54,4.67-8.9,5.68l-.21-1.09Z"
          />
          <path
            style={styles.fourteen}
            d="M491.63,750.82l-.3,1.78-9.34-6.69,1.03-.71c1.21,1.17,2.82,2.51,4.87,3.74,1.34,.81,2.61,1.41,3.75,1.88Z"
          />
          <path
            style={styles.fourteen}
            d="M476.36,740.31c.36,.48,1.02,1.2,2.04,1.74,.51,.27,.99,.43,1.38,.53-.37,.21-.75,.43-1.12,.64l-1.99-1.1-.31-1.8Z"
          />
          <polygon
            style={styles.ten}
            points="479.55 757.56 489 757.66 489 758.64 479.55 758.64 479.55 757.56"
          />
          <polygon
            style={styles.ten}
            points="478.07 727.25 491.9 727.25 491.9 725.39 490.28 723.78 488.58 725.48 481.41 725.48 478.4 723.67 476.4 725.67 478.07 727.25"
          />
          <g>
            <path
              style={styles.five}
              d="M473.8,700.11c-1.03-5.81-2.24-17.63,3.16-30.45,2.12-5.04,4.77-9.05,7.18-12.08,2.44,3.66,5.1,8.46,7.18,14.37,4.07,11.56,3.97,21.84,3.37,28.15-.76,.46-2.01,1.35-3.08,2.87-1.13,1.59-1.55,3.14-1.72,4.02-.77-.52-2.6-1.61-5.17-1.72-3.11-.14-5.35,1.22-6.09,1.72-.19-.94-.66-2.62-1.95-4.31-1-1.32-2.12-2.13-2.87-2.59Z"
            />
            <polyline
              style={styles.five}
              points="478.68 721.43 478.4 721.43 478.4 723.67 481.27 725.68 488.45 725.68 489.89 724.24 489.89 723.67 489.89 721.44"
            />
            <line
              style={styles.five}
              x1="477.38"
              y1="743.87"
              x2="477.31"
              y2="742.58"
            />
            <line
              style={styles.five}
              x1="491.31"
              y1="736.97"
              x2="490.75"
              y2="747.95"
            />
            <path
              style={styles.five}
              d="M477.62,748.3l.49,8.98,1.44,.56v.79c1.39,.22,3.04,.37,4.88,.36,1.72,0,3.25-.16,4.57-.36v-.97l1.32-1.32,.22-4.29"
            />
            <polyline
              style={styles.five}
              points="476.43 726.27 476.4 725.67 478.4 723.67"
            />
            <line
              style={styles.five}
              x1="477.08"
              y1="738.32"
              x2="476.75"
              y2="732.12"
            />
            <path
              style={styles.five}
              d="M489.89,723.67c.67,.57,1.34,1.15,2.01,1.72l-.32,6.31"
            />
            <path
              style={styles.five}
              d="M476.39,727.97c-.19,.3-.51,.9-.57,1.72-.09,1.15,.39,2,.57,2.3,4.88,1.63,9.77,3.26,14.65,4.88,.29,.1,.57,.19,.86,.29,.23-.56,.48-1.34,.57-2.3,.14-1.36-.09-2.48-.29-3.17-1.47,.03-3.11-.04-4.88-.27-4.6-.61-8.3-2.1-10.92-3.45Z"
            />
            <path
              style={styles.five}
              d="M476.96,744.06c-.14,.53-.26,1.21-.29,2.01-.03,1.03,.12,1.9,.29,2.52,2.35-1,5.63-2.72,8.9-5.68,2.34-2.12,4.01-4.27,5.17-6.03l-3.7-1.23c-.95,1.3-2.27,2.86-4.05,4.39-2.31,1.99-4.59,3.24-6.32,4.02Z"
            />
            <path
              style={styles.five}
              d="M478.4,743.36c-.36-.26-.73-.52-1.09-.78-.22-.15-.43-.31-.64-.46-.19-.61-.38-1.53-.28-2.65,.05-.56,.16-1.04,.28-1.44,.14,.1,.28,.2,.41,.29,1.66,1.18,3.06,2.27,4.24,3.25"
            />
            <path
              style={styles.five}
              d="M484.33,744.22c1.3,1.2,3.09,2.75,5.84,3.58,.21,.06,.4,.11,.57,.16,.23,.06,.42,.1,.57,.13,.13,.47,.24,1.06,.29,1.72,.07,1.15-.1,2.12-.29,2.8-.26-.19-.52-.37-.78-.56-2.85-2.04-5.71-4.08-8.56-6.13"
            />
            <path
              style={styles.five}
              d="M476.13,728.45c-.03-.24-.04-.5-.03-.77,.02-.59,.14-1.09,.26-1.46l.07,.04c1.92,1.22,4.51,2.61,7.71,3.72,2.81,.97,5.36,1.46,7.43,1.72"
            />
            <path
              style={styles.five}
              d="M484.28,705.28c-5,0-9.05,4.14-9.05,9.05,0,5.13,4.42,9.29,9.48,9.05,4.74-.22,8.62-4.23,8.62-9.05,0-4.92-4.06-9.05-9.05-9.05Zm.14,15.22c-3.49,0-6.32-2.83-6.32-6.32s2.83-6.32,6.32-6.32,6.32,2.83,6.32,6.32-2.83,6.32-6.32,6.32Z"
            />
            <path
              style={styles.five}
              d="M484.14,672.31l-10.34,27.8c.75,.46,1.87,1.27,2.87,2.59,1.29,1.68,1.76,3.37,1.95,4.31,.74-.5,2.98-1.87,6.09-1.72,2.57,.12,4.4,1.2,5.17,1.72,.27-1.08,.88-2.85,2.3-4.6,.87-1.06,1.78-1.8,2.51-2.3-3.52-9.27-7.04-18.53-10.55-27.8Zm2.3,26.94c-.59,1.35-.6,2.59-.54,3.3-.41,.08-.9,.15-1.47,.15-.62,0-1.17-.06-1.6-.15-.07-.63-.19-1.36-.41-2.15-.29-1.03-.66-1.89-1.01-2.57l2.83-6.63c1.02,2.21,2.04,4.41,3.05,6.62-.29,.36-.6,.84-.86,1.44Z"
            />
          </g>
        </g>
        <path
          style={styles.seven}
          d="M667,713c-3.77-22.78-8.85-43.09-14.21-60.6-10.29-10.92-23.72-23.1-40.79-34.4-38.48-25.48-76.44-33.93-100.11-37.07,3.08,1.97,4.83,3.8,5.84,5.38,1.01-.55,2.26-.77,3.73-.56,6.72,.92,11.83,6.29,7.78,12.96-.28,.46-.59,.91-.91,1.35,.9,.8,1.5,1.99,1.5,3.56,0,1.45,.02,2.89,.03,4.34,1.32,.85,2.23,2.23,2.06,4.01-.43,4.31-1.04,8.57-1.78,12.81,.25,11.73,.51,23.46,.22,35.19-.09,3.64-3.17,5.27-5.93,4.73-1.2,12.78-2.56,25.54-4.59,38.21,2.85,.74,5.14,4.41,3.57,7.58-1.56,3.17-3.52,6.08-5.58,8.93,3.22,.04,6.15,4.27,4.54,7.76-1.41,3.07-2.9,6.08-4.59,8.98,2.58,12.94,2.88,26.67-1.57,39.23-.28,.8-.58,1.6-.89,2.39-.08,.77-.18,1.54-.28,2.31,1.07,1.39,1.33,3.36,.77,5.04,.93,1.23,1.36,2.81,.8,4.24-1.07,2.75-2.13,6.4-3.66,9.68,.07,.83-.05,1.67-.34,2.44-.22,1.1-.43,2.27-.6,3.51-.17,1.23-.3,2.41-.39,3.53-.11,.97-.47,1.9-1.07,2.65-.04,5.03-.21,10.31-.54,15.81-.31,5.02-.73,9.85-1.24,14.45,25.24-.77,67.49-5.62,112.24-29.45,22.06-11.75,39.25-25.2,52.05-37.01-.56-18.31-2.28-39.19-6.05-61.99Z"
        />
        <path
          style={styles.seven}
          d="M451.11,834.74c.27-2.46,.53-4.92,.77-7.38-.15-5.82-.41-11.64-.62-17.46-.58-4.42-.84-8.89-2.1-13.19-1.35-4.59-2.37-8.76-1.87-13.33-1.02-2.75-1.95-5.53-1.82-8.63,.06-1.33,.53-2.38,1.25-3.16-.02-.18-.04-.36-.06-.54-.68-.77-1.13-1.8-1.19-3.08-.71-15.77-5.23-30.98-2.21-46.71-2.8-12.77-5.37-26.1-1.52-38.62,.16-.51,.39-.95,.65-1.35-1.24-8.72-2.73-17.35-4.58-26.01-2.3-10.84-4.47-21.76-4.6-32.88-.02-1.71,.67-2.96,1.69-3.76-1.24-10.73,.69-21.32,1.96-32.24,.2-1.68,1.05-2.91,2.17-3.7,1.2-1.83,3.06-2.95,5.84-2.96,1.27-1.44,2.54-2.87,3.81-4.31-15,3.92-31.42,9.26-48.69,16.58-41.74,17.69-73.46,40.6-95.85,60.09-2.99,8.83-5.78,18.48-8.15,28.91-5.61,24.73-7.29,47.17-7.22,65.93,12.73,15.49,32.6,36.01,61.22,54.07,39.56,24.98,77.37,33.6,100.22,36.95,.3-1.07,.59-2.14,.89-3.21Z"
        />
        <path d="M450.27,570.05c-.01,1.33-.14,2.64-.28,3.95l-.12,.98c-.03,.33-.12,.72-.22,1.06-.2,.71-.51,1.4-.93,2.02-.81,1.27-2.06,2.17-3.12,2.89-.52,.36-.86,.67-1.21,1.11-.31,.44-.6,.92-.79,1.46-.41,1.07-.59,2.29-.62,3.5-.02,.61,0,1.22,.06,1.85,.05,.59,.15,1.15,.32,1.7,.69,2.22,2.35,4.17,4.18,5.87,1.85,1.71,3.95,3.2,6.12,4.54,2.15,1.38,4.47,2.48,6.82,3.51,4.73,1.97,9.73,3.33,14.8,4.05,1.25,.18,2.58,.33,3.76,.38,1.29,0,2.58,.03,3.86,0,2.56-.12,5.11-.35,7.63-.82,5.03-.91,9.97-2.48,14.48-4.86,2.25-1.18,4.45-2.51,6.39-4.11,.49-.39,.94-.83,1.41-1.24,.45-.43,.86-.89,1.29-1.33l.55-.71c.2-.23,.37-.47,.5-.72,.26-.42,.66-1.15,.94-1.65,.57-1.05,.89-2.1,.81-3.18-.05-1.09-.45-2.17-1.07-3.16-1.25-1.99-3.27-3.61-5.41-4.86l.98-1.74c1.2,.67,2.33,1.44,3.39,2.33,1.06,.89,2.04,1.91,2.83,3.13,.78,1.21,1.35,2.65,1.44,4.18,.14,1.54-.35,3.09-1.02,4.33-.35,.65-.58,1.07-.99,1.77-.18,.33-.38,.62-.6,.89l-.65,.84c-.47,.5-.93,1.03-1.43,1.5-.51,.45-1,.93-1.53,1.36-2.09,1.74-4.38,3.17-6.75,4.42-4.75,2.51-9.9,4.14-15.15,5.1-2.63,.49-5.29,.73-7.96,.86-1.33,.04-2.66,0-3.98,.01-1.44-.06-2.71-.21-4.05-.4-10.51-1.56-20.84-5.62-28.88-12.86-1.94-1.86-3.84-4.02-4.72-6.84-.21-.7-.34-1.44-.39-2.15-.05-.68-.07-1.38-.05-2.07,.05-1.39,.25-2.8,.79-4.18,.25-.69,.63-1.35,1.09-1.96,.45-.6,1.11-1.16,1.67-1.53,1.1-.73,2.01-1.41,2.67-2.33,.32-.46,.61-.95,.82-1.48,.11-.28,.18-.52,.27-.84l.26-.95c.37-1.26,.76-2.51,1.26-3.75l.54,.11Z" />
        <path
          style={styles.three}
          d="M446,577c-1.07,1.08-2.57,2.74-4,5-.91,1.44-4.4,5.86-5,20-.25,5.87-1,11.61-.5,19.5,.32,5.01,.92,9.26,1.5,12.5,2.37,23.87,4.4,48.55,6,74,1.76,28.04,2.89,55.23,3.5,81.5-13.38-32.88-26.15-73.35-33.5-120.5-2.82-18.11-4.56-35.34-5.5-51.5,5.67,3.67,11.33,7.33,17,11-1.12-4.79-2.06-10.32-2.5-16.5-.81-11.54,.42-21.46,2-29,2.05-1.04,4.38-2.08,7-3,5.28-1.85,10.1-2.66,14-3Z"
        />
        <path
          style={styles.three}
          d="M516,583c1.03,.8,2.55,2.1,4,4,4.19,5.47,4.64,11.63,5,20,.25,5.75,.82,11.34,.5,19.5-.27,6.75-.91,12.4-1.5,16.5-2.38,22.52-4.41,45.87-6,70-1.86,28.16-2.98,55.35-3.5,81.5,13.38-32.88,26.15-73.35,33.5-120.5,2.82-18.11,4.56-35.34,5.5-51.5-5.67,3.67-11.33,7.33-17,11,1.12-4.79,2.06-10.32,2.5-16.5,.81-11.54-.42-21.46-2-29-1.94-1.05-4.28-2.12-7-3-5.52-1.78-10.45-2.09-14-2Z"
        />
        <path
          style={styles.five}
          d="M447.5,789.5c1.01,5.1,1.88,10.61,2.5,16.5,1.25,11.9,1.19,22.69,.52,32.01"
        />
        <path
          style={styles.five}
          d="M514.5,794.5c-1.32,5.08-2.53,10.59-3.5,16.5-1.85,11.24-2.45,21.52-2.41,30.48"
        />
        <circle style={styles.four} cx="439" cy="799" r="5" />
        <circle style={styles.four} cx="434" cy="821" r="5" />
        <rect x="516" y="799" width="16" height="2" rx="1" ry="1" />
        <rect x="516" y="814" width="16" height="2" rx="1" ry="1" />
        <polygon
          style={styles.two}
          points="618.28 688.5 556.5 688.5 556.5 673.5 618.67 673.5 618.28 688.5"
        />
      </g>
      <g id="Shoulder">
        <g>
          <path d="M289.61,748.67c-.91-1.03-1.83-2.05-2.71-3.1-.16-.19-.28-.51-.27-.76,.05-2.47,.11-4.94,.2-7.41,.05-1.47,.14-2.94,.27-4.41,.38-4.26,.69-8.53,1.2-12.78,.63-5.25,1.33-10.5,2.18-15.72,.92-5.63,2.04-11.23,3.07-16.84,.73-3.99,1.22-8.03,2.23-11.95,1.74-6.77,3.67-13.51,5.8-20.17,.76-2.37,2.14-4.61,3.59-6.66,1.05-1.49,2.62-2.65,4.07-3.82,6.08-4.88,12.09-9.86,18.34-14.52,4.77-3.55,9.8-6.77,14.82-9.97,13.26-8.43,27.33-15.36,41.35-22.38,13.87-6.94,28.29-12.49,43.1-17.07,6.81-2.11,13.66-4.07,20.67-5.5-.25,1.22-.39,1.42-1.42,1.69-13.06,3.43-25.64,8.26-38.17,13.21-8.35,3.3-16.39,7.25-24.35,11.37-6.67,3.45-13.39,6.81-19.95,10.47-6.61,3.68-13.14,7.53-19.55,11.57-8.21,5.17-16.25,10.61-24.38,15.91-4.23,2.76-8.05,6.02-11.92,9.25-1.81,1.51-2.89,3.25-3.44,5.46-1.94,7.73-3.97,15.45-5.84,23.2-1.14,4.69-2.15,9.42-3.07,14.16-.85,4.41-1.53,8.86-2.21,13.3-.68,4.45-1.32,8.9-1.89,13.37-.27,2.12-.34,4.26-.52,6.39-.31,3.75-.63,7.5-.94,11.25-.05,.56,0,1.12-.02,1.68-.07,2.35-.19,4.71-.22,7.06-.01,1.19,.13,2.38,.21,3.57-.08,.05-.15,.1-.23,.16Z" />
          <path d="M671.16,776.11c0-4.67,.12-9.14-.03-13.6-.17-4.96-.57-9.92-.92-14.88-.2-2.89-.43-5.77-.74-8.65-.31-2.86-.75-5.71-1.14-8.57-.48-3.48-.94-6.96-1.49-10.43-.6-3.77-1.25-7.53-1.97-11.28-1.13-5.83-2.24-11.66-3.55-17.45-1.76-7.74-3.63-15.46-5.61-23.15-1.27-4.93-3.11-9.67-5.69-14.09-.28-.47-.65-.91-1.05-1.29-2.43-2.31-4.84-4.63-7.32-6.88-3.68-3.35-7.31-6.75-11.14-9.92-5.38-4.47-10.81-8.9-16.42-13.08-11.22-8.36-23.42-15.08-36.09-20.98-9.9-4.61-20-8.7-30.39-12.06-7.93-2.57-16.05-4.38-24.18-6.14-4.02-.87-8.05-1.74-12.06-2.64-.64-.14-1.24-.44-1.85-.66l.06-.31c2.3,.29,4.62,.48,6.9,.9,4.7,.87,9.37,1.93,14.07,2.79,7,1.29,13.88,3.04,20.66,5.17,8.46,2.65,16.72,5.82,24.85,9.37,9.84,4.3,19.41,9.09,28.57,14.7,7.87,4.82,15.45,10.07,22.57,15.95,4.99,4.12,9.86,8.38,14.76,12.61,2.38,2.06,4.85,4.05,6.96,6.36,1.57,1.73,3.02,3.79,3.84,5.96,4.11,10.81,7.26,21.92,9.88,33.18,1.93,8.31,3.77,16.64,5.47,25,1.07,5.28,1.85,10.63,2.69,15.96,.7,4.44,1.34,8.9,1.89,13.36,.36,2.91,.59,5.84,.74,8.76,.37,7.24,.63,14.48,.99,21.71,.05,1.07-.28,1.82-1.08,2.46-.68,.54-1.33,1.11-2.17,1.81Z" />
        </g>
      </g>
    </g>
  );
};

export default GraphicBlazer;
