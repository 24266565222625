const BulletVest = ({ skinColor }) => {
  const styles = {
    one: {
      fill: "#4c4c4c",
    },
    two: {
      fill: "#333",
    },
    three: {
      fill: skinColor,
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    five: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    six: {
      fill: "#3a3a3a",
    },
    seven: {
      fill: "#212121",
    },
    eight: {
      fill: "#5b5b5b",
    },
  };

  return (
    <g>
      <path
        style={styles.three}
        d="m288.78,746.86c-.4-14.89.38-33.27,4.22-53.91,2.91-15.7,6.98-29.41,11.15-40.89,18.82-16.26,44.95-35.52,78.85-52.06,24.12-11.77,46.61-19.45,65.69-24.57,21.07,1.84,42.13,3.67,63.2,5.51,19.71,2.58,50.2,9.07,83.11,27.06,26.46,14.46,45.41,31.41,57.79,44.37,4.94,15.77,9.6,33.7,13.21,53.58,4.64,25.54,6.56,48.82,7.05,68.96-14.2,12.19-36.17,28.56-66.05,42-51.38,23.12-97.39,24.63-120,23.99-46.92-1.33-82.48-14.45-100-21.99-49.98-21.53-81.86-53.17-98.22-72.04Z"
      />
      <path
        style={styles.three}
        d="m518.87,533.53c-1.93,5.66-3.14,10.23-3.91,13.46-.37,1.56-.46,2.04-1.83,7.96-1.43,6.18-1.46,6.21-1.7,7.43-.91,4.8-1.06,8.01-1.17,10.7-.26,6.12.32,7.46,1.17,8.35.49.51,1.02.81,1.4.98,5.26,4.47,5.85,8.04,5.77,9.96-.35,9.12-16.94,15.17-27,16.97-23.01,4.12-44.54-8.01-48-17.09-.73-1.93-2.88-7.57-.23-11.22.48-.66,1.01-1.11,1.37-1.37.58-.37,1.48-1.02,2.38-2.02,0,0,1.01-.93,1.57-2.22,1.09-2.53,5.86-23.75,11.97-52.88,7.52,3.12,17.19,6.39,28.72,8.56,11.36,2.13,21.42,2.6,29.48,2.43Z"
      />
      <path d="m460,523c1.9,1.69,3.9,3.36,6,5,15.78,12.32,32.48,19.14,47,23,1.96-5.48,3.91-10.96,5.87-16.44-4.61.02-9.68-.16-15.13-.64-.79-.07-6.86-.61-12.73-1.62-12.45-2.13-23.75-6.29-31-9.31Z" />
      <g>
        <path d="m460.98,525.94s-11.31,49.7-11.31,49.7c0,0-.13.58-.13.58-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74l11.31-49.7s1.95.44,1.95.44h0Z" />
        <path d="m518.91,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59,0,0,1.88.69,1.88.69h0Z" />
      </g>
      <g>
        <path
          style={styles.two}
          d="m335.94,789.96c20.6,14.66,70.46,45.94,141.52,50.48,84.05,5.38,145.21-30,165.75-43.14-2.34-17.95-4.67-35.9-7.01-53.84-12.45-14.53-32.74-42.21-43.11-82.37-7.26-28.09-7.13-52.48-5.67-69.09-.07-.74-.54-5.06-4.18-7.6-1.48-1.04-2.98-1.42-3.98-1.58v-7.4c-5.04-2.43-11.39-4.8-18.91-6.16-22.5-4.06-40.99,3.59-49.04,7.57.33,2.18,2.13,15.63-7.17,26.22-1.43,1.63-6.3,6.8-14.23,8.93-16.17,4.35-36.12-6.37-41.33-22.3-1.84-5.62-1.5-10.82-.87-14.41-2.93-.41-7.31-1.13-12.54-2.42-5.81-1.43-7.64-2.32-11.57-3.43-4.62-1.3-13.87-3.8-24.62-2.24-5.24.76-12.61,1.83-16.34,7.63-1.31,2.03-1.8,4.07-2.01,5.51-2.09,1.62-4.18,3.24-6.27,4.86l-4.31,68.87-15.77,54.17c-2.49,3.05-5.45,7.28-8,12.72-2.83,6.04-4.21,11.54-4.92,15.68-2.55,7.17-5.1,14.35-7.65,21.52l2.25,31.83Z"
        />
        <path d="m335.94,789.96c.16-10.71-1.04-21.36-3.27-31.82,1.03-7.08,4.61-16.78,7.68-21.68,1.34-9.69,5.56-18.95,11.97-26.34,8.41-17.96,15.27-40.1,16.65-60.99,1.31-18.52,3.27-45.22,4.37-64.02,0,0,.03-.39.03-.39,0,0,.28-.27.28-.27,0,0,6.13-5.87,6.13-5.87,0,0-.29.51-.29.51.41-2.03,1.33-3.99,2.64-5.62,6.69-7.75,18.63-7.66,28-7.23,3.94.28,7.86.75,11.75,1.55,8.77,3.01,17.8,5.23,26.96,6.64l1.11.17s-.3,1.08-.3,1.08c-2.55,8.39-.37,17.19,5.66,23.53,11.08,12.17,32.94,18.11,45.79,5.47,6.01-6.1,8.8-14.88,9.32-23.31.01.38-.21-4.63-.2-4.58,0,0-.03-.66-.03-.66,0,0,.59-.29.59-.29,15.96-7.89,34.54-10.77,52.03-7.01,6.02,1.26,11.86,3.29,17.41,5.92,0,0,.03.55.03.55,0,0,.19,3.79.19,3.79-.08,1.05-.09,2.71-.19,3.79,0,0,0,.03,0,.03,0,0-.89-1.1-.89-1.1,11.37,1.59,10.51,18.26,9.39,26.09-.5,17.38,1.26,34.8,5.4,51.68,4.21,16.83,10.71,33.11,19.39,48.14,5.79,10.02,12.52,19.5,20.08,28.26,0,0-.11-.12-.11-.12,1.49,1.4,2.71,3.12,3.49,5.06.89,1.83,1.01,4.51.89,6-.1,6.32.19,12.65.71,18.95.87,10.47,2.58,21.09,4.61,31.4-4.66-11.97-7.13-24.74-7.99-37.54-.26-4.28-.37-8.57-.33-12.86.36-3.37-1.07-6.65-3.56-8.91-23.27-26.59-38.58-59.98-43.48-94.99-1.67-11.67-2.2-23.5-1.65-35.26,1.19-7.39,2.28-23.43-7.99-24.01,0,0,.06-1.01.06-1.01,0,0,0-.03,0-.03-.01-.75.21-2.75.18-3.58,0,0-.18-3.58-.18-3.58l.57.8c-5.25-2.45-10.77-4.33-16.44-5.52-11.34-2.41-23.22-2.05-34.44.84-5.63,1.41-11.08,3.47-16.29,6.01l.56-.94s.2,4.67.2,4.67c-.24,4.55-1.15,9.01-2.75,13.26-3.01,8.55-9.97,15.98-18.96,18.15-16.96,4.21-38.77-6.82-43.45-24.09-1.09-4.49-.75-9.2.46-13.55l.81,1.26c-9.28-1.43-18.42-3.68-27.3-6.73-3-.54-7.66-1.26-11.24-1.44-9.82-.5-25.72-.46-28.55,11.27,0,0-.06.29-.06.29,0,0-.23.22-.23.22,0,0-6.13,5.87-6.13,5.87,0,0,.31-.66.31-.66-1.02,18.96-3.03,45.41-4.38,64.07-1.56,21.47-7.87,42.47-16.98,61.9-6.22,7.25-10.44,16.22-11.68,25.72-3.73,6.62-6.33,13.88-7.63,21.37,1.83,8.48,2.2,21.66,1.26,31.66h0Z" />
        <path
          style={styles.five}
          d="m380.48,579.3l8.22,57.02c.36.51,1.11,1.4,2.35,2.07,1.59.85,3.08.83,3.72.78,2.15-.14,4.44-.38,6.85-.78,6.5-1.08,12.02-2.96,16.43-4.89.66-.58,1.64-1.59,2.35-3.13,1.23-2.68.79-5.17.59-6.07-3.33-15.98-6.65-31.96-9.98-47.93-.09-1.05-.1-2.83.59-4.89.61-1.83,1.53-3.13,2.17-3.91"
        />
        <path
          style={styles.five}
          d="m549.33,568.24c.64.52,2.66,2.3,3.32,5.39.52,2.46-.08,4.45-.39,5.28-2.93,12.26-5.87,24.52-8.8,36.78-.64,1.51-1.72,4.62-1.37,8.61.3,3.47,1.55,6.05,2.35,7.43,3.4,1.69,7.46,3.42,12.13,4.89,3.74,1.18,7.23,1.99,10.37,2.56.55-.03,2.31-.2,3.91-1.58,1.64-1.41,2.05-3.18,2.15-3.72,2.82-12.22,4.99-25.84,5.87-40.7.21-3.53.34-6.99.39-10.37"
        />
        <path
          style={styles.five}
          d="m422.8,569.16c.17,6.36,1.25,16.34,6.2,27.17,2.82,6.18,14.52,31.76,41.48,36.78,25.97,4.84,52.59-11.92,57.21-27.45,2.16-7.25,5.01-14.3,6.65-21.68.5-2.24,1.51-7.12,5.4-11.52,1.83-2.07,3.74-3.41,5.08-4.22"
        />
        <path
          style={styles.four}
          d="m341.93,738.57c3.72,12.59,7.44,25.17,11.16,37.76-2.23-.06-4.45-.12-6.68-.18-2.51-4.95-6.01-13.59-6.04-24.67-.01-5.03.69-9.4,1.56-12.91Z"
        />
        <path
          style={styles.four}
          d="m630.26,734.38c.33,1.21.74,3.36.27,5.94-.3,1.67-.88,3-1.37,3.91-1.35,2.65-2.35,5.07-3.08,7.12-1.36,3.79-2.59,6.51-2.2,9.9.29,2.52,1.29,4.47,2.07,5.7.21-2.02.7-4.95,2.04-8.24.78-1.92.56-2.18,2.28-4.5,1.1-1.49,4.37-6.31,3.59-12.52-.24-1.88-.79-3.46-1.37-4.7-.74-.87-1.48-1.74-2.22-2.62Z"
        />
        <path
          style={styles.five}
          d="m346.73,756.57c36.78,7.14,82.21,12.92,134.32,12.91,56.4,0,105.18-6.8,143.91-14.9"
        />
        <path d="m341.73,793.95c-.76-3.77-1.64-7.46-1.94-11.29-.47-5.1,5.71-6.25,9.59-6.29,9.31.03,40.38,1.75,50.22,2.05-.09.1,73.58,3.1,72.69,3.17,4.08-.71,7.88,2.84,7.8,6.57,1.09,11.68,1.06,23.47-.07,35.15-3.46.77-6.94,1.46-10.43,2.05-16.27,2.64-32.87,3.25-49.28,1.72-6.55-.58-13.07-1.52-19.52-2.78,6.51.91,13.04,1.55,19.58,1.94,13.07.72,26.2.23,39.19-1.39,6.48-.84,12.93-1.96,19.31-3.33,0,0-.8.92-.78.87,1.06-11.33,1.06-22.75,0-34.08,0-2.06-1.42-4.08-3.46-4.6-.69-.19-1.46-.24-2.14-.13,0-.03-72.99-3.14-73.01-3.17,0,0-36.46-1.58-36.46-1.58-5.97-.07-12.01-1.19-17.85.08-1.35.34-2.58.97-3.08,1.92-.6,1.14-.1,2.63-.13,3.92-.02,3.06-.06,6.12-.22,9.18h0Z" />
        <path d="m631.64,804.4c-.29-3.43-.48-7.17-.54-10.61-.26-3.4-.39-7.16-.74-10.53-.2-2.35-.45-4.7-.76-7.05-.03-1.84-.82-3.57-2.32-4.38-1.51-.84-3.53-.93-5.52-.9-4.06.15-8.19.89-12.16,1.79,0-.02-27.02,3.57-27.03,3.56-18,2.27-36.08,3.98-54.17,5.36-9.05.7-18.11,1.2-27.17,1.65-2.6-.36-4.43,2.38-4.36,4.77-.91,11.38-.92,22.83-.07,34.22l-.48-.83c2.35,1.72,5.84,3.04,8.32,3.49,4.08.46,8.63.06,12.72-.36,14.41-1.41,37.22-3.66,51.64-4.96,12.92-1.19,25.84-2.23,38.79-3.07-12.86,1.75-25.73,3.3-38.62,4.71-14.39,1.6-37.18,3.93-51.58,5.42-4.24.45-8.94.88-13.19.42-3.33-.6-6.54-1.91-9.34-3.82,0,0-.44-.3-.44-.3,0,0-.04-.53-.04-.53-.91-11.5-.96-23.07-.1-34.57-.07-3.64,3.05-7.42,6.63-7.02,18.08-.99,36.15-2.26,54.18-3.97,15.7-1.39,38.12-4.28,53.96-6.35,3.6-.81,8.36-1.65,12.46-1.77,5.22-.3,9.87,1.67,10,7.22,1.31,9.42,1.39,18.99-.06,28.4h0Z" />
        <line
          style={styles.five}
          x1="428.22"
          y1="780.79"
          x2="425.86"
          y2="827"
        />
        <path
          style={styles.five}
          d="m542.83,779.23c1.39,14.47,2.78,28.95,4.17,43.42"
        />
        <path
          style={styles.five}
          d="m572.77,634.73c1.15,7.5,2.7,18.95,3.75,33.01,1.26,16.75,1,26.44,1.37,36.98.47,13.39,2.09,32.79,7.5,56.96"
        />
        <path
          style={styles.five}
          d="m388.7,636.33c.4,10.32.42,25.71-1.76,44.33-1.16,9.89-2.36,15.66-3.72,24.07-2.17,13.42-4.63,32.72-5.48,57.15"
        />
        <g>
          <path
            style={styles.six}
            d="m383.32,708.21c17.63,9.16,56.89,26.49,108.68,23.7,39.33-2.12,69.27-14.94,86.04-23.7,2.45,17.55,4.9,35.11,7.35,52.66-25.86,4.22-55.36,7.42-87.91,8.22-45.72,1.13-86.14-2.75-119.71-8.22,1.85-17.55,3.7-35.11,5.55-52.66Z"
          />
          <path
            style={styles.seven}
            d="m388.7,636.33c-.24,20.92-3.64,53.73-21.13,88.41-6.53,12.96-13.84,23.82-20.84,32.71,10.35,1.14,20.7,2.28,31.04,3.42l4.53-47.61c2.71-13.8,4.92-29.44,5.93-46.68.63-10.73.72-20.84.46-30.25"
          />
          <path
            style={styles.seven}
            d="m573.2,633.02c2.5,17.77,5.77,32.27,8.41,42.54,6.32,24.62,10.55,40.5,22.5,57.13,7.52,10.47,15.49,17.61,20.85,21.88-13.19,2.1-26.38,4.19-39.57,6.29l-6.31-43.04-5.88-84.81Z"
          />
          <path
            style={styles.seven}
            d="m420.59,622.39c.98.83,2.31,1,3.32.45,1.01-.55,1.3-1.6,1.35-1.81-2.8-15.2-5.6-30.4-8.4-45.6-.04-.9-.03-1.93.1-3.06.19-1.73.59-3.2.99-4.36h-4.54l-2.44,6.62,9.61,47.77Z"
          />
          <path
            style={styles.seven}
            d="m542.06,621.54c-.44.18-1.58.59-2.97.28-2.1-.47-3.1-2.19-3.26-2.48,3.87-14.39,7.74-28.78,11.61-43.17.28-.83.62-2.21.52-3.91-.11-1.96-.75-3.44-1.17-4.26,1.31.1,2.62.2,3.93.29l2.15,7.13-10.79,46.12Z"
          />
          <path
            style={styles.seven}
            d="m422.8,569.16c.41,6.16,1.75,15.52,6.34,25.8,3.2,7.18,14.11,31.64,39.05,37.66,17.75,4.29,32.76-3.16,37.65-5.58,5.46-2.71,16.71-8.08,20.21-17.01,3.43-8.74,4.63-13.46,4.63-13.46.17-.66.69-2.8,1.77-5.53.73-1.86,1.41-3.05,1.7-3.78,3.11-7.86,2.15-11.77,5.48-15.39.69-.75,1.87-1.83,3.81-2.71-.99-.43-2.55-.92-4.25-.54-1.28.28-3.93,1.33-6.87,8.34-1.25,2.99-1.92,6.02-3.13,12-.69,3.43-1.11,5.94-1.17,6.29-1.72,9.93-17.94,31.41-42.26,34.27-6.63.78-11.73-.07-12.78-.26-32.59-5.94-48.38-54.43-50.16-60.11Z"
          />
          <path
            style={styles.eight}
            d="m495.93,786.11c.19-.6.57-1.53,1.33-2.47.67-.82,1.38-1.34,1.88-1.65,14.56-.92,29.13-1.84,43.69-2.77l4.17,43.42-41.34,3.46c-1.21-.13-2.67-.39-4.27-.9-2.48-.8-4.4-1.92-5.73-2.85-.84-6.42-1.35-13.92-1.03-22.26.2-5.05.67-9.73,1.29-13.98Z"
          />
          <path
            style={styles.eight}
            d="m428.79,780.69l45.24,1.89c.59.11,1.49.35,2.41.98,1.83,1.26,2.39,3.15,2.54,3.73l.1,35.21c-6.14,1.35-12.87,2.52-20.12,3.36-12.27,1.42-23.42,1.59-33.1,1.13.98-15.44,1.95-30.87,2.93-46.31Z"
          />
          <path
            style={styles.one}
            d="m425.86,827l2.36-46.21c-20.58-1.1-34.91-1.86-40.5-2.11-2.75-.12-5.58-.23-9.72-.59-2.9-.25-3.81-.4-6-.52-4.04-.22-4.89.08-8.93.07-8.4-.03-11.31-1.32-17.42.17-.87.21-1.57.43-2,.56-1.09,1.25-2.19,2.51-3.28,3.76.43,3.96.87,7.91,1.3,11.87,9.2,6.16,19.94,12.56,32.24,18.62,9.44,4.65,18.45,8.43,26.8,11.52l25.15,2.86Z"
          />
          <path
            style={styles.one}
            d="m550.71,568.29l2.1,8.21-10.75,45.04c-.23,1.15-.54,3.57.42,6.35.65,1.89,1.65,3.24,2.35,4.04,7.37,2.42,14.74,4.84,22.11,7.26.35.01,2.95.08,4.79-2.01,1.51-1.71,1.49-3.71,1.47-4.16,2.03-8.9,3.81-19.05,4.92-30.29.97-9.91,1.26-19.07,1.14-27.31-4.79-2.29-11.07-4.66-18.65-6.03-3.57-.65-6.9-.97-9.9-1.1Z"
          />
          <path
            style={styles.one}
            d="m380.48,579.3c.38-1.67.99-3.04,1.7-4.17,7.15-11.44,29.98-7.37,31.25-7.13-.81,2.21-1.63,4.41-2.44,6.62l9.89,49.18c.21.58,1.11,3.29-.22,6.25-1.09,2.43-3.03,3.57-3.68,3.91-2.88,1.22-6.33,2.43-10.28,3.39-5.36,1.3-10.18,1.75-14.09,1.84l-3.91-2.87-8.22-57.02Z"
          />
          <path
            style={styles.one}
            d="m542.83,779.23l4.17,43.42,60.77-6.1c5.09-2.15,10.47-4.71,16.05-7.75,2.83-1.54,5.52-3.11,8.08-4.68-.53-10.02-1.06-20.04-1.59-30.05-.36-.84-1.04-2.05-2.3-3.04-3.46-2.72-8.03-1.2-8.47-1.05-6.18,1-12.44,1.96-18.78,2.86-19.94,2.83-39.27,4.92-57.93,6.4Z"
          />
        </g>
      </g>
    </g>
  );
};

export default BulletVest;
