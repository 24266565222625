const RiRi = ({ color }) => {
  const styles = {
    one: {
      fill: "#bf517e",
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: color,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#ff7bac",
    },
    five: {
      fill: color,
      filter: "brightness(85%)",
    },
  };

  return (
    <g>
      <path
        style={styles.two}
        d="M312.67,644.95c-9.54-15.73-27.57-51-18.67-90.95,1.28-5.73,2.99-11.06,5-16,3.73-8.23,7.9-19.76,10-34,2.64-17.92,.95-32.98-1-43-3.26-29.6-2.48-54.32-1-72,2.04-24.44,4.83-54.74,20-90,9.01-20.94,19.45-36.77,27-47,7.96-11.9,34.05-47.5,81-59,16.17-3.96,30.7-4.05,42-3,6.65-1.83,18.58-4,32,0,3.76,1.12,7.06,2.55,9.87,4.04,2.88-1.18,6.27-2.28,10.13-3.04,10.88-2.15,20.18-.57,26,1,4.85,1.41,15.91,5.36,25,16,13.6,15.92,12.45,34.75,12.12,38.67-19.22-6.76-73.17-22.85-137.12-3.67-46.98,14.09-77.6,40.68-92.27,55.36-5.08,7.5-10.21,16.36-14.73,26.64-13.49,30.67-15.06,59.22-14.3,77.81,3.58,35.91,7.15,71.81,10.73,107.72l6.88,106.93-38.63,27.49Z"
      />
      <path
        style={styles.four}
        d="M483,230l8-18c4.29-1.73,11.64-4.06,21-4,8.97,.06,16.03,2.3,20.26,4,2.12,6.06,4.23,12.12,6.35,18.17-10.55,1.95-18.85,1.58-24.61,.83-5.7-.75-11.19-2.17-20-2-4.64,.09-8.45,.58-11,1Z"
      />
      <path
        style={styles.three}
        d="M477,190c3.41,.81,9.13,2.63,15,7,5.56,4.14,8.86,8.79,10.64,11.74"
      />
      <path
        style={styles.three}
        d="M518.87,194.04c-1.24,.99-3.31,2.91-4.87,5.96-1.7,3.32-1.97,6.37-2,8"
      />
      <path d="M532.07,211.02c12.19-3.55,26.43,2.12,32.93,12.98-6.55-6.14-14.8-10.24-23.74-11.03-2.91-.28-5.85-.24-8.81,.02l-.38-1.96h0Z" />
      <path
        style={styles.two}
        d="M428.1,581.6c.34-8,0-20.03-4.1-33.6-7.65-25.34-23.67-40.45-30.65-48-15.3-16.54-32.69-45.92-38.35-102,2.92,2.58,6.98,6.81,10,13,1.98,4.06,2.14,6.14,4,12,3.47,10.91,8.21,20.27,9,20,.8-.28-3.53-10.12-4-25-.14-4.37,.02-8.43,.35-12.13,.73-4.24,1.29-10.05,.65-16.87-.67-7.1-2.41-12.9-4-17.01,2.58-1.73,5.16-3.45,7.74-5.18l-.74-6.82h8.04l-.52-9.17h6.03v-9.07h7.02l1.57-9.07h8.87l.52-2.5h12l-.52-6.2h7.1l5.9-2h12.15l-4.06-5.87,9.91,4.87v-6.96l8,5.22-2.48-5.22,10.54,3.65-1.47-3.65h8.15l3.26-8.04,2.87,8.04,5.13-5.04,3.98,5.04,5.02-4.7,10,2.65v-6h6.2l4.8-4,4,4,2-7,3,1,4.72-1s4.28,6,6.28,6,6-4,6-4l7,3,5.21-2.56,7.79,5.56,5-4,4.89,2.98,4.11-.98,8,2h5l2,3,7-2,3,3,6-4,8,6,3-3,5,6,2.94,5.54s4.06-2.54,5.06-1.54,2.74,10.2,2.74,10.2l4.56,2.5v9.07l6.7,2.24,3,12,4,4,1.76,6.82,4.24,1.18v5.78l2.41,4.07v6.5l2.7,5-3.11,6.65,.26,6.9-1.48,5.5c2.89-4.01,33.42-47.79,16.22-94.39-15.98-43.28-62.53-60.27-70-63-3.52-1.28-9.53-3.24-18-6-21.33-6.94-38.87-12.12-50.67-15.48-8.58,1.81-15.5,1.83-20.33,1.48-6.95-.5-10.81-1.86-19-2-5.9-.1-10.76,.48-14,1-25.8,5.02-45.36,13.49-58,20-18.29,9.42-52.07,26.96-72,64-8.38,15.56-12.18,30.64-14,38-1.92,7.78-5.58,24.85-5.3,47.09,.02,1.69,.67,5.71,.5,11.25-.31,10.19-2.61,17.54-3.2,19.66-4.42,15.87,4.8,33.15,9,58,2.91,17.24,.59,21.44,1,46,.33,19.69,2.35,48.11,10.31,83.46,9.45-5.83,20.01-11.76,31.69-17.46,16.11-7.86,31.34-13.83,45.1-18.4Z"
      />
      <path
        style={styles.five}
        d="M478,232c-23.69,6.63-35.03,17.52-41,26-13.04,18.53-16.76,48.8-16.92,48.76,0,0,0-.06,0-.06,1.67-10.37,5.9-26.83,17.92-42.7,3.96-5.23,8.1-9.52,12-13,3.65-2.95,7.64-5.98,12-9,5.56-3.85,10.94-7.16,16-10Z"
      />
      <path
        style={styles.five}
        d="M536.85,230.7c23.69,6.63,35.03,17.52,41,26,13.04,18.53,16.76,48.8,16.92,48.76,0,0,0-.06,0-.06-1.67-10.37-5.9-26.83-17.92-42.7-3.96-5.23-8.1-9.52-12-13-3.65-2.95-7.64-5.98-12-9-5.56-3.85-10.94-7.16-16-10Z"
      />
      <path
        style={styles.five}
        d="M434,247c-8.34,3.89-22.87,12.1-35,28-20.15,26.41-19.51,55.45-19,64,.87-12.01,4.47-37.94,23-63,10.55-14.26,22.38-23.44,31-29Z"
      />
      <path
        style={styles.five}
        d="M574,242c7.79,2.81,25.14,10.25,39.83,28,14.93,18.04,18.92,36.82,20.17,45-3.18-10.25-9.86-27.17-24.17-44-12.89-15.16-26.75-24.09-35.83-29Z"
      />
      <path
        style={styles.five}
        d="M489.98,230.7c-6.05,3.67-13.86,9.45-20.98,18.3-15.79,19.63-17.72,41.33-18,50,3.02-12.26,8.9-29.39,21-47,5.98-8.69,12.25-15.73,17.98-21.3Z"
      />
      <path
        style={styles.five}
        d="M525,231.7c6.05,3.67,13.86,9.45,20.98,18.3,15.79,19.63,17.72,41.33,18,50-3.02-12.26-8.9-29.39-21-47-5.98-8.69-12.25-15.73-17.98-21.3Z"
      />
      <path
        style={styles.five}
        d="M612,255c7.34,4.09,26.31,15.95,38,40,9.25,19.03,9.47,36.48,9,45-.65-9.9-3.01-26.21-12-44-11.06-21.88-26.51-34.84-35-41Z"
      />
      <path
        style={styles.five}
        d="M505.03,230.7c-3.38,2.91-6.9,6.61-10.03,11.3-13.04,19.58-9.46,41.85-8,49-.89-8.78-1.24-25.33,7-43,3.36-7.21,7.38-12.95,11.03-17.3Z"
      />
      <path
        style={styles.five}
        d="M517.52,232c3.72,5.07,8.05,12.07,11.48,21,5.12,13.32,6.04,25.21,6,33-1.41-9.24-3.82-20.14-8-32-2.93-8.32-6.22-15.67-9.48-22Z"
      />
      <path
        style={styles.five}
        d="M473,231c-3.27-4.64-10.49-13.58-23-20-12.64-6.49-24.25-7.11-29.93-7.04,7.46,1.44,17.73,4.24,28.93,10.04,10.68,5.53,18.61,11.93,24,17Z"
      />
      <path
        style={styles.five}
        d="M432,245c-2.48-2.98-11.46-13.02-27-16-17.68-3.39-31.12,4.8-34,6.65,4.22-2.08,17.3-7.86,34-4.65,14.29,2.75,23.45,10.6,27,14Z"
      />
      <path
        style={styles.five}
        d="M457,235c-3.87-3.83-9.13-8.21-16-12-16-8.84-31.49-9.39-39.5-9.07,8.31,.77,19.79,2.77,32.5,8.07,9.64,4.02,17.3,8.82,23,13Z"
      />
      <path
        style={styles.five}
        d="M377,284c-3.1,4.95-7.85,13.18-12,24-13.44,35.03-11.49,73.13-11.92,73.1-.03,0-.04-.17-.04-.17-1.47-31.31,6.96-65.93,6.96-65.93,.99-4.05,1.65-6.62,3-10,3.99-10.01,9.9-16.92,14-21Z"
      />
      <path
        style={styles.five}
        d="M417,253c-2.63-2.91-8.61-8.69-18-11-18.69-4.61-33.63,8.74-35,10,2.79-2,16.44-11.34,34-8,9.17,1.74,15.62,6.22,19,9Z"
      />
      <path
        style={styles.five}
        d="M482,228c.46-1.68,3.04-11.78-3-21-5.56-8.48-16.2-12.83-27-11,1.9,.13,16.67,1.38,25,14,4.84,7.33,5.09,14.83,5,18Z"
      />
      <path
        style={styles.five}
        d="M395.3,265.85c-4.35-4.37-8.65-6.09-11.3-6.85-11.45-3.25-24.86,2.3-34.44,13.83,3.03-2.63,12.92-10.54,27.44-10.83,8.16-.16,14.59,2.15,18.3,3.85Z"
      />
      <path
        style={styles.five}
        d="M522.48,208c.49-1.78,2.04-6.4,6.52-10,6.55-5.25,14.2-4.16,15.49-3.96-2.49,.2-6.34,.83-10.49,2.96-6.65,3.4-10.13,8.61-11.52,11Z"
      />
      <path
        style={styles.five}
        d="M512,231.7c-1.25,4.85-2.33,10.31-3,16.3-1.64,14.8-.18,27.57,1.81,37.15-.3-11.18-.27-22.91,.19-35.15,.24-6.25,.57-12.36,1-18.3Z"
      />
      <path
        style={styles.five}
        d="M406.92,589.37c7.01-29.79,.65-50.37-2.92-59.37-12.08-30.48-41.75-53.23-42-53.01,0,0,0,0,0,0,0,0,0,0,0,0,0,0,29.71,33.86,39,53,11.99,24.7,5.8,57.56,5.92,59.37h0Z"
      />
      <path
        style={styles.five}
        d="M348,354c-.98-.09-14.29,41.01-3,91,12.75,56.47,49.84,93.11,50,93,.13-.09-25.28-25.32-40-68-21.02-60.96-5.87-115.9-7-116Z"
      />
      <path
        style={styles.five}
        d="M357.7,502.39c7.36,13.77,11.67,25.78,14.3,34.61,6.72,22.57,9.55,48.2,9.97,48.13,.03,0,.03-.11,.03-.13,1.12-31.64-8-53-8-53-4.43-10.36-21.8-39.44-16.3-29.61h0Z"
      />
      <path
        style={styles.five}
        d="M324,626c-6.88-16.45-16.71-46.18-15-84,1.24-27.43,8.11-49.43,14-64-4.38,17.07-8.3,38.75-9,64-.96,34.49,4.41,63.34,10,84Z"
      />
      <path
        style={styles.five}
        d="M374,282c-6.8,1.56-20.28,5.64-33,17-16.38,14.62-21.44,32.3-23,39,.03-2.91,.79-31.31,24-47,12.95-8.75,26.38-9.16,32-9Z"
      />
      <path
        style={styles.five}
        d="M329.02,500c-2.31,5.75-5.52,15.11-7.02,27-4.09,32.38,6.9,64.78,7.8,64.63,.02,0,.03-.01,.03-.02,1.13-1.51-7.05-35.87-5.83-59.61,.71-13.72,4.95-31.53,5.02-32h0Z"
      />
      <path
        style={styles.five}
        d="M337,351c-2.68,.05-7.94,.54-13,4-10.96,7.49-10.53,21.76-10.48,22.85,.75-3.63,3.51-14.5,13.48-21.85,3.58-2.64,7.15-4.13,10-5Z"
      />
      <path
        style={styles.five}
        d="M340,466c10.2,20.64,14.74,38.1,17,50,5.48,28.81,4.88,63.14,4.99,63.13,0,0,0-.04,0-.04,.85-34.94-13-83.09-13-83.09-2.2-7.66-5.22-17.9-9-30Z"
      />
      <path
        style={styles.five}
        d="M538,224c1.79-.74,10.23-3.98,19,0,3.93,1.79,6.52,4.39,8,6.17-2.68-1.7-6.72-3.83-12-5.17-6.25-1.59-11.6-1.39-15-1Z"
      />
      <path
        style={styles.one}
        d="M490,217c3.93,2.16,9.32,4.55,16,6,12.62,2.73,23.21,.67,29-.95,.67,2.32,1.33,4.63,2,6.95-14.87,2.78-22.63,2.18-27,1-1.12-.3-5.77-1.66-11.85-2-2.79-.15-5.16-.05-6.15,0-3.16,.17-5.87,.58-8,1l6-12Z"
      />
    </g>
  );
};

export default RiRi;
