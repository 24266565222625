import { createContext } from "react";
export const StoreContext = createContext();
export const StoreDispatch = createContext();

export const initialStore = {
  user: null,
  rightChatOpen: true,
  errors: null,
  successes: null,
  teams: [],
  notiCount: 0,
  joinMatchId: null,
  snipeMatchId: null,
  searchUser: null,
  leftRailOpen: true,
  walletOpen: false,
  tosVersion: null,
  searchOpen: false,
};

export const SET_USER = "SET_USER";
export const SET_CHAT_OPEN = "SET_CHAT_OPEN";
export const SET_ERRORS = "SET_ERRORS";
export const SET_SUCCESSES = "SET_SUCCESSES";
export const SET_TEAMS = "SET_TEAMS";
export const SET_NOTI_COUNT = "SET_NOTI_COUNT";
export const SET_JOIN_MATCH_ID = "SET_JOIN_MATCH_ID";
export const SET_SNIPE_MATCH_ID = "SET_SNIPE_MATCH_ID";
export const CLEAR_STORE = "CLEAR_STORE";
export const SET_SEARCH_USER = "SET_SEARCH_USER";
export const SET_LEFT_RAIL_OPEN = "SET_LEFT_RAIL_OPEN";
export const SET_WALLET_OPEN = "SET_WALLET_OPEN";
export const SET_TOS_VERSION = "SET_TOS_VERSION";
export const SET_SEARCH_OPEN = "SET_SEARCH_OPEN";

export const storeReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: payload,
      };
    case SET_CHAT_OPEN:
      return {
        ...state,
        rightChatOpen: payload,
      };
    case SET_ERRORS:
      return {
        ...state,
        errors: payload,
      };
    case SET_SUCCESSES:
      return {
        ...state,
        successes: payload,
      };
    case SET_TEAMS:
      return {
        ...state,
        teams: payload,
      };
    case SET_NOTI_COUNT:
      return {
        ...state,
        notiCount: payload,
      };
    case SET_JOIN_MATCH_ID:
      return {
        ...state,
        joinMatchId: payload,
      };
    case SET_SNIPE_MATCH_ID:
      return {
        ...state,
        snipeMatchId: payload,
      };
    case SET_SEARCH_USER:
      return {
        ...state,
        searchUser: payload,
      };
    case SET_LEFT_RAIL_OPEN:
      return {
        ...state,
        leftRailOpen: payload,
      };
    case SET_WALLET_OPEN:
      return {
        ...state,
        walletOpen: payload,
      };
    case SET_TOS_VERSION:
      return {
        ...state,
        tosVersion: payload,
      };
    case SET_SEARCH_OPEN:
      return {
        ...state,
        searchOpen: payload,
      };
    case CLEAR_STORE:
      return payload;
    default:
      return state;
  }
};

const Store = (props) => {
  return (
    <StoreContext.Provider value={props.store}>
      <StoreDispatch.Provider value={props.dispatch}>
        {props.children}
      </StoreDispatch.Provider>
    </StoreContext.Provider>
  );
};

export default Store;
