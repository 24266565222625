import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { createTeam } from "../../api/teams";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  SET_TEAMS,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import {
  cardLight,
  doubtedBlue,
  offWhite,
  small,
} from "../../utils/themeContstants";
import CustomInput from "../custom/CustomInput";
import CustomModal from "../custom/CustomModal";
import PrimaryButton from "../custom/PrimaryButton";
import SecondaryButton from "../custom/SecondaryButton";

const CreateTeamModal = ({ open, onClose }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleClose = () => {
    setName("");
    setLoading(false);
    setDisabled(true);
    onClose();
  };

  const handleCreateTeam = () => {
    setLoading(true);
    createTeam(middleware, name).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
        setName("");
        setDisabled(true);
      } else {
        let newTeams = store?.teams;
        newTeams?.push(res?.team);
        updateStore({ type: SET_TEAMS, payload: newTeams });
        updateStore({
          type: SET_SUCCESSES,
          payload: "Successfully create team!",
        });
        handleClose();
      }
    });
  };

  useEffect(() => {
    if (name !== "" && name != null && name?.length > 2) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [name]);

  const styles = {
    label: {
      fontSize: small,
      fontWeight: 700,
      color: offWhite,
    },
    width: {
      width: "100%",
    },
  };

  return (
    <CustomModal
      title="Create Team"
      description="Start competing with your friends"
      titleSize="large"
      open={open}
      onClose={handleClose}
      primaryButton={
        <SecondaryButton
          label="Create"
          bg={doubtedBlue}
          disabled={disabled}
          loading={loading}
          onClick={handleCreateTeam}
        />
      }
      secondaryButton={<SecondaryButton label="Close" onClick={handleClose} />}
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 1 }}
      >
        <Grid item sx={styles.width}>
          <CustomInput
            value={name}
            onChange={setName}
            placeholder={"Enter a team name"}
            autoFocus={true}
            onKeyDown={() => {
              if (name !== "" && name != null && name?.length > 2) {
                handleCreateTeam();
              } else {
                return;
              }
            }}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default CreateTeamModal;
