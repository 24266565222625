import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getAccessToken } from "../../api/useMiddleware";
import useTeamSocket from "../../sockets/useTeamSocket";
import { SET_TEAMS, StoreContext, StoreDispatch } from "../../store/Store";
import {
  card,
  cardLight,
  cardVeryLight,
  large,
  normal,
  secondaryText,
  text,
  transition,
} from "../../utils/themeContstants";
import EditTeamModal from "./EditTeamModal";
import TeamInviteModal from "./TeamInviteModal";
import TeamProfileModal from "./TeamProfileModal";

const TeamItem = ({ team }) => {
  const { teamData } = useTeamSocket(team?._id, getAccessToken());
  const updateStore = useContext(StoreDispatch);
  const store = useContext(StoreContext);
  const isDesktop = useMediaQuery("(min-width:1025px)");

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  useEffect(() => {
    if (team) {
      setCurrentTeam(team);
      setLoading(false);
    }
  }, [team]);

  useEffect(() => {
    if (teamData) {
      setCurrentTeam(teamData);
      const indexOfTeam = store?.teams?.findIndex(
        (storeTeam) => storeTeam?._id === teamData?._id
      );
      let newTeams = store?.teams;
      newTeams[indexOfTeam] = teamData;
      updateStore({ type: SET_TEAMS, payload: [...newTeams] });
    }
  }, [teamData]);

  useEffect(() => {
    if (team) {
      if (team?.team_owner === store?.user?._id) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  }, [team]);

  const styles = {
    container: {
      width: "100%",
      padding: 2,
      borderRadius: 2,
      border: `1px solid ${cardVeryLight}`,
      backgroundColor: card,
      transition: transition,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: cardLight,
      },
    },
    title: {
      fontSize: large,
      color: text,
      fontWeight: 800,
    },
    meta: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 600,
    },
    width: {
      width: "100%",
    },
  };

  return loading ? null : (
    <>
      <TeamProfileModal
        open={open}
        onClose={() => setOpen(false)}
        team={currentTeam}
      />
      <TeamInviteModal
        team={currentTeam}
        open={inviteOpen}
        onClose={() => setInviteOpen(false)}
      />
      <EditTeamModal
        team={currentTeam}
        open={editOpen}
        onClose={() => setEditOpen(false)}
      />

      <Grid item sx={styles.container} onClick={() => setOpen(true)}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          gap={{ xs: 2 }}
        >
          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
            >
              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <Grid item>
                    <Typography sx={styles.title}>
                      {currentTeam?.name}
                    </Typography>
                  </Grid>

                  {/* {isOwner && (
                    <Grid item>
                      <SecondaryButton
                        size="small"
                        label="Edit"
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditOpen(true);
                        }}
                      />
                    </Grid>
                  )} */}
                </Grid>
              </Grid>

              <Grid item>
                <Typography sx={styles.meta}>
                  {currentTeam?.users?.length} players
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={{ minWidth: isDesktop ? 0 : "100%" }}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              gap={{ xs: 1 }}
            >
              {/* <Grid item sx={{ minWidth: isDesktop ? 0 : "100%" }}>
                <PrimaryButton
                  label="view team"
                  type="purple"
                  size="small"
                  onClick={() => setOpen(true)}
                  fullWidth={isDesktop ? false : true}
                />
              </Grid>

              <Grid item sx={{ minWidth: isDesktop ? 0 : "100%" }}>
                <PrimaryButton
                  label="invite"
                  type="green"
                  size="small"
                  onClick={() => setInviteOpen(true)}
                  fullWidth={isDesktop ? false : true}
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TeamItem;
