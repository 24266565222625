import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { RiSwordFill } from "react-icons/ri";
import { getDisputes } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { DISPUTES_LIMIT } from "../../utils/limits";
import { text } from "../../utils/themeContstants";
import usePagination from "../../utils/usePagination";
import NotSelected from "../custom/NotSelected";
import DisputeGlimmer from "../glimmers/DisputeGlimmer";
import DisputeItem from "./DisputeItem";

const Disputes = () => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const store = useContext(StoreContext);
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [disputes, setDisputes] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const handleGetDisputes = () => {
    getDisputes(middleware, skip).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.disputes?.length >= DISPUTES_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setDisputes(disputes ? [...disputes, ...res?.disputes] : res?.disputes);
      }
    });
  };

  usePagination(bottom, () => setSkip(disputes?.length));

  useEffect(() => {
    if (disputes == null) {
      handleGetDisputes();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetDisputes();
    }
  }, [skip]);

  const styles = {
    width: {
      width: "100%",
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
  };

  return (
    <>
      <Grid item>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.icon}>
            <RiSwordFill style={{ fontSize: 22, color: text }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.title}>DISPUTES</Typography>
          </Grid>
        </Grid>
      </Grid>

      {loading && <DisputeGlimmer numItems={3} />}

      {!loading && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            {(!disputes || disputes?.length < 1) && (
              <Grid item alignSelf="center">
                <NotSelected label="NO DISPUTES" />
              </Grid>
            )}

            {disputes && disputes?.length > 0 && (
              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 1 }}
                >
                  {disputes?.map((dispute, i) => (
                    <DisputeItem dispute={dispute} key={i} />
                  ))}
                  {hasMore && (
                    <div style={{ width: "100%" }} ref={bottom}>
                      <DisputeGlimmer numItems={1} />
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Disputes;
