const GraphicBlazerIdea = ({ color }) => {
  const styles = {
    one: {
      fill: color,
    },
    two: {
      fill: "#3d3d3d",
    },
    three: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#2b2929",
      strokeWidth: 2,
    },
    five: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    six: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "none",
      strokeWidth: 2,
    },
  };

  return (
    <g>
      <g id="Blazer_tee_1_copy_5" data-name="Blazer tee 1 copy 5">
        <path
          style={styles.one}
          d="M426,707c-4.25,2.21-32.12,17.28-38,48-6.59,34.45,18.96,65.09,43,77,2.09,1.04,9.32,4.48,26,7,15.03,2.27,94.49,14.26,128-32,4.42-6.1,10.29-16.32,13-32-12.57-7.49-39.77-26.21-54-61-4.65-11.36-6.92-22.02-8-31,24.43-7.04,41.24-20.47,42-35,.13-2.52,.06-10.43-17-29-9.82-10.7-25.55-25.23-49.11-38.07,6.01,3.85,7,7.17,7.11,9.07,.51,9.09-17.54,18.05-32,20-2.33,.31-14.09,1.73-27-4-5.91-2.62-15.91-6.96-18-16-.65-2.82-.35-5.33,0-7,2.23-2.53,4.46-5.05,6.69-7.58-26.5,11.95-44.48,34.22-45.69,58.58-.57,11.46,2.68,21.15,6,28,3.43,2.51,8.94,7.27,13,15,6.92,13.18,4.84,25.92,4,30Z"
        />
        <path d="M450.27,570.05c-.01,1.33-.14,2.64-.28,3.95l-.12,.98c-.03,.33-.12,.72-.22,1.06-.2,.71-.51,1.4-.93,2.02-.81,1.27-2.06,2.17-3.12,2.89-.52,.36-.86,.67-1.21,1.11-.31,.44-.6,.92-.79,1.46-.41,1.07-.59,2.29-.62,3.5-.02,.61,0,1.22,.06,1.85,.05,.59,.15,1.15,.32,1.7,.69,2.22,2.35,4.17,4.18,5.87,1.85,1.71,3.95,3.2,6.12,4.54,2.15,1.38,4.47,2.48,6.82,3.51,4.73,1.97,9.73,3.33,14.8,4.05,1.25,.18,2.58,.33,3.76,.38,1.29,0,2.58,.03,3.86,0,2.56-.12,5.11-.35,7.63-.82,5.03-.91,9.97-2.48,14.48-4.86,2.25-1.18,4.45-2.51,6.39-4.11,.49-.39,.94-.83,1.41-1.24,.45-.43,.86-.89,1.29-1.33l.55-.71c.2-.23,.37-.47,.5-.72,.26-.42,.66-1.15,.94-1.65,.57-1.05,.89-2.1,.81-3.18-.05-1.09-.45-2.17-1.07-3.16-1.25-1.99-3.27-3.61-5.41-4.86l.98-1.74c1.2,.67,2.33,1.44,3.39,2.33,1.06,.89,2.04,1.91,2.83,3.13,.78,1.21,1.35,2.65,1.44,4.18,.14,1.54-.35,3.09-1.02,4.33-.35,.65-.58,1.07-.99,1.77-.18,.33-.38,.62-.6,.89l-.65,.84c-.47,.5-.93,1.03-1.43,1.5-.51,.45-1,.93-1.53,1.36-2.09,1.74-4.38,3.17-6.75,4.42-4.75,2.51-9.9,4.14-15.15,5.1-2.63,.49-5.29,.73-7.96,.86-1.33,.04-2.66,0-3.98,.01-1.44-.06-2.71-.21-4.05-.4-10.51-1.56-20.84-5.62-28.88-12.86-1.94-1.86-3.84-4.02-4.72-6.84-.21-.7-.34-1.44-.39-2.15-.05-.68-.07-1.38-.05-2.07,.05-1.39,.25-2.8,.79-4.18,.25-.69,.63-1.35,1.09-1.96,.45-.6,1.11-1.16,1.67-1.53,1.1-.73,2.01-1.41,2.67-2.33,.32-.46,.61-.95,.82-1.48,.11-.28,.18-.52,.27-.84l.26-.95c.37-1.26,.76-2.51,1.26-3.75l.54,.11Z" />
        <path
          style={styles.two}
          d="M667,713c-3.77-22.78-8.85-43.09-14.21-60.6-10.29-10.92-23.72-23.1-40.79-34.4-38.48-25.48-76.44-33.93-100.11-37.07,3.08,1.97,4.83,3.8,5.84,5.38,1.01-.55,2.26-.77,3.73-.56,6.72,.92,11.83,6.29,7.78,12.96-.28,.46-.59,.91-.91,1.35,.9,.8,1.5,1.99,1.5,3.56,0,1.45,.02,2.89,.03,4.34,1.32,.85,2.23,2.23,2.06,4.01-.43,4.31-1.04,8.57-1.78,12.81,.25,11.73,.51,23.46,.22,35.19-.09,3.64-3.17,5.27-5.93,4.73-1.2,12.78-2.56,25.54-4.59,38.21,2.85,.74,5.14,4.41,3.57,7.58-1.56,3.17-3.52,6.08-5.58,8.93,3.22,.04,6.15,4.27,4.54,7.76-1.41,3.07-2.9,6.08-4.59,8.98,2.58,12.94,2.88,26.67-1.57,39.23-.28,.8-.58,1.6-.89,2.39-.08,.77-.18,1.54-.28,2.31,1.07,1.39,1.33,3.36,.77,5.04,.93,1.23,1.36,2.81,.8,4.24-1.07,2.75-2.13,6.4-3.66,9.68,.07,.83-.05,1.67-.34,2.44-.22,1.1-.43,2.27-.6,3.51-.17,1.23-.3,2.41-.39,3.53-.11,.97-.47,1.9-1.07,2.65-.04,5.03-.21,10.31-.54,15.81-.31,5.02-.73,9.85-1.24,14.45,25.24-.77,67.49-5.62,112.24-29.45,22.06-11.75,39.25-25.2,52.05-37.01-.56-18.31-2.28-39.19-6.05-61.99Z"
        />
        <path
          style={styles.two}
          d="M451.11,834.74c.27-2.46,.53-4.92,.77-7.38-.15-5.82-.41-11.64-.62-17.46-.58-4.42-.84-8.89-2.1-13.19-1.35-4.59-2.37-8.76-1.87-13.33-1.02-2.75-1.95-5.53-1.82-8.63,.06-1.33,.53-2.38,1.25-3.16-.02-.18-.04-.36-.06-.54-.68-.77-1.13-1.8-1.19-3.08-.71-15.77-5.23-30.98-2.21-46.71-2.8-12.77-5.37-26.1-1.52-38.62,.16-.51,.39-.95,.65-1.35-1.24-8.72-2.73-17.35-4.58-26.01-2.3-10.84-4.47-21.76-4.6-32.88-.02-1.71,.67-2.96,1.69-3.76-1.24-10.73,.69-21.32,1.96-32.24,.2-1.68,1.05-2.91,2.17-3.7,1.2-1.83,3.06-2.95,5.84-2.96,1.27-1.44,2.54-2.87,3.81-4.31-15,3.92-31.42,9.26-48.69,16.58-41.74,17.69-73.46,40.6-95.85,60.09-2.99,8.83-5.78,18.48-8.15,28.91-5.61,24.73-7.29,47.17-7.22,65.93,12.73,15.49,32.6,36.01,61.22,54.07,39.56,24.98,77.37,33.6,100.22,36.95,.3-1.07,.59-2.14,.89-3.21Z"
        />
        <path d="M450.27,570.05c-.01,1.33-.14,2.64-.28,3.95l-.12,.98c-.03,.33-.12,.72-.22,1.06-.2,.71-.51,1.4-.93,2.02-.81,1.27-2.06,2.17-3.12,2.89-.52,.36-.86,.67-1.21,1.11-.31,.44-.6,.92-.79,1.46-.41,1.07-.59,2.29-.62,3.5-.02,.61,0,1.22,.06,1.85,.05,.59,.15,1.15,.32,1.7,.69,2.22,2.35,4.17,4.18,5.87,1.85,1.71,3.95,3.2,6.12,4.54,2.15,1.38,4.47,2.48,6.82,3.51,4.73,1.97,9.73,3.33,14.8,4.05,1.25,.18,2.58,.33,3.76,.38,1.29,0,2.58,.03,3.86,0,2.56-.12,5.11-.35,7.63-.82,5.03-.91,9.97-2.48,14.48-4.86,2.25-1.18,4.45-2.51,6.39-4.11,.49-.39,.94-.83,1.41-1.24,.45-.43,.86-.89,1.29-1.33l.55-.71c.2-.23,.37-.47,.5-.72,.26-.42,.66-1.15,.94-1.65,.57-1.05,.89-2.1,.81-3.18-.05-1.09-.45-2.17-1.07-3.16-1.25-1.99-3.27-3.61-5.41-4.86l.98-1.74c1.2,.67,2.33,1.44,3.39,2.33,1.06,.89,2.04,1.91,2.83,3.13,.78,1.21,1.35,2.65,1.44,4.18,.14,1.54-.35,3.09-1.02,4.33-.35,.65-.58,1.07-.99,1.77-.18,.33-.38,.62-.6,.89l-.65,.84c-.47,.5-.93,1.03-1.43,1.5-.51,.45-1,.93-1.53,1.36-2.09,1.74-4.38,3.17-6.75,4.42-4.75,2.51-9.9,4.14-15.15,5.1-2.63,.49-5.29,.73-7.96,.86-1.33,.04-2.66,0-3.98,.01-1.44-.06-2.71-.21-4.05-.4-10.51-1.56-20.84-5.62-28.88-12.86-1.94-1.86-3.84-4.02-4.72-6.84-.21-.7-.34-1.44-.39-2.15-.05-.68-.07-1.38-.05-2.07,.05-1.39,.25-2.8,.79-4.18,.25-.69,.63-1.35,1.09-1.96,.45-.6,1.11-1.16,1.67-1.53,1.1-.73,2.01-1.41,2.67-2.33,.32-.46,.61-.95,.82-1.48,.11-.28,.18-.52,.27-.84l.26-.95c.37-1.26,.76-2.51,1.26-3.75l.54,.11Z" />
        <path
          style={styles.four}
          d="M446,577c-1.07,1.08-2.57,2.74-4,5-.91,1.44-4.4,5.86-5,20-.25,5.87-1,11.61-.5,19.5,.32,5.01,.92,9.26,1.5,12.5,2.37,23.87,4.4,48.55,6,74,1.76,28.04,2.89,55.23,3.5,81.5-13.38-32.88-26.15-73.35-33.5-120.5-2.82-18.11-4.56-35.34-5.5-51.5,5.67,3.67,11.33,7.33,17,11-1.12-4.79-2.06-10.32-2.5-16.5-.81-11.54,.42-21.46,2-29,2.05-1.04,4.38-2.08,7-3,5.28-1.85,10.1-2.66,14-3Z"
        />
        <path
          style={styles.four}
          d="M516,583c1.03,.8,2.55,2.1,4,4,4.19,5.47,4.64,11.63,5,20,.25,5.75,.82,11.34,.5,19.5-.27,6.75-.91,12.4-1.5,16.5-2.38,22.52-4.41,45.87-6,70-1.86,28.16-2.98,55.35-3.5,81.5,13.38-32.88,26.15-73.35,33.5-120.5,2.82-18.11,4.56-35.34,5.5-51.5-5.67,3.67-11.33,7.33-17,11,1.12-4.79,2.06-10.32,2.5-16.5,.81-11.54-.42-21.46-2-29-1.94-1.05-4.28-2.12-7-3-5.52-1.78-10.45-2.09-14-2Z"
        />
        <path
          style={styles.six}
          d="M447.5,789.5c1.01,5.1,1.88,10.61,2.5,16.5,1.25,11.9,1.19,22.69,.52,32.01"
        />
        <path
          style={styles.six}
          d="M514.5,794.5c-1.32,5.08-2.53,10.59-3.5,16.5-1.85,11.24-2.45,21.52-2.41,30.48"
        />
        <circle style={styles.five} cx="439" cy="799" r="5" />
        <circle style={styles.five} cx="434" cy="821" r="5" />
        <rect x="516" y="799" width="16" height="2" rx="1" ry="1" />
        <rect x="516" y="814" width="16" height="2" rx="1" ry="1" />
        <polygon
          style={styles.three}
          points="618.28 688.5 556.5 688.5 556.5 673.5 618.67 673.5 618.28 688.5"
        />
        <g>
          <path d="M481.53,720.44c-.92-.34-1.9-.58-2.75-1.05-.7-.38-1.34-.96-1.83-1.59-.35-.45-.68-.59-1.2-.58-.57,.02-1.16,.07-1.72-.05-1.32-.28-2.11-1.3-2.14-2.91-.03-1.73,.05-3.47,.17-5.2,.07-1.07,1.13-1.97,2.23-1.97,5.28-.01,10.57-.02,15.85,0,1.24,0,2.34,.92,2.39,2.77,.05,1.73-.03,3.47-.17,5.2-.1,1.27-1.17,2.21-2.47,2.14-1.24-.07-2.18,.15-2.99,1.21-.91,1.18-2.27,1.79-3.82,1.88-.06,0-.12,.1-.18,.15h-1.38Z" />
          <path d="M483.26,633.91c1.75,.76,2.46,2.09,2.43,3.95-.05,3.12-.03,6.25-.01,9.37,0,1.89-1.63,3.34-3.25,3.4-2.05,.07-3.62-1.41-3.64-3.31-.04-3.41-.05-6.81,0-10.22,.02-1.33,.68-2.37,2-2.91,.14-.06,.26-.19,.39-.28,.69,0,1.38,0,2.07,0Z" />
          <path d="M451.86,673.79c-3.19,.01-6.37,.04-9.56-.01-.1,0-.2,0-.3,0,.33,.92,.4,1.93,.22,2.9,.61,1.16,.76,2.57,.44,3.85,3.09,.04,6.18,.03,9.28,0,1.94-.02,3.45-1.55,3.38-3.57-.06-1.59-1.54-3.2-3.46-3.19Z" />
          <path d="M520.38,673.8c-2.57-.02-5.13-.02-7.7,0-1.94,.02-3.45,1.55-3.38,3.57,.06,1.59,1.54,3.2,3.46,3.19,2.25-.01,4.5-.01,6.75,0-.01-2.25,.39-4.63,.86-6.76Z" />
          <path d="M482.2,703.87c-2.53,0-5.06,0-7.58,0-1.39,0-2.63-1.04-2.54-2.35,.22-3.02-1.17-5.44-2.74-7.84-1.51-2.32-3.07-4.63-4.4-7.05-1.33-2.41-2.09-5.02-2.4-7.78-.4-3.64,.31-7.02,1.83-10.32,.92-2,2.18-3.77,3.74-5.28,2.86-2.77,6.25-4.73,10.29-5.32,1.9-.28,3.88-.49,5.78-.31,3.69,.34,7.1,1.59,10.07,3.87,2.23,1.71,4.1,3.74,5.41,6.19,1.21,2.25,1.95,4.66,2.24,7.22,.36,3.14-.13,6.13-1.18,9.06-1.07,2.96-2.82,5.56-4.54,8.19-1.1,1.69-2.11,3.44-3.1,5.19-.64,1.12-.79,2.38-.67,3.63,.16,1.69-1.24,3-2.97,2.92-2.41-.11-4.82-.03-7.24-.03Zm6.14-38.04c-4.09-2.68-11.33-2.48-15.88,2.62-4.36,4.89-3.71,11.23-1.12,14.44-.09-10.08,7.71-17.05,17-17.06Z" />
          <path d="M449.98,648.91c.07-1.54,.81-2.65,2.29-3.17,1.33-.47,2.6-.17,3.6,.79,2.45,2.34,4.86,4.71,7.25,7.11,1.02,1.03,1.27,2.3,.69,3.65-.58,1.38-1.72,2.07-3.18,2.1-.92,.02-1.8-.33-2.5-1.02-2.41-2.38-4.84-4.74-7.24-7.11-.64-.64-.94-1.44-.91-2.35Z" />
          <path d="M514.48,648.74c.02,1.07-.35,1.97-1.11,2.71-2.35,2.31-4.7,4.63-7.07,6.92-1.86,1.8-4.64,1.12-5.64-1-.57-1.21-.31-2.77,.67-3.74,2.44-2.42,4.88-4.85,7.38-7.22,1.05-.99,2.36-1.12,3.7-.56,1.3,.54,1.93,1.55,2.07,2.88Z" />
          <path d="M450,705.41c-.11-.98,.34-1.78,1.04-2.47,2.37-2.33,4.73-4.66,7.12-6.98,1.86-1.81,4.76-1.03,5.62,1.03,.55,1.31,.51,2.53-.67,3.72-2.41,2.44-4.87,4.85-7.36,7.21-1.05,.99-2.34,1.08-3.7,.59-1.07-.38-2.26-1.93-2.04-3.11Z" />
          <path d="M511.14,708.78c-1.08,.11-1.93-.33-2.65-1.05-2.37-2.32-4.76-4.64-7.11-6.98-1.81-1.8-1.06-4.58,1-5.47,1.31-.57,2.67-.5,3.87,.65,2.48,2.37,4.95,4.77,7.35,7.22,1.01,1.02,1.14,2.31,.56,3.62-.57,1.29-1.6,1.95-3.03,2Z" />
        </g>
      </g>
      <g id="Shoulder">
        <g>
          <path d="M289.61,748.67c-.91-1.03-1.83-2.05-2.71-3.1-.16-.19-.28-.51-.27-.76,.05-2.47,.11-4.94,.2-7.41,.05-1.47,.14-2.94,.27-4.41,.38-4.26,.69-8.53,1.2-12.78,.63-5.25,1.33-10.5,2.18-15.72,.92-5.63,2.04-11.23,3.07-16.84,.73-3.99,1.22-8.03,2.23-11.95,1.74-6.77,3.67-13.51,5.8-20.17,.76-2.37,2.14-4.61,3.59-6.66,1.05-1.49,2.62-2.65,4.07-3.82,6.08-4.88,12.09-9.86,18.34-14.52,4.77-3.55,9.8-6.77,14.82-9.97,13.26-8.43,27.33-15.36,41.35-22.38,13.87-6.94,28.29-12.49,43.1-17.07,6.81-2.11,13.66-4.07,20.67-5.5-.25,1.22-.39,1.42-1.42,1.69-13.06,3.43-25.64,8.26-38.17,13.21-8.35,3.3-16.39,7.25-24.35,11.37-6.67,3.45-13.39,6.81-19.95,10.47-6.61,3.68-13.14,7.53-19.55,11.57-8.21,5.17-16.25,10.61-24.38,15.91-4.23,2.76-8.05,6.02-11.92,9.25-1.81,1.51-2.89,3.25-3.44,5.46-1.94,7.73-3.97,15.45-5.84,23.2-1.14,4.69-2.15,9.42-3.07,14.16-.85,4.41-1.53,8.86-2.21,13.3-.68,4.45-1.32,8.9-1.89,13.37-.27,2.12-.34,4.26-.52,6.39-.31,3.75-.63,7.5-.94,11.25-.05,.56,0,1.12-.02,1.68-.07,2.35-.19,4.71-.22,7.06-.01,1.19,.13,2.38,.21,3.57-.08,.05-.15,.1-.23,.16Z" />
          <path d="M671.16,776.11c0-4.67,.12-9.14-.03-13.6-.17-4.96-.57-9.92-.92-14.88-.2-2.89-.43-5.77-.74-8.65-.31-2.86-.75-5.71-1.14-8.57-.48-3.48-.94-6.96-1.49-10.43-.6-3.77-1.25-7.53-1.97-11.28-1.13-5.83-2.24-11.66-3.55-17.45-1.76-7.74-3.63-15.46-5.61-23.15-1.27-4.93-3.11-9.67-5.69-14.09-.28-.47-.65-.91-1.05-1.29-2.43-2.31-4.84-4.63-7.32-6.88-3.68-3.35-7.31-6.75-11.14-9.92-5.38-4.47-10.81-8.9-16.42-13.08-11.22-8.36-23.42-15.08-36.09-20.98-9.9-4.61-20-8.7-30.39-12.06-7.93-2.57-16.05-4.38-24.18-6.14-4.02-.87-8.05-1.74-12.06-2.64-.64-.14-1.24-.44-1.85-.66l.06-.31c2.3,.29,4.62,.48,6.9,.9,4.7,.87,9.37,1.93,14.07,2.79,7,1.29,13.88,3.04,20.66,5.17,8.46,2.65,16.72,5.82,24.85,9.37,9.84,4.3,19.41,9.09,28.57,14.7,7.87,4.82,15.45,10.07,22.57,15.95,4.99,4.12,9.86,8.38,14.76,12.61,2.38,2.06,4.85,4.05,6.96,6.36,1.57,1.73,3.02,3.79,3.84,5.96,4.11,10.81,7.26,21.92,9.88,33.18,1.93,8.31,3.77,16.64,5.47,25,1.07,5.28,1.85,10.63,2.69,15.96,.7,4.44,1.34,8.9,1.89,13.36,.36,2.91,.59,5.84,.74,8.76,.37,7.24,.63,14.48,.99,21.71,.05,1.07-.28,1.82-1.08,2.46-.68,.54-1.33,1.11-2.17,1.81Z" />
        </g>
      </g>
    </g>
  );
};

export default GraphicBlazerIdea;
