const Anime = () => {
  const styles = {
    one: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 3,
    },
  };

  return (
    <g>
      <polyline style={styles.one} points="449 355 470.5 376.5 443.5 403.5" />
      <polyline
        style={styles.one}
        points="625.69 355.32 604.19 376.82 631.19 403.82"
      />
    </g>
  );
};

export default Anime;
