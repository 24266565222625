const Axe = () => {
  const styles = {
    one: {
      fill: "#d1d1d1",
    },
    two: {
      fill: "#938f90",
    },
    three: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    four: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 10,
    },
    five: {
      fill: "#b7b6b6",
    },
    six: {
      fill: "#684e2f",
    },
    seven: {
      fill: "#727070",
    },
    eight: {
      fill: "#7c6143",
    },
  };

  return (
    <g>
      <g>
        <path
          style={styles.two}
          d="M362.52,466.03c20.53,30.02,14.95,62.25,13.78,68.35-1.53-1.57-4.51-4.21-9.02-5.88-5.18-1.91-9.69-1.56-11.9-1.24,.84-1.79,7.17-15.81,.52-27.16-1.12-1.9-3.58-6.11-8.78-8.22-5.41-2.2-10.37-.75-11.92-.23-3.14,1.23-7.34,3.08-12.03,5.8-1.3,.75-5.05,2.97-10.03,6.91l-.04-.06-1.21,1.08c-2.11,1.72-4.4,3.74-6.79,6.07l-4.12,3.68,.18,.34c-1.08,1.16-2.15,2.36-3.24,3.63-4.12,4.81-6.17,7.22-8.16,10.91-5.92,11.02-5.67,22.18-4.98,28.48,.79,5.68,2.97,15.71,9.89,25.95,4.61,6.82,9.77,11.44,13.63,14.35-6.73-.42-50.02-3.9-78.66-41.77-21.97-29.05-21.86-60.56-21.5-69.84,3.01,4.85,9.45,13.59,18.57,15.45,3.59,.73,10,.89,14.39,.78,2.67-.07,4.89-.24,6.39-.38,16.7-1.8,30.09-5.8,39.55-9.98h.02c2.4-1.08,4.56-2.16,6.45-3.22,2.39-1.34,4.63-2.73,6.74-4.13l2.06-1.15-.1-.2c5.93-4.14,10.73-8.41,14.48-12.19,1.21-1.19,9.23-9.44,8.37-21.97-.71-10.36-7.03-16.77-8.59-18.28,7.46,2.38,29.98,10.62,46.03,34.09Z"
        />
        <path
          style={styles.six}
          d="M313.15,504.37l61.84,97.88c-7.84,4.01-19.48,12.36-19.48,12.36l-54.34-99.13c1.33-1.43,2.65-2.77,3.94-4.02l6.79-6.07c.42-.35,.85-.69,1.25-1.02Z"
        />
        <path
          style={styles.six}
          d="M290.62,460.11l11.61,24.26c-.64,.45-1.29,.9-1.96,1.35l-13.19,7.33h-.02s-16.52-21.61-16.52-21.61l13.9,.27,6.18-11.6Z"
        />
        <polygon
          style={styles.two}
          points="282.96 437.32 292.54 454.61 284.45 471.06 265.08 471.19 255.48 453.9 263.76 436.95 282.96 437.32"
        />
      </g>
      <path
        style={styles.five}
        d="M208.64,487.7c-.37,7.22-1.54,44.37,26.34,75.97,29.19,33.08,68.03,34.94,74.68,35.15-12.8-3.77-38.36-13.23-61.73-36.91-28.21-28.58-36.62-61.04-39.29-74.21Z"
      />
      <g>
        <polygon
          style={styles.five}
          points="260.01 462.05 283.52 468.02 288.42 447.19 292.53 454.61 284.45 471.06 265.08 471.19 260.01 462.05"
        />
        <path
          style={styles.one}
          d="M208.64,487.7c-.39,9.18-.59,40.81,21.5,69.84,28.98,38.09,72.96,40.96,79.53,41.28-9.07-1.32-47.6-8-75.52-43.21-21.81-27.51-24.9-57-25.51-67.9Z"
        />
        <polygon
          style={styles.eight}
          points="303.76 512.79 360.88 610.89 355.51 614.6 300.98 515.14 303.76 512.79"
        />
        <polygon
          style={styles.eight}
          points="279.68 471.16 291.81 492.07 287.06 493.06 270.55 470.98 279.68 471.16"
        />
        <path
          style={styles.seven}
          d="M242.21,503.04c4.88,5.8,8.04,12.83,11.41,19.54,1.89,3.99,3.92,8.37,5.48,12.48,0,0-.45,.25-.45,.25-5.34-7.24-10.24-15.09-14.59-22.96-1.4-2.67-2.68-5.41-3.61-8.35,0,0,1.75-.97,1.75-.97h0Z"
        />
        <path
          style={styles.seven}
          d="M229.37,502.89c4.83,5.96,8.38,12.68,12.02,19.37,3.47,6.54,7.16,13.69,10.11,20.47,0,0-.45,.26-.45,.26-3.28-4.43-6.62-9.53-9.62-14.17-4.98-8.03-10.36-16.06-13.79-24.92,0,0,1.73-1,1.73-1h0Z"
        />
        <path
          style={styles.seven}
          d="M253.55,501.5c4.3,4.86,6.88,10.92,9.68,16.7,1.55,3.43,3.21,7.2,4.44,10.76,0,0-.46,.25-.46,.25-3.09-3.96-6.04-8.56-8.67-12.85-2.65-4.45-5.35-8.83-6.74-13.9,0,0,1.76-.96,1.76-.96h0Z"
        />
        <path
          style={styles.three}
          d="M300.98,515.14l54.53,99.46s11.64-8.34,19.47-12.36l-61.87-97.93-12.13,10.83Z"
        />
        <path
          style={styles.four}
          d="M316.5,431.93c7.46,2.38,29.98,10.63,46.03,34.09,20.53,30.02,14.95,62.24,13.77,68.36-1.53-1.57-4.5-4.21-9.02-5.88-5.18-1.91-9.69-1.56-11.9-1.24,.84-1.79,7.17-15.81,.52-27.16-1.12-1.91-3.57-6.1-8.78-8.22-5.41-2.2-10.36-.75-11.92-.23-3.14,1.23-7.34,3.08-12.03,5.8-2.44,1.42-13.64,8.08-25.25,21.65-4.11,4.81-6.17,7.21-8.16,10.91-5.93,11.02-5.68,22.18-4.98,28.48,.79,5.68,2.97,15.72,9.89,25.95,4.61,6.82,9.77,11.44,13.63,14.35-6.73-.43-50.02-3.9-78.66-41.77-21.97-29.05-21.86-60.56-21.5-69.84,3.01,4.85,9.45,13.59,18.57,15.45,3.59,.73,10,.89,14.39,.78,2.68-.07,4.89-.24,6.4-.38,20.95-2.26,36.69-7.97,46.01-13.2,10.11-5.67,17.75-12.18,23.19-17.66,1.2-1.19,9.23-9.44,8.37-21.97-.71-10.36-7.02-16.77-8.59-18.28Z"
        />
        <polygon
          style={styles.three}
          points="270.54 471.44 287.06 493.06 302.33 484.57 290.62 460.11 284.44 471.71 270.54 471.44"
        />
        <polygon
          style={styles.three}
          points="263.76 436.95 255.48 453.9 265.08 471.19 284.45 471.06 292.53 454.61 282.96 437.32 263.76 436.95"
        />
      </g>
    </g>
  );
};

export default Axe;
