const ToothPick = () => {
  const styles = {
    one: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    two: {
      fill: "#dd9f76",
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="m561.21,467.57c0-.25-8.28-1.72-17.31,1.49-9.75,3.46-15.37,10.59-15.15,10.86.22.28,6.85-7.14,18.48-10.53,7.81-2.28,13.99-1.6,13.98-1.82Z"
      />
      <polygon
        style={styles.two}
        points="545.52 470.95 591.12 502.74 594.85 503.78 594.85 501.43 546.63 469.05 545.52 470.95"
      />
    </g>
  );
};

export default ToothPick;
