import NeckTattoo from "./types/NeckTattoo";
import RevengeFace from "./types/RevengeFace";
import Uzi from "./types/Uzi";
import TearDrop from "./types/TearDrop";
import AnimeScar from "./types/AnimeScar";
import IssaKnife from "./types/IssaKnife";
import KissTattoo from "./types/KissTattoo";
import Yuji from "./types/Yuji";

const Tattoo = ({ type }) => {
  switch (type) {
    case "neck_tattoo":
      return <NeckTattoo />;
    case "revenge_face":
      return <RevengeFace />;
    case "uzi_diamond":
      return <Uzi />;
    case "tear_drop":
      return <TearDrop />;
    case "anime_scar":
      return <AnimeScar />;
    case "savage_sword":
      return <IssaKnife />;
    case "kiss_tattoo":
      return <KissTattoo />;
    case "yuji_tattoo":
      return <Yuji />;
    default:
      return null;
  }
};

export default Tattoo;
