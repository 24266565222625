const AntiMainstream = () => {
  const styles = {
    one: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    two: {
      fill: "#ccc",
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="M394,334c-11.97,7.2-23.95,14.39-35.92,21.59,1.49-.2,3.98-.35,6.92,.41,2.78,.72,4.81,1.97,6,2.83,8-4.41,16-8.81,24-13.22-.33-3.87-.67-7.74-1-11.61Z"
      />
      <path
        style={styles.one}
        d="M355,412c.14,.86,1.91,10.87,10,14,2.19,.85,4.29,.98,6,.86-2.07-5.84-4.13-11.67-6.2-17.51-1.38,.58-2.99,1.15-4.81,1.65-1.83,.49-3.51,.8-5,1Z"
      />
      <path
        style={styles.one}
        d="M628,330c-24.72-.08-45.55,3.83-61,8-4.56,1.34-9.93,2.49-16,3-16.64,1.41-30.23-2.68-38.46-6-20.24-4.73-46.71-8.58-77.54-7-14.76,.76-28.17,2.63-40,5v12.61c1.58,.54,5.04,1.95,8,5.39,2.69,3.13,3.64,6.38,4,8,.42,4.74,2.6,23.13,18,37,14.06,12.67,30.28,13.57,38,14,1.54,.09,38.58,1.54,61-27,6.02-7.67,9.23-15.27,11-20.57,1.07-4.52,4.71-7.9,9-8.43,5.15-.65,10.33,2.9,11.74,8.43,1.76,6.18,6.73,20.34,20.26,31.57,1.67,1.39,26.88,21.63,56,12,3.69-1.22,23.28-7.7,31-27,3.34-8.34,3.22-15.99,2.78-20.48,.18-1.5,.67-3.94,2.22-6.52,2.3-3.84,5.56-5.69,7-6.39v-10.61c-12.83-2.71-28.73-4.94-47-5Zm-117,57c-13.59,16.16-34.45,16.05-45,16-11.34-.06-26.95-.14-38-12-15.29-16.41-8.54-41.12-8-43,8.59-4.41,26.34-12.1,50-12,24.36,.1,42.47,8.4,51,13,.58,4.48,2.47,23.18-10,38Zm140.05,2.36c-9.94,11.51-23.98,11.59-34.18,11.64-9.48,.05-28.25,.16-40.47-15.52-11.21-14.38-9.51-32.52-8.99-36.87,7.67-4.46,23.96-12.51,45.87-12.61,21.28-.1,37.25,7.36,44.97,11.64,.49,1.83,6.56,25.79-7.2,41.72Z"
      />
      <rect
        style={styles.two}
        x="659"
        y="339"
        width="12"
        height="6"
        rx="3"
        ry="3"
      />
      <rect
        style={styles.two}
        x="402"
        y="338"
        width="18"
        height="6"
        rx="3"
        ry="3"
      />
    </g>
  );
};

export default AntiMainstream;
