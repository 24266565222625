const Brrrrrr = ({ color }) => {
  const styles = {
    one: {
      fill: color,
      stroke: color,
      strokeMiterLimit: 10,
    },
    two: {
      strokeMiterLimit: 10,
      fill: "none",
      stroke: "#000",
      strokeWidth: 2,
    },
    three: {
      fill: color,
      filter: "brightness(85%)",
    },
    four: {
      fill: color,
      filter: "brightness(115%)",
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="M687,380c-.29-5.99-.88-16.2-6-28-3.79-8.72-8.63-15.03-12.16-19-1.23-8.37-10.12-63.16-55.84-87-11.99-6.25-23.79-8.96-33.75-10-1.48-2.79-4.56-7.74-10.25-12-5.39-4.03-10.67-5.62-13.71-6.3-15.73-2.11-33.61-3.6-53.29-3.7-13.11-.07-33.06,2.54-59.91,4-9.1,3.58-18.87,7.86-29.09,13-11.1,5.59-21.08,11.37-29.94,17-4.67,6.45-9.53,14.1-14.06,23-8.37,16.46-12.86,31.81-15.37,44-7.39,15.17-11.87,28.4-14.63,38-5.1,17.71-9.28,32.78-7,52,1.28,10.78,4.17,19.34,6.39,24.83,1.23,6.67,2.43,13.4,3.61,20.17,2.36,13.54,4.55,26.93,6.6,40.15,8.2,14.62,15.96,25.64,21.4,32.85,12.71,16.86,21.72,24.87,34,44,5.1,7.95,8.78,14.71,11,19,9.14,2.22,18.81,4.25,29,6,21.11,3.63,40.76,5.4,58.52,6.03,36-.25,59.05-11.33,71.48-19.03,33.01-20.47,53.74-56.08,70-84,2.35-4.03,9.38-16.36,20-33,7.36-11.53,13.71-20.83,18-27,1.14-11.68,2.18-21.54,3-29,1.79-16.38,2.31-19.56,2-26Zm-29,15s-6.58,16.87-17,35c-21.82,37.96-65.64,86.13-69.19,90-11.47,5.43-21.55,8.35-28.81,10-11.39,2.59-25.82,5.72-43.26,2.01-4.03-.86-10.35-2.24-17.74-6.01-5.41-2.76-9.94-6.17-19-13-3.76-2.84-6.85-5.26-9-6.98-11.9-7.24-21.24-14.37-28-20.02-8.85-7.39-22.21-18.7-35-37-7.21-10.32-11.71-16.75-15-27-1.79-5.57-2.79-10.71-3-44-.19-30.14,.55-35.04,1.5-39.5,2.26-10.6,4.75-22.31,13.5-32.5,15.31-17.84,39.37-18.92,46.09-19l167.98,2c10.92,7.53,25.22,19.12,38.93,36,12.41,15.28,20.39,30.06,25.5,41.5-.75,4.04-1.74,8.42-3.07,13.04-1.65,5.75-3.53,10.91-5.43,15.46Z"
      />
      <path
        style={styles.one}
        d="M673.05,774.99c-14.8,12.66-86.86,71.59-193.55,65.51-112.29-6.4-178.88-80.02-190.72-93.57-.21-13.91,.58-30.68,3.72-49.43,2.94-17.61,7.26-32.86,11.65-45.41,13.37-11.79,30.41-25.01,51.35-37.59,21.81-13.1,42.28-22.17,59.5-28.5,44.17,.83,88.33,1.67,132.5,2.5,21.14,6.47,48.98,17.94,77,39,11.02,8.29,20.39,16.79,28.29,24.9,4.74,15.86,9.18,33.61,12.71,53.1,4.62,25.5,6.78,48.91,7.55,69.49Z"
      />
      <path
        style={styles.four}
        d="M319.92,638.99s0,0,0,0c0-.3,48.98-4.62,97.58-.48,12.91,1.1,30.62,3.21,51.67,7.63,1.14-7.37,2.29-14.73,3.43-22.1-12.19-3.66-26.67-6.91-43.1-8.53-36.93-3.66-67.8,2.45-88.45,8.53-7.04,4.98-14.09,9.97-21.13,14.95Z"
      />
      <path
        style={styles.four}
        d="M353.63,315c-4.35,16.83-16.42,72.18,10.37,134,28.27,65.23,81.05,94.39,96,102-16.79-5.85-54.61-21.59-86-60-36.94-45.2-42.09-93.8-43.11-111,1.67-10.05,4.44-21.98,9.11-35,4.21-11.73,9.04-21.76,13.63-30Z"
      />
      <path
        style={styles.four}
        d="M477.14,646.13c27.89-5.25,68.04-9.15,114.86-1.13,22.71,3.89,42.5,9.82,59,16.04,.6-2.88,1.19-5.76,1.79-8.65l-29.12-25.56c-19.8-4-44-7.32-71.67-7.84-27.33-.51-51.37,1.84-71.13,5.03-1.24,7.37-2.48,14.73-3.73,22.1Z"
      />
      <path
        style={styles.four}
        d="M568.97,542.57c12.08-4.74,64.03-26.7,91.03-84.57,19.81-42.45,15.58-81.51,13.05-96.95-.3,19.78-3.45,52.33-20.05,87.95-25.16,53.98-66.09,82.55-84.03,93.57Z"
      />
      <path
        style={styles.four}
        d="M445,237c16.3-4.94,40.06-10.19,69-10,27.48,.18,50.14,5.2,66,10-19.53-2.91-42.42-5.03-68-5-25.17,.03-47.71,2.13-67,5Z"
      />
      <path
        style={styles.four}
        d="M324.98,696.85c19.17-4.44,46.49-8.49,79.02-5.85,23.61,1.92,43.59,6.83,59.01,11.87l2.35-21.98c-17.02-3.6-36.6-6.57-58.37-7.89-34.56-2.09-64.79,.62-89.02,4.61,2.33,6.41,4.67,12.83,7,19.24Z"
      />
      <path
        style={styles.four}
        d="M471.19,702.87c27.03-5.72,65.1-10.37,109.81-4.87,23.41,2.88,43.89,7.95,60.99,13.46,1.46-8.71,2.91-17.42,4.37-26.13-20.78-3.35-43.65-6-68.36-7.33-39.11-2.1-74.4-.42-104.8,2.89l-2.01,21.98Z"
      />
      <path
        style={styles.four}
        d="M447,248c-9.34,1.58-20.64,4.28-33,9-13.97,5.33-25.28,11.72-33.86,17.39,11.5-5.59,24.12-11.15,37.86-16.39,10.04-3.83,19.74-7.14,29-10Z"
      />
      <path
        style={styles.four}
        d="M443.95,228.76c-9.32,3-19.38,6.68-29.95,11.24-9.42,4.06-18.05,8.3-25.87,12.5,6.83-4.18,14.45-8.42,22.87-12.5,11.4-5.53,22.19-9.75,31.97-13.01,.33,.59,.65,1.18,.98,1.77Z"
      />
      <path
        style={styles.four}
        d="M450.22,265.09c-9.88,.99-23.76,3.46-39.22,9.91-14.81,6.18-25.96,13.89-33.5,20,9.98-5.38,21.15-10.83,33.5-16,13.97-5.84,27.16-10.37,39.22-13.91Z"
      />
      <path
        style={styles.four}
        d="M505,252.5c-7.98-.81-20.61-1.01-35,3.5-8.17,2.56-14.79,5.98-19.78,9.09-.48-2.8-.97-5.6-1.45-8.4,6.24-2.29,14.44-4.61,24.23-5.69,13.15-1.44,24.25-.09,32,1.5Z"
      />
      <path
        style={styles.four}
        d="M548,250c7.9,1.58,17.09,4.05,27,8,6.35,2.53,12.02,5.28,17,8-1.26-2.16-2.53-4.31-3.79-6.47-3.97-2.92-11.56-7.67-22.21-9.53-7.44-1.3-13.75-.74-18,0Z"
      />
      <path
        style={styles.four}
        d="M332.16,743.43c22.5-2.03,49.96-2.45,80.84,1.57,16.91,2.2,32.3,5.4,46,9,.33-6.24,.67-12.48,1-18.72-17.09-3.46-37.27-6.34-60-7.28-26.29-1.09-49.54,.66-68.77,3.35,.31,4.02,.62,8.05,.92,12.07Z"
      />
      <path
        style={styles.four}
        d="M468.74,754c25.86-4.71,58.98-8.19,97.26-6,26.55,1.52,50.02,5.46,69.77,10.07,.8-6.63,1.61-13.26,2.41-19.89-20.52-3.61-44.08-6.4-70.18-7.17-38.27-1.14-71.86,2.37-99.25,7.17,0,5.28,0,10.55,0,15.83Z"
      />
      <path
        style={styles.four}
        d="M348.51,798.49c13.59-1.01,28.86-1.36,45.49-.49,23.32,1.23,43.93,4.59,61.25,8.55,.29-5.57,.59-11.13,.88-16.7-18.39-2.64-38.5-4.74-60.13-5.85-24.56-1.26-47.34-1.06-68,0,6.84,4.83,13.67,9.66,20.51,14.49Z"
      />
      <path
        style={styles.four}
        d="M466.88,807.61c24.6-3.47,52.48-6.04,83.12-6.61,29.93-.56,57.29,.88,81.55,3.32l1.11-14.47c-23.28-3.31-50.04-5.69-79.66-5.85-32.27-.18-61.23,2.32-86.11,5.85,0,5.92,0,11.84,0,17.76Z"
      />
      <path
        style={styles.four}
        d="M593.23,266.37c9.29,3.48,24.1,9.99,36.77,20.63,5.52,4.63,8.75,8.46,10,10,5.8,7.13,9.11,14.1,11,19-4.9-7.53-12-16.82-22-26-13.1-12.02-26.22-19.29-35.77-23.63Z"
      />
      <path
        style={styles.four}
        d="M587.25,252.6c6.31,.68,18.8,2.86,31.75,11.4,13.12,8.65,20.08,19.44,23.17,25-5.68-6.09-13.31-13.2-23.17-20-11.79-8.13-22.98-13.19-31.75-16.4Z"
      />
      <path
        style={styles.four}
        d="M583.2,242.78l1.74,6.44c6.51,.57,15.29,2.03,25.06,5.78,11.67,4.47,20.27,10.48,25.82,15.04-4.73-4.82-11.58-10.78-20.82-16.04-12.42-7.07-24.01-9.95-31.8-11.22Z"
      />
      <g>
        <rect
          x="475.18"
          y="601.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(125.11 -80.9) rotate(11.12)"
        />
        <rect
          x="476.98"
          y="604.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(125.83 -81.18) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="474.18"
          y="608.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(126.45 -80.58) rotate(11.12)"
        />
        <rect
          x="475.98"
          y="611.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(127.16 -80.86) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="473.18"
          y="615.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(127.78 -80.25) rotate(11.12)"
        />
        <rect
          x="474.98"
          y="618.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(128.5 -80.53) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="472.18"
          y="622.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(129.11 -79.93) rotate(11.12)"
        />
        <rect
          x="473.98"
          y="625.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(129.83 -80.21) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="471.18"
          y="628.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(130.25 -79.62) rotate(11.12)"
        />
        <rect
          x="472.98"
          y="631.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(130.96 -79.9) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="470.18"
          y="635.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(131.58 -79.3) rotate(11.12)"
        />
        <rect
          x="471.98"
          y="638.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(132.29 -79.58) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="469.18"
          y="642.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(132.91 -78.97) rotate(11.12)"
        />
        <rect
          x="470.98"
          y="645.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(133.63 -79.26) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="468.18"
          y="649.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(134.24 -78.65) rotate(11.12)"
        />
        <rect
          x="469.98"
          y="652.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(134.96 -78.93) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="468.18"
          y="656.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(135.59 -78.52) rotate(11.12)"
        />
        <rect
          x="469.98"
          y="659.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(136.31 -78.8) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="467.18"
          y="663.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(136.92 -78.19) rotate(11.12)"
        />
        <rect
          x="468.98"
          y="666.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(137.64 -78.48) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="466.18"
          y="670.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(138.25 -77.87) rotate(11.12)"
        />
        <rect
          x="467.98"
          y="673.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(138.97 -78.15) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="465.18"
          y="677.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(139.58 -77.55) rotate(11.12)"
        />
        <rect
          x="466.98"
          y="680.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(140.3 -77.83) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="464.18"
          y="684.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(140.91 -77.22) rotate(11.12)"
        />
        <rect
          x="465.98"
          y="687.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(141.63 -77.5) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="463.18"
          y="690.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(142.05 -76.92) rotate(11.12)"
        />
        <rect
          x="464.98"
          y="693.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(142.77 -77.2) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="463.18"
          y="697.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(143.4 -76.79) rotate(11.12)"
        />
        <rect
          x="464.98"
          y="700.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(144.12 -77.07) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="462.18"
          y="704.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(144.73 -76.46) rotate(11.12)"
        />
        <rect
          x="463.98"
          y="707.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(145.45 -76.74) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="461.18"
          y="710.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(145.87 -76.16) rotate(11.12)"
        />
        <rect
          x="462.98"
          y="713.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(146.59 -76.44) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="461.18"
          y="717.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(147.22 -76.02) rotate(11.12)"
        />
        <rect
          x="462.98"
          y="720.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(147.94 -76.31) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="461.18"
          y="724.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(148.57 -75.89) rotate(11.12)"
        />
        <rect
          x="462.98"
          y="727.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(149.29 -76.17) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="460.18"
          y="731.38"
          width="6.57"
          height="2.1"
          rx=".93"
          ry=".93"
          transform="translate(149.91 -75.61) rotate(11.12)"
        />
        <rect
          x="462.17"
          y="734.97"
          width="6.57"
          height="2.1"
          rx=".93"
          ry=".93"
          transform="translate(150.64 -75.93) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="460.18"
          y="738.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(151.25 -75.44) rotate(11.12)"
        />
        <rect
          x="461.98"
          y="741.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(151.97 -75.72) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="460.18"
          y="745.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(152.6 -75.31) rotate(11.12)"
        />
        <rect
          x="461.98"
          y="748.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(153.32 -75.59) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="459.18"
          y="752.33"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(153.93 -74.98) rotate(11.12)"
        />
        <rect
          x="460.98"
          y="755.88"
          width="6.08"
          height="2.1"
          rx=".9"
          ry=".9"
          transform="translate(154.65 -75.26) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="458.21"
          y="759.21"
          width="6.78"
          height="2.1"
          rx=".95"
          ry=".95"
          transform="translate(155.24 -74.73) rotate(11.12)"
        />
        <rect
          x="460.29"
          y="762.82"
          width="6.78"
          height="2.1"
          rx=".95"
          ry=".95"
          transform="translate(155.98 -75.07) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="458.21"
          y="767.21"
          width="6.78"
          height="2.1"
          rx=".95"
          ry=".95"
          transform="translate(156.78 -74.58) rotate(11.12)"
        />
        <rect
          x="460.29"
          y="770.82"
          width="6.78"
          height="2.1"
          rx=".95"
          ry=".95"
          transform="translate(157.52 -74.92) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="457.2"
          y="774.27"
          width="7.4"
          height="2.1"
          rx=".99"
          ry=".99"
          transform="translate(158.13 -74.32) rotate(11.12)"
        />
        <rect
          x="459.53"
          y="777.92"
          width="7.4"
          height="2.1"
          rx=".99"
          ry=".99"
          transform="translate(158.88 -74.7) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="457.2"
          y="781.27"
          width="7.4"
          height="2.1"
          rx=".99"
          ry=".99"
          transform="translate(159.48 -74.19) rotate(11.12)"
        />
        <rect
          x="459.53"
          y="784.92"
          width="7.4"
          height="2.1"
          rx=".99"
          ry=".99"
          transform="translate(160.23 -74.57) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="456.19"
          y="788.36"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(160.84 -73.95) rotate(11.12)"
        />
        <rect
          x="458.9"
          y="792.1"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(161.61 -74.4) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="455.19"
          y="795.36"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(162.17 -73.63) rotate(11.12)"
        />
        <rect
          x="457.9"
          y="799.1"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(162.94 -74.08) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="455.19"
          y="802.36"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(163.52 -73.5) rotate(11.12)"
        />
        <rect
          x="457.9"
          y="806.1"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(164.29 -73.95) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="455.19"
          y="809.36"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(164.87 -73.37) rotate(11.12)"
        />
        <rect
          x="457.9"
          y="813.1"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(165.64 -73.82) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="455.19"
          y="816.36"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(166.22 -73.23) rotate(11.12)"
        />
        <rect
          x="457.9"
          y="820.1"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(166.99 -73.69) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="455.19"
          y="824.36"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(167.76 -73.08) rotate(11.12)"
        />
        <rect
          x="457.9"
          y="828.1"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(168.54 -73.54) rotate(11.12)"
        />
      </g>
      <g>
        <rect
          x="454.19"
          y="831.36"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(169.09 -72.76) rotate(11.12)"
        />
        <rect
          x="456.9"
          y="835.1"
          width="8.38"
          height="2.11"
          rx="1.06"
          ry="1.06"
          transform="translate(169.87 -73.21) rotate(11.12)"
        />
      </g>
      <path
        style={styles.three}
        d="M445,235c10.88-5.95,34.77-17.12,67-17,31.66,.11,55.13,11.06,66,17-16.83-4.11-38.95-7.86-65-8-27.42-.15-50.62,3.76-68,8Z"
      />
      <path
        style={styles.three}
        d="M512.54,538.55c-3.86,.21-8.63,1.07-13.54,3.45-2.68,1.3-10.66,5.31-14.67,13.86-.63,1.34-1.01,2.46-2.33,9.14-.27,1.38-6.25,38.12-5.22,30.98h0l4.22,.02c1.74-3.21,3.56-7.23,5-12,.94-3.1,1.55-5.99,1.96-8.58-.17-1.73-.24-3.92,.04-6.42,.29-2.57,.86-4.75,1.43-6.43,1.07-3.11,4.58-11.99,13.57-16.57,2.19-1.12,4.32-1.78,6.22-2.18,6.28-.23,14.51-1.13,23.78-3.82,8.84-2.56,15.99-5.98,21.3-9-6.37,2.3-14.2,4.56-23.3,6-6.8,1.08-13.02,1.49-18.46,1.55Z"
      />
      <path
        style={styles.three}
        d="M476,601c.33-2.02,1.11-4.16,3.08-4.41-16.33-1.44-38.51-4.36-65.4-10.59-2.6,1.17-5.19,2.33-7.79,3.5,14.25,6.81,38.93,15.69,69.12,19.5,.16-3.09,.5-4.95,1-8Z"
      />
      <path
        style={styles.three}
        d="M554.3,589.5c-8.44,2.75-20.52,5.97-35.3,7.5-10.91,1.13-20.88,1.08-35,0-.93-.07-1.89-.15-2.86-.23,.67,.21,1.25,.54,1.72,.95,1.83,1.61,1.65,4.26,1.59,5.13-.12,1.93-.92,3.37-1.45,4.15,7.1,1.04,14.79,1.78,23,2,28.18,.77,52.08-4.73,69.8-10.75l-21.5-8.75Z"
      />
      <path
        style={styles.three}
        d="M454,287c-5.17-1.27-35.56-8.25-60,10-12.5,9.33-18.58,21.64-21.03,26.73-6.55,13.61-7.28,25.28-7.97,38.27-.82,15.46-1.99,37.56,8,64,9.19,24.32,23.44,40.73,32.88,49.91,4.45,5.31,9.47,10.72,15.12,16.09,22.98,21.83,47.54,34.49,67,42,7.41,2.47,21.43,6.08,39,4,17.58-2.08,30.37-8.86,37-13-31.66,14.97-53.66,10.41-62,8-13.66-3.94-26.14-12.33-45-25-9.48-6.37-16.99-12.06-22-16-7.03-3.74-15.99-9.45-25-18-11.78-11.18-18.93-22.76-23.13-30.96-3.67-4.95-8.81-13.06-11.87-24.04-2.68-9.61-2.49-18.87-2-37,.33-12.29,.54-20.1,2-31,1.39-10.37,3.98-24.14,9.19-40,3.92-5.12,11.84-13.98,24.81-20,20.21-9.38,39.01-5.5,45-4Z"
      />
      <path
        style={styles.three}
        d="M331.78,731.58c17.21-2.94,39.86-5.32,66.33-4.2,24.3,1.03,44.97,4.72,60.96,8.62,.31-2.58,.62-5.16,.92-7.74-15.52-2.93-33.61-5.39-53.84-6.39-29.15-1.45-54.65,.51-75.16,3.4,.26,2.1,.52,4.2,.78,6.31Z"
      />
      <path
        style={styles.three}
        d="M318.78,677.58c19.33-2.62,41.62-4.41,66.33-4.2,30.45,.26,57.38,3.49,79.89,7.62,.33-2.33,.67-4.67,1-7-18.84-4.96-44.06-9.66-74-10-31.27-.35-57.59,4.17-77,9l3.78,4.58Z"
      />
      <path
        style={styles.three}
        d="M473.78,681.58c20.25-2.35,42.44-3.94,66.33-4.2,39.34-.42,74.63,2.88,104.89,7.62,1-2.33,2-4.67,3-7-26.69-5.94-60.24-10.92-99-11-28.28-.06-53.53,2.5-75,6-.07,2.86-.14,5.72-.22,8.58Z"
      />
      <path
        style={styles.three}
        d="M470,738c26.16-4.45,57.92-7.73,94-7,27.6,.56,52.4,3.35,73.79,7,.4-2.58,.81-5.16,1.21-7.74-20.33-2.93-44.03-5.39-70.54-6.39-38.18-1.45-71.6,.51-98.46,3.4v10.73Z"
      />
      <path
        style={styles.three}
        d="M468.01,788.25c24.63-3.42,53.34-5.79,85.35-5.45,29.28,.3,55.69,2.8,78.64,6.2,.33-1.69,.67-3.37,1-5.06-21.9-4.34-47.81-7.9-77-8.94-34.13-1.21-64.22,1.34-89,5.05l1.01,8.2Z"
      />
      <path
        style={styles.three}
        d="M480,624c17.58-2.73,37.73-4.73,60-5,31.52-.38,59.23,2.81,82,7l-18-12c-21.37-1.4-44.81-1.92-70-1-18.79,.69-36.5,2.11-53,4-.33,2.33-.67,4.67-1,7Z"
      />
      <path
        style={styles.three}
        d="M346,622c17.61-3.93,40.84-7.33,68.11-6.62,22.72,.59,42.32,3.88,57.89,7.62,.67-2.33,1.33-4.67,2-7-14.69-3.01-32.14-5.48-51.84-6.13-26.25-.87-49.05,1.74-67.16,5.13-3,2.33-6,4.67-9,7Z"
      />
      <path
        style={styles.three}
        d="M326.78,782.58c18.29-.5,37.74-.44,58.22,.42,25.36,1.06,49.09,3.21,71,6,.33-2.33,.67-4.67,1-7-19.17-2.75-40.25-4.92-63-6-24.58-1.16-47.36-.87-68,.27l.78,6.31Z"
      />
      <path
        style={styles.three}
        d="M571.81,520c14.11-7.4,45.83-26.46,69.19-65,22.34-36.85,25.24-72.06,25.58-88-1.96,8.25-5.19,19.77-10.58,33-6.44,15.8-14.04,29.3-26,47-12.01,17.77-30.81,43.31-58.19,73Z"
      />
      <path
        style={styles.three}
        d="M656.37,664.38c-3.08,14.22-5.9,29.1-8.37,44.62-5.16,32.48-8.01,63.02-9.3,91.16-2.39,1.39-4.77,2.78-7.15,4.16,1.91-24.46,4.65-50.3,8.45-77.32,3.69-26.26,8.04-51.16,12.79-74.6,1.19,3.99,2.39,7.99,3.58,11.98Z"
      />
      <path
        style={styles.three}
        d="M300.96,659.16c6.21,14.49,12.66,32.94,17.04,54.84,5.18,25.86,5.9,48.59,5.16,66.17,1.61,1.28,3.23,2.55,4.84,3.83,3.03-14.47,6.24-38.74,1-68-5.29-29.57-16.91-51.4-24.85-63.94l-3.19,7.1Z"
      />
      <line style={styles.two} x1="442.09" y1="218" x2="454" y2="287" />
      <path d="M339.34,429.51c14.21,41.93,43.17,78.71,80.9,101.94,12.57,7.75,25.9,14.21,39.76,19.55-28.73-7.99-54.74-24.57-75.95-45.38-15.9-15.76-28.97-34.37-38.38-54.68-3.15-6.76-5.89-13.71-8.22-20.8,0,0,1.9-.63,1.9-.63h0Z" />
      <path
        style={styles.two}
        d="M445.2,236c16.91-4.63,40.49-9.2,68.8-9,26.63,.19,48.93,4.54,65.25,9,7.61,17.67,15.21,35.33,22.82,53"
      />
      <path d="M448.2,248.25c-24.22,3.84-47.33,13.11-68.06,26.14,14.8-11.11,31.87-19.1,49.64-24.15,5.94-1.69,12-2.98,18.1-3.96,0,0,.32,1.97,.32,1.97h0Z" />
      <path d="M586.46,251.5c23.21,2.21,45.26,16.52,55.71,37.5-9.38-14.38-23.08-25.95-39.35-31.63-5.36-1.88-10.93-3.2-16.58-3.88l.22-1.99h0Z" />
      <path d="M443.9,227.75c-19.5,5.98-38.29,14.23-55.77,24.75,12.73-8.56,26.41-15.82,40.68-21.49,4.76-1.91,9.6-3.64,14.5-5.17l.59,1.91h0Z" />
      <path d="M450.35,266.08c-25.99,4.47-51.08,13.88-72.85,28.92,15.22-12.97,33.47-22.47,52.83-27.55,6.48-1.68,13.1-2.83,19.76-3.35,0,0,.25,1.98,.25,1.98h0Z" />
      <path d="M582.23,241.64c18.25,2.28,35.66,10.65,48.88,23.42,1.87,1.82,3.66,3.73,5.36,5.69-7.3-7.39-15.76-13.59-25.05-18.19-9.24-4.62-19.25-7.59-29.45-8.94,0,0,.25-1.98,.25-1.98h0Z" />
      <path d="M592.28,265.04c12.93,3.96,24.95,10.52,35.53,18.89,10.73,7.99,19.84,18.9,23.18,32.06-5.55-19.8-23.31-32.76-40.7-41.88-5.96-2.97-12.19-5.35-18.58-7.16l.57-1.92h0Z" />
      <path d="M448.42,255.75c17.78-7.24,38.1-8.53,56.58-3.25-14.08-1.75-28.37-1.45-42.25,1.49-4.61,.96-9.14,2.22-13.63,3.64,0,0-.69-1.88-.69-1.88h0Z" />
      <path d="M589.32,261.61c-11.74-8.85-26.66-13.18-41.32-11.61,3.63-.82,7.38-1.2,11.13-1.17,7.54,.03,15.08,1.82,21.88,5.09,3.4,1.68,6.63,3.7,9.54,6.12l-1.23,1.57h0Z" />
      <path d="M673.05,361.05c1.44,55.02-21.86,109.57-60.95,148.07-12.95,12.86-27.43,24.18-43.13,33.45,26.52-17.5,49.8-39.94,67.29-66.46,22.69-33.97,34.91-74.34,36.78-115.05h0Z" />
      <path
        style={styles.two}
        d="M564,525c-32.32,17.37-56.53,13.79-65,12,0,0-20.6-3.97-60-31-3.99-2.74-8.86-6.09-15-11-16.21-12.95-31.96-25.44-44-47-10.28-18.4-13.73-35.98-15-46-.77-6.08-.97-11.24-1-15,.33-8.33,.67-16.67,1-25-.07-2.43-.11-5.88,0-10,.16-6.18,.63-12.05,1-15,.89-7.03,3.92-18.21,11-28,9.28-12.84,21.65-17.96,29-21,19.64-8.13,37.72-6.55,47-5"
      />
      <path
        style={styles.two}
        d="M484.33,597.08c-3.56,21.41-6.71,43.73-9.33,66.92-.64,5.67-1.24,11.3-1.8,16.89-1.96,19.61-3.42,38.71-4.45,57.28-.99,17.73-1.59,34.97-1.86,51.68-.29,16.93-.22,33.33,.11,49.15-4.33-.67-8.67-1.33-13-2,.52-15.44,1.22-31.16,2.13-47.15,1.02-17.86,2.3-36.05,3.87-54.57,1.51-17.84,3.29-35.97,5.36-54.39,1.41-12.5,2.95-25.13,4.64-37.89,.84-6.37,1.71-12.69,2.6-18.97,1.34-9.45,2.74-18.81,4.18-28.05,.07,.01,.15,.02,.22,.02,2.25,.39,4.7,.76,7.33,1.08Z"
      />
      <path
        style={styles.two}
        d="M304.15,652.09c5.25,7.98,10.41,17.26,14.85,27.91,17.38,41.68,13.96,80.89,10,103,21.21-.68,44.3-.51,69,1,20.69,1.26,40.11,3.32,58.13,5.85"
      />
      <path
        style={styles.two}
        d="M652.44,654.04h0c-4.23,20.21-8.1,41.55-11.44,63.96-4.53,30.4-7.55,59.26-9.45,86.32"
      />
      <path
        style={styles.two}
        d="M317.98,677.61c21.98-3.34,48.7-5.65,79.02-4.61,25.92,.89,48.93,4.04,68.37,7.89"
      />
      <path
        style={styles.two}
        d="M473.2,680.89c24.11-2.73,51.57-4.42,81.8-3.89,34.25,.61,64.96,3.96,91.36,8.33"
      />
      <path
        style={styles.two}
        d="M341.05,624.03c16.94-4.76,40.98-9.53,69.95-9.03,25.03,.43,46.04,4.66,61.6,9.03"
      />
      <path
        style={styles.two}
        d="M331.23,731.35c17.9-2.39,39.05-3.97,62.77-3.35,25.21,.66,47.46,3.65,66,7.28"
      />
      <path
        style={styles.two}
        d="M623.68,626.85s-.01,0-.01-.01c-20.12-4.05-44.65-7.39-72.67-7.84-26.85-.44-50.54,1.88-70.13,5.03"
      />
      <path
        style={styles.two}
        d="M468.74,738.17c25.67-4.41,56.84-7.71,92.26-7.17,29.06,.44,55.03,3.37,77.18,7.17"
      />
      <path
        style={styles.two}
        d="M466.88,789.85c25.95-3.71,56.28-6.27,90.12-5.85,27.95,.34,53.34,2.66,75.66,5.85"
      />
      <path
        style={styles.two}
        d="M575.8,515.72c-1.88,1.71-3.98,3.5-6.32,5.33-4.26,3.32-9.3,6.74-15.18,9.95-9.96,5.44-19.81,8.9-28.62,11.11-14.33,3.6-15.68-.31-22.41,3.61-9.69,5.65-15.63,17.69-15.27,24.28,.04,.64,.2,2.47,0,5-.17,2.22-.6,4.74-2,9-1.02,3.1-2.58,7.24-5,12"
      />
      <path
        style={styles.two}
        d="M660.91,387c-3.22,9.56-9.9,27.2-26.09,53-22.08,35.2-49.01,65.15-63.01,80-.75,.35-1.53,.7-2.33,1.05-8.1,3.57-18.52,7.33-31.05,9.95-9.82,2.05-18.73,2.95-26.24,3.26-19.82-2.53-32.52-8.71-40.67-14.26-3.91-2.66-4.84-3.86-12.48-10-15.28-12.28-26.78-19.71-31.46-22.99-35.15-24.63-49.06-60.01-49.06-60.01-7.77-20.37-6.69-37.25-4.52-71,1.45-22.58,2.33-34.09,10.19-45,14.56-20.21,40.94-23.33,49.9-24,55.99,.67,111.99,1.33,167.98,2,10.78,7.55,24.36,18.63,37.29,34,13.83,16.45,22.16,32.19,27.22,44-1.12,4.81-2.91,11.77-5.67,20Zm-184.13,208.98c-2.91-.18-6.55-.46-10.74-.86-1.12-.11-8.68-.85-17.04-2.12-12.42-1.88-23.79-4.33-34-7-7.32-14.86-15.1-25.71-21-33-6.46-7.98-43.94-52.8-46-65-.01-.09-.1-.59-.22-1.33-.42-2.43-1.36-7.84-2.78-14.67-1.93-9.31-2.91-12.46-4-18-1.1-5.59-2.35-13.79-2.61-24.17-3.27-8.28-6.44-19.06-7.5-31.83-.55-6.6-.44-12.64,0-18,1.64-9.27,4.14-19.73,7.94-31,4.42-13.1,9.66-24.47,14.8-34,2.85-12.65,7.63-27.75,15.82-44,4.34-8.6,9-16.27,13.61-23,6.33-3.99,13.13-8.02,20.41-12,6.01-3.28,11.87-6.28,17.53-9,2.92-1.68,6.26-3.39,10-5,4.36-1.88,8.44-3.26,12.11-4.3,13.56-1.87,28.78-3.27,45.42-3.7,25.37-.66,47.9,1.11,66.76,3.7,3.49,.91,10.02,3.09,16.02,8.3,4.19,3.63,6.59,7.41,7.94,10,6.11,.57,14.02,1.9,22.68,5,14.87,5.31,24.76,13.25,30.23,18.42,9.82,10.49,22.71,27.21,30.84,50.58,2.87,8.25,4.69,16.01,5.84,23,3.85,4.35,7.99,9.99,11.34,17,1.17,2.43,2.12,4.77,2.9,7,2.72,8.54,3.59,15.51,3.92,20,.98,13.5-1.79,21.13-4,45-.53,5.69-.83,10.29-1,13-6.32,9.26-12.66,18.92-19,29-4.89,7.78-9.55,15.45-14,23-5.13,8.71-9.95,15.46-13,20-24.86,45.42-58.57,75.12-86,84-18.14,5.87-38,7-38,7-5.12,.29-10.82,.36-16.99,.03-6.71-.36-12.82-1.12-18.23-2.05Z"
      />
      <g>
        <path d="M288.5,747.5c-.65-8.07-.85-16.18-.57-24.29,.28-8.11,.99-16.21,2.13-24.26,1.13-8.05,2.64-16.05,4.57-23.96,1.96-7.9,4.31-15.71,7.11-23.35l.16-.43,.35-.3c4.15-3.54,8.39-6.98,12.73-10.29,4.3-3.36,8.76-6.51,13.25-9.61,9-6.17,18.26-11.93,27.62-17.51,9.4-5.5,18.93-10.8,28.72-15.58,4.92-2.34,9.84-4.67,14.88-6.74,5.05-2.05,10.27-3.65,15.33-5.63h0c-5.05,2.02-9.86,4.61-14.75,6.96-4.9,2.32-9.82,4.6-14.66,7.06-9.71,4.83-19.26,9.97-28.53,15.6-9.26,5.64-18.22,11.76-26.88,18.26-4.34,3.24-8.62,6.55-12.87,9.9-4.23,3.37-8.44,6.77-12.59,10.24l.51-.73c-2.77,7.54-5.25,15.18-7.34,22.94-2.13,7.75-3.9,15.6-5.27,23.53-1.36,7.93-2.37,15.93-2.99,23.97-.63,8.04-.93,16.12-.91,24.21Z" />
        <path d="M671.28,775.15c-.18-5.17-.29-10.35-.61-15.52-.3-5.17-.79-10.33-1.34-15.47-.56-5.15-1.29-10.27-2.09-15.39-.84-5.11-1.79-10.2-2.79-15.27-2.07-10.15-4.06-20.32-6.31-30.42-2.27-10.1-4.7-20.16-7.4-30.17l.31,.56c-6.65-7.28-13.73-14.19-21.31-20.53-7.6-6.3-15.67-12.03-24-17.35-4.17-2.65-8.43-5.15-12.76-7.54-4.31-2.42-8.69-4.72-13.14-6.88-4.46-2.15-8.95-4.22-13.52-6.15-4.56-1.95-9.19-3.7-13.82-5.5v-.03c4.78,1.4,9.45,3.07,14.11,4.8,4.65,1.75,9.26,3.62,13.79,5.67,4.53,2.04,9.01,4.22,13.42,6.54,4.38,2.36,8.7,4.84,12.93,7.48,8.47,5.24,16.57,11.08,24.26,17.41,7.73,6.28,15.11,13.02,22,20.23l.21,.22,.1,.33c2.95,9.97,5.68,20.03,8.01,30.17,2.36,10.15,4.4,20.34,6.06,30.62,.83,5.14,1.56,10.3,2.19,15.47,.67,5.16,1.21,10.34,1.72,15.52,.52,5.18,.89,10.37,1.23,15.56,.2,2.59,.24,5.2,.38,7.79l.38,7.8-2,.05Z" />
        <path d="M515.83,543.7c-.2-.02-.04,.05,.44,.21,.21,.07,.21,.09,.17,.09-.1,0-.53-.07-.67-.09-.05,.1-.45-.03-1.02-.14-.58-.21-.9-.26-.91-.39-.13-.06-.54-.21-.62-.26-.04-.03-.03-.04,.19,0,.49,.1,.67,.12,.49,.04l1.93,.53h0Z" />
        <path d="M517.42,538.76c-.13-.04-.08-.02,.02,.02l.59,.18c.18,.05-.24-.07-.53-.14l-.09,.22c-.18-.1-.02-.04-.12-.09-.2-.12-1.57-.51-1.74-.72-.1-.08,.08-.04-.09-.16l-.09,.22c-.27-.1-.69-.26-.5-.25,.11,.01,.5,.11,.61,.13,.11,.02,.16,.02,.03-.04l1.9,.63h0Z" />
      </g>
    </g>
  );
};

export default Brrrrrr;
