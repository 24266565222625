import { Grid, Typography } from "@mui/material";
import {
  cardVeryLight,
  normal,
  small,
  text,
} from "../../utils/themeContstants";

const DetailContainer = ({
  icon,
  label,
  value,
  bg = cardVeryLight,
  firstTo = false,
  stretch = false,
  size,
  killLeadLabel = null,
  killLead = false,
}) => {
  
  const styles = {
    container: {
      borderRadius: 2,
      backgroundColor: bg,
      padding: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      minHeight: "100%",
      border: `1px solid rgba(197, 215, 232, 0.1)`,
      flexGrow: stretch ? 1 : null,
    },
    label: {
      fontSize: size === "small" ? small : normal,
      fontWeight: 700,
      color: text,
      whiteSpace: "nowrap",
    },
    value: {
      fontSize: size === "small" ? small : normal,
      fontWeight: 500,
      color: text,
      whiteSpace: "nowrap",
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: size === "small" ? 0 : 0.5 }}
      >
        <Grid item sx={styles.icon}>
          {icon}
        </Grid>

        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={styles.label}>{label}</Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.value}>
                {value}
                {firstTo && value !== 1 && <sup>+2</sup>}
                {killLeadLabel != null && killLead && (
                  <> {`(${killLeadLabel})`}</>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailContainer;
