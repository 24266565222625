import Anonymous from "./types/Anonymous";
import Arnold from "./types/Arnold";
import Astronaut from "./types/Astronaut";
import BandanaHat from "./types/BandanaHat";
import Baseball from "./types/Baseball";
import Beanie from "./types/Beanie";
import BeanieTwo from "./types/BeanieTwo";
import Beats from "./types/Beats";
import BoarMask from "./types/BoarMask";
import BurberryBucket from "./types/BurberryBucket";
import ChromeBeanie from "./types/ChromeBeanie";
import Cowboy from "./types/Cowboy";
import Crown from "./types/Crown";
import Doctor from "./types/Doctor";
import Durag from "./types/Durag";
import DuragFlatBill from "./types/DuragFlatBill";
import DuragTwo from "./types/DuragTwo";
import FlatBill from "./types/FlatBill";
import Gucci from "./types/Gucci";
import Headband from "./types/Headband";
import Hokage from "./types/Hokage";
import Ichigo from "./types/Ichigo";
import Jason from "./types/Jason";
import Naughty from "./types/Naughty";
import Nice from "./types/Nice";
import Obito from "./types/Obito";
import PartyHat from "./types/PartyHat";
import Pennywise from "./types/Pennywise";
import Pharaoh from "./types/Pharaoh";
import Pilot from "./types/Pilot";
import Pirate from "./types/Pirate";
import Santa from "./types/Santa";
import SkiMask from "./types/SkiMask";
import TacSquad from "./types/TacSquad";
import TheChef from "./types/TheChef";
import Tonka from "./types/Tonka";
import Turban from "./types/Turban";
import VloneMask from "./types/VloneMask";
import York from "./types/York";
import Joker from "./types/Joker";
import MimeHat from "./types/MimeHat";
import OnePiece from "./types/OnePiece";
import Sakonji from "./types/Sakonji";

const Hat = ({ type, color }) => {
  switch (type) {
    case "beanie_hat":
      return <Beanie />;
    case "beanie_2_hat":
      return <BeanieTwo />;
    case "pirate_hat":
      return <Pirate />;
    case "durag_hat":
      return <Durag />;
    case "durag_2_hat":
      return <DuragTwo />;
    case "cowboy_hat":
      return <Cowboy />;
    case "pilot_hat":
      return <Pilot />;
    case "baseball_hat":
      return <Baseball />;
    case "santa_hat":
      return <Santa />;
    case "ski_mask_hat":
      return <SkiMask color={color} />;
    case "tac_squad":
      return <TacSquad />;
    case "astronaut":
      return <Astronaut />;
    case "doctor":
      return <Doctor />;
    case "turban":
      return <Turban color={color} />;
    case "naughty":
      return <Naughty />;
    case "nice":
      return <Nice />;
    case "party_hat":
      return <PartyHat />;
    case "ichigo":
      return <Ichigo />;
    case "terminator":
      return <Arnold />;
    case "chrome_beanie":
      return <ChromeBeanie />;
    case "york":
      return <York />;
    case "gucci_band":
      return <Gucci />;
    case "jason":
      return <Jason />;
    case "vlone_mask":
      return <VloneMask />;
    case "bandana_hat":
      return <BandanaHat color={color} />;
    case "beats":
      return <Beats />;
    case "boar_mask":
      return <BoarMask />;
    case "burb_bucket":
      return <BurberryBucket />;
    case "durag_flat_bill":
      return <DuragFlatBill color={color} />;
    case "flat_bill":
      return <FlatBill color={color} />;
    case "anonymous":
      return <Anonymous />;
    case "headband":
      return <Headband />;
    case "hokage":
      return <Hokage />;
    case "the_chef":
      return <TheChef />;
    case "yeat_fuzzy":
      return <Tonka color={color} />;
    case "crown":
      return <Crown />;
    case "obito":
      return <Obito />;
    case "pennywise":
      return <Pennywise />;
    case "pharaoh":
      return <Pharaoh />;
    case "joker":
      return <Joker />;
    case "mime_hat":
      return <MimeHat />;
    case "one_piece":
      return <OnePiece />;
    case "sakonji":
      return <Sakonji />;
    default:
      return null;
  }
};

export default Hat;
