import styled from "styled-components";
import {
  cardLight,
  doubtedBlue,
  secondaryButton,
  secondaryText,
  text,
} from "../../utils/themeContstants";

const Input = styled.input`
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${(props) => props.placeholderColor};
    font-weight: 500;
    font-size: 15px;
  }
  :-ms-input-placeholder {
    color: ${(props) => props.placeholderColor};
  }
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-radius: ${(props) => props.borderRadius}px;
  border: ${(props) =>
    props.type === "number" ? "none" : `2px solid ${props.border}`};
  min-height: ${(props) => props.height}px;
  font-size: 15px;
  padding-left: 16px;
  padding-right: 16px;
  outline: none;
  font-family: "Inter";
  font-weight: 600;
  min-width:  ${(props) => props.width}%;
  webkit-appearance: none;
  border: 2px solid ${(props) => props.border};
  transition: all 0.2s ease-in-out;
  &:hover {
    border: 2px solid ${(props) => props.borderHover};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "text")};
  }
  &:focus {
    border: 2px solid ${(props) => props.borderHover};
  }
  ::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  }
`;

const CustomInput = ({
  placeholder,
  value,
  onChange,
  onKeyDown,
  onBlur,
  onFocus,
  type = "text",
  disabled = false,
  autoFocus = false,
  onPaste,
  backgroundColor = "#161924",
  borderColor = cardLight,
  borderRadius = 8,
  width = 100,
  height = 40,
}) => {
  return (
    <Input
      border={disabled ? "#60636B" : borderColor}
      borderHover={disabled ? "#60636B" : doubtedBlue}
      borderRadius={borderRadius}
      backgroundColor={disabled ? secondaryButton : backgroundColor}
      color={disabled ? secondaryText : text}
      placeholderColor={secondaryText}
      type={type}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      width={width}
      height={height}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          onKeyDown();
        }
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      autoFocus={autoFocus}
      onPaste={(e) => onPaste(e.target.value)}
      maximumScale={1}
      initialScale={1}
    />
  );
};

export default CustomInput;
