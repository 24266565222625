import axios from "axios";
import { API_ROUTE, errMessage } from "../utils/constants";

export const getMatches = async (
  skip,
  region,
  game_match_type,
  team_size,
  platform,
  round_lead,
  home,
  game
) => {
  const params = {
    skip,
    region,
    game_match_type,
    team_size,
    platform,
    round_lead,
    home,
    game,
  };

  return await axios
    .get(`${API_ROUTE}/match`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const createMatch = async (
  useMiddleware,
  entry_fee,
  region,
  game_match_type,
  team_id,
  first_to,
  public_match,
  match_type,
  putting_up,
  team_size,
  platform,
  best_of,
  game,
  game_map_type,
  extra_options,
  game_mode_options
) => {
  const gameModeOptions = { options: game_mode_options };
  return await useMiddleware
    .post(`${API_ROUTE}/match/create`, {
      entry_fee,
      region,
      game_match_type,
      team_id,
      first_to,
      public_match,
      putting_up,
      match_type,
      team_size,
      platform,
      best_of,
      game,
      game_map_type,
      extra_options,
      game_mode_options: gameModeOptions,
    })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const joinMatch = async (
  useMiddleware,
  team_id,
  match_id,
  putting_up,
  region,
  selected_game_mode
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/match/join`, {
      team_id,
      match_id,
      putting_up,
      region,
      selected_game_mode,
    })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getMatch = async (useMiddleware, match_id) => {
  const params = { match_id };

  return await useMiddleware
    .get(`${API_ROUTE}/match/match`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getSensitiveMatch = async (useMiddleware, match_id) => {
  const params = { match_id };

  return await useMiddleware
    .get(`${API_ROUTE}/match/sensitive`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const cancelMatch = async (useMiddleware, match_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/match/cancel`, { match_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const readyUp = async (useMiddleware, match_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/match/ready`, { match_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const submitResults = async (useMiddleware, choice, match_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/match/submit`, { choice, match_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getOngoingMatches = async (skip, home, game) => {
  const params = { skip, home, game };
  return await axios
    .get(`${API_ROUTE}/match/ongoing`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getMessages = async (useMiddleware, match_id, skip) => {
  const params = { match_id, skip };
  return await useMiddleware
    .get(`${API_ROUTE}/match/messages`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const sendMessage = async (
  useMiddleware,
  match_id,
  team_id,
  message
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/match/messages/send`, {
      match_id,
      team_id,
      message,
    })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const voteToCancel = async (useMiddleware, choice, match_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/match/votecancel`, { choice, match_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const sendRematch = async (useMiddleware, match_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/match/rematch`, { match_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const acceptRematch = async (useMiddleware, match_id, noti_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/match/rematch/accept`, { match_id, noti_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const submitEvidence = async (useMiddleware, match_id, links) => {
  return await useMiddleware
    .post(`${API_ROUTE}/match/evidence`, { match_id, links })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getMatchCount = async (game) => {
  const params = { game };
  return await axios
    .get(`${API_ROUTE}/match/count`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const inviteToPrivateMatch = async (
  useMiddleware,
  match_id,
  user_id
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/match/invite`, { match_id, user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
