const PonyTail = ({ color }) => {
  const styles = {
    one: {
      fill: "none",
      strokeWidth: 2,
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: color,
    },
    three: {
      fill: "#bc0ac1",
    },
  };

  return (
    <g transform="translate(244, 155)">
      <g>
        <path
          style={styles.two}
          d="M151.97,79c-5-4.25-7.71-9.63-6.74-16.28-16.74-1.03-35.19,9.26-39.99,21.71-2.63,6.82-1.06,13.47-.13,20.2,3.21,23.26-9.67,46.56-31.57,57.2-2.44,1.19-4.13,1.41-4.75-1.76-.26-1.29-.85-2.53-1.48-4.32-1.74,.92-3.46,1.57-4.87,2.61-15.82,11.68-33.67,17.27-53.57,16.79-1.1-.03-2.38,.24-3.25-.22-1.51-.79-2.77-2.04-4.14-3.1,1.03-1.2,1.83-2.84,3.15-3.53,6.75-3.55,13.7-6.74,20.45-10.29,6.22-3.27,10.95-7.96,14-14.28,2.68-5.58,2.31-10.88-.69-16.1-5.93-10.31-9.76-21.38-12.06-32.87-3.72-18.62-.23-35.93,11.77-51.23C54.81,22.21,77.42,12.18,104.81,11.61c6.1-.13,12.2-.02,19.38-.02-3.56-1.56-6.65-2.59-9.35-4.24-1.46-.89-2.23-2.84-3.31-4.31,1.57-.71,3.15-2.07,4.7-2.04,30.74,.63,59.22,7.93,83.8,26.73,1.47,1.13,3.92,1.51,5.9,1.5,6.57-.05,13.17-.88,19.71-.59,9.29,.41,16.34,8.28,14.87,16.28-.53,2.85-2.31,5.48-3.57,8.33,2.45,0,5.3,.08,8.14-.01,37.72-1.2,73.85,4.98,107.26,22.52,8.68,4.56,17.02,8.7,27.03,10.51,13.27,2.41,25.97,7.39,35.97,16.85,6.43,6.08,11.18,12.92,13.45,20.89,1.31,4.6,1.43,8.31,1.49,10.85,.6,26.87-3.47,56.14-5.37,68.77-1.82,12.12-1.89,10.66-2.19,14.3-.3,3.6-1.69,9.45-2.19,13.77-.25,2.15-.43,5.28-.02,9.19-5.46-5.81-6.82-10.61-7-14-.09-1.7,.14-2.39,0-6-.2-4.92-.35-8-2-11-1.27-2.31-2.22-2.36-4.15-4.68-1.85-2.23-4.01-5.82-4.94-11.65,0,0-2.45-4.7-5.49-9.01-5.6-7.95-13-12.28-25.79-22.78-14.53-11.93-20.76-19.32-25.12-28.19-.44-.9-.79-1.66-1.01-2.15-14.55,17.71-35.41,22.7-56.67,26.47-20.64,3.66-41.5,4.02-62.38,1.86-5.08-.53-9.13,1.18-12.46,4.82-14.86,16.26-33,27.58-54.88,32.61-6.76,1.55-13.99,1.38-21.02,1.53-4.74,.11-7.78,1.2-7.99,6.43-.14,3.31-.86,6.6-1.49,9.88-.18,.94-.73,2.22-1.47,2.52-.68,.28-2.18-.28-2.75-.93-15.73-17.92-26.09-38.12-24.48-62.11,1.61-24.03,13.67-43.5,31.92-59.44,6.49-5.67,13.62-10.65,20.63-16.08Z"
        />
        <path
          style={styles.three}
          d="M145.23,62.71c8.2-7.9,20.61-17.89,37.67-25.58,8.34-3.76,16.18-6.24,23.04-7.9,14.37-4.32,27.45-2.48,32.53,4.58,3.59,4.99,3.13,10.17,3.13,10.17-.45,5.05-3.63,8.31-4.65,9.27-9.56,1.07-20.09,2.82-31.35,5.6-21.56,5.32-39.54,12.89-53.63,20.15-1.01-.47-4.32-2.15-6.24-6.06-2.37-4.83-.8-9.39-.5-10.22Z"
        />
        <path
          style={styles.one}
          d="M345.01,131.44c3.57,5.9,8.44,12.85,14.99,19.96,7.57,8.23,15.16,14.25,21.44,18.55,3.91,2.7,8.84,6.71,13.56,12.45,3.28,3.99,5.67,7.86,7.42,11.17,3.38,6.29,6.76,12.58,10.14,18.87,.04,3.71,.25,6.78,.45,8.96,.89,10.02,2.48,13.06,3,14,1.47,2.65,3.28,4.44,4.5,5.5,.06-2.91,.19-7.25,.5-12.5,1.61-26.96,5.88-41.04,8-58,1.37-10.98,2.28-26.72-.21-46.38-2.02-5-6.03-13.05-13.79-20.62-10.69-10.42-22.5-14.1-28.1-15.45-7.44-2.08-14.88-4.15-22.32-6.23-7.43-4.15-15.96-8.38-25.59-12.32-11.45-4.68-22.16-8-31.63-10.39-12.16-2.7-27.14-5.05-44.37-5.61-17.45-.57-32.74,.82-45.16,2.76-7.75,1.53-16.08,3.56-24.84,6.24-15.87,4.85-29.58,10.76-41.03,16.6-5.67,3.99-11.75,8.75-17.97,14.4-9.62,8.76-17.2,17.52-23.09,25.32-2.63,3.88-5.61,9.11-7.91,15.68-3.78,10.76-4.06,20.3-3.73,26.41,.5,6.81,2.04,16.67,6.73,27.59,6.2,14.42,15.02,23.95,20.65,29.16,1.47-2.23,2.33-4.23,2.85-5.66,.81-2.22,.96-3.59,1-4,.13-1.37-.02-1.93,0-3,.02-1.31,.3-3.19,1.5-5.5,7.54-.05,16.74-.76,27-3,7.2-1.57,13.55-3.6,18.97-5.68,5.56-2.61,12.14-6.25,19.03-11.32,6.07-4.46,10.99-9.01,14.88-13.07,.58-.91,3.88-5.91,10.12-6.93,3.11-.51,5.69,.17,7.1,.67,11.24,1.06,24.35,1.46,38.9,.33,8.61-.67,16.58-1.78,23.83-3.13,8.54-1.34,20.95-4.38,34.17-11.87,8.02-4.54,14.3-9.57,19.01-13.96Z"
        />
        <path
          style={styles.one}
          d="M223.57,27.02c-9.62,.56-24.5,2.59-41.06,9.88-18.06,7.94-30.3,18.68-37.27,25.81-.29,1.53-.56,4.15,.27,7.19,1.44,5.29,5.21,8.21,6.47,9.1,11.71-6.9,28.03-14.81,48.53-20.1,13.68-3.52,26.08-5.07,36.45-5.65,3.81-5,4.41-11.77,1.55-17.35-2.85-5.57-8.67-9.03-14.94-8.88Z"
        />
        <path
          style={styles.one}
          d="M204.67,29.55c-8.59-6.08-20.32-13.08-35.16-18.65C148.16,2.89,129.13,1.13,116.24,1c-1.57,.68-3.13,1.36-4.7,2.04l3.31,4.31,9.35,4.24c-9.7-1.25-26.11-1.98-44.69,4.31-6.83,2.31-21.51,7.46-35,21-5.62,5.64-14.26,14.54-18,29-2.15,8.33-1.93,15.58-1.39,20.38,1.34,7.31,3.35,15.63,6.39,24.62,2.74,8.09,5.81,15.23,8.83,21.37,.08,2.57-.14,6.43-1.83,10.63-2.74,6.82-7.74,10.51-14,15-4.32,3.1-10.93,7.18-19.89,10.39-1.05,1.18-2.1,2.35-3.15,3.53l4.14,3.1c7.11,.48,17.7,.35,29.89-3.02,15.01-4.15,25.7-11.34,31.79-16.16,.86,2.19,1.72,4.37,2.58,6.56,4.59-1.58,14.99-5.86,23.62-16.39,7.34-8.95,9.88-18.12,10.85-22.83-.15-9.25-.31-18.5-.46-27.75,.13-1.91,1.45-18.03,15.62-27.42,11.27-7.47,23.08-5.66,25.73-5.19,5.38-6.05,14.27-14.63,27.27-21.81,12.64-6.99,24.29-9.97,32.16-11.35Z"
        />
      </g>
    </g>
  );
};

export default PonyTail;
