import { Card, CardContent, Grid, Typography } from "@mui/material";
import { currencyFormatter } from "../../utils/helpers";
import { card, green, modalShadow, normal, offWhite, secondaryText, text } from "../../utils/themeContstants";

const ReferralItem = ({ referral }) => {

  const styles = {
    width: {
      width: "100%",

    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    meta: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
    },
    container: {
      borderRadius: 1,
      padding: 2,
      backgroundColor: card,
      boxShadow: modalShadow,
      flexGrow: 1,
      paddingLeft: "30px",
      paddingRight:"30px"
    },
    smallLabel: {
      fontSize: 12,
      color: secondaryText,
      fontWeight: 600,
    },
    totalLabel: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 600,
    },
    totalValue: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    label: {
      fontSize: 15,
      color: offWhite,
      fontWeight: 600,
    },
    value: {
      fontSize: 15,
      color: text,
      fontWeight: 800,
    },
    containerHeader: {
      fontSize: 18,
      color: text,
      fontWeight: 800,
    },
    percentValue: {
      fontSize: 12,
      color: green,
      fontWeight: 600,
    },
  };

  return (
    <Grid item sx={styles.width}>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        gap={{ xs: 1 }}
      >
        <Grid item sx={styles.container}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography
                sx={(styles.totalLabel, { color: green })}
              >
                {referral.code}
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={styles.totalValue}>
                {currencyFormatter().format(
                  referral?.amountDeposited
                )}
              </Typography>
            </Grid>
            <Typography sx={styles.smallLabel}>
              {referral?.users}{" "} Users
            </Typography>
            <Typography sx={styles.smallLabel}>
              {referral?.depositCount}{" "} Deposits
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReferralItem;
