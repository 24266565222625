const Fatty = () => {
  const styles = {
    one: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#a96712",
    },
    five: {
      fill: "#5f1d00",
    },
    six: {
      fill: "red",
    },
  };

  return (
    <g>
      <path
        style={styles.three}
        d="m561.21,467.57c0-.25-8.28-1.72-17.31,1.49-9.75,3.46-15.37,10.59-15.15,10.86.22.28,6.85-7.14,18.48-10.53,7.81-2.28,13.99-1.6,13.98-1.82Z"
      />
      <g>
        <path
          style={styles.four}
          d="m543.36,470.28l-1.83,1.51.09,3.6,11.32,12.55c.55,1.4,1.47,3.4,2.93,5.6,2.44,3.66,4.99,5.71,6.99,7.41,9.2,7.81,16.59,16.48,16.59,16.48h0s11.07,15.28,14.61,15.92c0,0,9.01,10.91,12.59,12.64,0,0,4.46,5.58,10.41,4.92l7.09-10.98s.46-.26-3.41-4.08c0,0-.21-1.9-7.51-5.26,0,0-2.95-7.37-9.81-11.56,0,0-9.02-10.13-11.92-11.56,0,0-4.08-4.38-10.07-6.37l1.11-.02-1.55-3.4s-16.21-12.94-17.14-14.34c0,0-2.13-2.31-5.85-2.23l.43-.65-2.92-1.61-12.16-8.56Z"
        />
        <path
          style={styles.five}
          d="m541.52,471.79c1.42,2.65,4.18,6.81,9.13,9.71,1.22.71,2.41,1.26,3.52,1.67.02.92.19,3.26,1.82,5.54,1.25,1.77,2.79,2.69,3.61,3.1,3.41,1.41,7.36,3.4,11.44,6.26,1.85,1.29,3.5,2.61,4.97,3.89-.98-.73-2.96-2-5.78-2.44-2.06-.32-3.77-.07-4.86.17,3.56,3.06,7.11,6.11,10.67,9.17,0,0,1.64,3.79,6.49,6.64,3.05.78,7.06,2.17,11.32,4.74,2.68,1.62,4.85,3.35,6.56,4.91-2.86-1.56-6.43-3.18-10.67-4.48-2.71-.83-5.24-1.39-7.51-1.77,2.2,2.03,4.88,4.09,7.88,6.29,4.42,3.23,9.79,6.62,13.6,7.29l-7.26.73s9.4.79,13.28,5.36c0,0,.47,3.66,5.36,4.83,1.2.07,2.4.15,3.61.22-.88,2.44-1.75,4.88-2.63,7.31,0,0-5.45.18-9.42-4.95,0,0-5.16-3.8-12.59-12.64-.63-.31-1.31-.68-2-1.15-1.68-1.12-2.91-2.38-3.77-3.43,0,0-8.34-9.97-8.84-11.34l-16.25-16.11s-7.31-6.37-9.91-12.54l-11.68-13.85c-.03-1.04-.05-2.09-.08-3.13Z"
        />
        <path
          style={styles.one}
          d="m553.87,489.94c-.03-.06-.06-.11-.1-.16l-3.17-4.07-6.99-8.28c-.05-.06-.11-.11-.17-.15-.52-.34-1.48-1.08-2.09-2.39-.37-.79-.48-1.51-.51-2.01,0-.06,0-.11,0-.16.05-.34.3-1.48,1.35-2.08,1.03-.6,2.4-.44,3.38.42,3.22,2.33,6.44,4.67,9.66,7,.04.03.09.06.14.08,1.05.46,2.23,1.11,3.41,2.05.25.2.48.4.7.6.09.09.21.15.33.18.73.19,1.58.47,2.5.91.77.37,1.42.76,1.96,1.14.05.03.09.07.13.12.51.56,1.04,1.13,1.59,1.7,1.69,1.75,3.38,3.3,5.03,4.68,3.28,2.68,6.56,5.36,9.83,8.04.05.04.09.08.13.13.41.53.84,1.19,1.25,1.96.26.49.47.96.63,1.4.06.16.18.3.33.4,2.35,1.42,3.79,2.2,4.72,2.66.29.15,1.08.53,2.03,1.19.61.42,1.08.81,1.39,1.09.02.02.03.03.05.05,2.01,2.11,4.02,4.22,6.03,6.33.01.01.02.02.03.03.73.67,1.47,1.38,2.21,2.13,1.38,1.4,2.63,2.78,3.75,4.12.06.07.13.13.2.17,1.38.79,3.21,2.06,4.99,4,2.18,2.38,3.38,4.8,4.04,6.47.07.18.21.33.38.42,1.85.92,3.71,1.84,5.56,2.76.03.01.05.03.08.04.41.27,1.12.81,1.61,1.74.22.4.34.79.42,1.11.03.14.1.26.2.36.87.89,1.74,1.79,2.61,2.68.14.12.64.57.77,1.34.06.38.01.7-.05.93-.05.2-.03.41.07.59.37.64.75,1.43,1.05,2.35.16.48.28.93.37,1.35.04.17.01.34-.06.5l-.89,1.86c-.14.3-.08.65.15.89.38.41.49.94.32,1.32-.13.28-.4.43-.57.5-.08.03-.16.08-.22.13-.16.14-.36.3-.59.45-.32.21-.63.36-.89.46-.07.03-.14.06-.2.11l-.52.4c-.06.04-.11.08-.18.11-.17.07-.35.14-.56.19-.6.16-1.13.16-1.52.12-.03,0-.07,0-.1,0-.61.03-1.22.05-1.83.08-.18,0-.39-.02-.61-.08-.58-.14-1.02-.42-1.3-.66-.1-.09-.18-.2-.23-.33h0c-.1-.28-.34-.48-.63-.52-.78-.11-1.64-.31-2.57-.63-3-1.06-4.95-2.94-5.98-4.13-.03-.04-.06-.07-.1-.1-1.94-1.54-4.02-3.33-6.13-5.41-2.55-2.5-4.68-4.96-6.46-7.24-.07-.09-.15-.15-.25-.21-1.18-.63-2.66-1.57-4.16-2.95-1.17-1.09-2.05-2.18-2.7-3.11-.02-.02-.03-.05-.05-.07-1.15-1.27-2.32-2.66-3.5-4.15-1.48-1.87-2.79-3.69-3.95-5.43-.03-.04-.06-.08-.09-.11-3.03-3.07-6.06-6.13-9.09-9.2l-6.08-6.04s-.04-.03-.06-.05c-.88-.71-1.82-1.55-2.77-2.51-.7-.7-1.32-1.4-1.88-2.06-.03-.03-.05-.06-.08-.08-.91-.77-1.96-1.81-2.99-3.15-1.2-1.57-2-3.08-2.54-4.29Z"
        />
        <path
          style={styles.two}
          d="m542.04,471.86s1.07-1.11,2.88-.58l10.6,7.56s-2.15-.27-4.22-1.15c0,0-.77,1.15,5.11,1.57l1.46.81s-4.3-.54-5.22,1.84c0,0,2.03-1.31,4.49-1,0,0,4.49.87,6.68,2.39l2.69,2.03-2.23-2.33-4.79-2.23-1.17-.95-3.04-1.74-10.89-7.73c-1.08-.29-2.21.03-2.89.81-.7.81-.65,1.79-.64,2.01.05.44.18,1.05.48,1.71.5,1.08,1.24,1.76,1.67,2.09.96,1.05,1.91,2.1,2.87,3.15l5.39,6.45,2.62,3.43s-1.53-3.46.77-6.91c0,0-2.53.31-2.65,1.23,0,0-.92-.11-.88-.42l-.11.92s-1.61-2.07-2.73-3.15-2-2.15-3.57-3.88c-.92-1.02-3.6-3.98-3.11-5.07.03-.07.3-.64.42-.88Z"
        />
        <path
          style={styles.two}
          d="m561.7,489.28s5.78-1.5,12.01,2.42l-2.08-1.7s-10.19-3.55-9.92-.71Z"
        />
        <path
          style={styles.two}
          d="m565.34,487.55c.24-.09.84-.27,1.57-.12.79.17,1.28.62,1.46.81-.32-.16-.72-.33-1.19-.46-.72-.2-1.37-.24-1.84-.23Z"
        />
        <path
          style={styles.two}
          d="m552.18,483.1l-.85-3.22-.66.48s-.87-.67-1.52-.44-.15-1.27-1.11-1.07-.61-.08-.84-.73c0,0-3.07-2.88-4.15-4.26l-1-2-.32.67.47.6-.08.61s.66,1.64,8.8,9.21v.31s1.26-.15,1.26-.15Z"
        />
        <path
          style={styles.two}
          d="m552.25,477.5s-2.96-1.3-3.22-1.65c0,0,1.96,1.8,3.22,1.65Z"
        />
        <path
          style={styles.two}
          d="m623.17,539.04s-1.27.15-.73.84c0,0-2.23-2.15-1.07,1.11,0,0-2.34-1.23-.92,2.38,0,0-4.15-6.75-4.15-4.26,0,0,.5,3.49.42,6.6,0,0-1.46,1.69-1.27,2.65,0,0,.5,1,.42,1.61l.19.96,7.1-11.9Z"
        />
        <path
          style={styles.two}
          d="m623.37,538.62c.21.21.78.83.88,1.8.04.45-.03.83-.11,1.08l1.61,4.02-1.03,2.46.6,1.33s-.07.96-.65,1.09l-1.34.88-.62.33-.76.5-3.29.3c-.18.06-.97.28-1.8-.13-.56-.28-.86-.7-.98-.9"
        />
        <path
          style={styles.six}
          d="m620.62,546.33c.14-.04.28-.07.42-.08-.05-.07,0-.19.09-.2"
        />
        <path
          style={styles.six}
          d="m620.24,546.32s-.96.19-.69.54.08-.61.58.38c0,0,.13-.16.33-.13.2.02.28.2.29.21.52,0,.95-.36,1.04-.81.08-.44-.16-.99-.58-1.07-.42-.07-.92.3-.95.88Z"
        />
        <path
          style={styles.six}
          d="m623.34,541.4l.15,1.27.58.38-.08.61.58.38-.07.54-.51,1.57s-.65.23-.88-.42-.69.47-.69.47c0,0,1.19.53,1.15.84-.09.7-.69,1.2-.77,1.15-.03-.02.05-.13.04-.31-.03-.34-.37-.67-.53-.62-.11.04-.06.25-.16,1.05-.27,2.25.04-.21.04-.21,0,0,.27.35-.11.92l-.96.19s-.54-.69-.92-.11l-.27-.35s-.73-1.65-1.19-.46c0,0-.35.27-.61-.08,0,0,.11-.92.46-1.19,0,0,.61-2.42-.92-.11l-.27,2.15s.92.11,1.19.46,0,0,0,0c0,0,.31.04-.15,1.23,0,0-.33.27.47.06,0,0,.49-.25.76.09l-.04.31s.23-.67.52-1.07c.29-.39.47,0,.47,0h.39s.16-.75.42-.65c.27.1.54.45.88.18s.23.65.23.65c0,0,.58.38.65-.23s-.19-.96-.19-.96c0,0,.35-.27.65-.23s.61.08.65-.23-.58-.38-.54-.69.38-.58.38-.58l.15-1.23s.38-.58.69-.54-.27-.35-.27-.35c0,0-.27-.35.08-.61l.35-.27s-.19-.96-.73-1.65l-1.08-1.38Z"
        />
        <path
          style={styles.six}
          d="m622.69,544.13s.19-1.54.65-.23c0,0,.27.35.15,1.27,0,0-.84-.73-1.57.12,0,0-.58-.38-.23-.65,0,0,.65-.23,1-.5Z"
        />
        <path
          style={styles.six}
          d="m621.58,550.54s-.19-.96.65-.23-.46,1.19-.46,1.19c0,0-.54-.69-.19-.96Z"
        />
        <path
          style={styles.six}
          d="m617.59,550.04s-1.01.63-.08.68c.93.05.08-.68.08-.68Z"
        />
        <path
          style={styles.two}
          d="m615.8,548.1s-.83-1.05-.44-1.62.75-.89.75-.89c0,0,.49-3.94-.39-4.36s-.42.88-.42.88c0,0,.08,1.09-.21,1.29s-1.06,1.04-1.38,1.32-13.9-7.3-14.52-9.11-.98.99,6.53.81c0,0-2.97-1.17-3.58-1.25s2.43,0,3.04.38-6.45-2.99-7.68-3.14,2.19-.04,2.19-.04c0,0-5.64-2.26-7.64-3.76s10.6,5.06,16.16,5.45c5.57.38.31.04.31.04,0,0,1.04,1.69,5.45,1.3s4,2.99,4,2.99c0,0,1.83-4.76-3.85-4.22l-7.98-1c-2.76-.34-4.42-2.11-7.18-2.45s-8.29-3.53-8.29-3.53l1.57-.12s-2.61-1.57-2.84-2.23.65-.23.65-.23c0,0-2-1.5-2.27-1.84s2.61-.92-.12-1.57-4.76-1.84-4.76-1.84l2.42.61-3.69-2.95,4.22,1.15s.69,1.96,5.26,2.84c0,0,6.18,2.95,8.56,3.87,2.38.92,8.64,3.26,8.64,3.26,0,0-14.74-6.83-16.85-7.4s-2.15-.27-4.38-2.42c0,0,7.79,2.53,8.33,3.22s-12.98-5.98-15.43-6.29-13.63-10.74-15.36-14.39l-1.54-2.69-.07.57s-2.85-2.18-3.12-5.02c0,0,3.15,4.76,4.68,4.95l.36-.87.42,2.13,1.52.31.69-.57s-2.96-1.24-.35-2.19,1.31-.49,1.31-.49c0,0-1.62-.43-3.42-.54,0,0-1.76-2.91-3.57-3.45,0,0-.69-1.96-3.72-2.65,0,0-.42-1.61-.92-2.61s-1.18-.61-1.18-.61c0,0-1.86-2.57-.17-3.61s3.84-.77,3.84-.77c0,0-6.56-2.38-4.37,5.07,0,0,.81,1.04.35,2.23,0,0-.69,3.03,4.07,4.87,0,0,2.95,3.85,4.39,4.78l.14,1.39-1.81-.79,13.61,13.26s11.08,9.09,10.77,9.05-2.65-1.27-2.65-1.27c0,0,6.49,7.98,8.83,9.21,0,0,1.38,1.42,1.92,2.11s1.8.54,1.8.54l-.69.54s6.91,7.1,8.06,7.87c0,0-.32.47,2.74,2l-2.01-.5s2.79,1.75,4.14,2.7c0,0,1.44,3.93,7.06,3.3l.05-1.01Z"
        />
        <path
          style={styles.two}
          d="m566.55,499.57s.67,2.87,5.01,5.6c4.34,2.72,6.68,6.45,6.68,6.45,0,0,3.27,3.75,8.35,4.27,0,0-4.6.11-6.82-1.81s-8.98-5.26-9.82-8.48c0,0-5.38-4.41-4.57-5.87s1.17-.15,1.17-.15Z"
        />
        <path
          style={styles.two}
          d="m571.87,495.22c1.91,1.65,4.49,3.51,7.79,5.03.9.41,1.78.76,2.63,1.06.32.11.64.22.94.32-.17-.33-.34-.65-.52-.98-1.06-.41-2.14-.86-3.25-1.36-2.84-1.28-5.37-2.68-7.6-4.07Z"
        />
        <path
          style={styles.two}
          d="m590.76,506.31s-8.45-4.8-9.33-5.22,1.94.44,1.94.44l6.68,3.81,1.45,1.06,3.71,4.2,3.72,3.57-3.3-1.96s-3.57-1.38-4.22-1.15c0,0-1.46-1.96-6.32-2.45,0,0,3.25,2.07,5.24,3.57,0,0-4.72-2.15-6.34-4.22,0,0-5.53-3.18-6.3-4.53,0,0-7.22-4.64-10.67-4.45,0,0,2.96-1.19,8.98,2.99,0,0,12.67,3.45,14.74,4.33Z"
        />
        <path
          style={styles.two}
          d="m603.09,525s3.61,1.07,5.72,1.65c0,0,2.39.67,3.34,1.85l.61,1.36,6.08,3.14s1.51.84,1.9,2.84c0,0-.64-2.15-4.78-3.3-.03,0-.06-.02-.09-.02,0,0-4.26-3.34-6.64-4.26l-6.14-3.26Z"
        />
        <path
          style={styles.two}
          d="m604.17,528.88s2.76.34,3.65.77-3.45.19-3.65-.77Z"
        />
        <path
          style={styles.two}
          d="m607.7,530.56s1.88-.08,2.8.04-1.3.46-2.23.35-.58-.38-.58-.38Z"
        />
        <polygon
          style={styles.two}
          points="611.04 531.29 613.19 531.56 612.84 531.83 611.04 531.29"
        />
        <line
          style={styles.two}
          x1="577.67"
          y1="495.95"
          x2="580.74"
          y2="499.14"
        />
        <line
          style={styles.two}
          x1="575.17"
          y1="493.76"
          x2="576.52"
          y2="495.49"
        />
        <g>
          <path d="m596.48,515.44s3.26,1.34,3.8,2.03" />
          <path d="m596.48,515.44c1.66.26,3.44.12,4.88,1.19,0,0-2.16,1.68-2.16,1.68.1.04.07-.09-.01-.21-.74-1.01-1.76-1.83-2.71-2.67h0Z" />
        </g>
        <g>
          <line x1="605.24" y1="522.77" x2="607.31" y2="523.65" />
          <path d="m605.43,522.31c1.01-.65,1.8.32,1.88,1.34-.79.65-2.03.75-2.27-.42,0,0,.39-.92.39-.92h0Z" />
        </g>
        <g>
          <line x1="608.42" y1="524.38" x2="609.24" y2="525.77" />
          <path d="m608.42,524.38c.6.17,1.59.26,1.25,1.13,0,0-.86.51-.86.51-.92-.12-.53-1.03-.39-1.64h0Z" />
        </g>
        <path d="m629.23,525.45c.58-.81,2.02-1.36,2.75-.39.61.82.55,1.71.56,2.59-.1.83-.92,1.42-1.46,1.68-1.23.61-2.58.81-3.89,1.01,0,0-.11,0-.11,0,.14-.01.04,0,.04.02-.94.97-.53,3.22-.5,4.16.45,1.79.93,3.69,1.56,5.4.48.9.72,2.16.41,3.15-.27,1.04-.92,1.93-1.77,2.47.35-.95.6-1.82.66-2.67.2-1.79-1.26-3.44-1.56-5.19-.6-1.92-1.07-3.8-.85-5.85.09-.81.26-1.72.88-2.38.33-.32.56-.51,1.13-.56,0,0-.11,0-.11,0,.8-.14,1.6-.31,2.35-.56.54-.19,1.39-.5,1.68-.89.03.16.07-.54.08-.84.05-.37,0-.68-.05-.89-.28-.65-1.19-.35-1.8-.27h0Z" />
        <path
          style={styles.one}
          d="m637.7,525.83s-.3,1.58-1.3,2.08-2.98,1.19-4.27,5.39c0,0-.33,9-.48,10.23,0,0-.8,3.95-4.57,6.6"
        />
      </g>
    </g>
  );
};

export default Fatty;
