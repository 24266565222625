import { Grid, useMediaQuery } from "@mui/material";
import {
  gameModeOptions,
  newGameModeOptions,
  newTeamSizeOptions,
  platformOptions,
  warzoneRegionOptions,
} from "../../../utils/options";
import { cardDark, cardDarker } from "../../../utils/themeContstants";
import CustomDropdown from "../../custom/CustomDropdown";
import ScrollableContainer from "../../custom/ScrollableContainer";

const WarzoneMatchFilters = ({
  setPlatform,
  setRegion,
  setGameMode,
  setTeamSize,
  game,
}) => {
  const isTablet = useMediaQuery("(max-width: 768px)");

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <Grid item sx={styles.width}>
      {isTablet && (
        <ScrollableContainer gap={1}>
          <Grid item>
            <CustomDropdown
              options={platformOptions}
              placeholder="Platform"
              onChange={(value) => setPlatform(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>

          <Grid item>
            <CustomDropdown
              options={warzoneRegionOptions}
              placeholder="Region"
              onChange={(value) => setRegion(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>

          <Grid item>
            <CustomDropdown
              options={newGameModeOptions(game)}
              placeholder="Game Mode"
              onChange={(value) => setGameMode(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>

          <Grid item>
            <CustomDropdown
              options={newTeamSizeOptions(game)}
              placeholder="Team Size"
              onChange={(value) => setTeamSize(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>
        </ScrollableContainer>
      )}

      {!isTablet && (
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 1 }}
        >
          <Grid item>
            <CustomDropdown
              options={platformOptions}
              placeholder="Platform"
              onChange={(value) => setPlatform(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>

          <Grid item>
            <CustomDropdown
              options={warzoneRegionOptions}
              placeholder="Region"
              onChange={(value) => setRegion(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>

          <Grid item>
            <CustomDropdown
              options={newGameModeOptions(game)}
              placeholder="Game Mode"
              onChange={(value) => setGameMode(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>

          <Grid item>
            <CustomDropdown
              options={newTeamSizeOptions(game)}
              placeholder="Team Size"
              onChange={(value) => setTeamSize(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default WarzoneMatchFilters;
