import axios from "axios";
import { API_ROUTE, errMessage } from "../utils/constants";

export const logout = async (useMiddleware) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/logout`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getUser = async (useMiddleware) => {
  return await useMiddleware
    .get(`${API_ROUTE}/user/me`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getUserTeamsDetailed = async (useMiddleware, team_size, skip) => {
  const params = { team_size, skip };

  return await useMiddleware
    .get(`${API_ROUTE}/user/teams/detailed`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const updateAvatar = async (useMiddleware, avatar) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/avatar/save`, { avatar })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const setUsername = async (useMiddleware, username) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/username/edit`, { username })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
export const getUserTipsProfile = async (useMiddleware, user_id, skip) => {
  const params = { user_id, skip };
  return await useMiddleware
    .get(`${API_ROUTE}/user/tips`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getUserTeams = async (useMiddleware) => {
  return await useMiddleware
    .get(`${API_ROUTE}/user/teams`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const claimGiveaway = async (useMiddleware, noti_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/claim`, { noti_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const claimTransferFundsGiveaway = async (useMiddleware, noti_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/transferfunds`, { noti_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const fetchTwitterURL = async (useMiddleware) => {
  return await useMiddleware
    .get(`${API_ROUTE}/user/connections/twitter`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const addConnection = async (
  useMiddleware,
  type,
  code,
  verifier,
  username
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/connections/add`, {
      type,
      code,
      verifier,
      username,
    })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const removeConnection = async (useMiddleware, type) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/connections/remove`, { type })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getHistory = async (useMiddleware, skip, winners, state) => {
  const params = { skip, winners, state };
  return await useMiddleware
    .get(`${API_ROUTE}/user/history`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getRecentlyPlayed = async (useMiddleware) => {
  return await useMiddleware
    .get(`${API_ROUTE}/user/recent/match`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getRecentPurchase = async (useMiddleware) => {
  return await useMiddleware
    .get(`${API_ROUTE}/user/recent/purchase`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const linkEpic = async (useMiddleware) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/gameaccount`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
export const refreshEpic = async (useMiddleware) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/gameaccount/refresh`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getDeposits = async (useMiddleware, skip) => {
  const params = { skip };
  return await useMiddleware
    .get(`${API_ROUTE}/user/deposits`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getWithdrawals = async (useMiddleware, skip) => {
  const params = { skip };
  return await useMiddleware
    .get(`${API_ROUTE}/user/withdrawals`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const withdraw = async (useMiddleware, email, amount, type) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/withdraw/paypal`, { email, amount, type })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};


export const claim = async (useMiddleware, key) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/keys/claim`, { key })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const cashAppWithdraw = async (useMiddleware, cashtag, amount, type) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/withdraw/cashapp`, { cashtag, amount, type })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const tipUser = async (useMiddleware, amount, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/tip`, { amount, user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const createPayPalOrder = async (useMiddleware, amount) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/paypal/deposit`, { amount })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const capturePayPalOrder = async (useMiddleware, token) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/paypal/capture`, { token })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getUserProfile = async (useMiddleware, user) => {
  const params = { user };

  return await useMiddleware
    .get(`${API_ROUTE}/user/profile`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getReceipt = async (useMiddleware, receipt_id) => {
  const params = { receipt_id };

  return await useMiddleware
    .get(`${API_ROUTE}/user/receipt`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const poofCheckout = async (useMiddleware, amount) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/poof`, { amount })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const sellixCheckout = async (useMiddleware, amount) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/sellix/checkout`, { amount })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const kinguinCheckout = async (useMiddleware, amount) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/kinguin/checkout`, { amount })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const shopifyCheckout = async (useMiddleware, amount) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/shopify/checkout`, { amount })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getUserPurchases = async (useMiddleware, skip) => {
  const params = { skip };
  return await useMiddleware
    .get(`${API_ROUTE}/user/purchases`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const snipeUsers = async (useMiddleware, match_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/snipe`, { match_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const purchasePremium = async (useMiddleware, type, receiving_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/subscription/purchase`, { type, receiving_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const cancelSubscription = async (useMiddleware) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/subscription/cancel`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const resetStats = async (useMiddleware) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/stats/reset`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const resetEarnings = async (useMiddleware) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/earnings/reset`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const buySnipes = async (useMiddleware, amount) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/snipes/buy`, { amount })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const buyUnban = async (useMiddleware) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/unban/buy`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const payDebt = async (useMiddleware) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/debt/pay`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
export const payUnlinkDebt = async (useMiddleware) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/debt/unlink/pay`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const buyChatUnban = async (useMiddleware) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/chat/unban/buy`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const updateTOSVersion = async (useMiddleware) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/update/tos`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
