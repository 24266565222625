import { API_ROUTE, errMessage } from "../utils/constants";

export const getNotifications = async (useMiddleware, skip) => {
  const params = { skip };
  return await useMiddleware
    .get(`${API_ROUTE}/user/notifications`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const clearNotifications = async (useMiddleware) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/notifications/clear`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const toggleNotification = async (useMiddleware, value, id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/notifications/toggle`, { value, id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const deleteNotification = async (useMiddleware, id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/notifications/dismiss`, { id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const markAllRead = async (useMiddleware) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/notifications/read`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
