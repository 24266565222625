import "../styles/Goku.css";

const Goku = () => {
  return (
    <g>
      <g>
        <path
          class="goku-1"
          d="m843,305c-53,19.67-106,39.33-159,59l42,5-64.35,27.98c2.65-9.71,5.53-25.98,1.35-44.98-1.53-6.96-3.73-12.99-6-18-.97-.97-2.28-2.07-4-3-4.53-2.47-8.99-2.24-11-2-11,9.33-22,18.67-33,28,.65-5.96,1.29-19.55-6-34-4.36-8.64-9.99-14.49-14-18-2.51,9.44-6.27,20.38-12,32-11.11,22.55-25.16,38.61-36,49,2.93-10.18,6.75-28.58,3-51-2.72-16.25-8.44-28.82-13-37-9.67,17.33-19.33,34.67-29,52,9-17.33,18-34.67,27-52-11.11,6.15-24.42,14.83-38,27-18.72,16.76-31.19,34.08-39.2,47.33-.27-20.44-.53-40.89-.8-61.33-4.96,2.96-11.35,7.68-17,15-14.37,18.63-13.09,40.06-12.48,46.33-4.95-3.56-11.32-9.1-16.52-17.33-7.73-12.22-7.88-22.43-14-24-2.85-.73-7.34.33-14.47,8.37.24,7.42-.32,17.24-5.53,19.63-4.53,2.09-8.97-3.11-15.49-3.02,2.89-.42,5.52-.25,7.49.02-54.67-10.33-109.33-20.67-164-31,20.04-5.69,42.8-10.99,68-15,27.06-4.3,51.95-6.32,74-7-17.26-7.68-40.95-16.19-70-21,26.72,3.93,49.31,10.52,67,17-17.37-14.77-39.94-31.5-68-47-31.31-17.3-60.43-28.1-84-35,30.65-7.87,56.68-12.33,76-15,23.41-3.24,37.71-5.14,57-4,26.02,1.53,47,7.75,61,13-7.04-7.82-15.95-16.5-27-25-17.16-13.2-33.81-21.61-47-27,20.67-2.33,41.33-4.67,62-7l-63-62c29.35,1.56,53.2,6.52,70,11,27.22,7.26,63.69,17.4,98,47,9.01,7.77,15.7,15.01,20,20-.27-12.63-1.58-27.55-5-44-5.16-24.82-13.44-45.04-21-60,13.13,9.26,30.81,24,47,46,21.94,29.81,30.99,59.11,35,77,3.8-16.57,7.49-42.28,3-73-3.01-20.55-8.81-37.45-14.26-50,5.65,11.95,11.52,27.42,15.26,46,6.63,32.99,3.54,61.04,0,79,10.49-.19,37.07.73,64,18,22.31,14.3,33.9,32.8,38.91,42.1,20.67-8.21,46.23-16.45,76.09-22.1,18.06-3.42,34.9-5.37,50.16-6.36-14.98,1.09-32.31,3.51-51.16,8.36-18.72,4.82-34.8,10.97-48,17,19.43-1.72,41.23-2.64,65-2,22.69.61,43.49,2.53,62.09,5.1-16.94,4.49-36.31,10.82-57.09,19.9-18.39,8.03-34.4,16.69-48,25,22.83-.93,48.71-.64,77,2,22.22,2.07,42.63,5.28,61,9Z"
        />
        <path
          class="goku-1"
          d="m782.88,220.59c4.24-.3,8.28-.49,12.12-.59-3.92.14-7.96.33-12.12.59Z"
        />
        <path
          class="goku-1"
          d="m365,409c2.98,8.4,5.97,16.8,8.95,25.2l-65.12-3.89,55.98-20.96c-1.6.26-17.01,2.49-25.81-7.35-2.84-3.18-4.51-7.1-5.19-11.3.6,2.38,1.59,4.87,3.19,7.3,5.7,8.67,16.76,13.04,28,11Z"
        />
        <path
          class="goku-1"
          d="m355,356c.16-.01.31-.01.47-.01-2.07.3-4.28.91-6.47,2.01-.44.22-.87.46-1.29.71,2.73-1.9,5.18-2.59,7.29-2.71Z"
        />
        <path
          class="goku-1"
          d="m347.71,358.71c-3.55,2.47-7.56,6.98-11.86,14.93,2.44-6.3,6.62-11.82,11.86-14.93Z"
        />
        <path
          class="goku-1"
          d="m333.7,377.85c.72-1.5,1.44-2.9,2.15-4.21-2.13,5.46-2.95,11.5-2.04,17.05-1.53-6.09-.44-11.39-.11-12.84Z"
        />
        <path
          class="goku-1"
          d="m202,279c18.1-1.39,41.79-1.5,69,3-26.69-3.92-50.21-4.11-69-3Z"
        />
      </g>
      <polygon
        class="goku-3"
        points="375.94 347.98 245 324 296.73 306.05 199 325 371.38 358.72 375.94 347.98"
      />
      <path
        class="goku-3"
        d="m341,303l19,7,20.43-3.95c-21.08-20.38-57.56-50.23-110.43-70.05-32.19-12.07-61.39-17.02-84-19,23.18,6.63,52.46,17.31,84,35,30.11,16.89,53.63,35.31,71,51Z"
      />
      <path
        class="goku-3"
        d="m448.91,239.42l-32.91,2.58c-10.94-13.1-25.4-28.19-44-43-24.13-19.2-47.47-31.77-66-40,26-2.33,52-4.67,78-7l-49,13c13.93,2.37,34.26,7.58,56,20,30.98,17.7,49.05,41.03,57.91,54.42Z"
      />
      <polygon
        class="goku-3"
        points="337.15 121.64 321 100 416.43 152 366.14 150.17 337.15 121.64"
      />
      <path
        class="goku-3"
        d="m493,168l27,23c-2.16-17.95-7.1-42.67-19-70-10.36-23.79-22.78-42.17-33.13-55.26,6.79,11.94,14.95,29.24,20.13,51.26,4.76,20.23,5.46,37.92,5,51Z"
      />
      <path
        class="goku-3"
        d="m655.91,249.1l3.09,20.9c21.57-4.91,44.95-9.39,70-13,28.92-4.17,56.08-6.62,81.09-7.9-27.34-3.84-59.37-6.26-95.09-5.1-21.32.69-41.09,2.57-59.09,5.1Z"
      />
      <path
        class="goku-3"
        d="m439,268c-2.42,1.77-36.52,27.65-33,67,1.63,18.17,10.56,31.17,15.52,37.33-4.31-3.55-9.54-8.56-14.52-15.33-6.3-8.56-9.9-16.79-12-22.85-.32-9.41.53-23.66,7-39.15,2.44-5.84,5.25-10.83,8-15,9.67-4,19.33-8,29-12Z"
      />
      <path
        class="goku-3"
        d="m517,262c-8.07,5.43-17.44,12.62-27,22-9.35,9.17-16.61,18.18-22.13,26-2.41,4.84-4.75,10.17-6.87,16-6.34,17.44-8.61,33.5-9.2,46.33-.27-20.44-.53-40.89-.8-61.33l47-51,19,2Z"
      />
      <path
        class="goku-3"
        d="m531,298l29.12,5.09c.9,7.23,1.29,16.43-.12,26.91-2.68,19.94-10.58,34.81-16.12,43.43,1.65-9.73,3.01-25.19-.88-43.43-2.92-13.69-7.83-24.5-12-32Z"
      />
      <path
        class="goku-2"
        d="m373.95,434.2l-65.13-3.9,55.98-20.95c-1.6.26-17,2.49-25.81-7.35-11.29-12.63-4.09-36.93,10-44,5.24-2.63,10.6-2.46,14-2-54.67-10.33-109.33-20.67-164-31,20.04-5.69,42.8-10.99,68-15,27.06-4.3,51.95-6.32,74-7-17.26-7.68-40.95-16.19-70-21-27.21-4.5-50.9-4.39-69-3,18.79-1.11,42.31-.92,69,3,26.72,3.93,49.31,10.52,67,17-17.37-14.77-39.94-31.5-68-47-31.31-17.3-60.43-28.1-84-35,30.65-7.87,56.68-12.33,76-15,23.41-3.24,37.71-5.14,57-4,26.02,1.53,47,7.75,61,13-7.04-7.82-15.95-16.5-27-25-17.16-13.2-33.81-21.61-47-27,20.67-2.33,41.33-4.67,62-7l-63-62c29.35,1.56,53.2,6.52,70,11,27.22,7.26,63.69,17.4,98,47,9.01,7.77,15.7,15.01,20,20-.27-12.63-1.58-27.55-5-44-5.16-24.82-13.44-45.04-21-60,13.13,9.26,30.81,24,47,46,21.94,29.81,30.99,59.11,35,77,3.8-16.57,7.49-42.28,3-73-3.01-20.55-8.81-37.45-14.26-50,5.65,11.95,11.53,27.42,15.26,46,6.63,32.99,3.54,61.04,0,79,10.49-.19,37.07.73,64,18,22.31,14.3,33.9,32.8,38.91,42.1,20.66-8.21,46.22-16.45,76.09-22.1,23.28-4.41,44.54-6.37,63-7-17.54.49-39.48,2.69-64,9-18.72,4.82-34.8,10.97-48,17,19.43-1.72,41.23-2.64,65-2,22.69.61,43.49,2.53,62.09,5.1-16.93,4.49-36.3,10.82-57.09,19.9-18.39,8.03-34.4,16.69-48,25,22.83-.93,48.71-.64,77,2,22.22,2.07,42.63,5.28,61,9-53,19.67-106,39.33-159,59l42,5-64.35,27.98c2.64-9.72,5.53-25.98,1.35-44.98-1.53-6.96-3.73-12.99-6-18-.97-.97-2.28-2.07-4-3-4.53-2.47-8.99-2.24-11-2-11,9.33-22,18.67-33,28,.65-5.96,1.29-19.55-6-34-4.36-8.64-9.99-14.49-14-18-2.51,9.44-6.27,20.38-12,32-11.11,22.55-25.16,38.61-36,49,2.93-10.18,6.75-28.58,3-51-2.72-16.25-8.44-28.82-13-37-9.67,17.33-19.33,34.67-29,52,9-17.33,18-34.67,27-52-11.11,6.15-24.42,14.83-38,27-18.72,16.76-31.19,34.08-39.2,47.33-.27-20.44-.53-40.89-.8-61.33-4.96,2.96-11.35,7.68-17,15-14.37,18.63-13.09,40.06-12.48,46.33-4.96-3.57-11.32-9.1-16.52-17.33-7.73-12.22-7.88-22.43-14-24-2.85-.73-7.34.33-14.47,8.37.24,7.41-.32,17.23-5.53,19.63-4.65,2.14-9.2-3.38-16-3-5.3.3-12.8,4.23-21.3,21.85-.46,2.01-2.39,11.49,3.3,20.15,5.7,8.67,16.76,13.04,28,11,2.98,8.4,5.97,16.8,8.95,25.2Z"
      />
    </g>
  );
};

export default Goku;
