import { Grid, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import SpacingWrapper from "../../utils/SpacingWrapper";
import {
  cardVeryLight,
  doubtedBlue,
  normal,
  text,
  offWhite,
  menuShadow,
  linearGradientToTop,
  backgroundOpacity,
  background,
} from "../../utils/themeContstants";
import CustomMenuButton from "../custom/CustomMenuButton";
import SecondaryButton from "../custom/SecondaryButton";
import fortniteCover from "../../assets/games/fortnite-game-cover.jpeg";
import clashCover from "../../assets/games/clash-game-cover.jpeg";
import fifaCover from "../../assets/games/fifa-game-cover.jpeg";
import chessCover from "../../assets/games/chess-game-cover.png";
import warzoneCover from "../../assets/games/warzone-game-cover.png";
import {
  getGameHeader,
  getGameName,
  hexToRgbA,
  isWithinOneMinute,
} from "../../utils/helpers";
import { getMatchCount } from "../../api/matches";
import CreateMatch from "./CreateMatch";
import GameConnectionModal from "../connections/GameConnectionModal";
import { BsPlus } from "react-icons/bs";
import DownloadACModal from "../anti_cheat/DownloadACModal";
import UpdateTOSModal from "../support/UpdateTOSModal";

const Matches = ({ game }) => {
  const navigate = useNavigate();
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const isTablet = useMediaQuery("(max-width: 768px)");
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState("all");
  const [createOpen, setCreateOpen] = useState(false);
  const [openCount, setOpenCount] = useState(null);
  const [ongoingCount, setOngoingCount] = useState(null);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [tosOpen, setTosOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);

  const hasGameAccount = () => {
    switch (game) {
      case "fortnite":
        return store?.user?.connections?.epic?.name != null;
      case "clashroyale":
        return store?.user?.connections?.clashroyale?.name != null;
      case "chess":
        return store?.user?.connections?.chess?.name != null;
      case "fifa":
        return store?.user?.connections?.fifa?.name != null;
      case "warzone":
        return store?.user?.connections?.activision?.name != null;
      default:
        return null;
    }
  };

  const getLabel = () => {
    if (selected === "all") return "Open Matches";

    return "Ongoing Matches";
  };

  const getNavigatePath = (type) => {
    switch (game) {
      case "fortnite":
        if (type === "all") return "/wagers/matches";
        return "/wagers/ongoing";
      case "warzone":
        if (type === "all") return "/warzone/matches";
        return "/warzone/ongoing";
      case "clashroyale":
        if (type === "all") return "/clashroyale/matches";
        return "/clashroyale/ongoing";
      case "chess":
        if (type === "all") return "/chess/matches";
        return "/chess/ongoing";
      case "fifa":
        if (type === "all") return "/fifa/matches";
        return "/fifa/ongoing";
      default:
        return null;
    }
  };

  const getGame = () => {
    switch (game) {
      case "fortnite":
        return fortniteCover;
      case "clashroyale":
        return clashCover;
      case "chess":
        return chessCover;
      case "fifa":
        return fifaCover;
      case "warzone":
        return warzoneCover;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (game) {
      setLoading(true);
      setSelected("all");
      setOpenCount(null);
      setOngoingCount(null);
    }
  }, [game]);

  useEffect(() => {
    if (loading) {
      if (!openCount && !ongoingCount) {
        getMatchCount(game).then((res) => {
          setLoading(false);

          if (res?.error) {
            updateStore({ type: SET_ERRORS, payload: res?.message });
          } else {
            setOpenCount(res?.open_match_count);
            setOngoingCount(res?.ongoing_match_count);
          }
        });
      }
    }
  }, [loading, openCount, ongoingCount, game]);

  useEffect(() => {
    const path = location?.pathname;

    if (path?.split("/")[2] === "matches") {
      setSelected("all");
      return;
    }

    if (path?.split("/")[2] === "ongoing") {
      setSelected("ongoing");
      return;
    }

    setSelected(null);
  }, [location]);

  const styles = {
    container: {
      // marginTop:'20px',
      width: "100%",
      minHeight: "100vh",
      position: "relative",
    },
    topContainer: {
      width: "100%",
      position: "relative",
    },
    width: {
      width: "100%",
    },
    header: {
      fontSize: isTablet ? 28 : 54,
      fontWeight: 800,
      color: text,
    },
    label: {
      fontSize: 16,
      color: text,
      fontWeight: 700,
    },
    imageContainer: {
      height: isTablet ? 107 : 192,
      width: isTablet ? 80 : 144,
      borderRadius: 1,
      overflow: "hidden",
      boxShadow: "0 1px 5px 1px rgb(0 0 0 / 20%)",
      position: "relative",
      transition: "0.2s all ease-in-out",
      opacity: 1,
    },
    meta: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
    },
    createButton: {
      position: "absolute",
      top: 10,
      right: 10,
      borderRadius: 100,
      backgroundColor: doubtedBlue,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 30,
      width: 30,
      zIndex: 10,
      boxShadow: menuShadow,
    },
  };

  return (
    <>
      <CreateMatch
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        game={game}
      />
      <GameConnectionModal
        open={addOpen}
        onClose={() => setAddOpen(false)}
        game={"fortnite"}
        minWidth={500}
      />
      <DownloadACModal
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
      />
      <UpdateTOSModal open={tosOpen} onClose={() => setTosOpen(false)} />

      <Grid item sx={styles.container}>
        {isTablet && !store?.user?.match_info?.match_id && (
          <Grid
            item
            sx={styles.createButton}
            onClick={() => {
              if (!store?.user) {
                navigate("/login");
                return;
              }

              // if (
              //   store?.user?.info?.last_agreed_tos_version !== store?.tosVersion
              // ) {
              //   setTosOpen(true);
              //   return;
              // }

              if (
                game === "fortnite" &&
                store?.user?.anticheat?.must_run_ac &&
                !isWithinOneMinute(
                  store?.user?.anticheat?.last_heartbeat_timestamp
                )
              ) {
                setDownloadOpen(true);
                return;
              }

              setCreateOpen(true);
            }}
          >
            <BsPlus style={{ fontSize: 30, color: text }} />
          </Grid>
        )}

        <div
          style={{
            // backgroundImage: `url(${getGameHeader(game)})`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            width: "100%",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
            height: 200,
          }}
        />

        <div
          style={{
            backgroundColor: backgroundOpacity(0.6),
            zIndex: -1,
            position: "absolute",
            width: "100%",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 0,
            height: 200,
          }}
        />

        <div
          style={{
            background: linearGradientToTop(
              hexToRgbA(background, 1),
              hexToRgbA(background, 0)
            ),
            zIndex: -1,
            position: "absolute",
            width: "100%",
            top: 0,
            bottom: -1,
            right: 0,
            left: 0,
            zIndex: 0,
            height: 200,
          }}
        />

        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <SpacingWrapper>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 4 }}
              sx={{ paddingBottom: 8, maxWidth: 1200 }}
            >
              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 2 }}
                >
                  {/* <Grid item>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 2 }}
                    >
                      <GameBadge game={game} size="large" />

                      <Grid item>
                        <Typography sx={styles.header}>Matches</Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      gap={{ xs: 2 }}
                    >
                      <Grid item>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          gap={{ xs: 2 }}
                        >
                          {/* <Grid item sx={styles.imageContainer}>
                            <img
                              style={{
                                position: "absolute",
                                top: 0,
                                width: isTablet ? 80 : "100%",
                                height: isTablet ? 107 : "100%",
                              }}
                              // src={getGame()}
                              draggable={false}
                              alt={`${game}-cover`}
                            />
                          </Grid> */}

                          <Grid item sx={{ zIndex: 1 }}>
                            <Grid
                              container
                              direction="column"
                              alignItems="start"
                              justifyContent="center"
                            >
                              <Grid item>
                                <Typography sx={styles.header}>
                                  {getGameName(game)}
                                </Typography>
                              </Grid>

                              <Grid item>
                                <Grid
                                  container
                                  justifyContent="start"
                                  alignItems="center"
                                  gap={{ xs: 1 }}
                                >
                                  {!loading ? (
                                    <Grid item>
                                      <Typography sx={styles.meta}>
                                        <span
                                          style={{
                                            fontWeight: 800,
                                            color: text,
                                          }}
                                        >
                                          {openCount}
                                        </span>{" "}
                                        Open{" "}
                                        {!isTablet && (
                                          <>
                                            {openCount === 1
                                              ? "Match"
                                              : "Matches"}
                                          </>
                                        )}
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    <Grid item>
                                      <Skeleton
                                        variant="rectangular"
                                        width={150}
                                        height={15}
                                        sx={{
                                          borderRadius: 1,
                                          bgcolor: cardVeryLight,
                                        }}
                                      />
                                    </Grid>
                                  )}

                                  <Grid item>
                                    <Typography sx={styles.meta}>
                                      <span style={{ fontWeight: 800 }}>•</span>
                                    </Typography>
                                  </Grid>

                                  {!loading ? (
                                    <Grid item>
                                      <Typography sx={styles.meta}>
                                        <span
                                          style={{
                                            fontWeight: 800,
                                            color: text,
                                          }}
                                        >
                                          {ongoingCount}
                                        </span>{" "}
                                        Ongoing{" "}
                                        {!isTablet && (
                                          <>
                                            {ongoingCount === 1
                                              ? "Match"
                                              : "Matches"}
                                          </>
                                        )}
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    <Grid item>
                                      <Skeleton
                                        variant="rectangular"
                                        width={150}
                                        height={15}
                                        sx={{
                                          borderRadius: 1,
                                          bgcolor: cardVeryLight,
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {!isTablet && (
                        <>
                          <Grid item>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              gap={{ xs: 1 }}
                            >
                              {hasGameAccount() && (
                                <Grid item>
                                  <SecondaryButton
                                    bg={doubtedBlue}
                                    label="Create Match"
                                    onClick={() => {
                                      if (!store?.user) {
                                        navigate("/login");
                                        return;
                                      }

                                      if (
                                        store?.user?.info
                                          ?.last_agreed_tos_version !==
                                        store?.tosVersion
                                      ) {
                                        setTosOpen(true);
                                        return;
                                      }

                                      if (
                                        game === "fortnite" &&
                                        store?.user?.anticheat?.must_run_ac &&
                                        !isWithinOneMinute(
                                          store?.user?.anticheat
                                            ?.last_heartbeat_timestamp
                                        )
                                      ) {
                                        setDownloadOpen(true);
                                        return;
                                      }

                                      setCreateOpen(true);
                                    }}
                                    disabled={store?.user?.match_info?.match_id}
                                  />
                                </Grid>
                              )}

                              {!hasGameAccount() && (
                                <Grid item>
                                  <SecondaryButton
                                    label="Add Account"
                                    onClick={() => {
                                      if (!store?.user) {
                                        navigate("/login");
                                        return;
                                      }

                                      setAddOpen(true);
                                    }}
                                    bg={doubtedBlue}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      width: "100%",
                      // borderBottom: `1px solid ${cardVeryLight}`,
                    }}
                  >
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 1 }}
                    >
                      <CustomMenuButton
                        label="Open Matches"
                        onClick={() => navigate(getNavigatePath("all"))}
                        selected={selected === "all"}
                        size={isTablet ? null : "large"}
                      />
                      <CustomMenuButton
                        label="Ongoing Matches"
                        onClick={() => navigate(getNavigatePath("ongoing"))}
                        selected={selected === "ongoing"}
                        size={isTablet ? null : "large"}
                      />
                      {/* <CustomMenuButton
                        label="Coin Customs"
                        onClick={() => navigate(getNavigatePath("customs"))}
                        selected={selected === "customs"}
                        size="large"
                      /> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  marginTop="10px"
                  gap={{ xs: 1 }}
                >
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography sx={styles.label}>{getLabel()}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Outlet context={[game]} />
                </Grid>
              </Grid>
            </Grid>
          </SpacingWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default Matches;
