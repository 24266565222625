const Hockey = ({ color }) => {
  const styles = {
    one: {
      fill: "#f4f4f4",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "none",
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#fff",
    },
    five: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#fff",
      strokeWidth: 2,
    },
    six: {
      fill: color,
    },
  };

  return (
    <g>
      <path
        style={styles.five}
        d="M459.59,605.44l17.24,47.42,25.67-45.89c-5.14,1.42-12.75,2.91-22,2.52-8.83-.37-16.01-2.31-20.91-4.06Z"
      />
      <path
        style={styles.six}
        d="M288.78,746.93c10.25,11.89,92.23,103.85,220.22,97.07,87.36-4.62,145.7-52.58,164.05-69.01-.65-21.39-2.87-46.03-8.05-72.99-3.51-18.29-7.86-34.97-12.51-49.91-9.83-10.58-22.5-22.22-38.49-33.09-39.09-26.58-78.13-35.04-102.11-38.07-.53,1.96-1.16,3.99-1.89,6.07-2.8,7.96-6.42,14.61-10,19.98-2.21-.02-3.86,.5-5,1.02-5.32,2.45-8.41,9.17-8.25,17.05,.35,2.68,.56,6.97-.75,11.95-1.79,6.82-5.48,11.34-7.5,13.5-.68-.72-1.67-1.88-2.5-3.5-2.35-4.56-1.15-8.18-2-14-.3-2.06-.95-5.01-2.52-8.49-2.12-4.77-4.24-9.54-6.36-14.31-.74-.68-1.77-1.49-3.12-2.19-.92-.48-1.78-.8-2.52-1.02-6.3-2.08-12.59-4.16-18.89-6.24-7.86,.09-15.73,.17-23.59,.26-14.22,4.67-28.43,9.33-42.65,14-4.27,.14-9.5,.65-15.35,2-5.92,1.37-10.9,3.23-14.82,5-13.34,10.03-26.68,20.06-40.02,30.09-4.62,13.02-9.2,29.14-12.15,47.91-2.79,17.75-3.47,33.65-3.22,46.93Z"
      />
      <path
        style={styles.four}
        d="M449.03,576c.12,1.48,.36,3.16,.78,5,.54,2.31,1.24,4.29,1.94,5.9,1.61,2.77,3.3,6.05,4.85,9.84,1.52,3.71,2.61,7.17,3.39,10.24-2.89,.12-10.93,.05-18.91-5.32-9.86-6.62-12.69-16.65-13.32-19.14,7.09-2.17,14.18-4.34,21.26-6.52Z"
      />
      <path
        style={styles.four}
        d="M510.89,581c-.12,1.24-.35,2.65-.78,4.19-.53,1.94-1.24,3.6-1.93,4.95-1.6,2.32-3.28,5.08-4.81,8.25-1.51,3.11-2.59,6.02-3.37,8.58,2.87,.1,10.85,.04,18.78-4.46,9.79-5.56,12.6-13.97,13.22-16.06-7.04-1.82-14.07-3.64-21.11-5.47Z"
      />
      <path
        style={styles.two}
        d="M460,606.98c.9,.22,2.81,.82,4.5,2.52,1.08,1.09,1.68,2.23,2,3,.45,.8,1.2,2.2,2,4,.85,1.91,5.45,12.58,6,24,.08,1.67,.19,5.79,3,9,.39,.45,.75,.78,1,1,5.66-6.34,7.46-11.94,8-16,.92-6.87-1.19-13.46,3-21,.74-1.33,1.49-2.35,2-3,.86-.85,2.52-2.24,5-3,1.36-.42,2.58-.52,3.5-.52"
      />
      <path
        style={styles.one}
        d="M341.46,623.78c9.25-4.57,16.8-6.43,22.04-7.28,3.29-.53,8.89-1.18,16-2,3.78-.44,6.89-.78,9-1,7.5-.33,12.65-2.27,16-4,3.59-1.86,6.94-4.45,13-6,2.5-.64,4.61-.89,6-1l17.09-1.19c-1.91-1.5-4.01-3.41-6.09-5.81-4.2-4.86-6.59-9.76-7.96-13.37-11.24,3.82-23.35,8.53-36.04,14.37-18.94,8.73-35.28,18.2-49.04,27.28Z"
      />
      <path
        style={styles.one}
        d="M528.5,593.69c4.1-.14,9.94,.24,16,2.98,5.61,2.54,5.97,4.68,11,7.94,9,5.84,19.26,6.66,35,7.94,2.75,.22,7.15,.5,13,1.98,4.48,1.14,8.04,2.54,10.37,3.56-9.91-6.4-22.04-13.25-36.37-19.44-16.9-7.3-32.48-11.8-45.5-14.65-.01,1.04-.13,2.32-.5,3.74-.72,2.76-2.05,4.76-3,5.95Z"
      />
      <path d="M361.97,806.78c.93-23.01,2.95-69.31,4.03-92.31,0,0,1,.05,1,.05-1.09,23-3.45,69.28-4.7,92.27,0,0-.33-.02-.33-.02h0Z" />
      <line style={styles.two} x1="602.28" y1="819.1" x2="594.5" y2="721.5" />
      <path
        style={styles.one}
        d="M304.15,652.09c3.69,10.97,12.79,32.74,34.35,50.41,9.98,8.18,19.9,13.25,27.79,16.43-.14,2.96-.28,5.92-.42,8.87-7.35-.3-20.42-1.94-33.37-10.3-28.54-18.43-30.93-52.99-31.16-57.26l2.81-8.15Z"
      />
      <path
        style={styles.one}
        d="M652.49,652.09c-2.79,10.09-8.37,25.48-19.99,41.41-13.13,17.99-28.1,28.64-37.5,34.3,.21,2.69,.43,5.37,.64,8.06,7.48-2.36,25.04-9.02,39.86-26.36,15.95-18.66,19.32-38.7,20.22-46.4l-3.22-11.01Z"
      />
      <circle cx="460" cy="615" r="3" />
      <circle cx="467" cy="641" r="3" />
      <circle cx="464" cy="626" r="3" />
      <circle cx="493" cy="625" r="3" />
      <circle cx="491" cy="642" r="3" />
      <circle cx="496" cy="616" r="3" />
      <path
        style={styles.four}
        d="M458.5,614.5c-1.83,5.83-3.67,11.67-5.5,17.5,0,0-2,5-3,8s-1.24,15.61-1.24,15.61c.08,.43,.37,.78,.74,.89,.52,.15,1.17-.21,1.37-.89,.91-5.38,1.82-10.76,2.73-16.13,2.97-8.33,5.93-16.65,8.9-24.98-.54-1.71-2.1-2.41-3-2-.62,.28-.99,1.1-1,2Z"
      />
      <path
        style={styles.four}
        d="M494.5,615.5c-.27-.82,.18-1.73,1-2s1.73,.18,2,1c1.15,3.83,2.3,7.67,3.46,11.5,.25,2.4,.44,4.9,.54,7.5,.33,8.18-.23,15.61-1.2,22.11-.34,.8-1.19,1.14-1.8,.89-.43-.18-.73-.64-.77-1.17,.77-5.77,1.18-12.44,.77-19.83-.16-2.86-.43-5.58-.77-8.15-1.08-3.95-2.15-7.9-3.23-11.85Z"
      />
      <path
        style={styles.four}
        d="M462.5,624.61c-.4,.58-.39,1.34,0,1.89,.34,.47,.91,.71,1.5,.65,2.24-1.44,7.79-4.58,15.5-4.65,6.72-.06,11.74,2.24,14.09,3.5,1.04,.15,1.91-.66,1.91-1.5s-.87-1.65-1.91-1.5c-2.46-1.29-7.87-3.71-15.09-3.5-8,.24-13.69,3.57-16,5.11Z"
      />
      <path
        style={styles.four}
        d="M465.5,640.5c-.55,.67-.47,1.58,0,2,.54,.48,1.65,.37,2.2-.5,2.18-1.53,5.11-3.24,8.8-4.5,1.55-.53,3.02-.91,4.37-1.17-1.46-.94-2.91-1.88-4.37-2.83l-11,7Z"
      />
      <path
        style={styles.four}
        d="M491.76,641c.56,.35,.85,.96,.74,1.5-.15,.73-1.06,1.28-2,1l-17.51-13.42-1.06-4.08,19.83,15Z"
      />
      <polygon
        style={styles.four}
        points="480 632.1 486.75 630.86 486.5 634.5 483.38 634.79 480 632.1"
      />
      <path
        style={styles.four}
        d="M471.93,626c4.15,.2,8.34,.37,12.57,.5,.75,.02,1.5,.04,2.25,.06,0-.69,.01-1.37,.02-2.06-1.85-.5-4.33-.97-7.27-1.01-3.29-.04-6.04,.48-8.02,1.01l.45,1.49Z"
      />
      <path
        style={styles.three}
        d="M448.99,649.2l-.23,6.19c.02,.54,.33,.99,.74,1.11,.55,.16,1.29-.3,1.41-1.11,.35-2.07,.7-4.13,1.05-6.19h-2.96Z"
      />
      <path
        style={styles.three}
        d="M498.5,650.5h2v5s-.3,.85-1,1c-.69,.15-1.53-.38-1.75-1.31,.25-1.56,.5-3.12,.75-4.69Z"
      />
    </g>
  );
};

export default Hockey;
