const JeanJacket = ({ color }) => {
  const styles = {
    one: {
      fill: "none",
      strokeMiterLimit: 10,
      stroke: "#000",
    },
    two: {
      fill: "none",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      stroke: "#000",
    },
    three: {
      strokeMiterLimit: 10,
      stroke: "#000",
      fill: "#fbffa2",
    },
    four: {
      strokeMiterLimit: 10,
      fill: color,
      stroke: color,
      strokeWidth: 0.5,
    },
    five: {
      strokeMiterLimit: 10,
      stroke: "#000",
      fill: "#fff",
    },
    six: {
      fill: color,
    },
  };

  return (
    <g>
      <path
        style={styles.six}
        d="M504.95,644.96c1.05-.96,9.39-12.16,20.22-14.06,0,0-2.34,14.1-2.76,14.6s-17.47-.54-17.47-.54Z"
      />
      <path
        style={styles.six}
        d="M479.27,627.91c3.82,2.28,8.19,5.25,12.73,9.09,4.5,3.82,8.13,7.62,11,11l-28-14,4.27-6.09Z"
      />
      <path
        style={styles.six}
        d="M447.13,577.64c-.13,.36,3.01-11.63,3.01-11.63l-17.83-2.32c-2,2.92-5,6.67-9.31,10.31-4.82,4.07-9.54,6.52-13,8,.5,4.85,1,9.7,1.5,14.56l35.63-18.92Z"
      />
      <path
        style={styles.four}
        d="M450.5,566.5c-6.48,3.59-16.15,8.86-28,15-23.54,12.19-39.85,19.58-47,23-17.44,8.34-41.87,22.44-71.35,47.56-2.8,9.13-5.4,18.95-7.65,29.44-5.16,24.08-7.24,46.18-7.72,65.36,16.29,18.96,43.44,45.41,83.72,65.64,14.67,7.37,67.6,32.27,139,28,46.92-2.8,81.78-17.04,97-24,28.86-13.2,50.27-29.17,64.5-41.5-.82-14.62-2.25-30.16-4.5-46.5-3.87-28.17-9.48-53.65-15.71-76.13-10.48-10.98-25.38-24.44-45.29-36.87-21.28-13.28-41.21-20.95-56.33-25.5-4.25-5.62-8.42-9.76-11.67-12.5-6.26-5.27-12.38-8.59-17.03-10.66-1.05-.51-2.76-1.18-4.97-1.34-2.87-.22-5.12,.52-6.3,1v15.46c3.03,1.69,10.89,6.63,15.3,16.54,6.92,15.57-.58,30.21-1.6,32.12-7.29,5.69-14.58,11.38-21.87,17.07l-24.2-20.15h-15.25c-2.58-1.97-17.38-13.69-19.09-34.04-1.1-13.02,3.73-22.95,6-27Z"
      />
      <path
        style={styles.two}
        d="M450.77,566.23c-1.84-.65-3.94-1.26-6.27-1.73-4.71-.95-8.89-1.02-12.19-.81-2.83,4.03-7.26,9.24-13.81,13.81-2.83,1.97-5.57,3.48-8.06,4.65,.3,9.16,1.37,20.16,4.06,32.35,3.2,14.49,7.78,26.59,12.12,35.98,9.29-3.71,15.18-8.35,18.88-11.98,4.64-4.54,8.99-10.58,16-11,1.9-.12,3.48,.21,4.5,.5-1.57-1.2-26.01-20.4-21.5-42.5,.59-2.87,1.77-6.34,4.19-10.08,.69-3.06,1.39-6.13,2.08-9.19Z"
      />
      <path
        style={styles.five}
        d="M449.62,575c-.61,3.21-1.14,8.5,.79,14.16,5.46,15.97,26.33,23.51,40.27,21.12,7.31-1.25,11.37-1.66,18.66-7.04,1.17-.86,8.14-8.28,5.89-16.09-.84-2.92-2.96-5.02-4.23-6.15,3.36,2.2,9.25,6.69,13.07,14.19,8.33,16.34,.99,33.16-.06,35.49-2.33,.82-5.01,2.01-7.79,3.74-6.79,4.23-10.94,9.64-13.23,13.27-2.21-2.88-4.97-6.06-8.38-9.24-5.5-5.13-10.94-8.64-15.39-11.03-1.02,.65-3.64,2.12-7.2,1.97-2.48-.1-4.37-.95-5.4-1.51-1.89-1.38-23.32-17.45-21.12-39.73,.33-3.39,1.32-7.99,4.12-13.15Z"
      />
      <path
        style={styles.two}
        d="M512,581c3.54,1.88,8.5,5.96,12.5,12.5,10.15,16.58,.71,35.76-.07,37.28,2.14,.33,4.53,.86,7.07,1.72,7,2.36,12.05,6.13,15.24,9.02,2.28,1.7,6.3,4.16,11.76,4.98,1.9,.28,3.63,.31,5.13,.23-2.91-6.75-5.84-14.87-8.13-24.23-3.03-12.4-4.1-23.51-4.33-32.5-2.6-3.59-6.38-8.1-11.67-12.5-5.55-4.61-10.92-7.59-15.07-9.5-1.05-.74-3.48-2.24-6.93-2.5-2.88-.22-4.34,1.02-5.5,1.5,.08,4.97-.08,9.03,0,14Z"
      />
      <circle style={styles.three} cx="412" cy="703" r="6.5" />
      <circle style={styles.three} cx="563" cy="702" r="6.5" />
      <ellipse style={styles.three} cx="477.5" cy="639" rx="6" ry="6.5" />
      <path
        style={styles.two}
        d="M503.59,646.73c-2.11,1.3-4.64,3.15-7.09,5.77-3.32,3.56-4.46,6.36-9,14-1.47,2.47-3.15,5.21-5.07,8.17,1.02,20.28,2.04,40.56,3.07,60.83,1.87,37.09,.12,72.16-1.87,104.81"
      />
      <path
        style={styles.one}
        d="M528.5,631.61c-3.64,2.67-8.72,7.11-13,13.89-7.37,11.67-7.96,23.27-7.89,28.44,1.2,55.33,2.39,110.66,3.59,165.99"
      />
      <g>
        <path
          style={styles.one}
          d="M365.89,681.34c-.95,1.88-2.03,4.66-2.39,8.16-.52,5.06,.74,9.04,1.52,11.05,.54,.21,1.09,.42,1.64,.63,10.06,3.81,21.72,7.21,34.84,9.32,3.6,.58,7.1,1.03,10.5,1.37,7.49-.98,16.12-2.62,25.5-5.37,8.05-2.35,15.16-5.09,21.28-7.81,.79-2.59,1.57-6.06,1.72-10.19,.1-2.68-.09-5.07-.38-7.1"
        />
        <path
          style={styles.one}
          d="M365.89,681.34c.2-.42,.4-.79,.59-1.12l93.52,.37c.04,.26,.08,.53,.12,.81"
        />
        <path
          style={styles.one}
          d="M366.65,700.84l1.4,51.99c4.98,2.31,10.47,4.58,16.45,6.67,10.86,3.8,20.97,6.18,29.84,7.67,4.75-.91,9.83-2.11,15.16-3.67,10.45-3.07,19.5-6.88,27.05-10.67,1.29-6.35,2.37-13.5,2.95-21.33,.93-12.46,.37-23.56-.72-32.81"
        />
      </g>
      <path
        style={styles.one}
        d="M515.08,680.4l87.78,.54c1.05,2.49,2.23,6.09,2.64,10.56,.3,3.27,.09,6.11-.23,8.32-12.72,4.01-25.43,8.03-38.15,12.04-6.14-.77-12.71-1.85-19.62-3.37-12.27-2.69-23.11-6.18-32.42-9.81-.5-3.5-.8-7.61-.58-12.19,.1-2.17,.31-4.21,.58-6.1Z"
      />
      <path
        style={styles.one}
        d="M517.99,699.82l.06,47.1c4.84,3.4,11.33,7.3,19.45,10.57,9.15,3.69,17.42,5.4,23.74,6.22,10.16-2.49,20.99-5.8,32.26-10.22,3.03-1.19,5.98-2.41,8.84-3.66-.74-16.13-1.48-32.26-2.22-48.39"
      />
      <polygon
        style={styles.one}
        points="323.05 635.59 360.19 720.46 362.64 711.44 328.39 632.7 323.05 635.59"
      />
      <polygon
        style={styles.one}
        points="625.37 627.89 603.22 723.09 604.52 731.77 629.65 630.78 625.37 627.89"
      />
      <polyline
        style={styles.one}
        points="604.74 733.01 613.55 776.97 620.1 810.58"
      />
      <polyline
        style={styles.one}
        points="360.19 720.46 358.91 771.8 359.55 805.17"
      />
      <path
        style={styles.one}
        d="M453.46,630.78c5.38,3.6,12.27,8.99,19.04,16.72,6.67,7.62,11.07,15.05,13.92,20.8"
      />
      <path
        style={styles.one}
        d="M448.69,575.42c.25-.43,.58-1.08,.81-1.92,.18-.67,.75-2.78,0-4-1.35-2.18-6.83-1.19-10,0-2.05,.77-3.57,1.79-4.55,2.54-.3,1.7-.47,3.52-.45,5.46,.16,12.16,7.96,21,11.51,24.54"
      />
      <polygon
        style={styles.one}
        points="411.5 599.5 355.42 615.73 376.25 603.95 410.5 593.5 411.5 599.5"
      />
      <polygon
        style={styles.one}
        points="551.56 597.67 612.28 617.94 622.76 625.8 552.05 602.97 551.56 597.67"
      />
      <polygon
        style={styles.one}
        points="377.5 705.5 379.07 758.12 384.23 817.59 390.73 820.38 385.72 759.33 382.5 706.5 377.5 705.5"
      />
      <polygon
        style={styles.one}
        points="528.5 703.46 529.61 753.9 531.18 837.94 538.69 836.82 536.66 757.15 535.74 706.16 528.5 703.46"
      />
      <polygon
        style={styles.one}
        points="591.23 704.12 591.41 754.31 586.56 824.55 593.49 822.55 595.57 752.83 595.57 703.09 591.23 704.12"
      />
      <polyline
        style={styles.one}
        points="460.12 681.4 482.45 681.41 482.45 675.62 347.16 675.62 349.37 681.33 365.89 681.34"
      />
      <polyline
        style={styles.one}
        points="603.09 681.49 612.5 681.5 614.27 675.62 507.61 673.94 482.45 675.62 482.45 681.41 514.94 681.43"
      />
      <ellipse style={styles.three} cx="497.5" cy="685" rx="7" ry="6.5" />
    </g>
  );
};

export default JeanJacket;
