import { Grid } from "@mui/material";
import snipe from "../../assets/3d_icons/snipes.png";

const SnipeCoin = ({ size }) => {
  return (
    <Grid
      item
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <img draggable={false} height={size} width={size} src={snipe} />
    </Grid>
  );
};

export default SnipeCoin;
