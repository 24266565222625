import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import fortniteCover from "../../assets/games/fortnite-game-cover.jpeg";
import fifaCover from "../../assets/games/fifa-game-cover.jpeg";
import clashCover from "../../assets/games/clash-game-cover.jpeg";
import chessCover from "../../assets/games/chess-game-cover.png";
import warzoneCover from "../../assets/games/warzone-game-cover.png";
import {
  doubtedBlue,
  secondaryText,
  small,
  text,
} from "../../utils/themeContstants";
import { getGameName } from "../../utils/helpers";

const GameItem = ({ game, onClick, comingSoon = false, size }) => {
  const [hovered, setHovered] = useState(false);

  const getGame = () => {
    switch (game) {
      case "fortnite":
        return fortniteCover;
      case "warzone":
        return warzoneCover;
      case "clashroyale":
        return clashCover;
      case "chess":
        return chessCover;
      case "fifa":
        return fifaCover;
      default:
        return null;
    }
  };

  const styles = {
    imageContainer: {
      height: size === "small" ? 218 : 250,
      width: size === "small" ? 164 : 185,
      borderRadius: 1,
      overflow: "hidden",
      boxShadow: "0 1px 5px 1px rgb(0 0 0 / 20%)",
      position: "relative",
      transition: "0.2s all ease-in-out",
      opacity: comingSoon ? 0.5 : 1,
      "&:hover": {
        cursor: comingSoon ? "not-allowed" : "pointer",
        transform: comingSoon ? null : "scale(1.05)",
        opacity: comingSoon ? 0.5 : 0.8,
      },
    },
    name: {
      fontSize: 15,
      fontWeight: 700,
      color: comingSoon ? secondaryText : hovered ? doubtedBlue : text,
      cursor: comingSoon ? "not-allowed" : hovered ? "pointer" : "default",
    },
    soon: {
      borderRadius: 1,
      padding: 1,
      backgroundColor: doubtedBlue,
      position: "absolute",
      top: -15,
      right: -15,
      zIndex: 3,
    },
  };

  return (
    <Grid
      item
      onClick={comingSoon ? null : onClick}
      sx={{ position: "relative" }}
    >
      {comingSoon && (
        <Grid item sx={styles.soon}>
          <Typography sx={{ fontSize: small, fontWeight: 800, color: text }}>
            COMING SOON!
          </Typography>
        </Grid>
      )}

      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 1 }}
      >
        <Grid item sx={styles.imageContainer}>
          <img
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
            }}
            src={getGame()}
            draggable={false}
            alt={`${game}-cover`}
          />
        </Grid>

        <Grid
          item
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={comingSoon ? null : onClick}
        >
          <Typography sx={styles.name}>{getGameName(game)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GameItem;
