import { Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import Countdown from "react-countdown";
import { AiOutlineEye } from "react-icons/ai";
import { BsCheck } from "react-icons/bs";
import { MdTimer } from "react-icons/md";
import { RiTimerFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  SET_JOIN_MATCH_ID,
  SET_SNIPE_MATCH_ID,
  SET_WALLET_OPEN,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { AccountTypeEnum } from "../../utils/enums";
import {
  getMatchTypeImage,
  getPlatformString,
  getRegionString,
  getRegionStringFromArray,
  getTeamSizeString,
  getMatchTitle,
  getWordLogo,
  hexToRgbA,
  isWithinOneMinute,
  numFormatter,
  timeRemaining,
} from "../../utils/helpers";
import {
  backgroundOpacity,
  card,
  cardDark,
  cardExtraLight,
  cardLight,
  cardVeryLight,
  darkGrey,
  doubtedBlue,
  doubtedOrange,
  green,
  large,
  linearGradientToTop,
  normal,
  red,
  secondaryText,
  text,
  transition,
  yellow,
} from "../../utils/themeContstants";
import DownloadACModal from "../anti_cheat/DownloadACModal";
import UpgradeModal from "../app/UpgradeModal";
import GameConnectionModal from "../connections/GameConnectionModal";
import ACCoin from "../custom/ACCoin";
import Badge from "../custom/Badge";
import CoinBadge from "../custom/CoinBadge";
import CustomIconButton from "../custom/CustomIconButton";
import CustomPill from "../custom/CustomPill";
import ExpireTimer from "../custom/ExpireTimer";
import GameBadge from "../custom/GameBadge";
import SecondaryButton from "../custom/SecondaryButton";
import BuySnipesModal from "../subscriptions/BuySnipesModal";
import UpdateTOSModal from "../support/UpdateTOSModal";
import ChessMatchItemDetails from "./match_item_details/ChessMatchItemDetails";
import ClashMatchItemDetails from "./match_item_details/ClashMatchItemDetails";
import FifaMatchItemDetails from "./match_item_details/FifaMatchItemDetails";
import FortniteMatchItemDetails from "./match_item_details/FortniteMatchItemDetails";

const MatchItem = ({ match, home = false }) => {
  
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const navigate = useNavigate();

  const [snipeOpen, setSnipeOpen] = useState(false);
  const [buySnipesOpen, setBuySnipesOpen] = useState(false);
  const [buyPremiumOpen, setBuyPremiumOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [tosOpen, setTosOpen] = useState(false);

  // new state
  const [hovered, setHovered] = useState(false);

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <span style={{ color: hexToRgbA(text, 0.8) }}>Expired</span>;
    } else {
      return (
        <span
          style={{
            color: minutes === 0 && seconds > 0 ? red : hexToRgbA(text, 0.8),
          }}
        >
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </span>
      );
    }
  };

  const hasGameAccount = (game) => {
    switch (game) {
      case "fortnite":
        return store?.user?.connections?.epic?.name != null;
      case "clashroyale":
        return store?.user?.connections?.clashroyale?.name != null;
      case "chess":
        return store?.user?.connections?.chess?.name != null;
      case "fifa":
        return store?.user?.connections?.fifa?.name != null;
      case "warzone":
        return store?.user?.connections?.activision != null;
      default:
        return null;
    }
  };

  const handleOpenJoin = (e) => {
    e.stopPropagation();

    if (home) {
      navigate(`/wagers/matches`);
      return;
    }

    if (!store?.user) {
      navigate("/login");
      return;
    }

    // if (store?.user?.info?.last_agreed_tos_version !== store?.tosVersion) {
    //   setTosOpen(true);
    //   return;
    // }

    if (
      match?.game === "fortnite" &&
      store?.user?.anticheat?.must_run_ac &&
      !isWithinOneMinute(store?.user?.anticheat?.last_heartbeat_timestamp)
    ) {
      setDownloadOpen(true);
      return;
    }

    if (!hasGameAccount(match?.game)) {
      setAddOpen(true);
      return;
    }

    if (store?.user?.funds?.balance < match?.entry_fee) {
      if (store?.user?.funds?.balance > 0.495 && match?.entry_fee == 0.50) {
        updateStore({ type: SET_JOIN_MATCH_ID, payload: match?._id });
        return;
      }

      if (!store?.walletOpen) {
        updateStore({ type: SET_WALLET_OPEN, payload: true });
      }
      return;
    }

    if (store?.user?.match_info?.match_id) {
      return;
    }

    updateStore({ type: SET_JOIN_MATCH_ID, payload: match?._id });
  };

  const handleViewToken = (e, match_id) => {
    e.stopPropagation();

    if (store?.user.account_type === AccountTypeEnum.GOD) {
      navigate(`/token/${match_id}`);
      return;
    }
  };

  const handleViewOpponent = (e) => {
    e.stopPropagation();

    // if (store?.user?.info?.last_agreed_tos_version !== store?.tosVersion) {
    //   setTosOpen(true);
    //   return;
    // }

    if (
      match?.game === "fortnite" &&
      store?.user?.anticheat?.must_run_ac &&
      !isWithinOneMinute(store?.user?.anticheat?.last_heartbeat_timestamp)
    ) {
      setDownloadOpen(true);
      return;
    }

    if (store?.user?.premium_info?.snipes > 0) {
      return updateStore({ type: SET_SNIPE_MATCH_ID, payload: match?._id });
    }

    if (store?.user?.premium_info?.active) {
      return setBuySnipesOpen(true);
    }

    // open buy premium
    return setBuyPremiumOpen(true);
  };

  const styles = {
    width: {
      width: "100%",
    },
    card: {
      borderRadius: 2,
      overflow: "hidden",
      position: "relative",
      boxSizing: "border-box",
    },
    title: {
      fontSize: 16,
      color: text,
      fontWeight: 800,
    },

    container: {
      padding: 2,
      paddingTop: 1,
      borderRadius: 2,

      backgroundColor: card,
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
    },
    value: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
  };

  const fortniteStyles = {
    width: {
      width: "100%",
    },
    container: {
      position: "relative",
      borderRadius: 2,
      overflow: "hidden",
      transition: transition,
      minHeight: "100%",
      "&:hover": {
        cursor: store?.user?.match_info?.match_id ? "not-allowed" : "pointer",
      },
    },
    top: {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      overflow: "hidden",
      position: "relative",
      width: "100%",
      minHeight: 125,
    },
    bottom: {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      padding: 2,
      backgroundColor: hovered
        ? store?.user?.match_info?.match_id
          ? card
          : cardLight
        : card,
      width: "100%",
      // marginTop: -0.8,
      transition: transition,
      minHeight: "100%",
      flexGrow: 1,
      display: "flex",
      alignItems: "stretch",
      justifyContent: "stretch",
    },
    title: {
      fontWeight: 800,
      fontSize: large,
      color: text,
    },
  };

  return (
    <>
      <BuySnipesModal
        open={buySnipesOpen}
        onClose={() => setBuySnipesOpen(false)}
      />
      <UpgradeModal
        open={buyPremiumOpen}
        onClose={() => setBuyPremiumOpen(false)}
      />
      <GameConnectionModal
        open={addOpen}
        onClose={() => setAddOpen(false)}
        minWidth={500}
        game={match?.game}
      />
      <DownloadACModal
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
      />
      <UpdateTOSModal open={tosOpen} onClose={() => setTosOpen(false)} />

      <Grid
        item
        sx={fortniteStyles.container}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={handleOpenJoin}
      >
        <Grid
          container
          direction="column"
          alignItems="stretch"
          justifyContent="stretch"
          sx={{ minHeight: "100%" }}
        >
          <Grid item sx={fortniteStyles.top}>
            <div
              style={{
                backgroundColor: hexToRgbA(card, 0.4),
                position: "absolute",
                width: "100%",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
              }}
            />

            <div
              style={{
                background: linearGradientToTop(
                  hexToRgbA(card, 1),
                  hexToRgbA(card, 0)
                ),
                position: "absolute",
                width: "100%",
                top: 0,
                bottom: -1,
                right: 0,
                left: 0,
              }}
            />

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 5,
                left: 5,
              }}
            >
              {/* <GameBadge game={match?.game} borderRadius={1.5} /> */}
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 5,
                right: 5,
              }}
            >
              <CoinBadge
                amount={numFormatter().format(match?.entry_fee)}
                label="Entry fee per player to compete in this match"
                placement="top"
                size="small"
                backgroundColor={hexToRgbA(card, 0.6)}
              />
            </Grid>

            <div
              style={{
                backgroundImage: `url(${getMatchTypeImage(
                  match?.game_match_type,
                  match?.game,
                  match?.game_mode_options
                )})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                width: "100%",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                height: "100%",
                zIndex: -1,
              }}
            />

            {/* <img
              src={getMatchTypeImage(match?.game_match_type, match?.game)}
              draggable={false}
              width={"100%"}
              height={"100%"}
              alt="match-image"
              objectFit="cover"
              style={{
                objectFit: "cover",
              }}
            /> */}
          </Grid>

          <Grid item sx={fortniteStyles.bottom} id="bottom">
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 4 }}
              sx={{ flexGrow: 1 }}
            >
              <Grid
                item
                sx={{
                  width: "100%",
                  transition: transition,
                  transform: hovered
                    ? store?.user?.match_info?.match_id
                      ? null
                      : "translateY(-3px)"
                    : null,
                }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 0.5 }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: -0.5,
                    }}
                  >
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 0.5 }}
                    >
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <RiTimerFill
                          style={{
                            fontSize: 16,
                            color: hexToRgbA(text, 0.8),
                          }}
                        />
                      </Grid>

                      <Grid item>
                        <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                          <Countdown
                            date={new Date(match?.expire_time)}
                            renderer={renderer}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 0.5 }}
                    >
                      <Grid item>
                        <Typography sx={fortniteStyles.title}>
                          {getMatchTitle(
                            match?.game_match_type,
                            match?.team_size,
                            match?.game_map_type,
                            match?.extra_options,
                            match?.game_mode_options
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 1 }}
                    >
                      {match?.game === "fortnite" && (
                        <>
                          <CustomPill
                            label={getPlatformString(match?.platform)}
                            hoverLabel="Platform"
                          />

                          {match?.region?.length > 0 ? (
                            <>
                              <CustomPill
                                label={getRegionStringFromArray(match?.region)}
                                hoverLabel="Region"
                              />
                            </>
                          ) : (
                            <CustomPill
                              label={getRegionString(match?.region)}
                              hoverLabel="Region"
                            />
                          )}

                          {!match?.best_of && !match.game_mode_options.options.includes("esl_ctf")  && !match.game_mode_options.options.includes("esl_hp")  && (
                            <CustomPill
                              label={match?.first_to}
                              firstTo={true}
                              hoverLabel="First to"
                            />
                          )}

                          {match?.best_of && (
                            <CustomPill
                              label={`Best of ${match?.best_of}`}
                              hoverLabel="Best of"
                            />
                          )}

                          {match?.extra_options?.kill_lead && (
                            <CustomPill
                              label={`${match?.extra_options?.kill_lead} Round Lead`}
                              hoverLabel="Round lead"
                              color={yellow} // Or any other color you want
                              glow={true} // Make it glow
                            />
                          )}
                        </>
                      )}

                      {match?.game === "fifa" && (
                        <>
                          <CustomPill
                            label={getPlatformString(match?.platform)}
                            hoverLabel="Platform"
                          />

                          <CustomPill
                            label={getRegionString(match?.region)}
                            hoverLabel="Region"
                          />

                          <CustomPill
                            label={`Best of ${match?.best_of}`}
                            hoverLabel="Best of"
                          />
                        </>
                      )}

                      {match?.game === "clashroyale" && (
                        <>
                          <CustomPill
                            label={`Best of ${match?.best_of}`}
                            hoverLabel="Best of"
                          />

                          <CustomPill
                            label={getTeamSizeString(match?.team_size)}
                            hoverLabel="Team size"
                          />
                        </>
                      )}

                      {match?.game === "warzone" && (
                        <>
                          <CustomPill
                            label={getPlatformString(match?.platform)}
                            hoverLabel="Platform"
                          />

                          <CustomPill
                            label={getRegionString(match?.region)}
                            hoverLabel="Region"
                          />

                          <CustomPill
                            label={`Best of ${match?.best_of}`}
                            hoverLabel="Best of"
                          />

                          {match?.extra_options?.must_stream &&
                            match?.platform == null && (
                              <CustomPill
                                label={
                                  match?.extra_options?.must_stream
                                    ? "Must Stream"
                                    : null
                                }
                                hoverLabel="PC Stream Requirements"
                              />
                            )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                  flex: 1,
                }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 1 }}
                >
                  <Grid
                    item
                    sx={fortniteStyles.width}
                    onMouseEnter={() => setHovered(false)}
                    onMouseLeave={() => setHovered(true)}
                  >
                    <SecondaryButton
                      label="View Opponent"
                      onClick={handleViewOpponent}
                      fullWidth
                      disabled={
                        store?.user?.match_info?.match_id === match?._id
                      }
                      size="small"
                    />
                  </Grid>

                  {store?.user?.account_type === AccountTypeEnum.GOD && (
                    <Grid
                      item
                      sx={fortniteStyles.width}
                      onMouseEnter={() => setHovered(false)}
                      onMouseLeave={() => setHovered(true)}
                    >
                      <SecondaryButton
                        label="View Match"
                        onClick={(e) => handleViewToken(e, match?._id)}
                        fullWidth
                        size="small"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid
          item
          sx={styles.container}
          onClick={(e) => handleViewToken(e, match?._id)}
        >
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 2 }}
          >
            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                  >
                    <Grid
                      item
                      sx={{
                        marginBottom: match?.game === "clashroyale" ? -1 : 0,
                        marginLeft: match?.game === "clashroyale" ? -0.5 : 0,
                      }}
                    >
                      <img
                        draggable={false}
                        width={
                          match?.game === "clashroyale" ||
                          match?.game === "chess"
                            ? 65
                            : 50
                        }
                        src={getWordLogo(match?.game)}
                      />
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 1 }}
                      >
                        <Grid item>
                          <Typography sx={styles.title}>
                            {getTokenTitle(
                              match?.game_match_type,
                              match?.team_size,
                              match?.game_map_type,
                              match?.extra_options
                            )}
                          </Typography>
                        </Grid>

                        {match?.ac_verified && (
                          <Badge
                            textTransform="none"
                            label="This match is guaranteed to be protected by Rivox AC, and the team that created this match is being heavily monitored by our anti-cheat system."
                            maxWidth={250}
                          >
                            <Grid
                              item
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                              }}
                            >
                              <Grid
                                item
                                sx={{
                                  position: "absolute",
                                  top: -9,
                                  right: -9,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <BsCheck
                                  style={{ fontSize: 24, color: green }}
                                />
                              </Grid>
                              <ACCoin size={16} />
                            </Grid>
                          </Badge>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 1 }}
                  >
                    <CoinBadge
                      amount={numFormatter().format(match?.entry_fee)}
                      label="Entry fee per player to compete in this match"
                      placement="top"
                      insideLabel="Entry fee"
                      type="dark"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 3 }}
              >
                <ExpireTimer
                  size={90}
                  duration={1800}
                  remaining={timeRemaining(match?.expire_time)}
                  label="Expires in"
                  timerColor={doubtedBlue}
                  match={match}
                />

                <Grid item sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 2 }}
                  >
                    {match?.game === "clashroyale" && (
                      <ClashMatchItemDetails match={match} />
                    )}

                    {match?.game === "fifa" && (
                      <FifaMatchItemDetails match={match} />
                    )}

                    {match?.game === "fortnite" && (
                      <FortniteMatchItemDetails match={match} />
                    )}

                    {match?.game === "chess" && (
                      <ChessMatchItemDetails match={match} />
                    )}

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                        gap={{ xs: 1 }}
                      >
                        <Grid item sx={styles.width}>
                          <SecondaryButton
                            bg={doubtedBlue}
                            label={!home ? "Accept Match" : "View"}
                            disabled={
                              store?.user?.match_info?.match_id === match?._id
                            }
                            onClick={handleOpenJoin}
                            fullWidth
                          />
                        </Grid>

                        {!home && (
                          <Grid item sx={styles.width}>
                            <SecondaryButton
                              label="View Opponent"
                              onClick={handleViewOpponent}
                              fullWidth
                              disabled={
                                store?.user?.match_info?.match_id === match?._id
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
    </>
  );
};

export default MatchItem;
