const Confused = () => {
  return (
    <g>
      <path d="M419.41,346.2c.41-1.16,.46-2.33,1.16-3.1,4.25-4.7,6.49-10.22,9.41-15.45,3.13-5.63,7.91-9.97,14.25-13.25,4.25-2.2,8.92-3.02,13.82-3.15,7-.19,13.74,1.05,20.39,2.69,9.8,2.42,19.09,5.88,28.26,9.57,1.94,.78,3.94,1.46,5.86,2.28,2.71,1.15,3.43,2.82,2.17,5.15-.45,.83-1.12,1.61-1.81,2.33-1.48,1.53-3.19,2.06-5.66,1.34-5.78-1.69-11.59-3.33-17.5-4.66-7.76-1.76-15.67-3.06-23.78-2.78-10.23,.36-20.06,1.96-28.83,6.68-2.63,1.42-4.97,3.06-6.91,5.12-1.75,1.86-3.57,3.68-5.5,5.41-1.22,1.1-2.63,2.11-5.32,1.82Zm13.05-14.03c.54-.08,.8-.07,.99-.16,6.53-3.24,13.53-5.26,21.11-6.07,8.12-.87,16.17-.85,24.21,.48,9.68,1.61,19.04,4.06,28.13,7.23,.93,.32,1.89,.57,3.11,.93,1.01-1.5,2.08-2.8,2.8-4.22,.85-1.65,.24-2.85-1.59-3.84-.5-.27-1.04-.52-1.59-.71-7.23-2.52-14.39-5.2-21.72-7.49-8.4-2.63-17.02-4.71-26.09-5.12-6.32-.29-12.08,.73-17.28,4-6.11,3.85-9.37,9.1-12.07,14.98Z" />
      <path d="M567.97,331.42c13.43,5.08,27.41,8.11,41.83,9.96,14.27,1.83,28.62,1.99,43.03,1.34,.06,.2,.12,.4,.17,.6-.93,.42-1.82,.9-2.78,1.25-4.69,1.74-9.66,2.66-14.68,3.45-12.05,1.9-24.21,2.33-36.42,1.69-8.72-.46-17.38-1.4-25.69-3.8-1.71-.49-3.33-1.18-5.01-1.74-1.28-.43-2.07-1.24-2.66-2.24-1.72-2.93-.73-7.94,2.2-10.52Zm.28,.79c-1.13,2.5-1.4,4.98-.9,7.56,.53,2.74,2.23,4.37,5.56,5.24,7.98,2.08,16.18,2.92,24.46,3.32,7.85,.38,15.71,.27,23.56-.26,7.68-.52,15.27-1.38,22.71-3.06,.58-.13,1.11-.39,1.67-.59-26.84,.78-52.57-2.66-77.06-12.21Z" />
      <path d="M440.71,313.01c.21-.7,.25-1.49,.67-2.09,1.61-2.27,3.87-4,6.92-4.9,7.42-2.18,14.17-.68,21.04,2.65-1.42,.67-2.45,.34-3.43,.07-4.34-1.21-8.78-1.37-13.29-1.02-3.6,.28-6.62,1.44-9,3.74-.7,.67-1.57,1.22-2.36,1.82-.18-.09-.37-.18-.55-.26Z" />
      <path d="M558.61,336.32c-1.09,1.86-2.15,2.2-3.67,1-1.22-.97-2.38-2.11-3.12-3.35-1.71-2.89-.26-6.03,3.3-7.7-2.31,6.13-1.37,5.79,3.49,10.06Z" />
      <path d="M432.46,332.17c2.71-5.88,5.96-11.13,12.07-14.98,5.2-3.27,10.96-4.29,17.28-4,9.07,.41,17.69,2.49,26.09,5.12,7.33,2.29,14.49,4.97,21.72,7.49,.55,.19,1.09,.44,1.59,.71,1.83,.99,2.44,2.19,1.59,3.84-.73,1.42-1.79,2.72-2.8,4.22-1.22-.36-2.18-.61-3.11-.93-9.09-3.17-18.45-5.62-28.13-7.23-8.04-1.34-16.09-1.35-24.21-.48-7.58,.81-14.58,2.84-21.11,6.07-.19,.09-.45,.08-.99,.16Z" />
      <path d="M568.25,332.21c24.5,9.55,50.22,12.99,77.06,12.21-.56,.2-1.09,.46-1.67,.59-7.44,1.68-15.04,2.54-22.71,3.06-7.85,.53-15.71,.63-23.56,.26-8.28-.4-16.48-1.24-24.46-3.32-3.33-.87-5.04-2.5-5.56-5.24-.5-2.58-.23-5.06,.9-7.56Z" />
    </g>
  );
};

export default Confused;
