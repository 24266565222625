import { Grid, Skeleton } from "@mui/material";
import { cardVeryLight } from "../../utils/themeContstants";
import ScrollableContainer from "../custom/ScrollableContainer";

const MobileStreamItemGlimmer = ({ numItems }) => {
  const array = Array.apply(null, { length: numItems }).map(
    Number.call,
    Number
  );

  const styles = {
    container: {
      width: 226,
      height: 127,
    },
    width: {
      width: "100%",
    },
  };

  return (
    <Grid item sx={styles.width}>
      <ScrollableContainer gap={1}>
        {array.map((_, i) => (
          <Grid item key={i}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Skeleton
                  variant="rectangular"
                  animation="pulse"
                  sx={{
                    bgcolor: cardVeryLight,
                    borderRadius: 1,
                    width: 273,
                    height: 153,
                  }}
                />
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <Grid item>
                    <Skeleton
                      variant="circular"
                      animation="pulse"
                      sx={{ bgcolor: cardVeryLight, height: 45, width: 45 }}
                    />
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Skeleton
                          variant="text"
                          animation="pulse"
                          sx={{
                            borderRadius: 1,
                            bgcolor: cardVeryLight,
                            width: 150,
                          }}
                        />
                      </Grid>

                      <Grid item>
                        <Skeleton
                          variant="text"
                          animation="pulse"
                          sx={{
                            borderRadius: 1,
                            bgcolor: cardVeryLight,
                            width: 75,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </ScrollableContainer>
    </Grid>
  );
};

export default MobileStreamItemGlimmer;
