export const xp_gain_data = [
    { "Monetary Value of Match": "$0.50", "XP Gain per Match": 100 },
    { "Monetary Value of Match": "$1.00", "XP Gain per Match": 120 },
    { "Monetary Value of Match": "$1.50", "XP Gain per Match": 140 },
    { "Monetary Value of Match": "$2.00", "XP Gain per Match": 160 },
    { "Monetary Value of Match": "$2.50", "XP Gain per Match": 180 },
    { "Monetary Value of Match": "$3.00", "XP Gain per Match": 200 },
    { "Monetary Value of Match": "$3.50", "XP Gain per Match": 220 },
    { "Monetary Value of Match": "$4.00", "XP Gain per Match": 240 },
    { "Monetary Value of Match": "$4.50", "XP Gain per Match": 260 },
    { "Monetary Value of Match": "$5.00", "XP Gain per Match": 300 }
  ];
  