const CartierShades = () => {
  const styles = {
    one: {
      fill: "url(#cartier-gradient-2)",
    },
    two: {
      fill: "#a38d64",
    },
    three: {
      fill: "#ebb678",
    },
    four: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    five: {
      fill: "#e3e3d6",
    },
    six: {
      fill: "#fef7f4",
    },
    seven: {
      fill: "url(#cartier-gradient)",
    },
    eight: {
      fill: "#d6980d",
    },
  };
  return (
    <g>
      <defs>
        <linearGradient
          id="cartier-gradient"
          x1="462.5"
          y1="355.69"
          x2="467.87"
          y2="453.97"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#ebb678" />
          <stop offset="1" stop-color="#fff" />
        </linearGradient>
        <linearGradient
          id="cartier-gradient-2"
          x1="603.3"
          y1="348.72"
          x2="608.67"
          y2="447"
          xlinkHref="#cartier-gradient"
        />
      </defs>
      <g>
        <g>
          <path
            style={styles.eight}
            d="m665.78,353.48v3.69l-6.5,1.01-.2.03c-.2.03-.41.05-.61.05-1.71,0-3.26-1.12-3.76-2.8-.18-.6.13-1.23.71-1.46l3.86-1.49,3.26-1.26c1.56-.6,3.24.55,3.24,2.23Z"
          />
          <path
            style={styles.three}
            d="m659.28,352.51l-3.86,1.49c-.58.23-.89.86-.71,1.46.5,1.68,2.05,2.8,3.76,2.8.2,0,.41-.02.61-.05l.2-.03c-.04,1.55-.12,3.14-.25,4.75-.64,8.33-2.26,15.52-4.03,21.32-.76,2.49-2.52,4.54-4.84,5.73-6.69,3.44-14.78,6.77-24.19,9.15-27.84,7.02-52.09,1.83-66.01-2.61-2.87-.92-5.13-3.13-6.12-5.98-1.98-5.67-3.88-12.89-4.7-21.38h2.44c.95,0,1.72-.77,1.72-1.72s-.77-1.72-1.72-1.72h-2.7c-.16-2.72-.19-5.34-.12-7.83,3.48.5,6.67,1.16,9.53,1.87,1.4.34,2.79-.57,3.03-1.99l.5-2.92c.21-1.23-.54-2.42-1.73-2.78-2.89-.89-6.5-1.8-10.71-2.45.29-2.46.67-4.75,1.08-6.84.71-3.6,3.38-6.49,6.93-7.44,12.94-3.46,29.03-6.59,47.64-7.63,16.97-.94,32,.07,44.45,1.75,4.09.56,7.36,3.66,8.12,7.71.84,4.43,1.5,9.59,1.68,15.34Z"
          />
          <path
            style={styles.five}
            d="m521.26,357.77c-.9.12-1.78.25-2.64.4-3.17.52-6.05,1.15-8.6,1.81-1.03.26-2.11-.17-2.69-1.07l-.24-.38c-.82-1.29-.29-2.99,1.1-3.6,2.75-1.19,6.06-2.42,9.87-3.48,3.79-1.04,8.09-1.91,12.81-2.36,6.94-.66,13.2-.28,18.51.53,4.21.65,7.82,1.56,10.71,2.45,1.19.36,1.94,1.55,1.73,2.78l-.5,2.92c-.24,1.42-1.63,2.33-3.03,1.99-2.86-.71-6.05-1.37-9.53-1.87-.97-.15-1.98-.28-3-.4-1.11-.13-2.25-.24-3.41-.34-1.36-.1-2.75-.18-4.18-.24-6.2-.22-11.88.16-16.91.86Zm39.86-1.79v-.52c0-1.35-1.11-2.46-2.46-2.46h-.52c-1.35,0-2.46,1.11-2.46,2.46v.52c0,1.35,1.11,2.45,2.46,2.45h.52c1.35,0,2.46-1.1,2.46-2.45Zm-6.97-1.28v-.51c0-1.35-1.1-2.46-2.45-2.46h-.52c-1.35,0-2.46,1.11-2.46,2.46v.51c0,1.36,1.11,2.46,2.46,2.46h.52c1.35,0,2.45-1.1,2.45-2.46Zm-6.67-1v-.51c0-1.35-1.11-2.46-2.46-2.46h-.51c-1.35,0-2.46,1.11-2.46,2.46v.51c0,1.36,1.11,2.46,2.46,2.46h.51c1.35,0,2.46-1.1,2.46-2.46Zm-6.18,0v-.51c0-1.35-1.11-2.46-2.46-2.46h-.52c-1.35,0-2.45,1.11-2.45,2.46v.51c0,1.36,1.1,2.46,2.45,2.46h.52c1.35,0,2.46-1.1,2.46-2.46Zm-6.09,0v-.51c0-1.35-1.11-2.46-2.46-2.46h-.51c-1.35,0-2.46,1.11-2.46,2.46v.51c0,1.36,1.11,2.46,2.46,2.46h.51c1.35,0,2.46-1.1,2.46-2.46Zm-5.89,0v-.51c0-1.35-1.1-2.46-2.46-2.46h-.51c-1.35,0-2.46,1.11-2.46,2.46v.19s0-.05-.02-.08l-.12-.32c-.43-1.24-1.8-1.89-3.03-1.46l-.59.21c-1.24.44-1.89,1.8-1.46,3.03l.12.33c.43,1.23,1.8,1.88,3.03,1.45l.59-.21c.89-.31,1.47-1.1,1.57-1.98.28,1.04,1.24,1.81,2.37,1.81h.51c1.36,0,2.46-1.1,2.46-2.46Zm-11.45,1.85c0-.24-.04-.48-.12-.71-.4-1.13-1.65-1.72-2.78-1.33l-.89.32c-.89.31-1.45,1.15-1.45,2.05,0,.24.04.48.12.72.4,1.13,1.64,1.72,2.77,1.33l.9-.32c.89-.32,1.45-1.16,1.45-2.06Zm-5.25,1.45c0-.24-.04-.48-.12-.72-.39-1.12-1.64-1.72-2.77-1.33l-.89.32c-.89.32-1.45,1.16-1.45,2.05,0,.24.04.48.12.72.4,1.13,1.64,1.73,2.77,1.33l.9-.32c.88-.31,1.44-1.15,1.44-2.05Z"
          />
          <rect
            style={styles.six}
            x="555.68"
            y="353"
            width="5.44"
            height="5.43"
            rx="2.46"
            ry="2.46"
          />
          <path
            style={styles.six}
            d="m554.15,354.19v.51c0,1.36-1.1,2.46-2.45,2.46h-.52c-1.35,0-2.46-1.1-2.46-2.46v-.51c0-1.35,1.11-2.46,2.46-2.46h.52c1.35,0,2.45,1.11,2.45,2.46Z"
          />
          <path
            style={styles.eight}
            d="m551.58,365.72c.95,0,1.72.77,1.72,1.72s-.77,1.72-1.72,1.72h-2.75c-.95,0-1.72-.77-1.72-1.72s.77-1.72,1.72-1.72h2.75Z"
          />
          <path
            style={styles.six}
            d="m547.48,353.19v.51c0,1.36-1.11,2.46-2.46,2.46h-.51c-1.35,0-2.46-1.1-2.46-2.46v-.51c0-1.35,1.11-2.46,2.46-2.46h.51c1.35,0,2.46,1.11,2.46,2.46Z"
          />
          <path
            style={styles.eight}
            d="m545.76,357.49l1.41,19.9c-.98,0-1.86-.61-2.21-1.52-.78-2.06-1.28-4.22-1.48-6.42l-1.13-12.29h0c1.16.09,2.3.2,3.41.33Z"
          />
          <path
            style={styles.six}
            d="m541.3,353.19v.51c0,1.36-1.11,2.46-2.46,2.46h-.52c-1.35,0-2.45-1.1-2.45-2.46v-.51c0-1.35,1.1-2.46,2.45-2.46h.52c1.35,0,2.46,1.11,2.46,2.46Z"
          />
          <path
            style={styles.six}
            d="m535.21,353.19v.51c0,1.36-1.11,2.46-2.46,2.46h-.51c-1.35,0-2.46-1.1-2.46-2.46v-.51c0-1.35,1.11-2.46,2.46-2.46h.51c1.35,0,2.46,1.11,2.46,2.46Z"
          />
          <path
            style={styles.six}
            d="m529.32,353.19v.51c0,1.36-1.1,2.46-2.46,2.46h-.51c-1.13,0-2.09-.77-2.37-1.81.04-.32.02-.65-.09-.97v-.19c0-1.35,1.11-2.46,2.46-2.46h.51c1.36,0,2.46,1.11,2.46,2.46Z"
          />
          <path
            style={styles.six}
            d="m523.89,353.7c0,.23.03.44.09.65-.1.88-.68,1.67-1.57,1.98l-.59.21c-1.23.43-2.6-.22-3.03-1.45l-.12-.33c-.43-1.23.22-2.59,1.46-3.03l.59-.21c1.23-.43,2.6.22,3.03,1.46l.12.32s.02.05.02.08v.32Z"
          />
          <path
            style={styles.eight}
            d="m521.26,357.77c.59,1.39,1.85,4.96,1.02,9.48-.84,4.63-3.38,7.56-4.41,8.64.24-1.77.43-3.62.58-5.52.33-4.32.35-8.4.17-12.2.86-.15,1.74-.28,2.64-.4Z"
          />
          <path
            style={styles.three}
            d="m518.62,358.17c.18,3.8.16,7.88-.17,12.2-.15,1.9-.34,3.75-.58,5.52-.81,6.02-2.08,11.32-3.45,15.8-.77,2.49-2.52,4.54-4.84,5.73-6.7,3.44-14.79,6.77-24.2,9.15-27.83,7.02-52.08,1.83-66-2.61-2.87-.92-5.13-3.13-6.13-5.98-2.04-5.83-3.99-13.32-4.76-22.13-.23-2.64-.34-5.18-.35-7.62,2.29-.09,4.75-.44,7.33-1.07.77-.19,1.3-.9,1.3-1.69v-3.59c0-.99-.83-1.78-1.82-1.73-2.39.11-4.55.17-6.49.18.16-1.94.37-3.79.63-5.53l2.13-7.92v-.03c1.28-1.97,3.25-3.44,5.59-4.07,12.93-3.46,29.02-6.59,47.64-7.62,16.96-.95,31.99.06,44.45,1.75,4.08.55,7.36,3.65,8.12,7.7.4,2.12.75,4.4,1.04,6.84-3.81,1.06-7.12,2.29-9.87,3.48-1.39.61-1.92,2.31-1.1,3.6l.24.38c.58.9,1.66,1.33,2.69,1.07,2.55-.66,5.43-1.29,8.6-1.81Zm-.2,10.84c0-.95-.78-1.73-1.72-1.73h-1.65c-.95,0-1.72.78-1.72,1.73s.77,1.72,1.72,1.72h1.65c.94,0,1.72-.78,1.72-1.72Z"
          />
          <path
            style={styles.six}
            d="m517.75,354.84c.08.23.12.47.12.71,0,.9-.56,1.74-1.45,2.06l-.9.32c-1.13.39-2.37-.2-2.77-1.33-.08-.24-.12-.48-.12-.72,0-.9.56-1.74,1.45-2.05l.89-.32c1.13-.39,2.38.2,2.78,1.33Z"
          />
          <path
            style={styles.eight}
            d="m516.7,367.28c.94,0,1.72.78,1.72,1.73s-.78,1.72-1.72,1.72h-1.65c-.95,0-1.72-.78-1.72-1.72s.77-1.73,1.72-1.73h1.65Z"
          />
          <path
            style={styles.six}
            d="m512.5,356.28c.08.24.12.48.12.72,0,.9-.56,1.74-1.44,2.05l-.9.32c-1.13.4-2.37-.2-2.77-1.33-.08-.24-.12-.48-.12-.72,0-.89.56-1.73,1.45-2.05l.89-.32c1.13-.39,2.38.21,2.77,1.33Z"
          />
          <path
            style={styles.eight}
            d="m416.77,361.88v3.59c0,.79-.53,1.5-1.3,1.69-2.58.63-5.04.98-7.33,1.07-8.55.38-14.74-2.65-16.12-6.78-.78-2.34.03-4.86,1.02-6.8.67-1.31,2.56-1.26,3.15.1.09.21.13.43.13.65,0,.35-.1.7-.29,1.01-.29.48-.38.92-.29,1.32.42,1.77,4.66,2.63,12.72,2.6,1.94,0,4.1-.07,6.49-.18.99-.05,1.82.74,1.82,1.73Z"
          />
          <path
            style={styles.eight}
            d="m411.23,346.85v.03c-.66,1-1.12,2.12-1.36,3.34-.28,1.44-.55,2.96-.78,4.58l-12.5.87c-.13-.31-.27-.61-.4-.92-.83-1.9-1.65-3.82-2.47-5.73.03-.13.09-.34.2-.61.21-.55.63-1.33,1.43-2,1.24-1.02,2.59-1.1,3-1.11l1.76,3.72c1.04-.67,2.76-1.62,5.01-2.12.88-.2,1.85-.34,2.88-.36,1.22-.02,2.31.12,3.23.31Z"
          />
          <path
            style={styles.two}
            d="m405.12,346.9c-2.25.5-3.97,1.45-5.01,2.12l-1.76-3.72c-.41.01-1.76.09-3,1.11-.8.67-1.22,1.45-1.43,2l-.2-.08c-3.89-.61-7.8-1.25-11.74-1.92-1.95-.33-3.9-.66-5.83-1,.18,1.53.29,4.34-.91,7.5-.72,1.88-1.68,3.3-2.46,4.26-.59.49-1.53,1.09-2.74,1.18-1.52.1-2.45-.7-4.43-1.5-.99-.41-2.47-.9-4.41-1.18-.11-.64-1.33-8.4,3.89-12.84,1.6-1.37,3.35-2.03,4.69-2.35,3.96-.49,9.05-.73,14.87,0,8.96,1.12,15.95,4.06,20.47,6.42Z"
          />
        </g>
        <g>
          <path
            style={styles.four}
            d="m549.38,349.62c.29-2.46.67-4.75,1.08-6.84.71-3.6,3.38-6.49,6.93-7.44,12.94-3.46,29.03-6.59,47.64-7.63,16.97-.94,32,.07,44.45,1.75,4.09.56,7.36,3.66,8.12,7.71.84,4.43,1.5,9.59,1.68,15.34"
          />
          <path
            style={styles.four}
            d="m549.14,369.16c.82,8.49,2.72,15.71,4.7,21.38.99,2.85,3.25,5.06,6.12,5.98,13.92,4.44,38.17,9.63,66.01,2.61,9.41-2.38,17.5-5.71,24.19-9.15,2.32-1.19,4.08-3.24,4.84-5.73,1.77-5.8,3.39-12.99,4.03-21.32.13-1.61.21-3.2.25-4.75"
          />
          <path
            style={styles.four}
            d="m548.88,365.72c-.16-2.72-.19-5.34-.12-7.83"
          />
          <path
            style={styles.four}
            d="m408.14,368.23c.01,2.44.12,4.98.35,7.62.77,8.81,2.72,16.3,4.76,22.13,1,2.85,3.26,5.06,6.13,5.98,13.92,4.44,38.17,9.63,66,2.61,9.41-2.38,17.5-5.71,24.2-9.15,2.32-1.19,4.07-3.24,4.84-5.73,1.37-4.48,2.64-9.78,3.45-15.8.24-1.77.43-3.62.58-5.52.33-4.32.35-8.4.17-12.2"
          />
          <path
            style={styles.four}
            d="m411.23,346.85c1.27-1.97,3.24-3.44,5.58-4.07,12.93-3.46,29.02-6.59,47.64-7.62,16.96-.95,31.99.06,44.45,1.75,4.08.55,7.36,3.65,8.12,7.7.4,2.12.75,4.4,1.04,6.84"
          />
          <path
            style={styles.four}
            d="m408.46,360.33c.16-1.94.37-3.79.63-5.53.23-1.62.5-3.14.78-4.58.24-1.22.7-2.34,1.35-3.34"
          />
          <path
            style={styles.four}
            d="m518.62,358.17c-3.17.52-6.05,1.15-8.6,1.81-1.03.26-2.11-.17-2.69-1.07l-.24-.38c-.82-1.29-.29-2.99,1.1-3.6,2.75-1.19,6.06-2.42,9.87-3.48,3.79-1.04,8.09-1.91,12.81-2.36,6.94-.66,13.2-.28,18.51.53,4.21.65,7.82,1.56,10.71,2.45,1.19.36,1.94,1.55,1.73,2.78l-.5,2.92c-.24,1.42-1.63,2.33-3.03,1.99-2.86-.71-6.05-1.37-9.53-1.87-.97-.15-1.98-.28-3-.4-1.11-.13-2.25-.24-3.41-.34-1.36-.1-2.75-.18-4.18-.24-6.2-.22-11.88.16-16.91.86-.9.12-1.78.25-2.64.4Z"
          />
          <path
            style={styles.four}
            d="m405.12,346.9c-2.25.5-3.97,1.45-5.01,2.12l-1.76-3.72c-.41.01-1.76.09-3,1.11-.8.67-1.22,1.45-1.43,2-.11.27-.17.48-.2.61.82,1.91,1.64,3.83,2.47,5.73.13.31.27.61.4.92l12.5-.87,2.13-7.92v-.03c-.91-.19-2-.33-3.22-.31-1.03.02-2,.16-2.88.36Z"
          />
          <path
            style={styles.four}
            d="m659.28,358.18l6.5-1.01v-3.69c0-1.68-1.68-2.83-3.24-2.23l-3.26,1.26-3.86,1.49c-.58.23-.89.86-.71,1.46.5,1.68,2.05,2.8,3.76,2.8.2,0,.41-.02.61-.05l.2-.03Z"
          />
          <path
            style={styles.four}
            d="m408.46,360.33c1.94,0,4.1-.07,6.49-.18.99-.05,1.82.74,1.82,1.73v3.59c0,.79-.53,1.5-1.3,1.69-2.58.63-5.04.98-7.33,1.07-8.55.38-14.74-2.65-16.12-6.78-.78-2.34.03-4.86,1.02-6.8.67-1.31,2.56-1.26,3.15.1.09.21.13.43.13.65,0,.35-.1.7-.29,1.01-.29.48-.38.92-.29,1.32.42,1.77,4.66,2.63,12.72,2.6Z"
          />
          <path
            style={styles.four}
            d="m405.12,346.9c-4.52-2.36-11.51-5.3-20.47-6.42-5.82-.73-10.91-.49-14.87,0-1.34.32-3.09.98-4.69,2.35-5.22,4.44-4,12.2-3.89,12.84,1.94.28,3.42.77,4.41,1.18,1.98.8,2.91,1.6,4.43,1.5,1.21-.09,2.15-.69,2.74-1.18.78-.96,1.74-2.38,2.46-4.26,1.2-3.16,1.09-5.97.91-7.5,1.93.34,3.88.67,5.83,1,3.94.67,7.85,1.31,11.74,1.92"
          />
          <path
            style={styles.four}
            d="m516.7,370.73h-1.65c-.95,0-1.72-.78-1.72-1.72s.77-1.73,1.72-1.73h1.65c.94,0,1.72.78,1.72,1.73s-.78,1.72-1.72,1.72Z"
          />
          <path
            style={styles.four}
            d="m548.88,365.72h2.7c.95,0,1.72.77,1.72,1.72s-.77,1.72-1.72,1.72h-2.75c-.95,0-1.72-.77-1.72-1.72s.77-1.72,1.72-1.72h.05Z"
          />
          <path
            style={styles.four}
            d="m521.26,357.76h0c.59,1.4,1.85,4.97,1.02,9.49-.84,4.63-3.38,7.56-4.41,8.64"
          />
          <path
            style={styles.four}
            d="m542.35,357.16l1.13,12.29c.2,2.2.7,4.36,1.48,6.42.35.91,1.23,1.52,2.21,1.52l-1.41-19.9-.02-.33"
          />
          <path
            style={styles.four}
            d="m511.18,359.05l-.9.32c-1.13.4-2.37-.2-2.77-1.33-.08-.24-.12-.48-.12-.72,0-.89.56-1.73,1.45-2.05l.89-.32c1.13-.39,2.38.21,2.77,1.33.08.24.12.48.12.72,0,.9-.56,1.74-1.44,2.05Z"
          />
          <path
            style={styles.four}
            d="m516.42,357.61l-.9.32c-1.13.39-2.37-.2-2.77-1.33-.08-.24-.12-.48-.12-.72,0-.9.56-1.74,1.45-2.05l.89-.32c1.13-.39,2.38.2,2.78,1.33.08.23.12.47.12.71,0,.9-.56,1.74-1.45,2.06Z"
          />
          <path
            style={styles.four}
            d="m523.98,354.35c-.1.88-.68,1.67-1.57,1.98l-.59.21c-1.23.43-2.6-.22-3.03-1.45l-.12-.33c-.43-1.23.22-2.59,1.46-3.03l.59-.21c1.23-.43,2.6.22,3.03,1.46l.12.32s.02.05.02.08c.11.32.13.65.09.97Z"
          />
          <path
            style={styles.four}
            d="m523.89,353.38v-.19c0-1.35,1.11-2.46,2.46-2.46h.51c1.36,0,2.46,1.11,2.46,2.46v.51c0,1.36-1.1,2.46-2.46,2.46h-.51c-1.13,0-2.09-.77-2.37-1.81-.06-.21-.09-.42-.09-.65v-.32Z"
          />
          <path
            style={styles.four}
            d="m532.75,356.16h-.51c-1.35,0-2.46-1.1-2.46-2.46v-.51c0-1.35,1.11-2.46,2.46-2.46h.51c1.35,0,2.46,1.11,2.46,2.46v.51c0,1.36-1.11,2.46-2.46,2.46Z"
          />
          <path
            style={styles.four}
            d="m538.84,356.16h-.52c-1.35,0-2.45-1.1-2.45-2.46v-.51c0-1.35,1.1-2.46,2.45-2.46h.52c1.35,0,2.46,1.11,2.46,2.46v.51c0,1.36-1.11,2.46-2.46,2.46Z"
          />
          <path
            style={styles.four}
            d="m545.02,356.16h-.51c-1.35,0-2.46-1.1-2.46-2.46v-.51c0-1.35,1.11-2.46,2.46-2.46h.51c1.35,0,2.46,1.11,2.46,2.46v.51c0,1.36-1.11,2.46-2.46,2.46Z"
          />
          <path
            style={styles.four}
            d="m551.7,357.16h-.52c-1.35,0-2.46-1.1-2.46-2.46v-.51c0-1.35,1.11-2.46,2.46-2.46h.52c1.35,0,2.45,1.11,2.45,2.46v.51c0,1.36-1.1,2.46-2.45,2.46Z"
          />
          <rect
            style={styles.four}
            x="555.68"
            y="353"
            width="5.44"
            height="5.43"
            rx="2.46"
            ry="2.46"
          />
        </g>
      </g>
      <path
        style={styles.seven}
        d="m408.24,372.05l105.77-1.41c.23.2.88.72,1.86.78,1.47.1,2.45-.91,2.58-1.05.02.85.03,2.1-.04,3.6-.09,1.93-.59,12.41-5.09,19.17-1.23,1.85-4.11,5.46-16.14,9.88-21.29,7.82-40.12,6.72-49.79,6.07-6.92-.47-12.15-1.36-17.22-2.74-6.68-1.82-12.02-4.19-15.78-6.13-1.47-3.66-2.93-8.03-4.08-13.04-1.29-5.67-1.86-10.81-2.07-15.12Z"
      />
      <path
        style={styles.one}
        d="m549.1,365.07l105.77-1.41c.23.2.88.72,1.86.78,1.47.1,2.45-.91,2.58-1.05.02.85.03,2.1-.04,3.6-.09,1.93-.59,12.41-5.09,19.17-1.23,1.85-4.11,5.46-16.14,9.88-21.29,7.82-40.12,6.72-49.79,6.07-6.92-.47-12.15-1.36-17.22-2.74-6.68-1.82-12.02-4.19-15.78-6.13-.53-1.69-2.4-7.68-4.08-13.04-1.51-4.82-2.59-8.27-.86-10.34,1.14-1.37,3-1.52,3-2.42,0-.99-2.22-1.81-4.21-2.37Z"
      />
      <path
        style={styles.four}
        d="m549.38,349.62c.29-2.46.67-4.75,1.08-6.84.71-3.6,3.38-6.49,6.93-7.44,12.94-3.46,29.03-6.59,47.64-7.63,16.97-.94,32,.07,44.45,1.75,4.09.56,7.36,3.66,8.12,7.71.84,4.43,1.5,9.59,1.68,15.34"
      />
      <path
        style={styles.four}
        d="m549.14,369.16c.82,8.49,2.72,15.71,4.7,21.38.99,2.85,3.25,5.06,6.12,5.98,13.92,4.44,38.17,9.63,66.01,2.61,9.41-2.38,17.5-5.71,24.19-9.15,2.32-1.19,4.08-3.24,4.84-5.73,1.77-5.8,3.39-12.99,4.03-21.32.13-1.61.21-3.2.25-4.75"
      />
      <path
        style={styles.four}
        d="m548.88,365.72c-.16-2.72-.19-5.34-.12-7.83"
      />
      <path
        style={styles.four}
        d="m408.14,368.23c.01,2.44.12,4.98.35,7.62.77,8.81,2.72,16.3,4.76,22.13,1,2.85,3.26,5.06,6.13,5.98,13.92,4.44,38.17,9.63,66,2.61,9.41-2.38,17.5-5.71,24.2-9.15,2.32-1.19,4.07-3.24,4.84-5.73,1.37-4.48,2.64-9.78,3.45-15.8.24-1.77.43-3.62.58-5.52.33-4.32.35-8.4.17-12.2"
      />
      <path
        style={styles.four}
        d="m408.46,360.33c.16-1.94.37-3.79.63-5.53.23-1.62.5-3.14.78-4.58.24-1.22.7-2.35,1.36-3.35v-.02c1.27-1.97,3.24-3.44,5.58-4.07,12.93-3.46,29.02-6.59,47.64-7.62,16.96-.95,31.99.06,44.45,1.75,4.08.55,7.36,3.65,8.12,7.7.4,2.12.75,4.4,1.04,6.84"
      />
      <path
        style={styles.four}
        d="m507.33,358.91l-.24-.38c-.82-1.29-.3-2.99,1.1-3.6,5.49-2.38,13.21-4.94,22.67-5.85,12.44-1.19,22.68.98,29.22,2.98,1.19.37,1.94,1.55,1.73,2.78l-.5,2.92c-.24,1.42-1.63,2.33-3.03,1.98-5.54-1.38-12.33-2.56-20.11-2.84-11.42-.42-21.07,1.23-28.15,3.06-1.03.27-2.11-.17-2.69-1.07Z"
      />
      <path
        style={styles.four}
        d="m400.11,349.02l-1.76-3.72c-.4,0-1.76.08-3,1.11-1.18.97-1.53,2.2-1.63,2.61.96,2.22,1.91,4.43,2.87,6.65l12.5-.88,2.14-7.95c-.92-.19-2.01-.33-3.23-.3-1.03.02-2,.15-2.88.35-2.25.51-3.97,1.46-5.01,2.13Z"
      />
      <path
        style={styles.four}
        d="m662.54,351.25l-7.12,2.75c-.58.22-.89.86-.71,1.45h0c.57,1.88,2.43,3.06,4.38,2.76l6.7-1.04v-3.7c0-1.68-1.68-2.83-3.24-2.22Z"
      />
      <path
        style={styles.four}
        d="m416.77,361.88v3.59c0,.79-.53,1.49-1.3,1.68-12.22,3-21.7-.47-23.45-5.71-.78-2.33.03-4.85,1.02-6.79.67-1.32,2.57-1.26,3.15.1h0c.23.54.15,1.15-.15,1.66s-.39.93-.29,1.32c.52,2.19,6.92,3,19.21,2.42.99-.05,1.82.75,1.82,1.74Z"
      />
      <path
        style={styles.four}
        d="m405.12,346.9c-4.52-2.36-11.51-5.3-20.47-6.42-5.83-.73-10.91-.49-14.87,0-1.34.33-3.09.98-4.7,2.35-5.21,4.45-3.99,12.21-3.88,12.85,1.94.28,3.42.77,4.41,1.17,1.98.81,2.92,1.61,4.43,1.5,1.2-.08,2.14-.69,2.74-1.17.78-.96,1.74-2.38,2.46-4.26,1.2-3.16,1.09-5.97.91-7.5,1.94.34,3.88.67,5.83,1,3.94.66,7.85,1.3,11.74,1.92"
      />
      <rect
        style={styles.four}
        x="513.33"
        y="367.28"
        width="5.09"
        height="3.45"
        rx="1.72"
        ry="1.72"
      />
      <rect
        style={styles.four}
        x="547.11"
        y="365.72"
        width="6.2"
        height="3.45"
        rx="1.72"
        ry="1.72"
      />
      <path
        style={styles.four}
        d="m521.26,357.76c.57,1.38,1.85,4.96,1.02,9.49-.84,4.63-3.38,7.56-4.42,8.64"
      />
      <path
        style={styles.four}
        d="m542.35,357.16l1.13,12.3c.2,2.2.7,4.35,1.48,6.42h0c.35.92,1.23,1.52,2.21,1.52h0l-1.43-20.24"
      />
      <rect
        style={styles.four}
        x="507.36"
        y="354.99"
        width="5.3"
        height="4.35"
        rx="2.17"
        ry="2.17"
        transform="translate(-89.65 189.6) rotate(-19.39)"
      />
      <rect
        style={styles.four}
        x="512.6"
        y="353.55"
        width="5.3"
        height="4.35"
        rx="2.17"
        ry="2.17"
        transform="translate(-88.88 191.25) rotate(-19.39)"
      />
      <rect
        style={styles.four}
        x="518.58"
        y="351.48"
        width="5.38"
        height="5.1"
        rx="2.38"
        ry="2.38"
        transform="translate(-87.98 193.16) rotate(-19.39)"
      />
      <rect
        style={styles.four}
        x="523.89"
        y="350.73"
        width="5.43"
        height="5.43"
        rx="2.46"
        ry="2.46"
      />
      <rect
        style={styles.four}
        x="529.78"
        y="350.73"
        width="5.43"
        height="5.43"
        rx="2.46"
        ry="2.46"
      />
      <rect
        style={styles.four}
        x="535.87"
        y="350.73"
        width="5.43"
        height="5.43"
        rx="2.46"
        ry="2.46"
      />
      <rect
        style={styles.four}
        x="542.05"
        y="350.73"
        width="5.43"
        height="5.43"
        rx="2.46"
        ry="2.46"
      />
      <rect
        style={styles.four}
        x="548.72"
        y="351.73"
        width="5.43"
        height="5.43"
        rx="2.46"
        ry="2.46"
      />
      <rect
        style={styles.four}
        x="555.68"
        y="353"
        width="5.43"
        height="5.43"
        rx="2.46"
        ry="2.46"
      />
    </g>
  );
};

export default CartierShades;
