import { Grid, useMediaQuery } from "@mui/material";
import {
  gameModeOptions,
  killLeadOptions,
  killLeadOptionsFilter,
  platformOptions,
  regionOptions,
  teamSizeOptions,
} from "../../../utils/options";
import {
  card,
  cardDark,
  cardDarker,
  cardLight,
  container,
} from "../../../utils/themeContstants";
import CustomDropdown from "../../custom/CustomDropdown";
import ScrollableContainer from "../../custom/ScrollableContainer";

const FortniteMatchFilters = ({
  setPlatform,
  setRegion,
  setGameMode,
  setTeamSize,
  setRoundLead,
}) => {
  const isTablet = useMediaQuery("(max-width: 768px)");

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <Grid item sx={styles.width}>
      {isTablet && (
        <ScrollableContainer gap={1}>
          <Grid item>
            <CustomDropdown
              options={platformOptions}
              placeholder="Platform"
              onChange={(value) => setPlatform(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>

          <Grid item>
            <CustomDropdown
              options={regionOptions()}
              placeholder="Region"
              onChange={(value) => setRegion(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>

          <Grid item>
            <CustomDropdown
              options={gameModeOptions}
              placeholder="Game Mode"
              onChange={(value) => setGameMode(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>

          <Grid item>
            <CustomDropdown
              options={teamSizeOptions()}
              placeholder="Team Size"
              onChange={(value) => setTeamSize(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>
          <Grid item>
            <CustomDropdown
              options={killLeadOptionsFilter()}
              placeholder="Round Lead"
              onChange={(value) => setRoundLead(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>
        </ScrollableContainer>
      )}

      {!isTablet && (
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 1 }}
        >
          <Grid item>
            <CustomDropdown
              options={platformOptions}
              placeholder="Platform"
              onChange={(value) => setPlatform(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>

          <Grid item>
            <CustomDropdown
              options={regionOptions()}
              placeholder="Region"
              onChange={(value) => setRegion(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>

          <Grid item>
            <CustomDropdown
              options={gameModeOptions}
              placeholder="Game Mode"
              onChange={(value) => setGameMode(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>

          <Grid item>
            <CustomDropdown
              options={teamSizeOptions()}
              placeholder="Team Size"
              onChange={(value) => setTeamSize(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>
          <Grid item>
            <CustomDropdown
              options={killLeadOptionsFilter()}
              placeholder="Round Lead"
              onChange={(value) => setRoundLead(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={"transparent"}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default FortniteMatchFilters;
