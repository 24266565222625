const TearDrop = () => {
  return (
    <g>
      <path d="m450.14,376.67c-6.63,5.69-9.56,12.33-7.52,16.29.4.77,1.12,1.69,2.51,2.51.73-.48,1.73-1.27,2.51-2.51,1.38-2.19,1.25-4.44,1.25-6.27,0-2.41.24-5.78,1.25-10.02Z" />
      <path d="m440.12,389.2c-6.63,5.69-9.56,12.33-7.52,16.29.4.77,1.12,1.69,2.51,2.51.73-.48,1.73-1.27,2.51-2.51,1.38-2.19,1.25-4.44,1.25-6.27,0-2.41.24-5.78,1.25-10.02Z" />
    </g>
  );
};

export default TearDrop;
