const Peepers = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
  };

  return (
    <g>
      <path d="M462.78,381.08c2.44,1.33,4.81,2.79,7.33,3.95,5.77,2.65,11.76,3.94,18.02,1.93,5.49-1.76,9.36-5.5,12.13-10.46,.18-.33,.3-.9,.13-1.18-1.88-3.2-4.15-6.09-7.3-8.28,1.27,3.47,1.57,6.92,.23,10.39-1.33,3.43-3.67,5.92-7.07,7.38-5.08,2.18-10.83,.75-14.3-3.55-4.05-5.03-3.81-10.8,.95-17.63-.73,.25-1.18,.39-1.62,.57-4.55,1.83-8.3,4.79-11.58,8.36-.9,.97-1.69,1.06-2.74,.32-1.07-.75-1.13-1.44-.05-2.24,2.93-2.18,5.88-4.32,8.82-6.48,.22-.16,.45-.33,.67-.49-.05-.11-.11-.22-.16-.33-1.96,.87-3.92,1.74-5.94,2.65,1.02-2.62,11.13-6.77,18.03-7.37,9.9-.86,18.42,2.71,26.29,8.22-.04,.14-.08,.27-.12,.41-1.93-.54-3.87-1.07-5.8-1.61-.04,.07-.08,.14-.12,.21,.26,.24,.52,.47,.77,.72,2.83,2.72,5.68,5.42,8.47,8.19,1.06,1.06,.79,2.07-.65,2.46-1.05,.28-2.18,.2-3.27,.36-.45,.07-1.09,.24-1.28,.58-5.55,9.5-14.07,12.21-24.38,10.59-5.91-.93-10.96-3.85-15.46-7.65Zm25.38-7.61c-.02-1.56-1.29-2.89-2.79-2.9-1.55-.02-2.88,1.37-2.85,2.99,.02,1.55,1.32,2.9,2.8,2.92,1.54,.02,2.86-1.39,2.85-3Z" />
      <path d="M583.52,365.63c-1.91,.54-3.81,1.08-5.8,1.64,1.35-3.08,14.56-8.38,21.54-8.75,7.76-.41,20.75,3.76,22.87,7.52-2.13-.95-4.1-1.82-6.07-2.7-.05,.1-.11,.2-.16,.3,2.98,2.21,5.97,4.39,8.92,6.64,.55,.42,1.21,1.14,1.2,1.71-.01,.45-.92,1.13-1.53,1.25-.55,.11-1.39-.23-1.8-.66-3.34-3.45-6.99-6.44-11.47-8.3-.4-.17-.8-.32-1.21-.46-.12-.04-.26,0-.43,0,4.64,7.64,4.68,13.31,.15,18.14-3.83,4.09-9.71,5.01-14.79,2.33-5.84-3.08-7.68-8.55-5.69-17.47-.86,.72-1.63,1.21-2.19,1.87-1.64,1.94-3.17,3.96-4.78,5.93-.65,.8-.54,1.48-.05,2.34,5.1,8.89,14.1,12.71,24.05,10.18,3.76-.95,7.24-2.53,10.53-4.55,.88-.54,1.77-1.09,2.65-1.63,.11,.12,.22,.24,.32,.37-.3,.19-.62,.35-.9,.56-7.34,5.42-15.34,8.56-24.64,6.86-6.15-1.12-10.88-4.43-14.06-9.82-.62-1.05-1.2-1.54-2.44-1.41-.83,.09-1.73-.06-2.55-.29-1.52-.43-1.81-1.42-.69-2.53,2.82-2.8,5.72-5.52,8.59-8.27,.2-.19,.4-.38,.61-.56-.06-.08-.12-.16-.17-.24Zm10.6,7.85c-.01,1.62,1.32,3.01,2.86,2.99,1.48-.03,2.77-1.38,2.78-2.93,.01-1.62-1.32-3-2.87-2.97-1.5,.02-2.76,1.36-2.77,2.92Z" />
      <path
        style={styles.one}
        d="M488.16,373.47c.02,1.62-1.31,3.02-2.85,3-1.48-.02-2.78-1.37-2.8-2.92-.02-1.62,1.3-3.01,2.85-2.99,1.5,.02,2.77,1.34,2.79,2.9Z"
      />
      <path
        style={styles.one}
        d="M594.11,373.49c.01-1.56,1.28-2.89,2.77-2.92,1.55-.03,2.88,1.36,2.87,2.97-.01,1.55-1.3,2.91-2.78,2.93-1.54,.03-2.87-1.37-2.86-2.99Z"
      />
    </g>
  );
};

export default Peepers;
