import "../styles/Uzi.css";

const Uzi = () => {
  return (
    <g>
      <polygon
        class="uzi-1"
        points="521.78 327.85 536.07 314.43 550.61 326.73 550.63 348.71 537.16 359.28 522.98 347.99 521.78 327.85"
      />
      <polygon
        class="uzi-6"
        points="526.93 331.17 536.02 322.63 545.26 330.45 545.27 344.43 536.71 351.14 527.69 343.97 526.93 331.17"
      />
      <polygon
        class="uzi-5"
        points="522.98 347.99 527.69 343.97 536.71 351.14 537.16 359.28 522.98 347.99"
      />
      <polygon
        class="uzi-8"
        points="550.63 348.71 545.27 344.43 536.71 351.14 537.16 359.28 550.63 348.71"
      />
      <polygon
        class="uzi-7"
        points="526.93 331.17 521.78 327.85 522.98 347.99 527.69 343.97 526.93 331.17"
      />
      <polygon
        class="uzi-7"
        points="545.26 330.45 550.61 326.73 550.63 348.71 545.27 344.43 545.26 330.45"
      />
      <polygon
        class="uzi-2"
        points="536.02 322.63 536.07 314.43 521.78 327.85 526.93 331.17 536.02 322.63"
      />
      <polygon
        class="uzi-3"
        points="545.26 330.45 550.61 326.73 536.07 314.43 536.02 322.63 545.26 330.45"
      />
      <polygon
        class="uzi-4"
        points="527.69 343.97 542.06 327.74 545.26 330.45 530.96 346.57 527.69 343.97"
      />
    </g>
  );
};

export default Uzi;
