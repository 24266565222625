import { Grid, Skeleton, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { RiSwordLine } from "react-icons/ri";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { getMatches } from "../../api/matches";
import { TOKEN_PAGE_ID } from "../../sockets/socketConstants";
import useMatch from "../../sockets/useMatch";
import {
  SET_ERRORS,
  SET_JOIN_MATCH_ID,
  SET_SNIPE_MATCH_ID,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { MATCH_LIMIT } from "../../utils/limits";
import { cardVeryLight, emptyIcon } from "../../utils/themeContstants";
import usePagination from "../../utils/usePagination";
import Empty from "../custom/Empty";
import NotSelected from "../custom/NotSelected";
import MatchGlimmer from "../glimmers/MatchGlimmer";
import ClashMatchFilters from "./filters/ClashMatchFilters";
import FifaMatchFilters from "./filters/FifaMatchFilters";
import FortniteMatchFilters from "./filters/FortniteMatchFilters";
import WarzoneMatchFilters from "./filters/WarzoneMatchFilters";
import JoinMatch from "./JoinMatch";
import MatchItem from "./MatchItem";

const AvailableMatches = () => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const isTablet = useMediaQuery("(max-width: 768px)");
  const bottom = useRef(null);
  const { matchToAdd, matchToRemove } = useMatch(TOKEN_PAGE_ID);

  const [game] = useOutletContext();

  const [region, setRegion] = useState(null);
  const [gameMode, setGameMode] = useState(null);
  const [teamSize, setTeamSize] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [roundLead, setRoundLead] = useState(null);
  const [filters, setFilters] = useState({});

  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const [searchParams] = useSearchParams();
  const [joinOpen, setJoinOpen] = useState(false);
  const [privateMatchId, setPrivateMatchId] = useState(null);

  const handleGetMatches = (filtering = false) => {
    getMatches(
      filtering ? 0 : skip,
      region,
      gameMode,
      teamSize,
      platform,
      roundLead,
      null,
      game
    ).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        if (res?.matches?.length >= MATCH_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setMatches(
          filtering
            ? res?.matches
            : matches
            ? [...matches, ...res?.matches]
            : res?.matches
        );
      }
    });
  };

  usePagination(bottom, () => setSkip(matches?.length));

  useEffect(() => {
    if (game) {
      setLoading(true);
      setMatches(null);
      setSkip(0);
      setHasMore(false);

      setRegion(null);
      setTeamSize(null);
      setGameMode(null);
      setPlatform(null);
      setRoundLead(null);
      setFilters({});
    }
  }, [game]);

  useEffect(() => {
    if (store?.user) {
      const joinCode = searchParams.get("join");
      if (joinCode) {
        const privateMatch = searchParams.get("private");
        if (privateMatch) {
          setPrivateMatchId(joinCode);
          setJoinOpen(true);
          return;
        }

        if (store?.user?.match_info?.match_id) return;

        updateStore({ type: SET_JOIN_MATCH_ID, payload: joinCode });
      }
    }
  }, [searchParams, store?.user]);

  useEffect(() => {
    if (loading) {
      if (matches == null) {
        handleGetMatches();
        return;
      }
    }
  }, [loading, matches]);

  useEffect(() => {
    if (skip !== 0) {
      handleGetMatches();
    }
  }, [skip]);

  useEffect(() => {
    if (matches == null) return;

    setSkip(0);
    setLoading(true);
    handleGetMatches(true);
    let newFilters = {};

    if (region) {
      newFilters.region = region;
    } else {
      newFilters.region = null;
    }

    if (gameMode) {
      newFilters.game_match_type = gameMode;
    } else {
      newFilters.game_match_type = null;
    }

    if (teamSize) {
      newFilters.team_size = teamSize;
    } else {
      newFilters.team_size = null;
    }

    if (platform) {
      newFilters.platform = platform;
    } else {
      newFilters.platform = null;
    }
    if (roundLead) {
      newFilters.roundLead = roundLead;
    } else {
      newFilters.roundLead = null;
    }

    setFilters(newFilters);
  }, [region, gameMode, teamSize, platform, roundLead]);

  useEffect(() => {
    if (matches != null) {
      if (matchToRemove) {
        if (matchToRemove?.game !== game) return;

        const indexOfMatch = matches?.findIndex(
          (match) => match?._id === matchToRemove?._id
        );
        let newTokens = matches;

        if (newTokens?.length > 0 && indexOfMatch !== -1) {
          newTokens?.splice(indexOfMatch, 1);

          if (matchToRemove?._id === store?.joinMatchId) {
            updateStore({ type: SET_JOIN_MATCH_ID, payload: null });
          }

          if (matchToRemove?._id === store?.snipeMatchId) {
            updateStore({ type: SET_SNIPE_MATCH_ID, payload: null });
          }

          setMatches([...newTokens]);
        }
      }
    }
  }, [matchToRemove]);

  useEffect(() => {
    if (matches != null) {
      if (matchToAdd) {
        if (matchToAdd?.game !== game) return;

        let charMap = {};

        if (Object.keys(filters).length > 0) {
          Object.keys(filters)?.map((filter) => {
            const shouldCheck = filters[filter] != null;

            if (shouldCheck) {
              if (filter === "game_match_type") {
                if (
                  matchToAdd?.game_mode_options?.options?.includes(
                    filters?.game_match_type
                  )
                ) {
                  const index = matchToAdd?.game_mode_options?.options?.indexOf(
                    filters?.game_match_type
                  );
                  if (index !== -1) {
                    charMap[filter] = true;
                  } else {
                    charMap[filter] = false;
                  }
                } else {
                  charMap[filter] = false;
                }
              } else {
                if (matchToAdd[filter]?.length > 0) {
                  const index = matchToAdd[filter]?.indexOf(filters[filter]);
                  if (index !== -1) {
                    charMap[filter] = true;
                  } else {
                    charMap[filter] = false;
                  }
                } else {
                  if (matchToAdd[filter] === filters[filter]) {
                    charMap[filter] = true;
                  } else {
                    charMap[filter] = false;
                  }
                }
              }
            }
          });

          if (!Object.values(charMap)?.includes(false)) {
            setMatches([...matches, matchToAdd]);
            return;
          }

          return;
        }

        setMatches([...matches, matchToAdd]);
      }
    }
  }, [matchToAdd]);

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <>
      <JoinMatch
        open={joinOpen}
        onClose={() => setJoinOpen(false)}
        matchId={privateMatchId}
        match={null}
      />

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: isTablet ? 1 : 2 }}
        >
          {game === "fortnite" && (
            <FortniteMatchFilters
              setPlatform={setPlatform}
              setRegion={setRegion}
              setGameMode={setGameMode}
              setTeamSize={setTeamSize}
              setRoundLead={setRoundLead}
            />
          )}

          {game === "warzone" && (
            <WarzoneMatchFilters
              setPlatform={setPlatform}
              setRegion={setRegion}
              setGameMode={setGameMode}
              setTeamSize={setTeamSize}
              game={game}
            />
          )}

          {game === "clashroyale" && (
            <ClashMatchFilters
              game={game}
              setTeamSize={setTeamSize}
              setGameMode={setGameMode}
            />
          )}

          {game === "fifa" && (
            <FifaMatchFilters
              game={game}
              setTeamSize={setTeamSize}
              setGameMode={setGameMode}
            />
          )}

          {loading && <MatchGlimmer numItems={8} />}

          {!loading && matches?.length < 1 && (
            <Empty
              title="No matches"
              label="Try creating a match"
              icon={<RiSwordLine style={emptyIcon} />}
            />
          )}

          {!loading && matches?.length > 0 && (
            <Grid item sx={styles.width}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill,minmax(250px, 4fr))",
                  gap: 12,
                }}
              >
                {matches?.map((token, i) => (
                  <MatchItem match={token} key={i} />
                ))}

                {hasMore && (
                  <div style={{ width: "100%" }} ref={bottom}>
                    <Grid item sx={styles.width}>
                      <Skeleton
                        variant="rectangular"
                        animation="pulse"
                        sx={{
                          bgcolor: cardVeryLight,
                          minWidth: 250,
                          height: 300,
                          borderRadius: 2,
                        }}
                      />
                    </Grid>
                  </div>
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AvailableMatches;
