import { getColor } from "../../utils/getColor";
import Afro from "./types/Afro";
import AfroWithHeadband from "./types/AfroWithHeadband";
import AnimeKid from "./types/AnimeKid";
import Bald from "./types/Bald";
import Bangs from "./types/Bangs";
import Bob from "./types/Bob";
import Braids from "./types/Braids";
import Bunny from "./types/Bunny";
import Carti from "./types/Carti";
import Clown from "./types/Clown";
import CornRows from "./types/CornRows";
import CrewCut from "./types/CrewCut";
import DadHair from "./types/DadHair";
import Default from "./types/Default";
import Fade from "./types/Fade";
import FadeRows from "./types/FadeRows";
import Flow from "./types/Flow";
import Goku from "./types/Goku";
import HairWithBow from "./types/HairWithBow";
import HairWithHeadband from "./types/HairWithHeadband";
import HunterHunter from "./types/HunterHunter";
import JaMorant from "./types/JaMorant";
import Jett from "./types/Jett";
import Karen from "./types/Karen";
import Keef from "./types/Keef";
import KingVon from "./types/KingVon";
import Kodak from "./types/Kodak";
import MessyBuns from "./types/MessyBuns";
import Mohawk from "./types/Mohawk";
import PigTails from "./types/PigTails";
import PonyTail from "./types/PonyTail";
import PopSmoke from "./types/PopSmoke";
import RevengeFlow from "./types/RevengeFlow";
import RiRi from "./types/RiRi";
import SixNine from "./types/SixNine";
import SlaysDemons from "./types/SlaysDemons";
import Spice from "./types/Spice";
import Surfer from "./types/Surfer";
import Swirl from "./types/Swirl";
import Todoroki from "./types/Todoroki";
import Tucked from "./types/Tucked";
import Wavy from "./types/Wavy";

const Hair = ({ type, color }) => {
  switch (type) {
    case "crew_cut":
      return <CrewCut color={getColor(color)} />;
    case "hair_w_bow":
      return <HairWithBow color={getColor(color)} />;
    case "hair_w_headband":
      return <HairWithHeadband color={getColor(color)} />;
    case "bangs":
      return <Bangs color={getColor(color)} />;
    case "fade_swirl":
      return <Swirl color={getColor(color)} />;
    case "afro_w_headband":
      return <AfroWithHeadband color={getColor(color)} />;
    case "karen":
      return <Karen color={getColor(color)} />;
    case "fade":
      return <Fade color={getColor(color)} />;
    case "dad_hair":
      return <DadHair color={getColor(color)} />;
    case "pony_tail":
      return <PonyTail color={getColor(color)} />;
    case "afro":
      return <Afro color={getColor(color)} />;
    case "surfer":
      return <Surfer color={getColor(color)} />;
    case "mohawk":
      return <Mohawk color={getColor(color)} />;
    case "bald":
      return <Bald color={getColor(color)} />;
    case "default":
      return <Default color={getColor(color)} />;
    case "flow":
      return <Flow color={getColor(color)} />;
    case "braids":
      return <Braids color={getColor(color)} />;
    case "corn_rows":
      return <CornRows color={getColor(color)} />;
    case "fade_rows":
      return <FadeRows color={getColor(color)} />;
    case "tucked":
      return <Tucked color={getColor(color)} />;
    case "bunny":
      return <Bunny color={getColor(color)} />;
    case "jett":
      return <Jett />;
    case "bob":
      return <Bob color={getColor(color)} />;
    case "clown":
      return <Clown />;
    case "messy_buns":
      return <MessyBuns color={getColor(color)} />;
    case "pig_tails":
      return <PigTails color={getColor(color)} />;
    case "slays_demons":
      return <SlaysDemons color={getColor(color)} />;
    case "anime_kid":
      return <AnimeKid />;
    case "riri":
      return <RiRi color={getColor(color)} />;
    case "wavy":
      return <Wavy color={getColor(color)} />;
    case "revenge_flow":
      return <RevengeFlow color={getColor(color)} />;
    case "carti":
      return <Carti />;
    case "keef":
      return <Keef />;
    case "goku":
      return <Goku />;
    case "spice":
      return <Spice />;
    case "kodak":
      return <Kodak />;
    case "king_von":
      return <KingVon color={getColor(color)} />;
    case "pop_smoke":
      return <PopSmoke color={getColor(color)} />;
    case "morant":
      return <JaMorant color={getColor(color)} />;
    case "hunter_hunter":
      return <HunterHunter color={getColor(color)} />;
    case "todoroki":
      return <Todoroki color={getColor(color)} />;
    case "six_nine":
      return <SixNine />;
    default:
      return null;
  }
};

export default Hair;
