import { Grid } from "@mui/material";
import MatchTeamItem from "./MatchTeamItem";

const MatchPlayingState = ({ token }) => {
  const styles = {
    width: {
      width: "100%",
    },
  };

  const doesCreatingTeamMatch = () => {
    const ogTeam = token?.creating_team_obj;
    const currentTeam = token?.creating_team;

    for (let i = 0; i < ogTeam?.users?.length; i++) {
      const userIndex = currentTeam?.users?.findIndex(
        (currentUser) => currentUser?._id === ogTeam?.users[i]?._id
      );

      if (userIndex === -1) return false;
    }

    return true;
  };

  const doesJoiningTeamMatch = () => {
    const ogTeam = token?.joining_team_obj;
    const currentTeam = token?.joining_team;

    for (let i = 0; i < ogTeam?.users?.length; i++) {
      const userIndex = currentTeam?.users?.findIndex(
        (currentUser) => currentUser?._id === ogTeam?.users[i]?._id
      );

      if (userIndex === -1) return false;
    }

    return true;
  };

  return (
    <Grid item sx={styles.width}>
      <Grid
        container
        alignItems="stretch"
        justifyContent="start"
        gap={{ xs: 2 }}
      >
        <MatchTeamItem
          isCreatingTeam={true}
          team={
            doesCreatingTeamMatch()
              ? token?.creating_team
              : token?.creating_team_obj
          }
          token={token}
        />

        <MatchTeamItem
          isCreatingTeam={false}
          team={
            doesJoiningTeamMatch()
              ? token?.joining_team
              : token?.joining_team_obj
          }
          token={token}
        />
      </Grid>
    </Grid>
  );
};

export default MatchPlayingState;
