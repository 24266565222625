import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import { currencyFormatter, getNotiTimestamp } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import {
  card,
  cardDark,
  cardLight,
  green,
  modalShadow,
  normal,
  red,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from "../../utils/themeContstants";
import MarkWithdrawModal from "./MarkWithdrawModal";
import SecondaryButton from "../custom/SecondaryButton";
import { SET_SUCCESSES, StoreDispatch, StoreContext } from "../../store/Store";
import { AccountTypeEnum } from "../../utils/enums";

const PendingWithdrawalItem = ({
  withdrawal,
  setWithdrawals,
  withdrawals,
  showStatus,
  user = false,
}) => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const updateStore = useContext(StoreDispatch);

  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(withdrawal?.account_used);
    updateStore({
      type: SET_SUCCESSES,
      payload: "Email Copied",
    });
  };

  const styles = {
    completed: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 1,
      backgroundColor: green,
    },

    pending: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 1,
      backgroundColor: yellow,
    },

    cancelled: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 1,
      backgroundColor: red,
    },
    username: {
      fontSize: normal,
      fontWeight: 800,
      color: withdrawal?.premium ? "rgb(255, 255, 193)" : text,
      textShadow: withdrawal?.premium ? "rgb(255 93 0) 0px 0px 10px" : null,
    },

    container: {
      padding: 2,
      borderRadius: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
      flexGrow: 1,
      transition: transition,
      "&:hover": {
        cursor: withdrawal?.status === "pending" ? "pointer" : "default",
        transform: withdrawal?.status === "pending" ? "translateY(-5px)" : null,
        backgroundColor: withdrawal?.status === "pending" ? cardDark : card,
      },
    },
    label: {
      fontSize: small,
      fontWeight: 600,
      color: secondaryText,
    },
    value: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
    resultText: {
      fontSize: 12,
      color: text,
      fontWeight: 800,
      textTransform: "uppercase",
    },
    notBanned: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 1,
      backgroundColor: green,
    },
    banned: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 1,
      backgroundColor: red,
    },
    width: {
      width: "100%",
    },
  };

  return (
    <>
      <MarkWithdrawModal
        open={
          open &&
          store?.user?.account_type >= AccountTypeEnum.SENIOR_ADMIN &&
          !(
            withdrawal?.status === "completed" ||
            withdrawal?.status === "failed" ||
            withdrawal?.status === "canceled"
          )
        }
        onClose={() => setOpen(false)}
        setWithdrawals={setWithdrawals}
        withdrawals={withdrawals}
        withdraw={withdrawal}
      />

      <Grid item sx={styles.width}>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 2 }}
          wrap={isDesktop ? "nowrap" : "wrap"}
        >
          <Grid
            item
            sx={styles.container}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => setOpen(true)}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              gap={{ xs: 2 }}
              sx={{
                backgroundColor:
                  withdrawal?.status === "pending"
                    ? hovered
                      ? card
                      : cardLight
                    : cardLight,
                transition: transition,
                padding: 1,
                borderRadius: 1,
              }}
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography sx={styles.label}>ACCOUNT</Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.value}>
                      {withdrawal?.account_used}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {isDesktop && (
                <>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={styles.label}>USERNAME</Typography>
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.username}>
                          {withdrawal?.username}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography sx={styles.label}>AMOUNT</Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.value}>
                      {currencyFormatter().format(withdrawal?.amount)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sx={
                  showStatus
                    ? withdrawal?.status === "completed"
                      ? styles.completed
                      : withdrawal?.status === "pending"
                      ? styles.pending
                      : styles.cancelled
                    : withdrawal?.banned
                    ? styles.banned
                    : styles.notBanned
                }
              >
                <Typography sx={styles.resultText}>
                  {showStatus
                    ? withdrawal?.status
                      ? withdrawal.status.toUpperCase()
                      : "N/A"
                    : withdrawal?.banned
                    ? "BANNED"
                    : "NOT BANNED"}
                </Typography>
              </Grid>

              {isDesktop && (
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                  >
                    <Grid item alignSelf="end">
                      <Typography sx={{ ...styles.label, textAlign: "right" }}>
                        DATE
                      </Typography>
                    </Grid>
                    {withdrawal?.time_ago > 2.5 && (
                      <Grid item>
                        <Typography
                          sx={{
                            ...styles.value,
                            fontWeight: 500,
                            color: red,
                          }}
                        >
                          {getNotiTimestamp(new Date(withdrawal?.date))}
                        </Typography>
                      </Grid>
                    )}
                    {withdrawal?.time_ago < 2.5 && withdrawal.time_ago > 1 && (
                      <Grid item>
                        <Typography
                          sx={{
                            ...styles.value,
                            fontWeight: 500,
                            color: yellow,
                          }}
                        >
                          {getNotiTimestamp(new Date(withdrawal?.date))}
                        </Typography>
                      </Grid>
                    )}
                    {withdrawal?.time_ago < 1 && (
                      <Grid item>
                        <Typography
                          sx={{
                            ...styles.value,
                            fontWeight: 500,
                            color: secondaryText,
                          }}
                        >
                          {getNotiTimestamp(new Date(withdrawal?.date))}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {!user && (
            <Grid item sx={{ minWidth: isDesktop ? null : "100%" }}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 1 }}
              >
                <Grid item sx={styles.width}>
                  <SecondaryButton
                    label="Copy Account"
                    onClick={handleCopy}
                    fullWidth
                  />
                </Grid>

                <Grid item sx={styles.width}>
                  <SecondaryButton
                    fullWidth
                    label="View user"
                    size="small"
                    onClick={() => {
                      navigate("/staff/search", {
                        state: {
                          user_id: withdrawal?.user_id,
                        },
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PendingWithdrawalItem;
