import { Button, CircularProgress, Grid } from "@mui/material";
import {
  green,
  normal,
  purple,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from "../../utils/themeContstants";

const PrimaryButton = ({
  label = null,
  onClick = () => {},
  loading = false,
  disabled = false,
  fullWidth = false,
  type = "yellow",
  size = "large",
}) => {
  const getBackgroundColor = () => {
    switch (type) {
      case "yellow":
        return yellow;
      case "green":
        return green;
      case "secondary":
        return "rgba(203, 215, 255, 0.075)";
      case "premium":
      case "purple":
        return "#6634D6";
      default:
        return yellow;
    }
  };

  const getBoxShadow = () => {
    switch (type) {
      case "yellow":
        return "rgb(255 176 25 / 40%) 0px 0px 10px, rgb(255 255 255 / 20%) 0px 1px 0px inset, rgb(0 0 0 / 15%) 0px -3px 0px inset, rgb(255 135 25) 0px 0px 12px inset";
      case "green":
        return "rgb(118 255 25 / 40%) 0px 0px 10px, rgb(255 255 255 / 20%) 0px 1px 0px inset, rgb(0 0 0 / 15%) 0px -3px 0px inset, rgb(59 198 14) 0px 0px 12px inset";

      case "secondary":
        return "rgb(0 0 0 / 40%) 0px 0px 7px, rgb(255 255 255 / 4%) 0px 1px 0px inset, rgb(0 0 0 / 35%) 0px -4px 0px inset";
      case "premium":
      case "purple":
        return "#6634D6 0px 0px 10px, rgb(255 255 255 / 20%) 0px 1px 0px inset, rgb(0 0 0 / 15%) 0px -3px 0px inset, #401d90 0px 0px 12px inset";
      default:
        return "rgb(255 176 25 / 40%) 0px 0px 10px, rgb(255 255 255 / 20%) 0px 1px 0px inset, rgb(0 0 0 / 15%) 0px -3px 0px inset, rgb(255 135 25) 0px 0px 12px inset";
    }
  };

  const getTextColor = () => {
    switch (type) {
      case "yellow":
      case "green":
        return "rgb(20, 23, 34)";
      case "secondary":
      case "premium":
      case "purple":
        return text;
      default:
        return "rgb(20, 23, 34)";
    }
  };

  const styles = {
    container: {
      opacity: disabled || loading ? 0.3 : 1,
      padding: 2,
      paddingLeft: size === "small" ? 1 : 2,
      paddingRight: size === "small" ? 1 : 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      whiteSpace: "nowrap",
      transition: transition,
      border: "none",
      letterSpacing: 0.5,
      textTransform: "uppercase",
      height: size === "small" ? 24 : size === "massive" ? 48 : 40,
      minWidth: fullWidth ? "100%" : 0,
      borderRadius: 1,
      fontWeight: 800,
      fontSize: size === "massive" ? normal : small,
      color: getTextColor(),
      backgroundColor: getBackgroundColor(),
      boxShadow: getBoxShadow(),
      "&:hover": {
        cursor: disabled || loading ? "not-allowed" : "pointer",
        backgroundColor: getBackgroundColor(),
        filter: disabled || loading ? null : "brightness(115%)",
        boxShadow: getBoxShadow(),
      },
    },
    disabled: {
      padding: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      whiteSpace: "nowrap",
      transition: transition,
      border: "none",
      letterSpacing: 0.5,
      textTransform: "uppercase",
      height: size === "small" ? 24 : size === "massive" ? 48 : 40,
      minWidth: fullWidth ? "100%" : 0,
      borderRadius: 1,
      fontWeight: 800,
      fontSize: size === "massive" ? normal : small,
      color: secondaryText,
      backgroundColor: "rgba(203, 215, 255, 0.075)",
      boxShadow:
        "rgb(0 0 0 / 40%) 0px 0px 10px, rgb(255 255 255 / 4%) 0px 1px 0px inset, rgb(0 0 0 / 35%) 0px -4px 0px inset",
      "&:hover": {
        cursor: "not-allowed",
        backgroundColor: "rgba(203, 215, 255, 0.075)",
        boxShadow:
          "rgb(0 0 0 / 40%) 0px 0px 7px, rgb(255 255 255 / 4%) 0px 1px 0px inset, rgb(0 0 0 / 35%) 0px -4px 0px inset",
      },
    },
  };

  return (
    <Button
      variant="contained"
      size="large"
      sx={styles.container}
      onClick={(e) => {
        if (disabled || loading) {
          return;
        }

        onClick(e);
      }}
    >
      {loading && (
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={20} sx={{ color: getTextColor() }} />
        </Grid>
      )}

      {!loading && <>{label}</>}
    </Button>
  );
};

export default PrimaryButton;
