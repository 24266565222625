import { Grid, Typography, useMediaQuery } from "@mui/material";
import {
  getNotiTimestamp,
  getPurchaseTitle,
  getReadableDateString,
  getTime,
  numFormatter,
} from "../../utils/helpers";
import {
  card,
  cardLight,
  cardVeryLight,
  modalShadow,
  normal,
  offWhite,
  red,
  secondaryText,
  small,
  text,
} from "../../utils/themeContstants";
import RivoxCoin from "../custom/RivoxCoin";

const PurchaseItem = ({ purchase }) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const styles = {
    container: {
      padding: 2,
      borderRadius: 2,
      border: `1px solid ${cardVeryLight}`,
      width: "100%",
      backgroundColor: card,
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
    },
    value: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={styles.label}>Amount</Typography>
            </Grid>

            <Grid item>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 0.5 }}
              >
                <RivoxCoin size={18} />
                <Grid item>
                  <Typography sx={{ ...styles.value, color: red }}>
                    -{numFormatter().format(purchase?.price)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {isDesktop && (
          <>
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
              >
                <Grid item>
                  <Typography sx={styles.label}>Item name</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.value}>
                    {getPurchaseTitle(purchase)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
              >
                <Grid item>
                  <Typography sx={styles.label}>Item category</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.value}>
                    {purchase?.piece_name
                      ? purchase?.piece_name
                      : purchase?.type}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
              >
                <Grid item>
                  <Typography sx={styles.label}>Sent as gift</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.value}>
                    {purchase?.is_gift ? "True" : "False"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item alignSelf="end">
              <Typography sx={{ ...styles.label, textAlign: "right" }}>
                Date
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.value}>
                {getReadableDateString(new Date(purchase?.timestamp)) +
                  " at " +
                  getTime(new Date(purchase?.timestamp))}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PurchaseItem;
