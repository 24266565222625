const Blouse = ({ color, skinColor }) => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "#fff",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      fill: color,
    },
    four: {
      fill: skinColor,
    },
    five: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    six: {
      fill: "#e8e8e8",
    },
    seven: {
      fill: color,
      filter: "brightness(115%)",
    },
  };

  return (
    <g>
      <path
        style={styles.four}
        d="M455.49,838.28c19.35,2.8,44.55,4.37,73.51,.72,16.22-2.05,30.64-5.35,43-9,6.67-14,13.33-28,20-42-46.67,3.33-93.33,6.67-140,10l3.49,40.28Z"
      />
      <path
        style={styles.four}
        d="M578.5,599.8c-3.56,76.96-4.95,112.1-5.5,129.2-.44,13.88-.7,27.62-2,48-.36,5.67-.68,10.54-1,18-.64,15.07-.62,27.36-.5,35.5"
      />
      <path
        style={styles.four}
        d="M416.99,585.5l28.57,90.65c12.98,5.24,42.21,15.01,79.43,9.85,35.27-4.89,59.78-20.69,71-29-5-16.75-10-33.51-15-50.26l1.96-5.17c-4.97-2.35-12.12-5.5-20.96-8.57-14.84-5.16-27.41-7.69-42-10-16.27-2.58-40.39-5.75-71.31-7.58-10.57,3.36-21.13,6.72-31.7,10.08Z"
      />
      <path
        style={styles.one}
        d="M443.22,665.99c10.12,4.97,49.41,22.8,93.78,8.01,18.31-6.1,31.65-15.82,40.2-23.41-2.07,46.8-4.13,93.61-6.2,140.41-2.52-.25-6.37-.45-11,0-4.95,.48-7.97,1.21-14.45,2.98-4.32,1.18-9.19,2.25-14.55,3.02-8.85,1.26-16.77,1.37-23.39,1-3.2-.56-6.75-.94-10.61-1-18.08-.27-26.06,9-31.83,7.22-6.42-1.99-10.84-17.51-5.17-71.22-5.59-22.34-11.19-44.67-16.78-67.01Z"
      />
      <path
        style={styles.three}
        d="M461.33,814.5c.43-8.4,1.83-15.59,3.35-21.3-.23-.98-.19-2.04,.11-3-1.45-12.52-2.93-25.09-4.46-37.7-1.15-9.53-2.11-21.46-3.5-28.5-.95-4.82-3.4-12.47-5-19-2.43-9.94-5.51-21.5-6.27-28.85-1.34-12.97-.18-30.49-7.21-51.65-5.71-17.18-13.65-30.24-19.98-39-13.57,4.81-28.41,10.99-43.96,19-29.35,15.11-52.63,32.29-70.28,47.59-3.2,10.81-6.17,22.64-8.64,35.41-4.23,21.82-6.12,41.86-6.71,59.43,15.82,18.54,45.84,48.64,92.62,69.57,31.81,14.23,60.79,19.49,81.42,21.5-1.11-6.16-1.97-14.17-1.5-23.5Z"
      />
      <path
        style={styles.three}
        d="M673.05,774.99c-.83-21.33-3.16-45.58-8.13-71.99-3.47-18.44-7.75-35.36-12.33-50.6-9.28-10.31-22.45-22.84-40.17-34.4-12.6-8.21-24.58-14.04-34.84-18.2-.86,25.07-1.72,50.13-2.58,75.2-.88,22.32-1.77,44.65-2.65,66.97l-3.35,88.03c18.85-5.27,42.58-14.01,67.65-29,14.33-8.57,26.41-17.57,36.4-26.01Z"
      />
      <path
        style={styles.seven}
        d="M328.38,784.67c24.26,2.88,50.18,5.11,77.62,6.33,20.26,.91,39.71,1.19,58.28,1-.43,15.74-.86,31.49-1.28,47.23-20.66-2.31-49.43-7.93-81-22.23-22.42-10.16-40.25-21.97-53.62-32.33Z"
      />
      <circle style={styles.two} cx="412.5" cy="634.5" r="12.5" />
      <circle style={styles.two} cx="593.5" cy="649.5" r="12.5" />
      <path d="M336.33,627.04c10.56,10.83,19.27,23.38,25.67,36.96-7.01-13.28-16.21-25.34-27.08-35.53l1.4-1.43h0Z" />
      <path d="M423.34,730.74c13.05,33.58,20.16,69.47,20.89,105.52,0,0-2,.02-2,.02-.06-35.91-6.49-71.81-18.89-105.55h0Z" />
      <polygon points="521.4 836.26 522.38 820 523.08 820.01 523.4 836.3 521.4 836.26 521.4 836.26" />
      <path d="M648.25,793.82c-2.06-5.15-2.93-10.41-3.31-15.92-1.43-13.51-.09-27.1,1.58-40.5,.46-2.66,.99-5.32,1.72-7.92-.3,5.4-1.08,10.71-1.49,16.08-.32,5.37-.27,10.74-.13,16.09,.17,5.35,.39,10.7,.81,16.04,.57,5.43,1.04,10.65,.81,16.13h0Z" />
      <path d="M424.23,595.13c8.57-2.34,17.71-2.14,26,.47-8.47-1.97-17.4-1.43-25.41,1.43l-.6-1.91h0Z" />
      <path d="M536.97,600.47c14.51-2.68,29.58-2.64,44.2,.13l-.42,1.96c-14.23-3.36-29.16-4.1-43.78-2.08,0,0,14.62-2.02,0,0Z" />
      <path
        style={styles.five}
        d="M418.5,585.5c5.96,8.37,13.08,20.23,18.5,35.5,7.25,20.39,8.48,38.44,8.34,50.41,3.89,17.53,7.78,35.06,11.66,52.59,3.46,19.24,6.18,41.4,7,66,.59,17.78,.11,34.27-1,49.23"
      />
      <path
        style={styles.five}
        d="M445.08,666.89c9.74,4.84,37.86,17.22,73.92,11.11,28.98-4.91,48.54-19.28,57.15-26.49"
      />
      <path d="M328.38,784.67c45.12,5.02,90.54,7.14,135.89,6.33l.02,2c-45.44,.14-90.87-2.65-135.91-8.33,0,0,45.04,5.68,0,0Z" />
      <path d="M472.04,645.86c15.82,3.96,27.85,17.23,28.16,33.86,0,0-2,.03-2,.03,.23-11.54-5.96-22.69-15.75-28.78-3.24-2.08-6.77-3.74-10.49-4.83l.07-.27h0Z" />
      <path d="M526.79,645.61c-11.01,3.23-20.85,11.37-24.47,22.44-1.22,3.6-1.78,7.39-1.77,11.17l-2-.03c.2-11.98,6.99-23.42,17.38-29.36,3.37-2,7.01-3.53,10.79-4.5l.07,.27h0Z" />
      <path
        style={styles.six}
        d="M405,630c1.52-3.12,4.66-5.08,8-5,4.38,.11,6.79,3.68,7,4-1.55-.51-3.99-1.11-7-1-3.63,.13-6.42,1.23-8,2Z"
      />
      <path
        style={styles.six}
        d="M586.34,647.15c1.52-3.12,4.66-5.08,8-5,4.38,.11,6.79,3.68,7,4-1.55-.51-3.99-1.11-7-1-3.63,.13-6.42,1.23-8,2Z"
      />
      <polygon
        style={styles.four}
        points="572.5 829.13 567.5 830.5 570 791.89 572.5 829.13"
      />
      <path
        style={styles.seven}
        d="M570,790.96c15.24,.98,32.36,1.21,51,.04,13.33-.84,25.75-2.28,37.15-4.07-8.41,6.32-18.1,12.83-29.15,19.07-20.85,11.77-40.54,19.2-57,24-.67-13.01-1.33-26.03-2-39.04Z"
      />
      <path d="M463.84,804.61c7.52-3.39,15.51-5.75,23.67-6.93,4.08-.58,8.2-.9,12.32-.9,2.05,0,4.13,.07,6.16,.21,1.99,.08,4.05,.09,6.07,.08,8.12-.05,16.26-.7,24.3-1.79,4.02-.57,8.01-1.33,11.96-2.28,1.98-.48,3.97-.97,5.99-1.36,2.02-.39,4.06-.66,6.11-.86,4.08-.41,8.23-.39,12.29-.16,4.07,.17,8.15,.3,12.23,.39,8.15,.18,16.31,.19,24.46-.01,8.15-.21,16.3-.66,24.43-1.31,4.07-.33,8.13-.7,12.18-1.13,4.05-.5,8.09-1.09,12.13-1.63-4.04,.58-8.07,1.22-12.11,1.76-4.05,.47-8.11,.89-12.18,1.25-8.13,.73-16.28,1.26-24.44,1.56-8.16,.29-16.33,.36-24.49,.26-4.08-.05-8.16-.14-12.24-.27-4.08-.19-8.12-.17-12.16,.28-2.01,.21-4.02,.51-6,.9-1.98,.39-3.95,.91-5.93,1.4-3.97,1-8,1.81-12.05,2.43-8.11,1.18-16.27,1.92-24.47,2.05-2.06,.03-4.08,.05-6.17-.02-2.04-.12-4.05-.17-6.07-.14-4.04,.04-8.08,.4-12.07,1.01-7.98,1.24-15.77,3.63-23.1,7.02l-.83-1.82Z" />
      <path d="M578.58,599.84s-8.14,192.2-8.14,192.03c0,0,1.89,38.11,1.89,38.11,0,0-.64,.03-.64,.03,0,0-2.11-38.1-2.11-38.1,0,0,7.01-192.13,7.01-192.15,0,0,2,.08,2,.08h0Z" />
    </g>
  );
};

export default Blouse;
