const CatOne = () => {
  const styles = {
    one: {
      fill: "#f297cd",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },
    two: {
      stroke: "#000",
      fill: "none",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },
    three: {
      stroke: "#000",
      fill: "#f4ddae",
      strokeMiterLimit: 10,
    },
    four: {
      fill: "#efb83c",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      stroke: "#000",
    },
    five: {
      fill: "#f4b3dd",
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="M382.5,494c1.22-4.44,2.81-12.59,1-22.5-1.4-7.62-4.28-13.4-6.46-17-2.02,2.32-4.47,5.64-6.54,10-2.62,5.51-3.6,10.53-4,14,5.33,5.17,10.67,10.33,16,15.5Z"
      />
      <path
        style={styles.one}
        d="M275.5,466.5c-1.45,3.82-4.44,13.26-2,25,2.14,10.3,7.38,17.17,10.07,20.26,3.31-6.75,6.62-13.51,9.93-20.26,0-2.49-.35-8.18-4-14-4.72-7.54-11.83-10.26-14-11Z"
      />
      <path
        style={styles.four}
        d="M290.5,497.5c-.45,.64-3.94,5.86-6.75,13.75-3.66,10.27-6.16,25.09,.75,40.25,.75,1.65,9.77,20.67,30,27,30.83,9.64,72.79-14.88,77-49,2.89-23.43-13.2-41.86-17-46,.18-.54,.48-1.25,1-2,.68-.99,1.46-1.62,2-2-.72-.48-1.75-.98-3-1-2.09-.03-3.6,1.32-3.85,1-.21-.27,.97-1.06,1.85-3,0,0,.15-.33,1-3l-4,3c.23-2.75,.77-6.19,2-10,1.69-5.24,4.06-9.25,6-12-2.89,.36-7.32,1.31-12,4-7.83,4.5-11.58,11.11-13,14-4.62-1.24-11.18-2.42-19-2-12.51,.68-21.93,5.11-27,8-2.63-2.66-7.91-7.3-16-10-6.17-2.06-11.57-2.19-15-2,3.41,2.68,7.95,6.89,12,13,2.93,4.42,4.79,8.62,6,12-.71-.58-2.06-1.52-4-2-2.23-.56-4.09-.23-5,0,1,.28,2.49,.83,4,2,.88,.68,1.53,1.4,2,2-2.33,.67-4.67,1.33-7,2,.78-.18,3.73-.75,6,1,.4,.31,.73,.65,1,1Z"
      />
      <path d="M306.43,535.47c2.95-10.65,15.62-15.69,25.07-9.97-6.94-2.93-15.4-1.61-20.65,3.9-1.72,1.77-3.2,3.83-4.28,6.13-.03,.08-.17,.04-.14-.05h0Z" />
      <path d="M347.93,522.97c6.2-7.67,17.86-10.3,26.57-5.47-9.59-3.66-19.22-.98-26.57,5.47h0Z" />
      <path
        style={styles.two}
        d="M317.5,513.5c-.83,.16-1.88,.46-3,1-1.94,.94-3.23,2.2-3.98,3.06"
      />
      <path
        style={styles.two}
        d="M358.5,504.5c.94,.06,2.39,.27,4,1,1.37,.63,2.36,1.41,3,2"
      />
      <line style={styles.two} x1="301.26" y1="535.5" x2="293.96" y2="536.24" />
      <line style={styles.two} x1="299.5" y1="537.5" x2="293.96" y2="539.57" />
      <line style={styles.two} x1="300.48" y1="539.57" x2="295.5" y2="543.5" />
      <line style={styles.two} x1="377.04" y1="517.56" x2="380.96" y2="513.5" />
      <line style={styles.two} x1="379" y1="519.22" x2="382.5" y2="516.5" />
      <line style={styles.two} x1="379" y1="521.3" x2="383.3" y2="522.54" />
      <path d="M351.66,538.17c7.1-6.58,17.73-8.95,26.88-5.78,.14,.05,.08,.28-.07,.23-9.05-2.86-19.37-.31-26.12,6.28-.47,.45-1.18-.28-.68-.73h0Z" />
      <path d="M351.85,540.26c6.51-1.92,13.67-1.48,19.85,1.27-6.23-2.41-13.27-2.51-19.54-.32-.63,.21-.95-.76-.3-.95h0Z" />
      <path d="M353.56,543c4.66,.06,9.33,1.05,13.31,3.53,1.44,.82,5.17,3.09,6.63,3.97-2.59-1.39-6.6-3.66-9.09-4.78-3.45-1.36-7.17-1.85-10.84-1.72-.66,.02-.69-1-.01-1h0Z" />
      <path d="M333.54,543.05c-9.57-2.8-20.24-1.28-28.73,4-.05,.04-.12-.06-.06-.09,8.43-5.6,19.23-7.43,29.05-4.87,.63,.17,.39,1.14-.26,.97h0Z" />
      <path d="M334.26,545.28c-7.52-.24-15.01,2.94-20.14,8.47-.12,.13-.34-.05-.21-.19,5.05-5.8,12.64-9.28,20.36-9.27,.66,0,.66,1.01-.01,1h0Z" />
      <path d="M333.63,548.98c-7.75,1.91-14.5,7.38-18.02,14.57-.06,.13-.28,.04-.21-.1,3.36-7.43,10.1-13.22,17.98-15.43,.63-.18,.91,.8,.26,.97h0Z" />
      <path
        style={styles.one}
        d="M342.22,542.57c-1.24,.02-4.1-.13-6.72-2.07-.87-.64-1.52-1.34-2-1.97,.14-.52,.81-2.68,3-4.03,1.7-1.05,3.37-1.04,4-1,3.48-1.23,6.7-.81,8,1,.26,.36,.54,.92,.63,1.74-.43,.71-.96,1.48-1.63,2.26-1.81,2.1-3.82,3.34-5.28,4.07Z"
      />
      <path
        style={styles.two}
        d="M352.85,549.22c-.7,.18-1.49,.29-2.35,.28-4.22-.04-7.14-3.05-8-4v.26c-.29,.96-.85,2.36-2,3.74-2.24,2.7-5.18,3.47-6.24,3.7"
      />
      <path
        style={styles.four}
        d="M321.94,580.15c-.93,2.46-2.59,6.24-5.44,10.35-4.29,6.17-7.58,7.73-11,13-1.79,2.77-4.01,7.15-5.02,13.67v16.63l4.3-8.22c-.76,1.29-2.44,4.52-2.28,8.91,.14,3.71,1.52,6.43,2.28,7.72,.57-1.37,1.14-2.74,1.72-4.11-.24,4.42,1.05,7.01,2,8.39,4.7,6.81,17.81,8.27,32,2,.78-.06,1.92-.27,3-1,1.08-.73,.93-1.19,2-2,1.66-1.27,2.85-.79,4-2,.63-.67,.89-1.46,1-2-.42-.63-1.06-1.38-2-2-3.04-1.99-6.62-.81-7.18-.61,.7-1.1,1.68-2.93,2.18-5.39,.54-2.63,.3-4.83,.05-6.15,1.18,1.64,4.01,5.07,8.95,7.15,3.59,1.52,6.82,1.7,8.73,1.65,2.42-1.22,5.92-2.13,7.27-3.65,.69-.77,.85-2.34,1-4,.08-.87,.06-1.65,0-2.33,4.21,1.33,8.57,.26,11-2.67,1.9-2.29,2.38-4.47,2-6-.6-2.39-3.87-6.81-4-9-.25-4.02,1.6-6.32,1-11-.34-2.63-1.27-4.68-2-6-.65-2.61-1.3-6.42-1-11,.26-4.03,1.16-7.34,2-9.7-1.67-2.78-3.33-5.56-5-8.34-3.55,3.79-9.44,9.08-18,13.04-14.44,6.67-27.78,5.51-33.56,4.65Z"
      />
      <path
        style={styles.three}
        d="M344,580.16c-2.79,5.32-2.42,8.06-1.5,9.59,1.14,1.9,3.04,1.68,7,5.05,1.83,1.55,3.15,3.02,4,4.04,2.67,3.21,4.08,6.12,5,8.07,1,2.12,2.15,5.01,3,8.59,.58-2.13,1.5-4.75,3-7.58,1.31-2.48,2.73-4.49,4-6.06,1.87-1.51,3.97-3.5,6-6.06,.97-1.22,1.79-2.4,2.5-3.53-.68-3.34-.92-6.1-1-8.07-.04-.92-.07-1.81,0-3.03,.04-.61,.19-2.82,1-6.06,.81-3.22,1.34-3.73,1-5.05-.29-1.11-.85-1.42-2-3.03-.72-1.02-1.66-2.52-2.5-4.58-3.06,3.36-7.8,7.81-14.5,11.64-5.7,3.26-10.99,5.04-15,6.06Z"
      />
      <path
        style={styles.four}
        d="M267.5,587.5c-10.08,2.66-18.56-1.62-20-7-1.3-4.86,3.19-10.38,7-13,5.03-3.46,10.49-2.98,13-2.76,11.59,1.02,18.49,10.11,19,10.76,8.83,11.24,5.4,24.54,5,26-5.79,16.79-3.59,32.11,5,38,1.72,1.18,4.35,2.43,8.28,2.72,.57-1.37,1.14-2.74,1.72-4.11l2.91,9.52c-1.52,.96-5.53,3.2-10.91,2.87-8.49-.53-13.32-7.05-14-8-2.5-3.42-5.81-8.79-8-16-2.67-8.81-2.48-16.42-2-21-.33-4.33-.67-8.67-1-13-.5-2.54-2.02-4.52-4-5-.45-.11-1.13-.19-2,0Z"
      />
      <path
        style={styles.two}
        d="M303.5,481.5c3.81-3.01,10.92-7.77,21-10,14.63-3.23,26.54,.87,31.15,2.75"
      />
      <path
        style={styles.two}
        d="M361.5,616.5c.19,1.15,.67,3.05,2,5,2.05,3.01,4.87,4.25,6,4.67"
      />
      <path
        style={styles.two}
        d="M318.12,614.5c.64-.34,8.39-4.25,16.38,0,7.58,4.03,8.92,11.99,9.05,12.85"
      />
      <path
        style={styles.two}
        d="M332.5,645.5c.83-1.12,2.12-2.61,4-4,1.81-1.34,3.54-2.14,4.82-2.61"
      />
      <path
        style={styles.two}
        d="M343.55,642.2c.31,.37,.63,.8,.95,1.3,.45,.72,.77,1.41,1,2"
      />
      <path
        style={styles.two}
        d="M338.5,645.5c.33,.05,1.21,.24,2,1,.73,.7,.97,1.51,1.05,1.84"
      />
      <path
        style={styles.two}
        d="M361.5,628.59c.41,.32,1.5,1.26,2,2.91,.53,1.76,.07,3.23-.1,3.7"
      />
      <path
        style={styles.two}
        d="M364.5,627.09c.55,.58,1.47,1.72,2,3.41,.41,1.31,.42,2.45,.37,3.18"
      />
      <path
        style={styles.two}
        d="M373.59,621.34c.27,.14,1.39,.76,1.91,2.16,.54,1.47,.02,2.73-.09,2.99"
      />
      <path
        style={styles.two}
        d="M377.5,619.5c.28,.13,1.4,.67,2,2,.61,1.35,.26,2.57,.17,2.86"
      />
      <ellipse
        style={styles.five}
        cx="341.24"
        cy="535.28"
        rx="1.78"
        ry=".71"
        transform="translate(-135.98 116.67) rotate(-16.23)"
      />
    </g>
  );
};

export default CatOne;
