import { Button, CircularProgress, Grid } from "@mui/material";
import {
  cardExtraLight,
  secondaryButton,
  secondaryText,
  text,
  transition,
} from "../../utils/themeContstants";

const BorderOutlineButton = ({
  label,
  onClick,
  loading,
  disabled,
  fullWidth,
}) => {
  const styles = {
    button: {
      opacity: disabled || loading ? 0.3 : 1,
      fontFamily: "Nunito Sans",
      padding: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      whiteSpace: "nowrap",
      transition: transition,
      border: `2px solid ${cardExtraLight}`,
      textTransform: "none",
      boxShadow: "none",
      height: 32,
      borderRadius: 100,
      fontWeight: 800,
      minWidth: fullWidth ? "100%" : 192,
      fontSize: 12,
      color: text,
      backgroundColor: disabled || loading ? secondaryButton : "transparent",
      "&:hover": {
        cursor: disabled || loading ? "not-allowed" : "pointer",
        backgroundColor: cardExtraLight,
        boxShadow: "none",
      },
    },
  };

  return (
    <Button
      sx={styles.button}
      variant="contained"
      size="large"
      onClick={(e) => {
        if (loading || disabled) return;

        onClick(e);
      }}
    >
      {loading && (
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={20} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && <>{label}</>}
    </Button>
  );
};

export default BorderOutlineButton;
