const Basketball = ({ color, skinColor }) => {
  const styles = {
    one: {
      fill: skinColor,
    },
    two: {
      fill: color,
    },
    three: {
      fill: "#fff",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="M288.78,746.93c10.63,12.29,20.52,21.51,28.22,28.07,10.22,8.71,19.48,15.17,25,19,3.29,2.28,5.7,3.95,9,6,0,0,11.64,7.22,26,13,61.98,24.93,108,27,108,27,72.15,3.14,125.07-23.55,139-31,21.56-11.53,37.88-24.21,49.05-34.01-.74-18.02-2.53-38.17-6.05-59.99-3.77-23.36-8.83-44.32-14.21-62.6-9.91-10.89-22.98-23.1-39.79-34.4-39.22-26.37-78.35-34.04-101.57-36.58-20.91-2-41.83-4-62.74-6-18.73,4.79-40.9,12.12-64.69,23.58-34.78,16.74-61.22,36.61-79.85,53.09-4.16,11.19-8.23,24.57-11.15,39.91-4.03,21.14-4.74,39.91-4.22,54.93Z"
      />
      <g>
        <path style={styles.one} d="M348,717c-.05,4.15,3,83,3,83" />
        <polygon points="349 717.01 351 800 347 716.99 349 717.01 349 717.01" />
      </g>
      <path
        style={styles.two}
        d="M432.69,580.12c-10.06,3.79-20.12,7.59-30.19,11.38-1.75,13.4-4.8,28.63-10,45-11.68,36.77-29.52,64.41-43.95,82.85,.82,26.88,1.64,53.77,2.45,80.65,11.6,7.21,28.1,16.27,49,24,17.64,6.53,57.52,19.18,109.5,16.5,44.44-2.29,79.2-14.84,100.5-24.5-.3-29.11-.6-58.21-.9-87.32-13.15-12.14-32.32-32.79-47.6-63.18-16.14-32.11-21.04-61.38-22.67-79.59-6.11-1.14-12.22-2.27-18.33-3.41,.91,5.97,2.8,23.49-7,42-11.87,22.41-32.46,30.36-37.43,32.13-3.12-.82-31.86-8.93-42.57-37.13-6.8-17.9-2.54-33.91-.81-39.38Z"
      />
      <path
        style={styles.three}
        d="M448.69,575.42c-1.95,3.47-9.6,18.02-5.19,36.08,5.88,24.15,28.18,33.55,30.5,34.49,5.53-2.87,28.16-15.42,35.5-41.49,2.52-8.95,2.54-17.02,1.93-23.08,3.22,.44,6.45,.88,9.67,1.32,.95,6.49,2.31,22-5.61,38.75-11.65,24.68-34.59,33.41-39.43,35.13-3.11-.94-34.15-10.89-43.57-41.13-4.84-15.55-1.7-29.18,.19-35.38,5.34-1.56,10.67-3.13,16.01-4.69Z"
      />
      <path
        style={styles.three}
        d="M388.07,597.25l14.19-6.21c-1.85,14-5.1,30.1-10.77,47.46-11.66,35.75-28.93,62.71-42.95,80.85-.98-.61-7-4.5-8.05-11.85-.51-3.59,.38-6.52,1-8.11,11.88-13.39,27.77-34.93,38-64.89,4.68-13.7,7.22-26.38,8.57-37.25Z"
      />
      <path
        style={styles.three}
        d="M538.83,585.91c1.81,18.07,6.78,45.91,21.67,76.59,15.46,31.86,35.18,53.53,48.6,66.18,.89-2.09,1.84-4.85,2.4-8.18,.67-3.95,.58-7.36,.32-9.88-11.5-11.3-27.29-29.42-40.32-55.12-13.31-26.26-18.54-50.22-20.78-66.35-3.96-1.08-7.93-2.16-11.89-3.24Z"
      />
      <g>
        <line style={styles.one} x1="609.1" y1="728.68" x2="610" y2="816" />
        <polygon points="610.1 728.67 610 816 608.1 728.69 610.1 728.67 610.1 728.67" />
      </g>
    </g>
  );
};

export default Basketball;
