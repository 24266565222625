const Default = () => {
  const styles = {
    one: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="M561.21,467.57c0-.25-8.28-1.72-17.31,1.49-9.75,3.46-15.37,10.59-15.15,10.86,.22,.28,6.85-7.14,18.48-10.53,7.81-2.28,13.99-1.6,13.98-1.82Z"
      />
    </g>
  );
};

export default Default;
