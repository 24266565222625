import BlowinBubs from "./types/BlowinBubs";
import Blunt from "./types/Blunt";
import Cig from "./types/Cig";
import Default from "./types/Default";
import Fatty from "./types/Fatty";
import Frown from "./types/Frown";
import FrownTwo from "./types/FrownTwo";
import Fury from "./types/Fury";
import Ghost from "./types/Ghost";
import Grillz from "./types/Grillz";
import Growl from "./types/Growl";
import Joint from "./types/Joint";
import Joy from "./types/Joy";
import Kunai from "./types/Kunai";
import Lips from "./types/Lips";
import LipstickThree from "./types/LipstickThree";
import LipstickTwo from "./types/LipstickTwo";
import Meh from "./types/Meh";
import Nauseous from "./types/Nauseous";
import OHHH from "./types/OHHH";
import Pipe from "./types/Pipe";
import Rawr from "./types/Rawr";
import RedLipstick from "./types/RedLipstick";
import Scared from "./types/Scared";
import Slant from "./types/Slant";
import Smirk from "./types/Smirk";
import Swearing from "./types/Swearing";
import ToothPick from "./types/ToothPick";
import Whine from "./types/Whine";
import WrapItUp from "./types/wrap_it_up/WrapItUp";
import WrapItUpThree from "./types/wrap_it_up/WrapItUpThree";
import WrapItUpTwo from "./types/wrap_it_up/WrapItUpTwo";
import Yawn from "./types/Yawn";
import Yum from "./types/Yum";

const Mouth = ({ type }) => {
  switch (type) {
    case "lipstick":
      return <RedLipstick />;
    case "flat_mouth":
      return <Slant />;
    case "frown":
      return <Frown />;
    case "frown_2":
      return <FrownTwo />;
    case "ahhh":
      return <OHHH />;
    case "blunt":
      return <Blunt />;
    case "cig":
      return <Cig />;
    case "default":
      return <Default />;
    case "fury":
      return <Fury />;
    case "growl":
      return <Growl />;
    case "joint":
      return <Joint />;
    case "joy":
      return <Joy />;
    case "lipstick_2":
      return <LipstickTwo />;
    case "lipstick_3":
      return <LipstickThree />;
    case "meh":
      return <Meh />;
    case "nauseous":
      return <Nauseous />;
    case "scared":
      return <Scared />;
    case "smirk":
      return <Smirk />;
    case "swearing":
      return <Swearing />;
    case "whine":
      return <Whine />;
    case "lips":
      return <Lips />;
    case "yawn":
      return <Yawn />;
    case "yum":
      return <Yum />;
    case "rawr":
      return <Rawr />;
    case "wrap_it_up":
      return <WrapItUp />;
    case "wrap_it_up_2":
      return <WrapItUpTwo />;
    case "wrap_it_up_3":
      return <WrapItUpThree />;
    case "kunai":
      return <Kunai />;
    case "ghost":
      return <Ghost />;
    case "blowin_bubs":
      return <BlowinBubs />;
    case "fatty":
      return <Fatty />;
    case "grillz":
      return <Grillz />;
    case "tooth_pick":
      return <ToothPick />;
    case "pipe":
      return <Pipe />;
    default:
      return null;
  }
};

export default Mouth;
