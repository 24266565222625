const Cardigan = ({ color, skinColor }) => {
  const styles = {
    one: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#fff",
    },
    four: {
      fill: color,
    },
    five: {
      fill: skinColor,
    },
  };

  return (
    <g>
      <path
        style={styles.five}
        d="M448.69,575.42c-3.81,.89-9.66,2.36-16.69,4.58,0,0-15.02,4.74-26,10-15.54,7.45-36.22,44.25-50.22,95.43,5.14,42.06,10.28,84.12,15.42,126.18,25.9,12.71,79.46,34.35,147.8,28.39,34.1-2.97,62.09-11.96,82.34-20.49-12.87-75.41-25.75-150.82-38.62-226.22-6.68-2.23-13.92-4.38-21.72-6.29-10.57-2.6-20.5-4.37-29.57-5.58-20.91-2-41.83-4-62.74-6Z"
      />
      <path
        style={styles.three}
        d="M455.87,662.64c14.9,4.41,36.13,8.78,61.63,7.86,14.8-.54,27.75-2.73,38.37-5.33-4.02,6.66-9.93,14.97-18.37,23.33-9.51,9.41-19,15.66-26.3,19.71-7.53-4.76-15.51-10.29-23.7-16.71-12.5-9.8-22.97-19.69-31.63-28.86Z"
      />
      <ellipse
        style={styles.four}
        cx="378.5"
        cy="813"
        rx="1"
        ry=".5"
        transform="translate(-509.31 686.38) rotate(-56.92)"
      />
      <polygon
        style={styles.four}
        points="378 815 378.57 815.24 379.31 812.42 378 815"
      />
      <path
        style={styles.four}
        d="M378,815c-11.74-5.65-24.86-12.98-38.5-22.5-21.99-15.35-38.62-31.69-50.72-45.57,.45-17.7,2.28-38.12,6.72-60.43,2.47-12.44,5.45-23.93,8.65-34.41,14.55-11.77,31.96-24.38,52.35-36.59,18.43-11.04,35.96-19.77,51.8-26.68,9.12,22.26,26.5,55.88,59.2,86.68,15.24,14.35,30.45,24.94,43.7,32.71,6.25-2.94,35.1-17.33,48.3-51.71,11.55-30.09,3.79-56.41,1.29-63.88,13.86,4.76,31.73,12.4,50.71,24.88,18.04,11.86,31.61,24.49,41.29,34.9,4.33,15.3,8.36,32.03,11.71,50.1,4.88,26.34,7.42,50.71,8.55,72.49-8.21,7.17-17.86,14.7-29.05,22.01-19.89,13-38.88,21.54-54.71,27.25-.57-3.39-1.04-7.16-1.29-11.25-.31-5.16-.22-9.88,.08-14.03-18.19-.82-36.39-1.65-54.58-2.47-1.04-10.18-2.88-21.64-6-34-1.91-7.56-4.07-14.55-6.33-20.94-.41-.47-1.67-1.77-3.67-2.06-2.38-.34-4.84,.9-6.3,3.15-1.76,7-3.38,14.63-4.7,22.85-1.62,10.06-2.54,19.45-3,28-3.96,.85-8.3,1.56-13,2-11.88,1.11-22.28,.14-30.5-1.36-6.74-3.52-15.65-7.27-26.5-9.64-21.44-4.7-39.58-1.65-50,1-.47,5.59-1.03,10.16-1.5,13.5-.09,.63,.02-.15-1,6-.58,3.49-.9,5.5-2,8-.38,.87-.75,1.56-1,2Z"
      />
      <path
        style={styles.two}
        d="M408.3,588.82c9.85,22.85,27.82,55.98,60.2,86.68,14.83,14.06,29.61,24.69,42.7,32.71,6.3-2.89,33.58-16.23,47.3-48.71,13.46-31.87,4.59-60.19,2.29-66.88"
      />
      <path d="M380.5,694.5c-2.68,20.14-.75,40.23,2.27,60.2,.83,4.99,1.55,10.11,1.6,15.17,.36,12.69-.9,25.42-3.59,37.82-.55,2.47-1.21,4.93-2.08,7.32,.15-2.41,.89-5.02,1.3-7.48,3.09-14.78,4.07-30.07,2.14-45.06-4.2-22.23-7.67-45.74-1.64-67.96h0Z" />
      <path d="M599.5,677.5c11.26,10.69,18.15,25.61,19.95,41,1.3,10.29-.16,35.05-.2,45.7-.22,10.37-.46,20.75-.77,31.13-6.41,1.32-15.26,3.06-21.68,3.76-21.09,2.57-42.53,1.9-63.46-1.6,0,0-.67-.11-.67-.11l-.14-.67c-2.75-12.46-8.51-41.29-11.41-53.34-2.18-4.8-8.47-3.11-8.92,1.85-.05,.02-7.67,48.41-7.71,48.44,0,0-.1,.62-.1,.62l-.61,.18c-17.32,5.17-36.25,4.17-53.12-2.21-7.05-2.9-14.03-5.73-21.6-6.8-9.95-1.28-20.25-.8-30.3-.37-5,.3-10.16,.57-15.05,1.4,0,0-.41-1.96-.41-1.96,5.14-.88,10.19-1.13,15.35-1.44,10.27-.43,20.46-.92,30.72,.39,7.65,1.07,14.93,3.98,21.99,6.9,16.48,6.22,34.94,7.22,51.86,2.16,0,0-.71,.8-.71,.8,.03,.04,7.71-48.63,7.69-48.3,.38-5.57,7.23-8.9,11.32-4.59,1.17,1.12,1.86,2.92,2.08,4.42,0,0,3.63,17.14,3.63,17.14l7.25,34.28-.81-.78c24.21,4.06,49.1,4.25,73.3,.06,0,0,10.32-2.05,10.32-2.05l-.78,.99c-1.29-20.17-1.4-40.48-1.06-60.66,.02-5.02,.02-10-.49-14.85-1.75-14.87-8.07-28.57-15.45-41.51h0Z" />
      <path
        style={styles.one}
        d="M588.08,798.97c-.36,2.85-.6,6.04-.58,9.53,.03,6.11,.82,11.43,1.79,15.75"
      />
      <polygon points="514 833 514.65 818.7 515.35 818.7 516 833 514 833 514 833" />
      <path d="M419.51,611.53c16.37,1.01,32.63,3.45,48.54,7.28l-.08,.37c-16-3.3-32.26-5.18-48.54-5.65l.09-2h0Z" />
      <path d="M565.68,623.25c-13.12-1.7-26.4-2.36-39.66-1.96l-.03-.58c13.27-.87,26.63-.69,39.91,.56l-.22,1.99h0Z" />
    </g>
  );
};

export default Cardigan;
