import "../styles/Blunt.css";

const Blunt = () => {
  return (
    <g>
      <defs>
        <filter
          id="luminosity-noclip-2"
          data-name="luminosity-noclip"
          x="8164.21"
          y="-8225.59"
          width="32766"
          height="32766"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood flood-color="#fff" result="bg" />
          <feBlend in="SourceGraphic" in2="bg" />
        </filter>
        <mask
          id="mask"
          x="8164.21"
          y="-8225.59"
          width="32766"
          height="32766"
          maskUnits="userSpaceOnUse"
        />
      </defs>
      <g transform="translate(520, 470)">
        <g>
          <g>
            <g className="blunt-5">
              <path
                className="blunt-1"
                d="M15.53,7.61L22.73,1.18l59.14,83.46c.2,.96,.41,2.56,.06,4.49-.36,2-1.15,3.46-1.69,4.3l3.69,1.19c.08,.54,.12,1.24,.04,2.06-.08,.76-.24,1.39-.41,1.88,.46,.03,1.07,.12,1.75,.36,.51,.18,.94,.41,1.26,.61,.13,.37,.31,1.04,.25,1.89-.07,.83-.34,1.45-.53,1.79-.09,.09-.29,.27-.62,.35-.4,.1-.73-.02-.83-.07-.14-.08-.72-.43-.98-1.17-.26-.75-.02-1.39,.05-1.55-.15,.1-.51,.29-1,.28-.84-.02-1.35-.67-1.42-.76,.08-.88,.16-1.76,.25-2.64-.31-.12-.71-.31-1.11-.61-.64-.47-1.04-1-1.27-1.37-.96,.36-2.67,.85-4.81,.71-1.68-.11-3.01-.57-3.87-.95C52.3,66.18,33.91,36.9,15.53,7.61Z"
              />
            </g>
            <path
              className="blunt-4"
              d="M25.23,23.07c.66-1.26,1.73-2.96,3.42-4.66,1.47-1.47,2.94-2.47,4.09-3.12-3.33-4.71-6.67-9.41-10-14.12-.41-.03-3.14-.18-5.25,1.93-1.79,1.79-1.94,3.99-1.96,4.5,3.23,5.15,6.47,10.31,9.7,15.46Z"
            />
            <path
              className="blunt-2"
              d="M32.74,15.3c1.83,6.82,4.08,14.09,6.84,21.69,3.83,10.56,8.03,20.04,12.22,28.39"
            />
          </g>
          <path
            className="blunt-3"
            d="M33.47,1.55c0-.25-8.28-1.72-17.31,1.49C6.4,6.5,.79,13.63,1.01,13.9c.22,.28,6.85-7.14,18.48-10.53,7.81-2.28,13.99-1.6,13.98-1.82Z"
          />
        </g>
      </g>
    </g>
  );
};

export default Blunt;
