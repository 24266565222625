import { Grid } from "@mui/material";
import MatchTeamItem from "./MatchTeamItem";
import MatchWaitingTeamItem from "./MatchWaitingTeamItem";

const MatchWaitingState = ({ token }) => {
  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <Grid item sx={styles.width}>
      <Grid
        container
        alignItems="stretch"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <MatchTeamItem
          isCreatingTeam={true}
          team={token?.creating_team}
          token={token}
        />

        <MatchWaitingTeamItem token={token} />
      </Grid>
    </Grid>
  );
};

export default MatchWaitingState;
