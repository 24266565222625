const DefaultPierced = () => {
  const styles = {
    one: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#f9d438",
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "none",
      strokeWidth: 2.5,
    },
    three: {
      fill: "#f9e698",
    },
  };

  return (
    <g>
      <g>
        <path
          style={styles.two}
          d="M512.54,395.09c4.79,.05,10.54-.24,16.96-1.3,6.64-1.1,12.34-2.77,16.96-4.43,5.84,.63,10.81,4.37,12.78,9.65,2.45,6.58-.93,12.35-1.37,13.09-1.59,2.79-4.34,6.84-8.8,10.65-5.05,4.32-10.1,6.48-13.3,7.57"
        />
      </g>
      <g id="Nose_Ring" data-name="Nose Ring">
        <g>
          <circle style={styles.one} cx="538.5" cy="411.5" r="4.5" />
          <path
            style={styles.three}
            d="M536.86,408.23c.12-.09,1.4-.94,2.86-.41,1.17,.42,1.98,1.56,2.05,2.86-.44-.53-1.37-1.49-2.86-2.05-.79-.29-1.51-.39-2.05-.41Z"
          />
        </g>
      </g>
    </g>
  );
};

export default DefaultPierced;
