const RevengeFace = () => {
  const styles = {
    one: {
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      fill: "#e85b5b",
    },
  };

  return (
    <g>
      <g>
        <ellipse cx="533.92" cy="358.71" rx="1.63" ry="1.95" />
        <ellipse cx="533.92" cy="365.88" rx="1.63" ry="1.95" />
        <ellipse cx="533.92" cy="373.05" rx="1.63" ry="1.95" />
        <rect x="533.59" y="274" width="1.3" height="13.68" />
        <rect x="533.59" y="293.55" width="1.3" height="13.68" />
        <path
          style={styles.one}
          d="M536.85,352.19l-2.61-.35h-1.4l-1.59,.35,1.78-3.1-.1-13.2-3.26-3.26h-3.14l-1.42-1.3,1.95,.65-1.95-3.26-5.21-.65-3.26,.65,3.26-1.3-3.91-2.61,5.21,1.95c.33,.14,.9,.33,1.63,.33,.35,0,.95-.04,1.63-.33-1.52-1.95-3.04-3.91-4.56-5.86l5.21,5.21c-.33-1.19-.65-2.39-.98-3.58,.76,.98,1.52,1.95,2.28,2.93v2.61l1.95,3.26h1.3l-1.3-3.91,2.61,4.56,2.61,1.95v-5.25l-5.21-3.87-3.91-6.52,3.91,5.86,5.21,3.12v-2.1l-3.91-3.63-1.3-3.26-6.52-3.91,5.86,2.61v-3.26l-1.95-2.61,3.26,2.61v3.91l1.95,3.26,.65-3.26v3.91l2.28,2.16,.98,3.05-.65-5.86s-1.95-4.36-1.95-4.14,0-4.84,0-4.84c.33,1.8,.65,3.6,.98,5.4,.25,.48,.78,.75,1.3,.65,.49-.09,.88-.48,.98-.98,.22-1.74,.43-3.48,.65-5.21v5.86l-1.3,1.48,.65,2.93,1.63-2.78c.65-1.52,1.3-3.04,1.95-4.56,.1-.69,.46-2.46,1.95-3.91,.69-.67,1.42-1.07,1.95-1.3-.87,1.52-1.74,3.04-2.61,4.56l7.82-3.91-7.82,5.21-1.95,3.91,5.86-3.26-4.56,4.56h-1.95l-1.95,2.61-.33,5.18,6.84-5.84,1.95-3.26,3.26-1.3-3.26,1.95-1.3,2.61h4.56l2.61-2.61-2.61,3.26h-4.56l-7.77,7.66-.05,2.77,4.56-2.61,1.95-2.61,.65-3.26v2.61h1.95l-1.95,1.3-1.95,1.79c.55,.01,1.52-.03,2.61-.49,.94-.39,1.58-.94,1.95-1.3,.65-.43,1.3-.87,1.95-1.3h3.26l3.26-3.91-2.61,3.91,3.26-.65-3.91,1.3h-2.61l-5.21,3.91h-2.61l-4.24,2.37-.49,14.98,1.79,2.52Z"
        />
      </g>
      <g>
        <path
          style={styles.one}
          d="M437.37,300.65c-2.35,2.62-4.63,5.15-6.83,7.6-.63,.09-1.62,.16-2.73-.14-1.57-.43-2.53-1.36-2.96-1.84,.49,9.17,.97,18.24,1.44,27.21l-5.44-1.19-.35-32.26,9.73,1.77c1.27-.13,2.62-.31,4.07-.56,1.05-.18,2.07-.38,3.07-.59Z"
        />
        <path
          style={styles.one}
          d="M440.17,300.03l-4.36,30.91-2.73,1.24,.27,2.82,8.44,1.84-1.42-5.04c.75-6.46,1.52-13.06,2.31-19.8,1.7-1.14,3.44-2.31,5.22-3.5-.92-.42-2.03-1.06-3.07-2.06-2.42-2.31-2.99-5.13-3.16-6.42h-1.49Z"
        />
      </g>
      <g>
        <path
          style={styles.two}
          d="M439.12,391.23c1.36,1.73,2.36,2.08,2.99,2.09,2.93,.06,4.07-6.79,4.35-8.73,1.31,2.95,2.66,5.98,4.04,9.09-.02,.28,.29,.52,.56,.52,.23,0,.44-.15,.43-.38,.05-1.11,.17-2.28,.37-3.49,.46-2.78,1.27-5.19,2.18-7.23,.01-.24-.25-.45-.57-.48s-.6,.15-.62,.39c-.89,1.95-1.78,3.88-2.67,5.82-.29-.6-.81-1.69-1.54-3.18-.52-1.06-.82-1.65-1.55-2.26-.62-.51-1.27-.81-1.72-.97,.03,1.29-.11,3.01-.72,4.93-.65,2.04-1.63,3.62-2.46,4.72-.3-.05-.96-.19-1.54-.63-.22-.17-.38-.34-.49-.48-.4-.29-.86-.3-1.02-.14-.14,.13-.04,.37-.03,.41Z"
        />
        <path
          style={styles.two}
          d="M455.01,388.04l.04-.88c.26-.02,.46,.02,.61,.06,.79,.25,1.26,1.2,1.13,2.37l-.17,3.61s.44,.92,1.22,1.09c.8,.17,1.92-.4,2.01-1.58l.17-3.87c-.05-.42,.37-.68,.68-.65s.71,.38,.62,.79l-.19,4.65c.19,.28,.56,.49,.98,.55s.81-.05,1.02-.28l.2-5c.12-.1,.35-.25,.69-.35,.42-.13,.81-.14,1.03-.13,.18-.06,.85-.24,1.73-.06,.75,.16,1.22,.49,1.39,.62l1.43,.15c.2-.14,.83-.54,1.83-.54,1.26,0,2.6,.62,3.32,1.57l-.14,5.48c.22,.12,.83,.46,.75,.61-.11,.2-1.4-.1-1.53-.12l-.67-.09,.15-5.53c-.22-.16-.75-.5-1.55-.66-1.28-.26-2.22,.16-2.37,.23l-.17,5.42s-.15,.31-.56,.33c-.38,.02-.82-.21-.98-.54l.18-5.37c-.14-.13-.51-.44-1.11-.6-1.14-.3-2.03,.22-2.08,.25l-.2,5.19c-.62,.58-1.7,.8-2.74,.56-1.26-.29-1.86-1.11-1.93-1.21-.31,.87-1.5,1.29-2.63,1.01-.92-.23-1.66-.89-1.81-1.63l.26-5.37-.61-.07Z"
        />
        <path
          style={styles.two}
          d="M486.51,392.92c0-.48-.28-.99-.86-1.37-.15-.1-.32-.19-.51-.27-.78-.33-1.73-.4-2.55-.21-.37,.09-.67,.25-.91,.45-.51,.43-1.01,.85-1.52,1.28-.47,.36-.37,.83-.02,1.03,.3,.16,.73,.11,.81,.1,.46-.52,.92-1.03,1.38-1.55,.18-.2,.53-.29,.92-.25h0c.57,.06,1.03,.4,1.02,.76l-.03,2.37c-.01,1.11-1.64,1.71-3.32,1.26h-.01c-.13-.04-.26-.08-.38-.12-.8-.28-1.38-.8-1.63-1.34-.18-.4-.33-.88-.36-1.42-.04-.86,.25-1.51,.49-1.91,.03-.04,.05-.08,.08-.12,.25-.33,.63-.8,1.1-1.36,.92-1.1,1.34-1.48,1.65-1.88,.54-.69,1.04-1.79,.75-3.51-.28-.71-1.3-1.2-2.28-1.18-.78,.02-1.43,.37-1.63,.88-.54,1.62-1.09,3.24-1.63,4.85-.33,1.03-.74,3.12,.66,5.64,.42,.76,.94,1.44,1.46,2.01,.32,.36,.78,.67,1.33,.87,.52,.19,1.22,.37,2.01,.38,.28,0,.53-.02,.76-.05,.57-.08,1.02-.31,1.3-.63,.48-.52,.98-1.2,1.35-2.03,.44-1,.57-1.93,.57-2.67Zm-6.21-7.74v-.5c.23-.12,.59-.09,.81,.06s.21,.38,0,.5c-.56,1.47-1.12,2.94-1.68,4.4,.29-1.49,.59-2.98,.88-4.47Z"
        />
      </g>
    </g>
  );
};

export default RevengeFace;
