const MimeShirt = ({ color, skinColor }) => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "#191919",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: color,
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    five: {
      fill: skinColor,
    },
  };

  return (
    <g>
      <path
        style={styles.five}
        d="m288.78,746.86c-.4-14.89.38-33.27,4.22-53.91,2.91-15.7,6.98-29.41,11.15-40.89,18.82-16.26,44.95-35.52,78.85-52.06,24.12-11.77,46.61-19.45,65.69-24.57,21.07,1.84,42.13,3.67,63.2,5.51,19.71,2.58,50.2,9.07,83.11,27.06,26.46,14.46,45.41,31.41,57.79,44.37,4.94,15.77,9.6,33.7,13.21,53.58,4.64,25.54,6.56,48.82,7.05,68.96-14.2,12.19-36.17,28.56-66.05,42-51.38,23.12-97.39,24.63-120,23.99-46.92-1.33-82.48-14.45-100-21.99-49.98-21.53-81.86-53.17-98.22-72.04Z"
      />
      <path
        style={styles.five}
        d="m518.87,533.53c-1.93,5.66-3.14,10.23-3.91,13.46-.37,1.56-.46,2.04-1.83,7.96-1.43,6.18-1.46,6.21-1.7,7.43-.91,4.8-1.06,8.01-1.17,10.7-.26,6.12.32,7.46,1.17,8.35.49.51,1.02.81,1.4.98,5.26,4.47,5.85,8.04,5.77,9.96-.35,9.12-16.94,15.17-27,16.97-23.01,4.12-44.54-8.01-48-17.09-.73-1.93-2.88-7.57-.23-11.22.48-.66,1.01-1.11,1.37-1.37.58-.37,1.48-1.02,2.38-2.02,0,0,1.01-.93,1.57-2.22,1.09-2.53,5.86-23.75,11.97-52.88,7.52,3.12,17.19,6.39,28.72,8.56,11.36,2.13,21.42,2.6,29.48,2.43Z"
      />
      <path d="m460,523c1.9,1.69,3.9,3.36,6,5,15.78,12.32,32.48,19.14,47,23,1.96-5.48,3.91-10.96,5.87-16.44-4.61.02-9.68-.16-15.13-.64-.79-.07-6.86-.61-12.73-1.62-12.45-2.13-23.75-6.29-31-9.31Z" />
      <path
        style={styles.one}
        d="m288.63,747.17c-.24-14.03.56-31,3.77-49.98,2.94-17.39,7.23-32.47,11.6-44.89,24.81-19.59,46.44-33.13,62.24-42.1,17.43-9.89,29.41-15.16,41.66-19.2,11.1-3.67,20.71-5.78,27.75-7.04,3.77,4.48,16.23,18.1,37.55,22.59,25.55,5.39,45.31-6.23,49.57-8.87,7.92-3.09,15.83-6.19,23.74-9.28,13.52,4.83,24.53,9.78,32.6,13.72,15.38,7.51,40.36,19.63,64.83,43.21,3.04,2.93,5.14,5.6,6.74,7.3,6.41,16.97,10.51,36.24,14.54,57.73,4.46,23.79,6.71,45.65,7.68,64.88-15.33,13.32-84.69,70.6-187.96,66.88-115.79-4.17-185-81.82-196.3-94.94Z"
      />
      <path
        style={styles.two}
        d="m294.93,752.26c65.49-14.17,151.14-24.67,251.72-16.57,46.43,3.74,87.7,10.78,124.72,19.4.3,7.04.39,11.52.69,18.55-49.77-13.67-91.76-16.92-124.11-19.7-59.73-5.12-106.8-1.29-146.39,2.12-37.48,3.22-68.02,7.39-91.38,11.45-5.06-5.1-10.2-10.16-15.26-15.26Z"
      />
      <path
        style={styles.two}
        d="m363.87,807.5l34.41,15.96c38.06-5.57,82.39-9.06,131.68-7.41,23.67.79,45.94,2.69,66.65,5.28l29.53-13.82c-27.38-4.29-57.27-7.61-89.39-9.19-65.68-3.24-124.08,1.58-172.87,9.19Z"
      />
      <path
        style={styles.two}
        d="m324.98,781.61l22.92,16.49c44.71-8.35,100.29-14.78,164.05-13.36,49.02,1.09,92.62,6.6,129.55,13.55,6.69-4.69,13.38-9.38,20.08-14.06-44.41-8.21-97.08-14.74-156.41-15.92-69.34-1.38-130.26,4.88-180.19,13.3Z"
      />
      <path
        style={styles.two}
        d="m289.83,722.8c44.65-9.26,82.04-13.92,110.21-16.58,0,0,84.39-7.97,179.65.13,21.01,1.79,42.17,4.67,66.35,11.09,9.44,2.51,16.72,4.95,22.04,6.75.98,7.57,1.66,15.06,2.64,22.63-45.18-12.14-82.73-17.69-110.3-20.58-74.92-7.87-134.26-2-179.22,2.7-38.65,4.03-69.53,8.99-91.56,13.26.18-6.58,0-12.8.18-19.39Z"
      />
      <path
        style={styles.two}
        d="m294.23,687.11l-2.85,18.82c67.49-12.4,124.95-17.45,169.44-19.54,82.96-3.9,124.02,2.51,151.57,8.61,22.49,4.98,40.33,10.73,53.25,15.29-1.56-7.76-2.56-15.42-4.12-23.18-13.27-3.29-31.48-7.36-53.3-10.89-16.64-2.7-59.35-8.96-124.7-8.35-44.6.42-109.44,4.14-189.29,19.24Z"
      />
      <path
        style={styles.two}
        d="m623.68,626.48c-12.1-2.54-25.13-4.8-39.03-6.58-19.58-2.5-37.84-3.65-54.44-3.95,3.83-6.17,7.66-12.35,11.5-18.52,12.62.97,25.24,1.93,37.86,2.9l44.12,26.14Z"
      />
      <path
        style={styles.two}
        d="m379.79,602c13.27-1.17,26.54-2.35,39.82-3.52l3.34,20.87c-28.83,3.12-57.66,6.24-86.49,9.36,14.45-8.9,28.89-17.8,43.34-26.71Z"
      />
      <path
        style={styles.two}
        d="m460,593.91c3.81,2.46,12.96,7.62,25.74,8.02,12.3.39,21.46-3.83,25.46-5.98-.5,4.42-.99,8.85-1.49,13.27-4.77,2.2-13.73,5.56-25.34,5.36-9.3-.16-16.65-2.55-21.16-4.42-1.07-5.42-2.14-10.84-3.21-16.25Z"
      />
      <path
        style={styles.two}
        d="m304.57,652.06c52.38-14.46,126.77-28.28,215.63-23.25,50.04,2.83,93.83,11.03,129.99,20.46,2.58,6.94,4.74,13.88,7.32,20.82-40.58-9.66-89.22-17.9-145.14-20.35-86.12-3.77-159.23,7.47-213.56,20.35,1.92-6.01,3.84-12.02,5.75-18.03Z"
      />
      <path
        style={styles.three}
        d="m546.66,588.15c6.65,32,12.29,68.38,15.39,108.54,3.92,50.89,2.87,96.72-.35,135.95,5.6-1.6,11.19-3.2,16.79-4.8,3.74-37.58,5.15-83.38,0-135.06-3.76-37.66-10.29-71.26-17.63-100.15-4.73-1.49-9.46-2.99-14.19-4.48Z"
      />
      <path
        style={styles.three}
        d="m407.67,589.08c7.2,30.59,13.49,67.94,15.46,110.74,2.34,50.61-2.01,94.56-7.93,129.37,5.42,1.46,10.84,2.93,16.26,4.39,7.09-36.62,12.7-83.43,10.85-137.67-1.51-44.09-7.59-82.61-14.7-114.14-6.65,2.44-13.29,4.87-19.94,7.31Z"
      />
      <path
        style={styles.three}
        d="m476.15,638.98c-.62-5.92.52-10.49,2.74-11.15,2.6-.77,6.16,3.98,6.85,4.89,2.8,3.73,2.82,6.56,4.7,11.54.96,2.56,2.64,6.3,5.67,10.57.43,2.37-.43,4.72-2.15,5.87-2.38,1.59-6.25.79-8.22-2.35-1.17-.76-3.38-2.43-5.05-5.37-1.76-3.12-2-6.04-2.02-7.44-.84-2.18-1.68-4.37-2.52-6.55Z"
      />
      <path
        style={styles.three}
        d="m484.96,659.91c1.15,3.14,2.46,7.96,2.35,13.89-.06,3.46-.59,6.44-1.2,8.8-.5,1.77-.8,3.47-.95,5.09-1.74,18.79,16.05,33.91,21.13,37.96-.86-12.98.66-19.51,2.35-23.09.43-.9,1.96-3.92,2.54-8.22.39-2.86.23-5.27.03-6.85-.58-4.67-2-11.51-5.7-18.98-3-6.06-6.55-10.59-9.39-13.7.26,2.55-.89,5.01-2.95,6.28-2.51,1.55-5.96,1.1-8.2-1.2Z"
      />
      <path
        style={styles.two}
        d="m439.04,834.96c17.59,3.71,39.5,6.98,66.99,5.76,23.01-1.02,42.72-4.82,58.21-8.95-18.9,1.03-38.23,1.84-57.97,2.4-23.48.67-44.9.83-67.24.78Z"
      />
      <line
        style={styles.four}
        x1="506.28"
        y1="725.65"
        x2="498.18"
        y2="687.11"
      />
      <g>
        <path d="m460.98,525.94s-11.31,49.7-11.31,49.7c0,0-.13.58-.13.58-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74l11.31-49.7s1.95.44,1.95.44h0Z" />
        <path d="m518.91,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59,0,0,1.88.69,1.88.69h0Z" />
      </g>
      <path
        style={styles.three}
        d="m448.92,568.94c-5.58,1.2-15.44,4.28-21.32,12.83-11.64,16.94.68,43.32,13.53,57.01,16.22,17.28,38.01,19.23,44.61,19.57-1.77-2.03-5.91-3.4-6.91-11.61-.16-1.3-.19-1.69-.51-2.68-.69-2.09-1.96-3.57-2.94-4.5-2.1-.68-6.15-2.32-9.39-6.26-6.71-8.15-3.84-18.67-3.52-19.76.73-2.46.99-4.69,1.02-6.57.2-13.05-10.45-22.12-12.95-24.14-.74-1.37-1.78-3.71-2.14-6.8-.35-3.08.13-5.59.54-7.09Z"
      />
      <path
        style={styles.three}
        d="m511.71,563.63c6.45,2.43,10.56,4.91,13.18,6.82,1.02.74,3.42,2.57,7.08,3.74,2.04.65,3.81.9,5.02,1,3.9-.4,7.61,1.47,9.39,4.7,2.12,3.85.48,7.78.27,8.26-1.01,4.76-2.8,10.9-6.14,17.57-4.13,8.25-9.08,14.25-12.91,18.2-2.27,1.04-5.03,2.58-7.83,4.89-5.4,4.44-8.3,9.53-9.78,12.72-.76,1.76-1.98,4-3.91,6.26-3.55,4.14-7.65,6.13-9.98,7.04-3.46-7.37-6.91-14.74-10.37-22.11,6.59-4.5,13.66-10.48,20.15-18.39,1.18-1.44,2.29-2.88,3.33-4.3,1.56-2.39,3.38-6.08,2.93-10.37-.27-2.58-1.18-3.7-1.76-6.46-.55-2.58-.78-6.49,1.04-11.77.09-5.93.19-11.86.28-17.8Z"
      />
      <path d="m528.55,571.9c3.93,11.18,2.58,23.8-3.29,34.01,5.15-10.25,5.86-22.73,1.42-33.31,0,0,1.87-.7,1.87-.7h0Z" />
      <path d="m449.19,569.91c-9.04,2.27-14.88,11.16-12.76,20.39.62,2.97,2.01,5.77,3.91,8.18-6.47-6.93-6.94-18.82.14-25.55,2.28-2.25,5.07-3.94,8.16-4.95,0,0,.55,1.92.55,1.92h0Z" />
      <path d="m428.58,581.99c-3.74,15.23,1.13,32.24,12.71,42.91,3.81,3.62,8.23,6.61,13.05,8.79-4.88-2.03-9.42-4.89-13.39-8.42-12.25-10.61-17.68-27.91-14.33-43.72,0,0,1.95.43,1.95.43h0Z" />
    </g>
  );
};

export default MimeShirt;
