import "../styles/Bandana.css";

const Bandana = () => {
  return (
    <g>
      <g>
        <path
          class="bandana-4"
          d="m454.4,539c-.2,2.02-.32,4.85,0,8.17.39,4.07,1.3,7.2,2.01,9.2.7,3.14,2.19,8.39,5.35,13.28,1.9,2.94,3.84,4.88,5.35,6.13,1.09.77,2.69,1.66,4.68,2.04,1.59.31,2.97.2,4.01,0,.06-1.21.29-3.62,1.34-6.13.91-2.18,2.02-3.45,2.68-4.09-2.44-4.26-4.88-8.51-7.31-12.77-1.23-1.48-2.8-3.11-4.73-4.6-2.57-1.98-4.94-3.07-6.8-3.68-.88-.45-2.03-1.19-3.23-2.45-1.66-1.74-2.72-3.73-3.34-5.11Z"
        />
        <path
          class="bandana-4"
          d="m485.17,573.74c1.6-.4,7.61-1.58,13.38,4.09,8.3,8.15,8.68,23.19,8.69,24.52l2.01,6.13c.18.28.18.74,0,1.02s-.49.28-.67,0c-2.67-.19-7.9-1.13-13.38-6.13-7.05-6.44-10.25-15.72-11.37-19.41.45-3.41.89-6.81,1.34-10.22Z"
        />
        <path
          class="bandana-4"
          d="m479.15,583.95c.74-.34,1.71-.94,2.68-2.04,2.44-2.79,3.14-6.79,3.34-8.17l-2.01,12.26c-.36.08-.82.12-1.34,0-1.37-.3-2.28-1.47-2.68-2.04Z"
        />
        <path
          class="bandana-4"
          d="m479.15,583.95c-1.36.51-2.74-.37-3.34-2.04-.69-1.9-.06-3.9,0-4.09.06-1.21.29-3.62,1.34-6.13.91-2.18,2.02-3.45,2.68-4.09.45-.34.89-.68,1.34-1.02.07.26.43,1.45,1.34,2.04.98.64,1.85.1,2.01,0,.22.22.47.55.67,1.02.72,1.74.07,3.87,0,4.09-.18,1.23-.65,3.73-2.01,6.13-1.49,2.63-3.23,3.69-4.01,4.09Z"
        />
        <path
          class="bandana-4"
          d="m485.17,569.65c2.18-.98,5.15-1.93,8.69-2.04,2.9-.09,5.4.41,7.36,1.02,3.8.34,7.61.68,11.41,1.02,1.44.69,3.01,1.67,4.64,3.07.39.33.76.68,1.12,1.02-.12.32-.26.66-.45,1.02-.11.22-.23.41-.33.58.14.15.87.98,1,2.48.09.98-.12,1.76-.22,2.08.08.71.3,1.9.89,3.03.22.43.46.76.67,1.02.04.17.1.57,0,1.02-.13.58-.5,1-.95,1.1.46.93.58,2.09.28,2.99-.19.56-.49.87-.67,1.02.18.63.36,1.26.54,1.89.03.07.46,1.12.13,2.19-.19.63-.54.93-.67,1.02.04.13.31,1.08,0,2.04-.2.62-.54.92-.67,1.02-1.27-.71-2.6-1.4-4.01-2.04-2.76-1.26-5.36-2.16-7.76-2.81-.54-2.86-1.83-8.04-4.94-12.51-6.34-9.1-14.81-7.43-16.05-7.15v-4.09Z"
        />
        <path
          class="bandana-4"
          d="m475.8,581.91c-1.82.8-3.83,1.51-6.02,2.04-5.95,1.44-11.2,1.04-15.27.14l-10.13.87s-.55-.25-.68-1.01c-.09-.5.04-.92.08-1.03.2-.68.39-1.36.59-2.04-.07-.08-.67-.79-.67-2.04,0-.13.01-1.29.67-2.04-.18-.33-.52-1.02-.67-2.04-.13-.89-.06-1.63,0-2.04.14-.73.27-1.46.41-2.19-.02-.28-.06-1.08.26-1.89.22-.56.51-.87.67-1.02v-2.04l.67-4.09c1.17-.45,2.51-.83,4.01-1.02,3.3-.42,6.1.25,8.13,1.02.75,2.32,4.08,11.96,11.27,15.33,2.61,1.22,4.97,1.26,6.69,1.02v4.09Z"
        />
      </g>
      <path
        class="bandana-6"
        d="m481.59,568.12c-.92,1.36-2.09,3.38-3.12,6.13-1.28,3.43-1.82,6.62-2.07,8.79-.25-.17-.45-.39-.61-.61-1.59-2.32-.07-7.85,0-8.1.42-1.17,1.06-2.66,2.01-4.16,1.08-1.73,2.18-2.86,3.01-3.58l.78,1.53Z"
      />
      <path
        class="bandana-6"
        d="m444.7,567.61c1.69-.33,3.59-.54,5.69-.51,3.27.05,6.14.67,8.48,1.44-2.83-.02-5.9.15-9.15.6-2.09.29-4.07.66-5.96,1.1.31-.88.63-1.75.94-2.63Z"
      />
      <path
        class="bandana-6"
        d="m517.27,575.27c-1.02-.42-2.14-.78-3.34-1.02-4.59-.93-8.54.19-11.31,1.44,2.29-.56,5.85-.96,9.97.6,2.15.81,3.94,1.96,5.35,3.07.02-.31.02-.65,0-1.02-.07-1.35-.4-2.4-.67-3.07Z"
      />
      <path
        class="bandana-6"
        d="m518.61,586.51c-.63-.38-1.3-.73-2.01-1.02-5.11-2.09-9.77-.24-12.65,1.44,3.26-.39,7.09-.36,11.31.6,1.33.3,2.6.68,3.8,1.1-.15-.71-.3-1.41-.45-2.12Z"
      />
      <path
        class="bandana-6"
        d="m444.03,576.8c1.52-.29,3.2-.49,5.02-.51,3.53-.04,6.62.61,9.15,1.44-2.98-.1-6.28.05-9.82.6-1.85.29-3.62.66-5.29,1.1.31-.88.63-1.75.94-2.63Z"
      />
      <path
        class="bandana-6"
        d="m484.2,577.62c1.31.09,4.96.63,8.33,4.81,2.4,2.98,3.56,6.46,4.08,8.34-.93-1.76-2.47-4.22-4.75-6.29-3.25-2.95-6.35-3.6-8.03-3.77.12-1.03.25-2.05.37-3.08Z"
      />
      <path
        class="bandana-5"
        d="m445.23,564.39c1.68-.67,3.89-1.29,6.5-1.38,2.6-.09,4.82.38,6.51.92-.34-.89-.68-1.79-1.02-2.68-1.33-.63-3.22-1.27-5.49-1.31-2.71-.04-4.91.79-6.35,1.53-.05.97-.09,1.94-.14,2.91Z"
      />
      <path
        class="bandana-5"
        d="m484.69,576.65c1.62-.51,3.74-.99,6.25-1.06,2.5-.07,5.97,2.33,7.6,2.75-.33-.69-2.35-2.38-2.68-3.07-1.28-.48-2.74-2.01-4.93-2.04-2.6-.03-4.72.61-6.11,1.18-.05.75-.09,1.49-.14,2.24Z"
      />
      <path
        class="bandana-6"
        d="m454.4,540.53c1.97,1.91,4.32,4.56,6.69,8.17,1.78,2.72,3.18,5.4,4.28,7.78-3.88-4.29-7.76-8.59-11.63-12.88l.67-3.07Z"
      />
      <g>
        <path
          class="bandana-2"
          d="m454.07,539c-.2,2.02-.32,4.85,0,8.17.39,4.07,1.3,7.2,2.01,9.2.7,3.14,2.19,8.39,5.35,13.28,1.9,2.94,3.84,4.88,5.35,6.13,1.09.77,2.69,1.66,4.68,2.04,1.59.31,2.97.2,4.01,0,.06-1.21.29-3.62,1.34-6.13.91-2.18,2.02-3.45,2.68-4.09-2.44-4.26-4.88-8.51-7.31-12.77-1.23-1.48-2.8-3.11-4.73-4.6-2.57-1.98-4.94-3.07-6.8-3.68-.88-.45-2.03-1.19-3.23-2.45-1.66-1.74-2.72-3.73-3.34-5.11Z"
        />
        <path
          class="bandana-2"
          d="m484.83,573.74c1.6-.4,7.61-1.58,13.38,4.09,8.3,8.15,8.68,23.19,8.69,24.52l2.01,6.13c.18.28.18.74,0,1.02s-.49.28-.67,0c-2.67-.19-7.9-1.13-13.38-6.13-7.05-6.44-10.25-15.72-11.37-19.41.45-3.41.89-6.81,1.34-10.22Z"
        />
        <path
          class="bandana-2"
          d="m478.81,583.95c.74-.34,1.71-.94,2.68-2.04,2.44-2.79,3.14-6.79,3.34-8.17l-2.01,12.26c-.36.08-.82.12-1.34,0-1.37-.3-2.28-1.47-2.68-2.04Z"
        />
        <path
          class="bandana-2"
          d="m478.81,583.95c-1.36.51-2.74-.37-3.34-2.04-.69-1.9-.06-3.9,0-4.09.06-1.21.29-3.62,1.34-6.13.91-2.18,2.02-3.45,2.68-4.09.45-.34.89-.68,1.34-1.02.07.26.43,1.45,1.34,2.04.98.64,1.85.1,2.01,0,.22.22.47.55.67,1.02.72,1.74.07,3.87,0,4.09-.18,1.23-.65,3.73-2.01,6.13-1.49,2.63-3.23,3.69-4.01,4.09Z"
        />
        <path
          class="bandana-2"
          d="m484.83,569.65c2.18-.98,5.15-1.93,8.69-2.04,2.9-.09,5.4.41,7.36,1.02,3.8.34,7.61.68,11.41,1.02,1.44.69,3.01,1.67,4.64,3.07.39.33.76.68,1.12,1.02-.12.32-.26.66-.45,1.02-.11.22-.23.41-.33.58.14.15.87.98,1,2.48.09.98-.12,1.76-.22,2.08.08.71.3,1.9.89,3.03.22.43.46.76.67,1.02.04.17.1.57,0,1.02-.13.58-.5,1-.95,1.1.46.93.58,2.09.28,2.99-.19.56-.49.87-.67,1.02.18.63.36,1.26.54,1.89.03.07.46,1.12.13,2.19-.19.63-.54.93-.67,1.02.04.13.31,1.08,0,2.04-.2.62-.54.92-.67,1.02-1.27-.71-2.6-1.4-4.01-2.04-2.76-1.26-5.36-2.16-7.76-2.81-.54-2.86-1.83-8.04-4.94-12.51-6.34-9.1-14.81-7.43-16.05-7.15v-4.09Z"
        />
        <path
          class="bandana-2"
          d="m475.47,581.91c-1.82.8-3.83,1.51-6.02,2.04-5.95,1.44-11.2,1.04-15.27.14l-10.13.87s-.55-.25-.68-1.01c-.09-.5.04-.92.08-1.03.2-.68.39-1.36.59-2.04-.07-.08-.67-.79-.67-2.04,0-.13.01-1.29.67-2.04-.18-.33-.52-1.02-.67-2.04-.13-.89-.06-1.63,0-2.04.14-.73.27-1.46.41-2.19-.02-.28-.06-1.08.26-1.89.22-.56.51-.87.67-1.02v-2.04l.67-4.09c1.17-.45,2.51-.83,4.01-1.02,3.3-.42,6.1.25,8.13,1.02.75,2.32,4.08,11.96,11.27,15.33,2.61,1.22,4.97,1.26,6.69,1.02v4.09Z"
        />
      </g>
      <path
        class="bandana-3"
        d="m464.43,554.84c.19,1.09,1.01,5.26,3.68,7.66,1.14,1.03,2.25,1.39,3.01,1.53.17-.21.82-1.05,1-2.55.19-1.51-.22-2.67-.33-2.95-.53-.39-1.09-.77-1.67-1.13-2.03-1.26-3.96-2.06-5.69-2.55Z"
      />
      <path
        class="bandana-3"
        d="m467.11,557.56c.09.52.49,2.53,1.77,3.69.55.5,1.08.67,1.45.74.08-.1.39-.51.48-1.23.09-.73-.11-1.28-.16-1.42-.26-.19-.52-.37-.81-.55-.98-.61-1.91-.99-2.74-1.23Z"
      />
      <g>
        <path
          class="bandana-3"
          d="m496.49,585.65c-.67.46-3.31,2.11-6.21.46-1.24-.71-2.13-1.79-2.67-2.61,0-.33.02-1.63.67-2.8.65-1.17,1.5-1.41,1.71-1.46.53.38,1.08.81,1.63,1.27,1.92,1.62,3.54,3.41,4.88,5.14Z"
        />
        <path
          class="bandana-3"
          d="m493.42,584.19c-.32.22-1.59,1.02-2.99.22-.6-.34-1.02-.86-1.29-1.26,0-.16,0-.79.32-1.35.31-.57.72-.68.82-.7.26.18.52.39.78.61.93.78,1.7,1.64,2.35,2.48Z"
        />
      </g>
      <g>
        <g>
          <path
            class="bandana-3"
            d="m492.51,593.36c-.12.29-.59,1.4-1.5,1.75-.39.15-.73.11-.96.05-.04-.08-.18-.41-.15-.87.03-.46.22-.75.26-.82.18-.04.36-.08.55-.11.66-.1,1.27-.08,1.8,0Z"
          />
          <path
            class="bandana-3"
            d="m491.58,593.8c-.06.14-.28.67-.72.84-.19.07-.35.05-.46.02-.02-.04-.09-.2-.07-.42.01-.22.1-.36.13-.39.08-.02.17-.04.26-.05.32-.05.61-.04.87,0Z"
          />
        </g>
        <g>
          <path
            class="bandana-3"
            d="m491.97,597.47c.22.08,1.07.33,1.87-.43.34-.32.56-.73.69-1.02-.01-.1-.07-.48-.31-.77-.24-.29-.5-.28-.57-.28-.14.16-.29.34-.44.53-.51.66-.92,1.33-1.25,1.97Z"
          />
          <path
            class="bandana-3"
            d="m492.83,596.76c.11.04.51.16.9-.21.16-.16.27-.35.33-.49,0-.05-.03-.23-.15-.37-.12-.14-.24-.14-.27-.13-.07.08-.14.16-.21.25-.25.32-.44.64-.6.95Z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="bandana-3"
            d="m497.86,598.47c-.12.29-.59,1.4-1.5,1.75-.39.15-.73.11-.96.05-.04-.08-.18-.41-.15-.87.03-.46.22-.75.26-.82.18-.04.36-.08.55-.11.66-.1,1.27-.08,1.8,0Z"
          />
          <path
            class="bandana-3"
            d="m496.93,598.91c-.06.14-.28.67-.72.84-.19.07-.35.05-.46.02-.02-.04-.09-.2-.07-.42.01-.22.1-.36.13-.39.08-.02.17-.04.26-.05.32-.05.61-.04.87,0Z"
          />
        </g>
        <g>
          <path
            class="bandana-3"
            d="m497.32,602.58c.22.08,1.07.33,1.87-.43.34-.32.56-.73.69-1.02-.01-.1-.07-.48-.31-.77-.24-.29-.5-.28-.57-.28-.14.16-.29.34-.44.53-.51.66-.92,1.33-1.25,1.97Z"
          />
          <path
            class="bandana-3"
            d="m498.18,601.87c.11.04.51.16.9-.21.16-.16.27-.35.33-.49,0-.05-.03-.23-.15-.37-.12-.14-.24-.14-.27-.13-.07.08-.14.16-.21.25-.25.32-.44.64-.6.95Z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="bandana-3"
            d="m515.25,590.3c-.12.29-.59,1.4-1.5,1.75-.39.15-.73.11-.96.05-.04-.08-.18-.41-.15-.87.03-.46.22-.75.26-.82.18-.04.36-.08.55-.11.66-.1,1.27-.08,1.8,0Z"
          />
          <path
            class="bandana-3"
            d="m514.32,590.74c-.06.14-.28.67-.72.84-.19.07-.35.05-.46.02-.02-.04-.09-.2-.07-.42.01-.22.1-.36.13-.39.08-.02.17-.04.26-.05.32-.05.61-.04.87,0Z"
          />
        </g>
        <g>
          <path
            class="bandana-3"
            d="m514.71,594.41c.22.08,1.07.33,1.87-.43.34-.32.56-.73.69-1.02-.01-.1-.07-.48-.31-.77-.24-.29-.5-.28-.57-.28-.14.16-.29.34-.44.53-.51.66-.92,1.33-1.25,1.97Z"
          />
          <path
            class="bandana-3"
            d="m515.57,593.69c.11.04.51.16.9-.21.16-.16.27-.35.33-.49,0-.05-.03-.23-.15-.37-.12-.14-.24-.14-.27-.13-.07.08-.14.16-.21.25-.25.32-.44.64-.6.95Z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="bandana-3"
            d="m502.91,602.95c0,.31-.06,1.49-.66,2.32-.26.36-.53.53-.72.62-.05-.04-.25-.23-.35-.62-.1-.4-.04-.74-.02-.82.12-.14.25-.28.39-.42.48-.48.94-.82,1.37-1.08Z"
          />
          <path
            class="bandana-3"
            d="m502.32,603.87c0,.15-.03.72-.32,1.12-.12.17-.25.25-.35.3-.02-.02-.12-.11-.17-.3-.05-.19-.02-.35-.01-.39.06-.07.12-.14.19-.2.23-.23.45-.4.66-.52Z"
          />
        </g>
        <g>
          <path
            class="bandana-3"
            d="m503.62,606.61c.19-.07.9-.37,1.3-1.47.17-.47.23-.93.25-1.24-.04-.07-.19-.35-.45-.44-.26-.09-.46.07-.51.11-.06.22-.13.45-.19.69-.21.84-.33,1.64-.41,2.35Z"
          />
          <path
            class="bandana-3"
            d="m504.08,605.52c.09-.03.43-.18.63-.71.08-.23.11-.45.12-.6-.02-.04-.09-.17-.21-.21-.13-.04-.22.03-.24.05-.03.1-.06.22-.09.33-.1.4-.16.79-.2,1.13Z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="bandana-3"
            d="m488.23,587.22c-.09.23-.47,1.12-1.2,1.4-.31.12-.59.09-.77.04-.03-.07-.14-.33-.12-.7.02-.37.17-.6.21-.65.14-.04.29-.06.44-.09.53-.08,1.02-.06,1.44,0Z"
          />
          <path
            class="bandana-3"
            d="m487.48,587.57c-.05.11-.23.54-.58.67-.15.06-.28.04-.37.02-.01-.03-.07-.16-.06-.34.01-.18.08-.29.1-.31.07-.02.14-.03.21-.04.26-.04.49-.03.69,0Z"
          />
        </g>
        <g>
          <path
            class="bandana-3"
            d="m487.79,590.51c.17.06.86.26,1.5-.34.27-.26.45-.58.56-.82-.01-.08-.06-.39-.25-.62-.19-.23-.4-.23-.45-.22-.11.13-.23.27-.35.42-.41.53-.74,1.07-1,1.58Z"
          />
          <path
            class="bandana-3"
            d="m488.48,589.94c.08.03.41.13.72-.16.13-.12.22-.28.27-.39,0-.04-.03-.19-.12-.3-.09-.11-.19-.11-.22-.11-.06.06-.11.13-.17.2-.2.25-.36.51-.48.76Z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="bandana-3"
            d="m456.72,579.19c.02.27.1,1.32-.35,2.25-.19.4-.42.64-.58.78-.05-.02-.25-.12-.38-.43-.13-.31-.11-.63-.11-.71.09-.16.2-.33.31-.49.38-.57.76-1.03,1.12-1.39Z"
          />
          <path
            class="bandana-3"
            d="m456.29,580.18c.01.13.05.64-.17,1.08-.09.19-.2.31-.28.37-.02-.01-.12-.06-.18-.21-.06-.15-.05-.3-.05-.34.05-.08.09-.16.15-.24.18-.28.37-.5.54-.67Z"
          />
        </g>
        <g>
          <path
            class="bandana-3"
            d="m457.75,582.16c.16-.12.77-.62,1.02-1.71.11-.47.11-.89.09-1.17-.04-.05-.2-.25-.45-.24-.25,0-.41.21-.45.26-.04.21-.07.43-.1.66-.1.8-.13,1.54-.13,2.19Z"
          />
          <path
            class="bandana-3"
            d="m458.04,581.06c.08-.06.37-.3.49-.82.05-.22.05-.43.05-.56-.02-.03-.1-.12-.22-.12-.12,0-.2.1-.21.13-.02.1-.03.21-.05.32-.05.39-.06.74-.06,1.06Z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="bandana-3"
            d="m461.41,579.19c.02.27.1,1.32-.35,2.25-.19.4-.42.64-.58.78-.05-.02-.25-.12-.38-.43-.13-.31-.11-.63-.11-.71.09-.16.2-.33.31-.49.38-.57.76-1.03,1.12-1.39Z"
          />
          <path
            class="bandana-3"
            d="m460.97,580.18c.01.13.05.64-.17,1.08-.09.19-.2.31-.28.37-.02-.01-.12-.06-.18-.21-.06-.15-.05-.3-.05-.34.05-.08.09-.16.15-.24.18-.28.37-.5.54-.67Z"
          />
        </g>
        <g>
          <path
            class="bandana-3"
            d="m462.43,582.16c.16-.12.77-.62,1.02-1.71.11-.47.11-.89.09-1.17-.04-.05-.2-.25-.45-.24-.25,0-.41.21-.45.26-.04.21-.07.43-.1.66-.1.8-.13,1.54-.13,2.19Z"
          />
          <path
            class="bandana-3"
            d="m462.73,581.06c.08-.06.37-.3.49-.82.05-.22.05-.43.05-.56-.02-.03-.1-.12-.22-.12-.12,0-.2.1-.21.13-.02.1-.03.21-.05.32-.05.39-.06.74-.06,1.06Z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="bandana-3"
            d="m467.42,579.19c.02.27.1,1.32-.35,2.25-.19.4-.42.64-.58.78-.05-.02-.25-.12-.38-.43-.13-.31-.11-.63-.11-.71.09-.16.2-.33.31-.49.38-.57.76-1.03,1.12-1.39Z"
          />
          <path
            class="bandana-3"
            d="m466.99,580.18c.01.13.05.64-.17,1.08-.09.19-.2.31-.28.37-.02-.01-.12-.06-.18-.21-.06-.15-.05-.3-.05-.34.05-.08.09-.16.15-.24.18-.28.37-.5.54-.67Z"
          />
        </g>
        <g>
          <path
            class="bandana-3"
            d="m468.45,582.16c.16-.12.77-.62,1.02-1.71.11-.47.11-.89.09-1.17-.04-.05-.2-.25-.45-.24-.25,0-.41.21-.45.26-.04.21-.07.43-.1.66-.1.8-.13,1.54-.13,2.19Z"
          />
          <path
            class="bandana-3"
            d="m468.75,581.06c.08-.06.37-.3.49-.82.05-.22.05-.43.05-.56-.02-.03-.1-.12-.22-.12-.12,0-.2.1-.21.13-.02.1-.03.21-.05.32-.05.39-.06.74-.06,1.06Z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="bandana-3"
            d="m457.01,546.7c-.14.18-.68.84-1.43.75-.32-.04-.58-.2-.74-.33-.02-.08-.07-.38.03-.72.1-.34.29-.48.34-.52.14.03.29.08.44.13.52.18.98.43,1.37.7Z"
          />
          <path
            class="bandana-3"
            d="m456.23,546.68c-.07.09-.33.4-.69.36-.16-.02-.28-.1-.36-.16,0-.04-.03-.18.02-.35.05-.16.14-.23.16-.25.07.02.14.04.21.06.25.09.47.21.66.34Z"
          />
        </g>
        <g>
          <path
            class="bandana-3"
            d="m455.92,549.62c.15.14.76.66,1.49.4.31-.11.55-.34.7-.51,0-.08.03-.4-.11-.71-.13-.31-.33-.41-.38-.43-.14.07-.28.15-.42.23-.5.3-.92.66-1.28,1.02Z"
          />
          <path
            class="bandana-3"
            d="m456.69,549.4c.07.07.36.32.72.19.15-.05.26-.16.34-.24,0-.04.01-.19-.05-.34-.06-.15-.16-.2-.19-.21-.07.03-.13.07-.2.11-.24.15-.44.32-.62.49Z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="bandana-3"
            d="m459.02,554.87c-.14.18-.68.84-1.43.75-.32-.04-.58-.2-.74-.33-.02-.08-.07-.38.03-.72.1-.34.29-.48.34-.52.14.03.29.08.44.13.52.18.98.43,1.37.7Z"
          />
          <path
            class="bandana-3"
            d="m458.24,554.85c-.07.09-.33.4-.69.36-.16-.02-.28-.1-.36-.16,0-.04-.03-.18.02-.35.05-.16.14-.23.16-.25.07.02.14.04.21.06.25.09.47.21.66.34Z"
          />
        </g>
        <g>
          <path
            class="bandana-3"
            d="m457.93,557.79c.15.14.76.66,1.49.4.31-.11.55-.34.7-.51,0-.08.03-.4-.11-.71-.13-.31-.33-.41-.38-.43-.14.07-.28.15-.42.23-.5.3-.92.66-1.28,1.02Z"
          />
          <path
            class="bandana-3"
            d="m458.7,557.58c.07.07.36.32.72.19.15-.05.26-.16.34-.24,0-.04.01-.19-.05-.34-.06-.15-.16-.2-.19-.21-.07.03-.13.07-.2.11-.24.15-.44.32-.62.49Z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="bandana-3"
            d="m461.03,561c-.14.18-.68.84-1.43.75-.32-.04-.58-.2-.74-.33-.02-.08-.07-.38.03-.72.1-.34.29-.48.34-.52.14.03.29.08.44.13.52.18.98.43,1.37.7Z"
          />
          <path
            class="bandana-3"
            d="m460.24,560.98c-.07.09-.33.4-.69.36-.16-.02-.28-.1-.36-.16,0-.04-.03-.18.02-.35.05-.16.14-.23.16-.25.07.02.14.04.21.06.25.09.47.21.66.34Z"
          />
        </g>
        <g>
          <path
            class="bandana-3"
            d="m459.93,563.92c.15.14.76.66,1.49.4.31-.11.55-.34.7-.51,0-.08.03-.4-.11-.71-.13-.31-.33-.41-.38-.43-.14.07-.28.15-.42.23-.5.3-.92.66-1.28,1.02Z"
          />
          <path
            class="bandana-3"
            d="m460.7,563.71c.07.07.36.32.72.19.15-.05.26-.16.34-.24,0-.04.01-.19-.05-.34-.06-.15-.16-.2-.19-.21-.07.03-.13.07-.2.11-.24.15-.44.32-.62.49Z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="bandana-3"
            d="m464.5,566.88c-.05.26-.28,1.26-.94,1.81-.28.23-.56.31-.74.33-.04-.05-.19-.27-.23-.63-.04-.37.07-.65.1-.71.13-.09.27-.17.41-.25.5-.28.98-.44,1.4-.54Z"
          />
          <path
            class="bandana-3"
            d="m463.83,567.51c-.03.13-.13.61-.45.87-.14.11-.27.15-.36.16-.02-.03-.09-.13-.11-.31-.02-.18.03-.31.05-.34.06-.04.13-.08.2-.12.24-.13.47-.21.67-.26Z"
          />
        </g>
        <g>
          <path
            class="bandana-3"
            d="m464.61,570.24c.18,0,.87-.07,1.39-.9.22-.36.34-.73.41-1-.02-.07-.12-.36-.34-.51-.22-.15-.43-.07-.48-.05-.09.17-.18.35-.27.54-.31.66-.54,1.32-.72,1.91Z"
          />
          <path
            class="bandana-3"
            d="m465.18,569.43c.09,0,.42-.03.67-.43.11-.17.17-.35.2-.48-.01-.04-.06-.17-.16-.24-.11-.07-.21-.03-.23-.02-.04.08-.09.17-.13.26-.15.32-.26.63-.35.92Z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="bandana-3"
            d="m468.51,570.97c-.05.26-.28,1.26-.94,1.81-.28.23-.56.31-.74.33-.04-.05-.19-.27-.23-.63-.04-.37.07-.65.1-.71.13-.09.27-.17.41-.25.5-.28.98-.44,1.4-.54Z"
          />
          <path
            class="bandana-3"
            d="m467.85,571.59c-.03.13-.13.61-.45.87-.14.11-.27.15-.36.16-.02-.03-.09-.13-.11-.31-.02-.18.03-.31.05-.34.06-.04.13-.08.2-.12.24-.13.47-.21.67-.26Z"
          />
        </g>
        <g>
          <path
            class="bandana-3"
            d="m468.62,574.32c.18,0,.87-.07,1.39-.9.22-.36.34-.73.41-1-.02-.07-.12-.36-.34-.51-.22-.15-.43-.07-.48-.05-.09.17-.18.35-.27.54-.31.66-.54,1.32-.72,1.91Z"
          />
          <path
            class="bandana-3"
            d="m469.2,573.51c.09,0,.42-.03.67-.43.11-.17.17-.35.2-.48-.01-.04-.06-.17-.16-.24-.11-.07-.21-.03-.23-.02-.04.08-.09.17-.13.26-.15.32-.26.63-.35.92Z"
          />
        </g>
      </g>
      <g>
        <path
          class="bandana-3"
          d="m452.2,564.89c-.21-.02-.97-.04-1.5.76-.68,1.02-.74,2.97,0,4.58.46.99.96,2.01,1.5,3.06.42.81.84,1.57,1.25,2.29.52,2.15.3,4.38-.5,5.35-1.07,1.31-3.48.67-4.75-2.67-.21-1.26-.41-3.12-.25-5.35.27-3.77,1.4-6.42,2-7.64.1-.14.88-1.14,1.75-.76.2.09.37.23.5.38Z"
        />
        <path
          class="bandana-3"
          d="m452.45,576.35c.46.99.44,2.27,0,3.06-.56,1-1.53.7-2,.38-.74-.5-1.22-1.69-1.25-3.06-.15-1.05-.26-2.34-.25-3.82.03-3,.56-5.38,1-6.88-.16.63-.51,2.22-.25,4.2.23,1.75.82,2.92,1.11,3.43.55.89,1.09,1.79,1.64,2.68Z"
        />
        <path
          class="bandana-3"
          d="m449.7,572.53c.06,1.07.24,2.58.75,4.2.24.75.5,1.39.75,1.91.2.03.4-.12.5-.38.14-.37,0-.74,0-.76-.24-.31-.5-.69-.75-1.15-.76-1.39-1.09-2.84-1.25-3.82Z"
        />
      </g>
      <g>
        <path
          class="bandana-3"
          d="m508.95,574.08c.21-.02.97-.04,1.5.76.68,1.02.74,2.97,0,4.58-.46.99-.96,2.01-1.5,3.06-.42.81-.84,1.57-1.25,2.29-.52,2.15-.3,4.38.5,5.35,1.07,1.31,3.48.67,4.75-2.67.21-1.26.41-3.12.25-5.35-.27-3.77-1.4-6.42-2-7.64-.1-.14-.88-1.14-1.75-.76-.2.09-.37.23-.5.38Z"
        />
        <path
          class="bandana-3"
          d="m508.7,585.54c-.46.99-.44,2.27,0,3.06.56,1,1.53.7,2,.38.74-.5,1.22-1.69,1.25-3.06.15-1.05.26-2.34.25-3.82-.03-3-.56-5.38-1-6.88.16.63.51,2.22.25,4.2-.23,1.75-.82,2.92-1.11,3.43-.55.89-1.09,1.79-1.64,2.68Z"
        />
        <path
          class="bandana-3"
          d="m511.45,581.72c-.06,1.07-.24,2.58-.75,4.2-.24.75-.5,1.39-.75,1.91-.2.03-.4-.12-.5-.38-.14-.37,0-.74,0-.76.24-.31.5-.69.75-1.15.76-1.39,1.09-2.84,1.25-3.82Z"
        />
      </g>
      <ellipse class="bandana-1" cx="487.17" cy="570.16" rx=".33" ry=".51" />
      <ellipse class="bandana-1" cx="488.84" cy="569.65" rx=".33" ry=".51" />
      <ellipse class="bandana-1" cx="490.85" cy="569.65" rx=".33" ry=".51" />
      <ellipse class="bandana-1" cx="475.59" cy="563.96" rx=".33" ry=".51" />
      <ellipse class="bandana-1" cx="476.98" cy="565.46" rx=".33" ry=".51" />
      <ellipse class="bandana-1" cx="477.47" cy="567.61" rx=".33" ry=".51" />
      <path
        class="bandana-6"
        d="m483.16,570.05c0,.92-.08,3.62-1.34,6.25-1.2,2.51-2.77,3.47-3.34,3.77.77-.66,2.21-2.1,3.34-4.79.86-2.05,1.19-4,1.34-5.22Z"
      />
    </g>
  );
};

export default Bandana;
