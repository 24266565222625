import "../styles/Goose.css";

const Goose = () => {
  return (
    <g>
      <g id="Outline">
        <g>
          <path d="m460.98,525.94l-11.31,49.7-.13.58c-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74l11.31-49.7,1.95.44h0Z" />
          <path d="m518.91,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59l1.88.69h0Z" />
        </g>
      </g>
      <g id="Canada_Goose" data-name="Canada Goose">
        <path d="m450.27,570.05c-.01,1.33-.14,2.64-.28,3.95l-.12.98c-.03.33-.12.72-.22,1.06-.2.71-.51,1.4-.93,2.02-.81,1.27-2.06,2.17-3.12,2.89-.52.36-.86.67-1.21,1.11-.31.44-.6.92-.79,1.46-.41,1.07-.59,2.29-.62,3.5-.02.61,0,1.22.06,1.85.05.59.15,1.15.32,1.7.69,2.22,2.35,4.17,4.18,5.87,1.85,1.71,3.95,3.2,6.12,4.54,2.15,1.38,4.47,2.48,6.82,3.51,4.73,1.97,9.73,3.33,14.8,4.05,1.25.18,2.58.33,3.76.38,1.29,0,2.58.03,3.86,0,2.56-.12,5.11-.35,7.63-.82,5.03-.91,9.97-2.48,14.48-4.86,2.25-1.18,4.45-2.51,6.39-4.11.49-.39.94-.83,1.41-1.24.45-.43.86-.89,1.29-1.33l.55-.71c.2-.23.37-.47.5-.72.26-.42.66-1.15.94-1.65.57-1.05.89-2.1.81-3.18-.05-1.09-.45-2.17-1.07-3.16-1.25-1.99-3.27-3.61-5.41-4.86l.98-1.74c1.2.67,2.33,1.44,3.39,2.33,1.06.89,2.04,1.91,2.83,3.13.78,1.21,1.35,2.65,1.44,4.18.14,1.54-.35,3.09-1.02,4.33-.35.65-.58,1.07-.99,1.77-.18.33-.38.62-.6.89l-.65.84c-.47.5-.93,1.03-1.43,1.5-.51.45-1,.93-1.53,1.36-2.09,1.74-4.38,3.17-6.75,4.42-4.75,2.51-9.9,4.14-15.15,5.1-2.63.49-5.29.73-7.96.86-1.33.04-2.66,0-3.98.01-1.44-.06-2.71-.21-4.05-.4-10.51-1.56-20.84-5.62-28.88-12.86-1.94-1.86-3.84-4.02-4.72-6.84-.21-.7-.34-1.44-.39-2.15-.05-.68-.07-1.38-.05-2.07.05-1.39.25-2.8.79-4.18.25-.69.63-1.35,1.09-1.96.45-.6,1.11-1.16,1.67-1.53,1.1-.73,2.01-1.41,2.67-2.33.32-.46.61-.95.82-1.48.11-.28.18-.52.27-.84l.26-.95c.37-1.26.76-2.51,1.26-3.75l.54.11Z" />
        <g>
          <path d="m289.61,748.67c-.91-1.03-1.83-2.05-2.71-3.1-.16-.19-.28-.51-.27-.76.05-2.47.11-4.94.2-7.41.05-1.47.14-2.94.27-4.41.38-4.26.69-8.53,1.2-12.78.63-5.25,1.33-10.5,2.18-15.72.92-5.63,2.04-11.23,3.07-16.84.73-3.99,1.22-8.03,2.23-11.95,1.74-6.77,3.67-13.51,5.8-20.17.76-2.37,2.14-4.61,3.59-6.66,1.05-1.49,2.62-2.65,4.07-3.82,6.08-4.88,12.09-9.86,18.34-14.52,4.77-3.55,9.8-6.77,14.82-9.97,13.26-8.43,27.33-15.36,41.35-22.38,13.87-6.94,28.29-12.49,43.1-17.07,6.81-2.11,13.66-4.07,20.67-5.5-.25,1.22-.39,1.42-1.42,1.69-13.06,3.43-25.64,8.26-38.17,13.21-8.35,3.3-16.39,7.25-24.35,11.37-6.67,3.45-13.39,6.81-19.95,10.47-6.61,3.68-13.14,7.53-19.55,11.57-8.21,5.17-16.25,10.61-24.38,15.91-4.23,2.76-8.05,6.02-11.92,9.25-1.81,1.51-2.89,3.25-3.44,5.46-1.94,7.73-3.97,15.45-5.84,23.2-1.14,4.69-2.15,9.42-3.07,14.16-.85,4.41-1.53,8.86-2.21,13.3-.68,4.45-1.32,8.9-1.89,13.37-.27,2.12-.34,4.26-.52,6.39-.31,3.75-.63,7.5-.94,11.25-.05.56,0,1.12-.02,1.68-.07,2.35-.19,4.71-.22,7.06-.01,1.19.13,2.38.21,3.57-.08.05-.15.1-.23.16Z" />
          <path d="m671.16,776.11c0-4.67.12-9.14-.03-13.6-.17-4.96-.57-9.92-.92-14.88-.2-2.89-.43-5.77-.74-8.65-.31-2.86-.75-5.71-1.14-8.57-.48-3.48-.94-6.96-1.49-10.43-.6-3.77-1.25-7.53-1.97-11.28-1.13-5.83-2.24-11.66-3.55-17.45-1.76-7.74-3.63-15.46-5.61-23.15-1.27-4.93-3.11-9.67-5.69-14.09-.28-.47-.65-.91-1.05-1.29-2.43-2.31-4.84-4.63-7.32-6.88-3.68-3.35-7.31-6.75-11.14-9.92-5.38-4.47-10.81-8.9-16.42-13.08-11.22-8.36-23.42-15.08-36.09-20.98-9.9-4.61-20-8.7-30.39-12.06-7.93-2.57-16.05-4.38-24.18-6.14-4.02-.87-8.05-1.74-12.06-2.64-.64-.14-1.24-.44-1.85-.66l.06-.31c2.3.29,4.62.48,6.9.9,4.7.87,9.37,1.93,14.07,2.79,7,1.29,13.88,3.04,20.66,5.17,8.46,2.65,16.72,5.82,24.85,9.37,9.84,4.3,19.41,9.09,28.57,14.7,7.87,4.82,15.45,10.07,22.57,15.95,4.99,4.12,9.86,8.38,14.76,12.61,2.38,2.06,4.85,4.05,6.96,6.36,1.57,1.73,3.02,3.79,3.84,5.96,4.11,10.81,7.26,21.92,9.88,33.18,1.93,8.31,3.77,16.64,5.47,25,1.07,5.28,1.85,10.63,2.69,15.96.7,4.44,1.34,8.9,1.89,13.36.36,2.91.59,5.84.74,8.76.37,7.24.63,14.48.99,21.71.05,1.07-.28,1.82-1.08,2.46-.68.54-1.33,1.11-2.17,1.81Z" />
        </g>
        <path
          class="goose-10"
          d="m673.05,774.99c-10.77,9.78-27.1,22.99-49.05,35.01-69.26,37.93-137.84,33.1-167,29-52.73-7.41-89.83-28.31-104-37-30.34-18.6-51.24-39.78-64.22-55.07-.07-18.76,1.61-41.2,7.22-65.93,2.37-10.43,5.16-20.08,8.15-28.91,22.39-19.49,54.11-42.4,95.85-60.09,15.89-6.73,31.04-11.78,45.03-15.6,1.23-.34,2.45-.66,3.66-.98-.61.69-1.22,1.38-1.82,2.07-.37.41-.73.82-1.09,1.23,0,0,0,.01-.01.01-.82.92-1.63,1.85-2.44,2.77-.45.5-.89,1-1.33,1.5-.35,1.67-.65,4.18,0,7,1.6,6.94,7.87,11.11,13.37,13.86-.28.28-.56.57-.83.86-.64.66-1.25,1.36-1.86,2.08-.07.08-.14.16-.21.25-19.01,22.9-6.45,51.86-4.96,55.15l.49-.15c.08-.8.46-1.57,1.09-2.19.3-.3.65-.56,1.07-.76,1.42-.73,3.14-.7,4.49-.04.1.05.19.1.28.16-2.06-4.7-10.08-25.61,3.48-44.54,1.3-1.81,2.69-3.45,4.12-4.94.57-.59,1.14-1.16,1.72-1.7.1-.1.21-.19.31-.28,11.02,3.65,20.39,2.51,22.44,2.24,2.67-.36,5.47-.96,8.25-1.76.01,0,.03,0,.04-.01.6-.17,1.21-.35,1.81-.55.31-.09.61-.19.92-.3.02,0,.04-.01.06-.02.51-.17,1.03-.35,1.54-.54.19-.06.37-.13.56-.2.91-.34,1.81-.7,2.69-1.09l.02.02c.96.77,1.91,1.6,2.86,2.49.58.54,1.15,1.11,1.72,1.7,1.43,1.49,2.82,3.13,4.12,4.94,13.56,18.93,5.54,39.84,3.48,44.54.09-.06.18-.11.28-.16,1.35-.66,3.07-.69,4.49.04.42.2.77.46,1.07.76.63.62,1.01,1.39,1.09,2.19l.49.15c1.49-3.29,14.05-32.25-4.96-55.15-.07-.09-.14-.17-.21-.25-.61-.72-1.22-1.42-1.86-2.08-1.3-1.38-2.65-2.65-4.03-3.81,4.71-3.28,7.78-7.08,7.57-10.91-.11-1.89-1.1-5.21-7.11-9.07,23.67,3.14,61.63,11.59,100.11,37.07,17.07,11.3,30.5,23.47,40.79,34.4,5.36,17.51,10.44,37.82,14.21,60.6,3.77,22.8,5.49,43.67,6.05,61.99Z"
        />
        <path
          class="goose-4"
          d="m681,324c-8.4-20.56-18.96-36.52-28-48-2.16-3.35-53.76-80.27-146-76-84.15,3.89-128.22,71.93-132,78-8.62,10.33-20.49,25.87-32.12,46.25-20.69,36.22-37.31,65.33-34.88,98.75,3.44,47.34,43.48,81.13,86,117,19.56,16.5,37.61,28.38,51.03,36.4.62.37,1.24.73,1.84,1.09.09.05.17.1.26.15-.46.34-.91.7-1.35,1.08,0,0,0,.01-.01.01-.93.82-1.78,1.74-2.44,2.77-.69,1.06-1.17,2.22-1.33,3.5-.71,5.69,5.37,12.69,14.41,17.84,2.88-2.73,5.91-4.97,8.85-6.79,2.12-1.32,4.2-2.43,6.12-3.35,1.94-.84,4.31-.53,5.85.77,1.58,1.32,1.98,3.38.99,5.09-3.27,1.58-7.78,4.1-12.2,7.9-.19.16-.38.32-.57.5,1.59.56,3.23,1.06,4.89,1.48,4.44,1.13,9.08,1.72,13.66,1.56.49-.02.99-.04,1.49-.08,3.21-.22,6.53-.8,9.76-1.68.01,0,.03,0,.04-.01.72-.19,1.44-.4,2.15-.62.93-.29,1.84-.61,2.73-.94.93-.35,1.84-.72,2.72-1.12l-.02-.02c-.74-.6-1.49-1.17-2.23-1.7-3.28-2.38-6.41-4.09-8.86-5.27-.99-1.71-.59-3.77.99-5.09,1.54-1.3,3.91-1.61,5.85-.77,1.14.55,2.34,1.16,3.56,1.85,2.89,1.6,5.96,3.61,8.95,6.1,1.3-.69,5.67-3.22,7.87-8.65,1.58-3.91,1.25-7.4,1-9,15.34-1.27,77.02-8.15,124.83-60,63.2-68.54,48.06-156.99,46.17-167-2.12-9.54-5.26-20.39-10-32Zm-196.93,285.39c.11-.96.47-3.89.55-4.51-.16,1.8-.37,3.32-.55,4.51Zm9.13-44.6c-4.11,8.72-5.42,17.53-7.86,34.8-.2,1.44-1.18,9.17-1.34,10.41,2.77-21.92,6.11-50.09-3-66-2.82-4.92-9.75-14.51-27-21-9.91-4.55-23.89-12.25-38-25-8.91-8.05-28.5-26.18-39-57-4.65-13.64-13.5-39.62-3.06-68.18,7.25-19.82,20.78-30.91,29.48-39.48,24.01-23.62,52.27-28.4,66.19-31.24,32.94-6.71,59.07-.95,71.39,1.9,25.11,5.82,56.02,12.98,77,41,19.22,25.67,19.15,54.2,19,63-.89,53.44-41.68,86.6-48.13,91.68-10.07,10.89-19.65,16.85-26.21,20.2-14.99,7.67-24.64,7.11-40.66,16.12-9.21,5.19-15.79,10.87-19.65,14.54-4.79,5.79-7.56,10.88-9.15,14.25Z"
        />
        <path
          class="goose-7"
          d="m646.46,415.73c-.75,8.22-2.49,15.23-2.62,15.71-2.73,10.08-6.86,21.11-13.1,32.56-25.59,46.92-70.91,71.75-94.53,82.58-4.13,1.75-9.2,4.38-14.25,8.35-10.52,8.29-15.07,17.82-17.03,23.06l-2.75,16.56c-1.22-.69-2.42-1.3-3.56-1.85-1.94-.84-4.31-.53-5.85.77-1.58,1.32-1.98,3.38-.99,5.09,2.45,1.18,5.58,2.89,8.86,5.27l-.46,2.79v.05c-.19.05-.38.1-.56.15-.21.06-.41.12-.62.18-.31.12-.61.24-.92.36-.02.01-.04.02-.06.02-.19.08-.39.16-.58.23-.11.02-.23.05-.34.07-2.04.44-4.06.88-6.1,1.32-1.84.3-3.67.61-5.51.92-.16.03-.33.05-.49.08-2.13.26-4.87.39-8,0-2.53-.32-4.76-.9-6.66-1.56-1.71-.6-3.16-1.26-4.31-1.86,0-.04-.01-.08-.01-.12,4.42-3.8,8.93-6.32,12.2-7.9.99-1.71.59-3.77-.99-5.09-1.54-1.3-3.91-1.61-5.85-.77-1.92.92-4,2.03-6.12,3.35l-.96-13.06c-2.07-5.16-6.18-13.28-14.42-21.32-6.67-6.52-13.66-10.65-18.68-13.13-15.53-5.99-47.14-20.49-70.43-50.86-.12-.15-.23-.3-.34-.45-15.68-20.62-22.5-45.22-20.03-69.73,1.24-12.3,3.84-25.72,8.57-39.84,2.73-8.12,5.86-15.61,9.18-22.45,3.73-9.59,17.57-41.16,56.35-61.74,24.83-13.18,47.75-15.46,65.53-16.84,8.31-.64,20.65.05,31.97.37,15.34.44,21.54,1,28,2,4.5.7,12.43,1.96,22,5,17.88,5.69,30.11,13.99,33,16,18.2,12.65,27.97,27.29,33,35,5.91,9.05,14.25,20.64,17,38,.8,5.04,2.74,18.78,1.46,32.73Z"
        />
        <path
          class="goose-10"
          d="m448,600c3.36,2.01,7.36,4.11,12,6,6.33,2.58,12.15,4.09,17,5-6.33,25.33-12.67,50.67-19,76l-45.09-20.99,35.09-66.01Z"
        />
        <polyline
          class="goose-10"
          points="466.02 592.23 467.3 606.46 479.97 602.6 480.91 584.79 466 592"
        />
        <path
          class="goose-10"
          d="m454,601c3.7,2.39,14.73,8.84,30,8,16.92-.93,27.9-10.19,31-13-1.59-1.07-3.26-2.07-5-3-21.64-11.54-45.87-6.73-60-2.34,1.33,3.45,2.67,6.89,4,10.34Z"
        />
        <path
          class="goose-10"
          d="m439.52,578.04s12.83-8.79,15.65-2.42,14.83,23.37,14.83,23.37l46.74-16.78s21.21,4.13,28.24,10.96-10.98,86.83-10.98,86.83c0,0-56,7.52-57,5.26s-47.65-95.19-47.65-95.19l10.17-12.03Z"
        />
        <path
          class="goose-5"
          d="m484.62,578.55c-5.58-1.05-13.99-3.19-23.12-8.05-11.8-6.29-13.28-10.99-25-19-16.88-11.53-19.96-5.99-35-17-11.62-8.51-19.38-18.84-24-25-4.98-6.63-8.57-12.61-11-17-5.81-4.49-24.87-20.36-32-49-7.16-28.78,2.37-51.84,5.41-58.5.47-10.75,3.53-52.31,36.59-82.5,12.86-11.75,26.36-18.21,36.41-21.85,12.83-8.04,48.37-27.96,97.59-26.15,47.83,1.75,81.11,23.01,93.52,31.87,10.4,4.34,28.9,13.8,44.48,33.13,27,33.5,25.28,71.88,24.55,81.67,1.67,13.86,3.23,42.58-9.55,75.33-12.35,31.64-31.96,51.33-42.41,60.5-4.16,2.14-10.2,5.14-17.59,8.5-20.72,9.41-35.99,14.24-60,22-11.92,3.85-32.49,3.26-57.5,11.5-.51-1.48-3.39-7.81,4.5-18.5,3.41-4.62,7.74-6.96,10.35-8.08,2.64-3.97,6.4-8.6,11.65-12.92,14.55-11.95,30.97-13.53,37.89-13.78,2.81-.31,6.83-1.09,11.11-3.22,7.75-3.87,10.06-9.07,16-16,11.03-12.86,15.29-9.19,26-22,5.24-6.27,2.93-5.6,12-18,6.09-8.33,9.49-11.84,13-19,2.81-5.74,4.24-10.85,5.01-14.26-3.16-9.35-4.7-19.91-2.01-30.74,1.48-5.93,3.17-7.72,4-13,.38-2.46,1.72-12.98-9-31-11.74-19.74-28.4-32.43-39.68-39.54-6.14-1.52-15.31-3.95-26.32-7.46-21.39-6.82-21.03-8.59-30-10-15.09-2.37-17.07,2.49-42,2-12.98-.26-13.75-1.6-22-1-14.57,1.06-26.11,6.26-33.67,10.58-6.91,7.23-13.1,12.26-17.33,15.42-10.47,7.85-14.55,8.53-19,15-4.8,6.97-1.96,8.92-7,19-5.98,11.96-11.18,11.6-14,21-1.75,5.83-.42,8.21,0,20,.23,6.39.19,15.56-1.15,26.81,1.88,2.68,4.78,6.9,8.15,12.19,11.87,18.64,13.3,24.96,22,37,6.47,8.96,10.48,14.5,18,20,11.04,8.07,18.25,7.73,32,16,8.95,5.39,15.27,11.18,18.96,14.9,32.07-.39,40.01,2.54,40.54,5.6.79,4.57-15.14,8.12-24,24-3.87,6.94-5.02,13.77-5.38,18.55Z"
        />
        <g>
          <circle class="goose-12" cx="468.5" cy="280.5" r="8.5" />
          <ellipse class="goose-12" cx="478" cy="281.5" rx="8" ry="7.5" />
          <ellipse class="goose-12" cx="483.5" cy="280" rx="8.5" ry="9" />
          <ellipse class="goose-12" cx="487.5" cy="285" rx="8.5" ry="6" />
          <ellipse class="goose-12" cx="491.5" cy="279" rx="7.5" ry="5" />
          <ellipse class="goose-12" cx="498" cy="284" rx="6" ry="5" />
          <ellipse class="goose-12" cx="502" cy="278" rx="7" ry="6" />
          <ellipse class="goose-12" cx="503" cy="285.5" rx="8" ry="5.5" />
        </g>
        <g>
          <circle class="goose-12" cx="394.8" cy="320.31" r="8.5" />
          <ellipse
            class="goose-12"
            cx="402.39"
            cy="314.51"
            rx="8"
            ry="7.5"
            transform="translate(-106.09 362.27) rotate(-43.38)"
          />
          <ellipse
            class="goose-12"
            cx="405.36"
            cy="309.65"
            rx="8.5"
            ry="9"
            transform="translate(-101.94 362.98) rotate(-43.38)"
          />
          <ellipse
            class="goose-12"
            cx="411.7"
            cy="310.53"
            rx="8.5"
            ry="6"
            transform="translate(-100.82 367.58) rotate(-43.38)"
          />
          <ellipse
            class="goose-12"
            cx="410.49"
            cy="303.43"
            rx="7.5"
            ry="5"
            transform="translate(-96.27 364.8) rotate(-43.38)"
          />
          <ellipse
            class="goose-12"
            cx="418.65"
            cy="302.6"
            rx="6"
            ry="5"
            transform="translate(-93.47 370.18) rotate(-43.38)"
          />
          <ellipse
            class="goose-12"
            cx="417.43"
            cy="295.49"
            rx="7"
            ry="6"
            transform="translate(-88.92 367.4) rotate(-43.38)"
          />
          <ellipse
            class="goose-12"
            cx="423.31"
            cy="300.25"
            rx="8"
            ry="5.5"
            transform="translate(-90.58 372.74) rotate(-43.38)"
          />
        </g>
        <g>
          <circle class="goose-12" cx="541.3" cy="278.37" r="8.5" />
          <ellipse
            class="goose-12"
            cx="549.49"
            cy="283.29"
            rx="7.5"
            ry="8"
            transform="translate(60.68 661.78) rotate(-65.02)"
          />
          <ellipse
            class="goose-12"
            cx="555.11"
            cy="284.25"
            rx="9"
            ry="8.5"
            transform="translate(63.05 667.43) rotate(-65.02)"
          />
          <ellipse
            class="goose-12"
            cx="556.62"
            cy="290.47"
            rx="6"
            ry="8.5"
            transform="translate(58.29 672.39) rotate(-65.02)"
          />
          <ellipse
            class="goose-12"
            cx="562.78"
            cy="286.72"
            rx="5"
            ry="7.5"
            transform="translate(65.25 675.81) rotate(-65.02)"
          />
          <ellipse
            class="goose-12"
            cx="566.56"
            cy="294"
            rx="5"
            ry="6"
            transform="translate(60.84 683.44) rotate(-65.02)"
          />
          <ellipse
            class="goose-12"
            cx="572.72"
            cy="290.25"
            rx="6"
            ry="7"
            transform="translate(67.79 686.86) rotate(-65.02)"
          />
          <ellipse
            class="goose-12"
            cx="570.46"
            cy="297.47"
            rx="5.5"
            ry="8"
            transform="translate(59.94 688.98) rotate(-65.02)"
          />
        </g>
        <g>
          <ellipse
            class="goose-12"
            cx="611.27"
            cy="311.83"
            rx="8.43"
            ry="8.23"
            transform="translate(-37.01 536.82) rotate(-46.17)"
          />
          <ellipse
            class="goose-12"
            cx="617.22"
            cy="318.91"
            rx="7.44"
            ry="7.75"
            transform="translate(-40.29 543.29) rotate(-46.17)"
          />
          <ellipse
            class="goose-12"
            cx="622.09"
            cy="321.53"
            rx="8.93"
            ry="8.23"
            transform="translate(-40.68 547.61) rotate(-46.17)"
          />
          <ellipse
            class="goose-12"
            cx="621.45"
            cy="327.79"
            rx="5.95"
            ry="8.23"
            transform="translate(-45.39 549.08) rotate(-46.17)"
          />
          <ellipse
            class="goose-12"
            cx="628.37"
            cy="326.18"
            rx="4.96"
            ry="7.26"
            transform="translate(-42.1 553.57) rotate(-46.17)"
          />
          <ellipse
            class="goose-12"
            cx="629.47"
            cy="334.11"
            rx="4.96"
            ry="5.81"
            transform="translate(-47.49 556.81) rotate(-46.17)"
          />
          <ellipse
            class="goose-12"
            cx="636.39"
            cy="332.5"
            rx="5.95"
            ry="6.78"
            transform="translate(-44.2 561.3) rotate(-46.17)"
          />
          <ellipse
            class="goose-12"
            cx="631.94"
            cy="338.54"
            rx="5.45"
            ry="7.75"
            transform="translate(-49.92 559.95) rotate(-46.17)"
          />
        </g>
        <g>
          <ellipse
            class="goose-12"
            cx="647.09"
            cy="361.09"
            rx="4.57"
            ry="8.23"
            transform="translate(-56.73 129.25) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="648.31"
            cy="370.23"
            rx="4.03"
            ry="7.75"
            transform="translate(-58.44 129.65) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="650.11"
            cy="375.3"
            rx="4.84"
            ry="8.23"
            transform="translate(-59.37 130.08) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="648.21"
            cy="379.61"
            rx="3.22"
            ry="8.23"
            transform="translate(-60.22 129.8) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="652.11"
            cy="382.81"
            rx="2.69"
            ry="7.26"
            transform="translate(-60.76 130.6) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="650.66"
            cy="389.5"
            rx="2.69"
            ry="5.81"
            transform="translate(-62.05 130.44) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="654.56"
            cy="392.69"
            rx="3.22"
            ry="6.78"
            transform="translate(-62.59 131.24) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="650.79"
            cy="394.4"
            rx="2.95"
            ry="7.75"
            transform="translate(-62.98 130.56) rotate(-10.93)"
          />
        </g>
        <g>
          <ellipse
            class="goose-12"
            cx="658.39"
            cy="423.15"
            rx="8.23"
            ry="4.57"
            transform="translate(18.66 874.02) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="654.43"
            cy="431.48"
            rx="7.75"
            ry="4.03"
            transform="translate(8.48 875.54) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="653.18"
            cy="436.71"
            rx="8.23"
            ry="4.84"
            transform="translate(2.84 877.64) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="649.23"
            cy="439.29"
            rx="8.23"
            ry="3.22"
            transform="translate(-2.01 875.6) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="650.76"
            cy="444.09"
            rx="7.26"
            ry="2.69"
            transform="translate(-5.51 880.01) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="645.9"
            cy="448.91"
            rx="5.81"
            ry="2.69"
            transform="translate(-13 878.52) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="647.43"
            cy="453.72"
            rx="6.78"
            ry="3.22"
            transform="translate(-16.5 882.93) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="643.34"
            cy="453.1"
            rx="7.75"
            ry="2.95"
            transform="translate(-18.48 878.75) rotate(-67.9)"
          />
        </g>
        <g>
          <ellipse
            class="goose-12"
            cx="632.1"
            cy="480.19"
            rx="8.23"
            ry="4.57"
            transform="translate(-157.14 565.29) rotate(-43.38)"
          />
          <ellipse
            class="goose-12"
            cx="625.05"
            cy="486.12"
            rx="7.75"
            ry="4.03"
            transform="translate(-163.14 562.07) rotate(-43.38)"
          />
          <ellipse
            class="goose-12"
            cx="621.73"
            cy="490.37"
            rx="8.23"
            ry="4.84"
            transform="translate(-166.96 560.95) rotate(-43.38)"
          />
          <ellipse
            class="goose-12"
            cx="617.07"
            cy="491.07"
            rx="8.23"
            ry="3.22"
            transform="translate(-168.72 557.94) rotate(-43.38)"
          />
          <ellipse
            class="goose-12"
            cx="616.47"
            cy="496.08"
            rx="7.26"
            ry="2.69"
            transform="translate(-172.32 558.89) rotate(-43.38)"
          />
          <ellipse
            class="goose-12"
            cx="610.05"
            cy="498.45"
            rx="5.81"
            ry="2.69"
            transform="translate(-175.7 555.13) rotate(-43.38)"
          />
          <ellipse
            class="goose-12"
            cx="609.44"
            cy="503.45"
            rx="6.78"
            ry="3.22"
            transform="translate(-179.3 556.08) rotate(-43.38)"
          />
          <ellipse
            class="goose-12"
            cx="605.97"
            cy="501.19"
            rx="7.75"
            ry="2.95"
            transform="translate(-178.69 553.08) rotate(-43.38)"
          />
        </g>
        <g>
          <ellipse
            class="goose-12"
            cx="588.69"
            cy="531.48"
            rx="8.23"
            ry="4.57"
            transform="translate(-135.28 207.6) rotate(-17.97)"
          />
          <ellipse
            class="goose-12"
            cx="579.78"
            cy="533.81"
            rx="7.75"
            ry="4.03"
            transform="translate(-136.43 204.97) rotate(-17.97)"
          />
          <ellipse
            class="goose-12"
            cx="574.96"
            cy="536.22"
            rx="8.23"
            ry="4.84"
            transform="translate(-137.41 203.6) rotate(-17.97)"
          />
          <ellipse
            class="goose-12"
            cx="570.45"
            cy="534.86"
            rx="8.23"
            ry="3.22"
            transform="translate(-137.21 202.14) rotate(-17.97)"
          />
          <ellipse
            class="goose-12"
            cx="567.76"
            cy="539.12"
            rx="7.26"
            ry="2.69"
            transform="translate(-138.66 201.51) rotate(-17.97)"
          />
          <ellipse
            class="goose-12"
            cx="560.94"
            cy="538.51"
            rx="5.81"
            ry="2.69"
            transform="translate(-138.8 199.38) rotate(-17.97)"
          />
          <ellipse
            class="goose-12"
            cx="558.25"
            cy="542.77"
            rx="6.78"
            ry="3.22"
            transform="translate(-140.25 198.76) rotate(-17.97)"
          />
          <ellipse
            class="goose-12"
            cx="556.09"
            cy="539.24"
            rx="7.75"
            ry="2.95"
            transform="translate(-139.26 197.92) rotate(-17.97)"
          />
        </g>
        <g>
          <ellipse
            class="goose-12"
            cx="480.76"
            cy="546.5"
            rx="4.57"
            ry="8.23"
            transform="translate(-217.63 751.55) rotate(-65.02)"
          />
          <ellipse
            class="goose-12"
            cx="472.65"
            cy="542.13"
            rx="4.03"
            ry="7.75"
            transform="translate(-218.35 741.67) rotate(-65.02)"
          />
          <ellipse
            class="goose-12"
            cx="467.48"
            cy="540.61"
            rx="4.84"
            ry="8.23"
            transform="translate(-219.96 736.11) rotate(-65.02)"
          />
          <ellipse
            class="goose-12"
            cx="465.1"
            cy="536.54"
            rx="3.22"
            ry="8.23"
            transform="translate(-217.65 731.6) rotate(-65.02)"
          />
          <ellipse
            class="goose-12"
            cx="460.23"
            cy="537.83"
            rx="2.69"
            ry="7.26"
            transform="translate(-221.63 727.93) rotate(-65.02)"
          />
          <ellipse
            class="goose-12"
            cx="455.66"
            cy="532.73"
            rx="2.69"
            ry="5.81"
            transform="translate(-219.65 720.84) rotate(-65.02)"
          />
          <ellipse
            class="goose-12"
            cx="450.79"
            cy="534.02"
            rx="3.22"
            ry="6.78"
            transform="translate(-223.63 717.17) rotate(-65.02)"
          />
          <ellipse
            class="goose-12"
            cx="451.61"
            cy="529.96"
            rx="2.95"
            ry="7.75"
            transform="translate(-219.48 715.57) rotate(-65.02)"
          />
        </g>
        <g>
          <ellipse
            class="goose-12"
            cx="402.69"
            cy="518.26"
            rx="4.57"
            ry="8.23"
            transform="translate(-217.3 302.95) rotate(-33)"
          />
          <ellipse
            class="goose-12"
            cx="398.13"
            cy="510.25"
            rx="4.03"
            ry="7.75"
            transform="translate(-213.68 299.18) rotate(-33)"
          />
          <ellipse
            class="goose-12"
            cx="394.56"
            cy="506.22"
            rx="4.84"
            ry="8.23"
            transform="translate(-212.06 296.58) rotate(-33)"
          />
          <ellipse
            class="goose-12"
            cx="394.7"
            cy="501.51"
            rx="3.22"
            ry="8.23"
            transform="translate(-209.47 295.9) rotate(-33)"
          />
          <ellipse
            class="goose-12"
            cx="389.89"
            cy="500.02"
            rx="2.69"
            ry="7.26"
            transform="translate(-209.44 293.03) rotate(-33)"
          />
          <ellipse
            class="goose-12"
            cx="388.71"
            cy="493.28"
            rx="2.69"
            ry="5.81"
            transform="translate(-205.95 291.31) rotate(-33)"
          />
          <ellipse
            class="goose-12"
            cx="383.9"
            cy="491.78"
            rx="3.22"
            ry="6.78"
            transform="translate(-205.92 288.44) rotate(-33)"
          />
          <ellipse
            class="goose-12"
            cx="386.75"
            cy="488.78"
            rx="2.95"
            ry="7.75"
            transform="translate(-203.82 289.51) rotate(-33)"
          />
        </g>
        <g>
          <ellipse
            class="goose-12"
            cx="360.81"
            cy="457.52"
            rx="4.57"
            ry="8.23"
            transform="translate(-80.21 76.72) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="359.59"
            cy="448.39"
            rx="4.03"
            ry="7.75"
            transform="translate(-78.5 76.32) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="357.79"
            cy="443.31"
            rx="4.84"
            ry="8.23"
            transform="translate(-77.57 75.89) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="359.69"
            cy="439"
            rx="3.22"
            ry="8.23"
            transform="translate(-76.72 76.17) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="355.79"
            cy="435.8"
            rx="2.69"
            ry="7.26"
            transform="translate(-76.18 75.37) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="357.24"
            cy="429.12"
            rx="2.69"
            ry="5.81"
            transform="translate(-74.89 75.52) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="353.34"
            cy="425.92"
            rx="3.22"
            ry="6.78"
            transform="translate(-74.35 74.73) rotate(-10.93)"
          />
          <ellipse
            class="goose-12"
            cx="357.11"
            cy="424.21"
            rx="2.95"
            ry="7.75"
            transform="translate(-73.96 75.41) rotate(-10.93)"
          />
        </g>
        <g>
          <ellipse
            class="goose-12"
            cx="353.51"
            cy="390.46"
            rx="8.23"
            ry="4.57"
            transform="translate(-141.25 571.13) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="357.47"
            cy="382.14"
            rx="7.75"
            ry="4.03"
            transform="translate(-131.07 569.6) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="358.72"
            cy="376.9"
            rx="8.23"
            ry="4.84"
            transform="translate(-125.43 567.5) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="362.67"
            cy="374.32"
            rx="8.23"
            ry="3.22"
            transform="translate(-120.58 569.55) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="361.14"
            cy="369.52"
            rx="7.26"
            ry="2.69"
            transform="translate(-117.08 565.14) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="366"
            cy="364.7"
            rx="5.81"
            ry="2.69"
            transform="translate(-109.59 566.63) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="364.47"
            cy="359.9"
            rx="6.78"
            ry="3.22"
            transform="translate(-106.09 562.22) rotate(-67.9)"
          />
          <ellipse
            class="goose-12"
            cx="368.57"
            cy="360.51"
            rx="7.75"
            ry="2.95"
            transform="translate(-104.11 566.4) rotate(-67.9)"
          />
        </g>
        <g>
          <path
            class="goose-8"
            d="m522.02,662.39c0,.33-.06.66-.16,1-.11.32-.27.64-.47.95l-5.1,7.29c-1.25,1.79-4.01,2.38-6.11,1.3-2.1-1.08-2.8-3.43-1.53-5.23l5.09-7.28c.34-.5.79-.9,1.33-1.2.09-.06.18-.11.28-.16,1.35-.66,3.07-.69,4.49.04.42.2.77.46,1.07.76.63.62,1.01,1.39,1.09,2.19.01.11.02.23.02.34Z"
          />
          <path
            class="goose-13"
            d="m474,579l19,1.98v261.02c-5.81-.19-11.81-.52-18-.99-3.74-.29-7.41-.62-11-.99,3.33-87.01,6.67-174.01,10-261.02Z"
          />
          <path
            class="goose-11"
            d="m331.89,424.21s-9.76,3.02-11.33,4.91,11.33-10.33,11.33-10.33"
          />
          <path
            class="goose-11"
            d="m331.89,418.26s-6.89,4.74-7.89,6.74,7.89-10,7.89-10v3.26Z"
          />
          <polygon
            class="goose-11"
            points="331.89 413.43 326.16 417.22 330.24 417.22 331.89 413.43"
          />
          <polygon
            class="goose-11"
            points="330 411 323 414 331.89 413.43 331.89 408.87 330 411"
          />
          <path
            class="goose-11"
            d="m334.65,404.93c.35.07-11.65.07-12.65,4.07l11.7-2.04.95-2.04Z"
          />
          <polygon
            class="goose-11"
            points="333.07 407.74 323.92 410.43 333.7 410.43 333.07 407.74"
          />
          <path
            class="goose-11"
            d="m332,402s-11.17.93-13.09,4.96c0,0,13.96-5.11,15.52-2.04s-2.43-2.93-2.43-2.93Z"
          />
          <polyline
            class="goose-11"
            points="334.66 402 319.91 406.96 332.65 405.13 333.56 405 316.78 413.43 333.07 407.74 320.43 412.52 331.89 410.43"
          />
          <polyline
            class="goose-11"
            points="331.69 422.72 318.94 431.63 330.64 426.28 331.48 425.9 317.75 438.72 331.78 428.67 321 436.82 331.41 431.59"
          />
          <polyline
            class="goose-11"
            points="331.69 429.72 318.94 438.63 330.64 433.28 331.48 432.9 317.75 445.72 331.78 435.67 321 443.82 331.41 438.59"
          />
          <polyline
            class="goose-11"
            points="332.69 435.72 319.94 444.63 331.64 439.28 332.48 438.9 318.75 451.72 332.78 441.67 322 449.82 332.41 444.59"
          />
          <polyline
            class="goose-11"
            points="332.69 430.72 319.94 439.63 331.64 434.28 332.48 433.9 318.75 446.72 332.78 436.67 322 444.82 332.41 439.59"
          />
          <polyline
            class="goose-11"
            points="331.37 413.58 320.35 419.08 329.99 416.78 330.68 416.61 318.27 425.66 330.44 419.37 321.02 424.61 329.67 422.1"
          />
          <path
            class="goose-11"
            d="m332.16,402.18s-6.47.3-7.98,1.51,9.81-6.02,9.81-6.02"
          />
          <path
            class="goose-11"
            d="m334.17,397.24s-5.45,2.38-6.68,3.81,7.78-6.52,7.78-6.52l-1.1,2.71Z"
          />
          <polygon
            class="goose-11"
            points="335.79 393.23 331.32 395.08 333.59 396 335.79 393.23"
          />
          <polygon
            class="goose-11"
            points="335.56 390.78 330.64 391.69 335.79 393.23 337.33 389.44 335.56 390.78"
          />
          <path
            class="goose-11"
            d="m340.2,386.79c.17.14-6.53-2.58-8.44.52l7.22.96,1.22-1.48Z"
          />
          <polygon
            class="goose-11"
            points="338.37 388.77 332.35 388.93 337.81 391.15 338.37 388.77"
          />
          <path
            class="goose-11"
            d="m339.71,383.76s-6.55-1.76-8.98,1.16c0,0,9.52-1.08,9.35,1.83s-.37-2.98-.37-2.98Z"
          />
          <polyline
            class="goose-11"
            points="341.2 384.36 331.29 385.14 339.02 386.5 339.57 386.6 327.36 389.81 338.37 388.77 329.7 389.88 336.81 390.74"
          />
          <path
            class="goose-11"
            d="m339.62,378.62s-10.15,1.19-12.03,2.76,13.02-8.1,13.02-8.1"
          />
          <path
            class="goose-11"
            d="m340.7,372.78s-7.64,3.41-8.99,5.19,9.58-8.4,9.58-8.4l-.59,3.21Z"
          />
          <polygon
            class="goose-11"
            points="341.58 368.03 335.26 370.71 339.26 371.45 341.58 368.03"
          />
          <polygon
            class="goose-11"
            points="340.16 365.29 332.73 366.97 341.58 368.03 342.41 363.54 340.16 365.29"
          />
          <path
            class="goose-11"
            d="m345.84,360.17c.33.13-11.47-2.05-13.18,1.7l11.87.13,1.31-1.83Z"
          />
          <polygon
            class="goose-11"
            points="343.78 362.64 334.29 363.63 343.9 365.41 343.78 362.64"
          />
          <path
            class="goose-11"
            d="m343.77,356.81s-11.16-1.12-13.77,2.5c0,0,14.65-2.48,15.63.82s-1.86-3.32-1.86-3.32Z"
          />
          <polyline
            class="goose-11"
            points="346.38 357.29 330.98 359.49 343.83 360 344.76 360.04 326.72 365.28 343.78 362.64 330.48 365.05 342.13 365.08"
          />
          <polyline
            class="goose-11"
            points="339.69 377.12 325.53 383.56 338 380.44 338.9 380.21 323.07 390.32 338.69 382.99 326.61 389.04 337.8 385.79"
          />
          <polyline
            class="goose-11"
            points="338.41 384 324.25 390.44 336.73 387.32 337.63 387.09 321.8 397.2 337.42 389.87 325.34 395.92 336.52 392.68"
          />
          <polyline
            class="goose-11"
            points="338.31 390.08 324.14 396.53 336.62 393.4 337.52 393.18 321.69 403.28 337.31 395.95 325.23 402 336.41 398.76"
          />
          <path
            class="goose-11"
            d="m341.88,461.06s-7.57,6.87-8.19,9.24,5.89-14.15,5.89-14.15"
          />
          <path
            class="goose-11"
            d="m339.37,455.67s-4.24,7.21-4.3,9.45,2.92-12.4,2.92-12.4l1.38,2.95Z"
          />
          <polygon
            class="goose-11"
            points="337.32 451.3 333.73 457.15 337.43 455.42 337.32 451.3"
          />
          <polygon
            class="goose-11"
            points="334.58 449.89 329.51 455.57 337.32 451.3 335.39 447.16 334.58 449.89"
          />
          <path
            class="goose-11"
            d="m336.22,442.42c.35-.08-10.53,5-9.74,9.04l9.74-6.79v-2.25Z"
          />
          <polygon
            class="goose-11"
            points="335.98 445.63 328.83 451.95 337.69 447.81 335.98 445.63"
          />
          <path
            class="goose-11"
            d="m332.58,440.89s-9.73,5.57-9.76,10.04c0,0,10.48-10.53,13.2-8.41s-3.44-1.62-3.44-1.62Z"
          />
          <polyline
            class="goose-11"
            points="334.99 439.76 323.73 450.5 334.49 443.45 335.26 442.94 323.63 457.69 335.98 445.63 326.56 455.32 336.05 448.58"
          />
          <polyline
            class="goose-11"
            points="341.07 459.79 333.28 473.26 341.62 463.47 342.22 462.76 335.21 480.19 343.67 465.14 337.35 477.09 344.57 467.95"
          />
          <polyline
            class="goose-11"
            points="344.03 466.13 336.25 479.61 344.58 469.81 345.18 469.11 338.18 486.53 346.63 471.49 340.31 483.43 347.53 474.29"
          />
          <polyline
            class="goose-11"
            points="347.47 471.15 339.69 484.62 348.03 474.82 348.63 474.12 341.62 491.54 350.07 476.5 343.76 488.45 350.97 479.3"
          />
          <polygon
            class="goose-11"
            points="337.58 480.09 345.91 470.29 346.51 469.59 339.5 487.01 347.79 472.28 345.36 466.62 337.58 480.09"
          />
          <polygon
            class="goose-11"
            points="341.64 483.92 348.86 474.77 347.79 472.29 341.64 483.92"
          />
          <polyline
            class="goose-11"
            points="336.92 451.64 329.26 461.29 337.01 455.13 337.57 454.69 330.15 468.13 338.52 457.29 332.2 466.02 338.98 460.09"
          />
          <path
            class="goose-11"
            d="m332.81,440.99s-5.74,3.01-6.59,4.74,6.34-9.6,6.34-9.6"
          />
          <path
            class="goose-11"
            d="m332.53,435.66s-3.93,4.46-4.44,6.28,4.29-9.2,4.29-9.2l.15,2.92Z"
          />
          <polyline
            class="goose-11"
            points="333.25 427.43 323.15 439.26 333.13 431.14 333.85 430.56 323.78 446.42 334.84 433.16 326.45 443.76 335.21 436.08"
          />
          <path
            class="goose-11"
            d="m362.94,491.34c.12,3.17.26,7.45.29,9.81.38-2.01,1.51-5.06,2.42-7.32-.91-.82-1.81-1.65-2.71-2.49Z"
          />
          <path
            class="goose-11"
            d="m362.28,497.38c.31.76.08-.74-.34-2.93.01,1.26.1,2.35.34,2.93Z"
          />
          <path
            class="goose-11"
            d="m362.07,490.54c-.13-.13-.27-.25-.4-.37-.25-.24-.5-.49-.75-.73.35,1.66.73,3.5,1.02,5.01-.01-1.24.05-2.65.14-3.9Z"
          />
          <path
            class="goose-11"
            d="m358.35,486.84l-.51,3.78,1.98-2.27c-.5-.5-.99-1-1.47-1.51Z"
          />
          <path
            class="goose-11"
            d="m355.62,483.82l-2.29,7.07,3.97-5.17c-.57-.63-1.13-1.26-1.68-1.9Z"
          />
          <path
            class="goose-11"
            d="m351.97,479.07c-2.03,2.89-4.53,7.18-3.06,9.28l4.46-7.35c-.48-.64-.95-1.28-1.4-1.93Z"
          />
          <path
            class="goose-11"
            d="m354.02,481.85l-2.76,6.01,3.96-4.53c-.41-.49-.8-.98-1.19-1.48Z"
          />
          <path
            class="goose-11"
            d="m350.18,476.27c-.35.48-6.57,9.09-4.84,13.08,0,0,3-7.8,5.91-11.35-.37-.57-.73-1.14-1.07-1.73Z"
          />
          <path
            class="goose-11"
            d="m350.73,477.19l-4.73,11.4,6.07-9.37c-.46-.67-.91-1.34-1.34-2.03Z"
          />
          <path
            class="goose-11"
            d="m354.18,482.05l-3.64,9.8,4.92-8.25c-.43-.51-.86-1.03-1.27-1.56Z"
          />
          <path
            class="goose-11"
            d="m352.7,480.1l-3.89,15.1,5.3-13.22c-.49-.62-.96-1.24-1.42-1.88Z"
          />
          <path
            class="goose-11"
            d="m365.25,493.48l-1.31,12.07,3.11-10.45c-.6-.54-1.21-1.08-1.8-1.62Z"
          />
          <path
            class="goose-11"
            d="m369.89,497.62l-.69,9.78,2.18-8.47c-.5-.44-.99-.88-1.49-1.32Z"
          />
          <path
            class="goose-11"
            d="m367.99,495.94l.51,15.16,1.31-13.56c-.61-.53-1.21-1.07-1.81-1.6Z"
          />
          <path
            class="goose-11"
            d="m370.51,498.17l-1.3,11.99,3.1-10.4c-.6-.53-1.2-1.06-1.8-1.59Z"
          />
          <path
            class="goose-11"
            d="m375.09,503.31l-.62,8.71,1.83-7.1c-.42-.53-.82-1.07-1.22-1.61Z"
          />
          <path
            class="goose-11"
            d="m373.27,500.67l.5,15.04,1.21-12.55c-.6-.81-1.17-1.64-1.71-2.49Z"
          />
          <path
            class="goose-11"
            d="m375.43,503.76l-1.04,9.59,2.35-7.91c-.45-.55-.89-1.11-1.32-1.68Z"
          />
          <path
            class="goose-11"
            d="m380.07,509.25l-.42,5.96,1.3-5.06c-.29-.3-.59-.6-.88-.9Z"
          />
          <path
            class="goose-11"
            d="m378.57,507.59l.38,11.31.95-9.85c-.45-.48-.89-.97-1.33-1.46Z"
          />
          <path
            class="goose-11"
            d="m374.76,502.86l.43,12.76,1.04-10.8c-.51-.64-.99-1.3-1.47-1.96Z"
          />
          <path
            class="goose-11"
            d="m373.17,500.52c-.46-.41-.92-.82-1.38-1.22l-1.17,10.77,2.75-9.24c-.07-.1-.13-.2-.2-.31Z"
          />
          <path
            class="goose-11"
            d="m376.38,505l-.49,6.92,1.48-5.73c-.33-.4-.66-.79-.99-1.2Z"
          />
          <path
            class="goose-11"
            d="m357.97,486.44l-2.55,9.79,4.23-8.05c-.57-.57-1.13-1.15-1.67-1.74Z"
          />
          <path
            class="goose-11"
            d="m361.67,490.16c-.5-.48-1-.96-1.49-1.44l-1.18,13.4,2.89-11.75c-.07-.07-.15-.13-.22-.2Z"
          />
          <path
            class="goose-11"
            d="m361.93,490.41l-1.91,8.96,3.49-7.49c-.53-.49-1.06-.97-1.58-1.47Z"
          />
          <path
            class="goose-11"
            d="m348.18,472.48c-.27,1.8-1.1,7.22-1.55,9.69.79-1.95,3.02-4.9,3.66-5.72-.77-1.29-1.48-2.61-2.11-3.96Z"
          />
          <path
            class="goose-11"
            d="m346.74,468.97c0,.77.02,4.96-.03,7.73.32-1.78.93-3.88,1.2-4.78-.43-.96-.82-1.95-1.17-2.95Z"
          />
          <path
            class="goose-11"
            d="m348.1,472.31l-2.34,9.01,3.29-7.07c-.33-.64-.65-1.28-.96-1.94Z"
          />
          <path
            class="goose-11"
            d="m345.32,463.86l-4.4,14.68,5.44-10.65c-.42-1.31-.77-2.65-1.04-4.03Z"
          />
          <path
            class="goose-11"
            d="m346.78,469.07l-2.38,15.76,3.63-12.67c-.46-1.01-.88-2.04-1.25-3.09Z"
          />
          <path
            class="goose-11"
            d="m389.83,518.52c.11,2.72.36,9.44.4,12.63.5-2.67,2.35-7.2,3.2-9.22-1.2-1.14-2.4-2.28-3.6-3.41Z"
          />
          <path
            class="goose-11"
            d="m389.28,527.38c.31.76.08-.74-.34-2.93.01,1.26.1,2.35.34,2.93Z"
          />
          <path
            class="goose-11"
            d="m389.29,518.01c-.69-.65-1.39-1.29-2.08-1.92.41,1.91,1.21,5.66,1.73,8.35-.02-2.23.21-4.98.35-6.43Z"
          />
          <path
            class="goose-11"
            d="m385.64,514.66l-.81,5.96,2.68-3.07-.98-2.08c-.3-.27-.6-.55-.9-.82Z"
          />
          <path
            class="goose-11"
            d="m382.42,511.63l.26,2.02-2.35,7.25,5-6.51c-.98-.9-1.96-1.82-2.91-2.75Z"
          />
          <path
            class="goose-11"
            d="m379.39,508.49c-2.08,2.87-5.05,7.61-3.48,9.86l5.01-8.25c-.52-.53-1.03-1.07-1.53-1.61Z"
          />
          <path
            class="goose-11"
            d="m381.53,510.73l-3.28,7.12,4.86-5.56c-.53-.51-1.06-1.03-1.58-1.56Z"
          />
          <path
            class="goose-11"
            d="m377.41,506.24c-.12-.01-.19-.02-.19-.02,0,0-6.65,9.03-4.87,13.12,0,0,3.23-8.41,6.24-11.73-.4-.45-.79-.91-1.18-1.37Z"
          />
          <path
            class="goose-11"
            d="m377.88,506.82l-4.88,11.77,6.47-10c-.54-.58-1.08-1.17-1.59-1.77Z"
          />
          <path
            class="goose-11"
            d="m381.63,510.83l-4.1,11.02,5.66-9.49c-.53-.51-1.05-1.02-1.56-1.53Z"
          />
          <path
            class="goose-11"
            d="m379.96,509.12l-4.14,16.08,5.78-14.4c-.55-.55-1.1-1.11-1.64-1.68Z"
          />
          <path
            class="goose-11"
            d="m392.51,521.06l-1.57,14.49,3.67-12.33.04-.14c-.72-.67-1.43-1.34-2.14-2.02Z"
          />
          <path
            class="goose-11"
            d="m394.91,523.32l.6,17.79,1.53-15.85c-.71-.64-1.42-1.28-2.12-1.94Z"
          />
          <path
            class="goose-11"
            d="m397.06,525.28l-.86,12.12,2.71-10.5c-.62-.53-1.24-1.08-1.85-1.63Z"
          />
          <path
            class="goose-11"
            d="m397.75,525.9l-1.54,14.25,3.67-12.33.03-.09c-.73-.6-1.44-1.21-2.15-1.83Z"
          />
          <path
            class="goose-11"
            d="m400.18,527.96l.59,17.75,1.55-16.08c-.72-.54-1.44-1.1-2.14-1.67Z"
          />
          <path
            class="goose-11"
            d="m402.35,529.65l-.87,12.36,2.83-10.96c-.66-.45-1.31-.92-1.95-1.4Z"
          />
          <path
            class="goose-11"
            d="m402.83,530.01l-1.44,13.34,3.54-11.89c-.71-.47-1.41-.94-2.1-1.45Z"
          />
          <path
            class="goose-11"
            d="m407.49,533.31l-.84,11.89,2.74-10.61c-.64-.42-1.27-.84-1.89-1.29Z"
          />
          <path
            class="goose-11"
            d="m405.38,531.75l.57,17.15,1.5-15.62c-.7-.5-1.4-1.01-2.08-1.54Z"
          />
          <path
            class="goose-11"
            d="m399.03,527.01l-1.41,13.05,3.4-11.41c-.67-.53-1.33-1.08-1.98-1.64Z"
          />
          <path
            class="goose-11"
            d="m403.68,530.64l-.8,11.27,2.6-10.08s-.07-.05-.1-.08c-.57-.37-1.15-.72-1.71-1.11Z"
          />
          <path
            class="goose-11"
            d="m401.63,529.11l.55,16.5,1.45-15.01c-.68-.48-1.34-.98-2-1.49Z"
          />
          <polygon
            class="goose-11"
            points="387.02 529.37 390.82 521.2 389.21 519.1 387.02 529.37"
          />
          <path
            class="goose-11"
            d="m385.47,514.51l-3.06,11.72,4.6-8.77.33-.63-1.35,15.3,3.2-13.03-3.04-3.95c-.23-.21-.46-.42-.69-.63Z"
          />
          <path
            class="goose-11"
            d="m375.06,503.26c-.35,2.26-1.04,6.72-1.43,8.9.87-2.14,3.46-5.48,3.79-5.9-.82-.97-1.61-1.98-2.37-3Z"
          />
          <path
            class="goose-11"
            d="m374.68,502.74c-.32-.44-.63-.89-.93-1.34,0,1.62,0,3.7-.03,5.3.25-1.36.66-2.9.96-3.96Z"
          />
          <path
            class="goose-11"
            d="m373.49,501l-2.09,13.83,3.41-11.9c-.46-.63-.9-1.28-1.33-1.93Z"
          />
          <path
            class="goose-11"
            d="m370.92,498.53l-3,10.01,4.46-8.72c-.49-.43-.97-.86-1.46-1.29Z"
          />
          <path
            class="goose-11"
            d="m374.91,503.06l-2.14,8.27,3.19-6.85c-.36-.46-.7-.94-1.05-1.41Z"
          />
          <path
            class="goose-11"
            d="m419.72,540.16c-.31-.15-.61-.3-.92-.45.07,1.79.39,9.85.43,13.45.66-3.51,3.64-10.24,3.64-10.24l-3.15-2.75Z"
          />
          <path
            class="goose-11"
            d="m418.28,549.38c.31.76.08-.74-.34-2.93.01,1.26.1,2.35.34,2.93Z"
          />
          <path
            class="goose-11"
            d="m418.34,539.48c-.69-.34-1.37-.69-2.06-1.04.43,1.99,1.17,5.46,1.65,8.01-.03-2.55.28-5.78.41-6.97Z"
          />
          <path
            class="goose-11"
            d="m414.52,537.55l-.69,5.08,2.68-3.07-.62-1.31c-.46-.23-.92-.46-1.38-.69Z"
          />
          <path
            class="goose-11"
            d="m411.58,535.95l-2.25,6.94,4.41-5.74c-.73-.38-1.45-.78-2.16-1.19Z"
          />
          <path
            class="goose-11"
            d="m406.81,532.83c-1.66,2.67-3.09,5.82-1.9,7.53l3.77-6.22c-.63-.43-1.26-.86-1.88-1.31Z"
          />
          <path
            class="goose-11"
            d="m409.61,534.74l-2.35,5.11,3.72-4.26c-.46-.28-.92-.56-1.37-.85Z"
          />
          <path
            class="goose-11"
            d="m404.34,531.07c-1.82,3-4.14,7.64-2.99,10.27,0,0,2.08-5.41,4.45-9.27-.14-.1-.27-.21-.4-.31-.35-.23-.71-.45-1.06-.69Z"
          />
          <path
            class="goose-11"
            d="m409.86,534.91l-3.32,8.94,4.8-8.05c-.5-.29-.99-.59-1.47-.9Z"
          />
          <path
            class="goose-11"
            d="m405.6,531.92l-3.6,8.67,4.96-7.65c-.46-.33-.91-.67-1.36-1.01Z"
          />
          <path
            class="goose-11"
            d="m408.25,533.85l-3.43,13.35,4.95-12.35c-.51-.33-1.02-.66-1.52-1Z"
          />
          <polyline
            class="goose-11"
            points="421.61 542.08 419.94 557.55 423.61 545.22 423.87 544.33 424.5 563.1 426.16 545.93 425.2 559.41 428.11 548.13"
          />
          <polyline
            class="goose-11"
            points="426.89 546.69 425.21 562.15 428.88 549.83 429.14 548.94 429.77 567.71 431.43 550.53 430.48 564.01 433.38 552.73"
          />
          <polyline
            class="goose-11"
            points="432.06 549.88 430.39 565.35 434.06 553.02 434.32 552.13 434.95 570.9 436.61 553.73 435.65 567.21 438.56 555.93"
          />
          <polyline
            class="goose-11"
            points="428.3 546.59 426.62 562.06 430.29 549.73 430.56 548.84 431.18 567.61 432.84 550.44 431.89 563.92 434.79 552.64"
          />
          <path
            class="goose-11"
            d="m414.24,537.41l-2.82,10.82,4.6-8.77.33-.63-1.35,15.3,3.2-13.03-2.11-2.75c-.62-.31-1.24-.62-1.85-.93Z"
          />
          <polygon
            class="goose-11"
            points="416.02 551.37 419.82 543.2 418.21 541.1 416.02 551.37"
          />
          <path
            class="goose-11"
            d="m403.27,530.34c-.23,1.46-.46,2.87-.64,3.83.37-.91,1.05-2.04,1.74-3.07-.37-.25-.74-.5-1.1-.76Z"
          />
          <path
            class="goose-11"
            d="m398.2,526.29l-1.27,4.24,1.89-3.7c-.21-.18-.41-.36-.62-.54Z"
          />
          <path
            class="goose-11"
            d="m402.66,529.88l-.89,3.44,1.42-3.05c-.18-.13-.35-.26-.53-.39Z"
          />
          <path
            class="goose-11"
            d="m401.57,529.07l-1.17,7.76,2.04-7.11c-.29-.22-.58-.43-.87-.65Z"
          />
          <polyline
            class="goose-11"
            points="339.22 385.17 325.05 391.61 337.53 388.48 338.43 388.26 322.6 398.37 338.22 391.04 326.14 397.09 337.32 393.84"
          />
          <polyline
            class="goose-11"
            points="341.04 368.08 329.2 371.48 339.1 370.97 339.81 370.93 325.96 377.57 339.07 373.6 328.85 377.04 337.81 376.15"
          />
          <path
            class="goose-11"
            d="m343.89,357.02s-6.42-.89-8.12.03,10.74-4.13,10.74-4.13"
          />
          <path
            class="goose-11"
            d="m346.76,352.52s-5.79,1.34-7.26,2.53,8.84-4.99,8.84-4.99l-1.57,2.46Z"
          />
          <polygon
            class="goose-11"
            points="349.09 348.88 344.36 349.88 346.43 351.2 349.09 348.88"
          />
          <path
            class="goose-11"
            d="m349.71,346.22l-.4.2h-5s4.79,2.45,4.79,2.45l.21-.33c.09-.78.22-1.56.41-2.33Z"
          />
          <path
            class="goose-11"
            d="m351.27,341.85c-1.79-.59-3.86-.85-5.06.47l4.28,1.4c.23-.63.49-1.25.78-1.86Z"
          />
          <path
            class="goose-11"
            d="m350.18,344.6l-3.68-.58,3.25,2.08c.12-.5.27-1,.43-1.5Z"
          />
          <path
            class="goose-11"
            d="m352.52,339.46c-2.05-.64-5.08-1.19-6.89.32,0,0,3.52.25,6.13,1.07.24-.47.49-.93.76-1.39Z"
          />
          <path
            class="goose-11"
            d="m351.83,340.7l-5.69-.6,5.06,1.89c.2-.43.41-.87.63-1.29Z"
          />
          <path
            class="goose-11"
            d="m350.1,344.82l-6.39-.36,5.97,1.87c.12-.51.26-1.01.41-1.52Z"
          />
          <path
            class="goose-11"
            d="m350.65,343.29l-9.23.68,8.71.78c.16-.49.33-.97.52-1.46Z"
          />
          <path
            class="goose-11"
            d="m352.55,336.55s-10.1-1.58-12.33-.58,14.72-4.3,14.72-4.3"
          />
          <path
            class="goose-11"
            d="m355.17,331.21s-8.27,1.22-10.05,2.58,11.49-5.51,11.49-5.51l-1.43,2.93Z"
          />
          <polygon
            class="goose-11"
            points="357.29 326.87 350.48 327.75 354.14 329.54 357.29 326.87"
          />
          <polygon
            class="goose-11"
            points="356.66 323.86 349.06 323.47 357.29 326.87 359.3 322.78 356.66 323.86"
          />
          <path
            class="goose-11"
            d="m363.51,320.45c.28.22-10.5-5.06-13.15-1.91l11.4,3.32,1.75-1.41Z"
          />
          <polygon
            class="goose-11"
            points="360.86 322.28 351.45 320.67 360.23 324.97 360.86 322.28"
          />
          <path
            class="goose-11"
            d="m362.42,316.65s-10.44-4.08-13.94-1.3c0,0,14.78,1.55,14.83,5s-.9-3.7-.9-3.7Z"
          />
          <polyline
            class="goose-11"
            points="364.81 317.82 349.38 315.79 361.62 319.75 362.5 320.03 343.72 320.23 360.86 322.28 347.4 321.01 358.61 324.18"
          />
          <polyline
            class="goose-11"
            points="353.03 335.12 337.65 337.51 350.51 337.86 351.43 337.89 333.47 343.36 350.49 340.5 337.22 343.08 348.87 342.96"
          />
          <polyline
            class="goose-11"
            points="349.95 341.41 334.57 343.8 347.43 344.15 348.36 344.17 330.39 349.65 347.41 346.79 334.14 349.37 345.79 349.25"
          />
          <polyline
            class="goose-11"
            points="348.2 347.23 332.83 349.63 345.69 349.97 346.61 350 328.65 355.48 345.67 352.62 332.4 355.19 344.05 355.08"
          />
          <polyline
            class="goose-11"
            points="350.4 342.74 335.03 345.14 347.89 345.48 348.81 345.51 330.85 350.99 347.87 348.13 334.6 350.7 346.25 350.59"
          />
          <polyline
            class="goose-11"
            points="356.76 326.78 344.44 326.86 354.11 329.04 354.8 329.19 339.68 331.86 353.37 331.56 342.61 332.13 351.48 333.68"
          />
          <path
            class="goose-11"
            d="m362.48,316.89s-5.94-2.58-7.83-2.16,11.46-1.09,11.46-1.09"
          />
          <path
            class="goose-11"
            d="m366.46,313.33s-5.94-.26-7.68.48,9.85-2.43,9.85-2.43l-2.18,1.95Z"
          />
          <polygon
            class="goose-11"
            points="369.68 310.45 364.85 310.14 366.49 311.97 369.68 310.45"
          />
          <polygon
            class="goose-11"
            points="370.55 308.14 365.73 306.79 369.68 310.45 372.73 307.72 370.55 308.14"
          />
          <path
            class="goose-11"
            d="m376.48,306.6c.09.2-4.73-5.19-7.81-3.25l6.06,4.04,1.74-.79Z"
          />
          <polygon
            class="goose-11"
            points="373.96 307.57 368.48 305.08 372.41 309.47 373.96 307.57"
          />
          <path
            class="goose-11"
            d="m377.37,303.66s-5.11-4.47-8.58-2.91c0,0,9.02,3.22,7.6,5.76s.98-2.84.98-2.84Z"
          />
          <polyline
            class="goose-11"
            points="378.44 304.86 369.19 301.2 375.54 305.82 375.99 306.15 363.61 303.66 373.96 307.57 365.69 304.76 371.69 308.66"
          />
          <path
            class="goose-11"
            d="m380.01,303.33s-8.48-5.7-10.93-5.73,15.16,2.32,15.16,2.32"
          />
          <path
            class="goose-11"
            d="m384.64,299.59s-8.02-2.38-10.2-1.91,12.74-.14,12.74-.14l-2.54,2.05Z"
          />
          <polygon
            class="goose-11"
            points="388.39 296.56 381.85 294.48 384.41 297.65 388.39 296.56"
          />
          <polygon
            class="goose-11"
            points="389.1 293.56 382.37 290 388.39 296.56 391.94 293.69 389.1 293.56"
          />
          <path
            class="goose-11"
            d="m395.97,292.52c-1.99-2.2-7.37-7.82-10.34-6.44l8.56,7.49c.59-.35,1.19-.71,1.79-1.05Z"
          />
          <path
            class="goose-11"
            d="m393.5,293.98l.06-.08-7.85-5.42,5.56,6.88c.74-.47,1.48-.93,2.22-1.38Z"
          />
          <path
            class="goose-11"
            d="m396.59,292.02c.35-1.22.76-2.56.76-2.56,0,0-7.74-8.11-12.08-7.06,0,0,10.11,6.07,11.32,9.62Z"
          />
          <path
            class="goose-11"
            d="m395.97,292.52c.81-.47,1.62-.93,2.44-1.38l-12.51-7.96,9.43,8.75.64.59Z"
          />
          <path
            class="goose-11"
            d="m395.1,293.02c.28-.17.57-.32.85-.49l-17.05-7.72,14.37,8.91,1.83-.69Z"
          />
          <polygon
            class="goose-11"
            points="390.73 294.67 393.26 293.71 381.9 287.07 390.73 294.67"
          />
          <polyline
            class="goose-11"
            points="381.05 302.23 366.1 297.91 377.61 303.65 378.44 304.07 359.84 301.44 376.47 306.04 363.36 302.77 373.97 307.59"
          />
          <polyline
            class="goose-11"
            points="375.6 306.63 360.65 302.31 372.16 308.05 372.99 308.46 354.39 305.84 371.03 310.44 357.91 307.17 368.52 311.98"
          />
          <polyline
            class="goose-11"
            points="371.56 311.18 356.61 306.86 368.12 312.6 368.95 313.01 350.35 310.39 366.99 314.99 353.87 311.72 364.48 316.53"
          />
          <path
            class="goose-11"
            d="m375.19,307.96l-14.69-4.25,11.51,5.74.83.41-18.6-2.62,16.29,4.5,3.51-2.65c.38-.39.76-.76,1.15-1.14Z"
          />
          <polygon
            class="goose-11"
            points="368.37 313.39 370.53 311.76 357.76 308.58 368.37 313.39"
          />
          <polyline
            class="goose-11"
            points="387.96 296.25 376.75 291.12 384.6 297.17 385.16 297.61 370.33 293.64 382.87 299.15 372.87 295.12 380.25 300.27"
          />
          <path
            class="goose-11"
            d="m397.31,289.7s-4.3-4.85-6.19-5.26,10.85,3.85,10.85,3.85"
          />
          <path
            class="goose-11"
            d="m402.42,288.15s-5.27-2.75-7.16-2.8,9.96,1.96,9.96,1.96l-2.8.85Z"
          />
          <polygon
            class="goose-11"
            points="406.56 286.9 402.31 284.58 403.03 286.93 406.56 286.9"
          />
          <path
            class="goose-11"
            d="m406.75,286.84c.95-.46,1.9-.91,2.85-1.35l-1.27-.32-3.8-3.26,2.04,4.98.19-.06Z"
          />
          <path
            class="goose-11"
            d="m413.26,283.82c-.96-1.86-2.54-4.04-4.61-3.78l2.8,4.6c.6-.28,1.21-.55,1.81-.82Z"
          />
          <path
            class="goose-11"
            d="m410.33,285.15c.12-.06.25-.11.37-.17l-2.95-3.46,1.28,4.24c.43-.2.87-.41,1.3-.61Z"
          />
          <path
            class="goose-11"
            d="m414.27,283.36c.48-.22.97-.44,1.45-.66-1.04-1.82-3.19-4.92-5.87-4.96,0,0,3.14,3.1,4.42,5.62Z"
          />
          <path
            class="goose-11"
            d="m412.22,284.29l-8.29-6.11,6.68,6.84c.54-.25,1.08-.49,1.61-.73Z"
          />
          <path
            class="goose-11"
            d="m410.56,285.05l-5.21-4.99,3.59,5.75c.46-.22.92-.44,1.39-.65.08-.04.15-.07.23-.1Z"
          />
          <path
            class="goose-11"
            d="m414.64,283.19l-4.62-4.88,3.08,5.57c.51-.23,1.02-.46,1.53-.7Z"
          />
          <path
            class="goose-11"
            d="m411.01,282.12s-8.48-4.92-10.93-4.95,15.16,2,15.16,2"
          />
          <path
            class="goose-11"
            d="m415.64,278.89s-8.02-2.06-10.2-1.65,12.74-.12,12.74-.12l-2.54,1.77Z"
          />
          <polygon
            class="goose-11"
            points="419.39 276.27 412.85 274.47 415.41 277.21 419.39 276.27"
          />
          <polygon
            class="goose-11"
            points="420.1 273.68 413.37 270.6 419.39 276.27 422.94 273.79 420.1 273.68"
          />
          <path
            class="goose-11"
            d="m427.75,273.51c.16.27-7.38-7.79-11.12-6.3l8.93,6.76,2.18-.46Z"
          />
          <polygon
            class="goose-11"
            points="424.57 273.97 416.72 269.28 422.86 275.85 424.57 273.97"
          />
          <path
            class="goose-11"
            d="m428.36,270.13s-7.74-7.01-12.08-6.1c0,0,12.74,6.61,11.34,9.33s.75-3.23.75-3.23Z"
          />
          <polyline
            class="goose-11"
            points="430.03 271.92 416.9 264.7 426.33 272.26 427.01 272.81 409.9 266.11 424.57 273.97 412.9 268.07 421.73 274.64"
          />
          <polyline
            class="goose-11"
            points="412.05 281.17 397.1 277.44 408.61 282.4 409.44 282.76 390.84 280.49 407.47 284.46 394.36 281.64 404.97 285.8"
          />
          <polyline
            class="goose-11"
            points="406.6 284.97 391.65 281.24 403.16 286.2 403.99 286.56 385.39 284.29 402.03 288.27 388.91 285.44 399.52 289.6"
          />
          <path
            class="goose-11"
            d="m399.76,290.41c.86-.46,1.72-.92,2.58-1.36l.22-.14-14.95-3.73,11.51,4.96.64.27Z"
          />
          <path
            class="goose-11"
            d="m384.87,289.37l9.86,3.86c.73-.43,1.46-.85,2.2-1.27l-12.06-2.6Z"
          />
          <path
            class="goose-11"
            d="m399.67,290.46l-18.32-2.23,15.61,3.73c.9-.51,1.8-1.01,2.71-1.5Z"
          />
          <path
            class="goose-11"
            d="m399.16,290.73c.65-.35,1.3-.71,1.96-1.06l.41-.27-12.76-2.75,10.39,4.08Z"
          />
          <polygon
            class="goose-11"
            points="401.54 289.4 406.45 286.19 391.5 282.45 403.01 287.42 403.84 287.77 385.24 285.51 401.54 289.4"
          />
          <polyline
            class="goose-11"
            points="418.96 276 407.75 271.57 415.6 276.8 416.16 277.18 401.33 273.75 413.87 278.51 403.87 275.02 411.25 279.48"
          />
          <path
            class="goose-11"
            d="m428.31,270.34s-4.3-4.19-6.19-4.55,10.85,3.33,10.85,3.33"
          />
          <path
            class="goose-11"
            d="m433.42,269.01s-5.27-2.37-7.16-2.42,9.96,1.69,9.96,1.69l-2.8.73Z"
          />
          <polygon
            class="goose-11"
            points="437.56 267.92 433.31 265.92 434.03 267.95 437.56 267.92"
          />
          <polygon
            class="goose-11"
            points="439.32 266.43 435.52 263.62 437.56 267.92 441.48 266.9 439.32 266.43"
          />
          <path
            class="goose-11"
            d="m445.34,267.39c0,.19-2.1-5.79-5.7-5.39l3.79,5.38,1.91.02Z"
          />
          <polygon
            class="goose-11"
            points="442.66 267.23 438.75 263.28 440.45 268.15 442.66 267.23"
          />
          <path
            class="goose-11"
            d="m447.39,265.41s-2.75-5.36-6.54-5.41c0,0,6.82,5.81,4.46,7.28s2.09-1.87,2.09-1.87Z"
          />
          <polyline
            class="goose-11"
            points="447.86 266.74 441.03 260.5 444.82 266.43 445.1 266.86 434.92 260.39 442.66 267.23 436.35 262.01 440.14 267.25"
          />
          <path
            class="goose-11"
            d="m429.61,273.83s-8.08-5.56-10.52-5.77,14.96,3.16,14.96,3.16"
          />
          <path
            class="goose-11"
            d="m434.47,270.96s-7.84-2.67-10.05-2.42,12.71.85,12.71.85l-2.66,1.57Z"
          />
          <polygon
            class="goose-11"
            points="438.42 268.64 432.03 266.34 434.38 269.27 438.42 268.64"
          />
          <polygon
            class="goose-11"
            points="439.32 266.1 432.84 262.52 438.42 268.64 442.15 266.44 439.32 266.1"
          />
          <path
            class="goose-11"
            d="m446.96,266.52c.14.28-6.76-8.34-10.6-7.13l8.39,7.42,2.21-.3Z"
          />
          <polygon
            class="goose-11"
            points="443.75 266.74 436.28 261.46 441.91 268.49 443.75 266.74"
          />
          <path
            class="goose-11"
            d="m447.82,263.2s-7.18-7.58-11.58-7.01c0,0,12.2,7.56,10.59,10.17s.99-3.16.99-3.16Z"
          />
          <polyline
            class="goose-11"
            points="449.35 265.11 436.82 256.91 445.64 265.17 446.27 265.77 429.73 257.78 443.75 266.74 432.57 259.96 440.87 267.19"
          />
          <polyline
            class="goose-11"
            points="430.72 272.96 416.1 268.1 427.2 273.93 427.99 274.35 409.62 270.67 425.91 275.9 413.05 272.08 423.3 277.04"
          />
          <polyline
            class="goose-11"
            points="425 276.34 410.38 271.47 421.48 277.3 422.27 277.72 403.9 274.04 420.19 279.27 407.33 275.46 417.58 280.41"
          />
          <path
            class="goose-11"
            d="m413.04,283.92c.8-.36,1.6-.73,2.39-1.09l.09-.05-12.52-3.71,10.04,4.85Z"
          />
          <polygon
            class="goose-11"
            points="415.53 282.78 420.67 279.95 406.05 275.08 417.15 280.91 417.95 281.33 399.58 277.65 415.53 282.78"
          />
          <polyline
            class="goose-11"
            points="424.75 277.54 410.14 272.67 421.23 278.5 422.03 278.92 403.66 275.24 419.94 280.47 407.08 276.66 417.34 281.61"
          />
          <polyline
            class="goose-11"
            points="425.14 318.06 438.41 325.88 428.78 317.86 428.09 317.28 445.28 324.72 430.45 316.2 442.23 322.62 433.24 315.63"
          />
          <polyline
            class="goose-11"
            points="423.14 321.06 436.41 328.88 426.78 320.86 426.09 320.28 443.28 327.72 428.45 319.2 440.23 325.62 431.24 318.63"
          />
          <polyline
            class="goose-11"
            points="418.14 325.06 431.41 332.88 421.78 324.86 421.09 324.28 438.28 331.72 423.45 323.2 435.23 329.62 426.24 322.63"
          />
          <polyline
            class="goose-11"
            points="413.14 327.06 426.41 334.88 416.78 326.86 416.09 326.28 433.28 333.72 418.45 325.2 430.23 331.62 421.24 324.63"
          />
          <polyline
            class="goose-11"
            points="411.14 330.06 424.41 337.88 414.78 329.86 414.09 329.28 431.28 336.72 416.45 328.2 428.23 334.62 419.24 327.63"
          />
          <polyline
            class="goose-11"
            points="406.14 334.06 419.41 341.88 409.78 333.86 409.09 333.28 426.28 340.72 411.45 332.2 423.23 338.62 414.24 331.63"
          />
          <polyline
            class="goose-11"
            points="399.14 337.06 412.41 344.88 402.78 336.86 402.09 336.28 419.28 343.72 404.45 335.2 416.23 341.62 407.24 334.63"
          />
          <polyline
            class="goose-11"
            points="397.14 340.06 410.41 347.88 400.78 339.86 400.09 339.28 417.28 346.72 402.45 338.2 414.23 344.62 405.24 337.63"
          />
          <polyline
            class="goose-11"
            points="392.14 344.06 405.41 351.88 395.78 343.86 395.09 343.28 412.28 350.72 397.45 342.2 409.23 348.62 400.24 341.63"
          />
          <polyline
            class="goose-11"
            points="393.14 345.06 406.41 352.88 396.78 344.86 396.09 344.28 413.28 351.72 398.45 343.2 410.23 349.62 401.24 342.63"
          />
          <polyline
            class="goose-11"
            points="391.14 348.06 404.41 355.88 394.78 347.86 394.09 347.28 411.28 354.72 396.45 346.2 408.23 352.62 399.24 345.63"
          />
          <polyline
            class="goose-11"
            points="386.14 352.06 399.41 359.88 389.78 351.86 389.09 351.28 406.28 358.72 391.45 350.2 403.23 356.62 394.24 349.63"
          />
          <polyline
            class="goose-11"
            points="389.9 354.64 402.03 359.02 393.77 353.61 393.17 353.23 409.48 356.36 395.9 351.66 406.76 355.06 398.94 350.49"
          />
          <polyline
            class="goose-11"
            points="387.12 357.94 399.25 362.31 390.99 356.91 390.39 356.52 406.7 359.65 393.12 354.96 403.99 358.36 396.16 353.79"
          />
          <polyline
            class="goose-11"
            points="380.98 362.87 393.1 367.24 384.84 361.84 384.25 361.45 400.56 364.58 386.97 359.89 397.84 363.29 390.01 358.71"
          />
          <polyline
            class="goose-11"
            points="382.62 366.55 395.5 366.93 385.97 364.37 385.28 364.19 401.75 362.07 387.38 361.85 398.77 361.69 389.91 359.79"
          />
          <polyline
            class="goose-11"
            points="381.01 370.55 393.89 370.93 384.36 368.37 383.67 368.19 400.14 366.07 385.77 365.85 397.16 365.69 388.3 363.79"
          />
          <polyline
            class="goose-11"
            points="376.7 377.15 389.59 377.53 380.05 374.97 379.37 374.79 395.84 372.67 381.47 372.45 392.85 372.29 383.99 370.39"
          />
          <polyline
            class="goose-11"
            points="375.62 378.55 388.5 378.93 378.97 376.37 378.28 376.19 394.75 374.07 380.38 373.85 391.77 373.69 382.91 371.79"
          />
          <polyline
            class="goose-11"
            points="374.01 382.55 386.89 382.93 377.36 380.37 376.67 380.19 393.14 378.07 378.77 377.85 390.16 377.69 381.3 375.79"
          />
          <polyline
            class="goose-11"
            points="369.7 389.15 382.59 389.53 373.05 386.97 372.37 386.79 388.84 384.67 374.47 384.45 385.85 384.29 376.99 382.39"
          />
          <polyline
            class="goose-11"
            points="372.11 397.18 382.59 389.68 373.46 393.42 372.8 393.69 384.63 382.03 373.06 390.56 382.02 383.54 373.82 387.39"
          />
          <polyline
            class="goose-11"
            points="373.25 401.34 383.73 393.84 374.6 397.58 373.94 397.85 385.77 386.19 374.2 394.72 383.16 387.69 374.96 391.54"
          />
          <polyline
            class="goose-11"
            points="373.82 409.2 384.3 401.7 375.17 405.44 374.51 405.7 386.34 394.05 374.77 402.58 383.74 395.55 375.53 399.4"
          />
          <polyline
            class="goose-11"
            points="372.11 418.18 382.59 410.68 373.46 414.42 372.8 414.69 384.63 403.03 373.06 411.56 382.02 404.54 373.82 408.39"
          />
          <polyline
            class="goose-11"
            points="373.25 422.34 383.73 414.84 374.6 418.58 373.94 418.85 385.77 407.19 374.2 415.72 383.16 408.69 374.96 412.54"
          />
          <polyline
            class="goose-11"
            points="373.82 430.2 384.3 422.7 375.17 426.44 374.51 426.7 386.34 415.05 374.77 423.58 383.74 416.55 375.53 420.4"
          />
          <polyline
            class="goose-11"
            points="375.11 434.18 385.59 426.68 376.46 430.42 375.8 430.69 387.63 419.03 376.06 427.56 385.02 420.54 376.82 424.39"
          />
          <polyline
            class="goose-11"
            points="376.25 438.34 386.73 430.84 377.6 434.58 376.94 434.85 388.77 423.19 377.2 431.72 386.16 424.69 377.96 428.54"
          />
          <polyline
            class="goose-11"
            points="376.82 446.2 387.3 438.7 378.17 442.44 377.51 442.7 389.34 431.05 377.77 439.58 386.74 432.55 378.53 436.4"
          />
          <polyline
            class="goose-11"
            points="381.02 446.43 388.47 435.91 381.06 442.43 380.52 442.9 387.89 428.01 379.75 439.86 385.92 430.29 379.43 436.61"
          />
          <polyline
            class="goose-11"
            points="415.02 496.43 422.47 485.91 415.06 492.43 414.52 492.9 421.89 478.01 413.75 489.86 419.92 480.29 413.43 486.61"
          />
          <polyline
            class="goose-11"
            points="415.02 499.43 422.47 488.91 415.06 495.43 414.52 495.9 421.89 481.01 413.75 492.86 419.92 483.29 413.43 489.61"
          />
          <polyline
            class="goose-11"
            points="412.02 495.43 419.47 484.91 412.06 491.43 411.52 491.9 418.89 477.01 410.75 488.86 416.92 479.29 410.43 485.61"
          />
          <polyline
            class="goose-11"
            points="407.02 488.43 414.47 477.91 407.06 484.43 406.52 484.9 413.89 470.01 405.75 481.86 411.92 472.29 405.43 478.61"
          />
          <polyline
            class="goose-11"
            points="383.45 449.98 390.91 439.47 383.49 445.99 382.96 446.45 390.33 431.57 382.18 443.41 388.36 433.84 381.86 440.17"
          />
          <polyline
            class="goose-11"
            points="386.56 457.22 394.02 446.71 386.6 453.23 386.07 453.69 393.44 438.81 385.29 450.65 391.47 441.08 384.97 447.4"
          />
          <polyline
            class="goose-11"
            points="390.02 461.43 397.47 450.91 390.06 457.43 389.52 457.9 396.89 443.01 388.75 454.86 394.92 445.29 388.43 451.61"
          />
          <polyline
            class="goose-11"
            points="392.45 464.98 399.91 454.47 392.49 460.99 391.96 461.45 399.33 446.57 391.18 458.41 397.36 448.84 390.86 455.17"
          />
          <polyline
            class="goose-11"
            points="395.56 472.22 403.02 461.71 395.6 468.23 395.07 468.69 402.44 453.81 394.29 465.65 400.47 456.08 393.97 462.4"
          />
          <polyline
            class="goose-11"
            points="387.02 453.43 394.47 442.91 387.06 449.43 386.52 449.9 393.89 435.01 385.75 446.86 391.92 437.29 385.43 443.61"
          />
          <polyline
            class="goose-11"
            points="389.45 456.98 396.91 446.47 389.49 452.99 388.96 453.45 396.33 438.57 388.18 450.41 394.36 440.84 387.86 447.17"
          />
          <polyline
            class="goose-11"
            points="392.56 464.22 400.02 453.71 392.6 460.23 392.07 460.69 399.44 445.81 391.29 457.65 397.47 448.08 390.97 454.4"
          />
          <polyline
            class="goose-11"
            points="398.02 472.43 405.47 461.91 398.06 468.43 397.52 468.9 404.89 454.01 396.75 465.86 402.92 456.29 396.43 462.61"
          />
          <polyline
            class="goose-11"
            points="400.45 475.98 407.91 465.47 400.49 471.99 399.96 472.45 407.33 457.57 399.18 469.41 405.36 459.84 398.86 466.17"
          />
          <polyline
            class="goose-11"
            points="403.56 483.22 411.02 472.71 403.6 479.23 403.07 479.69 410.44 464.81 402.29 476.65 408.47 467.08 401.97 473.4"
          />
          <polyline
            class="goose-11"
            points="404.02 483.43 411.47 472.91 404.06 479.43 403.52 479.9 410.89 465.01 402.75 476.86 408.92 467.29 402.43 473.61"
          />
          <polyline
            class="goose-11"
            points="406.45 486.98 413.91 476.47 406.49 482.99 405.96 483.45 413.33 468.57 405.18 480.41 411.36 470.84 404.86 477.17"
          />
          <polyline
            class="goose-11"
            points="409.56 494.22 417.02 483.71 409.6 490.23 409.07 490.69 416.44 475.81 408.29 487.65 414.47 478.08 407.97 484.4"
          />
          <polyline
            class="goose-11"
            points="431.19 506.94 435.76 494.88 430.23 503.06 429.83 503.65 433.21 487.39 428.31 500.9 431.87 490.08 427.18 497.84"
          />
          <polyline
            class="goose-11"
            points="431.95 509.84 436.51 497.79 430.98 505.96 430.59 506.55 433.96 490.29 429.06 503.8 432.63 492.99 427.94 500.74"
          />
          <polyline
            class="goose-11"
            points="428.04 506.72 432.6 494.67 427.07 502.85 426.67 503.43 430.05 487.17 425.15 500.69 428.72 489.87 424.03 497.63"
          />
          <polyline
            class="goose-11"
            points="421.44 501.21 426 489.16 420.47 497.33 420.07 497.92 423.45 481.66 418.55 495.17 422.12 484.36 417.42 492.11"
          />
          <polyline
            class="goose-11"
            points="420.53 499.96 425.09 487.9 419.56 496.08 419.16 496.67 422.54 480.41 417.64 493.92 421.21 483.1 416.51 490.86"
          />
          <polyline
            class="goose-11"
            points="425.36 506.18 429.92 494.12 424.39 502.3 424 502.89 427.38 486.63 422.48 500.14 426.04 489.32 421.35 497.08"
          />
          <polyline
            class="goose-11"
            points="446.7 513.58 446.15 500.7 444.28 510.39 444.15 511.09 440.85 494.81 441.67 509.16 440.69 497.82 439.43 506.79"
          />
          <polyline
            class="goose-11"
            points="448.54 515.95 447.98 503.07 446.12 512.76 445.99 513.46 442.69 497.18 443.51 511.53 442.53 500.19 441.27 509.16"
          />
          <polyline
            class="goose-11"
            points="443.72 514.62 443.16 501.75 441.3 511.44 441.17 512.14 437.87 495.86 438.69 510.21 437.71 498.86 436.44 507.84"
          />
          <polyline
            class="goose-11"
            points="435.48 512.15 434.92 499.28 433.06 508.97 432.93 509.67 429.63 493.39 430.45 507.74 429.47 496.39 428.2 505.37"
          />
          <polyline
            class="goose-11"
            points="434.15 511.36 433.59 498.48 431.73 508.17 431.6 508.87 428.3 492.59 429.12 506.94 428.14 495.6 426.87 504.57"
          />
          <polyline
            class="goose-11"
            points="441.04 515.18 440.49 502.3 438.62 511.99 438.49 512.69 435.19 496.41 436.01 510.76 435.03 499.41 433.77 508.39"
          />
          <polyline
            class="goose-11"
            points="463.7 520.58 463.15 507.7 461.28 517.39 461.15 518.09 457.85 501.81 458.67 516.16 457.69 504.82 456.43 513.79"
          />
          <polyline
            class="goose-11"
            points="465.54 522.95 464.98 510.07 463.12 519.76 462.99 520.46 459.69 504.18 460.51 518.53 459.53 507.19 458.27 516.16"
          />
          <polyline
            class="goose-11"
            points="460.72 521.62 460.16 508.75 458.3 518.44 458.17 519.14 454.87 502.86 455.69 517.21 454.71 505.86 453.44 514.84"
          />
          <polyline
            class="goose-11"
            points="452.48 519.15 451.92 506.28 450.06 515.97 449.93 516.67 446.63 500.39 447.45 514.74 446.47 503.39 445.2 512.37"
          />
          <polyline
            class="goose-11"
            points="451.15 518.36 450.59 505.48 448.73 515.17 448.6 515.87 445.3 499.59 446.12 513.94 445.14 502.6 443.87 511.57"
          />
          <polyline
            class="goose-11"
            points="458.04 522.18 457.49 509.3 455.62 518.99 455.49 519.69 452.19 503.41 453.01 517.76 452.03 506.41 450.77 515.39"
          />
          <polyline
            class="goose-11"
            points="473.7 527.58 473.15 514.7 471.28 524.39 471.15 525.09 467.85 508.81 468.67 523.16 467.69 511.82 466.43 520.79"
          />
          <polyline
            class="goose-11"
            points="475.54 529.95 474.98 517.07 473.12 526.76 472.99 527.46 469.69 511.18 470.51 525.53 469.53 514.19 468.27 523.16"
          />
          <polyline
            class="goose-11"
            points="470.72 528.62 470.16 515.75 468.3 525.44 468.17 526.14 464.87 509.86 465.69 524.21 464.71 512.86 463.44 521.84"
          />
          <polyline
            class="goose-11"
            points="462.48 526.15 461.92 513.28 460.06 522.97 459.93 523.67 456.63 507.39 457.45 521.74 456.47 510.39 455.2 519.37"
          />
          <polyline
            class="goose-11"
            points="461.15 525.36 460.59 512.48 458.73 522.17 458.6 522.87 455.3 506.59 456.12 520.94 455.14 509.6 453.87 518.57"
          />
          <polyline
            class="goose-11"
            points="468.04 529.18 467.49 516.3 465.62 525.99 465.49 526.69 462.19 510.41 463.01 524.76 462.03 513.41 460.77 522.39"
          />
          <polyline
            class="goose-11"
            points="490.7 533.58 490.15 520.7 488.28 530.39 488.15 531.09 484.85 514.81 485.67 529.16 484.69 517.82 483.43 526.79"
          />
          <polyline
            class="goose-11"
            points="492.54 535.95 491.98 523.07 490.12 532.76 489.99 533.46 486.69 517.18 487.51 531.53 486.53 520.19 485.27 529.16"
          />
          <polyline
            class="goose-11"
            points="487.72 534.62 487.16 521.75 485.3 531.44 485.17 532.14 481.87 515.86 482.69 530.21 481.71 518.86 480.44 527.84"
          />
          <polyline
            class="goose-11"
            points="479.48 532.15 478.92 519.28 477.06 528.97 476.93 529.67 473.63 513.39 474.45 527.74 473.47 516.39 472.2 525.37"
          />
          <polyline
            class="goose-11"
            points="478.15 531.36 477.59 518.48 475.73 528.17 475.6 528.87 472.3 512.59 473.12 526.94 472.14 515.6 470.87 524.57"
          />
          <polyline
            class="goose-11"
            points="485.04 535.18 484.49 522.3 482.62 531.99 482.49 532.69 479.19 516.41 480.01 530.76 479.03 519.41 477.77 528.39"
          />
          <polyline
            class="goose-11"
            points="506.7 536.58 506.15 523.7 504.28 533.39 504.15 534.09 500.85 517.81 501.67 532.16 500.69 520.82 499.43 529.79"
          />
          <polyline
            class="goose-11"
            points="508.54 538.95 507.98 526.07 506.12 535.76 505.99 536.46 502.69 520.18 503.51 534.53 502.53 523.19 501.27 532.16"
          />
          <polyline
            class="goose-11"
            points="503.72 537.62 503.16 524.75 501.3 534.44 501.17 535.14 497.87 518.86 498.69 533.21 497.71 521.86 496.44 530.84"
          />
          <polyline
            class="goose-11"
            points="495.48 535.15 494.92 522.28 493.06 531.97 492.93 532.67 489.63 516.39 490.45 530.74 489.47 519.39 488.2 528.37"
          />
          <polyline
            class="goose-11"
            points="494.15 534.36 493.59 521.48 491.73 531.17 491.6 531.87 488.3 515.59 489.12 529.94 488.14 518.6 486.87 527.57"
          />
          <polyline
            class="goose-11"
            points="501.04 538.18 500.49 525.3 498.62 534.99 498.49 535.69 495.19 519.41 496.01 533.76 495.03 522.41 493.77 531.39"
          />
          <polyline
            class="goose-11"
            points="524.7 537.58 524.15 524.7 522.28 534.39 522.15 535.09 518.85 518.81 519.67 533.16 518.69 521.82 517.43 530.79"
          />
          <polyline
            class="goose-11"
            points="526.54 539.95 525.98 527.07 524.12 536.76 523.99 537.46 520.69 521.18 521.51 535.53 520.53 524.19 519.27 533.16"
          />
          <polyline
            class="goose-11"
            points="521.72 538.62 521.16 525.75 519.3 535.44 519.17 536.14 515.87 519.86 516.69 534.21 515.71 522.86 514.44 531.84"
          />
          <polyline
            class="goose-11"
            points="513.48 536.15 512.92 523.28 511.06 532.97 510.93 533.67 507.63 517.39 508.45 531.74 507.47 520.39 506.2 529.37"
          />
          <polyline
            class="goose-11"
            points="512.15 535.36 511.59 522.48 509.73 532.17 509.6 532.87 506.3 516.59 507.12 530.94 506.14 519.6 504.87 528.57"
          />
          <polyline
            class="goose-11"
            points="519.04 539.18 518.49 526.3 516.62 535.99 516.49 536.69 513.19 520.41 514.01 534.76 513.03 523.41 511.77 532.39"
          />
          <polyline
            class="goose-11"
            points="373.46 429.3 384.76 423.09 375.24 425.72 374.56 425.91 387.68 415.73 375.19 422.83 384.92 416.92 376.32 419.77"
          />
          <polyline
            class="goose-11"
            points="372.15 432 383.45 425.79 373.94 428.42 373.25 428.61 386.38 418.43 373.88 425.53 383.61 419.62 375.01 422.47"
          />
          <polyline
            class="goose-11"
            points="371.2 427.09 382.49 420.88 372.98 423.51 372.3 423.7 385.42 413.52 372.92 420.62 382.66 414.71 374.05 417.56"
          />
          <polyline
            class="goose-11"
            points="369.75 418.61 381.05 412.4 371.54 415.03 370.85 415.22 383.98 405.04 371.48 412.14 381.21 406.23 372.61 409.08"
          />
          <polyline
            class="goose-11"
            points="369.88 417.07 381.17 410.86 371.66 413.49 370.98 413.68 384.1 403.5 371.6 410.6 381.33 404.69 372.73 407.54"
          />
          <polyline
            class="goose-11"
            points="369.52 424.94 380.81 418.73 371.3 421.36 370.62 421.55 383.74 411.37 371.24 418.47 380.97 412.56 372.37 415.41"
          />
          <polyline
            class="goose-11"
            points="373.46 403.3 384.76 397.09 375.24 399.72 374.56 399.91 387.68 389.73 375.19 396.83 384.92 390.92 376.32 393.77"
          />
          <polyline
            class="goose-11"
            points="372.15 406 383.45 399.79 373.94 402.42 373.25 402.61 386.38 392.43 373.88 399.53 383.61 393.62 375.01 396.47"
          />
          <polyline
            class="goose-11"
            points="371.2 401.09 382.49 394.88 372.98 397.51 372.3 397.7 385.42 387.52 372.92 394.62 382.66 388.71 374.05 391.56"
          />
          <polyline
            class="goose-11"
            points="369.75 392.61 381.05 386.4 371.54 389.03 370.85 389.22 383.98 379.04 371.48 386.14 381.21 380.23 372.61 383.08"
          />
          <polyline
            class="goose-11"
            points="369.88 391.07 381.17 384.86 371.66 387.49 370.98 387.68 384.1 377.5 371.6 384.6 381.33 378.69 372.73 381.54"
          />
          <polyline
            class="goose-11"
            points="369.52 398.94 380.81 392.73 371.3 395.36 370.62 395.55 383.74 385.37 371.24 392.47 380.97 386.56 372.37 389.41"
          />
          <polyline
            class="goose-11"
            points="378.95 369.46 391.79 370.56 382.42 367.48 381.74 367.25 398.31 366.06 383.97 365.04 395.35 365.51 386.61 363.12"
          />
          <polyline
            class="goose-11"
            points="376.36 370.99 389.21 372.08 379.83 369 379.16 368.77 395.72 367.58 381.38 366.56 392.76 367.03 384.02 364.64"
          />
          <polyline
            class="goose-11"
            points="378.29 366.37 391.13 367.47 381.76 364.38 381.08 364.16 397.65 362.97 383.31 361.95 394.69 362.42 385.95 360.03"
          />
          <polyline
            class="goose-11"
            points="381.79 358.51 394.63 359.61 385.26 356.52 384.58 356.3 401.15 355.11 386.81 354.09 398.19 354.56 389.45 352.17"
          />
          <polyline
            class="goose-11"
            points="382.75 357.3 395.59 358.39 386.22 355.31 385.54 355.09 402.11 353.89 387.77 352.87 399.15 353.35 390.41 350.95"
          />
          <polyline
            class="goose-11"
            points="378.09 363.65 390.93 364.74 381.55 361.66 380.88 361.44 397.44 360.24 383.11 359.22 394.48 359.7 385.74 357.3"
          />
          <polyline
            class="goose-11"
            points="395.95 349.46 408.79 350.56 399.42 347.48 398.74 347.25 415.31 346.06 400.97 345.04 412.35 345.51 403.61 343.12"
          />
          <polyline
            class="goose-11"
            points="393.36 350.99 406.21 352.08 396.83 349 396.16 348.77 412.72 347.58 398.38 346.56 409.76 347.03 401.02 344.64"
          />
          <polyline
            class="goose-11"
            points="395.29 346.37 408.13 347.47 398.76 344.38 398.08 344.16 414.65 342.97 400.31 341.95 411.69 342.42 402.95 340.03"
          />
          <polyline
            class="goose-11"
            points="398.79 338.51 411.63 339.61 402.26 336.52 401.58 336.3 418.15 335.11 403.81 334.09 415.19 334.56 406.45 332.17"
          />
          <polyline
            class="goose-11"
            points="399.75 337.3 412.59 338.39 403.22 335.31 402.54 335.09 419.11 333.89 404.77 332.87 416.15 333.35 407.41 330.95"
          />
          <polyline
            class="goose-11"
            points="395.09 343.65 407.93 344.74 398.55 341.66 397.88 341.44 414.44 340.24 400.11 339.22 411.48 339.7 402.74 337.3"
          />
          <polyline
            class="goose-11"
            points="415.95 334.46 428.79 335.56 419.42 332.48 418.74 332.25 435.31 331.06 420.97 330.04 432.35 330.51 423.61 328.12"
          />
          <polyline
            class="goose-11"
            points="413.36 335.99 426.21 337.08 416.83 334 416.16 333.77 432.72 332.58 418.38 331.56 429.76 332.03 421.02 329.64"
          />
          <polyline
            class="goose-11"
            points="415.29 331.37 428.13 332.47 418.76 329.38 418.08 329.16 434.65 327.97 420.31 326.95 431.69 327.42 422.95 325.03"
          />
          <polyline
            class="goose-11"
            points="418.79 323.51 431.63 324.61 422.26 321.52 421.58 321.3 438.15 320.11 423.81 319.09 435.19 319.56 426.45 317.17"
          />
          <polyline
            class="goose-11"
            points="419.75 322.3 432.59 323.39 423.22 320.31 422.54 320.09 439.11 318.89 424.77 317.87 436.15 318.35 427.41 315.95"
          />
          <polyline
            class="goose-11"
            points="415.09 328.65 427.93 329.74 418.55 326.66 417.88 326.44 434.44 325.24 420.11 324.22 431.48 324.7 422.74 322.3"
          />
          <polyline
            class="goose-11"
            points="425.46 311.52 433.45 321.63 429.29 312.69 428.99 312.04 441.19 323.3 432.12 312.15 439.56 320.77 435.33 312.76"
          />
          <polyline
            class="goose-11"
            points="422.58 310.67 430.57 320.79 426.41 311.84 426.11 311.2 438.31 322.46 429.25 311.31 436.69 319.93 432.45 311.91"
          />
          <polyline
            class="goose-11"
            points="427.27 308.92 435.26 319.04 431.09 310.09 430.79 309.45 443 320.71 433.93 309.56 441.37 318.18 437.13 310.16"
          />
          <polyline
            class="goose-11"
            points="435.39 306.1 443.38 316.21 439.22 307.26 438.92 306.62 451.12 317.88 442.05 306.73 449.49 315.35 445.26 307.34"
          />
          <polyline
            class="goose-11"
            points="436.94 305.96 444.93 316.08 440.76 307.13 440.46 306.49 452.66 317.75 443.6 306.6 451.04 315.22 446.8 307.2"
          />
          <polyline
            class="goose-11"
            points="429.11 306.91 437.1 317.02 432.94 308.07 432.64 307.43 444.84 318.69 435.78 307.54 443.22 316.16 438.98 308.15"
          />
          <polyline
            class="goose-11"
            points="442.46 310.52 450.45 320.63 446.29 311.69 445.99 311.04 458.19 322.3 449.12 311.15 456.56 319.77 452.33 311.76"
          />
          <polyline
            class="goose-11"
            points="439.58 309.67 447.57 319.79 443.41 310.84 443.11 310.2 455.31 321.46 446.25 310.31 453.69 318.93 449.45 310.91"
          />
          <polyline
            class="goose-11"
            points="444.27 307.92 452.26 318.04 448.09 309.09 447.79 308.45 460 319.71 450.93 308.56 458.37 317.18 454.13 309.16"
          />
          <polyline
            class="goose-11"
            points="452.39 305.1 460.38 315.21 456.22 306.26 455.92 305.62 468.12 316.88 459.05 305.73 466.49 314.35 462.26 306.34"
          />
          <polyline
            class="goose-11"
            points="453.94 304.96 461.93 315.08 457.76 306.13 457.46 305.49 469.66 316.75 460.6 305.6 468.04 314.22 463.8 306.2"
          />
          <polyline
            class="goose-11"
            points="446.11 305.91 454.1 316.02 449.94 307.07 449.64 306.43 461.84 317.69 452.78 306.54 460.22 315.16 455.98 307.15"
          />
          <polyline
            class="goose-11"
            points="446.46 307.52 454.45 317.63 450.29 308.69 449.99 308.04 462.19 319.3 453.12 308.15 460.56 316.77 456.33 308.76"
          />
          <polyline
            class="goose-11"
            points="443.58 306.67 451.57 316.79 447.41 307.84 447.11 307.2 459.31 318.46 450.25 307.31 457.69 315.93 453.45 307.91"
          />
          <polyline
            class="goose-11"
            points="448.27 304.92 456.26 315.04 452.09 306.09 451.79 305.45 464 316.71 454.93 305.56 462.37 314.18 458.13 306.16"
          />
          <polyline
            class="goose-11"
            points="456.39 302.1 464.38 312.21 460.22 303.26 459.92 302.62 472.12 313.88 463.05 302.73 470.49 311.35 466.26 303.34"
          />
          <polyline
            class="goose-11"
            points="457.94 301.96 465.93 312.08 461.76 303.13 461.46 302.49 473.66 313.75 464.6 302.6 472.04 311.22 467.8 303.2"
          />
          <polyline
            class="goose-11"
            points="450.11 302.91 458.1 313.02 453.94 304.07 453.64 303.43 465.84 314.69 456.78 303.54 464.22 312.16 459.98 304.15"
          />
          <polyline
            class="goose-11"
            points="461.46 308.52 469.45 318.63 465.29 309.69 464.99 309.04 477.19 320.3 468.12 309.15 475.56 317.77 471.33 309.76"
          />
          <polyline
            class="goose-11"
            points="458.58 307.67 466.57 317.79 462.41 308.84 462.11 308.2 474.31 319.46 465.25 308.31 472.69 316.93 468.45 308.91"
          />
          <polyline
            class="goose-11"
            points="463.27 305.92 471.26 316.04 467.09 307.09 466.79 306.45 479 317.71 469.93 306.56 477.37 315.18 473.13 307.16"
          />
          <polyline
            class="goose-11"
            points="471.39 303.1 479.38 313.21 475.22 304.26 474.92 303.62 487.12 314.88 478.05 303.73 485.49 312.35 481.26 304.34"
          />
          <polyline
            class="goose-11"
            points="472.94 302.96 480.93 313.08 476.76 304.13 476.46 303.49 488.66 314.75 479.6 303.6 487.04 312.22 482.8 304.2"
          />
          <polyline
            class="goose-11"
            points="465.11 303.91 473.1 314.02 468.94 305.07 468.64 304.43 480.84 315.69 471.78 304.54 479.22 313.16 474.98 305.15"
          />
          <polyline
            class="goose-11"
            points="462.87 300.79 466.01 313.29 465.88 303.42 465.87 302.71 472.39 317.99 468.69 304.1 471.94 315.01 471.36 305.97"
          />
          <polyline
            class="goose-11"
            points="460.59 298.84 463.74 311.34 463.6 301.47 463.59 300.76 470.11 316.04 466.41 302.15 469.66 313.06 469.08 304.02"
          />
          <polyline
            class="goose-11"
            points="465.58 299.16 468.73 311.66 468.59 301.8 468.58 301.09 475.1 316.36 471.4 302.47 474.65 313.38 474.07 304.34"
          />
          <polyline
            class="goose-11"
            points="474.15 299.92 477.29 312.42 477.16 302.55 477.15 301.84 483.67 317.11 479.97 303.22 483.22 314.14 482.64 305.09"
          />
          <polyline
            class="goose-11"
            points="475.61 300.43 478.76 312.93 478.62 303.06 478.61 302.35 485.13 317.62 481.43 303.73 484.68 314.65 484.11 305.6"
          />
          <polyline
            class="goose-11"
            points="468.09 298.08 471.23 310.58 471.1 300.71 471.09 300 477.61 315.28 473.91 301.39 477.16 312.3 476.58 303.26"
          />
          <polyline
            class="goose-11"
            points="479.87 299.79 483.01 312.29 482.88 302.42 482.87 301.71 489.39 316.99 485.69 303.1 488.94 314.01 488.36 304.97"
          />
          <polyline
            class="goose-11"
            points="477.59 297.84 480.74 310.34 480.6 300.47 480.59 299.76 487.11 315.04 483.41 301.15 486.66 312.06 486.08 303.02"
          />
          <polyline
            class="goose-11"
            points="482.58 298.16 485.73 310.66 485.59 300.8 485.58 300.09 492.1 315.36 488.4 301.47 491.65 312.38 491.07 303.34"
          />
          <polyline
            class="goose-11"
            points="491.15 298.92 494.29 311.42 494.16 301.55 494.15 300.84 500.67 316.11 496.97 302.22 500.22 313.14 499.64 304.09"
          />
          <polyline
            class="goose-11"
            points="492.61 299.43 495.76 311.93 495.62 302.06 495.61 301.35 502.13 316.62 498.43 302.73 501.68 313.65 501.11 304.6"
          />
          <polyline
            class="goose-11"
            points="485.09 297.08 488.23 309.58 488.1 299.71 488.09 299 494.61 314.28 490.91 300.39 494.16 311.3 493.58 302.26"
          />
          <polyline
            class="goose-11"
            points="493.87 299.79 497.01 312.29 496.88 302.42 496.87 301.71 503.39 316.99 499.69 303.1 502.94 314.01 502.36 304.97"
          />
          <polyline
            class="goose-11"
            points="491.59 297.84 494.74 310.34 494.6 300.47 494.59 299.76 501.11 315.04 497.41 301.15 500.66 312.06 500.08 303.02"
          />
          <polyline
            class="goose-11"
            points="496.58 298.16 499.73 310.66 499.59 300.8 499.58 300.09 506.1 315.36 502.4 301.47 505.65 312.38 505.07 303.34"
          />
          <polyline
            class="goose-11"
            points="505.15 298.92 508.29 311.42 508.16 301.55 508.15 300.84 514.67 316.11 510.97 302.22 514.22 313.14 513.64 304.09"
          />
          <polyline
            class="goose-11"
            points="506.61 299.43 509.76 311.93 509.62 302.06 509.61 301.35 516.13 316.62 512.43 302.73 515.68 313.65 515.11 304.6"
          />
          <polyline
            class="goose-11"
            points="499.09 297.08 502.23 309.58 502.1 299.71 502.09 299 508.61 314.28 504.91 300.39 508.16 311.3 507.58 302.26"
          />
          <polyline
            class="goose-11"
            points="510.87 297.79 514.01 310.29 513.88 300.42 513.87 299.71 520.39 314.99 516.69 301.1 519.94 312.01 519.36 302.97"
          />
          <polyline
            class="goose-11"
            points="508.59 295.84 511.74 308.34 511.6 298.47 511.59 297.76 518.11 313.04 514.41 299.15 517.66 310.06 517.08 301.02"
          />
          <polyline
            class="goose-11"
            points="513.58 296.16 516.73 308.66 516.59 298.8 516.58 298.09 523.1 313.36 519.4 299.47 522.65 310.38 522.07 301.34"
          />
          <polyline
            class="goose-11"
            points="522.15 296.92 525.29 309.42 525.16 299.55 525.15 298.84 531.67 314.11 527.97 300.22 531.22 311.14 530.64 302.09"
          />
          <polyline
            class="goose-11"
            points="523.61 297.43 526.76 309.93 526.62 300.06 526.61 299.35 533.13 314.62 529.43 300.73 532.68 311.65 532.11 302.6"
          />
          <polyline
            class="goose-11"
            points="516.09 295.08 519.23 307.58 519.1 297.71 519.09 297 525.61 312.28 521.91 298.39 525.16 309.3 524.58 300.26"
          />
          <polyline
            class="goose-11"
            points="532.5 295.74 530.82 308.52 534.33 299.29 534.58 298.63 535.02 315.23 536.69 300.95 535.7 312.3 538.49 303.68"
          />
          <polyline
            class="goose-11"
            points="531.1 293.08 529.42 305.86 532.93 296.64 533.18 295.98 533.62 312.58 535.29 298.3 534.3 309.65 537.09 301.02"
          />
          <polyline
            class="goose-11"
            points="535.62 295.22 533.94 308 537.45 298.77 537.7 298.11 538.14 314.71 539.81 300.44 538.82 311.78 541.61 303.16"
          />
          <polyline
            class="goose-11"
            points="543.31 299.07 541.63 311.85 545.14 302.63 545.39 301.97 545.83 318.57 547.5 304.29 546.51 315.64 549.3 307.01"
          />
          <polyline
            class="goose-11"
            points="544.48 300.09 542.8 312.87 546.31 303.64 546.56 302.98 547 319.58 548.67 305.3 547.68 316.65 550.47 308.03"
          />
          <polyline
            class="goose-11"
            points="538.35 295.14 536.67 307.92 540.18 298.69 540.43 298.03 540.87 314.63 542.54 300.36 541.55 311.7 544.34 303.08"
          />
          <polyline
            class="goose-11"
            points="545.5 301.74 543.82 314.52 547.33 305.29 547.58 304.63 548.02 321.23 549.69 306.95 548.7 318.3 551.49 309.68"
          />
          <polyline
            class="goose-11"
            points="544.1 299.08 542.42 311.86 545.93 302.64 546.18 301.98 546.62 318.58 548.29 304.3 547.3 315.65 550.09 307.02"
          />
          <polyline
            class="goose-11"
            points="548.62 301.22 546.94 314 550.45 304.77 550.7 304.11 551.14 320.71 552.81 306.44 551.82 317.78 554.61 309.16"
          />
          <polyline
            class="goose-11"
            points="556.31 305.07 554.63 317.85 558.14 308.63 558.39 307.97 558.83 324.57 560.5 310.29 559.51 321.64 562.3 313.01"
          />
          <polyline
            class="goose-11"
            points="557.48 306.09 555.8 318.87 559.31 309.64 559.56 308.98 560 325.58 561.67 311.3 560.68 322.65 563.47 314.03"
          />
          <polyline
            class="goose-11"
            points="551.35 301.14 549.67 313.92 553.18 304.69 553.43 304.03 553.87 320.63 555.54 306.36 554.55 317.7 557.34 309.08"
          />
          <polyline
            class="goose-11"
            points="555.5 307.74 553.82 320.52 557.33 311.29 557.58 310.63 558.02 327.23 559.69 312.95 558.7 324.3 561.49 315.68"
          />
          <polyline
            class="goose-11"
            points="554.1 305.08 552.42 317.86 555.93 308.64 556.18 307.98 556.62 324.58 558.29 310.3 557.3 321.65 560.09 313.02"
          />
          <polyline
            class="goose-11"
            points="558.62 307.22 556.94 320 560.45 310.77 560.7 310.11 561.14 326.71 562.81 312.44 561.82 323.78 564.61 315.16"
          />
          <polyline
            class="goose-11"
            points="566.31 311.07 564.63 323.85 568.14 314.63 568.39 313.97 568.83 330.57 570.5 316.29 569.51 327.64 572.3 319.01"
          />
          <polyline
            class="goose-11"
            points="567.48 312.09 565.8 324.87 569.31 315.64 569.56 314.98 570 331.58 571.67 317.3 570.68 328.65 573.47 320.03"
          />
          <polyline
            class="goose-11"
            points="561.35 307.14 559.67 319.92 563.18 310.69 563.43 310.03 563.87 326.63 565.54 312.36 564.55 323.7 567.34 315.08"
          />
          <polyline
            class="goose-11"
            points="565.5 308.74 563.82 321.52 567.33 312.29 567.58 311.63 568.02 328.23 569.69 313.95 568.7 325.3 571.49 316.68"
          />
          <polyline
            class="goose-11"
            points="564.1 306.08 562.42 318.86 565.93 309.64 566.18 308.98 566.62 325.58 568.29 311.3 567.3 322.65 570.09 314.02"
          />
          <polyline
            class="goose-11"
            points="568.62 308.22 566.94 321 570.45 311.77 570.7 311.11 571.14 327.71 572.81 313.44 571.82 324.78 574.61 316.16"
          />
          <polyline
            class="goose-11"
            points="576.31 312.07 574.63 324.85 578.14 315.63 578.39 314.97 578.83 331.57 580.5 317.29 579.51 328.64 582.3 320.01"
          />
          <polyline
            class="goose-11"
            points="577.48 313.09 575.8 325.87 579.31 316.64 579.56 315.98 580 332.58 581.67 318.3 580.68 329.65 583.47 321.03"
          />
          <polyline
            class="goose-11"
            points="571.35 308.14 569.67 320.92 573.18 311.69 573.43 311.03 573.87 327.63 575.54 313.36 574.55 324.7 577.34 316.08"
          />
          <polyline
            class="goose-11"
            points="576.5 313.74 574.82 326.52 578.33 317.29 578.58 316.63 579.02 333.23 580.69 318.95 579.7 330.3 582.49 321.68"
          />
          <polyline
            class="goose-11"
            points="575.1 311.08 573.42 323.86 576.93 314.64 577.18 313.98 577.62 330.58 579.29 316.3 578.3 327.65 581.09 319.02"
          />
          <polyline
            class="goose-11"
            points="579.62 313.22 577.94 326 581.45 316.77 581.7 316.11 582.14 332.71 583.81 318.44 582.82 329.78 585.61 321.16"
          />
          <polyline
            class="goose-11"
            points="587.31 317.07 585.63 329.85 589.14 320.63 589.39 319.97 589.83 336.57 591.5 322.29 590.51 333.64 593.3 325.01"
          />
          <polyline
            class="goose-11"
            points="588.48 318.09 586.8 330.87 590.31 321.64 590.56 320.98 591 337.58 592.67 323.3 591.68 334.65 594.47 326.03"
          />
          <polyline
            class="goose-11"
            points="582.35 313.14 580.67 325.92 584.18 316.69 584.43 316.03 584.87 332.63 586.54 318.36 585.55 329.7 588.34 321.08"
          />
          <polyline
            class="goose-11"
            points="594.24 318.2 587.76 329.34 594.56 322.19 595.04 321.67 589.04 337.15 596.09 324.63 590.8 334.71 596.7 327.84"
          />
          <polyline
            class="goose-11"
            points="593.97 315.21 587.49 326.35 594.29 319.2 594.78 318.68 588.77 334.17 595.82 321.64 590.53 331.73 596.43 324.85"
          />
          <polyline
            class="goose-11"
            points="597.32 318.93 590.83 330.07 597.63 322.91 598.12 322.4 592.11 337.88 599.17 325.36 593.87 335.44 599.78 328.56"
          />
          <polyline
            class="goose-11"
            points="602.92 325.45 596.44 336.59 603.24 329.44 603.73 328.92 597.72 344.41 604.78 331.88 599.48 341.97 605.39 335.09"
          />
          <polyline
            class="goose-11"
            points="603.61 326.84 597.13 337.98 603.93 330.83 604.42 330.31 598.41 345.79 605.47 333.27 600.17 343.35 606.08 336.48"
          />
          <polyline
            class="goose-11"
            points="599.87 319.91 593.39 331.05 600.18 323.89 600.67 323.38 594.67 338.86 601.72 326.34 596.43 336.42 602.33 329.54"
          />
          <polyline
            class="goose-11"
            points="603.24 326.2 596.76 337.34 603.56 330.19 604.04 329.67 598.04 345.15 605.09 332.63 599.8 342.71 605.7 335.84"
          />
          <polyline
            class="goose-11"
            points="602.97 323.21 596.49 334.35 603.29 327.2 603.78 326.68 597.77 342.17 604.82 329.64 599.53 339.73 605.43 332.85"
          />
          <polyline
            class="goose-11"
            points="606.32 326.93 599.83 338.07 606.63 330.91 607.12 330.4 601.11 345.88 608.17 333.36 602.87 343.44 608.78 336.56"
          />
          <polyline
            class="goose-11"
            points="611.92 333.45 605.44 344.59 612.24 337.44 612.73 336.92 606.72 352.41 613.78 339.88 608.48 349.97 614.39 343.09"
          />
          <polyline
            class="goose-11"
            points="612.61 334.84 606.13 345.98 612.93 338.83 613.42 338.31 607.41 353.79 614.47 341.27 609.17 351.35 615.08 344.48"
          />
          <polyline
            class="goose-11"
            points="608.87 327.91 602.39 339.05 609.18 331.89 609.67 331.38 603.67 346.86 610.72 334.34 605.43 344.42 611.33 337.54"
          />
          <polyline
            class="goose-11"
            points="609.24 337.2 602.76 348.34 609.56 341.19 610.04 340.67 604.04 356.15 611.09 343.63 605.8 353.71 611.7 346.84"
          />
          <polyline
            class="goose-11"
            points="608.97 334.21 602.49 345.35 609.29 338.2 609.78 337.68 603.77 353.17 610.82 340.64 605.53 350.73 611.43 343.85"
          />
          <polyline
            class="goose-11"
            points="612.32 337.93 605.83 349.07 612.63 341.91 613.12 341.4 607.11 356.88 614.17 344.36 608.87 354.44 614.78 347.56"
          />
          <polyline
            class="goose-11"
            points="617.92 344.45 611.44 355.59 618.24 348.44 618.73 347.92 612.72 363.41 619.78 350.88 614.48 360.97 620.39 354.09"
          />
          <polyline
            class="goose-11"
            points="618.61 345.84 612.13 356.98 618.93 349.83 619.42 349.31 613.41 364.79 620.47 352.27 615.17 362.35 621.08 355.48"
          />
          <polyline
            class="goose-11"
            points="614.87 338.91 608.39 350.05 615.18 342.89 615.67 342.38 609.67 357.86 616.72 345.34 611.43 355.42 617.33 348.54"
          />
          <polyline
            class="goose-11"
            points="616.24 343.2 609.76 354.34 616.56 347.19 617.04 346.67 611.04 362.15 618.09 349.63 612.8 359.71 618.7 352.84"
          />
          <polyline
            class="goose-11"
            points="615.97 340.21 609.49 351.35 616.29 344.2 616.78 343.68 610.77 359.17 617.82 346.64 612.53 356.73 618.43 349.85"
          />
          <polyline
            class="goose-11"
            points="619.32 343.93 612.83 355.07 619.63 347.91 620.12 347.4 614.11 362.88 621.17 350.36 615.87 360.44 621.78 353.56"
          />
          <polyline
            class="goose-11"
            points="624.92 350.45 618.44 361.59 625.24 354.44 625.73 353.92 619.72 369.41 626.78 356.88 621.48 366.97 627.39 360.09"
          />
          <polyline
            class="goose-11"
            points="625.61 351.84 619.13 362.98 625.93 355.83 626.42 355.31 620.41 370.79 627.47 358.27 622.17 368.35 628.08 361.48"
          />
          <polyline
            class="goose-11"
            points="621.87 344.91 615.39 356.05 622.18 348.89 622.67 348.38 616.67 363.86 623.72 351.34 618.43 361.42 624.33 354.54"
          />
          <polyline
            class="goose-11"
            points="625.16 354.2 616.3 363.56 624.55 358.15 625.15 357.77 615.75 371.46 625.49 360.89 618.02 369.49 625.35 364.15"
          />
          <polyline
            class="goose-11"
            points="625.58 351.23 616.72 360.59 624.98 355.18 625.57 354.8 616.18 368.49 625.91 357.92 618.45 366.52 625.77 361.18"
          />
          <polyline
            class="goose-11"
            points="627.99 355.62 619.13 364.98 627.38 359.57 627.98 359.18 618.58 372.87 628.32 362.3 620.85 370.9 628.18 365.56"
          />
          <polyline
            class="goose-11"
            points="631.95 363.25 623.09 372.61 631.34 367.2 631.94 366.81 622.54 380.51 632.28 369.94 624.82 378.54 632.14 373.2"
          />
          <polyline
            class="goose-11"
            points="632.3 364.76 623.44 374.12 631.7 368.71 632.29 368.32 622.9 382.02 632.63 371.44 625.17 380.04 632.49 374.7"
          />
          <polyline
            class="goose-11"
            points="630.25 357.15 621.38 366.51 629.64 361.11 630.23 360.72 620.84 374.41 630.58 363.84 623.11 372.44 630.44 367.1"
          />
          <polyline
            class="goose-11"
            points="632.16 371.2 623.3 380.56 631.55 375.15 632.15 374.77 622.75 388.46 632.49 377.89 625.02 386.49 632.35 381.15"
          />
          <polyline
            class="goose-11"
            points="632.58 368.23 623.72 377.59 631.98 372.18 632.57 371.8 623.18 385.49 632.91 374.92 625.45 383.52 632.77 378.18"
          />
          <polyline
            class="goose-11"
            points="634.99 372.62 626.13 381.98 634.38 376.57 634.98 376.18 625.58 389.87 635.32 379.3 627.85 387.9 635.18 382.56"
          />
          <polyline
            class="goose-11"
            points="638.95 380.25 630.09 389.61 638.34 384.2 638.94 383.81 629.54 397.51 639.28 386.94 631.82 395.54 639.14 390.2"
          />
          <polyline
            class="goose-11"
            points="639.3 381.76 630.44 391.12 638.7 385.71 639.29 385.32 629.9 399.02 639.63 388.44 632.17 397.04 639.49 391.7"
          />
          <polyline
            class="goose-11"
            points="637.25 374.15 628.38 383.51 636.64 378.11 637.23 377.72 627.84 391.41 637.58 380.84 630.11 389.44 637.44 384.1"
          />
          <polyline
            class="goose-11"
            points="636.74 388.26 624.58 392.54 634.39 391.5 635.1 391.42 620.49 399.32 633.98 394.36 623.41 398.6 632.37 397.19"
          />
          <polyline
            class="goose-11"
            points="638.47 385.81 626.31 390.09 636.13 389.05 636.83 388.97 622.22 396.87 635.71 391.91 625.14 396.15 634.1 394.74"
          />
          <polyline
            class="goose-11"
            points="638.61 390.81 626.45 395.09 636.27 394.05 636.97 393.97 622.36 401.87 635.85 396.91 625.28 401.15 634.24 399.74"
          />
          <polyline
            class="goose-11"
            points="638.65 399.41 626.49 403.69 636.3 402.65 637.01 402.57 622.4 410.47 635.89 405.51 625.32 409.75 634.27 408.34"
          />
          <polyline
            class="goose-11"
            points="638.27 400.91 626.12 405.19 635.93 404.15 636.64 404.08 622.02 411.97 635.51 407.01 624.95 411.25 633.9 409.85"
          />
          <polyline
            class="goose-11"
            points="639.92 393.21 627.76 397.49 637.57 396.45 638.28 396.37 623.67 404.27 637.16 399.31 626.59 403.55 635.54 402.14"
          />
          <polyline
            class="goose-11"
            points="636.66 404.61 624.99 410.08 634.65 408.07 635.34 407.92 621.59 417.23 634.52 410.95 624.42 416.22 633.19 413.93"
          />
          <polyline
            class="goose-11"
            points="638.14 402 626.47 407.47 636.13 405.46 636.82 405.31 623.07 414.62 636 408.34 625.91 413.62 634.68 411.33"
          />
          <polyline
            class="goose-11"
            points="638.77 406.96 627.1 412.43 636.76 410.42 637.46 410.27 623.7 419.58 636.63 413.3 626.54 418.58 635.31 416.29"
          />
          <polyline
            class="goose-11"
            points="639.67 415.52 627.99 420.98 637.66 418.97 638.35 418.83 624.6 428.13 637.53 421.86 627.43 427.13 636.2 424.84"
          />
          <polyline
            class="goose-11"
            points="639.44 417.05 627.77 422.52 637.43 420.51 638.13 420.36 624.37 429.67 637.3 423.39 627.21 428.66 635.98 426.37"
          />
          <polyline
            class="goose-11"
            points="640.31 409.22 628.64 414.69 638.3 412.68 639 412.53 625.24 421.84 638.17 415.56 628.08 420.83 636.85 418.54"
          />
          <polyline
            class="goose-11"
            points="634.66 418.61 622.99 424.08 632.65 422.07 633.34 421.92 619.59 431.23 632.52 424.95 622.42 430.22 631.19 427.93"
          />
          <polyline
            class="goose-11"
            points="636.14 416 624.47 421.47 634.13 419.46 634.82 419.31 621.07 428.62 634 422.34 623.91 427.62 632.68 425.33"
          />
          <polyline
            class="goose-11"
            points="636.77 420.96 625.1 426.43 634.76 424.42 635.46 424.27 621.7 433.58 634.63 427.3 624.54 432.58 633.31 430.29"
          />
          <polyline
            class="goose-11"
            points="637.67 429.52 625.99 434.98 635.66 432.97 636.35 432.83 622.6 442.13 635.53 435.86 625.43 441.13 634.2 438.84"
          />
          <polyline
            class="goose-11"
            points="637.44 431.05 625.77 436.52 635.43 434.51 636.13 434.36 622.37 443.67 635.3 437.39 625.21 442.66 633.98 440.37"
          />
          <polyline
            class="goose-11"
            points="638.31 423.22 626.64 428.69 636.3 426.68 637 426.53 623.24 435.84 636.17 429.56 626.08 434.83 634.85 432.54"
          />
          <polyline
            class="goose-11"
            points="635.13 444.4 621.76 439.51 631.32 445.41 632.01 445.84 614.53 442.19 629.48 447.45 617.59 443.62 626.55 448.64"
          />
          <polyline
            class="goose-11"
            points="637.98 443.6 624.6 438.71 634.17 444.62 634.85 445.04 617.38 441.4 632.32 446.65 620.43 442.83 629.39 447.84"
          />
          <polyline
            class="goose-11"
            points="635.55 447.75 622.18 442.86 631.74 448.77 632.43 449.19 614.95 445.55 629.9 450.8 618.01 446.98 626.97 452"
          />
          <polyline
            class="goose-11"
            points="631.19 454.76 617.82 449.87 627.38 455.78 628.07 456.2 610.6 452.56 625.54 457.81 613.65 453.99 622.61 459"
          />
          <polyline
            class="goose-11"
            points="630.08 455.72 616.71 450.83 626.27 456.74 626.96 457.17 609.48 453.52 624.43 458.78 612.54 454.95 621.5 459.97"
          />
          <polyline
            class="goose-11"
            points="635.53 450.6 622.16 445.71 631.72 451.62 632.41 452.04 614.93 448.4 629.88 453.65 617.99 449.82 626.94 454.84"
          />
          <polyline
            class="goose-11"
            points="626.13 456.4 612.76 451.51 622.32 457.41 623.01 457.84 605.53 454.19 620.48 459.45 608.59 455.62 617.55 460.64"
          />
          <polyline
            class="goose-11"
            points="628.98 455.6 615.6 450.71 625.17 456.62 625.85 457.04 608.38 453.4 623.32 458.65 611.43 454.83 620.39 459.84"
          />
          <polyline
            class="goose-11"
            points="626.55 459.75 613.18 454.86 622.74 460.77 623.43 461.19 605.95 457.55 620.9 462.8 609.01 458.98 617.97 464"
          />
          <polyline
            class="goose-11"
            points="622.19 466.76 608.82 461.87 618.38 467.78 619.07 468.2 601.6 464.56 616.54 469.81 604.65 465.99 613.61 471"
          />
          <polyline
            class="goose-11"
            points="621.08 467.72 607.71 462.83 617.27 468.74 617.96 469.17 600.48 465.52 615.43 470.78 603.54 466.95 612.5 471.97"
          />
          <polyline
            class="goose-11"
            points="626.53 462.6 613.16 457.71 622.72 463.62 623.41 464.04 605.93 460.4 620.88 465.65 608.99 461.82 617.94 466.84"
          />
          <polyline
            class="goose-11"
            points="620.13 467.4 606.76 462.51 616.32 468.41 617.01 468.84 599.53 465.19 614.48 470.45 602.59 466.62 611.55 471.64"
          />
          <polyline
            class="goose-11"
            points="622.98 466.6 609.6 461.71 619.17 467.62 619.85 468.04 602.38 464.4 617.32 469.65 605.43 465.83 614.39 470.84"
          />
          <polyline
            class="goose-11"
            points="620.55 470.75 607.18 465.86 616.74 471.77 617.43 472.19 599.95 468.55 614.9 473.8 603.01 469.98 611.97 475"
          />
          <polyline
            class="goose-11"
            points="616.19 477.76 602.82 472.87 612.38 478.78 613.07 479.2 595.6 475.56 610.54 480.81 598.65 476.99 607.61 482"
          />
          <polyline
            class="goose-11"
            points="615.08 478.72 601.71 473.83 611.27 479.74 611.96 480.17 594.48 476.52 609.43 481.78 597.54 477.95 606.5 482.97"
          />
          <polyline
            class="goose-11"
            points="620.53 473.6 607.16 468.71 616.72 474.62 617.41 475.04 599.93 471.4 614.88 476.65 602.99 472.82 611.94 477.84"
          />
          <polyline
            class="goose-11"
            points="612.13 479.4 598.76 474.51 608.32 480.41 609.01 480.84 591.53 477.19 606.48 482.45 594.59 478.62 603.55 483.64"
          />
          <polyline
            class="goose-11"
            points="614.98 478.6 601.6 473.71 611.17 479.62 611.85 480.04 594.38 476.4 609.32 481.65 597.43 477.83 606.39 482.84"
          />
          <polyline
            class="goose-11"
            points="612.55 482.75 599.18 477.86 608.74 483.77 609.43 484.19 591.95 480.55 606.9 485.8 595.01 481.98 603.97 487"
          />
          <polyline
            class="goose-11"
            points="608.19 489.76 594.82 484.87 604.38 490.78 605.07 491.2 587.6 487.56 602.54 492.81 590.65 488.99 599.61 494"
          />
          <polyline
            class="goose-11"
            points="607.08 490.72 593.71 485.83 603.27 491.74 603.96 492.17 586.48 488.52 601.43 493.78 589.54 489.95 598.5 494.97"
          />
          <polyline
            class="goose-11"
            points="612.53 485.6 599.16 480.71 608.72 486.62 609.41 487.04 591.93 483.4 606.88 488.65 594.99 484.82 603.94 489.84"
          />
          <polyline
            class="goose-11"
            points="605.13 487.4 591.76 482.51 601.32 488.41 602.01 488.84 584.53 485.19 599.48 490.45 587.59 486.62 596.55 491.64"
          />
          <polyline
            class="goose-11"
            points="607.98 486.6 594.6 481.71 604.17 487.62 604.85 488.04 587.38 484.4 602.32 489.65 590.43 485.83 599.39 490.84"
          />
          <polyline
            class="goose-11"
            points="605.55 490.75 592.18 485.86 601.74 491.77 602.43 492.19 584.95 488.55 599.9 493.8 588.01 489.98 596.97 495"
          />
          <polyline
            class="goose-11"
            points="601.19 497.76 587.82 492.87 597.38 498.78 598.07 499.2 580.6 495.56 595.54 500.81 583.65 496.99 592.61 502"
          />
          <polyline
            class="goose-11"
            points="600.08 498.72 586.71 493.83 596.27 499.74 596.96 500.17 579.48 496.52 594.43 501.78 582.54 497.95 591.5 502.97"
          />
          <polyline
            class="goose-11"
            points="605.53 493.6 592.16 488.71 601.72 494.62 602.41 495.04 584.93 491.4 599.88 496.65 587.99 492.82 596.94 497.84"
          />
          <polyline
            class="goose-11"
            points="594.13 493.4 580.76 488.51 590.32 494.41 591.01 494.84 573.53 491.19 588.48 496.45 576.59 492.62 585.55 497.64"
          />
          <polyline
            class="goose-11"
            points="596.98 492.6 583.6 487.71 593.17 493.62 593.85 494.04 576.38 490.4 591.32 495.65 579.43 491.83 588.39 496.84"
          />
          <polyline
            class="goose-11"
            points="594.55 496.75 581.18 491.86 590.74 497.77 591.43 498.19 573.95 494.55 588.9 499.8 577.01 495.98 585.97 501"
          />
          <polyline
            class="goose-11"
            points="590.19 503.76 576.82 498.87 586.38 504.78 587.07 505.2 569.6 501.56 584.54 506.81 572.65 502.99 581.61 508"
          />
          <polyline
            class="goose-11"
            points="589.08 504.72 575.71 499.83 585.27 505.74 585.96 506.17 568.48 502.52 583.43 507.78 571.54 503.95 580.5 508.97"
          />
          <polyline
            class="goose-11"
            points="594.53 499.6 581.16 494.71 590.72 500.62 591.41 501.04 573.93 497.4 588.88 502.65 576.99 498.82 585.94 503.84"
          />
          <polyline
            class="goose-11"
            points="581.13 507.4 567.76 502.51 577.32 508.41 578.01 508.84 560.53 505.19 575.48 510.45 563.59 506.62 572.55 511.64"
          />
          <polyline
            class="goose-11"
            points="583.98 506.6 570.6 501.71 580.17 507.62 580.85 508.04 563.38 504.4 578.32 509.65 566.43 505.83 575.39 510.84"
          />
          <polyline
            class="goose-11"
            points="581.55 510.75 568.18 505.86 577.74 511.77 578.43 512.19 560.95 508.55 575.9 513.8 564.01 509.98 572.97 515"
          />
          <polyline
            class="goose-11"
            points="577.19 517.76 563.82 512.87 573.38 518.78 574.07 519.2 556.6 515.56 571.54 520.81 559.65 516.99 568.61 522"
          />
          <polyline
            class="goose-11"
            points="576.08 518.72 562.71 513.83 572.27 519.74 572.96 520.17 555.48 516.52 570.43 521.78 558.54 517.95 567.5 522.97"
          />
          <polyline
            class="goose-11"
            points="581.53 513.6 568.16 508.71 577.72 514.62 578.41 515.04 560.93 511.4 575.88 516.65 563.99 512.82 572.94 517.84"
          />
          <polyline
            class="goose-11"
            points="567.13 516.4 553.76 511.51 563.32 517.41 564.01 517.84 546.53 514.19 561.48 519.45 549.59 515.62 558.55 520.64"
          />
          <polyline
            class="goose-11"
            points="569.98 515.6 556.6 510.71 566.17 516.62 566.85 517.04 549.38 513.4 564.32 518.65 552.43 514.83 561.39 519.84"
          />
          <polyline
            class="goose-11"
            points="567.55 519.75 554.18 514.86 563.74 520.77 564.43 521.19 546.95 517.55 561.9 522.8 550.01 518.98 558.97 524"
          />
          <polyline
            class="goose-11"
            points="563.19 526.76 549.82 521.87 559.38 527.78 560.07 528.2 542.6 524.56 557.54 529.81 545.65 525.99 554.61 531"
          />
          <polyline
            class="goose-11"
            points="562.08 527.72 548.71 522.83 558.27 528.74 558.96 529.17 541.48 525.52 556.43 530.78 544.54 526.95 553.5 531.97"
          />
          <polyline
            class="goose-11"
            points="567.53 522.6 554.16 517.71 563.72 523.62 564.41 524.04 546.93 520.4 561.88 525.65 549.99 521.82 558.94 526.84"
          />
          <polyline
            class="goose-11"
            points="559.67 524.8 548.87 515.52 555.75 524.41 556.24 525.05 541.16 515.5 553.31 525.67 543.52 517.91 550.14 525.75"
          />
          <polyline
            class="goose-11"
            points="562.61 525.05 551.81 515.77 558.69 524.66 559.19 525.3 544.11 515.75 556.25 525.92 546.46 518.16 553.09 526.01"
          />
          <polyline
            class="goose-11"
            points="558.89 528.09 548.08 518.81 554.96 527.7 555.46 528.34 540.38 518.79 552.52 528.96 542.73 521.2 549.36 529.04"
          />
          <polyline
            class="goose-11"
            points="552.34 533.12 541.54 523.84 548.42 532.73 548.91 533.37 533.83 523.82 545.98 533.99 536.19 526.23 542.81 534.07"
          />
          <polyline
            class="goose-11"
            points="550.96 533.63 540.16 524.35 547.04 533.24 547.53 533.88 532.45 524.33 544.6 534.5 534.81 526.74 541.43 534.58"
          />
          <polyline
            class="goose-11"
            points="557.86 530.74 547.06 521.47 553.94 530.36 554.43 531 539.35 521.44 551.5 531.61 541.71 523.85 548.33 531.7"
          />
          <polyline
            class="goose-11"
            points="550.67 525.8 539.87 516.52 546.75 525.41 547.24 526.05 532.16 516.5 544.31 526.67 534.52 518.91 541.14 526.75"
          />
          <polyline
            class="goose-11"
            points="553.61 526.05 542.81 516.77 549.69 525.66 550.19 526.3 535.11 516.75 547.25 526.92 537.46 519.16 544.09 527.01"
          />
          <polyline
            class="goose-11"
            points="549.89 529.09 539.08 519.81 545.96 528.7 546.46 529.34 531.38 519.79 543.52 529.96 533.73 522.2 540.36 530.04"
          />
          <polyline
            class="goose-11"
            points="543.34 534.12 532.54 524.84 539.42 533.73 539.91 534.37 524.83 524.82 536.98 534.99 527.19 527.23 533.81 535.07"
          />
          <polyline
            class="goose-11"
            points="541.96 534.63 531.16 525.35 538.04 534.24 538.53 534.88 523.45 525.33 535.6 535.5 525.81 527.74 532.43 535.58"
          />
          <polyline
            class="goose-11"
            points="548.86 531.74 538.06 522.47 544.94 531.36 545.43 532 530.35 522.44 542.5 532.61 532.71 524.85 539.33 532.7"
          />
          <polyline
            class="goose-11"
            points="541.67 527.8 530.87 518.52 537.75 527.41 538.24 528.05 523.16 518.5 535.31 528.67 525.52 520.91 532.14 528.75"
          />
          <polyline
            class="goose-11"
            points="544.61 528.05 533.81 518.77 540.69 527.66 541.19 528.3 526.11 518.75 538.25 528.92 528.46 521.16 535.09 529.01"
          />
          <polyline
            class="goose-11"
            points="540.89 531.09 530.08 521.81 536.96 530.7 537.46 531.34 522.38 521.79 534.52 531.96 524.73 524.2 531.36 532.04"
          />
          <polyline
            class="goose-11"
            points="534.34 536.12 523.54 526.84 530.42 535.73 530.91 536.37 515.83 526.82 527.98 536.99 518.19 529.23 524.81 537.07"
          />
          <polyline
            class="goose-11"
            points="532.96 536.63 522.16 527.35 529.04 536.24 529.53 536.88 514.45 527.33 526.6 537.5 516.81 529.74 523.43 537.58"
          />
          <polyline
            class="goose-11"
            points="539.86 533.74 529.06 524.47 535.94 533.36 536.43 534 521.35 524.44 533.5 534.61 523.71 526.85 530.33 534.7"
          />
          <polyline
            class="goose-11"
            points="538.67 529.8 527.87 520.52 534.75 529.41 535.24 530.05 520.16 520.5 532.31 530.67 522.52 522.91 529.14 530.75"
          />
          <polyline
            class="goose-11"
            points="541.61 530.05 530.81 520.77 537.69 529.66 538.19 530.3 523.11 520.75 535.25 530.92 525.46 523.16 532.09 531.01"
          />
          <polyline
            class="goose-11"
            points="537.89 533.09 527.08 523.81 533.96 532.7 534.46 533.34 519.38 523.79 531.52 533.96 521.73 526.2 528.36 534.04"
          />
          <polyline
            class="goose-11"
            points="531.34 538.12 520.54 528.84 527.42 537.73 527.91 538.37 512.83 528.82 524.98 538.99 515.19 531.23 521.81 539.07"
          />
          <polyline
            class="goose-11"
            points="529.96 538.63 519.16 529.35 526.04 538.24 526.53 538.88 511.45 529.33 523.6 539.5 513.81 531.74 520.43 539.58"
          />
          <polyline
            class="goose-11"
            points="536.86 535.74 526.06 526.47 532.94 535.36 533.43 536 518.35 526.44 530.5 536.61 520.71 528.85 527.33 536.7"
          />
          <polyline
            class="goose-11"
            points="512.67 538.8 501.87 529.52 508.75 538.41 509.24 539.05 494.16 529.5 506.31 539.67 496.52 531.91 503.14 539.75"
          />
          <polyline
            class="goose-11"
            points="515.61 539.05 504.81 529.77 511.69 538.66 512.19 539.3 497.11 529.75 509.25 539.92 499.46 532.16 506.09 540.01"
          />
          <polyline
            class="goose-11"
            points="511.89 542.09 501.08 532.81 507.96 541.7 508.46 542.34 493.38 532.79 505.52 542.96 495.73 535.2 502.36 543.04"
          />
          <polyline
            class="goose-11"
            points="505.34 547.12 494.54 537.84 501.42 546.73 501.91 547.37 486.83 537.82 498.98 547.99 489.19 540.23 495.81 548.07"
          />
          <polyline
            class="goose-11"
            points="503.96 547.63 493.16 538.35 500.04 547.24 500.53 547.88 485.45 538.33 497.6 548.5 487.81 540.74 494.43 548.58"
          />
          <polyline
            class="goose-11"
            points="510.86 544.74 500.06 535.47 506.94 544.36 507.43 545 492.35 535.44 504.5 545.61 494.71 537.85 501.33 545.7"
          />
          <polyline
            class="goose-11"
            points="499.28 544.12 488.05 542.03 496.53 545.36 497.14 545.6 482.97 544.97 495.48 547.14 485.59 545.69 493.44 548.41"
          />
          <polyline
            class="goose-11"
            points="501.33 543.16 490.1 541.08 498.57 544.41 499.18 544.65 485.02 544.02 497.53 546.19 487.64 544.73 495.49 547.46"
          />
          <polyline
            class="goose-11"
            points="500.23 546.63 488.99 544.54 497.47 547.87 498.08 548.11 483.91 547.48 496.43 549.65 486.54 548.2 494.38 550.92"
          />
          <polyline
            class="goose-11"
            points="498.16 552.52 486.93 550.43 495.41 553.76 496.02 554 481.85 553.37 494.36 555.54 484.47 554.08 492.32 556.81"
          />
          <polyline
            class="goose-11"
            points="497.48 553.39 486.25 551.3 494.73 554.63 495.34 554.87 481.17 554.24 493.68 556.41 483.79 554.95 491.64 557.68"
          />
          <polyline
            class="goose-11"
            points="500.74 548.81 489.5 546.72 497.98 550.05 498.59 550.29 484.42 549.66 496.94 551.83 487.05 550.37 494.9 553.1"
          />
          <polyline
            class="goose-11"
            points="490.28 554.12 479.05 552.03 487.53 555.36 488.14 555.6 473.97 554.97 486.48 557.14 476.59 555.69 484.44 558.41"
          />
          <polyline
            class="goose-11"
            points="492.33 553.16 481.1 551.08 489.57 554.41 490.18 554.65 476.02 554.02 488.53 556.19 478.64 554.73 486.49 557.46"
          />
          <polyline
            class="goose-11"
            points="491.23 556.63 479.99 554.54 488.47 557.87 489.08 558.11 474.91 557.48 487.43 559.65 477.54 558.2 485.38 560.92"
          />
          <polyline
            class="goose-11"
            points="489.16 562.52 477.93 560.43 486.41 563.76 487.02 564 472.85 563.37 485.36 565.54 475.47 564.08 483.32 566.81"
          />
          <polyline
            class="goose-11"
            points="488.48 563.39 477.25 561.3 485.73 564.63 486.34 564.87 472.17 564.24 484.68 566.41 474.79 564.95 482.64 567.68"
          />
          <polyline
            class="goose-11"
            points="491.74 558.81 480.5 556.72 488.98 560.05 489.59 560.29 475.42 559.66 487.94 561.83 478.05 560.37 485.9 563.1"
          />
          <polyline
            class="goose-11"
            points="486.28 567.12 475.05 565.03 483.53 568.36 484.14 568.6 469.97 567.97 482.48 570.14 472.59 568.69 480.44 571.41"
          />
          <polyline
            class="goose-11"
            points="488.33 566.16 477.1 564.08 485.57 567.41 486.18 567.65 472.02 567.02 484.53 569.19 474.64 567.73 482.49 570.46"
          />
          <polyline
            class="goose-11"
            points="487.23 569.63 475.99 567.54 484.47 570.87 485.08 571.11 470.91 570.48 483.43 572.65 473.54 571.2 481.38 573.92"
          />
          <polyline
            class="goose-11"
            points="485.16 575.52 473.93 573.43 482.41 576.76 483.02 577 468.85 576.37 481.36 578.54 471.47 577.08 479.32 579.81"
          />
          <polyline
            class="goose-11"
            points="484.48 576.39 473.25 574.3 481.73 577.63 482.34 577.87 468.17 577.24 480.68 579.41 470.79 577.95 478.64 580.68"
          />
          <polyline
            class="goose-11"
            points="487.74 571.81 476.5 569.72 484.98 573.05 485.59 573.29 471.42 572.66 483.94 574.83 474.05 573.37 481.9 576.1"
          />
          <polyline
            class="goose-11"
            points="377.02 438.43 384.47 427.91 377.06 434.43 376.52 434.9 383.89 420.01 375.75 431.86 381.92 422.29 375.43 428.61"
          />
          <polyline
            class="goose-11"
            points="379.45 441.98 386.91 431.47 379.49 437.99 378.96 438.45 386.33 423.57 378.18 435.41 384.36 425.84 377.86 432.17"
          />
          <polyline
            class="goose-11"
            points="382.56 449.22 390.02 438.71 382.6 445.23 382.07 445.69 389.44 430.81 381.29 442.65 387.47 433.08 380.97 439.4"
          />
          <polyline
            class="goose-11"
            points="438 268.33 427.17 263.07 434.59 268.88 435.13 269.3 420.6 264.74 432.73 270.45 423.03 266.21 430.05 271.22"
          />
          <path
            class="goose-11"
            d="m447.76,263.41s-3.96-4.5-5.82-5.01,10.56,4.15,10.56,4.15"
          />
          <path
            class="goose-11"
            d="m452.96,262.47s-5.07-2.77-6.96-2.96,9.8,2.45,9.8,2.45l-2.85.52Z"
          />
          <polygon
            class="goose-11"
            points="457.17 261.7 453.09 259.38 453.64 261.46 457.17 261.7"
          />
          <polygon
            class="goose-11"
            points="459.04 260.35 455.47 257.25 457.17 261.7 461.15 260.98 459.04 260.35"
          />
          <path
            class="goose-11"
            d="m464.97,261.77c-.02.19-1.65-5.94-5.27-5.81l3.37,5.65,1.91.17Z"
          />
          <polygon
            class="goose-11"
            points="462.3 261.4 458.7 257.16 460.03 262.15 462.3 261.4"
          />
          <path
            class="goose-11"
            d="m467.17,259.95s-2.33-5.56-6.11-5.9c0,0,6.36,6.32,3.89,7.6s2.22-1.71,2.22-1.71Z"
          />
          <polygon
            class="goose-11"
            points="459.79 261.23 462.15 261.25 456.41 255.71 459.79 261.23"
          />
          <path
            class="goose-11"
            d="m462.16,261.25l5.35.05s.01,0,.02,0l-6.33-6.74,3.33,6.21.24.45-9.65-7.23,7.04,7.26Z"
          />
          <path
            class="goose-11"
            d="m462.61,259.79s-6.58-7.27-8.91-8.04,13.83,6.52,13.83,6.52"
          />
          <path
            class="goose-11"
            d="m468,258.12s-7.01-4.4-9.22-4.67,12.17,3.76,12.17,3.76l-2.96.91Z"
          />
          <polygon
            class="goose-11"
            points="472.37 256.77 466.69 253.06 468.29 256.45 472.37 256.77"
          />
          <polygon
            class="goose-11"
            points="473.83 254.51 468.36 249.53 472.37 256.77 476.51 255.49 473.83 254.51"
          />
          <path
            class="goose-11"
            d="m481.17,256.68c.07.31-4.65-9.67-8.67-9.38l6.45,9.16,2.22.22Z"
          />
          <polygon
            class="goose-11"
            points="478 256.15 471.95 249.3 475.8 257.43 478 256.15"
          />
          <path
            class="goose-11"
            d="m482.78,253.65s-5.24-9.03-9.65-9.49c0,0,10.13,10.17,7.96,12.34s1.69-2.85,1.69-2.85Z"
          />
          <polyline
            class="goose-11"
            points="483.82 255.86 473.52 244.99 480.2 255.07 480.68 255.79 466.42 244.2 478 256.15 468.69 246.98 475.09 255.93"
          />
          <polyline
            class="goose-11"
            points="463.88 259.2 450.78 251.1 460.23 259.33 460.91 259.92 443.89 252.1 458.52 260.95 446.89 254.27 455.73 261.46"
          />
          <polyline
            class="goose-11"
            points="457.54 261.17 444.43 253.06 453.89 261.29 454.57 261.88 437.54 254.06 452.18 262.91 440.55 256.23 449.38 263.42"
          />
          <polyline
            class="goose-11"
            points="452.49 263.68 439.39 255.57 448.84 263.81 449.52 264.4 432.5 256.58 447.13 265.42 435.5 258.74 444.34 265.93"
          />
          <polyline
            class="goose-11"
            points="457.02 262.28 443.92 254.17 453.37 262.4 454.05 263 437.03 255.18 451.67 264.02 440.03 257.34 448.87 264.53"
          />
          <polyline
            class="goose-11"
            points="472.04 256.38 462.71 248.75 468.59 256.12 469.02 256.65 455.93 248.87 466.42 257.22 457.96 250.86 463.64 257.35"
          />
          <path
            class="goose-11"
            d="m482.67,253.84s-2.82-5.3-4.51-6.21,9.32,6.47,9.32,6.47"
          />
          <path
            class="goose-11"
            d="m487.94,254.12s-4.3-3.86-6.08-4.49,8.97,4.64,8.97,4.64l-2.89-.15Z"
          />
          <polygon
            class="goose-11"
            points="492.22 254.35 488.78 251.14 488.84 253.3 492.22 254.35"
          />
          <polygon
            class="goose-11"
            points="494.35 253.47 491.59 249.63 492.22 254.35 496.26 254.57 494.35 253.47"
          />
          <path
            class="goose-11"
            d="m499.79,256.21c-.06.18-.23-6.16-3.79-6.87l1.97,6.27,1.82.6Z"
          />
          <polygon
            class="goose-11"
            points="497.28 255.24 494.76 250.28 494.9 255.44 497.28 255.24"
          />
          <path
            class="goose-11"
            d="m502.35,254.95s-.98-5.94-4.59-7.15c0,0,4.73,7.61,2.03,8.29s2.56-1.15,2.56-1.15Z"
          />
          <polyline
            class="goose-11"
            points="502.39 256.35 497.78 248.33 499.59 255.14 499.72 255.63 492 246.37 497.28 255.24 492.86 248.34 494.87 254.49"
          />
          <path
            class="goose-11"
            d="m537.28,259.79s6.58-7.27,8.91-8.04-13.83,6.52-13.83,6.52"
          />
          <path
            class="goose-11"
            d="m531.89,258.12s7.01-4.4,9.22-4.67-12.17,3.76-12.17,3.76l2.96.91Z"
          />
          <polygon
            class="goose-11"
            points="527.51 256.77 533.2 253.06 531.59 256.45 527.51 256.77"
          />
          <polygon
            class="goose-11"
            points="526.05 254.51 531.53 249.53 527.51 256.77 523.38 255.49 526.05 254.51"
          />
          <path
            class="goose-11"
            d="m518.72,256.68c-.07.31,4.65-9.67,8.67-9.38l-6.45,9.16-2.22.22Z"
          />
          <polygon
            class="goose-11"
            points="521.88 256.15 527.93 249.3 524.08 257.43 521.88 256.15"
          />
          <path
            class="goose-11"
            d="m517.11,253.65s5.24-9.03,9.65-9.49c0,0-10.13,10.17-7.96,12.34s-1.69-2.85-1.69-2.85Z"
          />
          <polyline
            class="goose-11"
            points="516.06 255.86 526.37 244.99 519.69 255.07 519.21 255.79 533.46 244.2 521.88 256.15 531.2 246.98 524.79 255.93"
          />
          <polyline
            class="goose-11"
            points="536 259.2 549.11 251.1 539.65 259.33 538.97 259.92 556 252.1 541.36 260.95 552.99 254.27 544.16 261.46"
          />
          <polyline
            class="goose-11"
            points="542.35 261.17 555.45 253.06 546 261.29 545.32 261.88 562.34 254.06 547.71 262.91 559.34 256.23 550.5 263.42"
          />
          <polyline
            class="goose-11"
            points="547.39 263.68 560.49 255.57 551.04 263.81 550.36 264.4 567.39 256.58 552.75 265.42 564.38 258.74 555.55 265.93"
          />
          <polyline
            class="goose-11"
            points="542.86 262.28 555.96 254.17 546.51 262.4 545.83 263 562.86 255.18 548.22 264.02 559.85 257.34 551.01 264.53"
          />
          <polyline
            class="goose-11"
            points="527.85 256.38 537.17 248.75 531.29 256.12 530.87 256.65 543.95 248.87 533.46 257.22 541.92 250.86 536.25 257.35"
          />
          <path
            class="goose-11"
            d="m517.21,253.84s2.82-5.3,4.51-6.21-9.32,6.47-9.32,6.47"
          />
          <path
            class="goose-11"
            d="m511.94,254.12s4.3-3.86,6.08-4.49-8.97,4.64-8.97,4.64l2.89-.15Z"
          />
          <polygon
            class="goose-11"
            points="507.67 254.35 511.1 251.14 511.04 253.3 507.67 254.35"
          />
          <polygon
            class="goose-11"
            points="505.54 253.47 508.3 249.63 507.67 254.35 503.62 254.57 505.54 253.47"
          />
          <path
            class="goose-11"
            d="m500.09,256.21c.06.18.23-6.16,3.79-6.87l-1.97,6.27-1.82.6Z"
          />
          <polygon
            class="goose-11"
            points="502.6 255.24 505.13 250.28 504.98 255.44 502.6 255.24"
          />
          <path
            class="goose-11"
            d="m497.54,254.95s.98-5.94,4.59-7.15c0,0-4.73,7.61-2.03,8.29s-2.56-1.15-2.56-1.15Z"
          />
          <polyline
            class="goose-11"
            points="497.5 256.35 502.11 248.33 500.3 255.14 500.17 255.63 507.89 246.37 502.6 255.24 507.02 248.34 505.01 254.49"
          />
          <path
            class="goose-11"
            d="m573.99,270.82s8.15-5.46,10.59-5.65-14.99,2.98-14.99,2.98"
          />
          <path
            class="goose-11"
            d="m569.16,267.9s7.87-2.58,10.07-2.3-12.72.7-12.72.7l2.65,1.6Z"
          />
          <polygon
            class="goose-11"
            points="565.24 265.53 571.66 263.31 569.28 266.21 565.24 265.53"
          />
          <polygon
            class="goose-11"
            points="564.37 262.98 570.89 259.47 565.24 265.53 561.54 263.28 564.37 262.98"
          />
          <path
            class="goose-11"
            d="m556.73,263.31c-.14.28,6.86-8.26,10.69-7l-8.48,7.32-2.21-.32Z"
          />
          <polygon
            class="goose-11"
            points="559.93 263.56 567.46 258.38 561.76 265.34 559.93 263.56"
          />
          <path
            class="goose-11"
            d="m555.9,259.98s7.27-7.5,11.66-6.87c0,0-12.29,7.42-10.71,10.05s-.96-3.17-.96-3.17Z"
          />
          <polyline
            class="goose-11"
            points="554.35 261.88 566.98 253.82 558.06 261.98 557.42 262.57 574.06 254.77 559.93 263.56 571.19 256.92 562.81 264.05"
          />
          <polyline
            class="goose-11"
            points="572.89 269.94 587.57 265.25 576.4 270.95 575.6 271.36 594.01 267.89 577.67 272.93 590.57 269.26 580.26 274.1"
          />
          <polyline
            class="goose-11"
            points="578.57 273.38 593.25 268.68 582.08 274.38 581.28 274.79 599.69 271.33 583.35 276.37 596.25 272.7 585.94 277.54"
          />
          <path
            class="goose-11"
            d="m585.7,278.39l.67-.34,11.16-5.7-14.25,4.56c.82.48,1.62.97,2.42,1.48Z"
          />
          <path
            class="goose-11"
            d="m600.54,276.36l-12.54,3.56c.05.04.11.07.16.11l2.07,1.17,10.31-4.84Z"
          />
          <path
            class="goose-11"
            d="m587.99,279.92l15.99-4.93-18.23,3.43c.76.49,1.5.99,2.24,1.5Z"
          />
          <path
            class="goose-11"
            d="m584.12,277.42c.7.43,1.4.86,2.08,1.31l10.28-4.82-12.36,3.51Z"
          />
          <path
            class="goose-11"
            d="m584.11,277.41l15.82-4.88-18.3,3.45c.84.46,1.66.94,2.48,1.43Z"
          />
          <path
            class="goose-11"
            d="m581.59,275.96l.72-.37,11.16-5.7-14.58,4.67c.91.45,1.81.91,2.7,1.4Z"
          />
          <polyline
            class="goose-11"
            points="565.66 265.23 576.55 260.08 569.07 265.81 568.53 266.22 583.11 261.84 570.91 267.4 580.66 263.28 573.58 268.2"
          />
          <path
            class="goose-11"
            d="m555.96,260.19s4.02-4.46,5.88-4.94-10.61,4.03-10.61,4.03"
          />
          <path
            class="goose-11"
            d="m550.78,259.19s5.11-2.71,6.99-2.88-9.83,2.33-9.83,2.33l2.84.55Z"
          />
          <polygon
            class="goose-11"
            points="546.57 258.37 550.68 256.1 550.1 258.17 546.57 258.37"
          />
          <polygon
            class="goose-11"
            points="544.72 257 548.33 253.95 546.57 258.37 542.6 257.61 544.72 257"
          />
          <path
            class="goose-11"
            d="m538.77,258.35c.01.19,1.72-5.92,5.34-5.75l-3.43,5.61-1.91.14Z"
          />
          <polygon
            class="goose-11"
            points="541.44 258.02 545.09 253.81 543.7 258.79 541.44 258.02"
          />
          <path
            class="goose-11"
            d="m536.6,256.51s2.39-5.53,6.18-5.82c0,0-6.43,6.24-3.97,7.56s-2.2-1.73-2.2-1.73Z"
          />
          <polyline
            class="goose-11"
            points="536.22 257.86 542.63 251.19 539.23 257.36 538.98 257.8 548.72 250.69 541.44 258.02 547.4 252.39 543.96 257.87"
          />
          <path
            class="goose-11"
            d="m608.99,288.82s8.15-5.46,10.59-5.65-14.99,2.98-14.99,2.98"
          />
          <path
            class="goose-11"
            d="m604.16,285.9s7.87-2.58,10.07-2.3-12.72.7-12.72.7l2.65,1.6Z"
          />
          <polygon
            class="goose-11"
            points="600.24 283.53 606.66 281.31 604.28 284.21 600.24 283.53"
          />
          <polygon
            class="goose-11"
            points="599.37 280.98 605.89 277.47 600.24 283.53 596.54 281.28 599.37 280.98"
          />
          <path
            class="goose-11"
            d="m591.73,281.31c-.14.28,6.86-8.26,10.69-7l-8.48,7.32-2.21-.32Z"
          />
          <polygon
            class="goose-11"
            points="594.93 281.56 602.46 276.38 596.76 283.34 594.93 281.56"
          />
          <path
            class="goose-11"
            d="m590.9,277.98s7.27-7.5,11.66-6.87c0,0-12.29,7.42-10.71,10.05s-.96-3.17-.96-3.17Z"
          />
          <polyline
            class="goose-11"
            points="589.35 279.88 601.98 271.82 593.06 279.98 592.42 280.57 609.06 272.77 594.93 281.56 606.19 274.92 597.81 282.05"
          />
          <polyline
            class="goose-11"
            points="607.89 287.94 622.57 283.25 611.4 288.95 610.6 289.36 629.01 285.89 612.67 290.93 625.57 287.26 615.26 292.1"
          />
          <polyline
            class="goose-11"
            points="613.57 291.38 628.25 286.68 617.08 292.38 616.28 292.79 634.69 289.33 618.35 294.37 631.25 290.7 620.94 295.54"
          />
          <polyline
            class="goose-11"
            points="617.86 295.04 632.53 290.35 621.37 296.05 620.57 296.46 638.98 292.99 622.64 298.03 635.54 294.36 625.23 299.2"
          />
          <polyline
            class="goose-11"
            points="613.8 292.58 628.48 287.89 617.31 293.59 616.51 294 634.92 290.53 618.58 295.57 631.48 291.91 621.17 296.74"
          />
          <polyline
            class="goose-11"
            points="600.66 283.23 611.55 278.08 604.07 283.81 603.53 284.22 618.11 279.84 605.91 285.4 615.66 281.28 608.58 286.2"
          />
          <path
            class="goose-11"
            d="m590.96,278.19s4.02-4.46,5.88-4.94-10.61,4.03-10.61,4.03"
          />
          <path
            class="goose-11"
            d="m585.78,277.19s5.11-2.71,6.99-2.88-9.83,2.33-9.83,2.33l2.84.55Z"
          />
          <path
            class="goose-11"
            d="m582.28,276.33l2.83-.16.58-2.07-3.72,2.06c.1.06.21.11.32.17Z"
          />
          <path
            class="goose-11"
            d="m581.71,276.02l1.61-4.08-3.57,3.03c.66.34,1.31.69,1.96,1.05Z"
          />
          <path
            class="goose-11"
            d="m577.19,273.73l1.92-3.14c-1.61-.07-2.83,1.08-3.7,2.38.6.25,1.2.49,1.78.76Z"
          />
          <path
            class="goose-11"
            d="m574.44,272.56c1.34-1.94,3.34-3.88,3.34-3.88-2.05.16-3.68,1.85-4.75,3.36.47.17.95.33,1.42.52Z"
          />
          <path
            class="goose-11"
            d="m578.22,274.22l5.5-5.54-6.76,4.95c.43.19.85.39,1.27.59Z"
          />
          <path
            class="goose-11"
            d="m578.3,274.26c.43.2.86.41,1.28.62l2.83-4.5-4.1,3.87Z"
          />
          <path
            class="goose-11"
            d="m575.52,273.02l2.11-3.83-3.23,3.36c.38.15.74.32,1.12.47Z"
          />
          <path
            class="goose-11"
            d="m638.67,310.62s9.24-3.3,11.65-2.89-15.27-.78-15.27-.78"
          />
          <path
            class="goose-11"
            d="m634.71,306.61s8.26-.57,10.33.23-12.5-2.43-12.5-2.43l2.17,2.2Z"
          />
          <polygon
            class="goose-11"
            points="631.49 303.35 638.25 302.77 635.24 305 631.49 303.35"
          />
          <polygon
            class="goose-11"
            points="631.27 300.67 638.45 298.86 631.49 303.35 628.45 300.27 631.27 300.67"
          />
          <path
            class="goose-11"
            d="m623.78,299.12c-.21.24,8.67-6.33,12.08-4.17l-10.01,5.03-2.06-.85Z"
          />
          <polygon
            class="goose-11"
            points="626.82 300.15 635.39 296.96 628.16 302.31 626.82 300.15"
          />
          <path
            class="goose-11"
            d="m623.79,295.69s8.88-5.49,12.99-3.81c0,0-13.73,4.19-12.84,7.12s-.15-3.31-.15-3.31Z"
          />
          <polyline
            class="goose-11"
            points="621.82 297.14 636.04 292.43 625.4 298.15 624.63 298.56 642.67 295.08 626.82 300.15 639.36 296.46 629.49 301.32"
          />
          <polyline
            class="goose-11"
            points="637.83 309.5 653.21 308.54 640.99 311.34 640.11 311.54 658.81 312.68 641.73 313.57 655.14 313.17 643.95 315.34"
          />
          <polyline
            class="goose-11"
            points="642.5 314.23 657.87 313.27 645.65 316.06 644.77 316.26 663.47 317.41 646.4 318.3 659.8 317.9 648.62 320.07"
          />
          <polyline
            class="goose-11"
            points="645.75 318.83 661.13 317.87 648.91 320.66 648.03 320.86 666.73 322.01 649.65 322.89 663.06 322.5 651.88 324.66"
          />
          <polyline
            class="goose-11"
            points="642.42 315.45 657.8 314.49 645.58 317.28 644.7 317.49 663.4 318.63 646.32 319.52 659.73 319.12 648.54 321.29"
          />
          <polyline
            class="goose-11"
            points="631.97 303.16 643.79 300.84 635.13 304.56 634.51 304.83 649.72 304.15 636.52 306.56 646.99 304.94 638.92 307.98"
          />
          <path
            class="goose-11"
            d="m623.8,295.9s4.99-3.34,6.91-3.35-11.27,1.31-11.27,1.31"
          />
          <path
            class="goose-11"
            d="m619.02,293.66s5.61-1.38,7.48-1.08-10.1-.14-10.1-.14l2.62,1.23Z"
          />
          <polygon
            class="goose-11"
            points="615.14 291.85 619.68 290.64 618.61 292.51 615.14 291.85"
          />
          <polygon
            class="goose-11"
            points="613.68 290.06 617.92 287.98 615.14 291.85 611.47 290.13 613.68 290.06"
          />
          <path
            class="goose-11"
            d="m607.58,289.91c-.03.19,3.11-5.32,6.59-4.27l-4.7,4.6-1.89-.33Z"
          />
          <polygon
            class="goose-11"
            points="610.25 290.24 614.82 287.06 612.26 291.55 610.25 290.24"
          />
          <path
            class="goose-11"
            d="m605.93,287.59s3.67-4.78,7.42-4.13c0,0-7.76,4.48-5.7,6.35s-1.71-2.22-1.71-2.22Z"
          />
          <polyline
            class="goose-11"
            points="605.23 288.81 613.08 283.92 608.27 289.07 607.92 289.44 619.1 284.92 610.25 290.24 617.41 286.25 612.73 290.72"
          />
          <path
            class="goose-11"
            d="m663,345.2s9.8.42,11.88,1.71-13.85-6.46-13.85-6.46"
          />
          <path
            class="goose-11"
            d="m660.83,339.99s7.87,2.58,9.49,4.1-10.67-6.96-10.67-6.96l1.19,2.86Z"
          />
          <polygon
            class="goose-11"
            points="659.08 335.76 665.56 337.76 661.93 338.69 659.08 335.76"
          />
          <polygon
            class="goose-11"
            points="659.88 333.19 667.21 334.22 659.08 335.76 657.42 331.76 659.88 333.19"
          />
          <path
            class="goose-11"
            d="m653.52,328.93c-.28.14,10.41-2.6,12.76.67l-11.17.89-1.59-1.57Z"
          />
          <polygon
            class="goose-11"
            points="655.96 331.03 665.09 331.31 656.38 333.54 655.96 331.03"
          />
          <path
            class="goose-11"
            d="m654.82,325.76s10.3-1.74,13.47,1.36c0,0-14.3-1.28-14.58,1.77s1.11-3.12,1.11-3.12Z"
          />
          <polyline
            class="goose-11"
            points="652.45 326.37 667.4 327.35 655.39 328.65 654.52 328.74 672.55 332.3 655.96 331.03 668.96 332.34 657.99 333.13"
          />
          <polyline
            class="goose-11"
            points="662.64 343.84 677.24 348.74 664.87 346.73 663.98 346.58 680.88 354.68 664.72 349.08 677.29 353.75 666.11 351.55"
          />
          <polyline
            class="goose-11"
            points="665.18 349.98 679.79 354.87 667.42 352.86 666.53 352.72 683.42 360.81 667.26 355.21 679.84 359.89 668.66 357.69"
          />
          <polyline
            class="goose-11"
            points="666.47 355.46 681.08 360.36 668.71 358.35 667.82 358.21 684.71 366.3 668.55 360.7 681.13 365.37 669.95 363.17"
          />
          <polyline
            class="goose-11"
            points="664.65 351.08 679.26 355.98 666.89 353.97 666 353.82 682.89 361.92 666.74 356.32 679.31 360.99 668.13 358.79"
          />
          <polyline
            class="goose-11"
            points="659.59 335.77 671.42 338.06 661.99 338.25 661.31 338.26 675.66 343.35 662.53 340.62 672.84 343.06 664.22 342.85"
          />
          <path
            class="goose-11"
            d="m654.75,325.96s5.88-1.22,7.66-.5-10.93-3.03-10.93-3.03"
          />
          <path
            class="goose-11"
            d="m651.16,322.09s5.72.84,7.34,1.81-9.31-3.93-9.31-3.93l1.97,2.12Z"
          />
          <polygon
            class="goose-11"
            points="648.25 318.95 652.91 319.54 651.22 320.87 648.25 318.95"
          />
          <polygon
            class="goose-11"
            points="647.57 316.75 652.29 316.41 648.25 318.95 645.5 315.98 647.57 316.75"
          />
          <path
            class="goose-11"
            d="m641.98,314.31c-.1.16,4.88-3.76,7.71-1.48l-6.09,2.49-1.62-1.01Z"
          />
          <polygon
            class="goose-11"
            points="644.33 315.62 649.76 314.39 645.7 317.59 644.33 315.62"
          />
          <path
            class="goose-11"
            d="m641.32,311.54s5.2-3.04,8.43-1.04c0,0-8.88,1.23-7.67,3.74s-.75-2.7-.75-2.7Z"
          />
          <polyline
            class="goose-11"
            points="640.21 312.41 649.33 310.83 642.93 313.79 642.47 314 654.53 314.02 644.33 315.62 652.46 314.61 646.45 317"
          />
          <path
            class="goose-11"
            d="m672.47,401.56s8.37,5.11,9.57,7.25-8.99-12.36-8.99-12.36"
          />
          <path
            class="goose-11"
            d="m673.1,395.96s5.64,6.06,6.31,8.18-5.97-11.25-5.97-11.25l-.35,3.07Z"
          />
          <polygon
            class="goose-11"
            points="673.62 391.41 678.32 396.3 674.69 395.36 673.62 391.41"
          />
          <polygon
            class="goose-11"
            points="675.56 389.55 681.48 394 673.62 391.41 674.1 387.1 675.56 389.55"
          />
          <path
            class="goose-11"
            d="m672.06,382.75c-.32-.01,10.37,2.77,10.84,6.77l-10.2-4.63-.63-2.14Z"
          />
          <polygon
            class="goose-11"
            points="673.17 385.76 681.03 390.42 672.33 388.16 673.17 385.76"
          />
          <path
            class="goose-11"
            d="m674.73,380.6s9.85,3.46,11.13,7.71c0,0-11.89-8.05-13.61-5.51s2.48-2.2,2.48-2.2Z"
          />
          <polyline
            class="goose-11"
            points="672.36 379.99 684.97 388.08 673.83 383.4 673.03 383.06 687.07 394.9 673.17 385.76 683.92 393.2 673.93 388.58"
          />
          <polyline
            class="goose-11"
            points="672.81 400.2 683.23 411.56 673.37 403.81 672.66 403.25 683.53 418.52 672.1 405.79 680.84 415.97 672.12 408.63"
          />
          <path
            class="goose-11"
            d="m671.43,414.57c0,.23,0,.47,0,.7l8.67,7.3-8.5-9.91-.16,1.91Z"
          />
          <polygon
            class="goose-11"
            points="671.92 409.85 672.63 410.41 682.48 418.16 672.07 406.8 671.59 412.65 682.78 425.12 671.92 409.85"
          />
          <path
            class="goose-11"
            d="m671.4,416.7c-.02.93-.05,1.86-.08,2.79l9.93,11.06-9.85-13.84Z"
          />
          <path
            class="goose-11"
            d="m671.42,416.08l9.54,7.5-9.51-10.37c0,.96-.01,1.91-.03,2.87Z"
          />
          <path
            class="goose-11"
            d="m671.24,421.82l7.33,6.17-7.24-8.44c-.03.76-.05,1.51-.09,2.27Z"
          />
          <path
            class="goose-11"
            d="m671.4,416.8l7.7,6.48-7.66-8.93c0,.82-.02,1.63-.03,2.45Z"
          />
          <path
            class="goose-11"
            d="m681.79,425.83l-10.36-14.55c.01,1.01.01,2.02,0,3.02l10.35,11.53Z"
          />
          <path
            class="goose-11"
            d="m671.43,410.96l.2.16,9.85,7.75-10.14-11.06c.04,1.05.07,2.1.08,3.15Z"
          />
          <polyline
            class="goose-11"
            points="674.06 391.66 683.3 399.4 674.96 395 674.36 394.68 684.45 406.08 674.28 397.34 682.12 404.46 674.68 400.1"
          />
          <path
            class="goose-11"
            d="m674.57,380.74s5.73,1.78,6.95,3.27-8.1-7.94-8.1-7.94"
          />
          <path
            class="goose-11"
            d="m673.31,375.62s4.6,3.5,5.55,5.14-6.24-7.95-6.24-7.95l.69,2.81Z"
          />
          <polygon
            class="goose-11"
            points="672.28 371.46 676.08 374.23 673.95 374.58 672.28 371.46"
          />
          <polygon
            class="goose-11"
            points="672.75 369.2 677.04 371.19 672.28 371.46 671.32 367.53 672.75 369.2"
          />
          <path
            class="goose-11"
            d="m669.04,364.36c-.17.09,6.09-.92,7.46,2.44l-6.53-.77-.93-1.67Z"
          />
          <polygon
            class="goose-11"
            points="670.46 366.65 675.8 368.2 670.71 369.03 670.46 366.65"
          />
          <path
            class="goose-11"
            d="m669.8,361.62s6.02-.15,7.88,3.17c0,0-8.36-3.22-8.53-.44-.16,2.78.65-2.73.65-2.73Z"
          />
          <polyline
            class="goose-11"
            points="668.41 361.84 677.16 364.87 670.13 364.37 669.62 364.33 680.16 370.18 670.46 366.65 678.07 369.7 671.65 368.87"
          />
          <path
            class="goose-11"
            d="m673.47,441.56s8.37,5.11,9.57,7.25-8.99-12.36-8.99-12.36"
          />
          <path
            class="goose-11"
            d="m674.1,435.96s5.64,6.06,6.31,8.18-5.97-11.25-5.97-11.25l-.35,3.07Z"
          />
          <polygon
            class="goose-11"
            points="674.62 431.41 679.32 436.3 675.69 435.36 674.62 431.41"
          />
          <polygon
            class="goose-11"
            points="676.56 429.55 682.48 434 674.62 431.41 675.1 427.1 676.56 429.55"
          />
          <path
            class="goose-11"
            d="m673.06,422.75c-.32-.01,10.37,2.77,10.84,6.77l-10.2-4.63-.63-2.14Z"
          />
          <polygon
            class="goose-11"
            points="674.17 425.76 682.03 430.42 673.33 428.16 674.17 425.76"
          />
          <path
            class="goose-11"
            d="m675.73,420.6s9.85,3.46,11.13,7.71c0,0-11.89-8.05-13.61-5.51s2.48-2.2,2.48-2.2Z"
          />
          <polyline
            class="goose-11"
            points="673.36 419.99 685.97 428.08 674.83 423.4 674.03 423.06 688.07 434.9 674.17 425.76 684.92 433.2 674.93 428.58"
          />
          <polyline
            class="goose-11"
            points="673.81 440.2 684.23 451.56 674.37 443.81 673.66 443.25 684.53 458.52 673.1 445.79 681.84 455.97 673.12 448.63"
          />
          <polyline
            class="goose-11"
            points="673.07 446.8 683.48 458.16 673.63 450.41 672.92 449.85 683.78 465.12 672.36 452.39 681.09 462.57 672.38 455.23"
          />
          <polyline
            class="goose-11"
            points="671.54 452.23 681.96 463.58 672.1 455.84 671.39 455.28 682.26 470.54 670.83 457.82 679.57 468 670.85 460.66"
          />
          <polyline
            class="goose-11"
            points="672.07 447.51 682.49 458.87 672.63 451.12 671.92 450.56 682.79 465.83 671.36 453.1 680.1 463.28 671.38 455.94"
          />
          <polyline
            class="goose-11"
            points="675.06 431.66 684.3 439.4 675.96 435 675.36 434.68 685.45 446.08 675.28 437.34 683.12 444.46 675.68 440.1"
          />
          <path
            class="goose-11"
            d="m675.57,420.74s5.73,1.78,6.95,3.27-8.1-7.94-8.1-7.94"
          />
          <path
            class="goose-11"
            d="m674.31,415.62s4.6,3.5,5.55,5.14-6.24-7.95-6.24-7.95l.69,2.81Z"
          />
          <polygon
            class="goose-11"
            points="673.28 411.46 677.08 414.23 674.95 414.58 673.28 411.46"
          />
          <polygon
            class="goose-11"
            points="673.75 409.2 678.04 411.19 673.28 411.46 672.32 407.53 673.75 409.2"
          />
          <path
            class="goose-11"
            d="m671.26,406.07l6.24.73c-.99-2.42-4.51-2.57-6.34-2.5.04.59.07,1.18.1,1.77Z"
          />
          <polygon
            class="goose-11"
            points="671.46 406.65 676.8 408.2 671.71 409.03 671.46 406.65"
          />
          <path
            class="goose-11"
            d="m670.94,401.62c.05.57.1,1.15.14,1.72,2.21-.62,7.59,1.45,7.59,1.45-1.69-3.03-6.85-3.17-7.73-3.17Z"
          />
          <path
            class="goose-11"
            d="m671.01,402.4c.06.66.11,1.31.16,1.97l6.99.5-7.15-2.48Z"
          />
          <polygon
            class="goose-11"
            points="672.65 408.87 679.07 409.7 671.66 406.73 672.65 408.87"
          />
          <path
            class="goose-11"
            d="m671.19,404.64c.03.4.04.8.06,1.19l.41.88,9.51,3.46-9.98-5.54Z"
          />
          <path
            class="goose-11"
            d="m656.8,490.18s5.84,7.88,6.14,10.31-3.68-14.84-3.68-14.84"
          />
          <path
            class="goose-11"
            d="m659.49,485.22s2.94,7.74,2.77,9.95-1.3-12.67-1.3-12.67l-1.48,2.72Z"
          />
          <polygon
            class="goose-11"
            points="661.68 481.2 664.19 487.5 661.18 485.26 661.68 481.2"
          />
          <polygon
            class="goose-11"
            points="664.18 480.21 667.98 486.56 661.68 481.2 663.74 477.39 664.18 480.21"
          />
          <path
            class="goose-11"
            d="m664.16,473.16c-.26.58-.53,1.16-.81,1.73l7.64,8.05c.87-3.16-4.83-8.12-6.83-9.78Z"
          />
          <path
            class="goose-11"
            d="m662.06,477.3c-.09.16-.18.32-.27.47l7.13,5.31-5.53-7.28-1.32,1.5Z"
          />
          <path
            class="goose-11"
            d="m674.19,482.94c.42-4.42-7.41-11.33-7.41-11.33,0,0-1.1.54-2.11,1.01,3.18.86,9.52,10.32,9.52,10.32Z"
          />
          <path
            class="goose-11"
            d="m664.27,473.25l.62.61,8.57,8.53-8.27-11.73c-.3.8-.62,1.59-.96,2.37l-.63,3.05,9.25,13.42-8.56-16.25Z"
          />
          <polygon
            class="goose-11"
            points="670.55 486.73 663.58 476.09 663.04 478.7 670.55 486.73"
          />
          <polyline
            class="goose-11"
            points="657.62 489.05 663 503.48 656.78 492.6 656.34 491.82 660.66 510.05 654.86 493.96 659.13 506.67 653.81 496.6"
          />
          <polyline
            class="goose-11"
            points="654.45 494.88 659.83 509.32 653.61 498.43 653.17 497.65 657.49 515.88 651.69 499.79 655.96 512.51 650.64 502.43"
          />
          <polyline
            class="goose-11"
            points="651 499.33 656.37 513.77 650.16 502.89 649.71 502.1 654.03 520.33 648.23 504.25 652.5 516.96 647.19 506.88"
          />
          <polyline
            class="goose-11"
            points="653.26 495.17 658.64 509.6 652.42 498.72 651.98 497.94 656.3 516.16 650.5 500.08 654.77 512.79 649.45 502.72"
          />
          <polyline
            class="goose-11"
            points="661.99 481.6 667.64 492.24 661.57 485.03 661.13 484.51 666.2 498.87 660.06 486.94 664.64 496.49 659.39 489.65"
          />
          <path
            class="goose-11"
            d="m666.58,471.67s4.64,3.8,5.21,5.64c.57,1.84-4.52-10.41-4.52-10.41"
          />
          <path
            class="goose-11"
            d="m667.33,466.45s2.95,4.97,3.21,6.85-2.79-9.71-2.79-9.71l-.42,2.86Z"
          />
          <polygon
            class="goose-11"
            points="667.95 462.21 670.42 466.21 668.32 465.73 667.95 462.21"
          />
          <polygon
            class="goose-11"
            points="669.23 460.3 672.45 463.76 667.95 462.21 668.53 458.21 669.23 460.3"
          />
          <path
            class="goose-11"
            d="m668.62,456.75l4.99,2.74c0-2.51-2.97-3.96-4.73-4.63-.08.63-.17,1.26-.26,1.89Z"
          />
          <path
            class="goose-11"
            d="m668.51,457.42c-.11.71-.22,1.41-.35,2.12l4.27.98-3.92-3.1Z"
          />
          <path
            class="goose-11"
            d="m669.17,452.46c-.05.47-.11.94-.16,1.41,2.29.3,6.45,4.19,6.45,4.19-.47-3.77-6.11-5.9-6.11-5.9,0,0-.07.12-.18.3Z"
          />
          <path
            class="goose-11"
            d="m668.8,455.4c-.09.69-.19,1.38-.3,2.06l7.22,6.53-6.92-8.59Z"
          />
          <path
            class="goose-11"
            d="m668.87,454.95l6.07,2.99-5.82-5.09c-.08.7-.16,1.4-.26,2.1Z"
          />
          <path
            class="goose-11"
            d="m668.29,458.76l.04.82,5.63,3.18-5.46-5.27c-.07.42-.14.85-.21,1.27Z"
          />
          <path
            class="goose-11"
            d="m618.38,535.3s1.11,9.75.15,12,4.24-14.69,4.24-14.69"
          />
          <path
            class="goose-11"
            d="m623.19,532.35s-1.33,8.17-2.58,10.01,5.22-11.62,5.22-11.62l-2.64,1.61Z"
          />
          <polygon
            class="goose-11"
            points="627.1 529.97 626.12 536.68 624.64 533.24 627.1 529.97"
          />
          <polygon
            class="goose-11"
            points="629.76 530.36 629.88 537.76 627.1 529.97 630.79 527.71 629.76 530.36"
          />
          <path
            class="goose-11"
            d="m632.98,523.42c-.19-.26,4.18,9.88,1.31,12.71l-2.61-10.89,1.3-1.82Z"
          />
          <polygon
            class="goose-11"
            points="631.28 526.15 632.43 535.22 628.87 526.95 631.28 526.15"
          />
          <path
            class="goose-11"
            d="m636.31,524.21s3.32,9.9.75,13.52c0,0-.95-14.32-4-14.13s3.26.61,3.26.61Z"
          />
          <polyline
            class="goose-11"
            points="635.34 521.97 636.69 536.89 633.55 525.22 633.32 524.38 632.6 542.74 631.28 526.15 632.01 539.2 629.52 528.48"
          />
          <polyline
            class="goose-11"
            points="619.66 534.73 617.09 549.92 617.15 537.39 617.16 536.48 611.78 554.43 614.81 537.6 612.14 550.74 612.58 539.36"
          />
          <polyline
            class="goose-11"
            points="613.99 538.2 611.42 553.39 611.49 540.85 611.49 539.95 606.11 557.9 609.14 541.06 606.47 554.21 606.91 542.83"
          />
          <polyline
            class="goose-11"
            points="608.77 540.32 606.2 555.51 606.27 542.98 606.27 542.07 600.89 560.02 603.92 543.19 601.25 556.33 601.69 544.95"
          />
          <polyline
            class="goose-11"
            points="612.82 537.85 610.25 553.04 610.32 540.5 610.32 539.6 604.94 557.55 607.97 540.71 605.3 553.86 605.74 542.48"
          />
          <polyline
            class="goose-11"
            points="627.17 530.47 626.73 542.51 625.09 533.23 624.97 532.56 622.16 547.53 622.83 534.13 622.01 544.69 620.89 536.14"
          />
          <path
            class="goose-11"
            d="m636.1,524.17s2.11,5.62,1.68,7.49c-.43,1.88,1.3-11.27,1.3-11.27"
          />
          <path
            class="goose-11"
            d="m639.37,520.03s.06,5.78-.65,7.53,2.44-9.8,2.44-9.8l-1.79,2.27Z"
          />
          <polygon
            class="goose-11"
            points="642.03 516.67 642.16 521.36 640.59 519.9 642.03 516.67"
          />
          <polygon
            class="goose-11"
            points="644.1 515.65 645.15 520.26 642.03 516.67 644.54 513.49 644.1 515.65"
          />
          <path
            class="goose-11"
            d="m645.63,509.75c-.17-.07,4.47,4.24,2.66,7.39l-3.41-5.63.75-1.76Z"
          />
          <polygon
            class="goose-11"
            points="644.7 512.28 646.76 517.45 642.98 513.93 644.7 512.28"
          />
          <path
            class="goose-11"
            d="m648.27,508.67s3.81,4.67,2.33,8.16c0,0-2.59-8.58-4.88-7s2.55-1.16,2.55-1.16Z"
          />
          <polyline
            class="goose-11"
            points="647.24 507.71 650.22 516.47 646.3 510.61 646.02 510.19 647.87 522.1 644.7 512.28 646.96 520.15 643.68 514.58"
          />
          <path
            class="goose-11"
            d="m578.27,555.24s-1.52,9.69-3.04,11.61,7.99-13.03,7.99-13.03"
          />
          <path
            class="goose-11"
            d="m583.69,553.68s-3.45,7.52-5.15,8.96,8.12-9.81,8.12-9.81l-2.97.85Z"
          />
          <polygon
            class="goose-11"
            points="588.09 552.42 585.36 558.63 584.85 554.92 588.09 552.42"
          />
          <polygon
            class="goose-11"
            points="590.55 553.51 588.7 560.67 588.09 552.42 592.25 551.22 590.55 553.51"
          />
          <path
            class="goose-11"
            d="m595.5,547.67c-.11-.3,1.4,10.64-2.12,12.6l.38-11.2,1.74-1.4Z"
          />
          <polygon
            class="goose-11"
            points="593.14 549.85 591.83 558.9 590.6 549.99 593.14 549.85"
          />
          <path
            class="goose-11"
            d="m598.5,549.33s.56,10.43-2.87,13.23c0,0,2.9-14.06-.1-14.68s2.98,1.45,2.98,1.45Z"
          />
          <polyline
            class="goose-11"
            points="598.17 546.9 595.5 561.64 595.57 549.56 595.58 548.69 590 566.19 593.14 549.85 590.37 562.62 590.83 551.63"
          />
          <polygon
            class="goose-11"
            points="566.82 571.93 576.78 556.06 576.53 556.93 573.14 568.99 579.65 555.03 574.07 556.83 566.82 571.93"
          />
          <polygon
            class="goose-11"
            points="568.15 568.47 574.05 556.83 571.6 557.62 568.15 568.47"
          />
          <path
            class="goose-11"
            d="m565.14,559.68l-3.38,10.62,5.79-11.4c-.8.26-1.6.53-2.41.78Z"
          />
          <path
            class="goose-11"
            d="m570.38,557.95l-.23.82-3.4,12.07,6.49-13.89c-.95.35-1.9.68-2.86,1.01Z"
          />
          <path
            class="goose-11"
            d="m567.57,558.9l-7.13,14.87,9.92-15.81c-.93.32-1.86.63-2.79.94Z"
          />
          <path
            class="goose-11"
            d="m559.18,561.49l-3.01,9.47,5.13-10.1c-.7.21-1.41.42-2.11.63Z"
          />
          <path
            class="goose-11"
            d="m561.35,560.85l-6.51,13.57,9-14.33c-.83.26-1.65.51-2.48.76Z"
          />
          <path
            class="goose-11"
            d="m564.42,559.91l-3.26,11.58,5.78-12.39c-.84.27-1.68.54-2.52.81Z"
          />
          <path
            class="goose-11"
            d="m565.97,559.42l-6.57,13.7,9.12-14.53c-.85.28-1.7.56-2.55.83Z"
          />
          <path
            class="goose-11"
            d="m563.76,560.11l-3.04,9.54,5.19-10.22c-.72.23-1.43.45-2.15.68Z"
          />
          <path
            class="goose-11"
            d="m569.03,558.41l-3.31,11.77,5.91-12.66c-.86.3-1.73.6-2.6.9Z"
          />
          <polyline
            class="goose-11"
            points="588.03 552.93 584.4 564.42 585.29 555.03 585.35 554.36 578.67 568.04 582.87 555.3 579.27 565.26 580.47 556.72"
          />
          <path
            class="goose-11"
            d="m598.32,549.23s.55,5.98-.37,7.67,4.25-10.52,4.25-10.52"
          />
          <path
            class="goose-11"
            d="m602.57,546.1s-1.48,5.59-2.63,7.09,4.96-8.8,4.96-8.8l-2.33,1.71Z"
          />
          <polygon
            class="goose-11"
            points="606.02 543.57 604.9 548.13 603.77 546.3 606.02 543.57"
          />
          <polygon
            class="goose-11"
            points="608.28 543.14 608.08 547.87 606.02 543.57 609.28 541.17 608.28 543.14"
          />
          <path
            class="goose-11"
            d="m611.34,537.86c-.15-.12,3.18,5.28.59,7.83l-1.79-6.33,1.19-1.5Z"
          />
          <polygon
            class="goose-11"
            points="609.77 540.05 610.37 545.58 607.66 541.18 609.77 540.05"
          />
          <path
            class="goose-11"
            d="m614.17,537.52s2.43,5.51.08,8.49c0,0-.22-8.96-2.85-8.05s2.77-.44,2.77-.44Z"
          />
          <polyline
            class="goose-11"
            points="613.43 536.32 613.97 545.56 611.75 538.87 611.59 538.39 610.21 550.36 609.77 540.05 609.85 548.24 608.16 542"
          />
          <path
            class="goose-11"
            d="m533.84,568.21c-.99,2.14-2.67,5.61-4.02,7.51,2.33-2.3,7.43-7.09,9.29-8.84-1.75.45-3.51.89-5.27,1.32Z"
          />
          <path
            class="goose-11"
            d="m541.87,566.17c-.65.17-1.29.34-1.94.5-.81.98-2.81,3.33-4.56,5.06,3.11-2.24,8.03-5.61,8.03-5.61l-1.53.04Z"
          />
          <polygon
            class="goose-11"
            points="544.89 566.09 540.7 571.43 541.13 567.7 544.89 566.09"
          />
          <polygon
            class="goose-11"
            points="547 567.76 543.42 574.23 544.89 566.09 549.22 565.97 547 567.76"
          />
          <path
            class="goose-11"
            d="m553.25,563.34c-.03-.32-1.29,10.65-5.19,11.67l3.16-10.75,2.03-.93Z"
          />
          <path
            class="goose-11"
            d="m547.9,564.58l-1,8.71,3.52-8.44-2.03-.41c-.16.04-.33.09-.49.13Z"
          />
          <path
            class="goose-11"
            d="m549.66,577.79c4.03-1.86,6.08-12.1,6.08-12.1,0,0-1.02-.68-1.93-1.32.85,3.19-4.15,13.42-4.15,13.42Z"
          />
          <polyline
            class="goose-11"
            points="556.02 563.26 549.77 576.87 552.85 565.18 553.07 564.34 543.3 579.9 550.42 564.86 544.55 576.54 547.73 566.01"
          />
          <path
            class="goose-11"
            d="m525.69,570.1l-4.12,6.57,6.18-7.02c-.69.15-1.37.31-2.06.46Z"
          />
          <path
            class="goose-11"
            d="m527.89,569.61l-8.47,10.07,11.32-10.73c-.95.22-1.9.44-2.85.66Z"
          />
          <path
            class="goose-11"
            d="m531.93,568.68l-5.66,9.74,8.61-10.46c-.98.24-1.97.48-2.95.72Z"
          />
          <path
            class="goose-11"
            d="m518.25,571.56l-3.32,5.29,4.91-5.58c-.53.1-1.06.19-1.59.29Z"
          />
          <path
            class="goose-11"
            d="m520.05,571.23l-7.26,8.64,9.58-9.08c-.77.15-1.55.3-2.32.44Z"
          />
          <path
            class="goose-11"
            d="m524.42,570.36l-4.79,8.24,7.21-8.76c-.81.18-1.61.35-2.42.52Z"
          />
          <path
            class="goose-11"
            d="m511.53,572.6l-2.19,3.49,3.19-3.62c-.33.05-.67.09-1,.13Z"
          />
          <path
            class="goose-11"
            d="m517.64,571.66l-3.59,6.18,5.34-6.49c-.58.11-1.17.21-1.75.31Z"
          />
          <path
            class="goose-11"
            d="m512.82,572.43l-5.62,6.68,7.32-6.94c-.57.09-1.14.18-1.71.26Z"
          />
          <path
            class="goose-11"
            d="m516.68,571.83l-2.59,4.13,3.82-4.35c-.41.07-.82.14-1.23.21Z"
          />
          <path
            class="goose-11"
            d="m518.17,571.57l-6.23,7.41,8.2-7.77c-.66.12-1.31.24-1.97.36Z"
          />
          <path
            class="goose-11"
            d="m522.88,570.68l-4.09,7.03,6.14-7.45c-.68.14-1.37.29-2.05.42Z"
          />
          <polyline
            class="goose-11"
            points="544.7 566.57 538.33 576.79 541.52 567.92 541.75 567.28 531.87 578.87 539.11 567.58 533.15 576.33 536.43 568.35"
          />
          <path
            class="goose-11"
            d="m555.59,565.55s-.96,5.92-2.27,7.34c-1.31,1.41,6.74-9.13,6.74-9.13"
          />
          <path
            class="goose-11"
            d="m560.48,563.58s-2.82,5.04-4.31,6.21,7-7.28,7-7.28l-2.68,1.08Z"
          />
          <polygon
            class="goose-11"
            points="564.46 561.99 562.24 566.13 561.6 564.07 564.46 561.99"
          />
          <polygon
            class="goose-11"
            points="566.76 562.14 565.38 566.66 564.46 561.99 568.22 560.48 566.76 562.14"
          />
          <path
            class="goose-11"
            d="m571.03,557.79c-.11-.15,1.76,5.9-1.37,7.73l-.15-6.57,1.53-1.15Z"
          />
          <polygon
            class="goose-11"
            points="568.96 559.51 568.17 565.02 566.64 560.09 568.96 559.51"
          />
          <path
            class="goose-11"
            d="m573.85,558.16s.98,5.94-2.04,8.24c0,0,2.02-8.73-.75-8.5-2.77.23,2.79.26,2.79.26Z"
          />
          <path
            class="goose-11"
            d="m573.28,556.92l-4.36,2.8-2.1,9.89,4.33-11.25.03.51.48,7.03,1.77-9.03c-.05.02-.1.04-.15.05Z"
          />
          <polygon
            class="goose-11"
            points="567 567.47 568.91 559.72 566.93 561 567 567.47"
          />
          <path
            class="goose-11"
            d="m519.22,571.39c-.87,1.94-2.85,6.17-4.4,8.34,2.72-2.68,9.22-8.77,9.93-9.43-1.84.39-3.68.76-5.53,1.09Z"
          />
          <path
            class="goose-11"
            d="m525.31,570.22s-5.22,6.43-7.22,7.39,10.31-7.48,10.31-7.48l-3.09.09Z"
          />
          <polygon
            class="goose-11"
            points="529.89 570.09 525.7 575.43 526.13 571.7 529.89 570.09"
          />
          <polygon
            class="goose-11"
            points="532 571.76 528.42 578.23 529.89 570.09 534.22 569.97 532 571.76"
          />
          <path
            class="goose-11"
            d="m538.25,567.34c-.03-.32-1.29,10.65-5.19,11.67l3.16-10.75,2.03-.93Z"
          />
          <path
            class="goose-11"
            d="m532.91,568.44l-1.02,8.86,3.52-8.44-2.32-.47c-.06.01-.12.03-.18.04Z"
          />
          <path
            class="goose-11"
            d="m534.66,581.79c4.03-1.86,6.08-12.1,6.08-12.1,0,0-1.02-.68-1.93-1.32.85,3.19-4.15,13.42-4.15,13.42Z"
          />
          <polyline
            class="goose-11"
            points="541.02 567.26 534.77 580.87 537.85 569.18 538.07 568.34 528.3 583.9 535.42 568.86 529.55 580.54 532.73 570.01"
          />
          <polygon
            class="goose-11"
            points="431.55 563.54 437.26 552.17 434.73 553.01 431.55 563.54"
          />
          <path
            class="goose-11"
            d="m436.77,563.87l5.69-12.38c-.32-.24-.63-.49-.95-.73l-4.23,1.41-6.97,14.74,9.77-15.56-.22.84-3.08,11.69Z"
          />
          <polygon
            class="goose-11"
            points="415.55 552.54 421.26 541.17 418.73 542.01 415.55 552.54"
          />
          <path
            class="goose-11"
            d="m423.13,541.83c-.52-.25-1.04-.51-1.56-.76l-.31.1-6.97,14.74,8.83-14.07Z"
          />
          <path
            class="goose-11"
            d="m423.61,542.06l-2.85,10.81,4.59-9.98c-.58-.28-1.16-.55-1.74-.83Z"
          />
          <path
            class="goose-11"
            d="m511.63,572.59l-5.06,8.07,7.4-8.41c-.78.12-1.56.23-2.34.33Z"
          />
          <path
            class="goose-11"
            d="m517.51,571.69l-6.24,10.73,9.29-11.28c-1.02.19-2.03.38-3.05.55Z"
          />
          <path
            class="goose-11"
            d="m514.04,572.25l-9.62,11.44,12.57-11.91c-.98.17-1.97.32-2.95.47Z"
          />
          <path
            class="goose-11"
            d="m504.63,573.35l-4.7,7.5,6.76-7.68c-.68.07-1.37.12-2.05.18Z"
          />
          <path
            class="goose-11"
            d="m506.79,573.16l-9.01,10.71,11.59-10.98c-.86.1-1.73.19-2.59.28Z"
          />
          <path
            class="goose-11"
            d="m510.35,572.76l-5.72,9.84,8.4-10.2c-.89.13-1.78.24-2.68.36Z"
          />
          <path
            class="goose-11"
            d="m499.78,573.65c-.48.02-.92-.03-1.33-.11l-4.11,6.55,5.68-6.46c-.08,0-.16,0-.24.01Z"
          />
          <path
            class="goose-11"
            d="m500.17,573.63l-7.97,9.48,10.13-9.6c-.72.04-1.44.08-2.16.12Z"
          />
          <path
            class="goose-11"
            d="m503.95,573.4l-4.91,8.44,7.1-8.62c-.73.07-1.46.12-2.19.18Z"
          />
          <path
            class="goose-11"
            d="m503.16,573.47l-4.07,6.49,5.84-6.64c-.59.05-1.18.1-1.77.14Z"
          />
          <path
            class="goose-11"
            d="m508.89,572.94l-5.1,8.78,7.47-9.07c-.79.1-1.58.2-2.37.3Z"
          />
          <path
            class="goose-11"
            d="m505.07,573.31l-8.13,9.67,10.43-9.88c-.77.08-1.53.15-2.3.22Z"
          />
          <polyline
            class="goose-11"
            points="529.7 570.57 523.33 580.79 526.52 571.92 526.75 571.28 516.87 582.87 524.11 571.58 518.15 580.33 521.43 572.35"
          />
          <path
            class="goose-11"
            d="m540.59,569.55s-.96,5.92-2.27,7.34c-1.31,1.41,6.74-9.13,6.74-9.13"
          />
          <path
            class="goose-11"
            d="m545.48,567.58s-2.82,5.04-4.31,6.21,7-7.28,7-7.28l-2.68,1.08Z"
          />
          <polygon
            class="goose-11"
            points="549.46 565.99 547.24 570.13 546.6 568.07 549.46 565.99"
          />
          <polygon
            class="goose-11"
            points="551.76 566.14 550.38 570.66 549.46 565.99 553.22 564.48 551.76 566.14"
          />
          <path
            class="goose-11"
            d="m554.77,562.74l-.27.2.15,6.57c2.51-1.46,1.81-5.63,1.49-7.16-.46.13-.91.25-1.37.38Z"
          />
          <polygon
            class="goose-11"
            points="553.96 563.51 553.17 569.02 551.64 564.09 553.96 563.51"
          />
          <path
            class="goose-11"
            d="m556.96,562.13s-.08.02-.12.03c1.55,1.43-.03,8.24-.03,8.24,3.02-2.3,2.04-8.24,2.04-8.24,0,0-.97,0-1.89-.03Z"
          />
          <path
            class="goose-11"
            d="m555.96,562.41l-2.04,1.31-2.1,9.89,4.33-11.25.03.51.48,7.03,1.6-8.14c-.76.22-1.53.44-2.3.65Z"
          />
          <polygon
            class="goose-11"
            points="552 571.47 553.91 563.72 551.93 565 552 571.47"
          />
          <path
            class="goose-11"
            d="m461.86,565.56s.53,9.79,1.84,11.86-6.62-13.78-6.62-13.78"
          />
          <path
            class="goose-11"
            d="m456.62,563.45s2.67,7.84,4.21,9.44-7.08-10.59-7.08-10.59l2.87,1.15Z"
          />
          <polygon
            class="goose-11"
            points="452.37 561.75 454.45 568.2 455.34 564.56 452.37 561.75"
          />
          <polygon
            class="goose-11"
            points="449.82 562.58 450.93 569.89 452.37 561.75 448.35 560.13 449.82 562.58"
          />
          <path
            class="goose-11"
            d="m445.49,556.27c.14-.29-2.48,10.44.82,12.75l.76-11.18-1.58-1.57Z"
          />
          <polygon
            class="goose-11"
            points="447.61 558.68 447.99 567.81 450.13 559.07 447.61 558.68"
          />
          <path
            class="goose-11"
            d="m442.33,557.6s-1.63,10.32,1.51,13.45c0,0-1.45-14.28,1.6-14.59,3.05-.31-3.11,1.14-3.11,1.14Z"
          />
          <polyline
            class="goose-11"
            points="442.91 555.23 444.06 570.16 445.22 558.13 445.31 557.27 449.07 575.25 447.61 558.68 449.06 571.67 449.73 560.68"
          />
          <polyline
            class="goose-11"
            points="420.91 541.23 422.06 556.16 423.22 544.13 423.31 543.27 427.07 561.25 425.61 544.68 427.06 557.67 427.73 546.68"
          />
          <polyline
            class="goose-11"
            points="460.5 565.21 465.56 579.76 463.41 567.41 463.25 566.52 471.54 583.33 465.76 567.23 470.57 579.75 468.25 568.6"
          />
          <polyline
            class="goose-11"
            points="466.66 567.69 471.72 582.24 469.57 569.89 469.42 569 477.7 585.8 471.92 569.71 476.74 582.23 474.41 571.08"
          />
          <polyline
            class="goose-11"
            points="472.16 568.91 477.22 583.47 475.07 571.12 474.92 570.23 483.2 587.03 477.42 570.94 482.24 583.46 479.91 572.3"
          />
          <polyline
            class="goose-11"
            points="467.76 567.15 472.82 581.7 470.67 569.35 470.52 568.46 478.8 585.26 473.02 569.17 477.84 581.69 475.51 570.54"
          />
          <polyline
            class="goose-11"
            points="452.39 562.26 454.82 574.06 454.9 564.63 454.9 563.95 460.15 578.24 457.28 565.14 459.83 575.42 459.52 566.8"
          />
          <path
            class="goose-11"
            d="m442.53,557.53s-1.15,5.89-.42,7.67-3.15-10.9-3.15-10.9"
          />
          <path
            class="goose-11"
            d="m438.62,553.99s.9,5.71,1.89,7.32-4.04-9.26-4.04-9.26l2.14,1.94Z"
          />
          <polygon
            class="goose-11"
            points="435.44 551.11 436.09 555.77 437.4 554.06 435.44 551.11"
          />
          <polygon
            class="goose-11"
            points="433.23 550.46 432.95 555.17 435.44 551.11 432.44 548.39 433.23 550.46"
          />
          <path
            class="goose-11"
            d="m430.48,545.31c-.79,1.25-3.02,5.02-1.13,7.31l2.42-6.11-.54-.84c-.25-.12-.5-.23-.75-.35Z"
          />
          <polygon
            class="goose-11"
            points="432.07 547.23 430.91 552.67 434.05 548.57 432.07 547.23"
          />
          <path
            class="goose-11"
            d="m429.06,544.65c-.61-.21-1.1-.39-1.1-.39,0,0-2.98,5.23-.95,8.44,0,0,.86-6.78,2.76-7.71-.24-.11-.48-.22-.72-.33Z"
          />
          <polygon
            class="goose-11"
            points="431.16 555.37 433.47 549.33 432.05 547.44 431.16 555.37"
          />
          <path
            class="goose-11"
            d="m428.6,544.44l-1.27,7.84,2.89-6.43.21-.46.15,12.05,1.46-10.01-1.62-2.15c-.61-.28-1.22-.56-1.82-.85Z"
          />
          <path
            class="goose-11"
            d="m495.24,574.16s5.07,8.39,7.21,9.6-12.33-9.04-12.33-9.04"
          />
          <path
            class="goose-11"
            d="m489.63,574.76s6.04,5.66,8.15,6.35-11.23-6.01-11.23-6.01l3.08-.33Z"
          />
          <polygon
            class="goose-11"
            points="485.08 575.26 489.95 579.98 489.02 576.35 485.08 575.26"
          />
          <polygon
            class="goose-11"
            points="483.21 577.19 487.64 583.13 485.08 575.26 480.77 575.72 483.21 577.19"
          />
          <path
            class="goose-11"
            d="m476.43,573.67c-.01-.32,2.72,10.38,6.72,10.86l-4.58-10.22-2.14-.64Z"
          />
          <polygon
            class="goose-11"
            points="479.44 574.79 484.07 582.67 481.84 573.95 479.44 574.79"
          />
          <path
            class="goose-11"
            d="m474.27,576.33s3.42,9.87,7.66,11.16c0,0-8-11.92-5.46-13.63,2.54-1.71-2.21,2.47-2.21,2.47Z"
          />
          <polyline
            class="goose-11"
            points="473.66 573.96 481.7 586.6 477.07 575.43 476.74 574.63 488.52 588.73 479.44 574.79 486.83 585.57 482.25 575.56"
          />
          <polyline
            class="goose-11"
            points="493.88 574.49 505.19 584.95 497.48 575.07 496.93 574.35 512.15 585.28 499.47 573.8 509.61 582.58 502.31 573.84"
          />
          <polygon
            class="goose-11"
            points="508.92 573.12 506.34 573.32 516.22 581.87 508.92 573.12"
          />
          <polygon
            class="goose-11"
            points="506.33 573.32 500.48 573.78 511.79 584.24 504.09 574.35 503.53 573.64 518.75 584.57 506.33 573.32"
          />
          <path
            class="goose-11"
            d="m521.65,580.36l-6.87-8.23c-.75.12-1.5.23-2.24.34l9.11,7.89Z"
          />
          <path
            class="goose-11"
            d="m506.87,573.15l10.36,9.58-7.69-9.87c-.89.11-1.78.2-2.67.29Z"
          />
          <path
            class="goose-11"
            d="m509.91,572.82l14.27,10.24-11.69-10.58c-.86.12-1.72.23-2.58.34Z"
          />
          <path
            class="goose-11"
            d="m516.93,580.87l-6.75-8.09c-.76.09-1.53.18-2.29.26l9.04,7.83Z"
          />
          <path
            class="goose-11"
            d="m504.79,573.34c-.92.08-1.85.14-2.78.2l10.5,9.71-7.71-9.89-.02-.02Z"
          />
          <path
            class="goose-11"
            d="m505.17,573.31l14.3,10.27-11.62-10.52c-.89.1-1.78.18-2.68.26Z"
          />
          <polyline
            class="goose-11"
            points="485.33 575.7 493.03 584.97 488.66 576.62 488.35 576.01 499.71 586.15 491.01 575.95 498.1 583.81 493.76 576.36"
          />
          <path
            class="goose-11"
            d="m474.41,576.17s1.75,5.74,3.24,6.96c1.49,1.22-7.91-8.13-7.91-8.13"
          />
          <path
            class="goose-11"
            d="m469.29,574.88s3.48,4.61,5.11,5.57-7.92-6.27-7.92-6.27l2.81.7Z"
          />
          <polygon
            class="goose-11"
            points="465.14 573.84 467.9 577.64 468.25 575.52 465.14 573.84"
          />
          <polygon
            class="goose-11"
            points="432.14 549.84 434.9 553.64 435.25 551.52 432.14 549.84"
          />
          <polygon
            class="goose-11"
            points="462.88 574.3 464.85 578.6 465.14 573.84 461.21 572.86 462.88 574.3"
          />
          <path
            class="goose-11"
            d="m458.06,570.57c.09-.17-.95,6.09,2.41,7.47l-.74-6.53-1.67-.94Z"
          />
          <polygon
            class="goose-11"
            points="460.34 572 461.86 577.35 462.71 572.25 460.34 572"
          />
          <path
            class="goose-11"
            d="m455.31,571.32s-.17,6.02,3.14,7.89c0,0-3.19-8.38-.41-8.53,2.78-.15-2.73.64-2.73.64Z"
          />
          <polyline
            class="goose-11"
            points="455.54 569.93 458.53 578.69 458.05 571.66 458.02 571.15 463.83 581.72 460.34 572 463.36 579.62 462.56 573.2"
          />
          <circle class="goose-1" cx="322" cy="678" r="22" />
          <path
            class="goose-3"
            d="m338.48,678c0,9.1-7.38,16.48-16.48,16.48-2.99,0-5.79-.8-8.21-2.19-4.94-2.85-8.27-8.18-8.27-14.29,0-9.1,7.38-16.48,16.48-16.48,2.28,0,4.44.46,6.41,1.3,5.91,2.5,10.07,8.36,10.07,15.18Z"
          />
          <line
            class="goose-6"
            x1="318.12"
            y1="661.98"
            x2="326.55"
            y2="693.84"
          />
          <line
            class="goose-2"
            x1="312.35"
            y1="691.36"
            x2="329.92"
            y2="663.55"
          />
          <line
            class="goose-2"
            x1="309.99"
            y1="666.71"
            x2="335.77"
            y2="687.06"
          />
          <line
            class="goose-2"
            x1="333.81"
            y1="666.51"
            x2="307.95"
            y2="687.06"
          />
          <line
            class="goose-2"
            x1="305.56"
            y1="676.78"
            x2="338.24"
            y2="675.21"
          />
          <rect class="goose-9" x="310" y="673" width="2" height="9" />
          <rect class="goose-9" x="311" y="680" width="4" height="2" />
          <rect class="goose-9" x="317" y="673" width="2" height="9" />
          <rect
            class="goose-9"
            x="319.25"
            y="671.25"
            width="2"
            height="5.5"
            transform="translate(994.25 353.75) rotate(90)"
          />
          <rect
            class="goose-9"
            x="326"
            y="671.5"
            width="2.5"
            height="5.5"
            transform="translate(1001.5 347) rotate(90)"
          />
          <rect
            class="goose-9"
            x="319.5"
            y="674"
            width="1.5"
            height="5.5"
            transform="translate(997 356.5) rotate(90)"
          />
          <rect class="goose-9" x="326" y="673" width="2" height="9" />
        </g>
      </g>
    </g>
  );
};

export default Goose;
