import { CircularProgress, Grid, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { GiMatchHead } from "react-icons/gi";
import { RiSwordLine } from "react-icons/ri";
import { useOutletContext } from "react-router-dom";
import { getOngoingMatches } from "../../api/matches";
import { TOKEN_PAGE_ID } from "../../sockets/socketConstants";
import useMatch from "../../sockets/useMatch";
import { SET_ERRORS, StoreDispatch } from "../../store/Store";
import { HOME_PAGE_ONGOING_MATCHES_LIMIT } from "../../utils/limits";
import { emptyIcon } from "../../utils/themeContstants";
import usePagination from "../../utils/usePagination";
import Empty from "../custom/Empty";
import NotSelected from "../custom/NotSelected";
import OngoingGlimmer from "../glimmers/OngoingGlimmer";
import OngoingTokenItem from "../tokens/OngoingTokenItem";

const OngoingMatches = () => {
  const { ongoingToAdd, ongoingToRemove } = useMatch(TOKEN_PAGE_ID);
  const bottom = useRef(null);
  const updateStore = useContext(StoreDispatch);
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const [game] = useOutletContext();

  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const handleGetOngoing = () => {
    getOngoingMatches(skip, null, game).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.matches?.length >= HOME_PAGE_ONGOING_MATCHES_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setMatches(matches ? [...matches, ...res?.matches] : res?.matches);
      }
    });
  };

  usePagination(bottom, () => setSkip(matches?.length));

  useEffect(() => {
    if (loading) {
      if (matches == null) {
        handleGetOngoing();
        return;
      }
    }
  }, [loading, matches]);

  useEffect(() => {
    if (skip !== 0) {
      handleGetOngoing();
    }
  }, [skip]);

  useEffect(() => {
    if (ongoingToAdd) {
      if (ongoingToAdd?.game !== game) return;

      if (matches) {
        setMatches([...matches, ongoingToAdd]);
      }
    }
  }, [ongoingToAdd]);

  useEffect(() => {
    if (ongoingToRemove) {
      if (ongoingToRemove?.game !== game) return;

      if (matches && matches?.length > 0) {
        const indexOfMatch = matches?.findIndex(
          (match) => match?._id === ongoingToRemove?._id
        );
        let newOngoing = matches;
        newOngoing?.splice(indexOfMatch, 1);
        setMatches([...newOngoing]);
      }
    }
  }, [ongoingToRemove]);

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <Grid item sx={styles.width}>
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        {loading && <OngoingGlimmer numItems={6} />}

        {!loading && matches?.length < 1 && (
          <Empty
            title="No ongoing matches"
            label="Try creating a match"
            icon={<GiMatchHead style={emptyIcon} />}
          />
        )}

        {!loading && matches && matches?.length > 0 && (
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gap: 8,
                  gridTemplateColumns: `repeat(${
                    !isDesktop ? "1" : "2"
                  }, minmax(0, 1fr))`,
                }}
              >
                {matches?.map((match, i) => (
                  <OngoingTokenItem token={match} key={i} />
                ))}
              </div>
              {hasMore && (
                <div style={{ width: "100%" }} ref={bottom}>
                  <OngoingGlimmer numItems={1} />
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default OngoingMatches;
