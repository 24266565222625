import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  SET_USER,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { canChangePieceColor } from "../../utils/helpers";
import SpacingWrapper from "../../utils/SpacingWrapper";
import {
  cardDark,
  offWhite,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import Piece from "../../avatar/Piece";
import PiecePreview from "./PiecePreview";
import Avatar from "../../avatar/Avatar";
import SelectedPreview from "./SelectedPreview";
import TextButton from "../custom/TextButton";
import SkinOptions from "./SkinOptions";
import PieceOptions from "./PieceOptions";
import ColorSelect from "./ColorSelect";
import VariantSelect from "./VariantSelect";
import SecondaryButton from "../custom/SecondaryButton";
import PrimaryButton from "../custom/PrimaryButton";
import { setAvatar } from "../../api/avatar";

const Locker = () => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const isDesktop = useMediaQuery("(min-width:1025px)");

  const [selected, setSelected] = useState(null);
  const [currentAvatar, setCurrentAvatar] = useState(null);
  const [currentOption, setCurrentOption] = useState(null);
  const [canChangeColor, setCanChangeColor] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [canReset, setCanReset] = useState(false);

  const handleSaveAvatar = () => {
    setLoading(true);
    setAvatar(middleware, currentAvatar.options).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: "Updated Avatar!" });
        let newUser = store.user;
        newUser.new_avatar = res?.avatar;
        updateStore({ type: SET_USER, payload: newUser });
      }
    });
  };

  const handleChangeOption = (option, value) => {
    let newAvatar = currentAvatar;
    newAvatar.options[`${option}`] = value;
    setCurrentAvatar({ ...newAvatar });
  };

  const handleChangeVariant = (option, variant, isPiece) => {
    let newAvatar = currentAvatar;
    if (isPiece) {
      newAvatar.options[option].current_variant = null;
      setCurrentAvatar({ ...newAvatar });
      return;
    }

    newAvatar.options[option].current_variant = variant;
    setCurrentAvatar({ ...newAvatar });
  };

  const handleResetAvatar = () => {
    const avatar = store?.user?.new_avatar?.options;
    let newAvatar = {};
    let completeAvatar = {};
    newAvatar.tattoos = avatar?.tattoos;
    newAvatar.earrings = avatar?.earrings;
    newAvatar.necklace = avatar?.necklace;
    newAvatar.backBling = avatar?.backBling;
    newAvatar.hat = avatar?.hat;
    newAvatar.hair = avatar?.hair;
    newAvatar.glasses = avatar?.glasses;
    newAvatar.eyebrows = avatar?.eyebrows;
    newAvatar.eyes = avatar?.eyes;
    newAvatar.mouth = avatar?.mouth;
    newAvatar.facialHair = avatar?.facialHair;
    newAvatar.nose = avatar?.nose;
    newAvatar.clothes = avatar?.clothes;
    newAvatar.skinColor = avatar.skinColor;
    newAvatar.hairColor = avatar.hairColor;
    newAvatar.facialHairColor = avatar.facialHairColor;
    newAvatar.clothesColor = avatar.clothesColor;
    newAvatar.hatColor = avatar.hatColor;
    newAvatar.emote = avatar.emote;
    completeAvatar.options = newAvatar;
    setCurrentAvatar(completeAvatar);
  };

  const getTitle = () => {
    switch (selected) {
      case "skin":
        return "Selecting Skin Tone";
      case "hair":
        return "Selecting Hair";
      case "hat":
        return "Selecting Hat";
      case "facialHair":
        return "Selecting Facial Hair";
      case "earrings":
        return "Selecting Earrings";
      case "eyes":
        return "Selecting Eyes";
      case "eyebrows":
        return "Selecting Eyebrows";
      case "nose":
        return "Selecting Nose";
      case "mouth":
        return "Selecting Mouth";
      case "glasses":
        return "Selecting Glasses";
      case "clothes":
        return "Selecting Clothes";
      case "necklace":
        return "Selecting Necklace";
      case "tattoos":
        return "Selecting Tattoos";
      case "backBling":
        return "Selecting Back Bling";
      case "emote":
        return "Selecting Emote";
      default:
        return "Your Locker";
    }
  };

  const getDescription = () => {
    switch (selected) {
      case "skin":
        return "Select an option to set your skin tone.";
      case "hair":
        return "Select an option to set your hair.";
      case "hat":
        return "Select an option to set your hat.";
      case "facialHair":
        return "Select an option to set your facial hair.";
      case "earrings":
        return "Select an option to set your earrings.";
      case "eyes":
        return "Select an option to set your eyes.";
      case "eyebrows":
        return "Select an option to set your eyebrows.";
      case "nose":
        return "Select an option to set your nose.";
      case "mouth":
        return "Select an option to set your mouth.";
      case "glasses":
        return "Select an option to set your glasses.";
      case "clothes":
        return "Select an option to set your clothes.";
      case "necklace":
        return "Select an option to set your necklace.";
      case "tattoos":
        return "Select an option to set your tattoos.";
      case "backBling":
        return "Select an option to set your back bling.";
      case "emote":
        return "Select an option to set your emote.";
      default:
        return "Select an option to customize your avatar.";
    }
  };

  useEffect(() => {
    if (store?.user?.new_avatar?.options) {
      handleResetAvatar();
    }
  }, [store?.user?.new_avatar]);

  useEffect(() => {
    setCurrentOption(null);
  }, [selected]);

  useEffect(() => {
    if (currentOption) {
      return setCanChangeColor(
        canChangePieceColor(selected, currentOption?.value)
      );
    }
  }, [currentOption, selected]);

  useEffect(() => {
    const avatar = store?.user?.new_avatar?.options;
    const current = currentAvatar?.options;

    if (
      current?.tattoos?.value === avatar?.tattoos?.value &&
      current?.earrings?.value === avatar?.earrings?.value &&
      current?.necklace?.value === avatar?.necklace?.value &&
      current?.backBling?.value === avatar?.backBling?.value &&
      current?.hat?.value === avatar?.hat?.value &&
      current?.hair?.value === avatar?.hair?.value &&
      current?.glasses?.value === avatar?.glasses?.value &&
      current?.eyebrows?.value === avatar?.eyebrows?.value &&
      current?.eyes?.value === avatar?.eyes?.value &&
      current?.mouth?.value === avatar?.mouth?.value &&
      current?.facialHair?.value === avatar?.facialHair?.value &&
      current?.nose?.value === avatar?.nose?.value &&
      current?.clothes?.value === avatar?.clothes?.value &&
      current?.skinColor === avatar?.skinColor &&
      current?.hairColor === avatar?.hairColor &&
      current?.facialHairColor === avatar?.facialHairColor &&
      current?.clothesColor === avatar?.clothesColor &&
      current?.hatColor === avatar?.hatColor &&
      current?.emote?.value === avatar?.emote?.value
    ) {
      setCanSave(false);
      setCanReset(false);
    }
  }, [currentAvatar]);

  useEffect(() => {
    const avatar = store?.user?.new_avatar?.options;
    const current = currentAvatar?.options;

    if (
      current?.tattoos?.value !== avatar?.tattoos?.value ||
      current?.earrings?.value !== avatar?.earrings?.value ||
      current?.necklace?.value !== avatar?.necklace?.value ||
      current?.backBling?.value !== avatar?.backBling?.value ||
      current?.hat?.value !== avatar?.hat?.value ||
      current?.hair?.value !== avatar?.hair?.value ||
      current?.glasses?.value !== avatar?.glasses?.value ||
      current?.eyebrows?.value !== avatar?.eyebrows?.value ||
      current?.eyes?.value !== avatar?.eyes?.value ||
      current?.mouth?.value !== avatar?.mouth?.value ||
      current?.facialHair?.value !== avatar?.facialHair?.value ||
      current?.nose?.value !== avatar?.nose?.value ||
      current?.clothes?.value !== avatar?.clothes?.value ||
      current?.skinColor !== avatar?.skinColor ||
      current?.hairColor !== avatar?.hairColor ||
      current?.facialHairColor !== avatar?.facialHairColor ||
      current?.clothesColor !== avatar?.clothesColor ||
      current?.hatColor !== avatar?.hatColor ||
      current?.emote?.value !== avatar?.emote?.value
    ) {
      setCanSave(true);
      setCanReset(true);
    }
  }, [currentAvatar]);

  useEffect(() => {
    if (currentAvatar?.options) {
      Object.keys(currentAvatar?.options)?.map((option) => {
        if (currentAvatar?.options[`${option}`]?._id) {
          if (
            store?.user?.new_locker?.options[`${option}`]?.findIndex(
              (opt) => opt === currentAvatar?.options[`${option}`]?._id
            ) === -1
          ) {
            setCanSave(false);
          }
        }
      });
    }
  }, [currentAvatar]);

  const styles = {
    container: {
      width: "100%",
      minHeight: "100vh",
    },
    width: {
      width: "100%",
    },
  };

  return currentAvatar == null ? null : (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <SpacingWrapper>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={{ xs: 4 }}
          >
            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent={isDesktop ? "space-between" : "center"}
                alignItems="center"
                gap={{ xs: 4 }}
                sx={{ paddingBottom: 8, maxWidth: 1200 }}
                wrap={isDesktop ? "nowrap" : "wrap"}
              >
                {!isDesktop && (
                  <Grid item sx={{ marginBottom: "auto" }}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <Grid item sx={{ position: "relative" }}>
                        <Avatar
                          size={350}
                          avatar={currentAvatar}
                          bgColor={cardDark}
                        />

                        {selected != null && currentOption && (
                          <SelectedPreview option={currentOption} />
                        )}
                      </Grid>

                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          direction="column"
                          alignItems="start"
                          justifyContent="center"
                          gap={{ xs: 1 }}
                        >
                          <Grid item sx={styles.width}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              gap={{ xs: 2 }}
                            >
                              <Grid item>
                                <SecondaryButton
                                  label="Reset Options"
                                  onClick={handleResetAvatar}
                                  disabled={loading || !canReset}
                                />
                              </Grid>

                              <Grid item>
                                <PrimaryButton
                                  type="green"
                                  label="Save Changes"
                                  onClick={handleSaveAvatar}
                                  loading={loading}
                                  disabled={!canSave}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          {selected != null &&
                            currentOption?.variants?.length > 0 && (
                              <VariantSelect
                                selected={selected}
                                avatar={currentAvatar}
                                onChange={handleChangeVariant}
                                option={currentOption}
                              />
                            )}

                          {selected != null &&
                            currentOption &&
                            canChangeColor && (
                              <ColorSelect
                                selected={selected}
                                avatar={currentAvatar}
                                onChange={handleChangeOption}
                              />
                            )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid
                  item
                  sx={{
                    width: isDesktop ? null : "100%",
                    marginBottom: "auto",
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    alignItems={"start"}
                    justifyContent="center"
                    gap={{ xs: selected == null ? 4 : 0 }}
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                      >
                        {selected != null && (
                          <Grid item>
                            <TextButton
                              label="Back"
                              color={offWhite}
                              icon={
                                <BiArrowBack
                                  style={{
                                    color: offWhite,
                                    fontSize: 22,
                                  }}
                                />
                              }
                              onClick={() => setSelected(null)}
                            />
                          </Grid>
                        )}

                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: 28,
                              color: text,
                              fontWeight: 800,
                            }}
                          >
                            {getTitle()}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: 16,
                              color: secondaryText,
                              fontWeight: 500,
                            }}
                          >
                            {getDescription()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    {selected == null && (
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          direction="column"
                          alignItems="start"
                          justifyContent="center"
                          gap={{ xs: 1 }}
                        >
                          <Grid item sx={styles.width}>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              gap={{ xs: 1 }}
                            >
                              <PiecePreview
                                title="Skin Tone"
                                size={100}
                                value={currentAvatar?.options?.skinColor}
                                selected={selected === "skin"}
                                onClick={() => setSelected("skin")}
                              >
                                <Piece
                                  type="color"
                                  color={currentAvatar?.options?.skinColor}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Hair"
                                size={100}
                                option={currentAvatar?.options?.hair}
                                value={currentAvatar?.options?.hair?.value}
                                selected={selected === "hair"}
                                onClick={() => setSelected("hair")}
                              >
                                <Piece
                                  type="hair"
                                  color={currentAvatar?.options?.hairColor}
                                  option={currentAvatar?.options?.hair?.value}
                                  size={100}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Hat"
                                size={100}
                                value={currentAvatar?.options?.hat?.value}
                                option={currentAvatar?.options?.hat}
                                selected={selected === "hat"}
                                onClick={() => setSelected("hat")}
                              >
                                <Piece
                                  type="hat"
                                  option={currentAvatar?.options?.hat?.value}
                                  size={100}
                                  color={currentAvatar?.options?.hatColor}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Facial Hair"
                                size={100}
                                option={currentAvatar?.options?.facialHair}
                                value={
                                  currentAvatar?.options?.facialHair?.value
                                }
                                selected={selected === "facialHair"}
                                onClick={() => setSelected("facialHair")}
                              >
                                <Piece
                                  type="facialHair"
                                  option={
                                    currentAvatar?.options?.facialHair?.value
                                  }
                                  color={
                                    currentAvatar?.options?.facialHairColor
                                  }
                                  size={100}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Earrings"
                                size={100}
                                option={currentAvatar?.options?.earrings}
                                value={currentAvatar?.options?.earrings?.value}
                                selected={selected === "earrings"}
                                onClick={() => setSelected("earrings")}
                              >
                                <Piece
                                  type="earrings"
                                  option={
                                    currentAvatar?.options?.earrings?.value
                                  }
                                  size={100}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Eyes"
                                size={100}
                                option={currentAvatar?.options?.eyes}
                                value={currentAvatar?.options?.eyes?.value}
                                selected={selected === "eyes"}
                                onClick={() => setSelected("eyes")}
                              >
                                <Piece
                                  type="eyes"
                                  option={currentAvatar?.options?.eyes?.value}
                                  size={100}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Eyebrows"
                                size={100}
                                option={currentAvatar?.options?.eyebrows}
                                value={currentAvatar?.options?.eyebrows?.value}
                                selected={selected === "eyebrows"}
                                onClick={() => setSelected("eyebrows")}
                              >
                                <Piece
                                  type="eyebrows"
                                  option={
                                    currentAvatar?.options?.eyebrows?.value
                                  }
                                  size={100}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Nose"
                                size={100}
                                option={currentAvatar?.options?.nose}
                                value={currentAvatar?.options?.nose?.value}
                                selected={selected === "nose"}
                                onClick={() => setSelected("nose")}
                              >
                                <Piece
                                  type="nose"
                                  option={currentAvatar?.options?.nose?.value}
                                  size={100}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Mouth"
                                size={100}
                                option={currentAvatar?.options?.mouth}
                                value={currentAvatar?.options?.mouth?.value}
                                selected={selected === "mouth"}
                                onClick={() => setSelected("mouth")}
                              >
                                <Piece
                                  type="mouth"
                                  option={currentAvatar?.options?.mouth?.value}
                                  size={100}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Glasses"
                                size={100}
                                option={currentAvatar?.options?.glasses}
                                value={currentAvatar?.options?.glasses?.value}
                                selected={selected === "glasses"}
                                onClick={() => setSelected("glasses")}
                              >
                                <Piece
                                  type="glasses"
                                  option={
                                    currentAvatar?.options?.glasses?.value
                                  }
                                  size={100}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Clothes"
                                size={100}
                                option={currentAvatar?.options?.clothes}
                                value={currentAvatar?.options?.clothes?.value}
                                selected={selected === "clothes"}
                                onClick={() => setSelected("clothes")}
                              >
                                <Piece
                                  type="clothes"
                                  option={
                                    currentAvatar?.options?.clothes?.value
                                  }
                                  color={currentAvatar?.options?.clothesColor}
                                  skinColor={currentAvatar?.options?.skinColor}
                                  size={100}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Necklace"
                                size={100}
                                option={currentAvatar?.options?.necklace}
                                value={currentAvatar?.options?.necklace?.value}
                                selected={selected === "necklace"}
                                onClick={() => setSelected("necklace")}
                              >
                                <Piece
                                  type="necklace"
                                  option={
                                    currentAvatar?.options?.necklace?.value
                                  }
                                  size={100}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Back Bling"
                                size={100}
                                option={currentAvatar?.options?.backBling}
                                value={currentAvatar?.options?.backBling?.value}
                                selected={selected === "backBling"}
                                onClick={() => setSelected("backBling")}
                              >
                                <Piece
                                  type="backBling"
                                  option={
                                    currentAvatar?.options?.backBling?.value
                                  }
                                  size={100}
                                  skinColor={currentAvatar?.options?.skinColor}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Tattoos"
                                size={100}
                                option={currentAvatar?.options?.tattoos}
                                value={currentAvatar?.options?.tattoos?.value}
                                selected={selected === "tattoos"}
                                onClick={() => setSelected("tattoos")}
                              >
                                <Piece
                                  type="tattoos"
                                  option={
                                    currentAvatar?.options?.tattoos?.value
                                  }
                                  size={100}
                                />
                              </PiecePreview>

                              <PiecePreview
                                title="Emote"
                                size={100}
                                option={currentAvatar?.options?.emote}
                                value={currentAvatar?.options?.emote?.value}
                                selected={selected === "emote"}
                                onClick={() => setSelected("emote")}
                              >
                                <Piece
                                  type="emote"
                                  option={
                                    currentAvatar?.options?.emote?.value
                                  }
                                  size={100}
                                />
                              </PiecePreview>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {selected === "skin" && (
                      <SkinOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                      />
                    )}

                    {selected === "hair" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="hair"
                      />
                    )}

                    {selected === "hat" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="hat"
                      />
                    )}

                    {selected === "facialHair" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="facialHair"
                      />
                    )}

                    {selected === "earrings" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="earrings"
                      />
                    )}

                    {selected === "eyes" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="eyes"
                      />
                    )}

                    {selected === "eyebrows" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="eyebrows"
                      />
                    )}

                    {selected === "nose" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="nose"
                      />
                    )}

                    {selected === "mouth" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="mouth"
                      />
                    )}

                    {selected === "glasses" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="glasses"
                      />
                    )}

                    {selected === "clothes" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="clothes"
                      />
                    )}

                    {selected === "necklace" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="necklace"
                      />
                    )}

                    {selected === "backBling" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="backBling"
                      />
                    )}

                    {selected === "tattoos" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="tattoos"
                      />
                    )}
                    {selected === "emote" && (
                      <PieceOptions
                        avatar={currentAvatar}
                        onChange={handleChangeOption}
                        setSelected={setCurrentOption}
                        type="emote"
                      />
                    )}
                  </Grid>
                </Grid>

                {isDesktop && (
                  <Grid item sx={{ marginBottom: "auto" }}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <Grid item sx={{ position: "relative" }}>
                        <Avatar
                          size={450}
                          avatar={currentAvatar}
                          bgColor={cardDark}
                        />

                        {selected != null && currentOption && (
                          <SelectedPreview option={currentOption} />
                        )}
                      </Grid>

                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          direction="column"
                          alignItems="start"
                          justifyContent="center"
                          gap={{ xs: 1 }}
                        >
                          <Grid item sx={styles.width}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              gap={{ xs: 2 }}
                            >
                              <Grid item>
                                <SecondaryButton
                                  label="Reset Options"
                                  onClick={handleResetAvatar}
                                  disabled={loading || !canReset}
                                />
                              </Grid>

                              <Grid item>
                                <PrimaryButton
                                  type="green"
                                  label="Save Changes"
                                  onClick={handleSaveAvatar}
                                  loading={loading}
                                  disabled={!canSave}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          {selected != null &&
                            currentOption?.variants?.length > 0 && (
                              <VariantSelect
                                selected={selected}
                                avatar={currentAvatar}
                                onChange={handleChangeVariant}
                                option={currentOption}
                              />
                            )}

                          {selected != null &&
                            currentOption &&
                            canChangeColor && (
                              <ColorSelect
                                selected={selected}
                                avatar={currentAvatar}
                                onChange={handleChangeOption}
                              />
                            )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </SpacingWrapper>
      </Grid>
    </Grid>
  );
};

export default Locker;
