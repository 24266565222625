import { useState } from "react";
import styled from "styled-components";
import {
  cardDark,
  cardDarker,
  cardVeryLight,
  secondaryButton,
  secondaryText,
  text,
} from "../../utils/themeContstants";

const ChatWrapper = styled.div`
  width: 100%;
`;

const MessageWrapper = styled.div`
  min-height: 43.5px;
  max-height: 140px;
  width: 100%;
  max-width: 100%;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor};
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 12px;
  padding-top: 4px;
  text-align: left;
  display: flex;
  justify-content: start;
  align-items: center;
  overflow-y: auto;
  cursor: ${(props) => props.cursor};
  transition: all 0.2s ease-in-out;
  ::-webkit-scrollbar {
    background-color: transparent;
    max-width: 6px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.scrollBarColor};
    border-radius: 0.8em;
  }
  :hover::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.scrollBarColor};
  }
`;

const MessageInputArea = styled.div`
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  align-content: center;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: 500;
  outline: none;
  ::placeholder,
  background-color: transparent;
  font-family: "Inter";
  webkit-appearance: none;
  transition: all 0.2s ease-in-out;
  border: none;
  resize: none;
  white-space: pre-wrap;
  :empty:before {
    content: attr(placeholder);
    opacity: 1;
    color: ${(props) => props.placeholderColor};
    font-size: 14px;
    font-weight: 500;
  }
  margin-right: 8px;
`;

const ChatInput = ({ placeholder, onChange, onKeyDown, disabled, onPaste }) => {
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);

  return (
    <ChatWrapper>
      <MessageWrapper
        backgroundColor={disabled ? secondaryButton : cardDark}
        scrollBarColor={cardVeryLight}
        cursor={
          disabled ? "not-allowed" : focused || hovered ? "text" : "default"
        }
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <MessageInputArea
          id={"chat-input"}
          contentEditable={disabled ? "false" : "true"}
          role="textbox"
          tabIndex={0}
          placeholder={placeholder}
          onPaste={(e) => {
            e.preventDefault();
            const text = e.clipboardData.getData("text");
            e.currentTarget.innerText = text;
          }}
          onInput={(e) => {
            if (disabled) return;

            onChange(e.currentTarget.innerText);
          }}
          onKeyDown={(e) => {
            if (disabled) {
              return;
            }

            if (e.key === "Enter") {
              if (e.shiftKey) {
                return;
              }

              if (e.currentTarget.innerText === "") {
                return;
              }

              e.preventDefault();
              onKeyDown();
              e.currentTarget.innerText = "";
            }
          }}
          color={text}
          placeholderColor={secondaryText}
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          onPaste={(e) => onPaste(e.target.value)}
        ></MessageInputArea>
      </MessageWrapper>
    </ChatWrapper>
  );
};

export default ChatInput;
