import { Grid, Typography } from "@mui/material";
import {
  cardDark,
  cardVeryLight,
  secondaryText,
  text,
  transition,
} from "../../utils/themeContstants";
import RivoxCoin from "../custom/RivoxCoin";

const DepositItem = ({ amount, onClick }) => {
  const styles = {
    container: {
      padding: 2,
      borderRadius: 1,
      border: `2px solid ${cardVeryLight}`,
      width: 200,
      minWidth: 200,
      backgroundColor: cardDark,
      transition: transition,
      "&:hover": {
        cursor: "pointer",
        border: `2px solid ${secondaryText}`,
        backgroundColor: "#1c1f2c",
      },
    },
  };

  return (
    <Grid item sx={styles.container} onClick={onClick}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap={{ xs: 0.5 }}
      >
        <RivoxCoin size={75} />

        <Grid item>
          <Typography sx={{ fontSize: 22, color: text, fontWeight: 700 }}>
            ${amount}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DepositItem;
