import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import {
  currencyFormatter,
  hexToRgbA,
  numFormatter,
} from "../../utils/helpers";
import {
  blue,
  card,
  cardLight,
  cardVeryLight,
  green,
  lightGrey,
  menuShadow,
  purple,
  red,
  secondaryText,
  text,
  transition,
} from "../../utils/themeContstants";
// import RivoxCoin from "../custom/RivoxCoin";
import RivoxCoin from "../../assets/coin.svg";
import PayPalDepositModal from "../wallet/PayPalDepositModal";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../store/Store";

const CoinItem = ({ amount }) => {
  const isDesktop = useMediaQuery("(min-width:1025px)");
  const navigate = useNavigate();
  const store = useContext(StoreContext);

  const [hovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);

  const getBorder = () => {
    switch (amount) {
      case 5:
        return green;
      case 10:
        return blue;
      case 25:
        return purple;
      case 50:
        return "#fb8b24";
      case 100:
        return red;
      default:
        return null;
    }
  };

  const getBackground = () => {
    switch (amount) {
      case 5:
        return `rgba(21, 226, 164, 0.3)`;
      case 10:
        return `rgba(21, 117, 226, 0.3)`;
      case 25:
        return `rgba(112, 82, 221, 0.3)`;
      case 50:
        return `rgba(251, 139, 36, 0.3)`;
      case 100:
        return `rgba(255, 84, 101, 0.3)`;
      default:
        return null;
    }
  };

  const styles = {
    container: {
      borderRadius: 2,
      border: `3px solid ${cardVeryLight}`,
      boxSizing: "border-box",
      backgroundColor: hexToRgbA(cardVeryLight, 0.3),
      transition: transition,
      cursor: hovered ? "pointer" : "cursor",
      transform: hovered ? "translateY(-5px)" : null,
      boxShadow: hovered ? menuShadow : null,
      position: "relative",
    },
    wordContainer: {
      width: "100%",
      padding: 1,
      backgroundColor: hexToRgbA(cardVeryLight, 0.3),
      marginTop: "auto",
      position: "relative",
    },
    name: {
      fontSize: 18,
      color: "#fff",
      fontWeight: 800,
      textAlign: "center",
    },
  };

  return (
    <>
      <PayPalDepositModal
        amount={amount}
        open={open}
        onClose={() => setOpen(false)}
      />

      <Grid
        item
        sx={styles.container}
        onClick={() => {
          if (!store?.user) {
            return navigate("/login");
          } else {
            return setOpen(true);
          }
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Grid
          container
          direction="column"
          alignItems={isDesktop ? "start" : "center"}
          justifyContent="center"
          sx={{ height: "100%" }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "auto",
              marginLeft: "auto",
              padding: 2,
            }}
          >
            <img src={RivoxCoin} draggable={false} width={150} />
          </Grid>

          <Grid item sx={styles.wordContainer}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography sx={styles.name}>{amount} Coins</Typography>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <Grid item>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: 800, color: text }}
                    >
                      {currencyFormatter().format(amount)}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: red,
                        fontWeight: 800,
                        textDecoration: "line-through",
                      }}
                    >
                      <span style={{ color: secondaryText }}>
                        {currencyFormatter().format(amount * 1.5)}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CoinItem;
