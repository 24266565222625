const WrapItUpThree = () => {
  const styles = {
    one: {
      fill: "#c4c6b7",
    },
    two: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      fill: "#ced7c7",
    },
  };

  return (
    <g>
      <g>
        <g>
          <path
            style={styles.three}
            d="M661.08,396.39c.38-.16,.78,.18,.69,.59-3,12.66-5.99,25.32-8.98,37.97-.05,.21-.28,.25-.44,.39-.17,.15-.35,.29-.52,.41,.07-.28,.12-.53,.17-.75-4.3,1.53-9.27,2.97-14.65,3.86-.43,.04-.89,.09-1.35,.14-2.07,.22-3.49,.43-4.61,.59-2.94,.24-5.97,.27-9.06,.06-.01-.01-.02-.01-.03,0-.98-.13-2.16-.28-3.6-.47-1.08-.14-2.02-.27-2.84-.38-2.28-.43-4.57-1.03-6.86-1.8-14.96-5.04-15.65-12.89-30-17-12.83-3.68-25.02-1.06-41.28,4.13,0,0-.02,0-.02,.01-4.78,1.23-8.74,2.48-11.47,3.4-7.07,2.38-8.5,3.33-12.14,4.85-.04,.02-.08,.03-.11,.04-.96,.38-1.88,.74-2.77,1.09-.03,0-.05,.02-.07,.03-1.14,.42-2.49,.9-4.14,1.45-3.8,1.28-10.66,3.45-19.74,4.15,0,0-.04,0-.11,.02h-.1c-8.53-1.19-17.79-2.2-27.71-2.9-12.1-.84-23.36-1.09-33.63-.97-.24,0-.45-.17-.5-.41-1.58-7.93-3.16-15.86-4.74-23.78l-2.86-18.59c10.98-1.5,20.19-5.9,51.46-4.74,20.77,.77,27.2-.08,30.93-.78,1.57-.29,7.36-1.43,15.54-2.83,2.97-.51,6.74-1.13,9.35-1.56,4.08-.68,7.47-1.23,9.85-1.62,0,0,6.27-.9,15.73-.97,.02,0,.05,0,.07,0,.05,0,.09,.01,.09,.01,.12,.04,10.31,1.81,18.37,4.96,7.24,2.83,13.95,7.24,15,8,0,0,1.65,1.18,4.23,2.53,.01,0,.02,.01,.02,.01,.01,0,.02,0,.02,.01,11.13,4.55,32.37,11.18,57.14,5.81,5.84-1.27,11.07-3.03,15.67-4.97Z"
          />
          <path
            style={styles.three}
            d="M651.83,435.76c-.19,.84-.47,1.96-.83,3.24,0,0-.82,2.91-2,6-7.87,20.63-18,34-18,34-1.58,2.08-2.9,3.67-4,5-5.46,6.57-10.42,11.6-14,15,0,0-7.66,7.17-16,13-20.06,14.01-47.18,20.63-53,22-14.49,2.22-40.16,4.18-70.06-4.62-4.75-1.4-9.27-2.97-13.58-4.67-18.7-12.85-29.27-21.96-35.27-27.7-11.14-7.52-21.05-14.87-28.78-21.87-2.37-2.14-4.35-4.08-6.01-5.83-.03,.43-.06,.84-.09,1.23-.4-.51-.81-1.03-1.21-1.54-4.08-9.94-8.16-19.88-12.25-29.82,16.48-1.78,30.85-2.57,42.46-2.91,16.46-.47,28.94-.83,45.79,.73,14.28,1.32,14.65,2.44,22.15,2.18,9.93-.34,16.07-2.53,23.99-5.63,.02-.01,.04-.02,.07-.03,1.08-.4,1.98-.76,2.77-1.09,.03-.01,.07-.02,.11-.04,3.43-1.35,7.28-2.83,11.91-4.39,4.14-1.39,8.02-2.69,11.7-3.86,0-.01,.01-.01,.02-.01,2.94-.75,6.18-1.49,9.64-2.14,3.91-.73,6.79-1.09,8.06-1.23,2.3-.27,4.45-.45,6.39-.57,1.94-.11,3.69-.17,5.19-.19,6.11-.38,12.75,1.03,21.25,5.6,6.02,3.24,6.86,4.87,9.6,6.86,2.05,1.48,3.81,2.29,4.99,2.84,3.43,1.61,6.27,2.27,8.16,2.7,1.27,.29,2.66,.51,4.86,.8,2.16,.42,4.31,.69,6.44,.85,.01-.01,.02-.01,.03,0,1.65,.2,2.75,.31,3.67,.35,2.25,.09,3.27-.09,5.39-.41,2.03-.15,4.02-.4,5.96-.73,7.4-.72,11.04-.5,14.48-3.1Z"
          />
          <path
            style={styles.three}
            d="M621,492c-1.9,7.37-10.2,36.11-37.61,51.93-20.36,11.75-41.04,11.29-52.19,9.74-11.17-1.55-26.81-4.74-43.89-11.68-12.45-5.06-22.37-10.78-29.93-15.89,9.71,5.14,19.07,10.12,27.26,14.49-7.15-4.49-14.11-8.99-20.7-13.45,8.6,3.21,18.9,6.2,30.67,8.06,23.65,3.74,43.5,1.39,56.39-1.2,6.81-1.9,14.23-4.48,22-8,9.11-4.12,17.16-8.85,24.19-13.73,9.88-6.86,17.77-14.02,23.81-20.27Z"
          />
          <path
            style={styles.three}
            d="M460.93,525.1c.99,.68,2,1.36,3.01,2.04-4.68-1.74-8.86-3.55-12.51-5.28,1.79,1.34,3.77,2.77,5.95,4.24-7.07-3.76-14.33-7.59-21.43-11.33-18.45-9.72-21.61-11.26-26.28-13.98-6.09-3.54-13.51-8.35-20.51-14.59-.6-.62-2.22-2.4-2.42-4.14-.08-.71,.1-1.22,.29-1.56,.73-.82,1.51-1.92,2.03-3.37,.22-.58,.33-1.06,.42-1.47,.21-1.05,.52-2.72,.73-5.12,3.27,4.16,6.52,8.3,9.79,12.46,9.06,9.91,28.99,29.32,60.36,41.71l.57,.39Z"
          />
          <path
            style={styles.three}
            d="M425.09,497.01c6,5.74,16.57,14.85,35.27,27.7-31.37-12.39-51.3-31.8-60.36-41.71-3.27-4.16-6.52-8.3-9.79-12.46,.03-.39,.06-.8,.09-1.23,1.66,1.75,3.64,3.69,6.01,5.83,7.73,7,17.64,14.35,28.78,21.87Z"
          />
          <path
            style={styles.three}
            d="M420.47,411.11l3.53,22.98-47.34,4.91c-1.22-26.18-2.44-52.37-3.66-78.55,.67-1.15,1.82-2.97,3.56-4.91,6.96-7.8,14.69-7.99,27.28-14.73,2.24-1.2,5.54-3.09,9.39-5.81l2.47,45.09,1.91,12.43c-.1,0-.19,.03-.29,.04-.29,.04-.49,.32-.43,.61,1.19,5.98,2.38,11.96,3.58,17.94Z"
          />
        </g>
        <g>
          <path
            style={styles.two}
            d="M511.14,433.55c-1.14,.42-2.49,.9-4.14,1.45-3.8,1.28-10.66,3.45-19.74,4.15,0,0-.04,0-.11,.02h-.1c-8.53-1.19-17.79-2.2-27.71-2.9-12.1-.84-23.36-1.09-33.63-.97-.24,0-.45-.17-.5-.41-1.58-7.93-3.16-15.86-4.74-23.78-1.2-5.98-2.39-11.96-3.58-17.94-.06-.29,.14-.57,.43-.61,.1-.01,.19-.03,.29-.04,10.98-1.5,20.19-5.9,51.46-4.74,20.77,.77,27.2-.08,30.93-.78,1.57-.29,7.36-1.43,15.54-2.83,2.97-.51,6.74-1.13,9.35-1.56,4.08-.68,7.47-1.23,9.85-1.62,0,0,6.27-.9,15.73-.97,.02,0,.05,0,.07,0,.05,0,.09,.01,.09,.01,.12,.04,10.31,1.81,18.37,4.96,7.24,2.83,13.95,7.24,15,8,0,0,1.65,1.18,4.23,2.53,.01,0,.02,.01,.02,.01,.01,0,.02,0,.02,.01,11.13,4.55,32.37,11.18,57.14,5.81,5.84-1.27,11.07-3.03,15.67-4.97,.38-.16,.78,.18,.69,.59-3,12.66-5.99,25.32-8.98,37.97-.05,.21-.28,.25-.44,.39-.17,.15-.35,.29-.52,.41"
          />
          <path
            style={styles.two}
            d="M513.98,432.43c-.79,.33-1.69,.69-2.77,1.09"
          />
          <path
            style={styles.two}
            d="M537.7,424.14c-4.78,1.23-8.74,2.48-11.47,3.4-7.07,2.38-8.5,3.33-12.14,4.85"
          />
          <path
            style={styles.two}
            d="M622.3,439.65c-.98-.13-2.16-.28-3.6-.47-1.08-.14-2.02-.27-2.84-.38-2.2-.29-3.59-.51-4.86-.8-1.89-.43-4.73-1.09-8.16-2.7-1.18-.55-2.94-1.36-4.99-2.84-2.74-1.99-3.58-3.62-9.6-6.86-8.5-4.57-15.14-5.98-21.25-5.6-1.5,.02-3.25,.08-5.19,.19-1.94,.12-4.09,.3-6.39,.57-1.27,.14-4.15,.5-8.06,1.23-3.46,.65-6.7,1.39-9.64,2.14"
          />
          <path
            style={styles.two}
            d="M651.83,435.76c-3.44,2.6-7.08,2.38-14.48,3.1-.43,.04-.89,.09-1.35,.14-2.07,.22-3.49,.43-4.61,.59-2.12,.32-3.14,.5-5.39,.41-.92-.04-2.02-.15-3.67-.35"
          />
          <path
            style={styles.two}
            d="M487.15,439.18c-7.5,.26-7.87-.86-22.15-2.18-16.85-1.56-29.33-1.2-45.79-.73-11.61,.34-25.98,1.13-42.46,2.91,4.09,9.94,8.17,19.88,12.25,29.82,.4,.51,.81,1.03,1.21,1.54,3.27,4.16,6.52,8.3,9.79,12.46,9.06,9.91,28.99,29.32,60.36,41.71,4.31,1.7,8.83,3.27,13.58,4.67,29.9,8.8,55.57,6.84,70.06,4.62,5.82-1.37,32.94-7.99,53-22,8.34-5.83,16-13,16-13,3.58-3.4,8.54-8.43,14-15,1.1-1.33,2.42-2.92,4-5,0,0,10.13-13.37,18-34,1.18-3.09,2-6,2-6,.36-1.28,.64-2.4,.83-3.24h0c.07-.29,.12-.54,.17-.76-4.3,1.53-9.27,2.97-14.65,3.86-1.94,.33-3.93,.58-5.96,.73-2.94,.24-5.97,.27-9.06,.06-.01-.01-.02-.01-.03,0-2.13-.16-4.28-.43-6.44-.85-2.28-.43-4.57-1.03-6.86-1.8-14.96-5.04-15.65-12.89-30-17-12.83-3.68-25.02-1.06-41.28,4.13,0,0-.02,0-.02,.01-3.68,1.17-7.56,2.47-11.7,3.86-4.63,1.56-8.48,3.04-11.91,4.39-.04,.02-.08,.03-.11,.04-.96,.38-1.88,.74-2.77,1.09-.03,0-.05,.02-.07,.03-7.92,3.1-14.06,5.29-23.99,5.63Z"
          />
          <path
            style={styles.two}
            d="M417.61,392.52l-1.91-12.43-2.47-45.09c-3.85,2.72-7.15,4.61-9.39,5.81-12.59,6.74-20.32,6.93-27.28,14.73-1.74,1.94-2.89,3.76-3.56,4.91,1.22,26.18,2.44,52.37,3.66,78.55l47.34-4.91-3.53-22.98-2.86-18.59Z"
          />
          <path
            style={styles.two}
            d="M463.94,527.14c-4.68-1.74-8.86-3.55-12.51-5.28,1.79,1.34,3.77,2.77,5.95,4.24,7.56,5.11,17.48,10.83,29.93,15.89,17.08,6.94,32.72,10.13,43.89,11.68,11.15,1.55,31.83,2.01,52.19-9.74,27.41-15.82,35.71-44.56,37.61-51.93-6.04,6.25-13.93,13.41-23.81,20.27-7.03,4.88-15.08,9.61-24.19,13.73-7.77,3.52-15.19,6.1-22,8-12.89,2.59-32.74,4.94-56.39,1.2-11.77-1.86-22.07-4.85-30.67-8.06Z"
          />
          <path
            style={styles.two}
            d="M460.36,524.71c-18.7-12.85-29.27-21.96-35.27-27.7-11.14-7.52-21.05-14.87-28.78-21.87-2.37-2.14-4.35-4.08-6.01-5.83-.03,.43-.06,.84-.09,1.23-.21,2.4-.52,4.07-.73,5.12-.09,.41-.2,.89-.42,1.47-.52,1.45-1.3,2.55-2.03,3.37-.19,.34-.37,.85-.29,1.56,.2,1.74,1.82,3.52,2.42,4.14,7,6.24,14.42,11.05,20.51,14.59,4.67,2.72,7.83,4.26,26.28,13.98,7.1,3.74,14.36,7.57,21.43,11.33,9.71,5.14,19.07,10.12,27.26,14.49-7.15-4.49-14.11-8.99-20.7-13.45-1.01-.68-2.02-1.36-3.01-2.04l-.57-.39Z"
          />
        </g>
      </g>
      <path
        style={styles.one}
        d="M380.82,449.08c29.96-3.3,54.7-2.16,73.18-.08,22.92,2.58,23.95,5.24,39,5,33-.52,43.42-13.53,71-12,9.06,.5,23.04,2.75,40.02,12,4.14,1.39,12.51,3.58,22.98,2,10.22-1.55,17.45-5.94,21-8.45"
      />
      <path
        style={styles.one}
        d="M383,350c.08,10.62,.39,21.63,1,33,1.04,19.33,2.81,37.6,5.06,54.71"
      />
      <path
        style={styles.one}
        d="M406.6,339.26c-.29,11.9-.21,24.5,.4,37.74,.97,21.09,3.11,40.58,5.88,58.24"
      />
      <path
        style={styles.one}
        d="M419.39,404.12c29.42,8.01,50.51,6.22,64.61,2.88,9.53-2.26,34.87-9.74,34.87-9.74h0c7.05-2.23,19.2-5.05,34.13-3.26,21.38,2.56,35.94,13.05,42,18,5.32,2.49,16.88,7.02,32,6,16-1.08,27.32-7.79,32.19-11.09"
      />
      <path
        style={styles.one}
        d="M406.44,483.59c23.64,6.01,43.81,8.41,58.56,9.41,24.78,1.69,37.4,2.54,54-2,19.62-5.37,23.45-12.44,40-15,18.45-2.85,34.58,2.7,45.02,7.59,3.16,.53,8.19,.93,13.98-.59,11.22-2.95,17.42-11.02,19.3-13.69"
      />
      <path
        style={styles.one}
        d="M480.87,539.21c49.25,13.69,77.38,5.92,94.13-4.21,15.89-9.62,43.85-34.88,44-35h0"
      />
      <path
        style={styles.one}
        d="M424,434c26.17,2.82,45.13-.64,57-4,3.51-.99,11.45-3.4,21-7,21.23-8,22.81-11.46,35-14,6.43-1.34,17.93-3.73,31,0,0,0,1.75,.5,19,7h0c5.11,1.85,12.71,5.13,21,11,2.09,1.48,3.3,2.47,4,3,5.57,4.19,16.64,7.53,41,3"
      />
      <path
        style={styles.one}
        d="M453.12,519.64c37.5,2.88,67.15-.64,87.88-4.64,32.76-6.32,76.84-20.32,78-20.64h0"
      />
      <path
        style={styles.two}
        d="M416.89,393.17c-.06-.29,.14-.57,.43-.61,11.12-1.46,20.2-5.95,51.75-4.78,20.77,.77,27.2-.08,30.93-.78,1.57-.29,7.36-1.43,15.54-2.83,2.97-.51,6.74-1.13,9.35-1.56,4.08-.67,7.47-1.23,9.85-1.62,0,0,6.27-.9,15.73-.97,.02,0,.05,0,.07,0,.05,0,.08,.02,.09,.02,.12,.04,10.31,1.81,18.37,4.96,7.24,2.83,13.95,7.24,15,8,0,0,1.65,1.18,4.23,2.53,.01,0,.02,.01,.02,.01,0,0,.01,0,.02,0,11.13,4.55,32.37,11.18,57.14,5.81,5.84-1.27,11.08-3.03,15.67-4.96,.39-.16,.79,.18,.69,.59-2.99,12.66-5.99,25.32-8.98,37.97-.05,.21-.27,.25-.44,.39-3.79,3.21-7.62,2.71-16.35,3.66-6,.65-6.56,1.14-10,1-1.47-.06-3.41-.31-7.3-.82-3.97-.52-5.95-.78-7.7-1.18-1.89-.43-4.73-1.09-8.16-2.7-1.18-.55-2.95-1.36-4.99-2.84-2.74-1.99-3.58-3.62-9.6-6.86-8.5-4.57-15.14-5.99-21.25-5.6h0c-3.01,.04-6.97,.22-11.58,.76-1.26,.15-4.14,.5-8.05,1.23-9.08,1.69-16.73,4.08-21.14,5.56-10.29,3.46-8.62,3.9-19.23,7.46-3.8,1.28-10.66,3.45-19.74,4.15,0,0-.11,.03-.21,.02-8.53-1.19-17.79-2.2-27.7-2.89-12.1-.85-23.37-1.1-33.63-.98-.25,0-.45-.17-.5-.41-2.77-13.91-5.55-27.82-8.32-41.72Z"
      />
      <path
        style={styles.two}
        d="M376.75,439.18c4.08,9.94,8.16,19.88,12.25,29.82,3.67,4.67,7.33,9.33,11,14,10.3,11.27,34.67,34.83,73.94,46.38,29.9,8.79,55.58,6.84,70.06,4.62,5.82-1.37,32.94-7.99,53-22,8.34-5.83,16-13,16-13,3.58-3.4,8.54-8.43,14-15,1.1-1.33,2.42-2.92,4-5,0,0,10.13-13.37,18-34,1.18-3.09,2-6,2-6,.48-1.71,.81-3.12,1-4-11.26,4-27.01,7.39-43,2-14.96-5.04-15.65-12.89-30-17-15.73-4.51-30.51,.45-53,8-17.36,5.83-23.67,10.66-38.85,11.18-7.51,.26-7.87-.86-22.15-2.18-16.85-1.56-29.33-1.2-45.79-.73-11.62,.33-25.98,1.13-42.46,2.91Z"
      />
      <path
        style={styles.two}
        d="M413.23,335c-3.85,2.72-7.15,4.61-9.39,5.81-12.59,6.74-20.32,6.93-27.28,14.73-1.74,1.94-2.89,3.76-3.56,4.91,1.22,26.18,2.44,52.37,3.66,78.55l47.34-4.91-8.3-54-2.47-45.09Z"
      />
      <path
        style={styles.two}
        d="M451.43,521.86c7.99,5.99,19.85,13.61,35.89,20.13,17.08,6.94,32.71,10.12,43.89,11.68,11.15,1.55,31.83,2.01,52.18-9.74,27.41-15.83,35.72-44.56,37.61-51.93-10.34,10.69-26.08,24.07-48,34-7.77,3.52-15.19,6.1-22,8-12.89,2.59-32.74,4.94-56.39,1.2-18.17-2.88-32.81-8.43-43.18-13.34Z"
      />
      <path
        style={styles.two}
        d="M390.3,469.31c-.2,3.05-.57,5.13-.83,6.34-.09,.42-.2,.89-.41,1.48-.52,1.45-1.3,2.55-2.04,3.36-.19,.34-.36,.85-.28,1.56,.2,1.74,1.82,3.52,2.42,4.15,7,6.24,14.41,11.05,20.5,14.59,4.67,2.72,7.83,4.25,26.28,13.98,16.84,8.87,34.53,18.27,48.69,25.82-8.25-5.18-16.23-10.36-23.71-15.49-19.06-13.06-29.78-22.29-35.84-28.09-11.14-7.53-21.05-14.87-28.78-21.87-2.37-2.14-4.34-4.08-6.01-5.83Z"
      />
    </g>
  );
};

export default WrapItUpThree;
