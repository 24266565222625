import { Grid, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  AiFillHome,
  AiFillShopping,
  AiFillTrophy,
  AiOutlineHome,
  AiOutlineShopping,
  AiOutlineTrophy,
} from "react-icons/ai";
import { IoGameController, IoGameControllerOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { StoreContext } from "../../../store/Store";
import {
  container,
  doubtedBlue,
  secondaryText,
  text,
} from "../../../utils/themeContstants";
import SelectGameModal from "../../custom/SelectGameModal";
import NavbarNavButtons from "./NavbarNavButtons";

const NavbarNavSection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const store = useContext(StoreContext);
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const isTablet = useMediaQuery("(max-width: 768px)");

  const [selected, setSelected] = useState("home");
  const [gamesOpen, setGamesOpen] = useState(false);

  useEffect(() => {
    const path = location?.pathname;

    if (gamesOpen) {
      return setSelected("games");
    }

    if (path === "/") {
      return setSelected("home");
    }

    if (path === "/leaderboard") {
      return setSelected("leaderboard");
    }

    if (path?.split("/")[1] === "shop") {
      return setSelected("shop");
    }

    return setSelected(null);
  }, [location?.pathname, gamesOpen]);

  const styles = {
    selectedIcon: {
      fontSize: 28,
      color: doubtedBlue,
    },
    icon: {
      fontSize: 28,
      color: secondaryText,
    },
    balanceContainer: {
      height: 42,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: container,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 1,
    },
    balance: {
      fontSize: 13,
      fontWeight: 700,
      color: text,
    },
  };

  return (
    <>
      <SelectGameModal
        title="Browse Games"
        // open={gamesOpen}
        onClose={(selectedGame) => {
          setGamesOpen(false);

          if (selectedGame) {
            navigate(`/${selectedGame}/matches`);
          }
        }}
      />

      <Grid
        item
        sx={{
          flex: isTablet ? 0.5 : 0.33,
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          gap={{ xs: 1 }}
     
        >
          <NavbarNavButtons
            label="Home"
            icon={
              selected === "home" ? (
                <AiFillHome style={styles.selectedIcon} />
              ) : (
                <AiOutlineHome style={styles.icon} />
              )
            }
            selected={selected === "home"}
            onClick={() => {
              setSelected("home");
              navigate("/");
              setGamesOpen(false);
            }}
          />

          <NavbarNavButtons
            label="Matches"
            icon={
              selected === "games" ? (
                <IoGameController style={styles.selectedIcon} />
              ) : (
                <IoGameControllerOutline style={styles.icon} />
              )
            }
            selected={selected === "games"}
            onClick={() => {
              navigate("/wagers/matches")
              // setSelected("games");
              setGamesOpen(true);
            }}
          />

          {/* {store?.user && (
            <NavbarNavButtons
              label="Wallet"
              icon={
                selected === "wallet" ? (
                  <MdAccountBalanceWallet style={styles.selectedIcon} />
                ) : (
                  <MdOutlineAccountBalanceWallet style={styles.icon} />
                )
              }
              selected={selected === "wallet"}
              onClick={(e) => {
                handleWalletOpen(e);
                setSelected("wallet");
              }}
              anchorId="wallet-anchor"
            />
          )} */}

          <NavbarNavButtons
            label="Leaderboard"
            icon={
              selected === "leaderboard" ? (
                <AiFillTrophy style={styles.selectedIcon} />
              ) : (
                <AiOutlineTrophy style={styles.icon} />
              )
            }
            selected={selected === "leaderboard"}
            onClick={() => {
              setSelected("leaderboard");
              navigate("/leaderboard");
              setGamesOpen(false);
            }}
          />

          <NavbarNavButtons
            label="Shop"
            icon={
              selected === "shop" ? (
                <AiFillShopping style={styles.selectedIcon} />
              ) : (
                <AiOutlineShopping style={styles.icon} />
              )
            }
            selected={selected === "shop"}
            onClick={() => {
              setSelected("shop");
              navigate("/shop/avatars");
              setGamesOpen(false);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NavbarNavSection;
