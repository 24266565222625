import { Grid } from "@mui/material";
import { useContext } from "react";
import { IoMdNotifications } from "react-icons/io";
import { StoreContext } from "../../store/Store";
import { NotificationTypeEnum } from "../../utils/enums";
import {
  cardVeryLight,
  secondaryButton,
  secondaryText,
} from "../../utils/themeContstants";
import { GiPartyPopper } from "react-icons/gi";
import Piece from "../../avatar/Piece";
import Avatar from "../../avatar/Avatar";
import godlikeCrate from "../../assets/godlike-crate.svg";
import snipes from "../../assets/3d_icons/snipes.png"
import rareCrate from "../../assets/rare-crate.svg";
import emojiCrate from "../../assets/emoji-crate.svg";
import exoticCrate from "../../assets/exotic-crate.svg";
import battlepass from "../../assets/bp-icon.svg"
import coin from "../../assets/coin.svg"

const NotificationAvatar = ({ noti }) => {
  const store = useContext(StoreContext);
  const type = noti?.notification_type;

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      borderRadius: 100,
      height: 55,
      width: 55,
      backgroundColor: secondaryButton,
    },
    icon: {
      fontSize: 30,
      color: secondaryText,
    },
    avatarContainer: {
      marginLeft: -1,
      marginRight: -1.5,
    },
  };

  return (
    <>
      {noti?.sender_avatar &&
        type !== NotificationTypeEnum.TOURNAMENT_EARNED &&
        type !== NotificationTypeEnum.TOURNAMENT_START &&
        type !== NotificationTypeEnum.TOURNAMENT_WIN &&
        type !== NotificationTypeEnum.KICKED && (
          <Grid item sx={styles.avatarContainer}>
            <Avatar
              size={75}
              avatar={noti?.sender_avatar[0]}
              bgColor={cardVeryLight}
            />
          </Grid>
        )}

      {noti?.sender_avatar == null &&
        type === NotificationTypeEnum.NEW_MATCH && (
          <Grid item sx={styles.container}>
            <IoMdNotifications style={styles.icon} />
          </Grid>
        )}

      {noti?.sender_avatar == null &&
        (type === NotificationTypeEnum.EARNED ||
          type === NotificationTypeEnum.GIVEAWAY) && (
          <Grid item sx={styles.systemContainer}>
            <GiPartyPopper style={styles.icon} />
          </Grid>
        )}

      {type === NotificationTypeEnum.RARE_ITEMS_CRATE && (
        <Grid item sx={styles.systemContainer}>
          <img
            src={godlikeCrate}
            width={75}
            draggable={false}
            alt="godlike-crate"
          />
        </Grid>
      )}

      {type === NotificationTypeEnum.RARE_CRATE && (
        <Grid item sx={styles.systemContainer}>
          <img
            src={rareCrate}
            width={75}
            draggable={false}
            alt="godlike-crate"
          />
        </Grid>
      )}


      {type === NotificationTypeEnum.EMOJI_CRATE && (
        <Grid item sx={styles.systemContainer}>
          <img
            src={emojiCrate}
            width={75}
            draggable={false}
            alt="godlike-crate"
          />
        </Grid>
      )}



      {type === NotificationTypeEnum.EXOTIC_CRATE && (
        <Grid item sx={styles.systemContainer}>
          <img
            src={exoticCrate}
            width={75}
            draggable={false}
            alt="godlike-crate"
          />
        </Grid>
      )}



      {type === NotificationTypeEnum.SNIPES && (
        <Grid item sx={styles.systemContainer}>
          <img
            src={snipes}
            width={75}
            draggable={false}
            alt="godlike-crate"
          />
        </Grid>
      )}


      {type === NotificationTypeEnum.BATTLE_PASS && (
        <Grid item sx={styles.systemContainer}>
          <img
            src={battlepass}
            width={75}
            draggable={false}
            alt="godlike-crate"
          />
        </Grid>
      )}


      {type === NotificationTypeEnum.BP_MONEY && (
        <Grid item sx={styles.systemContainer}>
          <img
            src={coin}
            width={75}
            draggable={false}
            alt="godlike-crate"
          />
        </Grid>
      )}


      {noti?.sender_avatar == null && type === NotificationTypeEnum.AVATAR && (
        <Grid item sx={styles.avatarContainer}>
          <Piece
            type={noti?.sender}
            color={store?.user?.new_avatar?.options[`${noti?.sender}Color`]}
            option={noti?.attached}
            size={75}
            skinColor={store?.user?.new_avatar?.options?.skinColor}
          />
        </Grid>
      )}
    </>
  );
};

export default NotificationAvatar;
