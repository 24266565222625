import { Grid, Typography } from "@mui/material";
import {
  large,
  normal,
  offWhite,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import CustomMenu from "../custom/CustomMenu";

const ChatRulesMenu = ({ anchor, handleClose }) => {
  const styles = {
    message: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
    },
    width: {
      width: "100%",
    },
    title: {
      fontSize: large,
      fontWeight: 700,
      color: text,
    },
  };

  return (
    <CustomMenu
      anchor={anchor}
      handleClose={handleClose}
      verticalOrigin="center"
    >
      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.title}>Chat Rules</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.message}>
              <span style={{ fontWeight: 800 }}>•</span> Do not harass or insult
              the opposing team
            </Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.message}>
              <span style={{ fontWeight: 800 }}>•</span> Respect mods, admins,
              and other staff members
            </Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.message}>
              <span style={{ fontWeight: 800 }}>•</span> Do not post links,
              doing so can result in a ban unless it is to a staff member
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </CustomMenu>
  );
};

export default ChatRulesMenu;
