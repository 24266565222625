import { CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { getNotes } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, StoreDispatch } from "../../store/Store";
import { getReadableDateString, getTime } from "../../utils/helpers";
import { TRANSACTION_LIMIT } from "../../utils/limits";
import {
  card,
  cardLight,
  modalShadow,
  normal,
  secondaryText,
  small,
  text,
} from "../../utils/themeContstants";
import usePagination from "../../utils/usePagination";
import NotSelected from "../custom/NotSelected";
import LogGlimmer from "../glimmers/LogGlimmer";

const UserNotes = ({ user }) => {
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [skip, setSkip] = useState(0);

  const handleGetNotes = () => {
    getNotes(middleware, user?._id, skip).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        if (res?.notes?.length >= TRANSACTION_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setNotes(notes ? [...notes, ...res?.notes] : res?.notes);
      }
    });
  };

  usePagination(bottom, () => setSkip(notes?.length));

  useEffect(() => {
    if (notes == null) {
      handleGetNotes();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetNotes();
    }
  }, [skip]);

  const styles = {
    width: {
      width: "100%",
    },
    label: {
      fontSize: small,
      fontWeight: 500,
      color: secondaryText,
    },
  };

  return (
    <>
      {loading && (
        <Grid
          item
          alignSelf="center"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (!notes || notes?.length < 1) && (
        <Grid item alignSelf="center">
          <NotSelected label="NO NOTES" />
        </Grid>
      )}

      {!loading && notes && notes?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 0.5 }}
          >
            {notes?.map((note, i) => (
              <Grid
                item
                sx={{
                  borderRadius: 1,
                  padding: 1,
                  width: "100%",
                  backgroundColor: card,
                  boxShadow: modalShadow,
                }}
                key={i}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  sx={{
                    width: "100%",
                    padding: 1,
                    borderRadius: 1,
                    backgroundColor: cardLight,
                  }}
                >
                  <Grid item>
                    <Typography sx={styles.label}>
                      {getReadableDateString(new Date(note?.timestamp)) +
                        " at " +
                        getTime(new Date(note?.timestamp))}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: normal,
                            color: text,
                            fontWeight: 800,
                          }}
                        >
                          {note?.attached}:
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: normal,
                            color: text,
                            fontWeight: 500,
                          }}
                        >
                          {note?.log}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {hasMore && (
              <div style={{ width: "100%" }} ref={bottom}>
                <LogGlimmer numItems={1} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserNotes;
