import { Divider, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import useMiddleware from "../../api/useMiddleware";
import { setUsername } from "../../api/user";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  SET_USER,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { getReadableDateString, getReadableTime } from "../../utils/helpers";
import {
  cardLight,
  cardVeryLight,
  doubtedBlue,
  doubtedOrange,
  normal,
  offWhite,
  secondaryText,
  small,
  text,
  yellow,
} from "../../utils/themeContstants";
import CustomCheckbox from "../custom/CustomCheckbox";
import CustomInput from "../custom/CustomInput";
import CustomModal from "../custom/CustomModal";
import PrimaryButton from "../custom/PrimaryButton";
import SecondaryButton from "../custom/SecondaryButton";

const EditUsernameModal = ({ open, onClose }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [canChange, setCanChange] = useState(null);
  const [newUsername, setNewUsername] = useState(store?.user?.username);
  const [currentUsername, setCurrentUsername] = useState(store?.user?.username);
  const [understands, setUnderstands] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setNewUsername(store?.user?.username);
    setUnderstands(false);
    setLoading(false);
    setCurrentUsername(store?.user?.username);
    setCanChange(null);
    onClose();
  };

  const handleUsernameChange = () => {
    setLoading(true);
    setUsername(middleware, newUsername).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        const name_change_timestamp = res?.name_change_timestamp;
        let newUser = store.user;
        newUser.info.name_change_timestamp = name_change_timestamp;
        newUser.username = newUsername;
        setCanChange(false);
        updateStore({ type: SET_USER, payload: newUser });
        updateStore({
          type: SET_SUCCESSES,
          payload: "Username changed!",
        });
        setCurrentUsername(newUsername);
        setUnderstands(false);
      }
    });
  };

  useEffect(() => {
    if (canChange == null) {
      if (store.user.info?.name_change_timestamp) {
        const today = new Date();
        const nameChangeDate = new Date(store.user.info?.name_change_timestamp);
        if (today < nameChangeDate) {
          setCanChange(false);
        } else {
          setCanChange(true);
        }
      } else {
        setCanChange(true);
      }
    }
  }, [store?.user, canChange]);

  const styles = {
    width: {
      width: "100%",
    },
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    warning: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
      maxWidth: 400,
    },
    value: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
    label: {
      fontSize: normal,
      fontWeight: 800,
      color: offWhite,
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="Change Username"
      description="Customize the name that you go by"
      titleSize="large"
      primaryButton={
        <SecondaryButton
          label="Save"
          loading={loading}
          disabled={
            !canChange || !understands || newUsername === currentUsername
          }
          onClick={handleUsernameChange}
          bg={doubtedBlue}
        />
      }
      secondaryButton={<SecondaryButton label="Close" onClick={handleClose} />}
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent="start"
            alignItems="start"
            gap={{ xs: 1 }}
          >
            <Grid item>
              <RiErrorWarningFill
                style={{ color: doubtedOrange, fontSize: 22 }}
              />
            </Grid>
            <Grid item>
              {canChange ? (
                <Typography sx={styles.warning}>
                  Once you change your username, you will not be able to change
                  it again for{" "}
                  <span style={{ color: doubtedOrange, fontWeight: 700 }}>
                    {store?.user?.premium_info?.active ? "3 days" : "30 days"}
                  </span>
                  .
                </Typography>
              ) : (
                <Typography sx={styles.warning}>
                  You cannot change your username again until{" "}
                  <span style={{ color: yellow }}>
                    {getReadableDateString(
                      new Date(store?.user?.info?.name_change_timestamp)
                    )}
                    {" at "}
                    {getReadableTime(
                      new Date(store?.user?.info?.name_change_timestamp)
                    )}
                  </span>
                  .
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <Divider sx={{ width: "100%", backgroundColor: cardVeryLight }} />
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            <Grid item>
              <Typography sx={styles.label}>New username</Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <CustomInput
                onChange={setNewUsername}
                value={newUsername}
                disabled={!canChange}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            gap={{ xs: 2 }}
          >
            <Grid item>
              <CustomCheckbox
                checked={understands}
                onChange={(value) => setUnderstands(value)}
                color={doubtedOrange}
                disabled={!canChange}
              />
            </Grid>

            <Grid item>
              <Typography
                sx={{
                  fontSize: normal,
                  color: text,
                  fontWeight: 400,
                  maxWidth: 400,
                }}
              >
                I understand that I cannot change my username for another{" "}
                {store?.user?.premium_info?.active ? "3 days" : "30 days"} once
                I save.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default EditUsernameModal;
