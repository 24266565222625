import { Divider, Grid } from "@mui/material";
import { useContext, useState } from "react";
import { FaDiscord, FaUsers, FaUserSecret } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { RiSwordFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../../store/Store";
import { isWithinOneMinute } from "../../../utils/helpers";
import {
  card,
  cardExtraLight,
  cardVeryLight,
  lightGrey,
} from "../../../utils/themeContstants";
import DownloadACModal from "../../anti_cheat/DownloadACModal";
import NewCustomMenu from "../../custom/NewCustomMenu";
import NewListItem from "../../custom/NewListItem";
import SelectGameModal from "../../custom/SelectGameModal";
import CreateMatch from "../../matches/CreateMatch";
import UpdateTOSModal from "../../support/UpdateTOSModal";

const CreateMenu = ({ anchor, handleClose }) => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const [gameOpen, setGameOpen] = useState(false);
  const [game, setGame] = useState(null);
  const [createOpen, setCreateOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [tosOpen, setTosOpen] = useState(false);

  const onClose = () => {
    handleClose();
  };

  const styles = {
    width: {
      width: "100%",
    },
    icon: {
      fontSize: 20,
      color: lightGrey,
    },
  };

  return (
    <>
      <SelectGameModal
        title="Create Match"
        open={gameOpen}
        onClose={(selectedGame) => {
          setGameOpen(false);

          if (selectedGame) {
            setGame(selectedGame);

            // if (
            //   store?.user?.info?.last_agreed_tos_version !== store?.tosVersion
            // ) {
            //   return setTosOpen(true);
            // }

            if (
              game === "fortnite" &&
              store?.user?.anticheat?.must_run_ac &&
              !isWithinOneMinute(
                store?.user?.anticheat?.last_heartbeat_timestamp
              )
            ) {
              setDownloadOpen(true);
              return;
            }

            setCreateOpen(true);
          }
        }}
      />
      <CreateMatch
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        game={game}
      />
      <UpdateTOSModal open={tosOpen} onClose={() => setTosOpen(false)} />
      {/* <DownloadACModal
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
      /> */}

      <NewCustomMenu
        title="Create"
        anchor={anchor}
        handleClose={onClose}
        verticalOrigin="bottom"
        horizontalOrigin="right"
        backgroundColor={card}
      >
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
            >
              <NewListItem
                title="Match"
                icon={<RiSwordFill style={styles.icon} />}
                onClick={() => {
                  if (!store?.user) {
                    navigate("/login");
                    handleClose();
                    return;
                  }

                  setGameOpen(true);
                  handleClose();
                }}
                minWidth={300}
                backgroundHover={cardVeryLight}
                description="Start earning in head-to-head matches in your favorite games."
              />
              <NewListItem
                title="Team"
                icon={<FaUsers style={styles.icon} />}
                onClick={() => {
                  if (!store?.user) {
                    navigate("/login");
                    handleClose();
                    return;
                  }

                  navigate("/profile/teams", {
                    state: {
                      create: true,
                    },
                  });
                  handleClose();
                }}
                minWidth={300}
                backgroundHover={cardVeryLight}
                description="Create a team with your friends to start competing."
              />
              <NewListItem
                title="Avatar"
                icon={<FaUserSecret style={styles.icon} />}
                onClick={() => {
                  if (!store?.user) {
                    navigate("/login");
                    handleClose();
                    return;
                  }

                  navigate("/locker");
                  handleClose();
                }}
                minWidth={300}
                backgroundHover={cardVeryLight}
                description="Customize your look by creating your avatar the way you want it to look."
              />
            </Grid>
          </Grid>

          <Grid item sx={{ width: "100%", paddingLeft: 1, paddingRight: 1 }}>
            <Divider
              sx={{
                width: "100%",
                backgroundColor: cardExtraLight,
              }}
            />
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
            >
              <NewListItem
                title="Ticket"
                icon={<FaDiscord style={styles.icon} />}
                rightAddOn={
                  <FiExternalLink style={{ fontSize: 20, color: lightGrey }} />
                }
                onClick={() =>
                  window.open("https://discord.gg/rivox", "_blank")
                }
                minWidth={300}
                backgroundHover={cardVeryLight}
                description="Create a support ticket in our Discord to get help from our staff."
              />
            </Grid>
          </Grid>
        </Grid>
      </NewCustomMenu>
    </>
  );
};

export default CreateMenu;
