const Smile = () => {
  return (
    <g>
      <path d="M572.1,360.06c.38-.51,.73-1.06,1.15-1.51,6.34-6.7,13.64-9.5,22.03-8.27,6.39,.94,11.91,3.99,16.53,9.43,1.08,1.27,1.98,2.79,2.82,4.3,.4,.71,.69,1.74-.12,2.48-.74,.66-1.54,.81-2.28-.02-.5-.56-.94-1.19-1.39-1.81-4.49-6.37-10.12-10.16-17.01-10.94-4.67-.53-9.21,.36-13.58,2.37-2.11,.97-4.12,2.28-6.18,3.44-.58,.32-1.16,.63-1.74,.95-.08-.14-.15-.28-.23-.42Z" />
      <path d="M485.74,367.44c-1.54-.96-3.06-1.98-4.63-2.87-4.44-2.52-9.11-4.07-14.01-4.09-7.94-.03-14.51,3.78-19.7,11.11-.39,.56-.77,1.14-1.21,1.64-.77,.88-1.66,.96-2.47,.17-.85-.83-.46-1.82,0-2.71,1.47-2.86,3.39-5.2,5.65-7.1,8.12-6.87,16.94-8.38,26.33-4.84,3.67,1.39,6.77,4.03,9.64,7.08,.3,.32,.49,.81,.73,1.22-.11,.13-.22,.25-.33,.38Z" />
      <path d="M633.41,376.19c-.66-.1-1.33-.13-1.97-.32-.73-.22-1.44-.54-2.15-.87-4.56-2.13-9.12-4.27-13.78-6.45,2.53-.89,14.34,3.95,17.9,7.64Z" />
      <path d="M442.74,375.47c-5.04,2.36-10.06,4.76-15.12,7.05-.94,.43-2.01,.47-3.4,.77,3.16-3.19,14.11-7.99,18.51-7.83Z" />
      <path d="M423,363.12c6.22,.82,11.68,4.05,17.73,6.09-1.04,1.42-1.95,.8-2.69,.53-3.11-1.13-6.19-2.37-9.27-3.6-1.22-.49-2.43-1-3.62-1.6-.75-.38-1.43-.94-2.15-1.42Z" />
      <path d="M617.82,362.58c5.62-2.35,11-5.45,17.18-6.89-1.91,2.53-14.4,7.61-17.18,6.89Z" />
      <path d="M440.52,372.98c-2.3,.82-4.52,.14-6.74-.58,2.32-1.14,4.5,.36,6.74,.58Z" />
      <path d="M624.39,365.37c-2.15,.77-4.28,1.37-6.48,.79,2.14-.7,4.27-1.53,6.48-.79Z" />
    </g>
  );
};

export default Smile;
