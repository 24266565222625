const Choker = () => {
  const styles = {
    one: {
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#343434",
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#5f5f5f",
    },
  };

  return (
    <g transform="translate(451, 545)">
      <g>
        <path
          style={styles.one}
          d="M30.62,33.9c-.36-1.88-.62-3.27-.87-4.59-10.5-1.4-20.87-2.47-29.24-9.78C.27,13.3,4.02,7.84,4.05,1.2c.69,.69,1.16,1,1.4,1.42,2.13,3.72,5.54,5.95,9.25,7.69,7.7,3.6,15.9,5.16,24.37,5.39,5.78,.15,11.5-.19,17.05-1.94,1.22-.38,2.4-.85,3.59-1.31,.95-.36,1.84-.49,2.63,.28-.5,4.08-1.14,8.07-1.39,12.08-.09,1.5-.72,1.9-1.84,2.29-3.95,1.38-8.03,2.12-12.19,2.26-4.05,.13-8.11,.03-12.38,.03v4.01c.18,.12,.29,.25,.43,.28,3.3,.67,4.36,2.82,2.74,5.75-.45,.82-1.19,1.48-1.78,2.23-.87,1.12-1.79,2.22-2.58,3.4-.95,1.42-1.6,1.59-2.81,.38-1.35-1.36-2.66-2.77-3.88-4.24-1.31-1.56-1.32-3.41-.61-5.2,.74-1.88,2.28-2.56,4.56-2.1ZM2.03,18.4c1.33,.69,2.35,1.31,3.44,1.78,6.2,2.68,12.75,4.09,19.35,5.31,8.91,1.64,17.87,2.36,26.91,1.22,2.6-.33,5.19-.73,7.37-1.85,.59-3.49,1.13-6.73,1.7-10.09-1.86,.71-3.48,1.39-5.13,1.93-7.57,2.5-15.35,2.8-23.2,2.02-4.46-.44-8.82-1.4-13.03-2.96-5.68-2.1-10.97-4.77-14.73-10.36-.93,4.52-1.77,8.62-2.66,12.98Zm29.69,25.79c1.42-1.56,2.74-2.93,3.96-4.39,.49-.59,.83-1.34,1.07-2.07,.24-.76,.35-1.76-.53-2.11-.74-.3-1.68-.28-2.49-.13-.55,.1-1,.69-1.5,1.06-.5-.32-.87-.62-1.3-.82-1.05-.48-2.15-.76-3.12,.11-.8,.72-.87,2.42-.23,3.57,.23,.41,.5,.82,.82,1.17,1.06,1.18,2.15,2.34,3.32,3.61Zm-.35-14.65c.3,2.08,.56,3.87,.91,6.24,.54-.94,1.02-1.38,1-1.8-.07-1.46-.3-2.91-.48-4.45h-1.43Z"
        />
        <path
          style={styles.two}
          d="M2.03,18.4c.89-4.36,1.73-8.46,2.66-12.98,3.76,5.58,9.05,8.26,14.73,10.36,4.21,1.56,8.58,2.52,13.03,2.96,7.85,.78,15.63,.48,23.2-2.02,1.66-.55,3.27-1.23,5.13-1.93-.56,3.36-1.11,6.6-1.7,10.09-2.18,1.12-4.78,1.52-7.37,1.85-9.03,1.14-18,.42-26.91-1.22-6.6-1.22-13.15-2.63-19.35-5.31-1.09-.47-2.11-1.09-3.44-1.78Z"
        />
        <path
          style={styles.three}
          d="M31.72,44.18c-1.17-1.27-2.26-2.43-3.32-3.61-.32-.35-.59-.76-.82-1.17-.64-1.15-.57-2.85,.23-3.57,.97-.87,2.07-.59,3.12-.11,.43,.2,.8,.5,1.3,.82,.5-.37,.95-.95,1.5-1.06,.8-.15,1.75-.16,2.49,.13,.88,.35,.77,1.35,.53,2.11-.23,.73-.58,1.49-1.07,2.07-1.22,1.46-2.54,2.83-3.96,4.39Z"
        />
        <path
          style={styles.one}
          d="M31.36,29.54h1.43c.18,1.54,.42,2.99,.48,4.45,.02,.42-.46,.86-1,1.8-.35-2.38-.61-4.17-.91-6.24Z"
        />
      </g>
    </g>
  );
};

export default Choker;
