const Obito = () => {
  const styles = {
    one: {
      fill: "#ba1a00",
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#d96b23",
    },
    five: {
      fill: "#732b05",
    },
  };

  return (
    <g>
      <path
        style={styles.four}
        d="m524.44,567.08c3.69.03,10.24-.1,18.13-1.28,64.8-9.71,98.14-72.42,104.66-84.69,7.13-13.41,10.26-23.63,14.32-36.89,5.12-16.7,13.88-46.28,10.89-84.46-2.85-36.39-14.76-60.06-18.62-67.26-8.94-16.72-18.33-26.78-29.26-34.6-8.28-5.94-16.35-9.81-22.17-12.23-15.84-6-44.89-14.96-82.5-15-15.84-.02-44.6-.05-73.52,11.8-71.81,29.42-78.34,104.93-78.94,113.8-1.58,23.31,4.11,42.28,8.65,53.83-.7,7.97-.45,18.65,3.3,30.76,1.93,6.25,4.44,11.72,7,16.34-.71,5.27-1.06,14.61,3.71,25.31,6.12,13.73,17.25,22.07,22.66,25.63.57,3.36,1.98,8.28,5.77,13.46,7.41,10.12,19.01,14.54,23.49,16.02,4.14,4.13,11.29,10.16,22.25,15.06,7.27,3.25,14.09,5.05,19.37,6.09,3.89,2.23,10.72,5.55,20.19,7.37,8.62,1.65,15.97,1.4,20.6.96Z"
      />
      <path
        style={styles.five}
        d="m386.81,458.3c.28,3,2.69,24.44,23.9,34.28,26.89,12.48,59.63-5.02,66.14-8.49,32.73-17.49,37.34-47.66,38.07-53.34-3.5,41.53-30.67,73.4-65.6,79.58-7.43,1.31-19.97,2.31-36.55-2.2-7.26-5.19-23.65-18.44-26.17-38.13-.54-4.25-.36-8.18.21-11.69Z"
      />
      <path
        style={styles.five}
        d="m559.22,430.75c-2.41,7.43-18.81,55.87-59.78,76.41-7.99,4.01-21.55,10.8-40.38,10.47-13.59-.24-24.12-4.09-29.98-6.72l-16.31-2.79c.57,3.36,2.32,10.12,8.66,16.92,7.3,7.83,16.55,11.27,20.6,12.56,7.17.72,18.67,1.17,32.03-1.56,2.79-.57,12.16-2.6,23.46-7.95,20.66-9.78,31.88-23.13,37.36-29.81,21.54-26.25,24.16-54.34,24.34-67.53Z"
      />
      <path
        style={styles.five}
        d="m604.55,421.48c-2.14,14.77-9.04,42.92-35.35,69.82-10.91,11.16-33.48,34.23-72.78,42.14-9.13,1.84-16.97,2.42-22.36,2.61-10.68.52-21.35,1.04-32.03,1.56,3.72,3.79,10.37,9.6,20.79,14.39,9.05,4.16,17.5,5.98,23.18,6.83,4.04.03,9.88-.19,16.63-1.43,8.04-1.48,31.2-6.94,57.87-38.43,30.13-35.57,40.25-75.45,44.06-97.49Z"
      />
      <path
        style={styles.five}
        d="m640.59,418.28c-7.6,32.03-29.05,99.85-87.03,113.66-2.2.52-4.44.97-6.74,1.33-4.27,4.47-12.52,11.9-25.95,17.68-15.35,6.61-29.68,7.72-37.06,7.89,3.47,1.96,8.21,4.19,14.21,5.89,11.71,3.33,22.15,2.94,28.01,2.34,5.43-1.25,10.55-2.76,15.39-4.48,77.47-27.58,94.79-114.41,99.18-144.31Z"
      />
      <path
        style={styles.five}
        d="m664.97,432.55c-4.76,13.15-13.24,30.64-29,48.77-8.22,9.45-16.8,17.23-24.72,23.5-28.02,20.58-56.04,41.15-84.06,61.73,6.59-.05,16.23-.61,27.22-3.18,6.11-1.43,32.01-8.02,61.08-37.61,33.08-33.68,44.86-71.29,49.5-93.21Z"
      />
      <path
        style={styles.five}
        d="m379.3,414.15c-3.22,25.41,14.94,46.72,35.96,50.68,25.56,4.82,62.45-14.65,65.09-48.26.46,4.88.39,12.52-3.33,21.05-7.23,16.57-28.71,37.57-55.86,35.82-18.49-1.19-30.78-12.37-34.88-16.49-2.78-5.04-5.56-11.22-7.56-18.42-3.13-11.24-3.28-21.08-2.62-28.46,1.07,1.36,2.14,2.71,3.21,4.07Z"
      />
      <path
        style={styles.five}
        d="m372.81,400.73c14.83,22.25,40.89,33.92,62.08,29.96,4.29-.8,13.23-3.15,21.98-11.32-6.19-.17-17.43-1.17-28.84-6.62-22.59-10.78-27.2-30.06-27.92-33.37,4.77,8.68,10.93,14.63,15.25,18.19,4.51,3.72,10.39,8.56,20.26,11.76,11.16,3.63,21.47,3.23,27.27,2.61-1.2,2.86-8.45,19.37-26.34,23.88-21.33,5.37-52.15-7.73-63.72-35.09Z"
      />
      <path
        style={styles.five}
        d="m425.51,403.23c-1.39-1.42-2.72-2.94-3.95-4.58-11.1-14.79-8.5-31.22-5.93-40.26.67,9.08,2.21,19.24,5.17,30.19,1.4,5.18,3,10.07,4.71,14.65Z"
      />
      <path
        style={styles.five}
        d="m424.44,395.5c.17-1.69.77-4.67,3.3-7.53,5.45-6.16,17.22-8.76,26.51-5.66,6.93,2.31,10.16,7.03,11.22,8.78-13.67,1.47-27.35,2.94-41.02,4.4Z"
      />
      <path
        style={styles.five}
        d="m504.14,267.22c6.89-1.78,48.77-12.06,78.26,5.83,3.89,2.36,8.05,5.5,11.94,9.74-13.46-8.71-26.03-12.27-33.61-13.88-9.24-1.95-17.06-2.26-25.29-2.59-12.97-.52-23.76.16-31.3.9Z"
      />
      <path
        style={styles.five}
        d="m503.01,319.84c4.77-1.19,35.8-8.61,53.7,4.2,1.85,1.33,3.69,2.96,5.36,4.98-5.33-2.52-12.52-5.33-21.46-7.32-15.71-3.5-29.34-2.82-37.6-1.86Z"
      />
      <path
        style={styles.five}
        d="m507.64,351.71c4.01.48,19.68,2.73,30.53,13.62,5.88,5.9,7.97,11.95,8.79,15.48-2.39-4.44-7.53-12.3-17.91-19.33-8.02-5.43-16.08-8.27-21.42-9.77Z"
      />
      <ellipse
        style={styles.two}
        cx="446.83"
        cy="400.73"
        rx="21.56"
        ry="16.77"
      />
      <ellipse style={styles.one} cx="446.34" cy="400.73" rx="3.47" ry="2.35" />
      <path
        style={styles.one}
        d="m448.64,404.01v3.93c.95-.36,3.2-1.32,4.88-3.36,1.7-2.07,1.85-4.09,1.85-4.91-.61.78-1.69,1.92-3.43,2.94-1.22.71-2.39,1.14-3.3,1.41Z"
      />
      <path
        style={styles.one}
        d="m443.9,404.01v3.93c-.95-.36-3.2-1.32-4.88-3.36-1.7-2.07-1.85-4.09-1.85-4.91.61.78,1.69,1.92,3.43,2.94,1.22.71,2.39,1.14,3.3,1.41Z"
      />
      <path
        style={styles.one}
        d="m434.49,393.79c.64-.62,4.44-4.19,10.89-4.54,5.5-.29,10.86,1.87,14.01,5.61-1.72.21-3.43.43-5.15.64-.9-1.01-2.28-2.2-4.33-3.04-6.99-2.88-14.96,1.1-15.42,1.34Z"
      />
      <path
        style={styles.one}
        d="m440.53,395.9c.68-.09,1.96-.17,3.4.24.73.21,1.29.48,1.7.72h1.75c.5-.3,1.49-.8,2.88-.96.68-.08,1.28-.05,1.73,0-.17-.21-1.95-2.33-5.34-2.48-2.44-.11-4.79.85-6.13,2.48Z"
      />
      <path
        style={styles.one}
        d="m447.95,411.28l1.85-2.64c1.52,0,4.29-.18,6.8-1.6,4.07-2.3,5.74-6.89,4.12-11.53,3.24,4.14,2.74,9.28-1.03,12.58-4.42,3.86-11.15,3.27-11.74,3.2Z"
      />
      <path
        style={styles.one}
        d="m444.72,411.35c-4.73-.9-8.43-3.52-9.55-6.88-.94-2.81.25-5.2.76-6.08-1.05-.7-2.11-1.39-3.16-2.09-.18.23-3.86,4.94-.69,9.77,2.33,3.54,7.35,5.65,12.64,5.28Z"
      />
      <path
        style={styles.three}
        d="m401.95,273.44c-5.75,8.31-26.67,40.88-13.91,81.21,8.81,27.84,29.32,45.65,39.32,53.29"
      />
      <path
        style={styles.three}
        d="m425.31,399.67c-4.22-8.89-9.32-22.73-9.66-39.89-.3-15.2-.99-45.2,26.78-68.57,32.98-27.75,81.47-25.45,94.71-24.81,11.61.55,35.18,1.68,54.86,14.77,4.3,2.86,7.42,5.63,9.48,7.64,7.07,6.89,11.38,12.71,13.6,16.02,15.63,23.33,21.84,46.94,21.84,46.94,8.8,33.49,9.38,94.74-25.96,153.79-14.56,24.33-28.28,36.71-43.68,45.6-16.04,9.27-32.03,13.68-42.85,15.91"
      />
      <path
        style={styles.three}
        d="m440.53,384.69c1.42-2.82,15.3-29.17,46.39-33.49,7.69-1.07,14.77-.56,20.72.51,3.85,1.06,9.19,2.83,14.87,5.82,29.31,15.42,33.68,45.53,35.74,59.76,5.77,39.73-15.85,71.57-26.4,84.97-4.16,5.82-13.72,17.29-31.87,25.85-25.04,11.8-49.92,10.27-57.96,9.51"
      />
      <path
        style={styles.three}
        d="m483.64,558.75c4.23.2,8.29.07,12.19-.34,64.18-6.79,95.55-90.87,101.13-106.56,19.65-53.61,4.79-103.03-34.89-122.82-8.38-4.18-22.48-9.34-43.93-9.79-9.67-.28-23.37.55-37.38,5.94-42.91,16.48-46.94,59.49-47.15,62.33"
      />
      <path
        style={styles.three}
        d="m376.1,410.08c16.33,22.66,46.24,31.22,65.62,24.12,14.69-5.38,19.85-18.49,21.15-22.26"
      />
      <path
        style={styles.three}
        d="m386.4,457.18c4.58,4.79,14.77,13.87,30.09,15.8,29,3.67,58.63-20.52,63.18-43.57.44-2.25,4.32-23.93-12.64-37.17-1.75-1.37-3.37-2.37-4.51-3.02"
      />
      <path
        style={styles.three}
        d="m449.8,384.12c7.39-4.76,17.83-9.57,28.79-8.17,23.41,2.98,34.12,32.35,35.98,49.4,3.83,35.17-23.38,78.48-66.36,85.17-14.53,2.26-27.39-.2-35.45-2.4"
      />
    </g>
  );
};

export default Obito;
