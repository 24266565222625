const PigTails = ({ color }) => {
  const styles = {
    one: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: color,
    },
  };

  return (
    <g>
      <path
        style={styles.two}
        d="M375,355c.25-6.06,1.24-15.04,5-25,19.72-52.31,93.84-73.96,147-73,12.93,.23,92.81,3.07,127,59,5.79,9.47,9.01,18.4,10.89,25.05,4.57-3.86,9.95-9.13,15.11-16.05,3.74-5.02,6.58-9.84,8.74-14.08,.13-3.9,.22-7.88,.26-11.92,.08-7.49-.02-14.76-.26-21.79-4.68-10.22-34.17-71.99-100.74-89.21-25.49-6.6-47.99-4.49-62.98-1.54-19.82-1.93-64.99-3.53-109.02,21.54-12.26,6.98-33.6,19.46-50,45-8.92,13.88-13.42,27.02-15.81,36.16-1.08,6.63-2.93,23.58,5.81,41.84,5.83,12.18,13.95,19.9,19,24Z"
      />
      <path
        style={styles.two}
        d="M350.19,289.16c-35.39,20.12-68.15,19.58-79.19,4.84-2.1-2.81-6.02-9.61-3.79-23.27-13.88-.22-23.11-12.52-21.21-22.73,1.73-9.27,12.66-17.04,24.83-14.6,.88-5.65,4.98-27.87,24.17-41.4,22.29-15.72,55.86-13.32,81.41,7.53,8.79,5.39,17.59,10.79,26.38,16.18-8.83,5.98-22.08,16.52-33.79,33.29-10.79,15.46-16.1,30.23-18.81,40.16Z"
      />
      <path
        style={styles.two}
        d="M623.84,203.12c1.71-3.11,6.8-11.35,17.16-16.12,6.71-3.09,12.84-3.43,16.35-3.35,2.17-2.06,17.24-15.9,39.65-13.65,21.22,2.14,33.01,17.09,34.93,19.62,2.55-1.39,5.84-2.55,9.07-1.62,15.74,4.56,22.85,56.85,4,69-3.31,2.13-6.84,2.66-9.36,2.76-4.43,3.69-11.3,8.56-20.64,12.24-10.66,4.2-20.13,5.11-26.26,5.21-5.34-11.38-13.98-26.65-27.74-42.21-13.26-15-26.8-25.22-37.16-31.88Z"
      />
      <path d="M388.77,208.05c-5.12,.56-10.19,1.53-15.12,3.01-2.47,.72-4.9,1.57-7.28,2.56-1.18,.52-2.39,.97-3.54,1.54l-1.75,.82-1.72,.89c-4.58,2.39-8.88,5.31-12.91,8.57-4.02,3.28-7.75,6.94-11.12,10.93,3.09-4.2,6.58-8.15,10.49-11.66,3.89-3.52,8.16-6.66,12.74-9.28,4.57-2.64,9.42-4.81,14.47-6.39,5.05-1.59,10.28-2.59,15.55-2.99l.18,1.99Z" />
      <path d="M354.55,275.55c-15.16-5.5-31.97-7.93-47.87-4.36-1.09,.3-3.79,1.06-4.9,1.37,1.11-.39,3.75-1.32,4.81-1.69,12.62-3.69,26.17-3.31,38.9-.31,3.36,.84,6.6,1.8,9.84,3.14,0,0-.77,1.84-.77,1.84h0Z" />
      <path d="M271.74,232.98c5.72,11.75,9.96,27.34,5.29,40.03-.08-.74,.25-1.73,.33-2.54,.15-.68,.15-1.82,.2-2.52,.43-8.4-1.76-16.68-4.26-24.64-1.05-3.18-2.15-6.39-3.38-9.48l1.81-.86h0Z" />
      <path d="M375.59,238.73c11.23-7.24,25.68-11.32,38.63-6.52,1.21,.55,2.61,1.18,3.78,1.79-13.77-3.06-26.57-.6-39.11,5.48-.37,.17-1.52,.7-1.9,.86,0,0-.79,.3-.79,.3l-.62-1.9h0Z" />
      <path d="M435.87,244.57c4.65,.39,9.31-.2,13.98-.05,13.86,.03,30,3.33,37.91,15.94,0,0-1.64,1.15-1.64,1.15-8.58-11.2-22.75-15.19-36.32-16-4.63-.42-9.35-.22-13.94-1.04h0Z" />
      <path d="M416.05,258.17c6.78,0,13.57,.8,20.19,2.44,6.62,1.64,13.05,4.11,19.09,7.38l-.99,1.74c-5.73-3.45-11.92-6.19-18.36-8.14-6.45-1.94-13.16-3.08-19.92-3.41Z" />
      <path d="M375.87,278.9c4.11-2.53,8.55-4.58,13.2-6.1,4.65-1.48,9.5-2.47,14.43-2.78,4.92-.29,9.91,.02,14.77,1.02,4.84,1.07,9.53,2.89,13.67,5.56l-.88,1.8c-4.5-1.56-8.96-2.93-13.54-3.88-4.57-.93-9.22-1.52-13.92-1.46-1.17,0-2.35,.06-3.52,.11-1.17,.11-2.35,.16-3.52,.33-2.35,.24-4.68,.67-7,1.15-2.32,.51-4.62,1.12-6.91,1.82-2.29,.71-4.53,1.55-6.78,2.44Z" />
      <path d="M361.83,304.71c3.46-2.7,7.18-5.11,11.11-7.15,1.95-1.04,3.97-1.96,6-2.83,2.05-.85,4.14-1.59,6.26-2.26,4.24-1.32,8.6-2.32,13.03-2.84,4.43-.51,8.91-.57,13.34-.12l-.12,2c-4.34-.08-8.66,.06-12.95,.5-4.3,.44-8.57,1.16-12.77,2.26-4.19,1.13-8.31,2.55-12.3,4.3-3.99,1.76-7.86,3.81-11.59,6.15Z" />
      <path d="M452.55,216.55c22.23-3.18,46.71-1.05,65.67,11.93,2.69,1.89,5.16,4.07,7.51,6.37l-1.43,1.4c-18.5-18.39-46.79-22.07-71.76-19.7h0Z" />
      <path d="M525.82,186.04v70.93s-.85-1-.88-.99c-.18-.07-.55,.04-.62,.12,.1-.08,.35-.29,.45-.37,1.02-.87,3.01-2.48,4.07-3.34,8.62-6.65,25.95-20.22,36.93-19.28-10.72,.64-29.21,15.93-37.97,22.77-.37,.29-1.39,1.13-1.76,1.41-.38,.3-.83,.72-1.35,.68,0,0-.02,0-.02,0l-.86-.03v-71.89h2Z" />
      <path d="M470.45,228.85c19.66,3.51,38.51,11.76,54.92,23.11,0,0-1.09,1.68-1.09,1.68-16.99-10.16-34.7-19.37-53.83-24.79h0Z" />
      <path d="M524.29,232.55c25.2-15.1,55.66-24.16,85.09-19.96-2.77,.03-5.52-.05-8.28-.05-19.22,.61-38.12,5.31-55.92,12.43-6.74,2.75-13.45,5.8-19.83,9.29,0,0-1.05-1.7-1.05-1.7h0Z" />
      <path d="M547.41,257.56c19.28-13.38,43.02-19.39,66.29-20.05,2.96-.13,7.03,.12,10.01,.15-4.79,.33-10.13,.58-14.92,1.16-2.75,.2-7.1,.92-9.86,1.29-8.03,1.38-16.37,3.3-24.04,6.06-8.55,2.94-16.88,6.83-24.46,11.77-.62,.4-1.61,1.1-2.27,1.46,0,0-.74-1.86-.74-1.86h0Z" />
      <path d="M578.88,263.53c1.96-.45,5.41-1.76,7.43-2.41,18.48-6.3,38.18-9.53,57.71-8.02-16.68-.49-33.31,2.24-49.19,7.21-4.53,1.39-9.32,3.2-13.82,4.71-.81,.23-1.66,.58-2.54,.47,0,0,.41-1.96,.41-1.96h0Z" />
      <path d="M688.26,278.09c-5.94-3.06-11.94-6.1-18.06-8.83-6.11-2.45-12.46-5.64-19.19-5.26,1.65-.38,3.38-.33,5.07-.16,5.07,.58,9.95,2.23,14.71,3.98,6.35,2.39,12.51,5.21,18.44,8.52,0,0-.96,1.75-.96,1.75h0Z" />
      <path d="M635.68,281.63c17.5,6.54,42.34,19.81,48.32,38.71,0,0-1.89,.64-1.89,.64-7.6-18.04-29.48-30.93-46.42-39.35h0Z" />
      <path d="M732.89,189.33c1.52,6.78,2.38,13.73,2.11,20.67-.28-5.16-1.25-10.23-2.56-15.21-.44-1.65-.93-3.31-1.46-4.89l1.92-.57h0Z" />
      <path d="M734.64,259.68c-.75-10.46,.62-20.85,.81-31.29,.05-2.59,.11-5.19-.25-7.75-.15-.83-.27-1.71-.8-2.43,4.63,4.75,4.23,24.35,3.77,31.18-.29,3.5-.69,6.99-1.54,10.45l-1.99-.17h0Z" />
      <path d="M735.9,232c-9.58-11.49-24.82-30.01-39.9-33.01,12.63,1.26,24.52,12.7,32.98,21.51,3.01,3.29,5.89,6.67,8.52,10.3l-1.6,1.2h0Z" />
      <path d="M626.55,205.29c8.34-5.05,20.5-2.62,28.95,1.01,7,2.86,13.42,7.08,19.19,11.92-6.36-3.49-13.18-7.37-19.99-9.88-5.34-2.05-11.06-3.47-16.75-3.51-2.5,0-5.17,.29-7.57,1-.92,.32-1.96,.67-2.69,1.11l-1.15-1.64h0Z" />
      <path d="M657.19,182.66c3.36-.14,13.17-.99,14.63,2.63-.25-.6-.79-.97-1.35-1.21-1.76-.66-3.69-.59-5.54-.51-2.49,.16-5,.55-7.43,1.06l-.31-1.98h0Z" />
      <path style={styles.one} d="M646.15,220.08s-27.58-8.26-27.37-2.67" />
    </g>
  );
};

export default PigTails;
