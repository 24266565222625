import { Grid } from "@mui/material";
import MatchCancelTeamItem from "./MatchCancelTeamItem";

const MatchCancelState = ({ token }) => {
  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <Grid item sx={styles.width}>
      <Grid
        container
        alignItems="stretch"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <MatchCancelTeamItem
          team={token?.creating_team}
          token={token}
          isCreatingTeam={true}
        />

        <MatchCancelTeamItem
          team={token?.joining_team}
          token={token}
          isCreatingTeam={false}
        />
      </Grid>
    </Grid>
  );
};

export default MatchCancelState;
