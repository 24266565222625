const PSGJacket = ({ color, skinColor }) => {
  const styles = {
    one: {
      strokeWidth: 0.25,
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#ff5975",
      strokeWidth: 0.5,
    },
    three: {
      fill: skinColor,
    },
    four: {
      fill: color,
    },
    five: {
      fill: color,
      filter: "brightness(85%)",
    },
  };

  return (
    <g>
      <path
        style={styles.five}
        d="m511.5,566.1l-.3,16.33,5.49-.41c3.45-1.16,7.52-2.87,11.8-5.46,2.21-1.34,4.16-2.72,5.87-4.06-.31-1.16-.62-2.32-.93-3.47-3.33-.7-6.92-1.34-10.76-1.86-3.95-.53-7.68-.87-11.17-1.07Z"
      />
      <polygon
        style={styles.five}
        points="435.59 561.3 435.17 562.87 448.88 577.1 451.24 561.3 435.59 561.3"
      />
      <path
        style={styles.four}
        d="m288.78,746.86c-.4-14.89.38-33.27,4.22-53.91,2.91-15.7,6.98-29.41,11.15-40.89,18.82-16.26,44.95-35.52,78.85-52.06,24.12-11.77,46.61-19.45,65.69-24.57,21.07,1.84,42.13,3.67,63.2,5.51,19.71,2.58,50.2,9.07,83.11,27.06,26.46,14.46,45.41,31.41,57.79,44.37,4.94,15.77,9.6,33.7,13.21,53.58,4.64,25.54,6.56,48.82,7.05,68.96-14.2,12.19-36.17,28.56-66.05,42-51.38,23.12-97.39,24.63-120,23.99-46.92-1.33-82.48-14.45-100-21.99-49.98-21.53-81.86-53.17-98.22-72.04Z"
      />
      <path
        style={styles.three}
        d="m518.87,533.53c-1.93,5.66-3.14,10.23-3.91,13.46-.37,1.56-.46,2.04-1.83,7.96-1.43,6.18-1.46,6.21-1.7,7.43-.91,4.8-1.06,8.01-1.17,10.7-.26,6.12.32,7.46,1.17,8.35.49.51,1.02.81,1.4.98,5.26,4.47,5.85,8.04,5.77,9.96-.35,9.12-16.94,15.17-27,16.97-23.01,4.12-44.54-8.01-48-17.09-.73-1.93-2.88-7.57-.23-11.22.48-.66,1.01-1.11,1.37-1.37.58-.37,1.48-1.02,2.38-2.02,0,0,1.01-.93,1.57-2.22,1.09-2.53,5.86-23.75,11.97-52.88,7.52,3.12,17.19,6.39,28.72,8.56,11.36,2.13,21.42,2.6,29.48,2.43Z"
      />
      <path
        style={styles.two}
        d="m558.78,668.39c-1.46.09-3.78.46-6,1.96-5.35,3.6-5.56,10.64-5.61,12.13-.24,7.92,5.05,14.75,10.87,18.14,3.91,2.28,7.7,2.8,9.94,2.93,1.28-.21,5.27-1.02,8.09-4.5,2.8-3.47,2.68-7.42,2.61-9.98-.2-6.78-3.82-11.48-4.89-12.78-5.51-6.68-13.23-7.7-15-7.89Z"
      />
      <path d="m460,523c1.9,1.69,3.9,3.36,6,5,15.78,12.32,32.48,19.14,47,23,1.96-5.48,3.91-10.96,5.87-16.44-4.61.02-9.68-.16-15.13-.64-.79-.07-6.86-.61-12.73-1.62-12.45-2.13-23.75-6.29-31-9.31Z" />
      <g>
        <path d="m460.98,525.94s-11.31,49.7-11.31,49.7c0,0-.13.58-.13.58-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74l11.31-49.7s1.95.44,1.95.44h0Z" />
        <path d="m518.91,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59,0,0,1.88.69,1.88.69h0Z" />
      </g>
      <path
        style={styles.four}
        d="m434.61,562.22c-3.52,3.99-5.95,7.67-7.57,10.43-2.71,4.64-3.6,7.5-3.95,8.74-.6,2.13-.87,3.96-1.01,5.28,21.09,12.07,42.17,24.13,63.26,36.2,22.83-9.96,45.65-19.91,68.48-29.87-2.86-.99-4.06-2.36-4.63-3.46-.45-.87-.5-1.57-1.24-2.67-1.04-1.56-2.42-2.26-2.75-2.55-2.24-2.03-5.44-5.59-9.64-12.32-9.62,7.46-17.25,9.96-22.57,10.83-3.13.51-6.61.64-11.09,2.87-4.04,2.01-5.48,4.19-10.04,6-3.51,1.39-6.57,1.96-8.48,2.22-8.45-.61-14.72-3.01-18.52-4.83-6.62-3.17-11.09-7.14-15.39-10.96-3.85-3.42-6.81-6.6-8.87-9-2-2.3-4-4.61-6-6.91Z"
      />
      <path
        style={styles.two}
        d="m478.42,754.86c-3.33-3.97-7.52-4-10.83-.49-1.35,1.43-1.77,1.38-2.5-.41-2.62-6.37-5.01-12.84-7.91-19.07-1.42-3.05-1.43-5.93-.77-8.94.83-3.79,2.73-7.09,5.42-9.91,1.23-1.29,1.83-.29,2.32.69,2.18,4.4,4.34,8.8,6.45,13.23,8.76,18.31,17.5,36.62,26.24,54.93.65,1.36.4,1.87-1.02,1.91-4.04.1-8.08.14-12.11.27-1.43.05-2.36-.43-3.03-1.76-3.84-7.63-7.75-15.22-11.63-22.83-.15-.29-.31-.57-.56-1.03-2.69,2.18-5.05,4.53-6.75,7.47-2.44,4.24-5.3,8.17-9.01,11.39-1.24,1.08-2.54,2.16-3.98,2.93-4.5,2.39-9.25,4.15-14.24,5.19-.09-.13-.19-.26-.28-.39.34-.32.69-.64,1.03-.97,1.85-1.8,3.75-3.55,5.54-5.4,1.75-1.8,1.55-4,.65-6.03-3.03-6.83-1.72-13.14,2.05-19.2,2.15-3.45,3.36-7.16,3.26-11.26-.01-.5.17-1.01.27-1.57,3.7,1.57,9.03,11.62,10.84,20.24,3.51-1.15,6.21-3.53,9.05-5.69,1.75-1.33,3.54-2.64,5.42-3.77,1.34-.8,2.85-1.12,4.36-.1.49.33,1.15.39,1.74.57Z"
      />
      <path
        style={styles.one}
        d="m536.73,572.57c-1.51-3.16-3.82-5.06-7.19-5.72-2-.39-4.01-.85-6.04-.97-3.92-.23-7.85-.44-11.77-.65.04.63-.04,1.16-.2,1.61,0,.04,0,.08,0,.12,3.47.18,6.95.39,10.41.64,3.37.25,6.68,1.29,9.98,2.12.68.17,1.59.95,1.68,1.55.08.55-.68,1.46-1.3,1.83-5.39,3.31-10.64,6.94-17.01,8.23-3.4.68-6.82,1.28-10.18,2.09-3.83.93-7.1,3.05-10.11,5.48-3.09,2.48-6.57,3.25-10.42,3.61-4.5.42-8.81-.15-13.09-1.29-7.04-1.88-12.97-5.75-18.19-10.74-5.04-4.82-10.02-9.69-15-14.56-.94-.92-1.74-1.97-2.61-2.97.04-.18.07-.36.11-.55,1.23-.07,2.47-.13,3.7-.2,1.15-.06,2.29-.08,3.44-.19,1.59-.15,3.16-.49,4.75-.54,1.38-.04,2.76-.09,4.14-.14.02-.51.04-1.03.09-1.53-.02,0-.04,0-.06,0-3.61-.43-7.14-.52-10.67.51-.45.13-.98,0-1.47-.05-2.43-.28-4.69.16-6.43,1.94-1.38,1.41-2.72,2.93-3.77,4.6-3.85,6.1-5.87,11.36-8.29,18.12-.28.77-1.28-.1-1.34.75.53-.67,1.67-.02,1.96-.78,2.48-6.67,5.18-11.34,9.2-17.28,1.03-1.52,2.31-2.86,3.49-4.32.44.39.63.52.77.68,5.21,6.65,11.33,12.43,17.53,18.11,4.05,3.71,8.41,7.07,13.63,8.93,4.08,1.46,8.32,2.44,12.92,3.77.14,2.75.4,5.72.42,8.69.07,13.45.07,26.91.11,40.36.02,6.42-.09,12.85.12,19.26.25,7.34.8,14.67,1.21,22.01.1,1.71.09,3.43.28,5.13.05.5.55,1.31.9,1.35,1.69.17,3.41.17,5.11.1.24,0,.63-.74.64-1.15.08-5.19.3-10.38.11-15.56-.28-8.01-.95-16.01-1.26-24.02-.17-4.47.13-8.96.1-13.44-.03-5.08-.21-10.15-.3-15.23-.03-1.72.05-3.44-.04-5.15-.27-5.13-.84-10.26-.82-15.38,0-2.26,1.09-4.54,1.82-6.77.14-.42.87-.72,1.38-.95,2.4-1.07,5.23-1.62,7.15-3.24,4.9-4.16,10.55-6.2,16.69-7.43,3.12-.62,6.37-1.19,9.22-2.51,4.52-2.1,8.75-4.81,13.06-7.23,3.97,7.58,9.47,11.53,16.61,15.67-4.41-5.26-12.23-10.5-15.19-16.71Z"
      />
      <path
        style={styles.one}
        d="m551.93,589.29c1.16,1.38,2.11,1.6,3.07,1.74-1.06-.59-2.08-1.17-3.07-1.75,0,0,0,0,0,0Z"
      />
      <path
        style={styles.one}
        d="m558.46,592.66c-1.37-1.38-2.42-1.47-3.46-1.63.94.53,1.91,1.07,2.9,1.63.04-.27.69.13.55,0Z"
      />
      <path
        style={styles.two}
        d="m522.36,788.08c-3.28.13-6.58.54-9.83.32-5.16-.34-8.2-3.92-8.32-9.1-.34-15.44-.72-30.88-1.06-46.31-.12-5.48-.16-10.96-.33-16.43-.05-1.48.45-1.94,1.9-1.86,3.26.18,6.52.28,9.78.32,1.5.02,1.32,1.02,1.33,1.94.11,10.9.21,21.81.31,32.71.09,10.24.15,20.48.3,30.72.03,2.34,1.49,3.95,3.4,5.16.9.57,1.8,1.15,2.69,1.72-.06.27-.12.54-.18.81Z"
      />
      <path
        style={styles.two}
        d="m546.85,789.18c-2.05-.15-3.84-.26-5.63-.41-3.39-.28-6.68-.8-9.21-3.46-1.68-1.76-2.56-3.74-2.58-6.22-.18-19.98-.42-39.95-.64-59.93,0-.55.02-1.11,0-1.66-.04-1.01.3-1.54,1.43-1.47,2.98.19,5.95.37,8.93.42,1.65.03,1.85.98,1.88,2.25.1,4.2.26,8.41.33,12.61.25,15.71.48,31.43.7,47.14.05,3.84,1.26,7.13,4.28,9.64.21.17.26.53.51,1.08Z"
      />
      <path
        style={styles.one}
        d="m546.03,682.96c-.05-8.16,3.18-13.25,8.39-14.9,3.4-1.08,6.85-.84,10.03.58,9.29,4.15,14.43,11.4,15.06,21.58.41,6.61-3.39,12.08-9.09,13.61-3.89,1.05-7.64.25-11.04-1.6-8.3-4.52-12.69-11.64-13.36-19.28Zm1.9-2.05c-.03,9.02,6.37,18.18,14.65,20.96,7.89,2.65,14.82-1.94,15.03-10.21.23-9.33-4.33-16.11-12.14-20.78-2.89-1.73-6.19-2.38-9.57-1.51-5.1,1.31-7.95,5.51-7.98,11.55Z"
      />
      <path
        style={styles.two}
        d="m436.84,806.54c.01,2.01-.53,4.39-2.35,6.32-2.38,2.52-5.79,2.75-8.51.61-4.06-3.2-4.74-10.09-1.38-14.01,2.76-3.23,7.3-3.12,10.06.24,1.32,1.61,1.99,3.56,2.17,6.85Zm-3.18-.71c-.57-1.16-1.02-2.68-1.94-3.82-1.63-2.03-4.42-1.52-5.52.82-.95,2.02-.47,5.19,1.03,6.82,1.82,1.98,4.57,1.56,5.63-.91.34-.79.47-1.67.81-2.91Z"
      />
      <path
        style={styles.two}
        d="m451.71,813.46c-2.26.45-3.66.33-4.71-1.5-.84-1.46-1.84-3.04-4.03-3.28-.08,1.24-.23,2.38-.22,3.52.01,1.13-.59,1.26-1.49,1.19-.79-.06-1.75.27-1.81-1.05-.19-4.42-.4-8.84-.57-13.26-.04-.99.37-1.62,1.49-1.79,2.66-.41,5.2-.15,7.5,1.26,2.64,1.6,3.21,4.56,1.15,7.46-1.08,1.51-.79,2.47.15,3.7.84,1.11,1.56,2.31,2.52,3.75Zm-9.26-8.08c1.33-.33,2.5-.43,3.48-.9,1.18-.57,1.28-3,.2-3.3-1.17-.32-2.42-.32-3.68-.46v4.66Z"
      />
      <path
        style={styles.two}
        d="m536,806.71c-.74.89-1.48,1.77-2.2,2.66-.7.86-1.38.97-2.18.08-.91-1.01-1.88-1.96-3.2-3.31,0,2.39-.04,4.24.02,6.09.04,1.1-.3,1.69-1.48,1.64-1.03-.04-1.53-.36-1.53-1.57-.02-4.2-.2-8.39-.27-12.59,0-.52.34-1.05.52-1.58.45.34,1.01.59,1.34,1.03,1.72,2.28,3.38,4.61,5.19,7.09,2.27-2.69,4.47-5.3,6.92-8.21.16.71.26.95.26,1.2.04,4.37.04,8.73.1,13.1.01,1.04-.08,1.88-1.43,1.86-1.25-.02-1.6-.73-1.6-1.83.01-1.86,0-3.72,0-5.57-.15-.02-.3-.05-.45-.07Z"
      />
      <path
        style={styles.two}
        d="m415.18,797.74c2.15.35,3.67,1.19,4.87,2.73.72.92.57,1.65-.2,2.46-.8.85-1.28.29-1.93-.2-1.67-1.27-3.48-1.03-4.85.55-1.75,2.02-1.35,5.7.79,7.27,1.22.9,2.72.84,4.25-.33.96-.73,1.69-.5,2.51.17.9.75.47,1.44,0,2.17-2.44,3.72-7.35,3.96-10.11.48-3.11-3.92-2.72-10.23.98-13.38,1.03-.88,2.46-1.29,3.7-1.91Z"
      />
      <path
        style={styles.two}
        d="m499.13,806.43c-1.69.09-3.27.17-4.98.26v3.31h7.22v3.39c-2.08,0-4.21.01-6.34,0q-3.32-.02-3.49-3.3c-.2-3.53-.48-7.06-.61-10.59-.09-2.44.1-2.52,2.47-2.43,1.66.06,3.32.05,4.98.06q2.12.02,2.16,2.2c0,.21.06.41.13.87-1.08.07-2.1.16-3.12.19-1.05.03-2.09,0-3.27,0v2.62c1.52.14,3.14.3,4.85.46v2.95Z"
      />
      <path
        style={styles.two}
        d="m482.42,806.55c.89-2.49,1.71-4.75,2.51-7.01.97-2.77,1.07-2.83,4.46-2.14-2,5.27-3.99,10.49-5.97,15.71-.27.01-.54.02-.81.04-2.44-5.16-4.88-10.32-7.31-15.47,1.77-1.21,3.35-.78,4.06,1.02,1.01,2.54,1.99,5.09,3.07,7.86Z"
      />
      <path
        style={styles.two}
        d="m573,806c-.12,1.05-.22,1.97-.34,3.11.75,0,1.43-.12,2.03.04.47.13,1.24.73,1.19.9-.17.56-.57,1.24-1.07,1.46-.66.28-1.5.17-2.41.24-.08,1.23-.16,2.42-.25,3.81,1.73.22,3.39.44,5.04.66,0,2.46-.14,2.61-2.47,2.49-1.43-.08-2.86-.2-4.28-.36-1.07-.12-1.64-.57-1.51-1.85.42-4.33.68-8.68,1.09-13.02.04-.38.69-1,1.05-.99,1.98.02,3.97.11,5.93.39.48.07,1.08.85,1.21,1.4.22.9-.31,1.65-1.31,1.71-1.25.08-2.52.02-3.9.02Z"
      />
      <path
        style={styles.two}
        d="m556.74,648.81c-1.37.82-1.68,2.85-3.42,2.98-.14-.14-.27-.28-.41-.42,1.29-1.66,2.57-3.32,3.98-5.14-.05-.28-.15-.85-.34-1.95,1.04.47,1.71.78,2.81,1.28.13-1.84.23-3.56.38-5.27.04-.51.11-1.07.37-1.47.12-.17,1.06-.1,1.12.06.17.43.12.98.08,1.47-.19,2.23-.55,4.45-.6,6.69-.09,4.71,2.75,7.55,6.51,9.78.98.58,1.74,1.53,2.68,2.19,1.03.72,2.17,1.31,3.25,1.95-.89.17-1.91.81-3.13-.13-2.61-2.03-5.12-4.26-7.98-5.85-1.45-.81-3.65-.81-5.37-.45-2.05.42-3.93,1.65-5.91,2.46-.81.33-1.7.46-2.55.69-.07-.16-.14-.31-.21-.47.25-.21.46-.49.75-.63,2.39-1.25,4.81-2.46,7.19-3.73,1.47-.79,1.86-2.71.8-4.03Z"
      />
      <path
        style={styles.two}
        d="m406.58,809.41c1.38,1.46,1.42,2.61.2,3.92-1.35,1.44-3.56,1.68-4.89.26-2.25-2.4-3.34-5.32-2.92-8.64.17-1.35.53-2.72,1.06-3.97,1.63-3.76,5.46-2.97,7.28-.83,1.28,1.51,1.11,2.2-.68,3.54-.11-.02-.24-.01-.31-.07-.94-.77-1.84-1.76-3.2-.96-1.53.89-1.8,2.45-1.84,4.01-.02.74.26,1.52.54,2.23.88,2.25,2.31,2.56,4.12.95.16-.14.35-.25.63-.44Z"
      />
      <path
        style={styles.two}
        d="m554,802.97c0,1.93.04,3.8-.01,5.66-.05,1.93-.21,3.86-.28,5.79-.03.85-.26,1.34-1.25,1.3-1-.05-1.13-.61-1.15-1.43-.07-3.26-.27-6.51-.23-9.77.02-1.55-.35-2.17-1.99-2.15-2.13.03-2.48-.52-2.22-3.1,2.65.21,5.32.42,7.98.65.38.03.76.15,1.14.18,2.31.16,2.52.45,2.04,3.04-1.27-.06-2.57-.11-4.03-.18Z"
      />
      <path
        style={styles.two}
        d="m467.81,813.69c-2.69,0-5.33.05-7.97-.05-.4-.02-1.08-.72-1.11-1.14-.26-4.57-.45-9.15-.54-13.73-.01-.52.64-1.44,1.06-1.48.69-.08,1.71,0,1.74,1.23.08,2.76.19,5.52.31,8.28.05,1.04.14,2.08.23,3.34,1.32,0,2.53-.03,3.73,0,2.75.09,2.91.28,2.54,3.55Z"
      />
      <path
        style={styles.two}
        d="m508.67,797.52c2.36-.16,2.46-.16,2.51,2.02.08,3.65.02,7.29.02,11.04,2.29.17,4.24.37,6.2.43,1.03.04,1.17.55,1.12,1.38-.05.83-.3,1.37-1.28,1.32-2.48-.11-4.97-.14-7.45-.32-.4-.03-1.08-.65-1.08-1.01-.07-4.91-.05-9.83-.05-14.87Z"
      />
      <path
        style={styles.two}
        d="m584.95,808.54c-.31-.56-.46-.92-.69-1.22-.28-.35-.64-.64-.97-.96-.35.45-1.01.91-.99,1.34.04.86.27,1.83.75,2.53.62.89,1.8,1.41,2.31,2.33.69,1.24,1.26,2.69,1.35,4.09.12,1.73-1.62,3.27-3.25,3.38-1.69.11-4.18-1.4-4.47-2.84-.09-.45.1-1.21.42-1.42.4-.25,1.09-.16,1.62-.04.34.07.58.56.93.65.67.19,1.37.23,2.06.34.1-.87.57-1.96.21-2.55-.63-1.03-1.75-1.78-2.7-2.61-2.12-1.87-2.25-4.86-.29-6.89,1.05-1.08,2.46-1.34,3.41-.61,1.08.82,1.39,2.71.3,4.49Z"
      />
      <path
        style={styles.two}
        d="m563.33,814.33c1.36.17,2.56.32,3.86.48v2.6c-1.77,0-3.47.08-5.15-.06-.42-.04-1.08-.8-1.1-1.25-.15-4.69-.19-9.38-.29-14.07-.02-.94.36-1.27,1.26-1.29.99-.02,1.4.4,1.39,1.38-.01,2.87,0,5.74.02,8.61,0,1.15,0,2.31,0,3.6Z"
      />
      <path
        style={styles.two}
        d="m473.26,805.21c0,2.04,0,4.08,0,6.12,0,1.14-.45,1.74-1.68,1.72-1.18-.02-1.28-.74-1.3-1.66-.09-4.25-.21-8.49-.31-12.74-.02-.7-.16-1.54.97-1.53.98,0,2.16-.17,2.21,1.3.07,2.26.02,4.52.02,6.78.03,0,.06,0,.09,0Z"
      />
      <path
        style={styles.one}
        d="m485.24,692.06c2.12.58,4.49,1.16,6.8,1.91.55.18,1.29.96,1.27,1.43-.04.64-.59,1.57-1.16,1.79-1.61.63-3.32,1.12-5.03,1.4-2.76.44-5.53.16-8.09-.95-.83-.36-1.79-1.36-1.89-2.16-.07-.61,1.02-1.72,1.8-1.99,1.89-.64,3.91-.92,6.3-1.43Zm6.65,3.46c-2.1-2.45-10.64-2.38-13.15,0,2.53,2.22,10.47,2.25,13.15,0Z"
      />
      <path
        style={styles.two}
        d="m395.83,798.27c.52,2.08.99,3.71,1.32,5.37.59,3.01,1.13,6.04,1.61,9.07.08.51-.23,1.08-.36,1.62-.32-.41-.56-.98-.97-1.21-.84-.46-1.75-.83-2.68-1.05-.81-.19-1.65-.16-1.85,1.02-.05.31-.5.56-.77.84-.12-.32-.41-.68-.34-.96,1.08-4.58,2.19-9.15,3.32-13.72.04-.18.23-.33.73-.99Zm.86,11.38c-.41-2.43-.81-4.8-1.21-7.16-.07.01-.13.02-.2.03-.5,2.34-1,4.68-1.53,7.13h2.93Z"
      />
      <path
        style={styles.two}
        d="m522.7,806.63c0,1.88,0,3.76,0,5.64,0,.88-.33,1.67-1.33,1.38-.5-.14-1.14-.88-1.16-1.38-.2-4.31-.28-8.62-.39-12.93-.02-.8-.07-1.57,1.13-1.58,1.03,0,1.5.32,1.54,1.42.09,2.48.27,4.96.41,7.44-.06,0-.13,0-.19,0Z"
      />
      <path
        style={styles.two}
        d="m542.15,798.91c2.13-.25,2.21-.19,2.21,1.72,0,3.86-.02,7.72-.01,11.58,0,.97-.11,1.77-1.33,1.84-1.42.07-1.26-.97-1.25-1.81.05-4.19.14-8.37.23-12.56,0-.26.11-.52.16-.76Z"
      />
      <path
        style={styles.two}
        d="m587.85,808.23c.48,1.04.79,2.23,1.48,3.11,1.81,2.3,2.82,4.71,1.89,7.66-.36,1.15-1,1.71-2.22,1.16-1.11-.5-2.04-2.21-1.57-2.96.13-.21.69-.39.9-.28.42.22.72.64,1.23,1.12.73-1.85.45-3.61-.79-4.77-2.32-2.19-2.06-4.83-1.87-7.53.02-.33.26-.8.52-.91.27-.11.89.02,1.01.24.44.81.77,1.68,1.04,2.56.03.09-.62.39-.96.59-.22,0-.44.01-.67.02Z"
      />
      <path
        style={styles.one}
        d="m567.1,701.44c-3.84-.11-6.99-1.46-9.76-3.7-5.12-4.15-8.4-9.43-8.58-16.11-.24-9.2,7.47-14.12,15.77-10.14,7.63,3.66,11.99,9.89,12.56,18.3.34,5-1.69,10-8.08,11.45-.69.16-1.42.15-1.91.2Zm-10.04-11.06c.75-1.26,1.43-2.44,2.17-3.59.23-.36.65-.92.9-.88,1.93.32,4.09.34,5.67,1.3,1.56.94,2.6,2.81,3.68,4.4.22.32-.36,1.2-.57,1.82-.35-.31-.75-.59-1.03-.95-1.01-1.3-1.84-2.77-3-3.91-2.06-2.03-4.39-1.35-5.21,1.4-.23.77-.28,1.59-.48,2.85,1.46.69,3.11,1.75,4.91,2.25,4.83,1.35,8.49-1.99,7.83-6.96-.78-5.89-4.97-10.6-10.74-12.06-3.01-.76-6.01.9-6.82,3.88-1.1,4,.47,7.31,2.7,10.44Z"
      />
      <path
        style={styles.one}
        d="m570.46,690.09c-4.58-2.45-5.93-6.82-7.06-11.29-.26-1.05.64-1.02,1.29-.81,3.41,1.09,7.07,7.9,6.16,11.44-.06.24-.26.45-.39.67Z"
      />
      <path
        style={styles.one}
        d="m561.18,679.75c-.18,3.02-1.25,5.51-3.53,7.47-.64.55-1.22.85-1.7-.12-1.47-2.96-1.19-7.34,1.78-9.03.84-.48,1.92-.54,2.9-.8.19.83.37,1.66.56,2.48Z"
      />
      <path
        style={styles.one}
        d="m565.04,694.42c-3.14.03-4.8-1.62-4.36-3.76.34-1.65,1.74-2.4,3.2-1.57.88.5,1.8,1.14,2.37,1.95.4.57.51,1.66.23,2.3-.26.6-1.18.91-1.43,1.09Zm-1.98-4.41c-.28,1-.79,2.01-.58,2.19.8.7,1.81,1.47,3.09,0-.73-.64-1.46-1.28-2.51-2.2Z"
      />
      <path
        style={styles.one}
        d="m564.29,685.49c-1.11-.15-2.08-.21-3.01-.45-.24-.06-.54-.68-.49-1,.37-2.24.82-4.47,1.24-6.68.58.18,1.76,3.95,2.26,8.12Z"
      />
      <ellipse style={styles.two} cx="485.45" cy="695.61" rx="6.7" ry="1.58" />
    </g>
  );
};

export default PSGJacket;
