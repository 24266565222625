import "../styles/Cross.css";

const Cross = () => {
  return (
    <g>
      <g>
        <circle class="cross-4" cx="491" cy="642" r=".5" />
        <circle class="cross-5" cx="491" cy="642" r=".5" />
      </g>
      <g>
        <circle class="cross-1" cx="448.27" cy="577.72" r="1.73" />
        <circle class="cross-2" cx="448.27" cy="577.72" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="448.73" cy="581.83" r="1.73" />
        <circle class="cross-2" cx="448.73" cy="581.83" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="449.51" cy="585.26" r="1.73" />
        <circle class="cross-2" cx="449.51" cy="585.26" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="450.51" cy="589.26" r="1.73" />
        <circle class="cross-2" cx="450.51" cy="589.26" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="451.51" cy="593.26" r="1.73" />
        <circle class="cross-2" cx="451.51" cy="593.26" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="452.51" cy="597.35" r="1.73" />
        <circle class="cross-2" cx="452.51" cy="597.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="453.51" cy="601.35" r="1.73" />
        <circle class="cross-2" cx="453.51" cy="601.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="454.51" cy="605.35" r="1.73" />
        <circle class="cross-2" cx="454.51" cy="605.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="455.51" cy="609.35" r="1.73" />
        <circle class="cross-2" cx="455.51" cy="609.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="456.51" cy="613.35" r="1.73" />
        <circle class="cross-2" cx="456.51" cy="613.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="458.51" cy="617.35" r="1.73" />
        <circle class="cross-2" cx="458.51" cy="617.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="460.51" cy="621.35" r="1.73" />
        <circle class="cross-2" cx="460.51" cy="621.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="462.51" cy="625.35" r="1.73" />
        <circle class="cross-2" cx="462.51" cy="625.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="464.51" cy="629.35" r="1.73" />
        <circle class="cross-2" cx="464.51" cy="629.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="466.51" cy="633.35" r="1.73" />
        <circle class="cross-2" cx="466.51" cy="633.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="468.51" cy="637.35" r="1.73" />
        <circle class="cross-2" cx="468.51" cy="637.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="470.51" cy="641.35" r="1.73" />
        <circle class="cross-2" cx="470.51" cy="641.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="472.51" cy="645.35" r="1.73" />
        <circle class="cross-2" cx="472.51" cy="645.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="474.51" cy="649.35" r="1.73" />
        <circle class="cross-2" cx="474.51" cy="649.35" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="477.79" cy="654.43" r="1.73" />
        <circle class="cross-2" cx="477.79" cy="654.43" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="481.79" cy="650.43" r="1.73" />
        <circle class="cross-2" cx="481.79" cy="650.43" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="484.79" cy="647.43" r="1.73" />
        <circle class="cross-2" cx="484.79" cy="647.43" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="487.33" cy="644.74" r="1.73" />
        <circle class="cross-2" cx="487.33" cy="644.74" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="490.33" cy="641.74" r="1.73" />
        <circle class="cross-2" cx="490.33" cy="641.74" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="511.33" cy="582.74" r="1.73" />
        <circle class="cross-2" cx="511.33" cy="582.74" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="511.33" cy="587" r="1.73" />
        <circle class="cross-2" cx="511.33" cy="587" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="511.33" cy="591" r="1.73" />
        <circle class="cross-2" cx="511.33" cy="591" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="510.33" cy="595" r="1.73" />
        <circle class="cross-2" cx="510.33" cy="595" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="509.33" cy="599" r="1.73" />
        <circle class="cross-2" cx="509.33" cy="599" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="508.33" cy="603" r="1.73" />
        <circle class="cross-2" cx="508.33" cy="603" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="507.33" cy="607" r="1.73" />
        <circle class="cross-2" cx="507.33" cy="607" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="506.33" cy="611" r="1.73" />
        <circle class="cross-2" cx="506.33" cy="611" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="504.33" cy="615" r="1.73" />
        <circle class="cross-2" cx="504.33" cy="615" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="502.33" cy="619" r="1.73" />
        <circle class="cross-2" cx="502.33" cy="619" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="500.33" cy="623" r="1.73" />
        <circle class="cross-2" cx="500.33" cy="623" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="498.33" cy="627" r="1.73" />
        <circle class="cross-2" cx="498.33" cy="627" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="496.33" cy="631" r="1.73" />
        <circle class="cross-2" cx="496.33" cy="631" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="494.33" cy="635" r="1.73" />
        <circle class="cross-2" cx="494.33" cy="635" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="492.33" cy="638" r="1.73" />
        <circle class="cross-2" cx="492.33" cy="638" r="1.73" />
      </g>
      <g>
        <circle class="cross-1" cx="476.31" cy="652.05" r="1.05" />
        <circle class="cross-2" cx="476.31" cy="652.05" r="1.05" />
      </g>
      <g>
        <circle class="cross-1" cx="478.99" cy="651.37" r="1.05" />
        <circle class="cross-2" cx="478.99" cy="651.37" r="1.05" />
      </g>
      <rect class="cross-3" x="475.57" y="653.5" width="3.45" height="34.5" />
      <rect
        class="cross-3"
        x="475.57"
        y="654.36"
        width="3.45"
        height="27.6"
        transform="translate(-190.86 1145.46) rotate(-90)"
      />
      <rect class="cross-6" x="475.57" y="653.5" width="3.45" height="34.5" />
      <rect
        class="cross-6"
        x="475.57"
        y="654.36"
        width="3.45"
        height="27.6"
        transform="translate(-190.86 1145.46) rotate(-90)"
      />
    </g>
  );
};

export default Cross;
